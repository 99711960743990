import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 15px;
  padding-top: 80px;

  svg {
    margin-bottom: 25px;
  }

  .header {
    font-family: "Rubik";
    font-size: 24px;
    letter-spacing: -0.1px;
    color: #000000;
    text-align: center;
  }

  .text {
    font-size: 15px;
    letter-spacing: -0.25px;
    line-height: 1.07;
    color: #222222;
    margin-bottom: 25px;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    padding-top: 0;
    padding-bottom: 40px;
    height: 100%;

    svg {
      margin-top: auto;
    }

    .text {
      margin-bottom: auto;
      text-wrap: balance;
      max-width: 269px;
    }
  }
`;
