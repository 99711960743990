import { Empty } from "antd";
import React from "react";
import { Wrapper } from "./styles";

const EmptyFilter: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Wrapper>
      <Empty description={text} />
    </Wrapper>
  );
};

export default EmptyFilter;
