import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6633;
  height: 20px;
  border-radius: 4px;
  padding: 0 8px;
  font-size: 15px;
  letter-spacing: -0.25px;
  color: #ffffff;
`;
