import React, { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  BarBoxWrapper,
  CheckboxItem,
  CheckboxWrapper,
  CloseFilter,
  CounterBox,
  CreateOrderBox,
  CreateOrderFormBox,
  CreateOrderWrapper,
  Dropdown,
  DropdownBody,
  DropdownBodyWrapper,
  DropdownItem,
  DropdownTitle,
  ExplanationMark,
  NewAddressBox,
  SettingsButton,
} from "../createOrderPage/styles";
import Button from "../../UI/Button";
import CharacteristicsPopUp from "../../components/popUp/characteristicsPopUp";
import { characteristics } from "../../constants/chars";
import { ENDPOINT_FOR_IMG, GEOCODE_MAPS } from "../../constants/general";
import searchByTag from "../../redux/modules/searchByTag";
import InputDataset from "../../components/locationInputDataset";
import { InputDatasetBackground } from "../SearchOrderPage/locationInputDataset/styles";
import { InputBox } from "../createOrderPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReactComponent as MapPin } from "../../assets/icons/map-pin-location.svg";
import { ReactComponent as MapPinStart } from "../../assets/icons/24-maps-pin-start.svg";
import { ReactComponent as MapRoute } from "../../assets/icons/24-maps-route.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/icons-chevron-left.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/icons-chevron-right.svg";
import { ReactComponent as PhotoIcon } from "../../assets/icons/icons-photo.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/icons-information.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icons-calendar.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/icons-wallet.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as OptionIcon } from "../../assets/icons/options.svg";
import { ReactComponent as NewAddressIcon } from "../../assets/icons/icons-add.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/icons-vehicles-unknown.svg";
import { OrderBox, PageText, Wrapper, PageContainer } from "./styles";
import {
  addOrderImages,
  editOrderData,
  getSingleOrders,
  getVehicleParams,
} from "../../redux/store/reducers/orderSlice";
import { RootState } from "../../redux/store/store";
import moment from "moment";
import {
  getAddresses,
  getAddressesAsync,
} from "../../redux/modules/getAddresses";
import PaymentMethodPopUp from "../../components/popUp/paymentMethodPopUp";
import TimePopUp from "../../components/popUp/timePopUp";
import DescriptionPopUp from "../../components/popUp/descriptionPopUp";
import PaymentAmountPopUp from "../../components/popUp/paymentAmountPopUp";
import PhotoVideoPopUp from "../../components/popUp/photoVideoPopUp";
import PageHeader from "../../UI/PageHeader";
import { clearAddressString } from "../../utils/clearAddressString";
import SearchVehiclePopUp from "../../components/popUp/searchVehiclePopUp";
import { emptyTagResult } from "../../constants/emptyTagResult";
import PageWrapper from "../../UI/PageWrapper";

interface VehicleParams {
  cId: number;
  value: string | number;
}

interface OrderInfo {
  address: string;
  title?: string;
  budget?: number;
  description?: string;
  startDate?: string | any;
  endDate?: string;
  shifts: number;
  accommodation?: 1 | 0;
  protection?: 1 | 0;
  telegram?: 1 | 0;
  whatsapp?: 1 | 0;
  viber?: 1 | 0;
  vehicleParams?: VehicleParams[];
  city?: string;
  paymentType?: string;
  latitude?: number;
  longitude?: number;
  categoryId?: number;
}

const EditOrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const [dateTime, setDateTime] = useState<any>({});
  const [payment, setPayment] = useState<any>({});
  const [characteristics, setCharacteristics] = useState<any>([]);
  const [chosenCharacteristics, setChosenCharacteristics] = useState<any>(null);
  const [photoVideo, setPhotoVideo] = useState<any>([]);
  const [dateTimeOpen, setDateTimeOpen] = useState<boolean>(false);
  const [paymentMethodOpen, setPaymentMethodOpen] = useState<boolean>(false);
  const [paymentAmountOpen, setPaymentAmountOpen] = useState<boolean>(false);
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const [additionalOpen, setAdditionalOpen] = useState<boolean>(true);
  const [characteristicsOpen, setCharacteristicsOpen] =
    useState<boolean>(false);
  const [photoVideoOpen, setPhotoVideoOpen] = useState<boolean>(false);
  const [vehicleInputIcon, setVehicleInputIcon] = useState<any>(QuestionIcon);
  const [task, setTask] = useState<any>();
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [searchResultToRender, setSearchResultToRender] = useState<any>([]);
  const [orderInfo, setOrderInfo] = useState<OrderInfo>({
    shifts: 1,
    address: "",
  });

  const user = useAppSelector((state: RootState) => state?.auth.user);
  const order = useAppSelector((state: RootState) => state.order);
  const { singleOrder, editProcess, vehicleParams } = useAppSelector(
    (state) => state.order,
  );
  const searchByTagResult = useAppSelector(
    (state: RootState) => state.searchByTag,
  );

  const submitHandler = async () => {
    if (user?.customer?.refId && params.id) {
      const orderData = orderInfo;
      if (orderInfo?.startDate?.date && orderInfo?.startDate?.time) {
        orderData.startDate = `${orderInfo?.startDate?.date?.format(
          "YYYY-MM-DD",
        )}T${orderInfo?.startDate?.time?.format("HH:mm")}`;
      }
      if (characteristics.length) {
        orderData.vehicleParams = [...characteristics];
      }
      if (orderData.budget) {
        orderData.budget = orderData.budget * orderData.shifts;
      }

      await dispatch(
        editOrderData({
          refId: user?.customer?.refId,
          orderId: params.id,
          orderData,
        }),
      );

      if (photoVideo) {
        const images = new FormData();
        photoVideo.forEach(({ file }: any) => {
          images.append("", file);
        });
        await dispatch(
          addOrderImages({
            refId: user?.customer?.refId,
            orderId: params.id,
            images,
          }),
        );
      }

      await dispatch(
        getSingleOrders({
          refId: user?.customer?.refId,
          orderId: params.id,
        }),
      );
      navigate(-1);
    }
  };

  useEffect(() => {
    if (!singleOrder && user) {
      dispatch(
        getSingleOrders({
          refId: user?.customer?.refId,
          orderId: params.id,
        }),
      );
    }
  }, [user]);

  useEffect(() => {
    if (searchByTagResult?.data?.length > 0) {
      setSearchResultToRender(searchByTagResult);
    } else {
      setSearchResultToRender(emptyTagResult);
    }
  }, [searchByTagResult]);

  useEffect(() => {
    user?.customer?.refId &&
      dispatch(
        getSingleOrders({
          refId: user?.customer?.refId,
          orderId: params.id,
        }),
      );
  }, []);

  useEffect(() => {
    if (singleOrder) {
      setOrderInfo({
        address: singleOrder?.order?.address,
        title: singleOrder?.order?.title,
        budget: singleOrder?.order?.budget / singleOrder?.order?.shifts,
        description: singleOrder?.order?.description,
        startDate: singleOrder?.order?.startDate,
        endDate: singleOrder?.order?.endDate,
        shifts: singleOrder?.order?.shifts,
        accommodation: singleOrder?.order?.accommodation,
        protection: singleOrder?.order?.protection,
        telegram: singleOrder?.order?.telegram,
        // vehicleParams: singleOrder?.vehicleParams,
        city: singleOrder?.order?.city,
        paymentType: singleOrder?.order?.paymentType,
        categoryId: singleOrder?.order?.categoryId,
      });

      if (singleOrder?.vehicleParams?.length) {
        let parameters: any[] = [];
        singleOrder?.vehicleParams?.map((parameter: any, index: number) => {
          parameters.push({
            cId: parameter.id,
            value: parameter.value,
          });
          if (index === singleOrder?.vehicleParams?.length - 1) {
            setChosenCharacteristics([...parameters]);
          }
        });
      }

      if (singleOrder.order.categoryId) {
        dispatch(
          getVehicleParams({
            categoryId: singleOrder.order.categoryId,
          }),
        );
      }
    }
  }, [singleOrder]);

  useEffect(() => {
    if (order?.vehicleParams?.length) {
      let params: any[] = [];
      Array.isArray(singleOrder?.vehicleParams) &&
        singleOrder?.vehicleParams?.map((parameter: any) => {
          params.push({ cId: parameter.id, value: parameter.value });
        });
      setChosenCharacteristics([...params]);
    }
  }, [order]);

  useEffect(() => {
    if (
      orderInfo.categoryId &&
      orderInfo.categoryId !== singleOrder?.order?.categoryId
    ) {
      setOrderInfo({ ...orderInfo, vehicleParams: [] });
      dispatch(getVehicleParams({ categoryId: orderInfo.categoryId })).then(
        () => {
          setChosenCharacteristics([]);
        },
      );
    }
  }, [orderInfo.categoryId]);

  useEffect(() => {
    const close = (e: any) => {
      //e.preventDefault();
      if (e.keyCode === 27 || e.key === "Enter" || e.key === "NumpadEnter") {
        navigate(-1);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // useEffect(() => {
  //   const listener = (event:any) => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       submitHandler()
  //     }
  //   };
  //   document.addEventListener("keydown", listener);
  //   return () => {
  //     document.removeEventListener("keydown", listener);
  //   };
  // }, []);

  return (
    <PageWrapper noHeight minHeight="calc(100vh - 65px)" flexDirection="column">
      <PageHeader>Редактирование заказа</PageHeader>
      <Wrapper>
        <PageText>
          До получения первого отклика вы можете изменять информацию о заказе,
          кроме адреса выполнения работ{" "}
        </PageText>
        <OrderBox>
          <BarBoxWrapper>
            <InputBox
              onClick={() => setTaskOpen(true)}
              setVehicleInputIcon={setVehicleInputIcon}
              icon={vehicleInputIcon}
              label={"Какая техника или что сделать?"}
              palceholder={"Какая техника или что сделать?"}
              value={orderInfo?.title}
              width={
                singleOrder?.vehicleParams?.length ? "calc(100% - 58px)" : 0
              }
            />
            {singleOrder?.vehicleParams?.length || vehicleParams.length ? (
              <SettingsButton
                onClick={() => orderInfo?.title && setCharacteristicsOpen(true)}
              >
                <OptionIcon />
                <ExplanationMark>
                  {chosenCharacteristics?.length
                    ? chosenCharacteristics.length
                    : "!"}
                </ExplanationMark>
              </SettingsButton>
            ) : (
              ""
            )}
          </BarBoxWrapper>
          <BarBoxWrapper>
            <InputBox
              address
              icon={<MapPinStart />}
              placeholder={"Адрес выполнения работы"}
              value={orderInfo?.address ? orderInfo?.address : orderInfo?.city}
            />
          </BarBoxWrapper>
          <BarBoxWrapper>
            <InputBox
              placeholder={"Указать дату и время"}
              icon={<Clock />}
              value={
                orderInfo?.startDate?.date && orderInfo?.startDate?.time
                  ? `${moment(orderInfo?.startDate.date)?.format(
                      "D MMMM",
                    )} ${moment(orderInfo?.startDate.time)?.format("HH:mm")}`
                  : orderInfo?.startDate
                  ? moment(orderInfo.startDate).format("D MMMM HH:mm")
                  : "По договоренности"
              }
              onClick={() => setDateTimeOpen(true)}
            />
          </BarBoxWrapper>
          <BarBoxWrapper>
            <InputBox
              // active={shift > 0}
              active={Boolean(Object.keys(payment)?.length)}
              onClick={() => setPaymentMethodOpen(true)}
              icon={<WalletIcon />}
              placeholder={"Способ оплаты"}
              value={
                orderInfo?.paymentType
                  ? orderInfo?.paymentType !== "bnnds"
                    ? orderInfo?.paymentType === "bn"
                      ? "Безналичный"
                      : ""
                    : "Безналичный с НДС"
                  : ""
              }
            >
              <div className="icon"></div>
              <div className="name">{"Оплата"}</div>
              <div className="right-icon">
                <ArrowRight />
              </div>
            </InputBox>
          </BarBoxWrapper>
          <Dropdown>
            <DropdownTitle
              open={additionalOpen}
              onClick={() => setAdditionalOpen(!additionalOpen)}
            >
              <div className="text additional">Дополнительная информация</div>
              <div className="arrow">
                <ArrowRight />
              </div>
            </DropdownTitle>
            <DropdownBodyWrapper
              shift={orderInfo?.shifts}
              open={additionalOpen}
            >
              <DropdownBody className="dropdown-body-box">
                <BarBoxWrapper>
                  <InputBox
                    // active={shift > 0}
                    icon={<div>₽</div>}
                    active={true}
                    placeholder={"Бюджет"}
                    value={
                      !orderInfo?.budget
                        ? ""
                        : (orderInfo?.budget * orderInfo?.shifts)?.toString()
                    }
                    onClick={() => {
                      setPaymentAmountOpen(true);
                    }}
                  />
                </BarBoxWrapper>

                <DropdownItem active={true}>
                  <div className="icon">
                    <CalendarIcon />
                  </div>
                  <div
                    className={`${
                      orderInfo?.shifts && orderInfo?.shifts > 0 ? "active" : ""
                    } text`}
                  >
                    <div className="title">Количество смен (8ч.) </div>
                  </div>

                  <CounterBox>
                    <button
                      disabled={
                        orderInfo?.shifts ? orderInfo?.shifts <= 0 : false
                      }
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          shifts:
                            orderInfo?.shifts > 0 ? orderInfo?.shifts - 1 : 0,
                        })
                      }
                    >
                      <LeftArrow />
                    </button>
                    <div className="indicator">{orderInfo?.shifts}</div>
                    <button
                      onClick={() =>
                        setOrderInfo({
                          ...orderInfo,
                          shifts: orderInfo?.shifts + 1,
                        })
                      }
                    >
                      <RightArrow />
                    </button>
                  </CounterBox>
                </DropdownItem>

                <BarBoxWrapper>
                  <InputBox
                    placeholder={"Дополнительная информация"}
                    icon={<InfoIcon />}
                    onClick={() => setDescriptionOpen(true)}
                    value={
                      orderInfo?.description
                        ? orderInfo?.description?.length <= 15
                          ? orderInfo?.description.slice(0, 15)
                          : orderInfo?.description.slice(0, 15) + "..."
                        : ""
                    }
                  />
                </BarBoxWrapper>

                <BarBoxWrapper>
                  <InputBox
                    placeholder={"Добавить фото, видео"}
                    icon={<PhotoIcon />}
                    onClick={() => setPhotoVideoOpen(true)}
                    value={
                      photoVideo.length ? `${photoVideo.length} файлов` : ""
                    }
                  />
                </BarBoxWrapper>
                <CheckboxWrapper>
                  {!!(orderInfo?.shifts && orderInfo?.shifts >= 2) && (
                    <CheckboxItem>
                      <input
                        type="checkbox"
                        checked={
                          orderInfo?.accommodation
                            ? !!orderInfo?.accommodation
                            : false
                        }
                        onChange={(e) => {
                          setOrderInfo({
                            ...orderInfo,
                            accommodation: e.target.checked ? 1 : 0,
                          });
                        }}
                      />
                      Предоставляется жилье
                    </CheckboxItem>
                  )}
                  {!!(orderInfo?.shifts && orderInfo?.shifts >= 2) && (
                    <CheckboxItem>
                      <input
                        type="checkbox"
                        checked={
                          !orderInfo?.protection
                            ? false
                            : !!orderInfo?.protection
                        }
                        onChange={(e) => {
                          setOrderInfo({
                            ...orderInfo,
                            protection: e.target.checked ? 1 : 0,
                          });
                        }}
                      />
                      Охрана
                    </CheckboxItem>
                  )}
                </CheckboxWrapper>
              </DropdownBody>
            </DropdownBodyWrapper>
          </Dropdown>
          <Button disabled={editProcess === "pending"} onClick={submitHandler}>
            Сохранить
          </Button>
          <div className="text formBottom">
            До получения первого отклика вы можете изменять информацию о заказе,
            кроме адреса выполнения работ
          </div>
        </OrderBox>
        {characteristicsOpen && (
          <CharacteristicsPopUp
            close={() => setCharacteristicsOpen(false)}
            isVisible={
              characteristicsOpen &&
              (singleOrder?.vehicleParams?.length > 0 ||
                !!vehicleParams?.length)
            }
            onSubmit={(p: any) => {
              setCharacteristics(p);
              setChosenCharacteristics(p);
            }}
            defaultValue={
              chosenCharacteristics?.length ? chosenCharacteristics : []
            }
            tab={() => {
              setCharacteristicsOpen(false);
            }}
            vehicle={orderInfo?.title}
            characteristics={vehicleParams}
            categoryId={singleOrder?.order?.categoryId}
          />
        )}

        {taskOpen && (
          <>
            <SearchVehiclePopUp
              wrapperClass="create-order-form-box"
              setValue={(a: any) => {
                setTask({ ...a.data, img: a?.img });
                setTaskOpen(false);
                setCharacteristics([]);
                setOrderInfo({
                  ...orderInfo,
                  title: a.data.title,
                  categoryId: a.data.categoryId,
                });
              }}
              category
              defaultValue={orderInfo?.title}
              close={() => setTaskOpen(false)}
              onChange={(text: any) => {
                text.length > 0
                  ? dispatch(searchByTag(user?.customer?.refId, text))
                  : setSearchResultToRender(emptyTagResult);
              }}
              setVehicleInputIcon={setVehicleInputIcon}
              type={"vehicle"}
              list={searchResultToRender?.data?.map(
                (
                  { categoryId, id, tags, title, category }: any,
                  index: number,
                ) => ({
                  name: category?.title,
                  // description: tags,
                  img: `${ENDPOINT_FOR_IMG}/${
                    category?.icon
                      ? category?.icon
                      : "assets/images/excavator.png"
                  }`,
                  data: {
                    categoryId,
                    id,
                    tags,
                    title: category?.title,
                  },
                }),
              )}
              tab={() => {
                setTaskOpen(false);
                setDateTimeOpen(true);
              }}
            />
            <InputDatasetBackground onClick={() => setTaskOpen(false)} />
          </>
        )}
        {dateTimeOpen && (
          <TimePopUp
            close={() => setDateTimeOpen(false)}
            onSubmit={(dt: any) =>
              setOrderInfo({ ...orderInfo, startDate: dt })
            }
            tab={() => {
              setDateTimeOpen(false);
              // setPaymentOpen(true);
            }}
            defaultValue={dateTime}
            setShift={(shifts: number) =>
              setOrderInfo({ ...orderInfo, shifts: shifts })
            }
            shift={orderInfo.shifts}
          />
        )}
        {paymentMethodOpen && (
          <PaymentMethodPopUp
            close={() => setPaymentMethodOpen(false)}
            onSubmit={(p: any) => {
              setOrderInfo({
                ...orderInfo,
                paymentType: p.paymentMethod[0].value,
              });
              // setPayment(p);
            }}
            tab={() => {
              setPaymentMethodOpen(false);
              setDescriptionOpen(true);
            }}
            defaultValue={
              orderInfo.paymentType === "bn"
                ? "Безналичный"
                : orderInfo.paymentType === "bnds"
                ? "Безналичный с НДС"
                : "По договоренности"
            }
          />
        )}
        {paymentAmountOpen && (
          <PaymentAmountPopUp
            close={() => setPaymentAmountOpen(false)}
            onSubmit={(p: any) => {
              setOrderInfo({
                ...orderInfo,
                budget: p.amount,
              });
            }}
            tab={() => {
              setPaymentAmountOpen(false);
              setDescriptionOpen(true);
            }}
            defaultValue={{ amount: Number(orderInfo?.budget) }}
          />
        )}
        {descriptionOpen && (
          <DescriptionPopUp
            close={() => setDescriptionOpen(false)}
            onSubmit={(p: any) =>
              setOrderInfo({ ...orderInfo, description: p })
            }
            defaultValue={orderInfo.description}
            tab={() => {
              setDescriptionOpen(false);
              setPhotoVideoOpen(true);
            }}
          />
        )}

        {photoVideoOpen && (
          <PhotoVideoPopUp
            close={() => setPhotoVideoOpen(false)}
            onSubmit={(p: any) => setPhotoVideo(p)}
            defaultPhotoVideo={photoVideo}
          />
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default EditOrderPage;
