export const roles: any = {
  agent: "Агент",
  customer: "Заказчик",
  worker: "Исполнитель",
};

export const menuItems: any = {
  // profile: "Профиль и настройки",
  profile: "Профиль и настройки",
  "my-vehicle": "Моя техника",
  finance: "Финансы",
  partners: "Партнерам",
  "employee-management": "Управление сотрудниками",
  "vehicle-management": "Управление техникой",
  "create-employee": "Новый сотрудник",
  "create-vehicle": "Управление техникой",
};
