import orderSlice from "./reducers/orderSlice";
import { combineReducers } from "redux";
import actionTypes from "../../constants/actionTypes";
import { promiseReducer } from "./promiseReducer";
import authSlice from "./reducers/authSlice";
import uiSlice from "./reducers/uiSlice";
import vehicleSlice from "./reducers/vehicleSlice";
import financeSlice from "./reducers/financeSlice";
import registrationSlice from "./reducers/registrationSlice";
import companySlice from "./reducers/companySlice";
import notificationsSlice from "./reducers/notificationsSlice";

const {
  GET_ADDRESSES,
  GET_ME,
  GET_REFERRALS,
  GET_REWARD,
  GET_LINK,
  GET_PASSPORT,
  GET_REGISTRATIONS,
  GET_PARTNERS_HISTORY,
  GET_CARDS,
  GET_PAYOUT_HISTORY,
  SEARCH_BY_TAG,
  GET_CHATS,
  GET_CHAT,
  GET_MANAGERS,
  GET_COMPANY,
  GET_MANAGER_DATA,
  GET_DASHBOARD_ORDERS,
  GET_DASHBOARD_SINGLE_ORDER,
  GET_DASHBOARD_OBJECTS,
  GET_DASHBOARD_SINGLE_OBJECT,
  GET_FEEDBACK_RATING,
} = actionTypes;

export const rootReducer = combineReducers({
  addresses: promiseReducer(GET_ADDRESSES),
  me: promiseReducer(GET_ME),
  referrals: promiseReducer(GET_REFERRALS),
  reward: promiseReducer(GET_REWARD),
  link: promiseReducer(GET_LINK),
  passport: promiseReducer(GET_PASSPORT),
  registrations: promiseReducer(GET_REGISTRATIONS),
  partnersHistory: promiseReducer(GET_PARTNERS_HISTORY),
  cards: promiseReducer(GET_CARDS),
  payoutHistory: promiseReducer(GET_PAYOUT_HISTORY),
  searchByTag: promiseReducer(SEARCH_BY_TAG),
  chats: promiseReducer(GET_CHATS),
  chat: promiseReducer(GET_CHAT),
  managers: promiseReducer(GET_MANAGERS),
  managerData: promiseReducer(GET_MANAGER_DATA),
  dashboardOrders: promiseReducer(GET_DASHBOARD_ORDERS),
  dashboardSingleOrders: promiseReducer(GET_DASHBOARD_SINGLE_ORDER),
  //company: promiseReducer(GET_COMPANY),
  object: promiseReducer(GET_DASHBOARD_OBJECTS),
  singleObject: promiseReducer(GET_DASHBOARD_SINGLE_OBJECT),
  feedbackRating: promiseReducer(GET_FEEDBACK_RATING),
  ui: uiSlice,
  vehicle: vehicleSlice,
  auth: authSlice,
  finance: financeSlice,
  order: orderSlice,
  registration: registrationSlice,
  company: companySlice,
  notifications: notificationsSlice,
});
