import { ReactComponent as Star } from "../../assets/icons/24-basic-star.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/icons-search.svg";
import { ReactComponent as OrdersIcon } from "../../assets/icons/icon-bottom-works-active.svg";
import { ReactComponent as UserAvatarIcon } from "../../assets/icons/icons-basic-user.svg";
import { ReactComponent as VehiclesManagementIcon } from "../../assets/icons/icons-vehicle.svg";
import { ReactComponent as RatingIcon } from "../../assets/icons/icon-rating.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/icons/icon-24-intellicon-basic-bar-chart.svg";
import { ReactComponent as GearIcon } from "../../assets/icons/gear.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/chat_bubble_outline_black_24dp.svg";

export const CustomerMenu = [
  {
    name: "Новый заказ",
    Icon: SearchIcon,
    link: "/create-order",
  },
  {
    name: "Мои заказы",
    Icon: OrdersIcon,
    link: "/o",
  },
  {
    name: "Сообщения",
    Icon: ChatIcon,
    link: "/messages",
  },
  {
    name: "Мой профиль",
    Icon: UserAvatarIcon,
    link: "/profile",
  },
];

export const WorkerMenu = [
  {
    name: "Поиск заказов",
    Icon: SearchIcon,
    link: "/search-order",
  },
  {
    name: "Мои работы",
    Icon: OrdersIcon,
    link: "/search-order/results?flag=my",
  },
  {
    name: "Сообщения",
    Icon: ChatIcon,
    link: "/messages",
  },
  {
    name: "Мой профиль",
    Icon: UserAvatarIcon,
    link: "/profile",
  },
];

export const CompanyMenu = [
  {
    name: "Цифровой диспетчер",
    Icon: Star,
    link: "/dashboard/objects",
  },
  {
    name: "Новый заказ",
    Icon: SearchIcon,
    link: "/create-order",
  },
  {
    name: "Мои заказы",
    Icon: OrdersIcon,
    link: "/o",
  },
  {
    name: "Сообщения",
    Icon: ChatIcon,
    link: "/messages",
  },
  {
    name: "Мой профиль",
    Icon: UserAvatarIcon,
    link: "/profile",
  },
];

export const DashboardMenu = [
  {
    name: "Объекты",
    Icon: Star,
    link: "/dashboard/objects",
  },
  {
    name: "Сотрудники",
    Icon: Star,
    link: "/dashboard/employees",
  },
  {
    name: "Исполнители",
    Icon: Star,
    link: "/dashboard/workers",
  },
  {
    name: "Заказы",
    Icon: Star,
    link: "/dashboard/order",
  },
];

export const WorkerSecondMenu = [
  {
    name: "Управление техникой",
    Icon: VehiclesManagementIcon,
    link: "/profile/vehicle-management",
  },
  {
    name: "Отзывы и рейтинг",
    Icon: RatingIcon,
    link: "/dashboard/employees",
  },
  {
    name: "Настройки",
    Icon: GearIcon,
    link: "/profile/settings",
  },
];

export const CustomerSecondMenu = [
  {
    name: "Отзывы и рейтинг",
    Icon: RatingIcon,
    link: "/dashboard/employees",
  },
  {
    name: "Настройки",
    Icon: GearIcon,
    link: "/profile/settings",
  },
];

export const CompanySecondMenu = [
  {
    name: "Аналитика",
    Icon: AnalyticsIcon,
    link: "/analytics",
  },
  {
    name: "Отзывы и рейтинг",
    Icon: RatingIcon,
    link: "/dashboard/employees",
  },
  {
    name: "Настройки",
    Icon: GearIcon,
    link: "/profile/settings",
  },
];

