export const WorkerOrderStatuses = [
  {
    title: "Уже ищем исполнителя",
    trackTitle: "Заказ создан",
    text: "Заказ можно редактировать до первого отклика исполнителя. Убедитесь, что заказ оформлен корректно и детально. Заказы со справедливым бюджетом, с подробным описанием и фото-видео пояснением собирают больше откликов",
    type: "created",
  },
  {
    title: "Есть отклик",
    trackTitle: "Есть отклик",
    text: "Сообщите заказчику, когда техника отправится на объект для выполнения работы. Вы получите подтверждение трекинга, а заказчик будет знать, что оборудование прибудет вовремя",
    type: "got_offer",
  },
  {
    title: "Вас назначили исполнителем",
    trackTitle: "Исполнитель назначен",
    text: "Заказчик произведет оплату в течение HH:MM. Как только банк подтвердит получение оплаты, мы сообщим что можно приступать к работе",
    type: "worker_selected",
  },
  {
    title: "Скоро начало работ",
    trackTitle: "Подтверждение оплаты ",
    text: "Вы готовы к заказу? Чтобы сообщить заказчику о готовности, нажмите кнопку.",
    type: "waiting_for_payment",
    button: "Выехать на объект",
  },
  {
    title: "Вы выехали на объект",
    trackTitle: "Выезд исполнителя",
    text: "Когда прибудете на обьект, обязательно нажмите кнопку. Не нажимайте кнопку раньше времени, это повлияет на время простоя.",
    type: "worker_on_the_way",
    button: "Прибыл на объект",
  },
  {
    title: "Вы прибыли на обьект",
    trackTitle: "Исполнитель прибыл",
    text: "Проверьте готовность к выполнению, технику безопасности и окружение. Сверьте техзадание с ответственным лицом за приемку работы. Сфотографируйте ситуацию «как сейчас»",
    type: "worker_set_out",
    button: "Начать работу",
  },
  {
    title: "Вы начали работу",
    trackTitle: "Начало работы",
    text: "Время пошло. Когда полностью выполните техзадание, сфотографируйте результат «после» и нажмите кнопку.",
    type: "worker_started",
    button: "Закончить работу",
  },
  {
    title: "Работа выполнена",
    trackTitle: "Работы завершены",
    text: "Вы — молодец! Мы сообщили заказчику о завершении и ожидаем приемку.",
    type: "worker_finished",
  },
  {
    title: "Работа принята",
    trackTitle: "Заказ завершен",
    text: "Оставьте честный отзыв о работе с заказчиком, так вы поможете другим исполнителям выбирать надежных заказчиков. Отзыв заказчика можно прочитать после публикации вашего отзыва.",
    type: "done",
    button: "Оставить отзыв",
  },
  {
    title: "Ваше мнение очень важно!",
    trackTitle: "Обмен отзывами",
    text: "Благодарим за отзыв!",
    type: "feedback_left",
  },
];

export const CustomerOrderStatuses = [
  {
    title: "Уже ищем исполнителя",
    trackTitle: "Заказ создан",
    text: "Заказ можно редактировать до первого отклика исполнителя. Убедитесь, что заказ оформлен корректно и детально. \n\nЗаказы со справедливым бюджетом, с подробным описанием и фото-видео пояснением собирают больше откликов",
    type: "created",
  },
  {
    title: "Пора назначить исполнителя",
    trackTitle: "Есть отклик",
    text: "Изучите поступившие отклики, сверьте детали и назначьте самого подходящего исполнителя на заказ",
    type: "got_offer",
  },
  {
    title: "Пора проверить документы и перейти к оплате",
    trackTitle: "Исполнитель выбран",
    text: `Заказ нужно оплатить в течение HH:MM (+Таймер обратного отсчета). Как только банк подтвердит получение оплаты, мы сообщим исполнителю, что он может приступить к работе. Если оплата задержится, заказ автоматически отменится. Пожалуйста, свяжитесь с нами, если потребуется помощь`,
    type: "worker_selected",
  },
  {
    title: "Ура! Скоро начнется работа",
    trackTitle: "Подтверждение оплаты ",
    text: "Прогнозируемый маршрут и путь исполнителя можно отследить в реальном времени на карте. Мы сообщим о прибытии исполнителя на заказ.",
    type: "waiting_for_payment",
  },
  {
    title: "Исполнитель выехал на обьект",
    trackTitle: "Выезд исполнителя",
    text: "Ожидаем прибытия исполнителя на место заказа. Мы сообщим, как только исполнитель будет готов к началу работ.",
    type: "worker_on_the_way",
  },
  {
    title: "Исполнитель прибыл и готов начать работу",
    trackTitle: "Исполнитель прибыл",
    text: "Ожидаем начала работ. Время пошло.",
    type: "worker_set_out",
  },
  {
    title: "Исполнитель сообщил, что приступил к работе",
    trackTitle: "Начало работы",
    text: "Мы сообщим о завершении, для приемки работ. Время пошло.",
    type: "worker_started",
  },
  {
    title: "Пора принимать работу",
    trackTitle: "Работы завершены",
    text: "Пожалуйста, убедитесь, что работа выполнена качественно и соответствует Техническому заданию. Фото «было/стало» приложены к заказу.",
    type: "worker_finished",
    button: "Принять работу",
  },
  {
    title: "Ура! Заказ завершен",
    trackTitle: "Заказ завершен",
    text: "Оставьте честный отзыв о работе с исполнителем, так вы поможете другим заказчикам выбирать надежных исполнителей. Отзыв Исполнителя о можно прочитать после публикации вашего отзыва.",
    type: "done",
    button: "Оставить отзыв",
  },
  {
    title: "Ваше мнение очень важно!",
    trackTitle: "Обмен отзывами",
    text: "Благодарим за отзыв!",
    type: "feedback_left",
  },
];

