import styled from "styled-components";

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  overflow-y: auto;
  height: 100%;
  background: #ffffff;

  &:nth-child(odd) {
    padding: 128px 0 40px 123px;
  }

  @media screen and (max-width: 1440px) {
    &:nth-child(odd) {
      padding: 40px 0 32px 32px;
    }
  }

  @media screen and (max-width: 998px) {
    &:nth-child(odd) {
      padding: 0 0 32px 56px;
      width: 100%;
    }

    &:nth-child(even) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    &:nth-child(odd) {
      padding: 0;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;

    @media screen and (max-width: 998px) {
      display: none;
    }
  }
`;

export const Paragraph = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 32px;
  max-width: 648px;

  @media screen and (max-width: 480px) {
    font-size: 16px;
    margin-bottom: 24px;
    padding: 0 16px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 56px;
  margin-bottom: 40px;

  a {
    color: unset;
    text-decoration: none;
    width: fit-content;

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1196px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 998px) {
    flex-direction: row;
    align-items: center;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: flex-start;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 24px;
    width: 100%;
    align-items: center;
    padding: 0 16px;
  }
`;

export const PhoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #999999;
  }

  .phone {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: #000;

    a {
      color: inherit;
      text-decoration: none;
    }
  }
`;

export const Button = styled.div`
  width: 328px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff6633;
  color: #ffffff;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 rgba(166, 60, 24, 0.3);

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  width: 50%;
  padding: 128px 0 40px 123px;
  overflow-y: auto;

  @media screen and (max-width: 1440px) {
    padding: 40px 0 32px 32px;
  }

  @media screen and (max-width: 998px) {
    padding: 32px 0 32px 56px;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    padding: 24px 0 24px 56px;
  }

  @media screen and (max-width: 480px) {
    padding: 25px 16px 25px 16px;
  }

  @media screen and (max-width: 320px) {
    padding: 37px 16px 37px 16px;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 24px;

  @media screen and (max-width: 480px) {
    width: 100%;
    gap: 8px;
    padding: 0 16px;
  }
`;

export const InfoCard = styled.div`
  width: 304px;
  height: 296px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px rgba(200, 200, 200, 0);
  background: #ffffff;
  border-radius: 10px;

  .cardText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
  }

  .cardHeader {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000;
    width: 100%;
  }

  .cardParagraph {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.15px;
    color: #757575;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    height: 216px;
  }

  @media screen and (max-width: 320px) {
    width: 288px;
    height: 240px;
  }
`;
