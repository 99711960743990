import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  width: 50%;
  min-height: 236px;
  max-width: 480px;
  background: white;
  border-radius: 16px;
  padding: 16px;
  flex-direction: column;
  overflow: hidden;

  .historyButton {
    display: flex;
    gap: 4px;
    font-size: 12px;
    line-height: 1;
    color: #757575;
    cursor: pointer;
    align-self: flex-end;
    transition: color 0.25s linear;
    align-items: center;
    margin-top: auto;

    svg {
      g {
        transition: all 0.25s linear;
      }
    }
    &:hover {
      color: #ff6633;

      svg {
        g {
          fill: #ff6633;
        }
      }
    }
  }

  &:first-child {
    gap: 24px;

    .cardTitle {
      font-size: 20px;
      line-height: 1.2;
      color: #000;
    }

    @media screen and (max-width: 768px) {
      width: 100%;
      max-width: 100%;
    }

    @media screen and (max-width: 480px) {
      border-radius: 0;
    }
  }

  &:nth-child(2) {
    gap: 16px;
    padding-top: 16px;

    .text {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: -0.2px;
      color: #222222;
      white-space: pre-wrap;
    }

    @media screen and (max-width: 480px) {
      width: calc(100% - 32px);
      margin: 0 16px;
    }
  }
`;

export const StatusLabel = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
  background: #f7f4f1;
  border-radius: 16px;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    aspect-ratio: 1 / 1;

    img,
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .titleGroup {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 16px;
    line-height: 1;
    color: #222222;

    .subTitle {
      font-size: 12px;
      line-height: 1;
      color: #757575;
    }
  }
`;
