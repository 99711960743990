import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  height: fit-content;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  .errorMessage {
    width: 100%;
    font-size: 14px;
    font-weight: 450;
    color: red;
  }
`;

export const Filter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 392px;
  height: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 16px;
  position: sticky;
  top: 16px;
  font-family: "Rubik";
  &::-webkit-scrollbar {
    display: none;
  }

  .filterItem {
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    color: #222222;
    padding: 10px 8px;
    transition: all 0.3s linear;
    border-radius: 8px;
    white-space: nowrap;

    &.active {
      background: rgba(255, 102, 51, 0.08);
      color: #ff6633;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: row;
    background: none;
    width: 100%;
    padding: 0;
    border-radius: 0;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 32px;
  border-radius: 16px;
  background: #ffffff;
  height: fit-content;
  width: 100%;
  max-width: 984px;

  @media screen and (max-width: 480px) {
    padding: 16px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px solid #ebebeb;
  cursor: pointer;

  .userInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;

    .subTitle {
      font-size: 12px;
      line-height: 1;
      color: #999999;
    }
  }

  .rightGroup {
    display: flex;
    gap: 16px;
    margin-left: auto;
    align-items: center;
  }

  .employeeAvarat {
    width: 24px;
    height: 24px;
    border-radius: 50px;
  }

  .currentUserBadge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 8px;
    background: rgba(255, 102, 51, 0.08);
    color: #ff6633;
    font-size: 15px;
    letter-spacing: -0.25px;
    border-radius: 10px;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

export const SideMenuWrapper = styled.div<{ open: boolean }>`
  height: 100vh;
  width: 477px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-477px")};
  transition: all 0.2s ease-in;
  z-index: 100;
  overflow: auto;
  scrollbar-gutter: stable;

  .menuItemGroup {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 20px;
    line-height: 1.2;
    color: #000;
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text {
      font-size: 16px;
      color: #000;
    }

    &:nth-child(2) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @keyframes wrapperOpen {
    from {
      right: -477px;
    }
    to {
      right: 0px;
    }
  }

  @keyframes wrapperClose {
    from {
      right: 0px;
    }
    to {
      right: -477px;
    }
  }
`;

export const SideMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;

  svg {
    cursor: pointer;
  }
`;

export const SideMenuBackground = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  backdrop-filter: blur(2px);
  border: solid 1px #979797;
  background-color: ${({ open }) => (open ? "#222222b3" : "#00000000")};
  position: fixed;
  top: 0;
  left: 0;
  //transition: all 0.25s ease-in;
  z-index: ${({ open }) => (open ? "3" : "-1")};
  ${({ open }) =>
    open ? "animation: open 0.2s ease-in" : "animation: close 0.2s ease-out"};

  @keyframes open {
    from {
      /* z-index: -1; */
      background-color: #22222200;
    }
    to {
      /* z-index: 3; */
      background-color: #222222b3;
    }
  }

  @keyframes close {
    from {
      /* z-index: 3; */
      background-color: #22222200;
    }
    to {
      /* z-index: -1; */
      background-color: #222222b3;
    }
  }
`;

export const OrdersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OrderCard = styled.div`
  padding: 16px;
  border-radius: 10px;
  background: #f7f7f7;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &:hover {
    box-shadow: 0 2px 8px 0 #00000014;
  }

  .orderTitle {
    font-size: 18px;
    line-height: 1.2;
    color: #000;
    display: flex;
    gap: 8px;
    align-items: center;

    .date {
      font-size: 14px;
      line-height: 1.14;
      margin-left: auto;
      opacity: 0.5;
    }
  }

  .orderBody {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .orderFooter {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 1.25;

    img {
      width: 24px;
      height: 24px;
    }

    .orderStatus {
      margin-left: auto;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.07;
      letter-spacing: -0.25px;
      border: 1px solid #21c17a;
      border-radius: 10px;
      padding: 0px 8px;
    }
  }
`;
