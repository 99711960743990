import { toast } from "react-toastify";

export const getUserLocation = async (
  success: (position: GeolocationPosition) => void,
) => {
  return new Promise(() => {
    window?.navigator?.geolocation?.getCurrentPosition(success, () => {
      toast.error(
        "Чтобы видеть вашу геопозицию, откройте доступ в настройках браузера.",
        {
          autoClose: 7000,
        },
      );
    });
  });
};

