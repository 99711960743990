import styled from "styled-components";

export const styles = {
  mainColor: "#FF6633",
  red: "rgba(255, 51, 88, 1)",
};

export const Container = styled.div<{
  keepContainer?: boolean;
  center?: boolean;
  fullscreen?: boolean;
}>`
  /* width: 1200px; */
  width: 100%;
  max-width: 1392px;
  margin: auto;
  ${({ center }) => center && "display: flex; justify-content: center;"}

  /* @media only screen and (max-width: 1440px) {
    width: ${({ keepContainer }) => (keepContainer ? "1000px" : "100%")};
  } */
  @media only screen and (max-width: 720px) {
    width: 95%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

export const BigTitle = styled.p`
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;

  letter-spacing: -1px;
  color: #000000;
`;

export const MediumTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
`;

export const SmallTitleSameHeight = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

export const SmallTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
`;

export const Text16 = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  color: #222222;
`;

export const TextLight12 = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height, or 114% */

  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.5;
`;

export const TextLight14 = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  color: #222222;

  mix-blend-mode: normal;
  opacity: 0.5;
`;

export const SmallText = styled.p`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #222222;
  white-space: break-spaces;
`;

export const Highlighted = styled.span`
  color: ${styles.mainColor};
  cursor: pointer;
`;

export const ContentTitle = styled(SmallTitle)`
  display: flex;
  align-items: center;

  @media only screen and (min-width: 1440px) {
    font-size: 26px;
    font-weight: 500;
    font-weight: normal;
  }
`;

export const ContentText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const Anchor = styled.a`
  color: ${styles.mainColor};
  &:hover {
    color: ${styles.mainColor}aa;
  }
`;

export const MobileGoBack = styled.div`
  margin-right: 16px;
  display: none;
  @media only screen and (max-width: 576px) {
    display: flex;
  }
`;
