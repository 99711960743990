// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth";
//
// // import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
//
// const firebaseConfig = {
//   apiKey: "AIzaSyDYqZxNszPD0rIXieyo_gwWul63t2sVJ3c",
//   authDomain: "rukki-pro.firebaseapp.com",
//   databaseURL: "https://rukki-pro.firebaseio.com",
//   projectId: "rukki-pro",
//   storageBucket: "rukki-pro.appspot.com",
//   messagingSenderId: "1001064264116",
//   appId: "1:1001064264116:web:e9306364c0b087c4a7c2e1"
// };
//
// export const firebaseApp = initializeApp(firebaseConfig);
//
// // initializeAppCheck(firebaseApp, {
// //   provider: new ReCaptchaV3Provider(process.env.REACT_APP_FIREBASE_RECAPTCHA),
//
// //   // Optional argument. If true, the SDK automatically refreshes App Check
// //   // tokens as needed.
// //   isTokenAutoRefreshEnabled: true
// // });
//
// export const authentication = getAuth(firebaseApp);

// authentication.settings.appVerificationDisabledForTesting = true;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyDYqZxNszPD0rIXieyo_gwWul63t2sVJ3c",
  authDomain: "rukki-pro.firebaseapp.com",
  databaseURL: "https://rukki-pro.firebaseio.com",
  projectId: "rukki-pro",
  storageBucket: "rukki-pro.appspot.com",
  messagingSenderId: "1001064264116",
  appId: "1:1001064264116:web:e9306364c0b087c4a7c2e1",
};

export const firebase = initializeApp(firebaseConfig);
export const authentication = getAuth(firebase);
