import React, { memo, useEffect, useState } from "react";
import {
  AddObj,
  CalendarWrapper,
  FilterBtn,
  FilterText,
  FilterWrapper,
  Section,
  StatCard,
  StatCardList,
  TableHeader,
  TableWrapper,
  TBody,
  TData,
  TH,
  TimeItem,
  TimeList,
  TRow,
} from "./styles";
import { ReactComponent as DropDown } from "../../assets/icons/icons-arrow-drop-down.svg";
import { ReactComponent as Plus } from "../../assets/icons/icons-add.svg";
import { styles } from "../../components/styles/styles";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getRandomNumberFloat, thousandSeparator } from "../../utils/numbers";
import moment from "moment";
import { DatePicker } from "antd";
import { Dropdown } from "../dashboardOrders";
import { removeDuplicates } from "../../utils/uiHelpers";
import getDashboardOrders from "../../redux/modules/getDashboardOrders";
import getManagers from "../../redux/modules/getManagers";
import { MinusOutlined } from "@ant-design/icons";
import getDashboardObjects from "../../redux/modules/getDashboardObjects";
import EmptyDashboardTable from "../../components/emptyPage/emptyDashboardTable";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";

const { RangePicker } = DatePicker;

const DashboardObjects: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [center, setCenter] = useState([55.75, 37.57]);
  const [coords, setCoords] = useState<{ id: string; coord: number[] }[]>([]);
  const [customerFilter, setCustomerFilter] = useState<string[]>([]);
  const [vehicleFilter, setVehicleFilter] = useState<string[]>([]);
  const [staffFilter, setStaffFilter] = useState<string[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const [filterParams, setFilterParams] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
    customers: string[] | null;
    vehicle: string[] | null;
    staff: string[] | null;
  }>({ from: null, to: null, customers: null, vehicle: null, staff: null });

  const [dateFilter, setDateFilter] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
  }>({ from: null, to: null });

  const user = useAppSelector((state) => state.auth.user);
  const { data: orders } = useAppSelector((state) => state.dashboardOrders);
  const {
    data: objects,
    loading: objLoading,
    success: objSuccess,
  } = useAppSelector((state) => state.object);

  const [sort, setSort] = useState<string[]>([]);

  const sortHandle = (name: string) => {
    if (sort[0] === name) {
      setSort([`-${name}`]);
    } else {
      setSort([name]);
    }
  };

  const sortData = (): any => {
    return objects?.objects;
  };

  const refId = user?.customer?.refId;

  const getUserLocation = () => {
    window?.navigator?.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setCenter([latitude, longitude]);
      },
      (err) => {},
    );
  };

  useEffect(() => {
    if (refId) {
      dispatch(getManagers(refId));
      dispatch(getDashboardOrders(refId));
    }
    getUserLocation();
  }, [refId]);

  useEffect(() => {
    if (refId) {
      dispatch(getDashboardObjects(refId, { filterParams, sort }));
    }
  }, [refId, filterParams, sort]);

  useEffect(() => {
    setCoords([]);
    objects?.objects?.forEach(
      ({ id, latitude, longitude }: any, index: number) => {
        setCoords((prev: any) => [
          ...prev,
          {
            id,
            coord: [
              latitude || center[0] + getRandomNumberFloat(-0.2, 0.2),
              longitude || center[1] + getRandomNumberFloat(-0.2, 0.2),
            ],
          },
        ]);
      },
    );
  }, [refId, objects?.objects]);

  const handleFilterDate = ({
    type,
    from,
    to,
  }: {
    type?: string | null;
    from?: moment.Moment | null;
    to?: moment.Moment | null;
  }) => {
    switch (type) {
      case "today":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().endOf("day"),
        });
        break;
      case "tomorrow":
        setDateFilter({
          from: moment().add(1, "day").startOf("day"),
          to: moment().add(1, "day").endOf("day"),
        });
        break;
      case "week":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(7, "day").endOf("day"),
        });
        break;
      case "month":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(30, "day").endOf("day"),
        });
        break;
      default:
        setDateFilter({
          from: from || null,
          to: to || null,
        });
        break;
    }
  };

  const handleFilter = () => {
    setFilterParams({
      ...filterParams,
      from: dateFilter?.from,
      to: dateFilter?.to,
      customers: customerFilter.length > 0 ? customerFilter : null,
      vehicle: vehicleFilter.length > 0 ? vehicleFilter : null,
      staff: staffFilter.length > 0 ? staffFilter : null,
    });
  };

  const isActiveDate = (type: string): boolean => {
    switch (type) {
      case "today":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().endOf("day").diff(dateFilter.to) === 0
        );
      case "tomorrow":
        return (
          moment().add(1, "day").startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(1, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "week":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(7, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "month":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(30, "day").endOf("day").diff(dateFilter.to) === 0
        );
      default:
        return false;
    }
  };

  return (
    <PageWrapper gap="0" minHeight="calc(100vh - 65px)" noWidth>
      <Section>
        <PageHeader
          button={
            <AddObj onClick={() => navigate("/dashboard/objects/add")}>
              <Plus />
              Добавить объект
            </AddObj>
          }
        >
          Объекты
        </PageHeader>
        <StatCardList>
          <StatCard onClick={() => navigate("/dashboard/objects")}>
            <div className="number">{orders?.headers?.total || 0}</div>
            <div className="text">
              Всего <br /> объектов
            </div>
          </StatCard>
          <StatCard onClick={() => navigate("/dashboard/order")}>
            <div className="number">{orders?.headers?.inWork || 0}</div>
            <div className="text">
              Заказов <br /> в работе
            </div>
          </StatCard>
          <StatCard>
            <div className="number">{orders?.headers?.startsToday || 0}</div>
            <div className="text">
              Заказов начинаются <br /> сегодня
            </div>
          </StatCard>
          <StatCard className="amountInWork">
            <div className="number">
              {orders?.headers?.orderAmountInWork || 0}
            </div>
            <div className="text">
              Сумма заказов <br /> в работе
            </div>
          </StatCard>
        </StatCardList>

        <TableWrapper>
          <TableHeader>
            <TH>Номер</TH>
            <TH
              onClick={() => sortHandle("name")}
              asc={sort.includes("name")}
              desc={sort.includes("-name")}
            >
              <div className="text">Название объекта</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => sortHandle("customer")}
              asc={sort.includes("customer")}
              desc={sort.includes("-customer")}
            >
              <div className="text">Заказчик</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => sortHandle("total")}
              asc={sort.includes("total")}
              desc={sort.includes("-total")}
            >
              <div className="text">Всего заказов</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => sortHandle("vehicle")}
              asc={sort.includes("vehicle")}
              desc={sort.includes("-vehicle")}
            >
              <div className="text">Всего техники</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => sortHandle("sum")}
              asc={sort.includes("sum")}
              desc={sort.includes("-sum")}
            >
              <div className="text">Итого</div>
              <DropDown />
            </TH>
          </TableHeader>
          <TBody>
            {objSuccess && sortData().length === 0 && (
              <EmptyDashboardTable text="Мы не смогли найти объекты" />
            )}
            {sortData()?.map(
              (
                {
                  id,
                  title,
                  latitude,
                  longitude,
                  address,
                  createdAt,
                  updatedAt,
                  orders,
                  customer,
                  sum,
                  ordersAmount,
                  vehiclesAmount,
                }: any,
                index: number,
              ) => (
                <TRow
                  key={`tRow_${index}`}
                  onClick={() => navigate(`/dashboard/manager/${id}`)}
                >
                  <TData>#{id}</TData>
                  <TData>{title}</TData>
                  <TData>{customer}</TData>
                  <TData>{ordersAmount}</TData>
                  <TData>{vehiclesAmount}</TData>
                  <TData>{thousandSeparator(sum)}</TData>
                </TRow>
              ),
            )}
          </TBody>
        </TableWrapper>
      </Section>
      <Section noPadding>
        {!!objects?.objects && (
          <YMaps query={{ lang: "ru_RU" }}>
            <Map
              width={"100%"}
              height={"100%"}
              defaultState={{
                center,
                zoom: 9,
              }}
              style={{
                borderRadius: "8px !important",
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Placemark
                width="0px"
                height="0px"
                options={{
                  preset: "islands#circleIcon",
                  iconColor: "#423189",
                  draggable: false,
                  hideIconOnBalloonOpen: false,
                  hasBalloon: true,
                  balloonShadow: false,
                  balloonPanelMaxMapArea: 0,
                }}
                geometry={{
                  type: "Point",
                  coordinates: center,
                }}
                properties={{
                  iconContent: `
                          <div class="myIconContentBoxAnimation">
                            <div class="first-circle"></div>
                            <div class="second-circle"></div>
                            <div class="third-circle"></div>
                            <div class="forth-circle"></div>
                            <div class="core-circle"></div>
                          </div>`,
                }}
              />
              {coords.map(({ id, coord }) => (
                <Placemark
                  key={id}
                  onClick={() => {
                    navigate(`/dashboard/manager/${id}`);
                  }}
                  width="0px"
                  height="0px"
                  options={{
                    preset: "islands#circleIcon",
                    iconColor: styles.mainColor,
                    draggable: false,
                    hideIconOnBalloonOpen: false,
                    hasBalloon: true,
                    balloonShadow: false,
                    balloonPanelMaxMapArea: 0,
                  }}
                  geometry={{
                    type: "Point",
                    coordinates: coord,
                  }}
                  properties={{
                    iconContent: `
                      <div class="myIconContentBoxAnimation mainColor">
                        <div class="core-circle mainColor"></div>
                      </div>
                    `,
                  }}
                />
              ))}
              <ZoomControl options={{ position: { right: 10, top: 20 } }} />
            </Map>
          </YMaps>
        )}
      </Section>
    </PageWrapper>
  );
};

export default memo(DashboardObjects);
