import { GoBack } from "./../../components/pageContainer/styles";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { styles } from "../../components/styles/styles";
import { Button } from "../../components/form/styles";

export const MainWrapper = styled.div<{ navbar?: number; footer?: number }>`
  /* width: 100%; */
  /* display: flex; */
  /* min-height: calc(100vh - 80px); */

  width: 100%;
  display: flex;
  min-height: calc(
    100vh - ${({ navbar, footer }) => Number(footer) + Number(navbar) || 0}px
  );
  flex-wrap: wrap;

  .row2 {
    width: 50%;
    background-color: white;
    /* position: absolute; */
    /* left: 0; */
    height: 100%;
  }

  .row3 {
    width: 50%;
    height: 100%;
    //padding: 110px 0 0 25px;
    img {
      /* position: absolute; */
      /* right: 0; */
      width: 100%;
      /* height: calc(100vh - 165px); */
      height: 100%;
      object-fit: cover;
      object-position: center;
      /* object-position: left;
      object-position: right; */
    }
  }

  @media only screen and (max-width: 998px) {
    img {
      display: none;
    }

    .row2 {
      width: 100%;
    }
    .row3 {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    min-height: 100vh;
  }
`;

export const MapWrapper = styled.div`
  width: 50%;
`;

export const Wrapper = styled.div<{ half?: boolean }>`
  padding: 40px 0;
  /* padding-left: 150px; */
  /* display: flex; */
  /* width: ${({ half }) => (half ? "50%" : "100%")}; */
  width: 656px;
  margin: auto;

  /* @media screen and (max-width: 1440px) {
    padding: 40px 0;
  } */

  @media screen and (min-width: 1920px) {
    padding: 80px 0;
    padding-left: 140px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -0.1px;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  width: 100%;
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 18px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
`;

export const InputWrapper = styled.label<{ active?: boolean }>`
  width: 100%;
  background-color: white;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "var(--color-light-gray)"};
  display: block;

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  :not(.date-input) input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
    overflow: hidden;
  }

  .ant-picker {
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 16px;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;
      }
    }
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 0 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;

export const AddCardsWrapper = styled.div`
  width: 648px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
`;

export const AddCardWrapper = styled.div`
  width: 304px;
  /* background-color: lightblue; */
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;
  button {
    padding: 20px 21px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
  }
`;

export const AddIconPart = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddTitle = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 8px;
`;

export const AddDesc = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.15px;
  margin-bottom: 24px;
  color: #757575;
`;

export const VehicleBox = styled.div`
  padding: 34px 0 0;
  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }
`;

export const VehicleList = styled.div``;

export const VehicleItem = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0;
  cursor: pointer;
  color: black;
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &:last-child {
    border: 0;
  }
  .data {
    margin-right: auto;
    div:first-child {
      margin-bottom: 4px;
    }
  }
  .year {
    opacity: 0.5;
    font-size: 12px;
    line-height: 1;
    color: #222222;
  }
  .data-line {
    /* font-weight: 400;
    font-size: 14px;
    line-height: 17px; */
    margin-right: auto;

    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }
  .number {
    color: black;
  }
  .info-required {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #ff3358;
    margin-right: 10px;
  }
  .arrow-right {
    display: flex;
    margin-left: 10px;
  }
`;

export const VehicleMainTitle = styled.div`
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  button {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #f63;
    margin-left: auto;
    border-radius: 10px;
    border: solid 1px rgba(255, 102, 51, 0);
    background-color: rgba(255, 102, 51, 0.16);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
`;

export const GoBackBtn = styled(GoBack)`
  padding: 9px 15px 9px 21px;
  span {
    margin-right: 10px;
  }
  svg {
    margin: 0;
  }
`;

export const SkipWrapper = styled.div`
  margin-top: 40px;
`;

export const SkipText = styled.div`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 1.2;
  color: #000;
  width: 464px;
`;

export const SkipBtn = styled(Button)`
  width: 296px;
  padding: 20px;
  margin: 0;
`;
