// const ENDPOINT = "http://r.rukki.pro";
// const ENDPOINT = "http://localhost:3001";
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
// const ENDPOINT = "https://api.rukki.pro";
const ENDPOINT_FOR_IMG = "https://dev.rukki.pro";
// const WS_URL = "ws://localhost:3001";
const WS_URL = process.env.REACT_APP_ENDPOINT;
const CHATS_URL = ENDPOINT?.replace("https", "wss");
// const WS_URL = "https://api.rukki.pro";
const BASE_URL = `${ENDPOINT}/api`;
const GEOCODE_MAPS = "https://geocode-maps.yandex.ru/1.x/";
// const YANDEX_PLACE_APIKEY = "7aa7a573-3eaa-4e8cj-a854-eb997563c59d";
const YANDEX_PLACE_APIKEY = "83521ae3-4f3e-491a-b483-7ccb9b1d163e";
// const YANDEX_PLACE_APIKEY = "735edeff-1af4-4fa1-8dc8-0bb464809350";
// process.env.REACT_APP_YANDEX_PLACE_APIKEY;

export {
  ENDPOINT,
  ENDPOINT_FOR_IMG,
  BASE_URL,
  GEOCODE_MAPS,
  YANDEX_PLACE_APIKEY,
  WS_URL,
  CHATS_URL,
};

