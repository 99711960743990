import styled from "styled-components";

export const Wrapper = styled.button<{ open: boolean }>`
  padding: 8px;
  align-items: center;
  height: 40px;
  border: 1px solid #00000019;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: -0.25px;
  color: #222222;
  font-weight: normal;
  flex-wrap: nowrap;
  min-width: 86px;
  width: fit-content;
  outline: none;
  transition: all 0.3s linear;

  &:focus {
    border: 1px solid #757575;
  }

  .value {
    display: flex;
    gap: 4px;
    align-items: center;

    svg {
      transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
      transition: all 0.2s linear;
    }
  }

  .dropdown {
    position: absolute;
    width: fit-content;
    left: -2px;
    top: 54px;
    transition: all 0.3s linear;
    background-color: #ffffff;
    border: solid 1px #e8e8e8;
    box-shadow: 0 2px 48px 0 #00000028;
    border-radius: 16px;
    padding: 11px 0;
    flex-direction: column;
    display: ${({ open }) => (open ? "flex" : "none")};
    animation: growDown 0.3s ease-in-out;
    transform-origin: top center;

    .dropdownItem {
      display: flex;
      gap: 16px;
      height: 30px;
      align-items: center;
      padding: 7px 8px;
      margin: 0 8px;
      background: #ffffff;
      font-size: 15px;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
      transition: all 0.3s linear;
      cursor: pointer;
      border-radius: 4px;

      .code {
        font-size: 16px;
        line-height: 1;
        display: flex;
        align-items: center;
        gap: 4px;
        width: 70px;
      }

      &:hover {
        background-color: rgba(255, 102, 51, 0.24);
        color: #ff6633;
      }
    }

    .active {
      background-color: rgba(255, 102, 51, 0.24);
      color: #ff6633;
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;
