import actionTypes from "../../constants/actionTypes";

const { _PENDING, _FULFILLED, _REJECTED } = actionTypes;

const defaultState = {
  loading: false,
  success: false,
  error: false,
  data: null,
};

export const promiseReducer = (actionType: string) => {
  return (state: any = defaultState, action: any) => {
    switch (action.type) {
      case `${actionType}${_PENDING}`:
        return {
          loading: true,
          success: false,
          error: false,
          data: state.data,
        };
      case `${actionType}/fulfilled`:
        return {
          loading: false,
          success: true,
          error: false,
          data: action.payload,
        };
      case `${actionType}${_FULFILLED}`:
        return {
          loading: false,
          success: true,
          error: false,
          data: action.payload?.data,
        };
      case `${actionType}${_REJECTED}`:
        return {
          loading: false,
          success: false,
          error: true,
          data: null,
        };
      default:
        return state;
    }
  };
};
