import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 984px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
`;
