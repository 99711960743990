import styled from "styled-components";

export const Wrapper = styled.div<{
  menuOpen: boolean;
  bold?: boolean;
  width?: string;
  paddingTop?: string;
  margin?: string;
}>`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  ${({ width }) => width && `max-width: ${width}`};
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : "31px")};
  position: relative;
  ${({ menuOpen }) => menuOpen && "z-index: 10"};
  flex-wrap: wrap;
  font-family: "Rubik";
  ${({ margin }) => margin && `margin: ${margin}`};

  .buttonContainer {
    margin-left: auto;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  .text {
    font-size: ${({ bold }) => (bold ? "36px" : "24px")};
    font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
    font-stretch: normal;
    font-style: normal;
    line-height: ${({ bold }) => (bold ? "1.11" : "1")};
    letter-spacing: ${({ bold }) => (bold ? "-0.5px" : "-0.1px")};
    text-align: center;
    color: #000;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    font-family: "Rubik";
  }

  .menuBackground {
    position: fixed;
    z-index: 9;
    opacity: 0.64;
    background-color: #000;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 117px;
    ${({ menuOpen }) => !menuOpen && "display: none"};
  }

  .menuIcon {
    display: none;
  }

  .arrowIcon {
    display: none;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: unset;
    padding: 16px;
    background: #ffffff;

    .arrowIcon {
      display: block;
      cursor: pointer;
    }

    .menuIcon {
      display: block;
      margin-left: auto;
    }

    .text {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #000;
    }

    div:not(.text, .dropdownMenu, .menuItem, .menuBackground) {
      display: none;
    }
  }
`;

export const DropdownMenu = styled.div<{ menuOpen: boolean }>`
  position: absolute;
  background: #ffffff;
  border-radius: 0 0 16px 16px;
  z-index: 10;
  width: 100%;

  left: 0;
  top: 56px;
  border: 1px solid #e8e8e8;
  display: ${({ menuOpen }) => (menuOpen ? "flex" : "none")};
  flex-direction: column;
  gap: 16px;
  padding: 16px;

  .menuItem {
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }
`;
