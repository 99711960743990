import { BigTitle, SmallTitleSameHeight, Text16 } from "./../styles/styles";
import { Button } from "./../form/styles";
import styled from "styled-components";
import { styles } from "../styles/styles";

export const SidebarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 392px;
  max-width: 392px;
  min-width: 320px;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;

  @media screen and (max-width: 998px) {
    width: 320px;
  }
`;

export const SwitchRoleBox = styled.div`
  width: 100%;
  margin-top: 10px;
  display: none;

  @media only screen and (max-width: 720px) {
    display: block;
  }

  @media only screen and (max-width: 420px) {
    margin: 0;
  }
`;

export const UserDataBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  button {
    padding: 6px 21px;
    margin: 0;
  }

  @media only screen and (max-width: 576px) {
    margin: 25px 0 0;
    padding-bottom: 0;
  }

  @media only screen and (max-width: 420px) {
    padding: 0 10px 0;
    margin: 1px 0 0;
  }
`;

export const UserData = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 16px 0 0 8px;
`;

export const Btn = styled(Button)`
  display: ${({ mobile }: { mobile: any }) => (mobile ? "none" : "block")};

  @media only screen and (max-width: 720px) {
    display: ${({ mobile }: { mobile: any }) => (mobile ? "non" : "none")};
  }

  @media only screen and (max-width: 420px) {
    width: 100%;
  }
`;

export const Role = styled.div`
  font-size: 12px;
  line-height: 1;
  color: #757575;
  margin-bottom: 8px;
`;

export const RoleSkelet = styled(Role)``;

export const FullName = styled.div`
  font-size: 16px;
  color: #222222;
  padding-bottom: 2px;
`;

export const FullNameSkelet = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: 4px 0 0;
`;

export const RatingBox = styled.div`
  align-items: flex-end;
  display: flex;

  .ant-rate-star,
  .ant-rate-star-full {
    margin-right: 4px;
  }
`;

export const RatingPart = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;

  span {
    font-size: 24px;
    line-height: 1;
    letter-spacing: -0.1px;
  }
`;

export const RateNumber = styled(SmallTitleSameHeight)`
  margin-left: 7px;
  margin-bottom: 4px;
`;

export const UserAvatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (max-width: 1250px) {
    width: 60px;
    height: 60px;
  }
  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const UserAvatarSkelet = styled.div`
  width: 80px;
  height: 80px;

  @media only screen and (max-width: 1250px) {
    width: 60px;
    height: 60px;
  }
`;

export const SwitchToBtn = styled.div`
  width: 100%;
  padding: 14px 21px;
  text-align: center;
  background-color: ${styles.mainColor};
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: white;
  display: none;
  @media only screen and (max-width: 576px) {
    display: block;
  }
`;

export const NavsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media only screen and (max-width: 576px) {
    display: none;
  }
`;

export const NavItem = styled.div`
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  color: black;
  padding: 8px;
  background: ${({ active, slash }: { active: boolean; slash?: boolean }) =>
    active || slash ? "#FCE1D8" : "transparent"};
  border-radius: 10px;
  align-items: center;
  transition: all 0.3s linear;

  p {
    color: black;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  div {
    display: flex;
  }

  @media only screen and (min-width: 1440px) {
    padding: 8px;
  }
  @media only screen and (max-width: 576px) {
    padding: 14px 0;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
    background: transparent;
  }
`;

export const SwitchRoleWrapperXL = styled.div<{
  active?: boolean;
  width?: string;
  height?: string;
}>`
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  height: ${({ height }) => (height ? height : "38px")};
  width: ${({ width }) => (width ? width : "100%")};
  padding: 4px 5px;
  border-radius: 8px;
  border: 1px solid #c8c8c8;
  gap: 8px;
  justify-content: space-between;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    transition: all 0.3s linear;
    color: #757575;
    padding: 5px 0 5px 0;
    border-radius: 4px;
    cursor: pointer;
    word-wrap: nowrap;
    white-space: nowrap;
    ${({ height }) => !height && "height: 28px"};

    &:not(.active):hover {
      background: #f7f4f1;
    }
  }
  .active {
    background-color: ${styles.mainColor};
    color: #fdfdfd;
  }
`;
