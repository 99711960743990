import React, { memo, useEffect, useState } from "react";
import {
  CalendarWrapper,
  Section,
  StatCard,
  StatCardList,
  FilterWrapper,
  AddObj,
  TimeList,
  TimeItem,
  FilterBtn,
  FilterText,
  TableHeader,
  TableWrapper,
  TBody,
  TData,
  TH,
  TRow,
} from "../dashboardObjects/styles";
import { ReactComponent as Plus } from "../../assets/icons/icons-add.svg";
import { ReactComponent as DropDown } from "../../assets/icons/icons-arrow-drop-down.svg";
import vehicleIcon1 from "../../assets/icons/icons-vehicles-excavator.svg";
import vehicleIcon2 from "../../assets/icons/icons-vehicles-beton.svg";
import vehicleIcon3 from "../../assets/icons/icons-vehicles-hoist.svg";
import vehicleIcon4 from "../../assets/icons/icons-vehicles-musor.svg";
import vehicleIcon5 from "../../assets/icons/icons-vehicles-wheelbarrow.svg";
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import getManagers from "../../redux/modules/getManagers";
import { getRandomNumberFloat, thousandSeparator } from "../../utils/numbers";
import { staffList, StaffType } from "../../constants/mockData";
import moment from "moment";
import { DatePicker } from "antd";
import AddManagerPopUp from "../../components/popUp/addManagerPopUp";
import { Dropdown } from "../dashboardOrders";
import { removeDuplicates } from "../../utils/uiHelpers";
import getDashboardOrders from "../../redux/modules/getDashboardOrders";
import getDashboardSingleObject from "../../redux/modules/getDashboardSingleObject";
import getDashboardObjects from "../../redux/modules/getDashboardObjects";
import EmptyDashboardTable from "../../components/emptyPage/emptyDashboardTable";
import { MinusOutlined } from "@ant-design/icons";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";

const { RangePicker } = DatePicker;

const DashboardManagers: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vehicleList = [
    vehicleIcon1,
    vehicleIcon2,
    vehicleIcon3,
    vehicleIcon4,
    vehicleIcon5,
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { objId, id } = useParams();
  const [addManagerPopUp, setAddManagerPopUp] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const data = useAppSelector((state) => state.managers.data);

  const [sort, setSort] = useState<string[]>([]);
  const [coords, setCoords] = useState<
    { id: string; coord: number[]; icon: any }[]
  >([]);
  const refId = user?.customer?.refId;
  const [customerFilter, setCustomerFilter] = useState<string[]>([]);
  const [vehicleFilter, setVehicleFilter] = useState<string[]>([]);
  const [staffFilter, setStaffFilter] = useState<string[]>([]);

  const [dateFilter, setDateFilter] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
  }>({ from: null, to: null });

  const { data: managers, success: managersSuccess } = useAppSelector(
    (state) => state.managers,
  );
  const { data: singleObject } = useAppSelector((state) => state.singleObject);

  const [filterParams, setFilterParams] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
    customers: string[] | null;
    vehicle: string[] | null;
    staff: string[] | null;
  }>({ from: null, to: null, customers: null, vehicle: null, staff: null });

  const sortHandle = (name: string) => {
    if (sort[0] === name) {
      setSort([`-${name}`]);
    } else {
      setSort([name]);
    }
  };

  const sortData = (): StaffType[] => {
    const staffList = (objId === "all" ? data : singleObject?.users)?.map(
      ({
        id,
        refId,
        name,
        inWork,
        orderAmount,
        orderTotal,
        doneAmount,
        phoneNumber,
        status,
      }: any) => ({
        id,
        fullName: name,
        phoneNumber,
        totalAmount: orderTotal,
        activeOrdersCount: inWork,
        allOrdersCount: orderTotal,
        allTotalAmount: doneAmount || 0,
        objectId: 0,
        orderAmount: orderAmount,
      }),
    );

    return staffList;
  };

  useEffect(() => {
    if (refId) {
      dispatch(getManagers(refId, { filterParams, sort }));
    }
  }, [refId, sort, addManagerPopUp, filterParams]);

  useEffect(() => {
    if (refId && objId !== "all") {
      dispatch(getDashboardSingleObject(refId, objId));
    }
  }, [refId]);

  const isActiveDate = (type: string): boolean => {
    switch (type) {
      case "today":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().endOf("day").diff(dateFilter.to) === 0
        );
      case "tomorrow":
        return (
          moment().add(1, "day").startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(1, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "week":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(7, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "month":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(30, "day").endOf("day").diff(dateFilter.to) === 0
        );
      default:
        return false;
    }
  };

  const handleFilterDate = ({
    type,
    from,
    to,
  }: {
    type?: string | null;
    from?: moment.Moment | null;
    to?: moment.Moment | null;
  }) => {
    switch (type) {
      case "today":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().endOf("day"),
        });
        break;
      case "tomorrow":
        setDateFilter({
          from: moment().add(1, "day").startOf("day"),
          to: moment().add(1, "day").endOf("day"),
        });
        break;
      case "week":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(7, "day").endOf("day"),
        });
        break;
      case "month":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(30, "day").endOf("day"),
        });
        break;
      default:
        setDateFilter({
          from: from || null,
          to: to || null,
        });
        break;
    }
  };

  const handleFilter = () => {
    setFilterParams({
      ...filterParams,
      from: dateFilter?.from,
      to: dateFilter?.to,
      customers: customerFilter.length > 0 ? customerFilter : null,
      vehicle: vehicleFilter.length > 0 ? vehicleFilter : null,
      staff: staffFilter.length > 0 ? staffFilter : null,
    });
  };

  useEffect(() => {
    let data = searchParams.get("data");
    if (data) {
      data = JSON.parse(data);
      if (Array.isArray(data)) {
        data.forEach(({ email, full_name, phone_number }) => {
          const obj = {
            id: Math.max(...staffList.map(({ id }) => id)) + 1,
            fullName: full_name,
            phoneNumber: phone_number,
            totalAmount: 0,
            activeOrdersCount: 0,
            allOrdersCount: 0,
            allTotalAmount: 0,
            customer: staffList[0]?.customer,
            address: staffList[0]?.address,
            objectId: staffList[0]?.objectId,
            orderAmount: 0,
          };
          staffList.push(obj);
        });
        setSearchParams({});
      }
    }
  }, []);

  useEffect(() => {
    if (refId) {
      dispatch(getDashboardObjects(refId));
      dispatch(getDashboardOrders(refId));
    }
  }, [refId]);

  return (
    <PageWrapper gap="0" minHeight="calc(100vh - 65px)" noWidth>
      <Section>
        <PageHeader
          button={
            objId === "all" ? (
              <AddObj
                onClick={() => {
                  setAddManagerPopUp(true);
                }}
              >
                <Plus />
                Добавить сотрудника
              </AddObj>
            ) : (
              <></>
            )
          }
        >
          {objId === "all" ? "Сотрудники" : singleObject?.object?.title}
        </PageHeader>
        <StatCardList>
          <StatCard onClick={() => navigate(`/dashboard/manager/${objId}`)}>
            <div className="number">
              {(objId === "all" ? data : singleObject)?.headers
                ?.totalCustomers || 0}
            </div>
            <div className="text">
              Всего <br /> менеджеров
            </div>
          </StatCard>
          <StatCard onClick={() => navigate("/dashboard/order")}>
            <div className="number">
              {(objId === "all" ? data : singleObject)?.headers?.inWork || 0}
            </div>
            <div className="text">
              Заказов <br /> в работе
            </div>
          </StatCard>
          <StatCard>
            <div className="number">
              {(objId === "all" ? data : singleObject)?.headers?.startToday ||
                0}
            </div>
            <div className="text">
              Заказов <br /> начинаются сегодня
            </div>
          </StatCard>
          <StatCard className="amountInWork">
            <div className="number">
              {(objId === "all" ? data : singleObject)?.headers?.inWorkAmount ||
                0}
            </div>
            <div className="text">
              Сумма заказов <br /> в работе
            </div>
          </StatCard>
        </StatCardList>
        <TableWrapper>
          <TableHeader>
            <TH
              onClick={() => {
                sortHandle("name");
              }}
              asc={sort?.includes("name")}
              desc={sort?.includes("-name")}
            >
              <div className="text">Имя</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("phone_number");
              }}
              asc={sort.includes("phone_number")}
              desc={sort.includes("-phone_number")}
            >
              <div className="text">Номер телефона</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("sum");
              }}
              asc={sort.includes("sum")}
              desc={sort.includes("-sum")}
            >
              <div className="text">Сумма заказов</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("in_work");
              }}
              asc={sort.includes("in_work")}
              desc={sort.includes("-in_work")}
            >
              <div className="text">Заказов в работе</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("total");
              }}
              asc={sort.includes("total")}
              desc={sort.includes("-total")}
            >
              <div className="text">Кол-во заказов</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("sum_done");
              }}
              asc={sort.includes("sum_done")}
              desc={sort.includes("-sum_done")}
            >
              <div className="text">Сумма выполненных всего</div>
              <DropDown />
            </TH>
          </TableHeader>
          <TBody>
            {managersSuccess && sortData()?.length === 0 && (
              <EmptyDashboardTable text="Мы не смогли найти сотрудников" />
            )}
            {sortData()?.map(
              ({
                id,
                fullName,
                phoneNumber,
                totalAmount,
                activeOrdersCount,
                allOrdersCount,
                allTotalAmount,
                objectId,
                orderAmount,
              }) => (
                <TRow
                  onClick={() => navigate(`/dashboard/manager/${objId}/${id}`)}
                >
                  <TData>{fullName}</TData>
                  <TData>{phoneNumber}</TData>
                  <TData>{thousandSeparator(orderAmount)}</TData>
                  <TData>{activeOrdersCount}</TData>
                  <TData>{allOrdersCount}</TData>
                  <TData>{thousandSeparator(allTotalAmount)}</TData>
                </TRow>
              ),
            )}
          </TBody>
        </TableWrapper>
      </Section>

      <Section>{!id ? <></> : <Outlet />}</Section>
      {addManagerPopUp && (
        <AddManagerPopUp
          close={() => {
            setAddManagerPopUp(false);
          }}
        />
      )}
    </PageWrapper>
  );
};

export default memo(DashboardManagers);
