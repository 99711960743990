import React, { memo, useCallback, useEffect, useState } from "react";
import { MessagePart, SidePart, UserItemBox, Wrapper } from "./styles";
import pattern from "../../assets/images/pattern.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import MessagesEmptyPage from "../../components/emptyPage/messagesEmptyPage";
import Loading from "../../components/loading";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";
import { Socket, io } from "socket.io-client";
import MessagesList from "../../components/messagesList";
import CounterBadge from "../../UI/CounterBadge";
import { authActions, getUser } from "../../redux/store/reducers/authSlice";
import * as MagicBytes from "magic-bytes.js";
import { IUserView } from "../../types";
import getManagers from "../../redux/modules/getManagers";
import moment from "moment";
import { CHATS_URL } from "../../constants/general";

export interface Message {
  id: number;
  text: string;
  is_system: 0 | 1;
  sender_id: number;
  chat_id: number;
  frontend_id: string;
  created_at: string;
  updated_At: string | null;
  file_object_name: string | null;
  is_file: 0 | 1;
  is_read: boolean;
}

export interface Chat {
  id: number;
  title: string;
  order_id: number | null;
  unread_messages: number;
}

export interface ChatMember {
  chat_id: number;
  id: number;
  is_hidden: 0 | 1;
  member_id: number;
  unread_message_ids: number[];
}

export type SetMessageFileState = {
  id: number;
  fileUrl: string;
  type: string;
};

export type SetMessageFilesDispatch = React.Dispatch<
  React.SetStateAction<SetMessageFileState[]>
>;

let staticChatId: number | null = null;



const MessagesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const accessToken = useAppSelector(
    (state: RootState) => state.auth.accessToken,
  );
  const initChatData = useAppSelector(
    (state: RootState) => state.auth.initChatData,
  );
  const [currentChatId, setCurrentChatId] = useState<number | null>(null);
  const [chats, setChats] = useState<Chat[] | null>(null);
  const [chatsMembers, setChatsMembers] = useState<Map<number, IUserView[]>>(
    new Map(),
  );
  const [messageFiles, setMessageFiles] = useState<SetMessageFileState[]>([]);
  const [chatMessages, setChatMessages] = useState<Map<number, Message[]>>(
    new Map(),
  );
  const [socket, setSocket] = useState<Socket | null>(null);
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const [chatMemberToDisplay, setChatMemberToDisplay] =
    useState<IUserView | null>(null);

  useEffect(() => {
    if (user?.customer?.refId && !socket) {
      const newSocket = io(`${CHATS_URL}/a-chats`, {
        extraHeaders: {
          token: `${accessToken}`,
          ref: user?.customer?.refId!,
          autojoin: "true",
        },
        transports: ["polling"],
      });
      setSocket(newSocket);
    }
  }, [user]);

  useEffect(() => {
    if (socket) {
      if (initChatData) {
        socket.emit("createChat", initChatData);
      }

      socket.on("chatCreated", (response) => {
        socket.emit("collectChats");
        dispatch(authActions.setInitChatData(null));
      });

      socket.on("availableChats", (response: Chat[]) => {
        setChats(response);
        for (const chat of response) {
          socket.emit("collectChatMembers", { chatId: chat.id });
          socket.emit("collectMessages", {
            chat: chat.id,
            options: { take: 50, skip: 0 },
          });
        }
      });

      socket.on("chatMembers", async (membersResponse: ChatMember[]) => {
        if (membersResponse.length > 0) {
          const currentChatMembers = chatsMembers.get(
            membersResponse[0].chat_id,
          );
          if (
            !currentChatMembers ||
            (currentChatMembers &&
              currentChatMembers?.length !== membersResponse.length)
          ) {
            const newChatMembers: IUserView[] = await Promise.all(
              membersResponse.map(async (member) => {
                const newMember = await fetchChatMemberData(member);
                return newMember;
              }),
            );

            if (newChatMembers.length === membersResponse.length) {
              setChatsMembers((prev) => {
                let newValue = prev;
                newValue.set(membersResponse[0].chat_id, newChatMembers);
                return new Map(newValue);
              });
            }
          }
        }
      });

      socket.on(
        "chatMessages",
        (response: { chatId: number; messages: Message[] }) => {
          setChatMessages((prev) => {
            let newValue = prev;
            newValue.set(response.chatId, response.messages);
            return new Map(newValue);
          });
        },
      );

      socket.on("successMessagesRead", ({ chat }: { chat: number }) => {
        socket.emit("collectMessages", {
          chat: chat,
          options: { take: 50, skip: 0 },
        });
      });

      socket.on("newMessage", (message: Message) => {
        if (staticChatId === message.chat_id) {
          socket.emit("readAllChatMessages", {
            chatId: staticChatId,
          });
        }
        if (
          message.is_file &&
          messageFiles.filter((messageFile) => messageFile.id === message.id)
            .length === 0
        ) {
          getFileContent(message.id);
        }
        socket.emit("collectChats");
        if (
          chats &&
          message.sender_id !== user?.customer.id &&
          !message.is_read
        ) {
          setChats((prev) => {
            let newValue = prev;
            let newChatIndex = newValue?.findIndex(
              (chat) => chat.id === message.chat_id,
            );
            if (newChatIndex && newValue !== null) {
              newValue[newChatIndex] = {
                ...newValue[newChatIndex],
                unread_messages: newValue[newChatIndex].unread_messages++,
              };
            }
            return newValue;
          });
        }

        setChatMessages((prev) => {
          let newValue = prev;
          let newMessages = newValue.get(message.chat_id);

          if (newMessages) {
            newMessages.push(message);
            newValue.set(message.chat_id, newMessages);
          }

          return new Map(newValue);
        });
      });

      socket.on("textSent", (response) => {
        socket.emit("collectChats");
      });

      socket.on("chatCreated", (response) => {
        socket.emit("collectChats");
      });

      socket.io.on("reconnect_error", (error) => {
        console.error(error);
      });

      socket.on("error", (error) => {
        console.error(error);
      });

      socket.on("connect", () => {
        socket.emit("collectChats");
      });

      socket.on("addChatMember", () => {
        socket.emit("collectChatMembers", { chatId: currentChatId });
      });
    }
  }, [socket]);

  const getFileContent = (messageId: number) => {
    socket!.emit(
      "getFileContent",
      { messageId, ack: true },
      (response: { binary: Uint8Array; base64: string }) => {
        setMessageFiles((prev) => {
          const newValue = prev;
          newValue.push({
            id: messageId,
            fileUrl: response.base64,
            type: MagicBytes.filetypemime(response.binary.slice(0, 20))[0],
          });
          return newValue;
        });
      },
    );
  };

  useEffect(() => {
    if (chatsMembers.size === chats?.length) {
      setReadyToRender(true);
    }
  }, [chatsMembers, chats]);

  useEffect(() => {
    if (currentChatId !== null) {
      socket!.emit("readAllChatMessages", { chatId: currentChatId });
    }
  }, [currentChatId]);

  if (currentChatId !== null) {
    staticChatId = currentChatId;
  }

  const fetchChatMemberData = async (member: ChatMember) => {
    const memberData = await dispatch(getUser({ id: member.member_id }));
    return memberData.payload as IUserView;
  };

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getManagers(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  const getMessageDate = (messageDateString: string) => {
    const messageDate = moment(messageDateString);
    const todayDate = moment();
    if (messageDate.day() === todayDate.day()) {
      return messageDate.format("H:mm");
    } else if (messageDate.isSame(todayDate.subtract(1, "day"), "day")) {
      return messageDate.format("вчера H:mm");
    } else {
      return messageDate.format("DD.MM H:mm");
    }
  };

  const renderChats = useCallback(
    (chats: Chat[]) => {
      if (readyToRender) {
        return chats.map(({ id, title, unread_messages, order_id }, index) => {
          const messages = chatMessages.get(id);
          const lastMessage = messages ? messages[messages.length - 1] : null;
          const members = chatsMembers.get(id);
          let chatMemberInfo: IUserView | undefined;
          if (members && members?.length === 2) {
            chatMemberInfo = members?.find(
              (member) => member.id !== user?.customer.id,
            );
          }

          return (
            <UserItemBox
              active={id === currentChatId}
              key={`chat_${index}`}
              onClick={() => {
                setCurrentChatId(id);
                chatMemberInfo && setChatMemberToDisplay(chatMemberInfo);
              }}
            >
              <div className="avatar">
                <img src={pattern} alt="avatar" />
              </div>
              <div className="data">
                <div className="title">
                  <div className="full-name">
                    {chatMemberInfo
                      ? `${chatMemberInfo.firstName} ${chatMemberInfo.secondName}`
                      : title}
                    {chatMemberInfo && order_id && (
                      <span className="order-id">{`Заказ №${order_id}`}</span>
                    )}
                  </div>
                  <div className="time"></div>
                </div>
                {lastMessage ? (
                  lastMessage.is_file === 1 ? (
                    <div className="desc">
                      <div className="message">Файл</div>
                      <div className="messageTime">
                        {getMessageDate(lastMessage.created_at)}
                      </div>
                    </div>
                  ) : (
                    <div className="desc">
                      <div className="message">{lastMessage.text}</div>
                      <div className="messageTime">
                        {getMessageDate(lastMessage.created_at)}
                      </div>
                    </div>
                  )
                ) : null}
              </div>
              {messages && unread_messages > 0 ? (
                <CounterBadge value={unread_messages} />
              ) : null}
            </UserItemBox>
          );
        });
      } else {
        return null;
      }
    },
    [chats, chatsMembers, readyToRender, currentChatId],
  );

  return (
    <PageWrapper flexDirection="column" noFooter>
      <PageHeader>Сообщения</PageHeader>
      <Wrapper>
        {chats === null || !readyToRender ? (
          <Loading />
        ) : socket && chats.length > 0 && chatsMembers.size && readyToRender ? (
          <>
            <SidePart open={currentChatId === null}>
              {renderChats(chats)}
            </SidePart>
            <MessagePart open={currentChatId !== null}>
              <MessagesList
                socket={socket}
                chat={chats.find((chat) => chat.id === currentChatId) || null}
                chatId={currentChatId ?? 0}
                messagesState={chatMessages}
                setChatId={setCurrentChatId}
                messageFiles={messageFiles}
                setMessageFiles={setMessageFiles}
                getFileContent={getFileContent}
                chatMemberToDisplay={chatMemberToDisplay}
                chatMembers={
                  currentChatId ? chatsMembers.get(currentChatId)! : []
                }
              />
            </MessagePart>
          </>
        ) : chats?.length === 0 ? (
          <MessagesEmptyPage />
        ) : (
          <Loading />
        )}
      </Wrapper>
    </PageWrapper>
  );
};

export default memo(MessagesPage);

