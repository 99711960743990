import { screen } from "@testing-library/react";
import styled from "styled-components";

import bg from "../../assets/images/bg.png";
import mobileBg from "../../assets/images/mobile-bg.png";

export const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  min-height: 300px;
  background-color: white;
  display: flex;
  position: relative;
`;

export const BG = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${bg});
  background-size: auto 110%;
  background-repeat: no-repeat;
  background-position: 110% center;
  @media only screen and (max-width: 1050px) {
    background-image: url(${mobileBg});
    background-size: auto 100%;
    background-position: 100% center;
  }
`;

export const ButtonWrapper = styled.div`
  /* margin: auto; */
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  /* margin: auto; */
  button {
    margin: 0;
    /* margin-bottom: 15px; */
    &:last-child {
      /* margin-bottom: 0; */
    }
  }
  @media only screen and (max-width: 576px) {
    button {
      padding: 6px 21px;
    }
  }
`;

export const DataPart = styled.div`
  height: fit-content;
  z-index: 3;

  width: 60%;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  margin: auto 0 auto 30px;

  p {
    margin: 0 0 20px 0;
    font-weight: 900;
    font-size: 62px;
    line-height: 68px;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
    margin: auto 0;
    padding: 0 30px;
  }

  @media only screen and (max-width: 576px) {
    padding: 0 15px;
    p {
      font-size: 37px;
      line-height: 43px;
    }
  }
`;
