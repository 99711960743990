import { screen } from "@testing-library/react";
import styled from "styled-components";
import { Button } from "../form/styles";
import { SmallTitle, styles, Text16 } from "../styles/styles";

export const DocLink = styled.a`
  color: #ff6633;

  &:hover {
    color: #ff6633;
    opacity: 0.7;
  }
`;

export const TitleBox = styled.p`
  padding-left: 10px;
  padding-top: 14px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;
`;

export const ButtonContainerRevoke = styled.div`
  padding-left: 10px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: nowrap;
`;

export const ButtonContainer = styled.div`
  padding-left: 10px;
  padding-bottom: 24px;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: start;
  gap: 16px;
`;

export const ButtonConnect = styled.div`
  cursor: pointer;
  display: flex;
  width: 260px;
  height: 48px;
  padding: 12px 16px;
  border-radius: 10px;
  border: solid 1px #e8e8e8;

  img {
    width: 24px;
    height: 24px;
    margin: 0 8px 0 0;
    object-fit: contain;
  }
  .buttonConnect {
    cursor: pointer;
    border: none;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000;
    background-color: transparent;
  }
  .buttonConnectRevoke {
    cursor: pointer;
    background-color: transparent;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000;
    border: none;
  }
`;
export const ButtonRevoke = styled.button`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #757575;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const Subtitle = styled.p`
  padding: 8px 10px 24px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #000;
`;

export const Wrapper = styled.div<{ paddingless?: boolean }>`
  background-color: white;
  padding: ${({ paddingless }) => (paddingless ? "0" : "24px")};
  border-radius: 16px;

  @media only screen and (max-width: 1050px) {
    padding: ${({ paddingless }) => (paddingless ? "0" : "16px")};
  }
`;

export const Title = styled(SmallTitle)`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  .titleGroup {
    display: flex;
    gap: 8px;
  }

  .button {
    width: fit-content;
    display: block;
    padding: 6px 21px;
    margin: 0 0 0 auto;
    font-size: 16px;
    background-color: ${styles.mainColor};
    border-radius: 10px;
    color: white;
    cursor: pointer;

    input {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    label {
      width: 100%;
      margin-top: 15px;
      text-align: center;
    }
  }
`;

export const NavsWrapper = styled.div`
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;

  .title {
    margin: 20px 10px 0;
  }

  @media only screen and (min-width: 1440px) {
    padding: 10px 16px;
  }

  @media only screen and (max-width: 1050px) {
    .title {
      font-size: 25px;
      line-height: 30px;
      margin: 10px 10px 0;
    }
  }
`;

export const NavsList = styled.div`
  margin: 32px 0;

  @media only screen and (max-width: 1050px) {
    margin: 16px 0;
  }
`;

export const NavItem = styled(Text16)`
  margin-bottom: 16px;
  padding: 14px 10px;
  background: ${({ active }: { active?: boolean }) =>
    active ? "#fce1d8" : "transparent"};
  border-radius: 10px;
  color: #222222;
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;

  .pill {
    width: 28px;
    height: 20px;
    border-radius: 10px;
    background-color: ${styles.mainColor};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
  }

  @media only screen and (min-width: 1440px) {
    padding: 12px 8px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1050px) {
    margin-bottom: 8px;
  }
`;

export const UserData = styled.div`
  padding: 0 10px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  margin-top: 23px;

  ${({ _customer }: { _customer?: boolean }) =>
    _customer &&
    `
    flex-flow: row;
    button {
      background-color: transparent;
      color: black;
    }
  
  `}

  button.chat {
    margin-bottom: 15px;
    /* color: ${styles.mainColor}; */
    /* border: 1px solid ${styles.mainColor}; */
    transition-duration: 0.2s;

    &:hover {
      /* background-color: ${styles.mainColor}; */
      /* color: white; */
    }
  }

  .avatar-img {
    width: 45px;
    height: 45px;
    margin-right: 16px;
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  .data {
    flex: 1;
    ${({ _customer }: { _customer?: boolean }) =>
      _customer &&
      `
    flex: none;
    width: auto;
  `}
    padding-right: 20px;

    .first-name {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;

      color: #222222;
      margin-bottom: 2px;
    }

    .customer-name {
      font-weight: 500;
      font-size: 26px;
      line-height: 33px;
      color: #222222;
      margin-bottom: 2px;
    }

    .role {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #222222;

      mix-blend-mode: normal;
      opacity: 0.5;
    }
  }

  .send-sms-icon {
    width: 100%;
    height: fit-content;
    cursor: pointer;
    border-radius: 100%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
    ${({ _customer }: { _customer?: boolean }) =>
      _customer &&
      `
    width: auto;
    margin-top: 0px;
    margin-left: auto;
    
  `}
  }
`;

export const DetailedInfo = styled.div`
  font-weight: 400;
  font-size: 14px;
  width: 100%;
  line-height: 19px;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  color: #808080;
  margin: 0;

  span {
    margin-right: 5px;
  }

  b {
    color: #1a110f;
  }
`;

export const FileItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid lightgray;
  padding: 16px 0;
  gap: 8px;
  transition: all 0.2s linear;
  animation: width-animation 0.15s linear;

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    aspect-ratio: 1 / 1;
    background-color: #f7f4f1;
    border-radius: 4px;
  }

  .orange {
    svg {
      g {
        path {
          fill: "#ff6633" !important;
          color: "#ff6633" !important;
        }
      }
    }
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }

  :hover {
    transform: scale(0.98);
  }

  div {
    width: fit-content;
  }

  .file-name {
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    gap: 8px;

    .text {
      display: flex;
      flex-direction: column;
      overflow: auto;

      .date {
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #757575;
      }
      .name {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: -0.25px;
        color: #000;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        width: 100%;
      }
    }
  }
  .ant-checkbox-wrapper {
    margin-right: 10px;
  }
  .right {
    display: flex;
    margin-left: auto;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;

    .right {
      margin-left: 0;
    }
  }

  @media only screen and (max-width: 576px) {
    flex-wrap: wrap;
    .file-name {
      width: 100%;
    }
  }
`;

export const AlertBadge = styled.div<{ red?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${({ red }) => (red ? "8px" : "12px")};

  .text {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: ${({ red }) => (red ? "rgba(255, 51, 88, 0.8)" : "#757575")};
  }
`;

export const SignButton = styled.div<{ loading?: boolean }>`
  width: 196px;
  height: 32px;
  border-radius: 10px;
  border: solid 1px rgba(255, 102, 51, 0);
  background-color: ${({ loading }) =>
    loading ? "#cccccc" : "rgba(255, 102, 51, 0.16)"};
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 10px 4px 8px;
  cursor: pointer;
  transition: all 0.3s linear;

  svg {
    path {
      transition: all 0.3s linear;
      fill: ${({ loading }) => (loading ? "#666666" : "#ff6633")};
    }
  }

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: ${({ loading }) => (loading ? "#666666" : "#ff6633")};
    transition: all 0.3s linear;
  }
`;

export const SignAllBtn = styled(Button)``;

export const Pending = styled.div`
  padding: 6px 21px;
  border: 1px solid ${styles.mainColor};
  border-radius: 10px;
  color: ${styles.mainColor};
  cursor: pointer;
`;
