import React, { memo, useCallback, useEffect, useState } from "react";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";
import {
  ListItem,
  ListWrapper,
  Wrapper,
} from "../dashboardEmployeesPage/styles";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReactComponent as ProfileIcon } from "../../assets/icons/icon-bottom-profile-active.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-24-basic-chevron-right.svg";
import AddManagementItemEmpty from "../../components/addManagementItemEmpty";
import {
  addVipWorker,
  getVipWorkers,
  deleteVipWorker,
} from "../../redux/store/reducers/companySlice";
import Loading from "../../components/loading";
import { useNavigate } from "react-router-dom";
import { WorkerType } from "../../types";
import AddWorkerSideMenu from "./addWorkerSideMenu";
import Button from "../../UI/Button";
import { getAvatarUrl } from "../../components/proposalItem";

interface DashboardWorkersPageProps {}

const DashboardWorkersPage: React.FC<DashboardWorkersPageProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { workers, getWorkers, createWorker, deleteWorker } = useAppSelector(
    (state) => state.company,
  );
  const user = useAppSelector((state) => state.auth.user);

  const [addWorker, setAddWorker] = useState<boolean>(false);

  const renderWorkers = useCallback(
    (workers: WorkerType[]) => {
      return workers.map((worker: WorkerType, index: number) => {
        return (
          <ListItem
            key={`employee_${index}`}
            onClick={() => {
              navigate(`/user/${worker.workerId}`);
            }}
          >
            {false ? (
              <img
                className="employeeAvarat"
                src={getAvatarUrl(worker?.avatarUrl as string)}
                alt="avatar"
              />
            ) : (
              <ProfileIcon />
            )}
            <div className="userInfo">
              <div>{`${
                worker.companyTitle
                  ? worker.companyTitle
                  : `${worker.firstName && worker.firstName}${
                      worker.secondName &&
                      " " + worker.secondName + " (Физлицо)"
                    }`
              }`}</div>
            </div>
            <div className="rightGroup">
              <ArrowIcon />
            </div>
          </ListItem>
        );
      });
    },
    [workers],
  );

  useEffect(() => {
    user?.customer.refId &&
      dispatch(
        getVipWorkers({
          refId: user?.customer.refId,
        }),
      );
  }, [user]);

  return (
    <PageWrapper flexDirection="column">
      <PageHeader
        width="984px"
        margin="0px auto"
        menuList={[
          {
            label: "Добавить исполнителя",
            onClick: () => setAddWorker(true),
          },
        ]}
        button={
          !!workers.length && (
            <Button
              height="32px"
              width="fit-content"
              onClick={() => setAddWorker(true)}
            >
              Добавить исполнителя
            </Button>
          )
        }
      >
        Избранные исполнители
      </PageHeader>
      <Wrapper>
        {getWorkers !== "succeeded" ? (
          <Loading height="calc(100% - 67px)" title="Загрузка" />
        ) : (
          <ListWrapper style={{ margin: "0 auto" }}>
            {workers.length ? (
              renderWorkers(workers)
            ) : (
              <AddManagementItemEmpty
                workers
                onClick={() => setAddWorker(true)}
              />
            )}
          </ListWrapper>
        )}
      </Wrapper>
      <AddWorkerSideMenu
        customerRefId={user?.customer.refId}
        menuOpen={addWorker}
        toggleOpen={setAddWorker}
      />
    </PageWrapper>
  );
};

export default memo(DashboardWorkersPage);
