import React, { memo, useEffect, useState } from "react";
import {
  AddressBox,
  CharacteristicsBox,
  CheckboxItem,
  DataWrapper,
  Description,
  ForemanContacts,
  ImagesWrapper,
  LabelText,
  ShiftBox,
  Title,
} from "./styles";
import { ReactComponent as Tipper } from "../../assets/icons/tipper.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icon-24-basic-calendar.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/icon-24-intellicon-finance-wallet.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/icon-24-basic-check.svg";
import { Wrapper } from "../../pages/infoAboutOrderPage/styles";
import ImagePreview from "../imagePreview";
import { RootState } from "../../redux/store/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ENDPOINT, ENDPOINT_FOR_IMG } from "../../constants/general";
import moment from "moment";
import useHttpRequest from "../../hooks/useHttpRequest";
import { useParams } from "react-router-dom";
import { getOrderImages } from "../../redux/store/reducers/orderSlice";
import { getRandomNumber, thousandSeparator } from "../../utils/numbers";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import vehicleIcon1 from "../../assets/icons/icons-vehicles-excavator.svg";
import vehicleIcon2 from "../../assets/icons/icons-vehicles-beton.svg";
import vehicleIcon3 from "../../assets/icons/icons-vehicles-hoist.svg";
import vehicleIcon4 from "../../assets/icons/icons-vehicles-musor.svg";
import vehicleIcon5 from "../../assets/icons/icons-vehicles-wheelbarrow.svg";
import PhotoVideoPopUp from "../popUp/photoVideoPopUp";

const OrderDetailedInfo: React.FC<{
  fallbackCoords?: [number, number] | null;
}> = ({ fallbackCoords }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [imgPreview, setImgPreview] = useState({ open: false, url: "" });
  const [worker, setWorker] = useState<any>(null);

  const fireBaseData = useAppSelector(
    (state: RootState) => state.auth.fireBaseData,
  );

  const { singleOrder, images, offers } = useAppSelector(
    (state: RootState) => state.order,
  );

  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const [ymaps, setYmaps] = useState<any>();
  const [center, setCenter] = useState<number[]>([]);
  const [mouseOverTime, setMouseOverTime] = useState(-1);
  const [icon, setIcon] = useState<any>(null);
  const [imagePopUp, setImagePopUp] = useState<boolean>(false);

  const [uploadFileProcess, uploadFileRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(
        getOrderImages({
          refId: fireBaseData?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
    },
  });

  const vehicleList = [
    vehicleIcon1,
    vehicleIcon2,
    vehicleIcon3,
    vehicleIcon4,
    vehicleIcon5,
  ];

  useEffect(() => {
    const w = offers?.find(({ status }: any) => status === "Accepted");
    setWorker(w);
  }, [offers]);

  const workerPath = [
    singleOrder?.coordinates?.map((coord: any) => [
      coord.latitude,
      coord.longitude,
    ]),
  ];
  const trackTimePath = [
    singleOrder?.coordinates?.map((coord: any) => [
      moment(coord.createdAt).calendar(),
    ]),
  ];

  const uploadFile = async (files: any) => {
    if (files.length) {
      const formData = new FormData();
      files.forEach(({ file }: any) => formData.append("", file));
      uploadFileRequest({
        method: "PUT",
        url: `${ENDPOINT}/orders/${fireBaseData?.refId}/${singleOrder?.order?.id}/images`,
        data: formData,
      });
    }
  };

  useEffect(() => {
    const showCoords = workerPath[getRandomNumber(0, workerPath.length - 1)];
    if (showCoords) {
      setCenter(showCoords[showCoords.length - 1]);
      setIcon(vehicleList[getRandomNumber(0, vehicleList.length - 1)]);
    }
  }, [id]);

  const iconPath = vehicleCategories.find(
    ({ title, icon }: { title: any; icon: any }) =>
      title.trim() === singleOrder?.order?.title?.trim() && icon,
  )?.icon;

  const generateTitle = () => {
    const vehicleTitle = vehicleCategories?.find(
      (category: any) => category?.id === singleOrder?.order?.categoryId,
    )?.title;
    const vehicleParameters = singleOrder?.vehicleParams?.length
      ? singleOrder?.vehicleParams?.map(
          (
            parameter: {
              id: number;
              value: string | number;
              measure: string;
              title: string;
            },
            index: number,
          ) => {
            if (parameter?.value) {
              return `${parameter?.value}${
                parameter?.measure ? " " + parameter?.measure : ""
              }`;
            }
          },
        )
      : null;

    return `${vehicleTitle}${
      vehicleParameters?.length ? ", " + vehicleParameters.join(", ") : ""
    }`;
  };

  return (
    <Wrapper id="order_detailed_info">
      <div className="cardHeader">Информация о заказе</div>
      {singleOrder?.order?.description && (
        <>
          <div className="subTitle">Описание</div>
          <div className="cardText">{singleOrder?.order?.description}</div>
        </>
      )}
      <div className="cardText">
        {iconPath ? (
          <img src={`${ENDPOINT_FOR_IMG}/${iconPath}`} alt="" />
        ) : (
          <Tipper />
        )}
        {generateTitle()}
      </div>
      <div className="cardText">
        <WalletIcon />
        {singleOrder?.order?.budget
          ? `${thousandSeparator(singleOrder?.order?.budget)} ₽`
          : "Цена обсуждается"}
      </div>
      <div className="cardText">
        <CalendarIcon />
        {singleOrder?.order?.startDate
          ? moment(singleOrder?.order?.startDate).format("DD.MM.YYYY, HH:mm")
          : "Время будет обсуждаться"}
      </div>

      {singleOrder?.order?.foremanContent && (
        <>
          <div className="title">Контакты прораба</div>
          <div className="cardText">{singleOrder?.order?.foremanContent}</div>
        </>
      )}

      {!!singleOrder?.order?.paymentType && (
        <LabelText half className="box">
          <div className="label">Способ оплаты</div>
          <div className="text">
            {singleOrder?.order?.paymentType === "bn"
              ? "Безналичный"
              : singleOrder?.order?.paymentType === "bnnds"
              ? "Безналичный с НДС"
              : ""}
          </div>
        </LabelText>
      )}

      {!!(singleOrder?.order?.shifts || singleOrder?.order?.accommodation) && (
        <>
          <div className="subTitle">Дополнительная информация:</div>
          {singleOrder?.order?.shifts && (
            <div className="cardText">{`Смен: ${
              singleOrder?.order?.shifts || 0
            }`}</div>
          )}
          {singleOrder?.order?.accommodation ? (
            <div className="cardText">
              <CheckIcon />
              Предоставляется жилье
            </div>
          ) : (
            ""
          )}
          {!!singleOrder?.order?.protection ? (
            <div className="cardText">
              <CheckIcon />
              Охрана
            </div>
          ) : (
            ""
          )}
          {!!singleOrder?.order?.telegram ? (
            <div className="cardText">
              <CheckIcon />
              Связаться с вами в Телеграм
            </div>
          ) : (
            ""
          )}
        </>
      )}
      {images?.length ? (
        <ImagesWrapper>
          {images.map((image: { id: number; path: string }) => {
            return (
              <div className="imageContainer" key={`image_${image.id}`}>
                <img
                  onClick={() =>
                    setImgPreview({
                      open: true,
                      url: `${ENDPOINT}/api/customers/image/${image.path}`,
                    })
                  }
                  src={`${ENDPOINT}/api/customers/image/${image.path}`}
                  alt={image.path}
                />
              </div>
            );
          })}
          {/* {user?.customer?.id === singleOrder?.order?.creatorId && (
          <div
            className="imageContainer add"
            onClick={() => setImagePopUp(true)}
          >
            +
          </div>
        )} */}
        </ImagesWrapper>
      ) : null}

      {imgPreview.open && (
        <ImagePreview
          close={() => setImgPreview({ open: false, url: "" })}
          url={imgPreview.url}
        />
      )}
      {imgPreview.open && (
        <ImagePreview
          close={() => setImgPreview({ open: false, url: "" })}
          url={imgPreview.url}
        />
      )}
      {imagePopUp && (
        <PhotoVideoPopUp
          onSubmit={uploadFile}
          close={() => setImagePopUp(false)}
          defaultPhotoVideo={[]}
        />
      )}
    </Wrapper>
  );
};

export default OrderDetailedInfo;
