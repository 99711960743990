import { Skeleton } from "antd";
import React, { memo } from "react";
import {
  FullNameSkelet,
  RoleSkelet,
  UserAvatarSkelet,
  UserData,
  UserDataBox,
} from "./styles";

const LoaderSideBar = () => {
  return (
    <UserDataBox>
      <UserData>
        <RoleSkelet>
          <Skeleton.Input size="default" active />
        </RoleSkelet>
        <FullNameSkelet>
          <Skeleton.Input size="small" active />
        </FullNameSkelet>
      </UserData>
      <UserAvatarSkelet>
        <Skeleton.Avatar size="large" shape="circle" active />
      </UserAvatarSkelet>
    </UserDataBox>
  );
};

export default memo(LoaderSideBar);
