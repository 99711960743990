import React, { memo, useEffect, useState } from "react";
import {
  Content,
  ContentCard,
  ContentWrapper,
  FeedbackItem,
  PageWrapper,
  Rating,
  VehicleItem,
} from "./styles";
import PageNavigation, { NavigationItem } from "../../UI/PageNavigation";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { authActions, getUser } from "../../redux/store/reducers/authSlice";
import defaultAvatar from "../../assets/images/avatar.png";
import CounterBadge from "../../UI/CounterBadge";
import { ReactComponent as InActiveStar } from "../../assets/icons/icons-rating-large-gray.svg";
import { ReactComponent as ArrowBack } from "../../assets/icons/icons-arrow-back.svg";
import { ReactComponent as Star } from "../../assets/icons/icons-16-small-rating-star-active.svg";
import { ReactComponent as StarGray } from "../../assets/icons/icons-16-small-rating-star-gray.svg";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { Skeleton } from "antd";
import PageHeader from "../../UI/PageHeader";
import Button from "../../UI/Button";
import {
  deleteVipWorker,
  getVipWorkers,
} from "../../redux/store/reducers/companySlice";

const UserProfilePage: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { user, userView } = useAppSelector((state) => state.auth);
  const { workers, getWorkers } = useAppSelector((state) => state.company);
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const [currentNavItem, setCurrentNavItem] =
    useState<string>("Об исполнителе");
  const [navigationList, setNavigationList] = useState<NavigationItem[]>([
    {
      label: "Об исполнителе",
      id: "workerBlock",
    },
    {
      label: "Список техники",
      id: "vehiclesBlock",
    },
    {
      label: "Реквизиты",
      id: "requisitesBlock",
    },
    {
      label: "Отзывы заказчиков",
      id: "feedbackBlock",
    },
  ]);

  useEffect(() => {
    if (userView) {
      let newNavigation: NavigationItem[] = navigationList.map(
        (item, index) => {
          if (userView.vehicles.length > 0 && index === 1) {
            return {
              ...item,
              counterValue: userView.vehicles.length,
            };
          }
          if (userView.feedbacks.length > 0 && index === 3) {
            return {
              ...item,
              counterValue: userView.feedbacks.length,
            };
          }
          return { ...item };
        },
      );
      setNavigationList([...newNavigation]);
    }
  }, [userView]);

  useEffect(() => {
    if (params.userId) {
      dispatch(
        getUser({
          id: Number(params.userId),
          refId: user?.customer?.refId,
        }),
      );
    }
    return () => {
      dispatch(authActions.clearUserView());
    };
  }, [user]);

  useEffect(() => {
    if (!vehicleCategories.length) {
      dispatch(getVehicleCategories());
    }
  }, [vehicleCategories]);

  useEffect(() => {
    user?.customer.refId &&
      dispatch(
        getVipWorkers({
          refId: user?.customer.refId,
        }),
      );
  }, [user]);

  const renderVehicles = () => {
    return userView?.vehicles?.map((vehicle: any, index: number) => {
      return (
        <VehicleItem key={`vehicle_${index}`}>
          {vehicleCategories?.find(
            (category: any) => category.id === vehicle.categoryId,
          )?.icon ? (
            <img
              className="vehicleIcon"
              src={`https://dev.rukki.pro/${
                vehicleCategories?.find(
                  (category: any) => category.id === vehicle.categoryId,
                )?.icon
              }`}
              width={"24px"}
              height={"24px"}
              alt=""
            />
          ) : (
            <img
              className="vehicleIcon"
              src={`https://dev.rukki.pro/assets/images/excavator.png`}
              width={"24px"}
              height={"24px"}
              alt=""
            />
          )}
          <div className="vehicleInfo">
            {!!vehicle.productionYear && (
              <div className="textSmall">{`${vehicle.productionYear} год выпуска`}</div>
            )}
            <div className="text">
              {`${
                vehicleCategories?.find(
                  (category: any) => category.id === vehicle.categoryId,
                )?.title
              } ${vehicle.brand} ${vehicle.model}${
                vehicle.stateNumber &&
                ", госномер" + vehicle.stateNumber.toString().toUpperCase()
              }`}
            </div>
          </div>
        </VehicleItem>
      );
    });
  };

  const renderFeedback = () => {
    return userView?.feedbacks?.map((feedback: any, index: number) => {
      return (
        <FeedbackItem key={`feedback_${index}`}>
          <div className="profilePhoto">
            <img
              src={
                feedback?.createdBy?.avatarUrl
                  ? `data:image/jpeg;base64,${feedback?.createdBy?.avatarUrl}`
                  : defaultAvatar
              }
              alt="avatar"
              width={"40px"}
              height={"40px"}
            />
          </div>
          <div className="feedbackData">
            <div className="feedbackInfo">
              <div className="name">{`${feedback.createdBy.firstName} ${feedback.createdBy.secondName}`}</div>
              <div className="rating">
                <div className="stars">
                  {Array(5)
                    .fill("")
                    .map((v, i) => (
                      <InActiveStar
                        className={feedback.rating >= i + 1 ? "" : "in-active"}
                      />
                    ))}
                </div>
                {feedback.rating === 5
                  ? "Отлично"
                  : feedback.rating === 4
                  ? "Хорошо"
                  : feedback.rating === 3
                  ? // ? "Удовлетворительно"
                    "Нормально"
                  : feedback.rating === 2
                  ? "Плохо"
                  : "Ужасно"}
              </div>
            </div>
            <div className="feedbackValue">{feedback.description}</div>
          </div>
        </FeedbackItem>
      );
    });
  };

  // const handleAddWorker = () => {
  //   if (user?.customer.refId && userView)
  //     dispatch(
  //       addVipWorker({
  //         refId: user?.customer.refId,
  //         firstName: userView.firstName,
  //         lastName: userView.secondName,
  //         patronymic: userView.patronymic,
  //         phone: userView.phoneNumber,
  //       })
  //     );
  // };

  const handleRemoveWorker = () => {
    if (user?.customer.refId && userView)
      dispatch(
        deleteVipWorker({
          refId: user?.customer.refId!,
          workerId: userView.id,
        }),
      );
  };

  return (
    <PageWrapper>
      <PageHeader>Профиль исполнителя</PageHeader>
      <ContentWrapper>
        <PageNavigation
          list={navigationList}
          currentItem={currentNavItem}
          setCurrentItem={setCurrentNavItem}
        />
        <Content>
          <ContentCard id="workerBlock">
            <div className="mainInfoWrapper">
              <div className="profilePhoto">
                {userView ? (
                  <img
                    src={
                      userView?.avatarUrl
                        ? `data:image/jpeg;base64,${userView?.avatarUrl}`
                        : defaultAvatar
                    }
                    alt="avatar"
                    width={"80px"}
                    height={"80px"}
                  />
                ) : (
                  <Skeleton.Avatar
                    active
                    size={"large"}
                    shape={"circle"}
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                  />
                )}
              </div>
              <div className="mainInfo">
                <div className="textLarge">
                  {userView ? (
                    `${userView?.firstName} ${userView?.secondName}`
                  ) : (
                    <Skeleton
                      active
                      paragraph={{
                        rows: 0,
                        style: { margin: 0 },
                      }}
                      title={{
                        width: "250px",
                        style: {
                          margin: 0,
                          height: "32px",
                        },
                      }}
                    />
                  )}
                </div>
                <div className="text">
                  {userView ? (
                    `Работает как ${
                      userView?.company ? userView?.company?.title : "физлицо"
                    }`
                  ) : (
                    <Skeleton
                      active
                      paragraph={{
                        rows: 0,
                        style: { margin: 0 },
                      }}
                      title={{
                        width: "270px",
                        style: {
                          margin: 0,
                          height: "20px",
                        },
                      }}
                    />
                  )}
                </div>
                <Rating>
                  {userView ? (
                    <>
                      <div className="ratingStars">
                        {Array(5)
                          .fill("")
                          .map((v, i) =>
                            i + 1 <= Number(userView?.rating[0]) ? (
                              <Star />
                            ) : (
                              <StarGray />
                            ),
                          )}
                      </div>
                      {userView?.rating}
                    </>
                  ) : (
                    <Skeleton
                      active
                      paragraph={{
                        rows: 0,
                        style: { margin: 0 },
                      }}
                      title={{
                        width: "145px",
                        style: {
                          margin: 0,
                          height: "24px",
                        },
                      }}
                    />
                  )}
                </Rating>
              </div>
            </div>
            {!!user?.customer.companyId &&
              getWorkers === "succeeded" &&
              !!workers.length &&
              (workers.find((worker) => worker.workerId === userView?.id) ? (
                <Button
                  width="fit-content"
                  height="36px"
                  onClick={handleRemoveWorker}
                >
                  Удалить из избранных
                </Button>
              ) : // <Button width="fit-content" height="36px">
              //   Добавить в избранные
              // </Button>
              null)}
          </ContentCard>
          {!!userView?.vehicles?.length && (
            <ContentCard id="vehiclesBlock">
              <div className="cardHeader">Список техники</div>
              <div className="vehicles">{renderVehicles()}</div>
            </ContentCard>
          )}
          {userView?.company && userView?.requisites && (
            <ContentCard id="requisitesBlock">
              <div className="cardHeader">Реквизиты исполнителя</div>
              <div className="requisites">
                {userView ? (
                  <>
                    <div className="requisitesColumn">
                      <div>{userView?.company?.title}</div>
                      {userView?.requisites[0]?.actualAddress && (
                        <div>{`Адрес: ${userView?.requisites[0]?.actualAddress}`}</div>
                      )}
                      <div>
                        {userView?.requisites[0]?.inn &&
                          `ИНН: ${userView?.requisites[0]?.inn}`}
                        <br />
                        {userView?.requisites[0]?.kpp &&
                          `КПП: ${userView?.requisites[0]?.kpp}`}
                      </div>
                    </div>
                    <div className="requisitesColumn">
                      <div>
                        {userView?.requisites[0]?.rs &&
                          `Расчетный счет: ${userView?.requisites[0]?.rs}`}
                        <br />
                        {userView?.requisites[0]?.bik &&
                          `БИК: ${userView?.requisites[0]?.bik}`}
                      </div>
                      {userView?.requisites[0]?.ks && (
                        <div>{`Корр. счет: ${userView?.requisites[0]?.ks}`}</div>
                      )}
                    </div>
                  </>
                ) : (
                  <Skeleton
                    paragraph={{
                      rows: 3,
                      style: { marginTop: "16px" },
                      width: "100%",
                    }}
                    active
                    title={{ width: "100%" }}
                  />
                )}
              </div>
            </ContentCard>
          )}

          {!!userView?.feedbacks?.length && (
            <ContentCard id="feedbackBlock">
              <div className="cardHeader">
                Отзывы заказчиков{" "}
                <CounterBadge value={userView?.feedbacks?.length} />
              </div>
              <div className="feedbackWrapper">{renderFeedback()}</div>
            </ContentCard>
          )}
        </Content>
      </ContentWrapper>
    </PageWrapper>
  );
};

export default memo(UserProfilePage);
