import React, { memo } from "react";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icon-104-intellicon-time-calendar.svg";
import { Wrapper } from "./styles";
import Button from "../../UI/Button";
import { useLocation, useNavigate } from "react-router-dom";

interface OrdersEmptyTagProps {
  header?: string;
}

const OrdersEmptyTag: React.FC<OrdersEmptyTagProps> = ({ header }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const getTitle = (): string => {
    if (pathname.includes("search-order")) {
      return `Пока нет ${header ? header + " " : ""}работ`;
    } else return `Пока нет ${header ? header + " " : ""}заказов`;
  };

  const getText = (): string => {
    if (pathname.includes("search-order")) {
      return "Посмотрите текущие заказы и откликнитесь на подходящее предложение";
    } else if (pathname.includes("analytics")) {
      return "Создайте свой первый заказ и начните пользоваться аналитикой";
    } else
      return "Создайте свой первый заказ и начните получать предложения от исполнителей";
  };

  const getButton = (): JSX.Element => {
    if (pathname.includes("search-order")) {
      return (
        <Button
          height="48px"
          width="296px"
          shadowed
          onClick={() => navigate("/search-order")}
        >
          Поиск заказов
        </Button>
      );
    } else
      return (
        <Button
          height="48px"
          width="296px"
          shadowed
          onClick={() => navigate("/create-order")}
        >
          Создать заказ
        </Button>
      );
  };

  return (
    <Wrapper>
      <CalendarIcon />
      <div className="header">{getTitle()}</div>
      <div className="text">{getText()}</div>
      {getButton()}
    </Wrapper>
  );
};

export default memo(OrdersEmptyTag);
