// import { staffList } from "./mockData";
import moment from "moment";
import { ReactComponent as Beton } from "../assets/icons/icons-vehicles-beton.svg";
import { ReactComponent as Excavator } from "../assets/icons/icons-vehicles-excavator.svg";
import { ReactComponent as Hoist } from "../assets/icons/icons-vehicles-hoist.svg";
import { ReactComponent as Musor } from "../assets/icons/icons-vehicles-musor.svg";
import { ReactComponent as Wheelbarrow } from "../assets/icons/icons-vehicles-wheelbarrow.svg";

export interface ObjectType {
  id: string;
  name: string;
  customer: string;
  ordersCount: number;
  vehicleCount: number;
  total: number;
  startDate: number;
  endDate: number;
}

export const objectList: ObjectType[] = [
  {
    id: "123-456",
    name: "ЖК Лайв Успенский",
    customer: "СЗ «Автодорн»",
    ordersCount: 12,
    vehicleCount: 17,
    total: 1300000,
    startDate: moment().add(0, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
  },
  {
    id: "123-516",
    name: "Трасса Москва Кубинка",
    customer: "ООО «Дор строй»",
    ordersCount: 10,
    vehicleCount: 30,
    total: 700000,
    startDate: moment().add(1, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
  },
  {
    id: "123-785",
    name: "Мост, Усачева 20",
    customer: "ООО «Москва Лайв»",
    ordersCount: 9,
    vehicleCount: 25,
    total: 1650000,
    startDate: moment().add(5, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
  },
  {
    id: "123-915",
    name: "Москва, Усачева 20",
    customer: "ООО «Ледор строй»",
    ordersCount: 3,
    vehicleCount: 16,
    total: 9250000,
    startDate: moment().add(10, "day").valueOf(),
    endDate: moment().add(15, "day").valueOf(),
  },
  {
    id: "123-953",
    name: "Москва, Усачева 20",
    customer: "ООО «Бассейн-строй»",
    ordersCount: 3,
    vehicleCount: 16,
    total: 9250000,
    startDate: moment().add(20, "day").valueOf(),
    endDate: moment().add(25, "day").valueOf(),
  },
];

export interface StaffType {
  id: number;
  fullName: string;
  phoneNumber: string;
  totalAmount: number;
  activeOrdersCount: number;
  allOrdersCount: number;
  allTotalAmount: number;
  customer: string;
  //   description: string;
  address: string;
  objectId: string;
  orderAmount: number;
}

export const staffList: StaffType[] = [
  {
    id: 1,
    fullName: "Астемир Беков",
    phoneNumber: "+79526511001",
    totalAmount: 117000,
    activeOrdersCount: 3,
    allOrdersCount: 10,
    allTotalAmount: 1200000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-456",
    orderAmount: 6526,
  },
  {
    id: 2,
    fullName: "Александр Лавров",
    phoneNumber: "+79751102399",
    totalAmount: 10000,
    activeOrdersCount: 2,
    allOrdersCount: 11,
    allTotalAmount: 200000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-456",
    orderAmount: 6526,
  },
  {
    id: 3,
    fullName: "Алексей Минин",
    phoneNumber: "+78752107811",
    totalAmount: 70000,
    activeOrdersCount: 3,
    allOrdersCount: 11,
    allTotalAmount: 500000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-456",
    orderAmount: 6526,
  },
  {
    id: 4,
    fullName: "Дмитрий Ярыш",
    phoneNumber: "+79515115150",
    totalAmount: 60000,
    activeOrdersCount: 1,
    allOrdersCount: 10,
    allTotalAmount: 1700000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-516",
    orderAmount: 6526,
  },
  {
    id: 5,
    fullName: "Игорь Ильин",
    phoneNumber: "+79656402307",
    totalAmount: 25000,
    activeOrdersCount: 2,
    allOrdersCount: 11,
    allTotalAmount: 150000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-516",
    orderAmount: 6526,
  },
  {
    id: 6,
    fullName: "Алексей Минин2",
    phoneNumber: "+78752107811",
    totalAmount: 30000,
    activeOrdersCount: 3,
    allOrdersCount: 11,
    allTotalAmount: 135000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-516",
    orderAmount: 6526,
  },
  {
    id: 7,
    fullName: "Виктор Морозов",
    phoneNumber: "+79544181000",
    totalAmount: 190000,
    activeOrdersCount: 1,
    allOrdersCount: 10,
    allTotalAmount: 1200000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-785",
    orderAmount: 6526,
  },
  {
    id: 8,
    fullName: "Александр Пекаев",
    phoneNumber: "+78751102399",
    totalAmount: 139000,
    activeOrdersCount: 2,
    allOrdersCount: 11,
    allTotalAmount: 420000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-785",
    orderAmount: 6526,
  },
  // {
  //   id: 9,
  //   fullName: "Алексей Минин",
  //   phoneNumber: "+78752107811",
  //   totalAmount: 130000,
  //   activeOrdersCount: 3,
  //   allOrdersCount: 11,
  //   allTotalAmount: 590000,
  //   customer: "ЖК Лайв Успенский",
  //   // description: "",
  //   address: "",
  //   objectId: "123-785",
  // },
  {
    id: 10,
    fullName: "Генадий Крякин",
    phoneNumber: "+79895626204",
    totalAmount: 125000,
    activeOrdersCount: 1,
    allOrdersCount: 10,
    allTotalAmount: 1800000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-915",
    orderAmount: 6526,
  },
  {
    id: 11,
    fullName: "Денис Лавров",
    phoneNumber: "+78854981552",
    totalAmount: 165000,
    activeOrdersCount: 2,
    allOrdersCount: 11,
    allTotalAmount: 230000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-915",
    orderAmount: 6526,
  },
  // {
  //   id: 12,
  //   fullName: "Алексей Минин",
  //   phoneNumber: "+78752107811",
  //   totalAmount: 28000,
  //   activeOrdersCount: 3,
  //   allOrdersCount: 11,
  //   allTotalAmount: 550000,
  //   customer: "ЖК Лайв Успенский",
  //   // description: "",
  //   address: "",
  //   objectId: "123-915",
  // },
  {
    id: 13,
    fullName: "Астемир Беков2",
    phoneNumber: "+79526511001",
    totalAmount: 117000,
    activeOrdersCount: 3,
    allOrdersCount: 10,
    allTotalAmount: 1200000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-953",
    orderAmount: 6526,
  },
  {
    id: 14,
    fullName: "Александр Лавров2",
    phoneNumber: "+79751102399",
    totalAmount: 10000,
    activeOrdersCount: 2,
    allOrdersCount: 11,
    allTotalAmount: 200000,
    customer: "ЖК Лайв Успенский",
    // description: "",
    address: "",
    objectId: "123-953",
    orderAmount: 6526,
  },
  // {
  //   id: 15,
  //   fullName: "Алексей Минин",
  //   phoneNumber: "+78752107811",
  //   totalAmount: 70000,
  //   activeOrdersCount: 3,
  //   allOrdersCount: 11,
  //   allTotalAmount: 500000,
  //   customer: "ЖК Лайв Успенский",
  //   // description: "",
  //   address: "",
  //   objectId: "123-953",
  // },
];

export interface OrderType {
  id: string;
  title: string;
  vehicle: string;
  vehicleData?: any;
  Icon: any;
  amount: number;
  customer: string;
  startDate: number;
  endDate: number;
  status: string;
  createdAt: number;
  staffId: number;
}

export const orderList: OrderType[] = [
  {
    id: "935-412",
    title: "Автовышка",
    vehicle: "Экскаватор, ковш 400",
    Icon: Excavator,
    amount: 120000,
    customer: "СЗ «Автодорн»",
    startDate: moment().add(0, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 1,
  },
  {
    id: "935-414",
    title: "Автовышка",
    vehicle: "Экскаватор, ковш 400",
    Icon: Excavator,
    amount: 120000,
    customer: "СЗ «Автодорн»",
    startDate: moment().add(0, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 2,
  },
  {
    id: "935-416",
    title: "Автовышка",
    vehicle: "Экскаватор, ковш 400",
    Icon: Excavator,
    amount: 120000,
    customer: "СЗ «Автодорн»",
    startDate: moment().add(0, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 3,
  },
  {
    id: "935-522",
    title: "Вывезти мусор",
    vehicle: "Экскаватор, ковш 400",
    Icon: Musor,
    amount: 160000,
    customer: "ООО «Бассейн-строй»",
    startDate: moment().add(1, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 13,
  },
  {
    id: "935-524",
    title: "Вывезти мусор",
    vehicle: "Экскаватор, ковш 400",
    Icon: Musor,
    amount: 160000,
    customer: "ООО «Бассейн-строй»",
    startDate: moment().add(1, "day").valueOf(),
    endDate: moment().add(10, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 14,
  },
  {
    id: "935-562",
    title: "Разгрузить материалы на стройплощадке",
    vehicle: "Экскаватор, ковш 400",
    Icon: Wheelbarrow,
    amount: 80000,
    customer: "ООО «Ледор строй»",
    startDate: moment().add(3, "day").valueOf(),
    endDate: moment().add(15, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 10,
  },
  {
    id: "935-564",
    title: "Разгрузить материалы на стройплощадке",
    vehicle: "Экскаватор, ковш 400",
    Icon: Wheelbarrow,
    amount: 80000,
    customer: "ООО «Ледор строй»",
    startDate: moment().add(3, "day").valueOf(),
    endDate: moment().add(15, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 11,
  },
  {
    id: "935-673",
    title: "Замена уличного освещения",
    vehicle: "Экскаватор, ковш 400",
    Icon: Hoist,
    amount: 20000,
    customer: "ООО «Бассейн-строй»",
    startDate: moment().add(10, "day").valueOf(),
    endDate: moment().add(17, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 14,
  },
  {
    id: "935-782",
    title: "Доставка бетона",
    vehicle: "Камаз БМЦ 3200",
    Icon: Beton,
    amount: 520000,
    customer: "ООО «Москва Лайв»",
    startDate: moment().add(17, "day").valueOf(),
    endDate: moment().add(20, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 7,
  },
  {
    id: "935-784",
    title: "Доставка бетона",
    vehicle: "Камаз БМЦ 3200",
    Icon: Beton,
    amount: 520000,
    customer: "ООО «Москва Лайв»",
    startDate: moment().add(17, "day").valueOf(),
    endDate: moment().add(20, "day").valueOf(),
    status: "Идут работы",
    createdAt: Date.now(),
    staffId: 8,
  },
];
