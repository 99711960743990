import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppointExecutor from "../../components/appointToExecutor";
import ChooseAnyMsg from "../../components/chooseAnyMsg";
import NewFooter from "../../components/newFooter";
import Navbar from "../../components/navbar";
import RedirectRef from "../../components/redirectRef";
import CreateOrderPage from "../createOrderPage";
import FeedbackAndRatingPage from "../feedbackAndRatingPage";
import InfoAboutOrderPage from "../infoAboutOrderPage";
import LoginPage from "../loginPage";
import MessagesPage from "../messagesPage";
import MyOrdersPage from "../myOrdersPage";
import SettingPage from "../settingPage";
import SwitchToAgent from "../switchToAgent";
import { getRefIDFromFireBase } from "../../redux/store/reducers/authSlice";
import { useGlobalContext } from "../../App";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PrivateRoute from "../../hocs/PrivateRoute";
import { RootState } from "../../redux/store/store";
import SearchOrderPage from "../SearchOrderPage";
import useHttpRequest from "../../hooks/useHttpRequest";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";
import { Highlighted } from "../../components/styles/styles";
import moment from "moment";
import { ConfigProvider } from "antd";
import GoPage from "../goPage";
import Dashboard from "../dashboard";
import DashboardOrders from "../dashboardOrders";
import DashboardRightOrderData from "../../components/dashboardRightOrderData";
import DashboardRightOrders from "../../components/dashboardRightOrders";
import DashboardManagers from "../dashboardManagers";
import DashboardRightManagerData from "../../components/dashboardRightManagerData";
import DashboardOrderData from "../dashboardOrderData";
import DashboardObjects from "../dashboardObjects";
import RegisterAsOrganization from "../registerAsOrganisation";
import locale from "antd/es/locale/ru_RU";
import DashboardAddObject from "../dashboardAddObject";
import DashboardAddManager from "../dashboardAddManager";
import AddOrgPage from "../addOrgPage";
import SuccessPaymentPage from "../successPaymentPage";
import ErrorPaymentPage from "../errorPaymentPage";
import UserRegistration from "../userRegistration";
import EmployeeManagementPage from "../employeeManagementPage";
import VehicleManagementPage from "../vehicleManagementPage";
import CreateVehiclePage from "../createVehiclePage";
import OrgDataPage from "../orgDataPage";
import DigitalDispatcherDemo from "../digitalDispatcherDemo";
import RegisterWithInvitationPage from "../registerWithInvitationPage";
import EditOrderPage from "../editOrderPage";
import UserProfilePage from "../userProfilePage";
import TelegramAuthPage from "../telegramAuthPage";
import ProfileContainer from "../../components/profileContainer";
import { getPageTitle } from "../../utils/getPageTitle";
import DashboardEmployeesPage from "../dashboardEmployeesPage";
import Analytics from "../analytics";
import {
  clearPagination,
  setOrdersFilter,
} from "../../redux/store/reducers/orderSlice";
import DashboardWorkersPage from "../dashboardWorkersPage";
import DocumentViewer from "../documentViewer";
import { getLocationPermission } from "../../utils/getLocationPermission";

// const getToken = (): string | null => {
//   return localStorage.getItem("accessToken");
// };

// if (!("Notification" in window)) {
//   // Check if the browser supports notifications
//   alert("This browser does not support desktop notification");
// } else if (Notification.permission === "granted") {
//   // Check whether notification permissions have already been granted;
//   // if so, create a notification
//   const notification = new Notification("Hi there!");
//   // …
// } else if (Notification.permission !== "denied") {
//   // We need to ask the user for permission
//   Notification.requestPermission().then((permission) => {
//     // If the user accepts, let's create a notification
//     if (permission === "granted") {
//       const notification = new Notification("Hi there!");
//       // …
//     }
//   });
// }

getLocationPermission();

const CreateOrderNotification = ({ data, id }: any) => {
  const navigate = useNavigate();
  const orderId = data?.orderId;
  const [process, request] = useHttpRequest();
  useEffect(() => {
    if (orderId) {
      request({
        method: "GET",
        url: `${ENDPOINT}/orders/${id}/${orderId}`,
      });
    }
  }, []);
  const getAccessToNotification = () => {
    Notification.requestPermission();
  };
  const sendNotification = (text: any, body: any) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        new Notification(text, { body });
      } else if (Notification.permission !== "denied") {
        getAccessToNotification();
      }
    }
  };

  useEffect(() => {
    if (process.success) {
      sendNotification(
        `RUKKI.PRO/${orderId}`,
        `${moment(process?.data?.order?.createdAt).format("lll")}\n${
          process?.data?.order?.title
        }\n${
          process?.data?.order?.paymentType
        }\nRUKKI.PRO/${orderId}\nзаказ создан и ждёт исполнителей`,
      );
    }
  }, [process.success]);

  return (
    <>
      {moment(process?.data?.order?.createdAt).format("lll")} <br />
      {process?.data?.order?.title} <br />
      {process?.data?.order?.paymentType} <br />
      <Highlighted
        onClick={() => {
          navigate(`/o/${orderId}`);
        }}
      >
        RUKKI.PRO/{orderId}
      </Highlighted>
      <br />
      заказ создан и ждёт исполнителей
    </>
  );
};

const MsgBox = ({ data, text, created, refId, title }: any) => {
  const navigate = useNavigate();
  const getAccessToNotification = () => {
    Notification.requestPermission();
  };
  const sendNotification = (text: any) => {
    if ("Notification" in window) {
      if (Notification.permission === "granted") {
        new Notification(text);
      } else if (Notification.permission !== "denied") {
        getAccessToNotification();
      }
    }
  };
  useEffect(() => {
    if (!created) {
      sendNotification(text);
    }
  }, []);

  return created ? (
    <CreateOrderNotification data={data} id={refId} />
  ) : (
    <div
      style={{ display: "flex", flexDirection: "column", gap: "6px" }}
      onClick={() => navigate(`/my-orders/${data?.orderId}`)}
    >
      <div
        style={{
          fontSize: "16px",
          fontWeight: "500",
          color: "#fdfdfd",
        }}
      >
        {title}
      </div>
      <div
        style={{
          fontSize: "12px",
          fontWeight: "400",
          color: "#fff3ef",
        }}
      >
        {text}
      </div>
    </div>
  );
};

const MainPage: React.FC = () => {
  moment.locale("ru", {
    calendar: {
      sameDay: "[Сегодня в] LT",
      nextDay: "[Завтра в] LT",
      lastDay: "[Вчера в] LT",
      nextWeek: "dddd",
      lastWeek: "ll LT",
      sameElse: "L",
    },
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useGlobalContext();
  const { pathname, search } = useLocation();

  const currentUser = useAppSelector((state) => state.auth.user);
  const loadingReg = useAppSelector((state) => state.auth.loadingReg);

  const [switchToAgent, setSwitchToAgent] = useState(false);
  const [disableLoginBtn, setDisableLoginBtn] = useState(false);

  const getAccessToNotification = () => {
    Notification.requestPermission();
  };

  useEffect(() => {
    if ("Notification" in window) {
      if (Notification.permission !== "denied") {
        getAccessToNotification();
      }
    }
  }, []);

  useEffect(() => {
    if (pathname === "/" && !currentUser?.customer) {
      navigate("/create-order");
    }
  }, [currentUser]);

  useEffect(() => {
    if (loadingReg === "succeeded" && user && user.uid)
      dispatch(getRefIDFromFireBase({ uid: user.uid, navigate }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingReg]);

  useEffect(() => {
    const refId = currentUser?.customer?.refId;
    let order: any = localStorage.getItem("makeOrderData");
    order = JSON.parse(order);
    let photoVideo: any = localStorage.getItem("makeOrderPhotoVideo");
    photoVideo = JSON.parse(photoVideo);
    if (refId && order) {
      httpRequest({
        method: "POST",
        url: `${ENDPOINT}/orders/${refId}`,
        data: order,
      })
        .then((res: any) => {
          const orderId = res?.data?.id;
          const formData = new FormData();
          photoVideo?.forEach(({ file }: any) => {
            formData.append("", file);
          });

          if (formData) {
            httpRequest({
              method: "PUT",
              url: `${ENDPOINT}/orders/${refId}/${orderId}/images`,
              data: formData,
            })
              .then((res) => {
                // toast.success("успешно создана заявка");
              })
              .catch((err) => {});
          } else {
            // toast.success("успешно создана заявка");
          }
          localStorage.removeItem("makeOrderData");
          localStorage.removeItem("makeOrderPhotoVideo");
          navigate(`/o/${orderId}`);
        })
        .catch((err) => {});
    }
  }, [currentUser?.customer?.refId]);

  useEffect(() => {
    getPageTitle(pathname, search);
    if (!pathname.includes("/o")) {
      dispatch(clearPagination());
    }
    if (!pathname.includes("search-order") && !pathname.includes("/o")) {
      dispatch(setOrdersFilter("in_progress"));
    }
  }, [pathname]);

  return (
    <ConfigProvider locale={locale}>
      <Navbar disableLoginBtn={disableLoginBtn} />

      <Routes>
        <Route
          path="/login"
          element={<LoginPage setDisable={setDisableLoginBtn} />}
        />
        <Route
          path="/login/:referralId"
          element={<LoginPage setDisable={setDisableLoginBtn} />}
        />
        <Route path="/telegram-auth/" element={<TelegramAuthPage />}>
          <Route path=":sessionId" element={<TelegramAuthPage />} />
        </Route>
        <Route path="/r/:refId" element={<RedirectRef />} />
        <Route
          path="/registration"
          element={<UserRegistration setDisable={setDisableLoginBtn} />}
        />
        <Route path="/invite/:id" element={<RegisterWithInvitationPage />} />
        <Route path="/messages" element={<MessagesPage />}>
          <Route path="" element={<ChooseAnyMsg />} />
          {/* <Route path=":id" element={<MessagesList socket={client} />} /> */}
        </Route>
        <Route path="/go" element={<GoPage />} />
        <Route
          path="/search-order/*"
          element={
            <PrivateRoute>
              <SearchOrderPage />
            </PrivateRoute>
          }
        />
        <Route path="/create-order" element={<CreateOrderPage />} />
        <Route path="/o" element={<MyOrdersPage />} />
        <Route path="/success-payment" element={<SuccessPaymentPage />} />
        <Route path="/error-payment" element={<ErrorPaymentPage />} />
        <Route path="/user/:userId" element={<UserProfilePage />} />
        <Route
          path="/o/:id"
          element={
            <PrivateRoute>
              <InfoAboutOrderPage />
            </PrivateRoute>
          }
        />
        <Route path="/o/:id/edit" element={<EditOrderPage />} />
        <Route path="/o/:id/appoint/:userId" element={<AppointExecutor />} />
        <Route path="/digital-dispatcher" element={<DigitalDispatcherDemo />} />
        <Route path="/analytics/*" element={<Analytics />} />
        <Route path="/profile" element={<ProfileContainer />}>
          <Route
            path=""
            element={
              <PrivateRoute>
                <OrgDataPage />
              </PrivateRoute>
            }
          />
          <Route
            path="settings"
            element={
              <PrivateRoute>
                <SettingPage />
              </PrivateRoute>
            }
          />
          <Route
            path="vehicle-management"
            element={
              <PrivateRoute>
                <VehicleManagementPage />
              </PrivateRoute>
            }
          />
          <Route
            path="employee-management"
            element={
              <PrivateRoute>
                <EmployeeManagementPage />
              </PrivateRoute>
            }
          />
          <Route path="rating" element={<FeedbackAndRatingPage />} />
          {/* <Route
            path="create-employee"
            element={
              <PrivateRoute>
                <CreateEmployeePage />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="create-vehicle"
            element={
              <PrivateRoute>
                <CreateVehiclePage />
              </PrivateRoute>
            }
          />
        </Route>

        <Route path="/add-org" element={<AddOrgPage />} />
        <Route path="/dashboard" element={<Dashboard />}>
          <Route path="order" element={<DashboardOrders />}>
            <Route path="" element={<DashboardRightOrders />} />
            <Route path=":id" element={<DashboardRightOrderData />} />
          </Route>
          <Route path="order/:id/info" element={<DashboardOrderData />} />
          <Route
            path="employees"
            element={
              <PrivateRoute>
                <DashboardEmployeesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="workers"
            element={
              <PrivateRoute>
                <DashboardWorkersPage />
              </PrivateRoute>
            }
          />
          <Route
            path="manager"
            element={<Navigate to={"/dashboard/manager/all"} />}
          />
          <Route path="manager/add" element={<DashboardAddManager />} />
          <Route path="manager/:objId" element={<DashboardManagers />}>
            <Route path=":id" element={<DashboardRightManagerData />} />
          </Route>
          <Route path="objects" element={<DashboardObjects />} />
          <Route path="objects/add" element={<DashboardAddObject />} />
        </Route>
        <Route
          path="/document/:fileName"
          element={
            <PrivateRoute>
              <DocumentViewer />
            </PrivateRoute>
          }
        />
        <Route
          path="/register-as-organization"
          element={<RegisterAsOrganization />}
        />
      </Routes>
      {!pathname.startsWith("/dashboard") &&
      !pathname.includes("/o/") &&
      pathname !== "/search-order" &&
      pathname.includes("/o/") &&
      pathname.includes("appoint") ? (
        <NewFooter />
      ) : null}
      {switchToAgent && (
        <SwitchToAgent closeAgentMode={() => setSwitchToAgent(false)} />
      )}
    </ConfigProvider>
  );
};

export default MainPage;

