import styled from "styled-components";
import { styles } from "../../components/styles/styles";

export const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: calc(100vh - 80px);
  @media only screen and (min-width: 1440px) {
    padding: 0 170px;
  }
`;

export const Wrapper = styled.div<{ half?: boolean }>`
  padding: 40px 0;
  /* display: flex; */
  width: ${({ half }) => (half ? "50%" : "100%")};

  .roleBtn {
    width: 155px;
    height: 42px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: transparent;
    color: #ff6633;
    border-radius: 10px;
    font-size: 13px;
    border: 1px solid #ff6633;
    cursor: pointer;
    transition: all 0.4s ease-out;
    animation: anim 0.2s ease;

    :hover {
      background-color: #ff6633;
      color: white;
    }

    :focus {
      background-color: #ff6633;
      color: white;
    }
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -0.1px;
  margin-bottom: 34px;
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  width: 500px;
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 18px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
`;

export const InputWrapper = styled.label<{
  active?: boolean;
  small?: boolean;
  orgPage?: boolean;
}>`
  width: 100%;
  background-color: white;
  padding: ${({ small }) => (small ? "8px 16px" : "12px 16px")};
  border-radius: 10px;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "var(--color-light-gray)"};
  display: block;

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
      white-space: nowrap;
    }
  }

  :not(.date-input) input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
    overflow: hidden;
  }

  .ant-picker {
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 16px;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;
      }
    }
  }

  @media screen and (max-width: 480px) {
    ${({ orgPage }) => orgPage && "width: 376px !important;"}
  }

  @media screen and (max-width: 320px) {
    ${({ orgPage }) => orgPage && "width: 100% !important;"}
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 12px 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;

export const ActualAddress = styled.div`
  width: 100%;
  position: relative;
`;

export const AddAddressText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;

  u {
    cursor: pointer;
  }
`;

export const CloseAddAddress = styled.div`
  position: absolute;
  right: -30px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: fit-content;
  cursor: pointer;

  svg {
    display: flex;
  }
`;
