import React, { memo, useMemo } from "react";
import { AccordionWrapper, AccordionHeader, AccordionBody } from "./styles";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-bottom.svg";
import Button from "../Button";

interface AccordionProps {
  title: string;
  list?: any[] | string | React.ReactNode;
  userInfo?: any;
  bodyMaxHeight?: string;
  margin?: string;
  open: boolean;
  setOpen: () => void;
  buttonVisible?: boolean;
  buttonOnClick?: (param?: any) => void;
  buttonDisabled?: boolean;
  button?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  title,
  list,
  bodyMaxHeight,
  margin,
  open,
  setOpen,
  buttonVisible,
  buttonOnClick,
  buttonDisabled,
  button = true,
}) => {
  return (
    <AccordionWrapper margin={margin} open={open}>
      <AccordionHeader open={open} onClick={() => setOpen()}>
        {title} <ArrowIcon />
      </AccordionHeader>
      <AccordionBody
        open={open}
        maxHeight={bodyMaxHeight}
        buttonVisible={buttonVisible}
      >
        {list}

        {button && (
          <div className="hiddenButton">
            <Button
              onClick={() => buttonOnClick && buttonOnClick()}
              height="100%"
              disabled={buttonDisabled}
            >
              Сохранить
            </Button>
          </div>
        )}
      </AccordionBody>
    </AccordionWrapper>
  );
};

export default memo(Accordion);
