import React, { memo, useEffect, useRef, useState } from "react";
import {
  DatasetBox,
  DatasetList,
  InputBox,
  InputDatasetWrapper,
} from "./styles";
import { ReactComponent as Close } from "../../../assets/icons/Close.svg";
import { ReactComponent as PinMap } from "../../../assets/icons/map-pin-location.svg";
import { DebounceInput } from "react-debounce-input";
import { getAddressesString } from "../../../utils/getAddressString";

interface Props {
  setValue: any;
  close: any;
  list: any;
  search?: any;
  setLocation?: any;
  category?: any;
  defaultValue?: any;
  onChange?: any;
  tab?: any;
  onFocus?: any;
  onClick?: any;
  type?: "vehicle" | "city";
  setVehicleInputIcon?: any;
  isAddress?: boolean;
  getUserLocation: (mount?: boolean) => void;
}

const InputDataset: React.FC<Props> = ({
  setValue,
  close,
  list,
  search,
  category,
  defaultValue,
  onChange,
  tab,
  type,
  setVehicleInputIcon,
  isAddress,
  getUserLocation,
}) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const ref = useRef<any>();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setSearchInput(defaultValue);
    }
  }, [defaultValue]);

  return (
    <InputDatasetWrapper>
      <InputBox>
        <div
          className="go-back"
          onClick={() => {
            setSearchInput("");
            getUserLocation(true);
          }}
        >
          <PinMap />
        </div>
        <DebounceInput
          debounceTimeout={500}
          element={"input"}
          type="text"
          placeholder={"Введите адрес"}
          ref={ref}
          value={searchInput}
          onFocus={() => setIsOpen(true)}
          onBlur={() => setIsOpen(false)}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value);
            onChange && onChange(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              // e.preventDefault();
              setValue({ data: { title: searchInput } });
            } else if (e.key === "Tab") {
              e.preventDefault();
              tab?.();
            }
          }}
          // autoFocus
        />
        <div
          className="close"
          onClick={() => {
            if (searchInput) {
              setSearchInput("");
            } else {
              close();
            }
          }}
        >
          <Close />
        </div>
      </InputBox>
      {list?.length > 0 && isOpen && (
        <DatasetList>
          {list?.map((item: any, index: number) => {
            const {
              city,
              county,
              road,
              house_number,
              lat,
              lon,
              town,
              addresstype,
            } = item;
            return addresstype !== "state" ? (
              <DatasetBox
                key={index}
                onMouseDown={() => {
                  setValue({
                    coordinates: [lat, lon],
                    name: road
                      ? getAddressesString({
                          road,
                          house_number,
                        })
                      : city || town,
                    city: city || town,
                  });
                  setSearchInput(defaultValue);
                  // setVehicleInputIcon(img);
                }}
              >
                {/* <div className="img">{img && <img src={img} alt="" />}</div> */}
                <div className="data">
                  <div className="title">
                    {road
                      ? getAddressesString({
                          road,
                          house_number,
                        })
                      : city}
                  </div>
                  <div className="subtitle">
                    {city && road ? city : town && road ? town : ""}
                    {county
                      ? (city || town) && road
                        ? ", " + county
                        : county
                      : ""}
                  </div>
                </div>
              </DatasetBox>
            ) : null;
          })}
        </DatasetList>
      )}
    </InputDatasetWrapper>
  );
};

export default memo(InputDataset);
