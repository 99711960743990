import React, { memo } from "react";
import { Wrapper } from "./styles";
import { Empty } from "antd";

const MessagesEmptyPage: React.FC = () => {
  return (
    <Wrapper>
      <Empty description="У вас нет сообщений" />
    </Wrapper>
  );
};

export default memo(MessagesEmptyPage);
