import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  transition: all 0.3s linear;

  .editPen {
    cursor: pointer;
    width: 20px;
    height: 16px;
    opacity: 0.6;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 1px;
    transition: all 0.4s ease-out;
    animation: anim 0.3s ease;

    :hover {
      opacity: 1;
      box-shadow: 1px 2px 7px 4px #ececec;
    }
  }

  .actionTime {
    font-size: 12px;
  }

  .actionContainer {
    display: flex;
    gap: 7px;
    align-items: start;
    justify-content: start;
  }

  .avatar {
    border-radius: 50px;
    width: 25px;
    height: 25px;
  }

  /* .ant-timeline-item-head-blue {
    color: red;
    border-color: #21c17a;
  } */

  .ant-timeline-item-content {
    top: -5px;
  }
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg,
  img {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  div.title {
    margin-bottom: 15px;
    width: 100%;
    background: white;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-size: 20px;
  }

  .subtitle {
    margin-top: 8px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
`;
