import React, { memo, useState } from "react";
import { Wrapper } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_black_18dp.svg";

interface FilePreviewProps {
  src: string;
  onClick: () => void;
  fileType: string;
  fileExtension?: string | null;
}

const FilePreview: React.FC<FilePreviewProps> = ({
  src,
  onClick,
  fileType,
  fileExtension,
}) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);
  console.log(fileExtension);
  return (
    <Wrapper className={`${isClosed ? "fileClose" : ""} `}>
      {fileType.includes("video") ? (
        <video poster={`${src}`} width={"100%"} height={"100%"}>
          <source width={"100%"} src={`${src}#t=0.1`} type={fileType} />
        </video>
      ) : fileType.includes("image") ? (
        <img src={src} alt="" />
      ) : (
        <div className="file">{`.${fileExtension}`}</div>
      )}

      <div
        className="closeButton"
        onClick={() => {
          setIsClosed(true);
          setTimeout(() => {
            onClick();
          }, 300);
        }}
      >
        <CloseIcon />
      </div>
    </Wrapper>
  );
};

export default memo(FilePreview);

