import styled from "styled-components";
import { SmallTitle } from "../../components/styles/styles";

export const ContentTitle = styled(SmallTitle)`
  display: flex;
  text-align: center;

  @media screen and (max-width: 473px) {
    font-size: 14px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 5%;
  inset: 0;
  padding: 30px;
  background: #f7f4f1;

  @media screen and (max-width: 473px) {
    gap: 1%;
  }
`;
