import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 984px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 24px;
  transition: all 0.3s linear;

  .title {
    font-family: "Rubik";
    font-size: 20px;
    line-height: 1.2;
    color: #222222;
    width: 100%;
  }
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-switch-checked {
    background: #ff6633 !important;
  }

  .switchBlock {
    display: flex;
    gap: 8px;
    align-items: center;

    .switchTitle {
      font-size: 15px;
      line-height: 1;
      width: 100px;
    }
  }
`;

export const NotificationsBlock = styled.div`
  display: flex;
  gap: 16px;

  .mapContainer {
    border-radius: 8px;
    overflow: hidden;
    width: 70%;
    height: 650px;
  }
`;

export const AreasList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 10px;
  width: 30%;
  min-width: 200px;
  background: #f7f4f1;
  position: relative;
  gap: 8px;
`;

export const AreaBlock = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  font-size: 14px;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.4s linear;
  &:hover {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  }
  user-select: none;

  .areaRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    gap: 4px;
    padding: ${({ active }) =>
      active ? "8px 8px 4px 8px" : "8px 8px 8px 8px"};
    z-index: 4;
    background-color: #ffffff;

    input {
      appearance: none;
      outline: none;
      height: ${({ active }) => (active ? "36px" : "20px")};
      width: 100%;
      border-radius: 10px;
      border: ${({ active }) =>
        active ? "1px solid #e5e5e5" : "1px solid #ffffff"};
      padding: ${({ active }) => (active ? "4px 8px" : "0px")};
      transition: all 0.3s linear;

      &:focus {
        border: 1px solid #999999;
      }
    }

    .visibilityIcon {
      width: 18px;
      height: 18px;
      position: relative;

      svg {
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s linear;
        opacity: 0%;
        &.active {
          opacity: 100%;
        }
      }
    }
  }

  .areaDropdown {
    width: 100%;
    height: ${({ active }) => (active ? "69px" : "0px")};
    transition: transform 0.4s linear, height 0.3s linear, opacity 0.1s linear;
    opacity: ${({ active }) => (active ? "100%" : "0%")};
    transform: ${({ active }) =>
      active ? "translateY(0px)" : "translateY(-69px)"};
    overflow: hidden;
    display: flex;
    gap: 4px;
    padding: ${({ active }) =>
      active ? "4px 8px 8px 8px" : "0px 8px 0px 8px"};
    z-index: 3;

    .inputBlock {
      display: flex;
      flex-direction: column;
      gap: 2px;

      label {
        font-size: 12px;
      }

      input {
        appearance: none;
        outline: none;
        height: 36px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        padding: 4px 8px;
        transition: all 0.3s linear;

        &:focus {
          border: 1px solid #999999;
        }
      }
    }

    button {
      appearance: none;
      border: none;
      align-self: flex-end;
      border-radius: 10px;
      aspect-ratio: 1 / 1;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff6633;
      cursor: pointer;

      &:disabled {
        background-color: #f7f4f1;
      }
    }
  }
`;

export const AddAreaBlock = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  background: ${({ open }) => (open ? "#ffffff" : "#f7f4f1")};
  border-radius: 8px;
  display: flex;
  padding: 8px;
  gap: 4px;
  overflow: hidden;
  transition: all 0.3s linear;
  box-sizing: content-box;

  .addAreaButton {
    font-size: 14px;
    color: #222222;
    transition: all 0.3s linear;
    width: 60%;
    text-align: ${({ open }) => (open ? "left" : "center")};
    margin: ${({ open }) => (!open ? "0 20%" : "0")};
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: #ff6633;
    }
  }

  .addAreaBlockRow {
    display: flex;
    gap: 4px;
    transition: all 0.3s linear;
    opacity: ${({ open }) => (open ? "100%" : "0%")};
    visibility: ${({ open }) => (open ? "visible" : "hidden")};

    .inputBlock {
      display: flex;
      flex-direction: column;
      gap: 2px;

      label {
        font-size: 12px;
      }

      input {
        appearance: none;
        outline: none;
        height: 36px;
        width: 100%;
        border-radius: 10px;
        border: 1px solid #e5e5e5;
        padding: 4px 8px;
        transition: all 0.3s linear;

        &:focus {
          border: 1px solid #999999;
        }
      }
    }

    button {
      appearance: none;
      border: none;
      border-radius: 10px;
      aspect-ratio: 1 / 1;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ff6633;
      align-self: flex-end;
      cursor: pointer;
    }
  }
`;

