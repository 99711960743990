import React, { memo } from "react";
import { ToastWrapper } from "./styles";

interface ToastContainerProps {
  title: string;
  content: string;
}

const ToastContainer: React.FC<ToastContainerProps> = ({ title, content }) => {
  return (
    <ToastWrapper>
      <div className="iconContainer">!</div>
      <div className="toastTextContainer">
        <div className="toastTitle">{title}</div>
        <div className="toastContent">{content}</div>
      </div>
    </ToastWrapper>
  );
};

export default memo(ToastContainer);
