import React, { memo, useEffect, useRef, useState } from "react";
import { Wrapper, Button } from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setPagination } from "../../redux/store/reducers/orderSlice";

interface PaginationProps {
  pageSize?: number;
  listLength: number;
  onClick: (take: number, skip: number) => void;
}

export interface PaginationType {
  take: number;
  skip: number;
}

const Pagination: React.FC<PaginationProps> = ({
  pageSize = 10,
  listLength,
  onClick,
}) => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector((state) => state.order.pagination);

  const [currentPage, setCurrentPage] = useState<number>(pagination.page);
  const [pageInput, setPageInput] = useState<boolean>(false);

  const pageAmount = Math.ceil(listLength / pageSize);
  const inputRef = useRef(null);

  useEffect(() => {
    dispatch(
      setPagination({
        ...pagination,
        page: currentPage,
        skip: currentPage === 1 ? 0 : pageSize * (currentPage - 1),
      }),
    );
    return () => {
      dispatch(
        setPagination({
          take: 10,
          skip: currentPage === 1 ? 0 : pageSize * (currentPage - 1),
          page: currentPage,
        }),
      );
    };
  }, [currentPage]);

  const renderButtons = () => {
    return Array.from(Array(pageAmount + 1).keys())
      .slice(1)
      .map((n, index) => {
        if (index === 9) {
          return (
            <>
              <input
                ref={inputRef}
                type="number"
                onBlur={() => {
                  setPageInput(false);
                }}
                autoFocus
                max={pageAmount}
                onKeyDown={(e) => {
                  if (e.key === "Enter" || e.keyCode === 13) {
                    //@ts-ignore
                    setCurrentPage(Number(inputRef.current.value));

                    onClick(
                      10,
                      //@ts-ignore
                      Number(inputRef.current.value) === 1
                        ? 0
                        : //@ts-ignore
                          pageSize * (Number(inputRef.current.value) - 1),
                    );
                    //@ts-ignore
                    inputRef?.current.blur();
                  }
                }}
              />
              <Button
                square
                className="inputToggle"
                key={`pageButton_${index}`}
                onClick={() => {
                  setPageInput(true);
                  //@ts-ignore
                  setTimeout(() => {
                    //@ts-ignore
                    inputRef && inputRef?.current?.focus();
                  }, 150);
                }}
              >
                ...
              </Button>
            </>
          );
        }
        if (index > 9 && index === pageAmount - 1) {
          return (
            <Button
              square
              className={`${currentPage === n && "active"}`}
              key={`pageButton_${index}`}
              onClick={() => {
                setCurrentPage(n);
                onClick(10, n === 1 ? 0 : pageSize * (n - 1));
              }}
            >
              {n}
            </Button>
          );
        }
        return (
          <Button
            square
            className={`${currentPage === n && "active"}`}
            key={`pageButton_${index}`}
            onClick={() => {
              setCurrentPage(n);
              onClick(10, n === 1 ? 0 : pageSize * (n - 1));
            }}
          >
            {n}
          </Button>
        );
      });
  };

  return (
    <Wrapper inputToggle={pageInput}>
      <Button
        onClick={() => {
          if (currentPage !== 1) {
            setCurrentPage(currentPage - 1);
            onClick(
              10,
              currentPage - 1 === 1 ? 0 : pageSize * (currentPage - 2),
            );
          }
        }}
      >
        Назад
      </Button>
      {renderButtons()}
      <Button
        onClick={() => {
          if (currentPage !== pageAmount) {
            setCurrentPage(currentPage + 1);
            onClick(10, currentPage + 1 === 1 ? 0 : pageSize * currentPage);
          }
        }}
      >
        Вперед
      </Button>
    </Wrapper>
  );
};

export default memo(Pagination);
