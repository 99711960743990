import styled, { keyframes } from "styled-components";

const loading = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingBox = styled.div<{
  noHeight?: boolean;
  half?: boolean;
  height?: string;
}>`
  width: 100%;
  height: ${({ half, noHeight, height }) =>
    height
      ? height
      : half
      ? noHeight
        ? "100%"
        : "50vh"
      : "calc(100vh - 137px)"};
  min-height: ${({ noHeight }) => (noHeight ? "unset" : "500px")};
  display: flex;

  .box {
    margin: auto;
    width: fit-content;

    .loading {
      width: 85px;
      height: 85px;
      animation-name: ${loading};
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      display: flex;
      margin: auto;

      svg {
        margin: auto 0 0 auto;
      }
    }

    .text {
      margin-top: 80px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
      }

      .desc {
        width: 300px;
        margin-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
`;
