export const emptyTagResult = {
  loading: false,
  error: false,
  success: true,
  data: [
    {
      id: 165,
      title: "Автокран",
      tags: "поднять кран опустить приподнять поднимать ",
      categoryId: 179,
      category: {
        id: 179,
        icon: "assets/images/crane_3.png",
        title: "Автокран",
        commission: "0.00",
        checklistId: 0,
      },
    },
    {
      id: 166,
      title: "Автовышка",
      tags: "поднять высотные работы поднимать вышка баннер высота Автовышка подъемные работы подъёмник поднять высота высоты груз грузить крыша потолок под потолком завод помещение",
      categoryId: 178,
      category: {
        id: 178,
        icon: "assets/images/hoist.png",
        title: "Автовышка",
        commission: "0.00",
        checklistId: 0,
      },
    },
    {
      id: 196,
      title: "Экскаватор-погрузчик",
      tags: "котлован кронверк экскаватор эскаватор эсковатор эсковатар копает выкопать капает выкапать капать копать рыть экскаватор экскаваторы погрузчик землеройная, фундамент, траншея, котлован, каталаван, котловон, катлаван, котлован, кронверг, кранверг, кронверк, кронвер, кранверк, фундаменд фундамент фундаминт фундаминд погрузчики погрузить дорожная выкопать траншея фундамент план планировка планировщик передвинуть потянуть копать копка монтажвырыть взрыть разрыть раскопать раскапать разрыть перерыть яма яму траншею троншею фундамент вырыть",
      categoryId: 4,
      category: {
        id: 4,
        icon: "assets/images/excavator.png",
        title: "Экскаватор погрузчик",
        commission: null,
        checklistId: 8,
      },
    },
    {
      id: 182,
      title: "Самосвал ",
      tags: "Самосвал земля возить вывезти грунт песок щебень нерудные строительная самасвал савок совок писок чернозём черназём ",
      categoryId: 110,
      category: {
        id: 110,
        icon: "assets/images/tipper.png",
        title: "Самосвал",
        commission: null,
        checklistId: 8,
      },
    },
    {
      id: 163,
      title: "Манипулятор",
      tags: "перевезти загрузить перевозка груз выгрузить ",
      categoryId: 210,
      category: {
        id: 210,
        icon: "assets/images/crane_truck.png",
        title: "Манипулятор",
        commission: null,
        checklistId: null,
      },
    },
  ],
};
