import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  background-color: transparent;
`;

export const Text = styled.div`
  margin: 25px 0px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;
`;

export const ContentPart = styled.div`
  width: 100%;
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  height: 48px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 12px 6px;

  img {
    width: 24px;
    height: 24px;
  }

  .selectGroup {
    display: flex;
    gap: 8px;
    align-items: center;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
    }
  }

  .arrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
  }
  /* @media only screen and (max-width: 320px) {
    width: 275px;
  }
  @media only screen and (max-width: 480px) {
    width: 430px;
  } */
`;

export const SelectBody = styled.div`
  display: block;
  width: 100%;
  justify-content: stretch;

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }

  label {
    opacity: 0.5;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #222222;
  }
  select {
    width: 100%;
    border: none;
    font-size: 15px;
    line-height: 1.07;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.25px;
  }
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #f63;
  margin-bottom: 9px;
`;
