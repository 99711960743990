import countries from "../constants/world.json";

export const getCountryByPhone = (
  phone: string,
):
  | undefined
  | {
      data: {
        id: number;
        alpha2: string;
        alpha3: string;
        name: string;
        country_id: number;
        flag: string;
        dial_code: string;
      };
      text: string;
      open: false;
    } => {
  let result: any = undefined;

  countries.forEach((country) => {
    if (!result)
      phone.startsWith(country.dial_code) &&
        (result = {
          data: { ...country },
          text: country.name,
          open: false,
        });
  });

  return result;
};
