import React, { memo, useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Docs from "../../components/infoAboutOrderContainer/Docs";
import OrderDetailedInfo from "../../components/orderDetailedInfo";
import Proposals from "../../components/proposals";
import start from "../../assets/images/24-basic-star.png";
import { ReactComponent as ChatIcon } from "../../assets/icons/24-chatting-comment.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/icons-edit-pencil.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  clearSingleOrder,
  fetchCancelOffer,
  fetchSendRespond,
  getMyOrders,
  getOrderDocs,
  getOrderImages,
  getOrderOffers,
  getOrderWithoutSignIn,
  getSingleOrders,
  getWorkerAgreement,
  editOrderData,
  getOrderActions,
  requestCall,
  getCounterOffers,
} from "../../redux/store/reducers/orderSlice";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { RootState } from "../../redux/store/store";
import { ContactsBlock, Content, Links, Section, Support } from "./styles";
import { ReactComponent as CallIcon } from "../../assets/icons/icon-24-intellicon-call-call-calling.svg";
import PageHeader from "../../UI/PageHeader";
import Loading from "../../components/loading";
import PageNavigation, { NavigationItem } from "../../UI/PageNavigation";
import Button from "../../UI/Button";
import avatar from "../../assets/images/avatar.png";
import ProposalPopUp from "../../components/popUp/ProposalPopUp";
import {
  setOpenProposalPopUp,
  setPageToRedirect,
  setWorkerRespond,
} from "../../redux/store/reducers/registrationSlice";
import { checkUserRegistered } from "../../utils/checkUserRegistered";
import useHttpRequest from "../../hooks/useHttpRequest";
import DeleteOrderPopUp from "../../components/popUp/deleteOrderPopUp";
import { ENDPOINT } from "../../constants/general";
import ClosedOrder from "../../components/closedOrder";
import PageWrapper from "../../UI/PageWrapper";
import { createWorkerAgreement } from "../../redux/store/reducers/orderSlice";
import { getAddressesAsync } from "../../redux/modules/getAddresses";
import HistoryOfOrder from "../../components/historyOfOrder";
import { ReactComponent as TelegramLogo } from "../../assets/icons/24-symbols-telegram.svg";
import { ReactComponent as VKontakteLogo } from "../../assets/icons/24-symbols-vk.svg";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/24-symbols-youtube.svg";
import { SocialMediaLogo } from "../../components/newFooter/styled";
import MapContainer from "./mapContainer";
import StatusHistorySidebar from "../../components/statusHistorySidebar";
import OrderStatus from "../../components/orderStatus";
import WaitingOfferBlock from "./waitingOfferBlock";
import OrderPaid from "./orderPaid";
import { getAvatarUrl } from "../../components/proposalItem";

const SocialMedias = [
  {
    icon: <YouTubeLogo />,
    link: "https://www.youtube.com/channel/UCGuavrdpoW8BvdhfgfGmdTg",
  },
  {
    icon: <TelegramLogo />,
    link: "https://t.me/Rukki_Pro",
  },
  {
    icon: <VKontakteLogo />,
    link: "https://vk.com/rukkipro",
  },
];

const code = `
  var myZadarmaCallmeWidget5018;
  var myZadarmaCallmeWidgetFn5018 = function() {
    myZadarmaCallmeWidget5018 = new ZadarmaCallmeWidget("myZadarmaCallmeWidget5018");
    myZadarmaCallmeWidget5018.create({
  "widgetId": "J4Lt3rNYBpk7uPuuBf995bSLa659dLmG75smt99MnF4kRfhuubynH56La49Ygs4nz5csXUtncRja1shVxx3r3zEzbknR6Bub4101d46322b2ff7f607aa7a91c3e7fdd", "sipId":"152713_0_1", "domElement":"myZadarmaCallmeWidget5018" }, { "shape":"square", "language":"ru", "width":"0", "dtmf":false, "font": "'Trebuchet MS','Helvetica CY',sans-serif", "color_call": "rgb(255, 255, 255)", "color_bg_call": "rgb(255,102,51)", "color_border_call": "rgb(255,102,51)", "color_connection": "rgb(255, 255, 255)", "color_bg_connection": "rgb(255,183,0)", "color_border_connection": "rgb(255,219,128)", "color_calling": "rgb(255, 255, 255)", "color_border_calling": "rgb(144,233,211)", "color_bg_calling": "rgb(33,211,167)", "color_ended": "rgb(255, 255, 255)", "color_bg_ended": "rgb(164,164,164)", "color_border_ended": "rgb(210, 210, 210)"
    });
  };

  myZadarmaCallmeWidgetFn5018();
  if (window.addEventListener) {
    window.addEventListener('load', myZadarmaCallmeWidgetFn5018, false);
  } else if (window.attachEvent) {
    window.attachEvent('onload', myZadarmaCallmeWidgetFn5018);
  }
`;

const InfoAboutOrderPage: React.FC<{ detailRef?: any }> = ({ detailRef }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let { id, userType } = useParams();
  const [currentItem, setCurrentItem] = useState<string>("Статус заказа");
  const [selectedWorker, setSelectedWorker] = useState<any>(undefined);
  const [proposalPopUp, setProposalPopUp] = useState<boolean>(false);
  const [deletePopUp, setDeletePopUp] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [callButtonDisabled, setCallButtonDisalbed] = useState<boolean>(false);
  const [fallbackCoords, setFallbackCoords] = useState<[number, number] | null>(
    null,
  );
  const [orderStatus, setOrderStatus] = useState<string>("created");
  const [modal, setModal] = useState({
    userAgreement: false,
    confidentiality: false,
  });
  const zadarmaContainerRef = useRef(null);

  const { user, userLoading } = useAppSelector(
    (state: RootState) => state.auth,
  );

  const {
    offers,
    orderDocs,
    singleOrder,
    singleOrderLoading,
    singleOrderNotFound,
    orderActions,
    orderActionsLoading,
  } = useAppSelector((state) => state.order);

  const openAppointPopUp = useAppSelector(
    (state) => state.registration.openAppointPopUp,
  );

  const [deleteProcess, deleteRequest] = useHttpRequest({
    onSuccess: () => {
      navigate("/o");
      setDeletePopUp(false);
    },
    onError: () => {
      setDeletePopUp(false);
    },
  });

  const MenuList = [
    {
      label: "Редактировать заказ",
      onClick: () => navigate(`/o/${id}/edit`),
    },
    {
      label: "Отменить заказ",
      onClick: () => setDeletePopUp(true),
    },
  ];

  const CustomerNavigationList: NavigationItem[] = [
    {
      label: "Статус заказа",
      id: "order_process",
    },
    {
      label: "История заказа",
      id: "order_history",
    },
    {
      label: "Предложения исполнителей",
      id: "order_proposals",
      counterValue: offers?.length,
    },
    {
      label: "Информация о заказе",
      id: "order_info",
    },
    {
      label: "На карте",
      id: "order_map",
    },
    {
      label: "Документация к заказу",
      id: "order_docs",
      disabled: !orderDocs?.length,
    },
  ];

  const WorkerNavigationList: NavigationItem[] = [
    {
      label: "Статус заказа",
      id: "order_process",
    },
    {
      label: "Предложения исполнителей",
      id: "order_proposals",
      counterValue: offers?.length,
    },
    {
      label: "Информация о заказе",
      id: "order_detailed_info",
    },
    {
      label: "На карте",
      id: "order_map",
    },
    {
      label: "Документация к заказу",
      id: "order_docs",
      disabled: !orderDocs?.length,
    },
  ];

  const callHandler = () => {
    setCallButtonDisalbed(true);
    setTimeout(() => {
      setCallButtonDisalbed(false);
    }, 60000);
    const offerId: number | undefined = offers.find(
      (offer: any) =>
        offer.status === "WaitingForPayment" || offer.status === "Accepted",
    )?.id;
    if (user?.customer && offerId) {
      dispatch(
        requestCall({
          refId: user?.customer.refId,
          orderId: singleOrder?.order?.id,
          offerId,
        }),
      );
    }
  };

  useEffect(() => {
    if (singleOrder?.history?.length && singleOrder?.offers?.length) {
      singleOrder?.history?.map((historyItem: any) => {
        if (historyItem.type === "worker_selected") {
          const worker = singleOrder?.offers?.find(
            (offer: any) => offer.workerId === historyItem.workerId,
          )?.worker;
          setSelectedWorker(worker);
        }
      });
    }

    if (
      singleOrder &&
      (!singleOrder?.order?.latitude || !singleOrder?.order?.longitude)
    ) {
      dispatch(getAddressesAsync({ text: singleOrder?.order?.address })).then(
        (response) => {
          if (user?.customer?.id === singleOrder?.order?.creatorId) {
            dispatch(
              editOrderData({
                refId: user?.customer?.refId!,
                orderId: id!,
                orderData: {
                  latitude: response.payload.suggestions[0].data.geo_lat,
                  longitude: response.payload.suggestions[0].data.geo_lon,
                },
              }),
            );
          }
          setFallbackCoords([
            response.payload.suggestions[0].data.geo_lat,
            response.payload.suggestions[0].data.geo_lon,
          ]);
        },
      );
    }
  }, [singleOrder]);

  const cancelOrderHandler = () => {
    deleteRequest({
      method: "DELETE",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/delete`,
    });
  };

  const fetchRespondHandler = async (data: any) => {
    if (user && checkUserRegistered(user)) {
      await dispatch(
        fetchSendRespond({
          ...data,
          orderId: singleOrder?.order?.id,
          refId: user?.customer?.refId,
        }),
      ).then(() => {
        dispatch(getWorkerAgreement({ refId: user?.customer?.refId })).then(
          (response) => {
            if (!response.payload) {
              dispatch(
                createWorkerAgreement({
                  refId: user?.customer?.refId,
                }),
              );
            }
          },
        );
        dispatch(
          getSingleOrders({
            refId: user?.customer?.refId,
            orderId: id,
          }),
        );
        dispatch(
          getOrderOffers({
            refId: user?.customer?.refId,
            orderId: id,
          }),
        );
        dispatch(
          getMyOrders({
            refId: user?.customer?.refId,
            take: 10,
            skip: 0,
          }),
        );
      });
    } else {
      dispatch(setPageToRedirect(`/o/${singleOrder?.order?.id}`));
      dispatch(
        setWorkerRespond({
          ...data,
          orderId: id,
          refId: user?.customer?.refId,
        }),
      );
      navigate("/registration");
    }
  };

  const redirectToChatHandler = async () => {
    // if (user?.customer?.refId) {
    //   const { data } = await axios.post(
    //     `${ENDPOINT}/messages/${user.customer.refId}/init`,
    //     {
    //       userId: worker?.id,
    //       customerId: singleOrder?.customer?.id,
    //       orderId: singleOrder?.order?.id,
    //     },
    //     {
    //       headers: {
    //         authorization: `Bearer ${getToken()}`,
    //       },
    //     }
    //   );
    //   navigate(`/messages/${data.id}`);
    // }
  };

  const handleCancelOffer = async () => {
    const currentOffer = offers.find(
      ({ worker }: any) => worker?.refId === user?.customer?.refId,
    );

    if (user?.customer?.refId && currentOffer) {
      await dispatch(
        fetchCancelOffer({
          refId: user?.customer?.refId,
          offerId: currentOffer?.id,
          orderId: singleOrder?.order?.id,
        }),
      ).then(() => {
        dispatch(
          getSingleOrders({
            refId: user?.customer?.refId!,
            orderId: id,
          }),
        );

        dispatch(
          getOrderOffers({
            refId: user?.customer?.refId!,
            orderId: id,
          }),
        );
      });
    }
  };

  useEffect(() => {
    if (singleOrder?.history && offers.length && singleOrder.order) {
      if (singleOrder?.history?.length === 1 && offers?.length === 0) {
        setOrderStatus("created");
      }
      if (
        offers?.length > 0 &&
        singleOrder.order.status === "Published" &&
        !offers?.find((offer) => offer.status === "WaitingForPayment")
      ) {
        setOrderStatus("got_offer");
      }
      if (
        offers?.length > 0 &&
        singleOrder.order.status === "Published" &&
        offers?.find((offer) => offer.status === "WaitingForPayment")
      ) {
        setOrderStatus("worker_selected");
      }
      if (offers?.length > 0 && singleOrder.order.status === "WorkerSelected") {
        setOrderStatus("waiting_for_payment");
      }
      if (
        offers?.length > 0 &&
        singleOrder.order.status === "GoingToDestination"
      ) {
        setOrderStatus("worker_on_the_way");
      }
      if (offers?.length > 0 && singleOrder.order.status === "WorkerSetOut") {
        setOrderStatus("worker_set_out");
      }
      if (offers?.length > 0 && singleOrder.order.status === "InProgress") {
        setOrderStatus("worker_started");
      }
      if (
        offers?.length > 0 &&
        singleOrder?.history.find(
          (historyItem: any) => historyItem.type === "wait_for_accept",
        )
      ) {
        setOrderStatus("worker_finished");
      }
      if (
        offers?.length > 0 &&
        singleOrder.order.status === "Done" &&
        singleOrder.order.feedbacks.length === 0
      ) {
        setOrderStatus("done");
      }
      if (
        offers?.length > 0 &&
        singleOrder.order.status === "Done" &&
        singleOrder.order.feedbacks.length > 0
      ) {
        if (
          singleOrder.order.feedbacks.find(
            (feedback: any) => feedback.createdBy.id === user?.customer.id,
          )
        ) {
          setOrderStatus("feedback_left");
        }
      }
    }
  }, [offers, singleOrder, orderStatus, dispatch]);

  useEffect(() => {
    if (user?.customer?.refId && id) {
      dispatch(
        getSingleOrders({ refId: user?.customer?.refId, orderId: id }),
      ).then((result: any) => {
        if (result.payload) {
          dispatch(
            getOrderImages({
              refId: user?.customer?.refId,
              orderId: id,
            }),
          );
          dispatch(
            getCounterOffers({
              refId: user?.customer.refId,
              orderId: id!,
            }),
          );
          dispatch(
            getOrderOffers({
              refId: user?.customer?.refId,
              orderId: id,
            }),
          );
          dispatch(
            getOrderDocs({
              refId: user?.customer?.refId,
              orderId: id,
            }),
          );
          dispatch(
            getOrderActions({
              refId: user?.customer?.refId,
              orderId: id,
            }),
          );
          dispatch(getVehicleCategories());
          if (user?.customer?.role === "worker" && openAppointPopUp) {
            setProposalPopUp(true);
            dispatch(setOpenProposalPopUp(false));
          }
          setPageLoading(false);
        }
        setPageLoading(false);
      });
    } else {
      if (
        userLoading !== "pending" &&
        !user &&
        !localStorage.getItem("accessToken")
      ) {
        dispatch(getOrderWithoutSignIn(id));
        dispatch(getVehicleCategories()).then(() => {
          setPageLoading(false);
        });
      }
    }
    return () => {
      dispatch(clearSingleOrder());
    };
  }, [user?.customer?.refId]);

  const renderContactBlock = useCallback(() => {
    if (offers.length && user && singleOrder) {
      const selectedOffer = offers.find(
        (offer: any) =>
          offer.status === "Accepted" || offer.status === "WaitingForPayment",
      );
      if (
        user?.customer.role === "customer" &&
        user.customer.id === singleOrder.customer.id &&
        selectedOffer
      ) {
        return (
          <ContactsBlock>
            <div className="imageContainer">
              <img
                src={
                  selectedOffer.worker?.avatarUrl?.length
                    ? getAvatarUrl(selectedOffer.worker.avatarUrl)
                    : avatar
                }
                alt=""
              />
            </div>
            <div className="contactInfo">
              <div className="contactTitle">
                {`${
                  selectedOffer.worker.firstName &&
                  selectedOffer.worker.firstName
                } ${
                  selectedOffer.worker.secondName &&
                  selectedOffer.worker.secondName
                }`}
              </div>
              <div className="contactSubtitle">Исполнитель</div>
            </div>
            <div className="contactButtons">
              <ChatIcon onClick={redirectToChatHandler} />
              <CallIcon
                style={{
                  opacity: callButtonDisabled ? "50%" : "100%",
                }}
                onClick={() => !callButtonDisabled && callHandler()}
              />
            </div>
          </ContactsBlock>
        );
      }

      if (
        user?.customer.role === "worker" &&
        selectedOffer &&
        selectedOffer?.worker.id === user.customer.id
      ) {
        return (
          <ContactsBlock>
            <div className="imageContainer">
              <img
                src={
                  selectedOffer.worker.avatarUrl
                    ? getAvatarUrl(selectedOffer.worker.avatarUrl)
                    : avatar
                }
                alt=""
              />
            </div>
            <div className="contactInfo">
              <div className="contactTitle">
                {`${
                  singleOrder.customer.firstName &&
                  singleOrder.customer.firstName
                } ${
                  singleOrder.customer.secondName &&
                  singleOrder.customer.secondName
                }`}
              </div>
              <div className="contactSubtitle">Заказчик</div>
            </div>
            <CallIcon
              style={{
                opacity: callButtonDisabled ? "50%" : "100%",
              }}
              onClick={() => !callButtonDisabled && callHandler()}
            />
          </ContactsBlock>
        );
      }
    }
    return null;
  }, [offers, user, singleOrder]);

  const renderOfferBlock = useCallback(() => {
    if (
      user?.customer.role === "worker" &&
      user?.customer.id !== singleOrder?.customer?.id
    ) {
      return offers.find((offer) => offer.workerId === user.customer.id) ? (
        <div className="responseBlock">
          <div className="responseHeader">Вы откликнулись</div>
          <div className="text">
            Вы можете отменить свое предложение до момента подписания
            документов.
          </div>
          {!orderDocs?.find(
            (doc: any) =>
              doc.documentType === "tech_task" && doc.isSignedByWorker === 1,
          ) && (
            <div className="cancelOffer" onClick={handleCancelOffer}>
              Отозвать отклик
            </div>
          )}
        </div>
      ) : (
        <div className="responseBlock">
          <div className="responseHeader">Предложите свои услуги</div>
          <div className="text">
            Отправьте свое предложение цены и сроков, если хотите выполнить
            работу
          </div>
          <Button
            width="181px"
            height="48px"
            fontWeight="normal"
            onClick={() => {
              if (user?.customer?.role === "worker") {
                setProposalPopUp(true);
              } else {
                dispatch(setPageToRedirect(`/o/${id}`));
                dispatch(setOpenProposalPopUp(true));
                navigate("/login?role=worker");
              }
            }}
          >
            <img src={start} alt="vehicles_icon" />
            Откликнуться
          </Button>
        </div>
      );
    } else return null;
  }, [user, offers, singleOrder, orderDocs]);

  useEffect(() => {
    if (zadarmaContainerRef.current) {
      const element = document.createElement("script");
      element.innerHTML = code;

      document.documentElement.prepend(element);
    }
  }, [zadarmaContainerRef.current]);

  if (singleOrderLoading === "failed" && singleOrderNotFound && !pageLoading) {
    return <ClosedOrder />;
  }

  if (singleOrder && !pageLoading) {
    return (
      <PageWrapper
        flexDirection="column"
        noHeight
        minHeight="calc(100vh - 65px)"
        //background="#f7f4f1"
      >
        <Content>
          <Section>
            <div className="stickyContainer">
              <div className="group">
                <PageNavigation
                  list={
                    user?.customer?.role === "customer" &&
                    singleOrder?.order?.creatorId === user?.customer?.id
                      ? CustomerNavigationList
                      : WorkerNavigationList
                  }
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                />
                {renderContactBlock()}
                {singleOrder && renderOfferBlock()}
              </div>
              <div className="group">
                <Support id="order_support">
                  <div className="supportHeader">Нужна помощь?</div>
                  <div className="text">
                    Напишите в чат поддержки, мы работаем круглосуточно
                  </div>
                  <div id="zadarmaScripts">{}</div>
                  <Button
                    width="251px"
                    fontSize="16px"
                    fontWeight="normal"
                    height="48px"
                    variant="secondary"
                    onClick={() =>
                      window.open("https://t.me/Rukki_Pro", "_blank")
                    }
                  >
                    <ChatIcon /> Написать в поддержку
                  </Button>

                  <div
                    id="myZadarmaCallmeWidget5018"
                    className="callMeWidget"
                    ref={zadarmaContainerRef}
                  ></div>
                </Support>
                <Links>
                  <div className="row">
                    {SocialMedias.map(({ icon, link }, index) => (
                      <SocialMediaLogo
                        target={"_blank"}
                        rel="noreferrer"
                        key={`link_${index}`}
                        href={link}
                      >
                        {icon}
                      </SocialMediaLogo>
                    ))}
                  </div>
                  <div className="row">
                    <div
                      onClick={() =>
                        setModal({
                          ...modal,
                          userAgreement: true,
                        })
                      }
                    >
                      Правила Сервиса
                    </div>
                    <div
                      onClick={() =>
                        setModal({
                          ...modal,
                          confidentiality: true,
                        })
                      }
                    >
                      Конфиденциальность
                    </div>
                  </div>
                </Links>
              </div>
            </div>
          </Section>
          <Section>
            <PageHeader
              menuList={MenuList}
              paddingTop="0px"
              button={
                user?.customer?.role === "customer" &&
                singleOrder?.order?.creatorId === user?.customer?.id &&
                !singleOrder?.offers?.length ? (
                  <Button
                    variant="secondary"
                    fontWeight="normal"
                    onClick={() => navigate("edit")}
                    height="32px"
                    width="189px"
                  >
                    <EditIcon /> Редактировать
                  </Button>
                ) : null
              }
            >
              {`Заказ ${singleOrder?.order?.id}`}{" "}
              {!!offers.find((offer: any) => offer.status === "Accepted") && (
                <OrderPaid />
              )}
            </PageHeader>
            <div className="mobileNavigation">
              <PageNavigation
                list={
                  user?.customer?.role === "customer" &&
                  singleOrder?.order?.creatorId === user?.customer?.id
                    ? CustomerNavigationList
                    : WorkerNavigationList
                }
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
              />
            </div>
            {user?.customer?.refId &&
            ((user?.customer?.role === "customer" &&
              (singleOrder?.order?.creatorId === user?.customer?.id ||
                user.customer.companyId === singleOrder.order.company?.id)) ||
              (user?.customer?.role === "worker" &&
                offers?.find(
                  ({ status }: any) =>
                    status === "WaitingForPayment" || status === "Accepted",
                )?.workerId === user?.customer?.id)) ? (
              <OrderStatus
                role={user?.customer?.role}
                history={singleOrder?.history}
                orderId={id!}
                refId={user?.customer?.refId}
                status={orderStatus}
                workerId={
                  user?.customer?.role === "customer"
                    ? offers?.find((offer: any) => offer?.status === "Accepted")
                        ?.workerId
                    : user.customer.id
                }
                feedbacks={singleOrder.order.feedbacks}
                userId={user.customer.id}
                userAccessSettings={
                  user.customer.companyId ? user.customer.accessSettings : null
                }
              />
            ) : null}

            {!!orderActions?.length && (
              <HistoryOfOrder
                orderHistoryInfo={orderActions}
                workerId={
                  offers?.find((offer: any) => offer?.status === "Accepted")
                    ?.workerId
                }
              />
            )}

            {user?.customer?.refId ? (
              offers?.length ? (
                <Proposals />
              ) : (
                singleOrder?.order?.creatorId === user?.customer?.id && (
                  <WaitingOfferBlock />
                )
              )
            ) : null}

            <OrderDetailedInfo fallbackCoords={fallbackCoords} />
            <MapContainer
              fallbackCoords={fallbackCoords}
              coords={[
                singleOrder?.order?.latitude,
                singleOrder?.order?.longitude,
              ]}
              address={singleOrder?.order?.address}
            />
            {user?.customer && !!orderDocs?.length
              ? (offers.find(
                  (offer: any) =>
                    (offer.status === "WaitingForPayment" ||
                      offer.status === "Accepted") &&
                    offer.workerId === user.customer.id,
                ) ||
                  singleOrder.customer.id === user.customer.id ||
                  user?.customer.companyId ===
                    singleOrder.order.company?.id) && (
                  <Docs
                    userInfo={{
                      ...user?.customer!,
                    }}
                  />
                )
              : null}
            {singleOrder && renderOfferBlock()}
          </Section>
        </Content>
        {proposalPopUp && (
          <ProposalPopUp
            orderPaymentType={singleOrder?.order?.paymentType}
            close={() => setProposalPopUp(false)}
            onSubmit={fetchRespondHandler}
            orderCategoryId={singleOrder?.order?.categoryId}
          />
        )}
        {deletePopUp && (
          <DeleteOrderPopUp
            loading={deleteProcess?.loading}
            close={() => setDeletePopUp(false)}
            onSubmit={() => {
              cancelOrderHandler();
            }}
          />
        )}
      </PageWrapper>
    );
  }

  return (
    <Loading
      title="Загрузка заказа"
      description="Не закрывайте окно браузера"
    />
  );
};

export default InfoAboutOrderPage;

