import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 4px;
  svg {
    width: 18px;
    height: 18px;
  }
`;
