import React, { memo } from "react";
import { ReactComponent as Success } from "../../assets/icons/payment_success.svg";
import { ContentTitle, Wrapper } from "./styles";

const SuccessPaymentPage: React.FC = () => {
  return (
    <Wrapper>
      <Success width={100} height={150} />
      <ContentTitle>Спасибо, оплата прошла успешно!</ContentTitle>
      <ContentTitle>
        Это окно скоро закроется, но вы можете закрыть его сами
      </ContentTitle>
    </Wrapper>
  );
};

export default memo(SuccessPaymentPage);
