import styled from "styled-components";
import { styles } from "../../styles/styles";

export const TimeBox = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  justify-content: space-between;
  .input-box {
    width: 100%;
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;

      color: rgba(0, 0, 0, 0.539261);
    }
    .ant-picker {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
      outline: 0;
      box-shadow: unset !important;
      border-radius: 10px;
      height: 48px;

      .ant-picker-suffix {
        display: none;
      }
    }
  }
  .date {
  }
  .pill {
    padding: 2.5px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: rgba(153, 153, 153, 0.8);
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    margin: 12px 8px 0 0;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
  .active {
    border-color: ${styles.mainColor};
    color: ${styles.mainColor};
  }
`;

export const PillWrapper = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  margin-top: 8px;

  .pill {
    padding: 2.5px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: rgba(153, 153, 153, 0.8);
    border: 1px solid #e3e3e3;
    border-radius: 8px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }

  .active {
    border-color: ${styles.mainColor};
    color: ${styles.mainColor};
  }

  @media screen and (max-width: 375px) {
    gap: 4px;

    .pill {
      padding: 2.5px 4px;
    }
  }
`;

export const Description = styled.div`
  width: 100%;
  margin: 24px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
