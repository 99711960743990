import React, { memo, useEffect, useState } from "react";
import {
  AddNewImg,
  DropdownItem,
  DropdownList,
  DropdownWrapper,
  ErrorMsg,
  ErrorsWrapper,
  InputsWrapper,
  InputWrapper,
  PhotoVideoBox,
  PhotoVideoItem,
  PhotoVideoList,
  PVTitle,
  RegisterForm,
  SelectWrapper,
  SubBtn,
} from "./styles";
import { ReactComponent as DropdownIcon } from "../../../assets/icons/icons-expand-more.svg";
import { ReactComponent as HoistIcon } from "../../../assets/icons/icons-vehicles-hoist.svg";
import { Button } from "../../form/styles";
import NumberFormat from "react-number-format";
import { ENDPOINT_FOR_IMG } from "../../../constants/general";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import PopUpContainer from "../popUpContainer";
import { httpRequest } from "../../../utils/httpRequest";
import { getVehicleCategories } from "../../../redux/store/reducers/vehicleSlice";
import { ReactComponent as Close } from "../../../assets/icons/close-red.svg";
import pdfIcon from "../../../assets/icons/pdf-icon-popup.png";
import xlsIcon from "../../../assets/icons/xls-icon-popup.png";
import docIcon from "../../../assets/icons/doc-icon-popup.png";
import addImgIcon from "../../../assets/images/add-button.png";
import PhoneInput from "react-phone-number-input/input";
import { characteristics as characteristicsList } from "../../../constants/chars";
import { VEHICLE_PARAMETERS_NAMES } from "../../../utils/uiHelpers";
import { getCompany } from "../../../redux/store/reducers/companySlice";
import { inputVehicleList2 } from "../../../utils/vehicleHelpers";

const getFileIcon = (fileItem: any) => {
  if (fileItem?.file?.type?.includes("image")) {
    return fileItem.base64;
  } else if (
    fileItem?.file?.type?.includes("excel") ||
    fileItem?.file?.type.includes("sheet")
  ) {
    return xlsIcon;
  } else if (fileItem?.file?.type?.includes("pdf")) {
    return pdfIcon;
  } else if (fileItem?.file?.type?.includes("word")) {
    return docIcon;
  } else {
    return null;
  }
};

export const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
}: any) => {
  const photoVideo = value;
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  return type === "img" ? (
    <>
      <PhotoVideoBox>
        <PVTitle>Фотографии с номером и СТС</PVTitle>
        <PhotoVideoList>
          <AddNewImg>
            <img src={addImgIcon} alt="" />
            <input
              type="file"
              multiple
              onChange={(e: any) => {
                const imgs: any = [
                  ...(photoVideo?.length > 0 ? photoVideo : []),
                ];
                for (const file of e?.target?.files) {
                  const form = new FileReader();
                  form.readAsDataURL(file);
                  form.onload = () => {
                    imgs.push({
                      file: file,
                      base64: form?.result,
                    });
                  };
                }
                onChange({ target: { name, value: imgs } });
              }}
            />
          </AddNewImg>
          {photoVideo
            ?.filter((v: any, index: number) =>
              photoVideo?.length <= 7
                ? true
                : photoVideo?.length > 7 && index < 6,
            )
            ?.map((f: any, i: number) => {
              return (
                <PhotoVideoItem>
                  <div className="item">
                    {f?.file?.type.includes("video") ? (
                      <video>
                        <source src={f.base64} />
                      </video>
                    ) : (
                      <img src={getFileIcon(f)} alt={f.base64} />
                    )}
                  </div>
                  <div
                    className="close"
                    onClick={() =>
                      onChange({
                        target: {
                          name,
                          value: photoVideo.filter(
                            (v: any, index: number) => index !== i,
                          ),
                        },
                      })
                    }
                  >
                    <Close />
                  </div>
                </PhotoVideoItem>
              );
            })}
          {photoVideo?.length > 7 && (
            <PhotoVideoItem>
              <div className="item">
                <div className="cover">+{photoVideo?.length - 6}</div>
                <img src={photoVideo?.[6]?.base64} alt="" />
              </div>
            </PhotoVideoItem>
          )}
        </PhotoVideoList>
      </PhotoVideoBox>
    </>
  ) : (
    <InputWrapper
      active={
        (type !== "vehicle_type" && value?.length) ||
        (type === "vehicle_type" && value?.title)
      }
      style={{ width: width ? width : "100%" }}
      className={
        (type !== "vehicle_type" && value?.length) ||
        (type === "vehicle_type" && value?.title)
          ? "active"
          : ""
      }
      select={type === "vehicle_type"}
    >
      {type === "vehicle_type" ? (
        <SelectWrapper>
          {value?.icon ? (
            <img src={`${ENDPOINT_FOR_IMG}/${value?.icon}`} alt="" />
          ) : (
            <HoistIcon />
          )}
          <div className="input-part">
            <span className="label">
              <span className="text">Тип техники</span>{" "}
              <span className="required">{required ? "*" : ""}</span>
            </span>
            <input
              type="text"
              value={value?.title}
              onChange={({ target: { value: title } }) => {
                onChange({
                  target: { name, value: { title } },
                });
              }}
            />
          </div>
          <div className="dropdown">
            <DropdownIcon />
          </div>
          <DropdownWrapper className="dropdown-wrapper">
            <DropdownList>
              {vehicleCategories
                .filter(
                  ({ title }) =>
                    !value?.title ||
                    title?.toLowerCase().includes(value?.title?.toLowerCase()),
                )
                .map(({ id, icon, title, commission, checklistId }) => (
                  <DropdownItem
                    onMouseDown={() => {
                      onChange({
                        target: {
                          name,
                          value: {
                            title,
                            icon,
                            id,
                          },
                        },
                      });
                    }}
                  >
                    {title}
                  </DropdownItem>
                ))}
            </DropdownList>
          </DropdownWrapper>
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          ) : type === "year" ? (
            <NumberFormat
              format="####"
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          ) : type === "number" ? (
            <NumberFormat
              name={name}
              value={value || ""}
              placeholder={placeholder}
              onChange={onChange}
              min={0}
              spellCheck={false}
            />
          ) : type === "vehicle_number" ? (
            <input
              name={name}
              value={value || ""}
              type={"text"}
              placeholder={placeholder}
              onChange={(e: any) => {
                const { value, name } = e?.target;
                const [n1, n2, n3, n4, n5, n6, n7, n8, n9] = value.split("");
                const letters =
                  "абвгдеёжзийклмнопрстуфхцчшщъыьэюяabcdefghijklmnopqrstuvwxyz";

                onChange(e);
              }}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            value ? (
              <PhoneInput
                name={name}
                value={value || ""}
                className="phone-number-input"
                prefix={"+"}
                placeholder={placeholder}
                onChange={(e: any) => {
                  onChange({ target: { value: e, name } });
                }}
                autoFocus
                spellCheck={false}
              />
            ) : (
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                spellCheck={false}
              />
            )
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const AddVehiclePopUp: React.FC<{ close?: any; onSubmit?: any }> = ({
  close,
  onSubmit,
}) => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [errorMsgStep2, setErrorMsgStep2] = useState<any>({
    fullNameEmpty: false,
    phoneNumberEmpty: false,
    emailPattern: false,
    emailEmpty: false,
  });
  const [characteristics, setCharacteristics] = useState<any>();
  const fireBaseData = useAppSelector((state) => state?.auth.fireBaseData);
  const refId = fireBaseData?.user?.refId;
  const dispatch = useAppDispatch();

  const changeFormDataStep2 = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (formData?.type?.title) {
      setCharacteristics(characteristicsList[formData?.type?.title]);
    }
  }, [formData?.type?.title]);

  useEffect(() => {
    if (refId) {
      dispatch(getCompany(refId));
    }
  }, [refId]);

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, []);

  const addVehicle = () => {
    const customerId = refId;
    const {
      type,
      mark_model,
      img,
      vehicle_number,
      year,
      load_capacity,
      length,
      arrowReach,
      height,
      numberAxles,
      volume,
      loadCapacity,
    } = formData;
    const data: { [key: string]: any } = {
      categoryId: type?.id,
      brand: mark_model,
      model: mark_model,
      stateNumber: vehicle_number,
      comment: `${year || ""}${load_capacity ? `,\n${load_capacity}` : ""}${
        length ? `,\n${length}` : ""
      }`,
      productionYear: Number(year),
      arrowReach: arrowReach || undefined,
      height: height || undefined,
      numberAxles: numberAxles || undefined,
      volume: volume || undefined,
      loadCapacity: loadCapacity || undefined,
    };

    httpRequest({
      method: "POST",
      path: `vehicles/${customerId}/add`,
      data,
    })
      .then((res) => {
        const vehicleId = res?.data?.vehicle?.raw?.insertId;

        if (img?.length) {
          const formData = new FormData();
          img.forEach((element: any, index: number) => {
            formData.append(`file${index}`, element?.file);
          });
          httpRequest({
            method: "PUT",
            path: `vehicles/${customerId}/${vehicleId}/images/add`,
            data: formData,
          }).then(() => {
            onSubmit();
          });
        } else {
          onSubmit();
        }
      })
      .catch((err) => {});
  };

  return (
    <PopUpContainer big title="Информация о новом средстве" close={close}>
      <RegisterForm>
        <InputsWrapper>
          <>
            {inputVehicleList2.map(
              ({ label, name, width, type, required }, index) => (
                <>
                  <InputBox
                    label={label}
                    width={width}
                    name={name}
                    onChange={(e: any) => changeFormDataStep2(e)}
                    value={formData?.[name]}
                    type={type}
                    required={required}
                  />
                </>
              ),
            )}
            {characteristics &&
              Object.entries(characteristics).map((item: any) => {
                if (item[1].type !== "radio") {
                  return (
                    <InputBox
                      label={VEHICLE_PARAMETERS_NAMES[item[0]]}
                      name={item[0]}
                      onChange={(e: any) => changeFormDataStep2(e)}
                      value={formData?.[item[0]]}
                      type={""}
                      width={"calc((100% - 8px) / 2)"}
                    />
                  );
                }
              })}
          </>
          <ErrorsWrapper>
            {errorMsgStep2?.fullNameEmpty && (
              <ErrorMsg>Укажите полное имя.</ErrorMsg>
            )}
            {errorMsgStep2?.phoneNumberEmpty && (
              <ErrorMsg>Укажите номер телефона.</ErrorMsg>
            )}
            {errorMsgStep2?.emailEmpty && (
              <ErrorMsg>Укажите адрес электронной почты.</ErrorMsg>
            )}
            {errorMsgStep2?.emailPattern && (
              <ErrorMsg>Электронная почта недействительна.</ErrorMsg>
            )}
          </ErrorsWrapper>
        </InputsWrapper>

        {errorMsg.length > 0 ? (
          <ErrorsWrapper>
            {errorMsg.map((msg: string) => (
              <ErrorMsg>{msg}</ErrorMsg>
            ))}
          </ErrorsWrapper>
        ) : (
          ""
        )}
        <Button
          onClick={() => {
            addVehicle();
          }}
          disabled={
            !(
              formData?.type &&
              formData?.mark_model &&
              formData?.vehicle_number &&
              formData?.year
            )
          }
        >
          Добавить
        </Button>
        <SubBtn>
          Перед тем, как допустить вашу технику к работам, мы проверим
          информацию о ней
        </SubBtn>
      </RegisterForm>
    </PopUpContainer>
  );
};

export default memo(AddVehiclePopUp);
