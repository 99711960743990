import React, { memo } from "react";
import PageWrapper from "../../UI/PageWrapper";
import { Section } from "./styles";
import PageHeader from "../../UI/PageHeader";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../sidebar";
import Button from "../../UI/Button";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add-white.svg";
import { useAppSelector } from "../../redux/hooks";

const ProfileContainer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const vehicle = useAppSelector((state) => state.vehicle.vehicleList);

  if (location.pathname === "/") {
    navigate("/profile");
  }

  const MenuList =
    location.pathname === "/employee-management" ||
    location.pathname === "/employees"
      ? [
          {
            label: "Добавить сотрудника",
            onClick: () => navigate("/create-employee"),
          },
        ]
      : location.pathname === "/vehicle-management"
      ? [
          {
            label: "Добавить технику",
            onClick: () => navigate("/create-vehicle"),
          },
        ]
      : [];

  const getPageHeader = (path: string) => {
    switch (path) {
      case "/profile":
        return "Профиль";
      case "/profile/employee-management":
        return "Управление сотрудниками";
      case "/profile/vehicle-management":
        return "Управление техникой";
      case "/profile/rating":
        return "Отзывы и рейтинг";
      case "/profile/create-employee":
        return "Добавление сотрудника";
      case "/profile/create-vehicle":
        return "Добавление техники";
      case "/profile/employees":
        return "Сотрудники";
      case "/profile/settings":
        return "Настройки";
      default:
        return "";
    }
  };

  return (
    <PageWrapper
      noHeight
      minHeight="calc(100vh - 137px)"
      gap="16px"
      paddingTop="24px"
    >
      <Section>
        <div className="stickyContainer">
          <Sidebar />
        </div>
      </Section>
      <Section>
        <PageHeader
          paddingTop="11px"
          backButton={
            location.pathname === "/profile/create-vehicle" ? true : false
          }
          menuList={MenuList}
          button={
            vehicle?.length &&
            location.pathname === "/profile/vehicle-management" ? (
              <Button
                onClick={() => navigate("/profile/create-vehicle")}
                height="32px"
                fontSize="15px"
                fontWeight="normal"
                width="fit-content"
              >
                <PlusIcon />
                Добавить технику
              </Button>
            ) : location.pathname === "/profile/employee-management" ||
              (location.pathname === "/profile/employees" &&
                user?.customer?.accessSettings?.addingEmployees) ? (
              <Button
                onClick={() => navigate("/profile/create-employee")}
                height="32px"
                fontSize="15px"
                fontWeight="normal"
                width="fit-content"
              >
                <PlusIcon />
                Добавить сотрудника
              </Button>
            ) : (
              <></>
            )
          }
        >
          {getPageHeader(location.pathname)}
        </PageHeader>
        <Outlet />
      </Section>
    </PageWrapper>
  );
};

export default memo(ProfileContainer);

