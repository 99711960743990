import React, { memo, useState } from "react";
import { Wrapper } from "./styles";
import { ReactComponent as RadarIcon } from "../../assets/icons/icon-56-intellicon-maps-radar.svg";
import { ReactComponent as RukkiIcon } from "../../assets/icons/Rukki App.svg";
import Button from "../../UI/Button";
import DownloadDisabled from "../../components/popUp/downloadAppDisabledPopUp";

const WaitingOfferBlock: React.FC = () => {
  const [downloadApp, setDownloadApp] = useState<boolean>(false);

  return (
    <Wrapper id="order_proposals">
      <RadarIcon className="radarIcon" />
      <div
        className="cardHeader"
        style={{
          fontSize: "24px",
          letterSpacing: "-0.1px",
          lineHeight: "1",
          marginBottom: "-8px",
        }}
      >
        Ожидаем отклики исполнителей
      </div>
      <div className="cardText" style={{ marginBottom: "16px" }}>
        В ближайшее время вы получите отклики, а мы сообщим вам об этом. Не
        забудьте установить мобильное приложение
      </div>
      <Button
        background="#fff3ef"
        border="#fff3ef"
        height="48px"
        width="fit-content"
        fontColor="#000000"
        fontWeight="400"
        onClick={() => setDownloadApp(true)}
      >
        <RukkiIcon /> Установить приложение
      </Button>

      {downloadApp && <DownloadDisabled close={() => setDownloadApp(false)} />}
    </Wrapper>
  );
};

export default memo(WaitingOfferBlock);
