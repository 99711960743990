import styled from "styled-components";

export const InputWrapper = styled.div<{
  active?: boolean;
  width?: string;
  height?: string;
  isLabel?: boolean;
}>`
  width: ${({ width }) => (width ? width : "100%")};
  height: ${({ height }) => (height ? height : "48px")};
  background-color: white;
  padding: 8px 16px;
  border-radius: 10px;
  transition: all 0.3s linear;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "rgba(0, 0, 0, 0.1)"};
  display: block;

  &:focus-within {
    border: 1px solid #999999;
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }

  span {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      display: inline;
    }

    .text {
      display: inline;
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    input {
      height: ${({ isLabel }) => (isLabel ? "16px" : "100%")};
    }
  }
  &:focus-within {
    .dropdown-wrapper {
      display: block;
    }
  }
`;
