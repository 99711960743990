import styled from "styled-components";
import { styles } from "../../styles/styles";

export const PopUpDesc = styled.div`
  margin: 32px 0 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #909090;

  span {
    color: ${styles.mainColor};
    font-weight: bold;
  }
`;

export const CardDetailData = styled.div`
  .data {
    display: flex;
    justify-content: space-between;
    margin: 24px 0;

    .half {
      width: 48%;
    }
  }
`;

export const BankData = styled.div`
  margin-bottom: 29px;

  img {
    margin-bottom: 8px;
    height: 32px;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #909090;
  }

  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }
`;
