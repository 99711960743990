import styled from "styled-components";

export const OrderItemWrapper = styled.div<{ navbarItem?: boolean }>`
  width: 100%;
  max-width: 974px;
  padding: 16px;
  border-radius: 10px;
  background-color: white;
  display: flex;
  gap: 16px;
  border: 1px solid #e8e8e8;
  ${({ navbarItem }) => navbarItem && "flex-direction: column"};
  transition: all 0.25s linear;
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 8px 0 #00000014;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const OrderItemMapPart = styled.div<{ navbarItem?: boolean }>`
  width: ${({ navbarItem }) => (navbarItem ? "100%" : "191px")};
  height: 136px;
  background-color: #f7f4f1;
  border-radius: 8px;
  position: relative;

  .imageContainer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .vehicleIcon {
      margin-bottom: 7px;
    }
  }

  .mapImage {
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
    transition: all 0.3s linear;

    &:hover {
      backdrop-filter: brightness(0.8);
    }
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const OrderItemDescriptionPart = styled.div<{ navbarItem?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${({ navbarItem }) => navbarItem && "gap: 16px"};

  > * {
    gap: 3px;
  }

  @media screen and (max-width: 767px) {
    gap: 16px;
    > * {
      gap: 6px;
      margin-left: 2px;
    }
  }

  .titleDescription {
    font-size: 12px;
    line-height: 12px;
    color: #999999;

    .offersCount {
      margin-left: 24px;
    }
  }
`;

export const HeaderPart = styled.div<{ navbarItem?: boolean }>`
  display: flex;
  justify-content: space-between;

  .columnGroup {
    display: flex;
    flex-direction: column;
  }

  .title {
    display: flex;
    font-size: ${({ navbarItem }) => (navbarItem ? "20px" : "24px")};
    line-height: 24px;
    color: black;
    word-wrap: break-word;
    position: relative;
    align-items: center;
    gap: 8px;
    font-family: "Rubik";
    cursor: pointer;

    @media screen and (max-width: 767px) {
      font-size: 20px;
    }

    img {
      width: 24px;
      height: 24px;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Rubik";
    }
  }
`;

export const LocationPart = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-size: 15px;
    line-height: 16px;
  }
`;

export const PricePart = styled.div<{
  responsed?: boolean;
  navbarItem?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  ${({ navbarItem }) => !navbarItem && "align-items: center"};
  gap: 16px;
  ${({ navbarItem }) => navbarItem && "flex-direction: column"};

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .price {
    font-size: ${({ navbarItem }) => (navbarItem ? "16px" : "20px")};
    line-height: 24px;
  }

  .searchButton {
    color: ${({ responsed }) => (responsed ? "white" : "#222222")};
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    border-radius: 10px;
    padding: 8px 12px;
    cursor: pointer;
    background-color: ${({ responsed }) => (responsed ? "#ff6633" : "#f7f4f1")};
    white-space: nowrap;

    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }

  .responseButWrapper {
    display: flex;
    align-items: center;
    gap: 16px;

    .cancelButton {
      font-size: 12px;
      line-height: 12px;
      color: #999999;
      transition: all 0.25s linear;
      cursor: pointer;
      white-space: nowrap;
      &:hover {
        color: #222222;
      }
    }
  }
`;
