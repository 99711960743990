import React, { memo, useEffect, useState } from "react";
import {
  AddUserPart,
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  InputsTitle,
  InputsWrapper,
  InputWrapper,
  RegisterForm,
  RemoveUser,
  SelectWrapper,
  Wrapper,
} from "./styles";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { Button } from "../../components/form/styles";
import NumberFormat from "react-number-format";
import { ENDPOINT } from "../../constants/general";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";

import { GoBack, TitlePart } from "../../components/pageContainer/styles";
import { getCompany } from "../../redux/store/reducers/companySlice";

const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
}: any) => {
  return (
    <InputWrapper
      active={type !== "role" && value?.length}
      style={{ width: width ? width : "100%" }}
      className={type !== "role" && value?.length ? "active" : ""}
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              // mask={"### ### ####"}
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            <NumberFormat
              // format="### ### ######"
              name={name}
              value={value || ""}
              prefix={"+"}
              placeholder={placeholder}
              onChange={onChange}
              maxLength={12}
              spellCheck={false}
            />
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const DashboardAddManager: React.FC = () => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>([{}]);
  const [step, setStep] = useState<number>(1);
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [errorMsgStep2, setErrorMsgStep2] = useState<any>({
    fullNameEmpty: false,
    phoneNumberEmpty: false,
    emailPattern: false,
    emailEmpty: false,
  });
  const user = useAppSelector((state) => state?.auth.user);
  const company = useAppSelector((state) => state.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const changeFormData = (e: any) => {
    const { name, value } = e.target;
    // if (name === "inn_organization") {
    //   onChangeHandler(e);
    // } else {
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    // }
  };

  const changeFormDataStep2 = (e: any, index: number) => {
    const { name, value } = e.target;

    // if (name === "inn_organization") {
    //   onChangeHandler(e);
    // } else {
    setFormData((prev: any) =>
      prev.map((v: any, i: number) =>
        i === index ? { ...v, [name]: value } : v,
      ),
    );
    // }
  };

  const inputListStep2 = [
    {
      // label: "ИНН организации",
      name: "role",
      width: "300px",
      type: "role",
    },
    {
      label: "ФИО сотрудника",
      name: "full_name",
      required: true,
    },
    {
      label: "Телефон",
      name: "phone_number",
      width: "calc((100% - 12px) / 2)",
      required: true,
      type: "tel",
    },
    {
      label: "Электронная почта",
      name: "email",
      width: "calc((100% - 12px) / 2)",
      required: true,
    },
    {
      label: "Примечание (видно только вам)",
      name: "note",
    },
  ];

  const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevState: any) => ({ ...prevState, [name]: value }));
  };

  const [addUserProcess, addUserRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      navigate({
        pathname: "/dashboard/manager/all",
      });
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setErrorMsg([
          "Один из пользователей уже является менеджером в другой компании, либо пользователь уже зарегистрирован",
        ]);
      }
    },
  });

  const addUserToCompany = () => {
    setErrorMsgStep2({});
    const emailRegEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const filterData = formData?.filter(
      ({ email, full_name, note, phone_number }: any, index: number) => {
        const objData = {
          fullNameEmpty: false,
          phoneNumberEmpty: false,
          emailPattern: false,
          emailEmpty: false,
        };
        if (!full_name) {
          objData.fullNameEmpty = true;
        }
        if (!phone_number) {
          objData.phoneNumberEmpty = true;
        }
        if (!email) {
          objData.emailEmpty = true;
        } else if (!email?.toLowerCase()?.match(emailRegEx)) {
          objData.emailPattern = true;
        }
        setErrorMsgStep2((prev: any) => ({
          ...prev,
          [index]: objData,
        }));
        return (
          full_name && email?.toLowerCase()?.match(emailRegEx) && phone_number
        );
      },
    );

    if (filterData?.length === formData?.length) {
      setErrorMsgStep2({});
      // navigate({
      //   pathname: "/dashboard/manager/all",
      //   search: `?${createSearchParams({
      //     data: JSON.stringify(formData),
      //   })}`,
      // });
      addUserRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${user?.customer?.refId}/add-managers`,
        data: {
          users: formData?.map(
            ({ email, full_name, note, phone_number }: any) => ({
              firstName: full_name?.split(" ")[0],
              secondName: full_name?.split(" ")[1],
              phoneNumber: phone_number,
              email,
              companyDescription: note,
            }),
          ),
        },
      });
    } else {
    }
  };

  // useEffect(() => {
  //   if (company.data && company.success) {
  //     if (!formData?.users?.length) {
  //       setFormData((prev: any) => ({
  //         ...prev,
  //         users: [{}],
  //       }));
  //     }
  //     setStep(2);
  //   }
  // }, [company.data, company.success]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getCompany(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  return (
    <Wrapper>
      {/* <Title>Шаг {step} из 2</Title> */}
      <TitlePart _mb20_p0>
        <GoBack onClick={() => navigate("/dashboard/manager")}>
          <LeftArrow /> Назад
        </GoBack>
      </TitlePart>
      <RegisterForm>
        <FormTitle>Сведения о сотрудниках</FormTitle>
        <FormDesc>
          Добавьте сотрудников, чтобы отслеживать их работу в системе
        </FormDesc>
        {formData?.map((v: any, i: number) => (
          <>
            {i > 0 ? <InputsTitle>Сотрудник {i + 1}</InputsTitle> : ""}
            <InputsWrapper>
              {inputListStep2.map(
                ({ label, name, width, type, required }, index) => (
                  <>
                    <InputBox
                      label={label}
                      width={width}
                      name={name}
                      onChange={(e: any) => changeFormDataStep2(e, i)}
                      value={formData?.[i]?.[name]}
                      type={type}
                      required={required}
                    />
                    {i > 0 && index === 0 ? (
                      <RemoveUser
                        onClick={() => {
                          setFormData((prev: any) =>
                            prev?.filter((v: any, ind: number) => i !== ind),
                          );
                        }}
                      >
                        Удалить сотрудника
                      </RemoveUser>
                    ) : (
                      ""
                    )}
                  </>
                ),
              )}
              <ErrorsWrapper>
                {errorMsgStep2?.[i]?.fullNameEmpty && (
                  <ErrorMsg>Укажите полное имя.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.phoneNumberEmpty && (
                  <ErrorMsg>Укажите номер телефона.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.emailEmpty && (
                  <ErrorMsg>Укажите адрес электронной почты.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.emailPattern && (
                  <ErrorMsg>Электронная почта недействительна.</ErrorMsg>
                )}
              </ErrorsWrapper>
            </InputsWrapper>
          </>
        ))}

        {errorMsg.length > 0 ? (
          <ErrorsWrapper>
            {errorMsg.map((msg: string) => (
              <ErrorMsg>{msg}</ErrorMsg>
            ))}
          </ErrorsWrapper>
        ) : (
          ""
        )}

        <AddUserPart>
          У вас есть еще сотрудники?{" "}
          <u
            onClick={() => {
              setFormData((prev: any) => [...prev, {}]);
            }}
          >
            Добавьте еще одного
          </u>
        </AddUserPart>
        <Button
          onClick={() => {
            // registerCompany();
            addUserToCompany();
            // navigate("/dashboard/manager");
          }}
          fit
          _m0auto
          disabled={
            formData?.filter(
              (
                { email, full_name, note, phone_number }: any,
                index: number,
              ) => {
                return (
                  full_name &&
                  email?.toLowerCase()?.match(emailRegEx) &&
                  phone_number
                );
              },
            ).length !== formData?.length
          }
        >
          Продолжить
        </Button>
      </RegisterForm>
    </Wrapper>
  );
};

export default memo(DashboardAddManager);
