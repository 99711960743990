import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 20px;
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
`;

export const HeaderBtn = styled.button`
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  display: flex;
  align-items: center;
  background-color: transparent;
  font-size: 15px;
  line-height: 1;
  letter-spacing: -0.25px;

  svg {
    margin-right: 8px;
    display: flex;
  }
`;

export const OrderWrapper = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
`;

export const DataWrapper = styled.div`
  padding: 20px 20px 16px;
`;

export const Title = styled.h1`
  margin: 0 0 4px;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.5px;
`;

export const SubTitle = styled.div`
  margin: 4px 0 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
`;

export const OrderName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 0.83;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }
`;

export const BudgetDeadline = styled.div`
  display: flex;
  gap: 22px 0;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const TitleValue = styled.div`
  width: 50%;

  .title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 7px;
  }

  .value {
    font-size: 15px;
    line-height: 1.07;
  }
`;

export const Desc = styled.div`
  margin-bottom: 16px;

  .title {
    font-size: 16px;
    line-height: 1.25;
  }

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
`;

export const Address = styled(Desc)`
  margin: 0;
`;

export const MapWrapper = styled.div<{ dataHeight: number }>`
  height: calc(100% - ${({ dataHeight }) => dataHeight || 0}px);

  .showPathWrapper {
    position: relative;
  }

  .showPathIcon {
    margin-left: -2px;
  }

  .showPath {
    box-shadow: none;
    width: 180px;
    height: 32px;
    background-color: #ff6633;
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 9px 10px;
    position: absolute;
    top: 16px;
    left: 16px;
    z-index: 99;
  }
`;
