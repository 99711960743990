import { TitleValue } from "./../../dashboardRightOrderData/styles";
import styled from "styled-components";
import { Container, styles } from "../../styles/styles";

export const Wrapper = styled(Container)`
  padding: 40px 0;
  /* display: flex; */
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -0.1px;
  margin-bottom: 34px;
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  width: 100%;
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 18px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const InputWrapper = styled.label<{
  active?: boolean;
  select?: boolean;
}>`
  width: 100%;
  background-color: white;
  padding: ${({ select }) => (select ? "8px" : "12px 16px")};
  border-radius: 10px;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "var(--color-light-gray)"};
  display: block;

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }

  span {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    input {
      height: 16px;
    }
  }
  &:focus-within {
    .dropdown-wrapper {
      display: block;
    }
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  position: relative;

  .input-part {
    width: calc(100% - 58px);
  }

  img,
  svg {
    width: 24px;
    height: 24px;
    display: flex;
    margin-right: 10px;
  }

  .dropdown {
    display: flex;
    svg {
      margin-right: 0;
    }
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const DropdownWrapper = styled.div`
  display: none;
  width: 100%;
  position: absolute;
  top: 38px;
  right: 0;
  left: 0;
  background-color: white;
  border: 1px solid #e3e3e3;
  z-index: 1;
  max-height: 300px;
  height: fit-content;
  overflow: auto;
`;

export const DropdownList = styled.div``;

export const DropdownItem = styled.div`
  cursor: pointer;
  padding: 5px 10px;
  &:hover {
    background-color: #00000010;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 12px 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;

export const SubBtn = styled.div`
  opacity: 0.5;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  color: #222222;
  width: 280px;
  margin: 8px auto auto;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin: 8px 0 16px;
`;

export const CheckBox = styled.label`
  display: flex;
  align-items: center;
  .input-wrapper {
    border: 1px solid #e5e5e5;
    width: 24px;
    height: 24px;
    margin-right: 4px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    position: relative;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    input {
      visibility: hidden;
    }
  }
  span {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
    cursor: pointer;
    user-select: none;
  }
`;

export const PhotoVideoBox = styled.div`
  width: 100%;
  margin: 8px 0;

  .text {
    margin-bottom: 20px;
  }
`;

export const PVTitle = styled.div`
  font-size: 16px;
  line-height: 1;
  color: #222222;
`;

export const PhotoVideoList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
`;

export const PhotoVideoItem = styled.div<{
  borderless?: boolean;
  dashed?: boolean;
}>`
  ${({ borderless, dashed }) =>
    !borderless &&
    `border: 1px ${dashed ? "dashed" : "solid"} ${styles.mainColor};`};
  width: 74px;
  height: 74px;
  /* padding-top: 100%; */
  position: relative;
  border-radius: 10px;
  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 7px;
    }

    .cover {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height */

      text-align: center;

      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(34, 34, 34, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
    }
  }

  .new-img {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 7px;
    svg {
      margin-bottom: 1px;
    }
    input {
      display: none;
    }
    .new-img-text {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      /* identical to box height, or 118% */

      text-align: center;

      color: #ff6633;
    }
  }

  .close {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: white;
    border-radius: 4px;
    display: flex;
  }
`;

export const AddNewImg = styled.label`
  width: 74px;
  height: 74px;
  /* padding-top: 100%; */
  position: relative;
  border-radius: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 7px;
  }

  input {
    display: none;
  }
`;
