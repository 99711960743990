import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px;
  display: flex;
  gap: 16px;
  background: #f7f4f1;
  border-radius: 16px;
  transition: all 0.3s linear;
  position: relative;

  .buttonsWrapper {
    display: flex;
    gap: 16px;
    margin-top: auto;

    div {
      z-index: 3;
    }

    .proposalMenuButton {
      height: 48px;
      width: 48px;
      border: 1px solid #e8e8e8;
      background-color: #ffffff;
      border-radius: 10px;
      appearance: none;
      outline: none;
      line-height: 0;
      cursor: pointer;
    }

    @media screen and (max-width: 480px) {
      gap: 8px;
    }
  }

  .createdAt {
    position: absolute;
    top: 24px;
    right: 12px;
    font-size: 14px;
    color: #00000080;
    line-height: 1;

    @media screen and (max-width: 1050px) {
      display: none;
    }
  }

  .workerData {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 55px;
    width: 100%;
    max-width: 300px;
  }

  .offer {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
    min-width: 292px;
    max-width: 500px;

    .offerDetails {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .offerItem {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &:nth-child(4) {
          margin-bottom: 51px;
        }
      }

      .offerItemTitle {
        font-size: 12px;
        line-height: 1;
        color: #999999;
      }

      .offerItemText {
        font-size: 15px;
        line-height: 1.07;
        letter-spacing: -0.25px;
        color: #000;
      }
    }

    @media screen and (max-width: 590px) {
      grid-template-columns: repeat(auto-fit, 230px);
      grid-template-rows: unset;
    }
  }

  .counterOffer {
    display: flex;
    flex-direction: column;
    width: 55%;

    .counterOfferTitle {
      cursor: pointer;
      transition: all 0.3s linear;
      user-select: none;

      &:hover {
        color: #ff6633;
      }
    }

    .counterOfferBody {
      transition: all 0.3s linear;
      display: flex;
      flex-direction: column;
      gap: 12px;
      white-space: pre-line;
      line-height: 1;
      max-height: 0px;
      overflow: hidden;
      padding: 0;
      border-radius: 12px;
      background-color: #ffffff;
      margin-top: 0px;

      &.active {
        padding: 16px !important;
        max-height: 300px !important;
        margin-top: 8px;
      }

      .counterOfferButtons {
        display: flex;
        flex-direction: column;
        gap: 8px;

        @media screen and (min-width: 700px) {
          width: 50%;
        }

        @media screen and (min-width: 1050px) {
          width: 100%;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0 2px 24px 0 #00000028;
  }

  .itemButton {
    box-shadow: 1px 1px 5px 3px #f3f3f3;
  }

  @media screen and (max-width: 1250px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1050px) {
    padding: 16px;

    .counterOffer {
      width: 100%;
    }
  }
`;

export const ProposalMenuWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 412px;
  height: 184px;
  z-index: 2;
  background-color: #fdfdfd;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  padding: 16px;
  animation: proposalMenuOpen 0.2s ease-in;
  display: flex;
  flex-direction: column;

  .buttonsList {
    display: flex;
    flex-direction: column;

    .button {
      height: 32px;
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 4px 8px;
      background-color: #fdfdfd;
      cursor: pointer;
      border-radius: 8px;
      font-size: 15px;
      transition: all 0.2s linear;
      width: fit-content;

      &:hover {
        background-color: #f7f4f1;
      }
    }

    .disabled {
      opacity: 0.5;

      &:hover {
        background-color: #fdfdfd !important;
      }
    }
  }

  @keyframes proposalMenuOpen {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    width: calc(100% - 16px);
  }
`;

export const Half = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;

  .proposalFlexGroup {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .proposalSubTitle {
    font-size: 12px;
    line-height: 1;
    color: #999999;
  }

  .proposalText {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }
  /* @media only screen and (min-width: 1440px) {
    width: 400px;
  } */
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const HideButton = styled.div`
  width: 48px;
  height: 48px;
  background: #ffffff;
  border: 1px solid #00000019;
  border-radius: 10px;
  padding: 12px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;

  &:hover {
  }
`;

export const RightHalf = styled(Half)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  .buttonsWrapper {
    display: flex;
    gap: 16px;
  }

  .itemInfo {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #222222;
      mix-blend-mode: normal;
      opacity: 0.5;
    }

    .price {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #000;
    }

    .time {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      margin: 0;
      padding: 0;
    }
  }

  .timeTitle {
    margin-top: 10px;
  }
`;

export const UserData = styled.div`
  .starRating {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .ratingMsg {
    display: flex;
    cursor: pointer;
  }

  .rating {
    display: flex;
    align-items: center;
    padding-right: 10px;
    font-weight: 500;
  }

  .message {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  .starRating {
    padding-left: 3px;
  }

  display: flex;
  align-items: center;
  gap: 8px;

  .avatar {
    width: 48px;
    height: 48px;

    img {
      width: 48px;
      height: 100%;
      object-fit: cover;
      object-position: center;
      aspect-ratio: 1 / 1;
      border-radius: 50%;
    }
  }

  .data {
    display: flex;
    flex-direction: column;
    gap: 2px;
    line-height: 1;

    b {
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
      white-space: nowrap;
    }

    small {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      color: #222222;
      mix-blend-mode: normal;
      opacity: 0.5;
    }

    .companyName {
      font-weight: 400;
      font-size: 12px;
      max-width: 200px;
    }

    .second-line {
      display: flex;

      .stat {
        margin-right: 10px;
        display: flex;
        align-items: center;

        svg {
          margin-right: 6px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          color: #222222;
          mix-blend-mode: normal;
          opacity: 0.5;
        }
      }
    }
  }
`;

export const MsgBox = styled.div`
  margin: 16px 0 32px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  @media only screen and (max-width: 1050px) {
    margin: 16px 0 20px;
  }
`;

export const ButtonWrapper = styled.div`
  button {
    padding: 0 10px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 3px #f3f3f3;
    transition: all 0.3s linear;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  @media only screen and (max-width: 1050px) {
    button {
      padding: 7px 15px;
    }
  }
  @media only screen and (max-width: 350px) {
    flex-direction: column;
    button {
      margin: 5px 0 0;
      justify-content: center;
      width: 100%;
    }
  }
`;

