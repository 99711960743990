import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

const searchByTag = (id: any, query: string) => ({
  type: actionTypes.SEARCH_BY_TAG,
  payload: httpRequest({
    method: "POST",
    url: `${ENDPOINT}/orders/${id}/search-by-tags`,
    data: { query },
  }),
});

export default searchByTag;
