import React, { memo } from "react";
import {
  OrderDate,
  SearchRadius,
  UserVehicleList,
} from "../../../types/filters.types";
import Button from "../../../UI/Button";
import SwitchSelect from "../../../UI/SwitchSelect";
import { FiltersWrapper, PopUpWrapper } from "./styles";

const dateList: OrderDate[] = [
  { label: "Сегодня", value: "today" },
  { label: "Вчера", value: "yesterday" },
  { label: "За неделю", value: "last_seven_days" },
  { label: "За 10 дней", value: "last_ten_days" },
  { label: "Все", value: "all" },
];

const radiusList: SearchRadius[] = [
  { label: "10 км", value: "10" },
  { label: "20 км", value: "20" },
  { label: "50 км", value: "50" },
  { label: "100 км", value: "100" },
  { label: "Все", value: "-1" },
];

interface IFilter {
  period?: string;
  radius?: string;
  vehicle?: string;
}

interface IFiltersPopUpProps {
  filter: IFilter;
  onFilterSelect: ({ name, value }: { name: any; value: any }) => void;
  resetFilters: () => void;
  close: () => void;
}

const myVehicleList: UserVehicleList[] = [
  { label: "Только для моей техники", value: "only_my_vehicle" },
  { label: "Для любой техники", value: "all" },
];

const FiltersPopUp: React.FC<IFiltersPopUpProps> = ({
  filter,
  onFilterSelect,
  resetFilters,
  close,
}) => {
  return (
    <PopUpWrapper onClick={close}>
      <FiltersWrapper>
        <div
          className="cancelButton"
          onClick={(e) => {
            e.stopPropagation();
            resetFilters();
          }}
        >
          Сбросить фильтры
        </div>
        <div className="filters">
          <div className="row">
            <div className="label">Публикация объявлений</div>
            <SwitchSelect
              data={dateList}
              value={filter.period}
              name={"period"}
              onFilterSelect={onFilterSelect}
            />
          </div>
          <div className="row">
            <div className="label">Радиус поиска</div>
            <SwitchSelect
              data={radiusList}
              value={filter.radius}
              name={"radius"}
              onFilterSelect={onFilterSelect}
            />
          </div>
          <div className="row">
            <div className="label">Поиск заказов</div>
            <SwitchSelect
              data={myVehicleList}
              value={filter.vehicle}
              name={"vehicle"}
              onFilterSelect={onFilterSelect}
            />
          </div>
        </div>

        <div className="buttons">
          <Button
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              resetFilters();
            }}
            variant="outlined"
            outlined
          >
            Сбросить фильтры
          </Button>
          <Button onClick={close}>Показать</Button>
        </div>
      </FiltersWrapper>
    </PopUpWrapper>
  );
};

export default memo(FiltersPopUp);
