import React, { useState } from "react";
import Loading from "../../loading";
import PopUpContainer from "../popUpContainer";
import { DescriptionBox } from "./styles";

interface Props {
  close?: any;
  onSubmit?: any;
  loading?: boolean;
  companyName?: string;
  removeEmployee?: boolean | string;
  // tab?: any;
}

const ConfirmLeaveCompanyPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  loading,
  companyName,
  removeEmployee,
}) => {
  const [text, setText] = useState<string>(
    // defaultValue ||
    "",
  );
  return (
    <PopUpContainer
      title={
        removeEmployee === "leave"
          ? "Покинуть организацию"
          : "Удалить сотрудника"
      }
      close={close}
      onSubmit={() => {
        onSubmit();
        close();
      }}
      button={removeEmployee === "leave" ? "Покинуть" : "Удалить"}
    >
      <DescriptionBox>
        {loading ? (
          <Loading />
        ) : (
          <div className="text">
            {removeEmployee === "leave"
              ? `Вы действительно хотите покинуть организацию ${companyName}?`
              : "Вы действительно хотите удалить сотрудника?"}
          </div>
        )}
      </DescriptionBox>
    </PopUpContainer>
  );
};

export default ConfirmLeaveCompanyPopUp;
