import React, { memo } from "react";
import {
  OrderDate,
  SearchRadius,
  UserVehicleList,
} from "../../types/filters.types";
import { SwitchItem, Wrapper } from "./styles";

interface ISwitchSelectProps {
  data: OrderDate[] | SearchRadius[] | UserVehicleList[];
  value: string | undefined;
  onFilterSelect: ({ name, value }: { name: any; value: any }) => void;
  name: string;
}

const SwitchSelect: React.FC<ISwitchSelectProps> = ({
  data,
  value,
  onFilterSelect,
  name,
}) => {
  return (
    <Wrapper>
      {data.map((item, index) => {
        return (
          <SwitchItem
            active={value === item.value}
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              onFilterSelect({ name, value: item.value });
            }}
          >
            {item.label}
          </SwitchItem>
        );
      })}
    </Wrapper>
  );
};

export default memo(SwitchSelect);
