import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import {
  PhotoVideoBox,
  PhotoVideoItem,
  PhotoVideoList,
  SmallText,
} from "./styles";
import { ReactComponent as Close } from "../../../assets/icons/close-red.svg";
import pdfIcon from "../../../assets/icons/pdf-icon-popup.png";
import xlsIcon from "../../../assets/icons/xls-icon-popup.png";
import docIcon from "../../../assets/icons/doc-icon-popup.png";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import { RcFile } from "antd/lib/upload";

const { Dragger } = Upload;

interface Props {
  onSubmit: any;
  close: any;
  defaultPhotoVideo?: any;
}

export interface File {
  mediaFileUrl: string;
  mediaFileName: string;
  file: RcFile;
}

const getFileIcon = (f: any) => {
  if (f?.file?.type?.includes("image")) {
    return f.mediaFileUrl;
  } else if (
    f?.file?.type?.includes("excel") ||
    f?.file?.type.includes("sheet")
  ) {
    return xlsIcon;
  } else if (f?.file?.type?.includes("pdf")) {
    return pdfIcon;
  } else if (f?.file?.type?.includes("word")) {
    return docIcon;
  } else {
    return null;
  }
};

const PhotoVideoPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  defaultPhotoVideo,
}) => {
  const [photoVideo, setPhotoVideo] = useState<File[]>(
    defaultPhotoVideo.length > 0 ? defaultPhotoVideo : [],
  );
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    if (photoVideo.length) {
      let size = 0;
      photoVideo.map((file: any) => {
        size = Number((size + file.file.size / 1000000).toFixed(2));
      });
      setTotalSize(size);
    }
    if (photoVideo.length > 10) {
      setPhotoVideo(
        photoVideo.slice(photoVideo.length - 10, photoVideo.length),
      );
    }
  }, [photoVideo]);

  const props: UploadProps = {
    name: "file",
    showUploadList: false,
    multiple: true,
    beforeUpload: (file) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        setPhotoVideo((prevState: any) => [
          ...prevState,
          {
            //base64: reader.result,
            mediaFileUrl: URL.createObjectURL(file),
            mediaFileName: file.name,
            file,
          },
        ]);
      };
    },
  };

  return (
    <PopUpContainer
      close={close}
      onSubmit={() => {
        onSubmit(photoVideo);
        close();
      }}
      title="Добавить фото и видео"
      button="Добавить файлы"
      disabled={!Boolean(photoVideo) || totalSize > 10}
    >
      <PhotoVideoBox>
        <Dragger
          {...props}
          showUploadList={false}
          accept="image/*, video/*, application/pdf, .docx, .doc, .xls, .xlsx"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Кликните или перетащите файлы в эту область для загрузки
          </p>
        </Dragger>

        <PhotoVideoList>
          {photoVideo
            .filter((v, index: number) =>
              photoVideo.length <= 7
                ? true
                : photoVideo.length > 7 && index < 6
                ? true
                : false,
            )
            .map((f, i: number) => {
              return (
                <PhotoVideoItem>
                  <div className="item">
                    {f?.file?.type.includes("video") ? (
                      <video>
                        <source src={f.mediaFileUrl} />
                      </video>
                    ) : (
                      <img src={getFileIcon(f)} alt={f.mediaFileName} />
                    )}
                  </div>
                  <div
                    className="close"
                    onClick={() =>
                      setPhotoVideo(
                        photoVideo.filter(
                          (v: any, index: number) => index !== i,
                        ),
                      )
                    }
                  >
                    <Close />
                  </div>
                </PhotoVideoItem>
              );
            })}
          {photoVideo.length > 7 && (
            <PhotoVideoItem>
              <div className="item">
                <div className="cover">+{photoVideo.length - 6}</div>
                <img src={photoVideo?.[6]?.mediaFileUrl} alt="" />
              </div>
            </PhotoVideoItem>
          )}
        </PhotoVideoList>
      </PhotoVideoBox>
      <p>Вы можете добавлять pdf файлы, фото и видео.</p>
      <SmallText
        error={totalSize > 10}
      >{`Максимальный размер загружаемых файлов - 10 Мб ${
        totalSize > 0 ? `(${totalSize} Мб)` : ""
      }`}</SmallText>
    </PopUpContainer>
  );
};

export default memo(PhotoVideoPopUp);
