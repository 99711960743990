export const getPhotoStatus = (status: string | undefined): string => {
  switch (status) {
    case "confirmed":
      return "Проверка пройдена";
    case "not-confirmed":
      return "Проверка не пройдена";
    case "pending":
      return "Данные проверяются";
    default:
      return "Нет загруженных фотографий";
  }
};

export const getFullPhotoStatus = (status: string | undefined): string => {
  switch (status) {
    case "confirmed":
      return "Проверка пройдена";
    case "not-confirmed":
      return "Проверка не пройдена";
    case "pending":
      return "Данные проверяются";
    default:
      return "Нет загруженных фотографий";
  }
};

export const getColorPhotoStatus = (status: string | null): string => {
  switch (status) {
    case "confirmed":
      return "fulfilled";
    case "not-confirmed":
      return "rejected";
    case "pending":
      return "pending";
    default:
      return "empty";
  }
};

export const getPaymentStatus = (status: string): string => {
  switch (status) {
    case "Done":
      return "Платеж подтвержден";
    case "Initiated":
      return "Все необходимые реквизиты для платежа получены, платёж готов к проверке на возможность проведения";
    case "Wait For Owner Requisites":
      return "Часть реквизитов для платежа получена, кроме реквизитов плательщика";
    case "NotAllowed":
      return "Платёж нельзя провести: либо у пользователя нет прав для подписи, либо платёж заблокирован комплаенсом";
    case "Allowed":
      return "Платёж готов к подписанию, все проверки пройдены";
    case "WaitingForSign":
      return "Платёж ждёт подписи";
    case "WaitingForCreate":
      return "Платёж подписан, ждёт создания внутри систем банка";
    case "Created":
      return "Платёж создан";
    case "Rejected":
      return "Платёж отменён";
    default:
      return "empty";
  }
};

export const getToken = () => {
  return localStorage.getItem("accessToken");
};

export const getHistoryName = (type: string): string => {
  switch (type) {
    case "Sent":
      return "Заявка подана";
    case "Accepted":
      return "Принята";
    case "Rejected":
      return "Отказано";
    case "created":
      return "Заказ создан";
    case "waiting_for_start":
      return "Начало \nработ"; // работ";
    case "edited":
      return "Заказ создан";
    case "in":
      return "Заказ создан";
    case "worker_selected":
      return "Исполнитель выбран";
    case "worker_set_out":
      return "Исполнитель выехал на работы";
    case "started":
      return "Начало работ";
    case "done":
      return "Прием результата";
    case "paid":
      return "Оплачено";
    case "rate":
      return "Отзыв о заказе";
    case "waiting_for_finish":
      return "Завершение"; // работ";
    default:
      return "Неизвестный тип";
  }
};
export const getHistoryNameBlock = (type: string): string => {
  switch (type) {
    case "Sent":
      return "Заявка подана";
    case "Accepted":
      return "Принята";
    case "Rejected":
      return "Отказано";
    case "created":
      return "Заказ создан";
    case "waiting_for_start":
      return "Ожидание начало работ";
    case "waiting_for_finish":
      return "Ожидание окончания работ";
    case "edited":
      return "Заказ создан";
    case "in":
      return "Заказ создан";
    case "worker_selected":
      return "Исполнитель выбран";
    case "started":
      return "Начало работ";
    case "done":
      return "Завершение";
    case "paid":
      return "Оплачено";
    default:
      return "Неизвестный тип";
  }
};

export const getWorkerData = (array: any): any | null => {
  const result = array.find((item: any) => item.status === "Accepted");
  if (result) return result;
  return null;
};

export const VEHICLE_PARAMETERS_NAMES: any = {
  defaultParameter: "Пользовательский параметр",
  stateNumber: "Номер техники",
  height: "Высота",
  typeArrow: "Тип стрелы",
  typeChassis: "Тип шасси",
  loadCapacity: "Грузоподъемность (тонны)",
  loadCapacitySide: "Грузоподъемность борта (тонны)",
  loadCapacityArrow: "Грузоподъемность стрелы (тонны)",
  arrowReach: "Вылет стрелы (метры)",
  numberAxles: "Количество осей",
  equipments: "Навесное оборудование",
  volume: "Объем",
};

export function removeDuplicates(arr: any) {
  let unique: any = [];
  arr?.forEach((element: any) => {
    if (
      element?.label &&
      element?.value &&
      !unique.find(({ label, value }: any) => value === element.value)
    ) {
      unique.push(element);
    }
  });
  return unique;
}

export const inputDataList = [
  {
    label: "Фамилия",
    name: "last_name",
    required: true,
    width: "calc((100% - 16px) / 2)",
    lettersOnly: true,
  },
  {
    label: "Имя",
    name: "first_name",
    required: true,
    width: "calc((100% - 16px) / 2)",
    lettersOnly: true,
  },
  {
    label: "Отчество",
    name: "patronymic",
    width: "calc((100% - 16px) / 3)",
    lettersOnly: true,
  },
  {
    label: "Телефон",
    name: "phone_number",
    width: "calc((100% - 16px) / 3)",
    required: true,
    type: "tel",
    disabled: true,
  },
  {
    label: "Электронная почта",
    name: "email",
    width: "calc((100% - 16px) / 3)",
    required: true,
    type: "email",
  },
  {
    label: "Страна",
    name: "country",
    width: "calc((100% - 16px) / 2)",
    required: true,
    type: "country",
  },
  {
    label: "Город",
    name: "city",
    width: "calc((100% - 16px) / 2)",
    required: true,
    type: "city",
  },
];
