import styled from "styled-components";

export const FormText = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
`;

export const TelegramButton = styled.div`
  width: 203px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  margin-top: 8px;
  border-radius: 10px;
  background: #fff3ef;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #f63;
  align-self: center;
  cursor: pointer;
  transition: all 0.3s linear;
  &:hover {
    background: rgba(255, 102, 51, 0.24);
  }
`;
