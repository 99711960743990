import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  gap: 16px;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title {
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: normal;
  }
`;

export const InputsWrapper = styled.div`
  width: 510px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
`;

export const ContentTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const AvatarContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 24px;

  @media only screen and (max-width: 320px) {
    margin-left: 10px;
    display: flex;
    gap: 12px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 10px;
    margin-top: 5px;
  }
`;
export const Avatar = styled.div`
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;

  img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
  }
`;

export const ContainerImg = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  width: 32px;
  height: 32px;
  background-color: rgba(255, 255, 255, 0.84);
  border-radius: 50%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  width: fit-content;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  p {
    font-size: 16px;
    line-height: 1;
    letter-spacing: normal;
  }
`;

export const BtnContainer = styled.div`
  display: flex;
  gap: 12px;
  button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: normal;
    color: #ff6633;
  }
`;

export const Text = styled.div`
  /* width: 332px */
  margin: 0 20px 20px 0;

  font-size: 20px;
  line-height: 1.2;
  letter-spacing: normal;
`;

export const InfoBlock = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #222222;
  p {
    padding: 8px;
    opacity: 0.5;
    width: 280px;

    @media only screen and (max-width: 480px) {
      text-align: center;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 275px;
  @media only screen and (max-width: 480px) {
    /* width: 460px; */
    width: 100%;
  }
  @media only screen and (min-width: 481px) {
    /* width: 296px; */
  }
`;
