import React, { memo, useEffect, useState } from "react";
import {
  AddCardsWrapper,
  AddCardWrapper,
  AddDesc,
  AddIconPart,
  AddTitle,
  ButtonsWrapper,
  Content,
  ContentTitle,
  ContentTitleWrapper,
  ContentWrapper,
  List,
  Paragraph,
  RegisterForm,
  RoleBtn,
  SkipBtn,
  SkipWrapper,
  SubmitButton,
  VehicleBox,
  VehicleList,
  VehicleMainTitle,
  Wrapper,
} from "./styles";
import { ReactComponent as ThreePeopleIcon } from "../../assets/icons/icons-people-3.svg";
import { ReactComponent as Hoist } from "../../assets/icons/icons-vehicles-hoist-white.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import RegisterImg from "../../assets/images/register-img.png";
import { ReactComponent as SelectedIcon } from "../../assets/icons/icons-24-status-icon-check-orange.svg";
import { ReactComponent as SelectedIconSmall } from "../../assets/icons/icons-24-status-icon-check-orange-small.svg";
import RegisterUser from "../userRegistration/registerUser";
import AddVehiclePopUp from "../../components/popUp/addVehiclePopUp";
import { getVehiclesList } from "../../redux/store/reducers/vehicleSlice";
import getManagers from "../../redux/modules/getManagers";
import { getMe } from "../../redux/store/reducers/authSlice";

import { ManagerRow as VehicleRow } from "../../components/vehicleManagementPagesDataList";
import { ManagerRow as EmployeeRow } from "../../components/managementPagesDataList";
import useHttpRequest from "../../hooks/useHttpRequest";
import { ENDPOINT } from "../../constants/general";
import jwtDecode from "jwt-decode";
import { CustomerRole } from "../../types";

interface Props {
  setDisable?: any;
}

const listItemsWorker: string[] = [
  "Ежедневные заказы на ваш тип спецтехники",
  "Заказы рядом с вами",
  "Удобные взаиморасчёты",
  "Электронный документооборот",
];
const listItemsClient: string[] = [
  "Быстрый заказ спецтехники",
  "База от 12000 проверенных исполнителей",
  "Удобные взаиморасчёты",
  "Электронный документооборот",
];

const UserRegistration: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [addVehicle, setAddVehicle] = useState(false);
  const [addManager, setAddManager] = useState(false);
  const [role, setRole] = useState<CustomerRole>(CustomerRole.Customer);
  const { user, fireBaseData, loading } = useAppSelector(
    (state) => state?.auth,
  );
  const refId = fireBaseData?.user?.refId;
  const vehicleList = useAppSelector((state) => state.vehicle.vehicleList);
  const { data: managers } = useAppSelector((state) => state.managers);

  const { id: cmp } = useParams();

  useEffect(() => {
    dispatch(getMe(refId));
  }, []);

  useEffect(() => {
    if (step === 2) {
      dispatch(getVehiclesList(refId));
    } else if (step === 3) {
      dispatch(getManagers(refId));
    }
  }, [step]);

  const [companyDataProcess, companyDataRequest] = useHttpRequest({
    onSuccess: (res: any) => {},
    onError: (err: any) => {},
  });

  useEffect(() => {
    if (cmp) {
      companyDataRequest({
        method: "GET",
        url: `${ENDPOINT}/companies/info`,
        params: {
          cmp,
        },
      });
    }
  }, [cmp]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken") || "";
    const decoded: any = token ? jwtDecode(token) : {};
    const phoneNumber = decoded?.phone_number;
    if (!phoneNumber && loading === "succeeded" && cmp) {
      localStorage.setItem("companyDataInBase64", cmp);
      navigate("/login");
    }
  }, [loading, cmp]);

  return (
    <Wrapper>
      <Content>
        <ContentWrapper>
          <div className="content-box">
            {step === 1 && (
              <>
                <ContentTitle>Кто вы?</ContentTitle>
                <ButtonsWrapper>
                  <RoleBtn
                    onClick={() => setRole(CustomerRole.Customer)}
                    isActive={role === "customer"}
                    width={"208px"}
                    firstStep
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Я – заказчик</div>
                  </RoleBtn>
                  <RoleBtn
                    onClick={() => setRole(CustomerRole.Worker)}
                    isActive={role === "worker"}
                    width={"208px"}
                    firstStep
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Я – исполнитель</div>
                  </RoleBtn>
                </ButtonsWrapper>
                <List>
                  {(role === "worker"
                    ? listItemsWorker
                    : role === "customer"
                    ? listItemsClient
                    : []
                  ).map((item, index) => {
                    return (
                      <div className="listItem" key={index}>
                        <div className="icon">
                          <SelectedIconSmall />
                        </div>
                        <div className="text">{item}</div>
                      </div>
                    );
                  })}
                </List>
                <RegisterUser
                  setRole={setRole}
                  setStep={setStep}
                  role={role}
                  companyData={cmp}
                  onSuccess={(e: any) => {
                    if (e) {
                      setStep(e);
                    }
                    dispatch(getMe(refId));
                  }}
                />
              </>
            )}
            {step > 2 && (
              <>
                <ContentTitleWrapper>
                  <ContentTitle>Продолжим!</ContentTitle>
                  <Paragraph>
                    Чтобы скорее приступить к работе в сервисе, добавьте
                    информацию о своем парке техники и сотрудниках
                  </Paragraph>
                </ContentTitleWrapper>
                <RegisterForm>
                  {step === 4 && (
                    <VehicleBox>
                      <VehicleMainTitle>Список сотрудников</VehicleMainTitle>
                      <VehicleList>
                        {managers?.users?.map((user: any, index: number) => {
                          if (user.companyRole !== "admin") {
                            return <EmployeeRow value={user} index={index} />;
                          }
                        })}
                      </VehicleList>
                    </VehicleBox>
                  )}

                  {step === 3 && (
                    <VehicleBox>
                      <VehicleMainTitle>Список техники</VehicleMainTitle>
                      <VehicleList>
                        {vehicleList?.map((vehicle: any, index: number) => (
                          <VehicleRow value={vehicle} index={index} />
                        ))}
                      </VehicleList>
                    </VehicleBox>
                  )}
                  <AddCardsWrapper>
                    {step === 4 &&
                      (!managers?.users?.length ||
                        (managers?.users?.length === 1 &&
                          managers?.users[0]?.companyRole === "admin")) && (
                        <AddCardWrapper>
                          <AddIconPart>
                            <ThreePeopleIcon />
                          </AddIconPart>
                          <AddTitle>Добавьте сотрудников</AddTitle>
                          <AddDesc>
                            Так вы сможете назначать заказы своим сотрудникам и
                            управлять занятостью
                          </AddDesc>
                          <SubmitButton onClick={() => setAddManager(true)}>
                            Добавить сотрудника
                          </SubmitButton>
                        </AddCardWrapper>
                      )}
                    {step === 3 && !vehicleList?.length && (
                      <AddCardWrapper>
                        <AddIconPart>
                          <Hoist />
                        </AddIconPart>
                        <AddTitle>Добавьте свою технику</AddTitle>
                        <AddDesc>
                          Так мы сможем предлагать вам наиболее подходящие
                          заказы, а вы – управлять занятостью
                        </AddDesc>
                        <SubmitButton onClick={() => setAddVehicle(true)}>
                          Добавить технику
                        </SubmitButton>
                      </AddCardWrapper>
                    )}
                  </AddCardsWrapper>
                  <SkipWrapper className="skip-wrapper">
                    <SkipBtn
                      onClick={() => {
                        if (step === 2 && role === "customer") {
                          navigate("/create-order");
                        } else if (step === 3 && role === "worker") {
                          navigate("/search-order");
                        } else {
                          setStep((prev) => prev + 1);
                        }
                      }}
                    >
                      Заполнить позже
                    </SkipBtn>
                  </SkipWrapper>
                </RegisterForm>
                {addVehicle && (
                  <AddVehiclePopUp
                    onSubmit={() => {
                      if (user?.customer?.refId)
                        dispatch(getVehiclesList(user?.customer?.refId));
                      setAddVehicle(false);
                    }}
                    close={() => setAddVehicle(false)}
                  />
                )}
              </>
            )}
          </div>
        </ContentWrapper>
      </Content>
      <div className="image">
        <img src={RegisterImg} alt="" />
      </div>
    </Wrapper>
  );
};

export default memo(UserRegistration);
