import styled from "styled-components";

export const PageWrapper = styled.div`
  margin: 0 auto;
  max-width: 1392px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 31px;
  min-height: calc(100vh - 137px);

  @media screen and (max-width: 768px) {
    height: calc(100vh - 64px);
  }
`;

export const PageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  padding-top: 31px;

  .text {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    text-align: center;
    color: #000;
  }

  .arrowIcon {
    display: none;
  }

  @media screen and (max-width: 480px) {
    padding: 16px;
    background: #ffffff;

    .arrowIcon {
      display: block;
      cursor: pointer;
    }

    .text {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #000;
    }

    div:not(.text) {
      display: none;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 24px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
    padding: 0 8px 8px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 976px;
`;

export const ContentCard = styled.div`
  background: #ffffff;
  border-radius: 16px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .cardHeader {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: #000;
    display: flex;
    gap: 5px;
    align-items: center;
  }

  .vehicles {
    display: flex;
    flex-direction: column;
  }

  .requisites {
    display: flex;
    gap: 80px;
    width: 100%;

    .requisitesColumn {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      max-width: 380px;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: #222222;
    }

    @media screen and (max-width: 1050px) {
      flex-direction: column;
      gap: 16px;
    }
  }

  .feedbackWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .mainInfoWrapper {
    display: flex;
    gap: 24px;
    align-items: center;

    .profilePhoto {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;

      img {
        border-radius: 50%;
      }
    }

    .mainInfo {
      display: flex;
      flex-direction: column;
      gap: 7px;

      .textLarge {
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: -0.5px;
        color: #000;

        @media screen and (max-width: 480px) {
          font-size: 28px;
        }

        @media screen and (max-width: 390px) {
          font-size: 20px;
        }
      }

      .text {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #222222;
      }
    }
  }
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;
  gap: 11px;

  .ratingStars {
    display: flex;
    align-items: center;
    gap: 3px;
  }
`;

export const FeedbackItem = styled.div`
  display: flex;
  gap: 16px;
  width: 356px;

  .profilePhoto {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      border-radius: 50%;
    }
  }

  .feedbackData {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .feedbackInfo {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .name {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        color: #222222;
      }

      .rating {
        display: flex;
        gap: 8px;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        color: #999999;

        .stars {
          display: flex;
          gap: 2px;

          svg {
            width: 12px;
            height: 12px;

            & g path:last-child {
              fill: #878787;
              opacity: 1;
            }

            &.in-active g path:last-child {
              fill: #cfcfcf;
              opacity: 1;
            }
          }
        }
      }
    }

    .feedbackValue {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
    }
  }
`;

export const VehicleItem = styled.div`
  display: flex;
  gap: 8px;
  height: 48px;
  border-bottom: 1px solid #ebebeb;
  align-items: center;
  width: 100%;

  .vehicleInfo {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .textSmall {
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #999999;
    }

    .text {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
    }
  }
`;
