import React, { memo, useEffect } from "react";
import RatingBox from "../../components/ratingBox";
import { ContentTitle } from "../../components/styles/styles";
import {
  FeedbackTitle,
  FeedbackWrapper,
  RatingWrapper,
  SFHeader,
  SFText,
  SingleFeedbackWrapper,
  Wrapper,
} from "./styles";

import defaultImage from "../../assets/images/avatar.png";
import { ReactComponent as UserIcon } from "../../assets/icons/icons-16-small-profile.svg";
import { ReactComponent as InActiveStar } from "../../assets/icons/icons-rating-large-gray.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import getFeedbackRating from "../../redux/modules/getFeedbackRating";
import { Dropdown } from "antd";
import FeedbackEmptyPage from "../../components/emptyPage/feedbackEmptyPage";

const FeedbackAndRatingPage: React.FC = () => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const data = useAppSelector((state: RootState) => state.feedbackRating.data);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const userId = user?.customer?.id;
    const refId = user?.customer?.refId;
    if (userId && refId) {
      dispatch(getFeedbackRating(userId, refId));
    }
  }, [user?.customer?.id]);

  return (
    <Wrapper>
      <ContentTitle>Мой рейтинг</ContentTitle>
      <RatingWrapper>
        <div className="number">{user?.customer?.rating}</div>
        <div className="stars-number-wrapper">
          <div className="stars-wrapper">
            <RatingBox rating={user?.customer?.rating} />
          </div>
          <div className="rating-number">
            <UserIcon /> {data?.total || 0}
          </div>
        </div>
      </RatingWrapper>
      <FeedbackWrapper>
        <FeedbackTitle>
          {user?.customer?.role === "worker"
            ? "Отзывы заказчиков"
            : "Отзывы исполнителей"}
        </FeedbackTitle>
        {data?.feedbacks?.map(
          (
            {
              id,
              description,
              rating,
              type,
              rewards,
              createdBy: {
                avatarUrl,
                birthday,
                checked,
                city,
                commission,
                companyDescription,
                companyId,
                dateOfIssue,
                email,
                feedbackCount,
                firstName,
                id: userId,
                isCompanyManager,
                isStatsAdmin,
                patronymic,
                phoneNumber,
                premium,
                premiumAt,
                rating: userRating,
                rayon,
                refId,
                rejectPassportReason,
                role,
                score,
                secondName,
                series,
                settings,
                status,
                telegramId,
                token,
                totalPaid,
                verificationStatus,
              },
            }: any,
            index: number,
          ) => (
            <SingleFeedbackWrapper key={`feedback_${index}`}>
              <SFHeader>
                <div className="avatar">
                  <img
                    src={
                      avatarUrl
                        ? `data:image/jpeg;base64,${avatarUrl}`
                        : defaultImage
                    }
                    alt=""
                  />
                </div>
                <div className="data-part">
                  <div className="full-name">{`${firstName} ${secondName}`}</div>
                  <div className="rating">
                    <div className="stars">
                      {Array(5)
                        .fill("")
                        .map((v, i) => (
                          <InActiveStar
                            className={rating >= i + 1 ? "" : "in-active"}
                          />
                        ))}
                    </div>
                    <div className="r-text">
                      {rating === 5
                        ? "Отлично"
                        : rating === 4
                        ? "Хорошо"
                        : rating === 3
                        ? // ? "Удовлетворительно"
                          "Нормально"
                        : rating === 2
                        ? "Плохо"
                        : "Ужасно"}
                    </div>
                  </div>
                </div>
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [
                      {
                        key: "1",
                        label: "Оспорить отзыв",
                      },
                    ],
                  }}
                >
                  <div className="three-dots">
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                  </div>
                </Dropdown>
              </SFHeader>
              <SFText>
                {description ? (
                  <>
                    {description}
                    <br />
                  </>
                ) : (
                  <></>
                )}
                {rewards.map(({ title }: any) => title).join(", ")}
              </SFText>
            </SingleFeedbackWrapper>
          ),
        )}
      </FeedbackWrapper>
      {user?.customer?.rating == "0.0" && <FeedbackEmptyPage />}
    </Wrapper>
  );
};

export default memo(FeedbackAndRatingPage);
