import React, { memo, useEffect } from "react";
import { useDispatch } from "react-redux";
import AddManagementItemEmpty from "../../components/addManagementItemEmpty";
import EmployeePageDataList from "../../components/employeePageDataList";
import ManagementPagesDataList from "../../components/managementPagesDataList";
import { useAppSelector } from "../../redux/hooks";
import getManagers from "../../redux/modules/getManagers";
import { Wrapper } from "./styles";
import Loading from "../../components/loading";

const EmployeeManagementPage: React.FC = () => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const { data: managers } = useAppSelector((state) => state.managers);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getManagers(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  return (
    <Wrapper>
      {managers ? (
        managers?.users ? (
          <EmployeePageDataList />
        ) : (
          <AddManagementItemEmpty employees />
        )
      ) : (
        <Loading
          title="Загрузка списка сотрудников"
          description="Не закрывайте окно браузера"
          half
        />
      )}
    </Wrapper>
  );
};

export default memo(EmployeeManagementPage);
