import React, { useEffect, useState } from "react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import "antd/dist/antd.min.css";
import "./App.css";
import MainPage from "./pages/mainPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { store } from "./redux/store/store";
import {
  getAuth,
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { authentication } from "./firebase/config";
import { getToken } from "./utils/uiHelpers";
import {
  authActions,
  getRefIDFromFireBase,
} from "./redux/store/reducers/authSlice";
import axios from "axios";
import { ENDPOINT } from "./constants/general";
import { httpRequest } from "./utils/httpRequest";

import jwtDecode from "jwt-decode";

export type GlobalContent = {
  setUpRecaptcha: (number: string) => any;
  user?: any;
  setUser?: any;
};

export const MyGlobalContext = React.createContext<GlobalContent>({
  setUpRecaptcha,
});

export const useGlobalContext = () => React.useContext(MyGlobalContext);

async function setUpRecaptcha(number: string) {
  const recapchaVerifer = new RecaptchaVerifier(
    "recaptcha-container",
    { size: "invisible" },
    authentication,
  );

  return new Promise((resolve, reject) => {
    signInWithPhoneNumber(authentication, number, recapchaVerifer)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        if (number && error.message) {
          httpRequest({
            method: "POST",
            url: `${ENDPOINT}/actions/logs-auth`,
            data: {
              phone: number,
              message: error.message,
            },
          });
        }
        reject(error);
      });
  });
}

const pushTokenHandler = async (refId: any) => {
  try {
    await axios.post(
      `${ENDPOINT}/api/customers/${refId}/push-token`,
      {
        token: localStorage.getItem("pushToken"),
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
  } catch (error) {}

  localStorage.removeItem("pushToken");
};

function App() {
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    store.dispatch(
      authActions.setAccessToken(localStorage.getItem("accessToken")),
    );
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      authentication,
      async (currentUser: any) => {
        if (currentUser) {
          setUser(currentUser);
          store.dispatch(authActions.setAccessToken(currentUser.accessToken));
          localStorage.setItem("accessToken", currentUser.accessToken);
          localStorage.setItem("uid", currentUser.uid);
          if (getToken())
            store
              .dispatch(getRefIDFromFireBase({ uid: currentUser.uid }))
              .then(async (result) => {
                if (localStorage.getItem("pushToken")) {
                  pushTokenHandler(result?.payload?.refId);
                }
              });
        } else {
          if (getToken()) {
            store
              .dispatch(getRefIDFromFireBase({ uid: getToken() }))
              .then((result) => {
                if (localStorage.getItem("pushToken")) {
                  pushTokenHandler(result?.payload?.refId);
                }
              });
          }
        }
      },
    );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (user) {
      axios.interceptors.request.use(async (config: any) => {
        const token: string = localStorage.getItem("accessToken") || "";
        const decode: any = jwtDecode(token);
        const exp: number = (decode?.exp || 0) * 1000;
        const currentDate = Date.now();
        const diff = exp - currentDate;
        if (diff <= 0) {
          try {
            // @ts-ignore
            const payload = await getAuth().verifyIdToken(token, true);
            return config;
          } catch (err: any) {
            if (err?.code == "auth/id-token-revoked") {
              authentication.signOut();
              // Token has been revoked. Inform the user to reauthenticate or signOut() the user.
            } else {
              localStorage.removeItem("accessToken");
              window.location.href = "/login";
              // Token is invalid.
            }
            return config;
          }
        }
        return config;
      });
    }
  }, [user]);

  return (
    <Provider store={store}>
      <MyGlobalContext.Provider value={{ setUpRecaptcha, user, setUser }}>
        <BrowserRouter>
          <MainPage />
          <ToastContainer autoClose={2000} />
        </BrowserRouter>
      </MyGlobalContext.Provider>
    </Provider>
  );
}

export default App;
