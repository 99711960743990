import styled from "styled-components";
import { SmallTitle, styles } from "../styles/styles";

export const Wrapper = styled.div`
  background-color: white;
  padding: 24px;
  border-radius: 10px;
  @media only screen and (min-width: 1440px) {
    padding: 24px;
  }
  @media only screen and (max-width: 1050px) {
    padding: 16px;
  }
`;

export const Title = styled(SmallTitle)`
  line-height: 20px;
  margin-bottom: 24px;
`;

export const ProcessSteps = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  margin: 60px auto;

  .process-bar {
    width: ${({
      step,
      lengtOfArray,
    }: {
      step: number;
      lengtOfArray: number;
    }) => {
      let stepCount = step - 1;
      if (stepCount > lengtOfArray) stepCount = lengtOfArray;
      return `${stepCount * (100 / lengtOfArray)}%`;
    }};
    height: 5px;
    background-color: ${styles.mainColor};
    border-radius: 10px;
  }

  .process-track {
    height: 5px;
    width: 100%;
    background-color: #ececec;
    border-radius: 10px;
  }

  .process-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  @media only screen and (max-width: 1050px) {
    margin: 40px auto;
    position: relative;
    .process-bar {
      width: 100%;
      height: ${({
        step,
        lengtOfArray,
      }: {
        step: number;
        lengtOfArray: number;
      }) => {
        let stepCount = step - 1;
        if (stepCount > lengtOfArray) stepCount = lengtOfArray;
        return `${stepCount * (100 / lengtOfArray)}%`;
      }};
    }

    .process-track {
      width: 5px;
      height: 250px;
    }

    .process-steps {
      flex-direction: column;
      position: absolute;
      top: 0;
      left: -6.5px;
      bottom: 0;
    }
  }
`;

export const ProcessItem = styled.div`
  text-align: center;
  width: 18px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 11.5px;

  .circle {
    width: 18px;
    height: 18px;
    background-color: ${({ active }: { active?: boolean }) =>
      active ? styles.mainColor : "#999999"};
    border-radius: 100%;
    border: 2px solid white;
    box-shadow: 0 0 0 2px
      ${({ active }: { active?: boolean }) =>
        active ? styles.mainColor : "#999999"};
  }

  .process-name {
    position: absolute;
    width: fit-content;
    /* max-width: 100%; */
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
    margin: auto;
    bottom: 30px;
  }

  .process-date {
    position: absolute;
    width: fit-content;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    mix-blend-mode: normal;
    opacity: 0.5;
    white-space: nowrap;
    margin: auto;
    top: 22px;
  }

  @media only screen and (max-width: 1250px) {
    .process-name {
      white-space: nowrap;
    }
  }
  @media only screen and (max-width: 1050px) {
    bottom: 0;
    .process-name {
      bottom: 10px;
      left: 25px;
      font-size: 16px;
    }

    .process-date {
      top: 7px;
      left: 25px;
    }
  }
`;

export const ProcessBarList = styled.div``;

export const ProcessBar = styled.div`
  background: #f7f4f1;
  border-radius: 10px;
  padding: 16px 16px 8px;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BarHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .status {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: flex;

    .progress {
      width: 16px;
      height: 16px;
      border: 1px solid ${styles.mainColor};
      border-radius: 50%;
      box-shadow: 0 0 0 4px rgba(255, 102, 51, 0.2);
      margin: auto;
    }

    .active {
      margin: auto;
    }

    .not-active {
      margin: auto;
    }
  }

  .data {
    width: calc(100% - 40px);

    .name {
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .rate {
        width: fit-content;
        margin-left: auto;

        .rate-number {
          margin-left: 10px;
        }

        .ant-rate-star-zero,
        .ant-rate-star-focused {
          .ant-rate-star-first,
          .ant-rate-star-second {
            color: #c7c3c1;
          }
        }

        .ant-rate-star-full,
        .ant-rate-star-full.ant-rate-star-focused {
          color: #fec826 !important;

          .ant-rate-star-first,
          .ant-rate-star-second {
            color: #fec826;
          }
        }
      }
    }

    .date {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      margin-top: 4px;
      color: #222222;
      /* mix-blend-mode: normal; */
      /* opacity: 0.5; */
    }
  }

  .rating-part {
    display: flex;
    align-items: center;
    span {
      margin-left: 10px;
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
      color: #000;
    }
  }

  @media only screen and (max-width: 1050px) {
    .data .name {
      flex-direction: column;
      align-items: flex-start;

      .rate {
        margin: 0;
      }
    }
  }
`;

export const BarDesc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  .description {
    width: 100%;
    max-width: 635px;
    margin-bottom: 8px;
  }

  .infoBlock {
    margin-top: 22px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 7px 100px;
    width: 100%;

    .column {
      display: flex;
      flex-direction: column;
      min-width: 320px;
      max-width: 350px;
      gap: 7px;

      &:last-child {
        justify-content: flex-end;
      }

      @media screen and (max-width: 1050px) {
        width: 100%;
        max-width: 100%;
      }

      .info {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        gap: 10px;

        .price {
          /* font-family: "Google Sans", sans-serif; */
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: right;
          color: #000;
        }

        .priceBigSize {
          /* font-family: "Google Sans", sans-serif; */
          font-size: 16px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1;
          letter-spacing: normal;
          text-align: right;
          color: #000;

          @media screen and (max-width: 1050px) {
            font-size: 18px;
          }
        }

        .subtitle {
          /* font-family: "Google Sans", sans-serif; */
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 16px;
          letter-spacing: normal;
          /* color: #222222;
        mix-blend-mode: normal;
        opacity: 0.5; */
        }
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;

  button {
    margin: 0;
    padding: 18px 30px;
  }

  .btn_wrapper {
    display: flex;
    gap: 15px;
  }

  .btn_geo {
    background: transparent;
    border-radius: 12px;
    border: 1px solid #ff6633;
    color: #ff6633;
  }

  .btn_geo:hover {
    opacity: 0.8;
  }

  .cancel {
    background-color: transparent;
    color: black;
    padding: 18px 30px;

    button {
      padding: 0;
      background-color: transparent;
      border: 0;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    button {
      width: 100%;
      padding: 7px 15px;
    }
  }
`;

export const PopUpAcceptBody = styled.div`
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    margin: 16px 0;
  }

  button {
    &.need-help {
      width: fit-content;
      padding: 0;
      margin-top: 25px;
      background-color: transparent;
      color: black;
    }
  }
`;

export const PopUpRateBody = styled.div`
  .rate-box {
    width: fit-content;
    margin: 0 auto 35px;

    .ant-rate {
      .ant-rate-star {
        height: fit-content;
        margin-right: 18px !important;
      }
    }

    .rate-result {
      margin-top: 15px;

      b {
        font-weight: 500;
        font-size: 20px;
        line-height: 20px;
        margin-right: 10px;
      }

      span {
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;

        color: #222222;
      }
    }
  }

  .desc {
    width: 320px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #666666;
    margin-top: 32px;
    margin-bottom: 24px;
  }

  .rate-box {
    .ant-rate-star-zero,
    .ant-rate-star-focused {
      /* color: #000000 !important; */

      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #c7c3c1;
      }
    }

    .ant-rate-star-full,
    .ant-rate-star-full.ant-rate-star-focused {
      color: #fec826 !important;

      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #fec826;
      }
    }
  }
`;

export const ProcessFinished = styled.div`
  margin: 35px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .text {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    margin: 0 29px 0 0;

    svg {
      margin-right: 8px;
    }
  }

  button {
    margin: 0;
    padding: 7px 17px;
  }

  @media only screen and (max-width: 1250px) {
    flex-direction: column;
    button {
      margin-top: 15px;
    }
  }
`;

export const BarList = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .item {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .name {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;

      color: #222222;

      mix-blend-mode: normal;
      opacity: 0.5;
    }

    .price {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }

    b.price {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
    }
  }
`;

export const Half = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;

  .mt-auto {
    margin-top: auto;
  }

  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
`;
