import React from "react";
import { InputWrapper } from "./styles";

interface IInputProps {
  label?: string;
  width?: string;
  type?: "vehicle_number";
  placeholder?: string;
  onChange: any;
  value?: string;
  name: string;
  required?: boolean;
  disabled?: boolean;
  height?: string;
  maxLength?: number | undefined;
  upperCase?: boolean;
  readOnly?: boolean;
  autoComplete?: string;
}

const Input: React.FC<IInputProps> = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  disabled,
  height,
  maxLength,
  readOnly = false,
  autoComplete,
}: any) => {
  return (
    <InputWrapper
      width={width}
      height={height}
      //active={value?.length}
      className={label ? (value?.length ? "active" : "") : "active"}
      isLabel={!!label}
    >
      {type === "vehicle_number" ? (
        <input
          autoComplete={autoComplete}
          name={name}
          value={value || ""}
          type={"text"}
          placeholder={placeholder}
          maxLength={maxLength}
          readOnly={readOnly}
          onChange={(e) => {
            const { value, name } = e?.target;
            const [n1, n2, n3, n4, n5, n6, n7, n8, n9] = value.split("");
            const letters =
              "абвгдеёжзийклмнопрстуфхцчшщъыьэюяabcdefghijklmnopqrstuvwxyz";
            if (
              value.length <= 9 &&
              (!n1 || letters.includes(n1?.toLowerCase() || "")) &&
              (!n2 || /^\d$/.test(n2?.toLowerCase() || "")) &&
              (!n3 || /^\d$/.test(n3?.toLowerCase() || "")) &&
              (!n4 || /^\d$/.test(n4?.toLowerCase() || "")) &&
              (!n5 || letters.includes(n5?.toLowerCase() || "")) &&
              (!n6 || letters.includes(n6?.toLowerCase() || "")) &&
              (!n7 || /^\d$/.test(n7?.toLowerCase() || "")) &&
              (!n8 || /^\d$/.test(n8?.toLowerCase() || "")) &&
              (!n9 || /^\d$/.test(n9?.toLowerCase() || ""))
            ) {
              onChange(e);
            }
          }}
          spellCheck={false}
          disabled={disabled}
        />
      ) : (
        <input
          autoComplete={autoComplete}
          name={name}
          value={value || ""}
          type={type || "text"}
          placeholder={placeholder}
          onChange={onChange}
          spellCheck={false}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}
    </InputWrapper>
  );
};

export default Input;
