import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/icon-24-intellicon-basic-check-mark.svg";
import { ReactComponent as VisibilityIcon } from "../../assets/icons/visibility_black_18dp.svg";
import { ReactComponent as VisibilityOffIcon } from "../../assets/icons/visibility_off_black_18dp.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import {
  YMaps,
  ZoomControl,
  Map,
  Circle,
  Placemark,
} from "@pbe/react-yandex-maps";
import {
  NotificationsBlock,
  Wrapper,
  AreasList,
  AreaBlock,
  AddAreaBlock,
  SwitchContainer,
} from "./styles";
import Accordion from "../../UI/Accordion";
import {
  createWorkerNotificationPoint,
  getWorkerNotificationPoints,
  editWorkerNotificationPoint,
} from "../../redux/store/reducers/notificationsSlice";
import { NotificationArea } from "../../types";
import { getUserLocation } from "../../utils/getUserLocation";
import { Switch } from "antd";
import { updateProfile } from "firebase/auth";
import {
  getMe,
  updateProfileInfo,
} from "../../redux/store/reducers/profileSlice";

const SettingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const telegramButtonRef = useRef<HTMLDivElement | null>(null);

  const user = useAppSelector((state: RootState) => state.auth.user);
  const { notificationAreas } = useAppSelector((state) => state.notifications);
  const [ymap, setYmap] = useState<any>(null);
  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [areasAccordionOpen, setAreasAccordionOpen] = useState<boolean>(true);
  const [zoom, setZoom] = useState(9);
  const [areas, setAreas] = useState<NotificationArea[]>(notificationAreas);
  const [addAreaBlock, setAddAreaBlock] = useState<boolean>(false);
  const [activeAreaBlock, setActiveAreaBlock] = useState<number | null>(null);
  const [smsNotifications, setSmsNotifications] = useState<boolean>(false);
  const [smsPending, setSmsPending] = useState<boolean>(false);
  const [telegramNotifications, setTelegramNotifications] =
    useState<boolean>(false);
  const [telegramPending, setTelegramPending] = useState<boolean>(false);
  const [newAreaParams, setNewAreaParams] = useState<{
    latitude: number;
    longitude: number;
    radius: number;
  } | null>(null);

  const dragEndHandler = (index: number, e: any) => {
    const coordinates = e.originalEvent.target.geometry._coordinates;
    setNewAreaParams({
      ...newAreaParams!,
      latitude: coordinates[0],
      longitude: coordinates[1],
    });
    // dots.find((dot, index) => index);
    // setDots([...dots, []])
  };

  const renderAreas = useCallback(
    (areas: NotificationArea[]) => {
      return areas.map((area, index) => {
        return (
          <Circle
            key={`circle_${index}`}
            geometry={[[area.latitude, area.longitude], area.radius * 1000]}
            options={{
              draggable: activeAreaBlock === index ? true : false,
              fillColor: area.enabled ? "#ff663399" : "#868f9b4a",
              strokeWidth: 0,
            }}
            onDragEnd={(e: any) => {
              const newAreas = areas.map((area, newIndex) => {
                const coordinates =
                  e.originalEvent.target.geometry._coordinates;
                if (newIndex === index) {
                  return {
                    ...area,
                    latitude: coordinates[0],
                    longitude: coordinates[1],
                  };
                } else return area;
              });
              setAreas([...newAreas]);
            }}
          />
        );
      });
    },
    [areas, activeAreaBlock],
  );

  const renderAreaBlocks = useCallback(
    (areas: NotificationArea[]) => {
      return areas.map((area, index) => {
        return (
          <AreaBlock
            active={activeAreaBlock === index}
            key={`area_${index}`}
            onClick={() => {
              setCenter([area.latitude, area.longitude]);
              setActiveAreaBlock(activeAreaBlock === index ? null : index);
            }}
          >
            <div className="areaRow">
              <input
                type="text"
                value={
                  activeAreaBlock === index
                    ? area.title
                    : `Область «${area.title}»`
                }
                readOnly={activeAreaBlock !== index}
                onClick={(e) => {
                  if (activeAreaBlock === index) {
                    e.stopPropagation();
                  }
                }}
                onChange={(e) => {
                  const newAreas = areas.map((area, newIndex) => {
                    if (newIndex === index) {
                      return {
                        ...area,
                        title: e.target.value,
                      };
                    } else return area;
                  });
                  setAreas([...newAreas]);
                }}
              />
              <div className="visibilityIcon">
                <VisibilityIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    const newAreas = areas.map((area, newIndex) => {
                      if (newIndex === index) {
                        return {
                          ...area,
                          enabled: !area.enabled,
                        };
                      } else return area;
                    });
                    setAreas([...newAreas]);
                    editAreaHandler({
                      ...area,
                      enabled: !area.enabled,
                    });
                  }}
                  className={`${!area.enabled ? "active" : ""}`}
                />
                <VisibilityOffIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    const newAreas = areas.map((area, newIndex) => {
                      if (newIndex === index) {
                        return {
                          ...area,
                          enabled: !area.enabled,
                        };
                      } else return area;
                    });
                    setAreas([...newAreas]);
                    editAreaHandler({
                      ...area,
                      enabled: !area.enabled,
                    });
                  }}
                  className={`${area.enabled ? "active" : ""}`}
                />
              </div>
            </div>

            <div className="areaDropdown">
              <div className="inputBlock">
                <label htmlFor={`radiusInput_${index}`}>Радиус, км</label>
                <input
                  id={`radiusInput_${index}`}
                  type="number"
                  placeholder="Радиус, км"
                  value={area.radius}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    const newAreas = areas.map((area, newIndex) => {
                      if (newIndex === index) {
                        return {
                          ...area,
                          radius: Number(e.target.value),
                        };
                      } else return area;
                    });
                    setAreas([...newAreas]);
                  }}
                />
              </div>

              <button
                onClick={() => editAreaHandler(area)}
                disabled={area.title === ""}
              >
                <CheckIcon />
              </button>
            </div>
          </AreaBlock>
        );
      });
    },
    [areas, activeAreaBlock],
  );

  useEffect(() => {
    const success = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setLocation([latitude, longitude]);
      setCenter([latitude, longitude]);
    };

    getUserLocation(success);
  }, []);

  useEffect(() => {
    if (user) {
      const smsAllowed = user.customer.notifications_settings?.sms;
      const telegramAllowed = user.customer.notifications_settings?.telegram;
      setSmsNotifications(smsAllowed === undefined ? true : smsAllowed);
      setTelegramNotifications(
        telegramAllowed === undefined ? false : telegramAllowed,
      );
    }
  }, [user]);

  const editAreaHandler = ({
    latitude,
    longitude,
    radius,
    enabled,
    id,
    title,
  }: NotificationArea) => {
    if (user?.customer.refId) {
      dispatch(
        editWorkerNotificationPoint({
          refId: user.customer.refId,
          latitude: latitude,
          longitude: longitude,
          radius: radius,
          enabled: enabled,
          pointId: id,
          title: title,
        }),
      );
    }
  };

  const createAreaHandler = () => {
    if (user?.customer.refId && newAreaParams)
      dispatch(
        createWorkerNotificationPoint({
          refId: user.customer.refId,
          latitude: newAreaParams.latitude,
          longitude: newAreaParams.longitude,
          radius: newAreaParams.radius,
          enabled: true,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setAddAreaBlock(false);
          setNewAreaParams(null);
        }
      });
  };

  useEffect(() => {
    setAreas([...notificationAreas]);
  }, [notificationAreas]);

  useEffect(() => {
    if (addAreaBlock) {
      setNewAreaParams({
        latitude: location[0],
        longitude: location[1],
        radius: 0,
      });
    } else {
    }
  }, [addAreaBlock]);

  useEffect(() => {
    if (user?.customer.refId) {
      dispatch(getWorkerNotificationPoints({ refId: user?.customer.refId }));
    }
  }, [user]);

  const callbackOnAuthUser = async (tgUser: any) => {
    console.log(tgUser);
    await dispatch(
      updateProfileInfo({
        refId: user?.customer.refId!,
        data: {
          telegramId: tgUser.id,
        },
      }),
    );

    await dispatch(getMe(user?.customer.refId!));
  };

  useEffect(() => {
    if (telegramButtonRef.current && user) {
      (window as any).TelegramLoginWidget = {
        callbackOnAuth: (user: any) => callbackOnAuthUser(user),
      };

      const script = document.createElement("script");
      script.src = "https://telegram.org/js/telegram-widget.js?2";
      script.setAttribute("data-telegram-login", "Rukki_Bot");
      script.setAttribute("data-size", "medium");
      script.setAttribute("data-request-access", "write");
      script.setAttribute("data-radius", "10");
      script.setAttribute(
        "data-onauth",
        "TelegramLoginWidget.callbackOnAuth(user)",
      );
      script.async = true;

      telegramButtonRef.current.appendChild(script);
    }
  }, [telegramButtonRef.current, user]);

  const changeNotificationSettings = async (
    settingName: "telegram" | "sms",
  ) => {
    if (settingName === "telegram") {
      setTelegramNotifications(!telegramNotifications);
      setTelegramPending(true);
    } else {
      setSmsNotifications(!smsNotifications);
      setSmsPending(true);
    }
    await dispatch(
      updateProfileInfo({
        refId: user?.customer.refId!,
        data: {
          notifications_settings: {
            ...user?.customer.notifications_settings,
            [settingName]: user?.customer?.notifications_settings
              ? !user?.customer?.notifications_settings[settingName]
              : true,
          },
        },
      }),
    );
    await dispatch(getMe(user?.customer.refId!));

    if (settingName === "telegram") {
      setTelegramPending(false);
    } else {
      setSmsPending(false);
    }
  };

  return (
    <Wrapper>
      <div className="title">Настройки уведомлений</div>
      <SwitchContainer>
        <div className="switchBlock">
          <div className="switchTitle">Telegram</div>
          <Switch
            onChange={() => changeNotificationSettings("telegram")}
            checked={telegramNotifications}
            loading={telegramPending}
          />
          <div ref={telegramButtonRef}></div>
        </div>
        <div className="switchBlock">
          <div className="switchTitle">СМС</div>
          <Switch
            onChange={() => changeNotificationSettings("sms")}
            checked={smsNotifications}
            loading={smsPending}
          />
        </div>
        {/* <div className="switchBlock">
          <div className="switchTitle">Email</div>
          <Switch onChange={() => changeNotificationSettings("sms")} />
        </div> */}
      </SwitchContainer>
      {user?.customer.role === "worker" && (
        <Accordion
          list={
            <NotificationsBlock>
              <AreasList>
                {renderAreaBlocks(areas)}

                <AddAreaBlock open={addAreaBlock}>
                  <div
                    className="addAreaButton"
                    onClick={() => setAddAreaBlock(!addAreaBlock)}
                  >
                    Добавить область
                  </div>
                  <div className="addAreaBlockRow">
                    <div className="inputBlock">
                      <label htmlFor="newAreaInput">Радиус, км</label>
                      <input
                        id="newAreaInput"
                        type="number"
                        placeholder="Радиус, км"
                        value={newAreaParams?.radius}
                        onChange={(e) => {
                          setNewAreaParams({
                            ...newAreaParams!,
                            radius: Number(e.target.value),
                          });
                        }}
                      />
                    </div>

                    <button onClick={createAreaHandler}>
                      <CheckIcon />
                    </button>
                  </div>
                </AddAreaBlock>
              </AreasList>
              <div className="mapContainer">
                <YMaps>
                  <Map
                    onLoad={(e) => {
                      setYmap(e);
                    }}
                    instanceRef={(e) => {
                      setYmap(e);
                    }}
                    width={"100%"}
                    height={"100%"}
                    defaultState={{ center, zoom }}
                    state={{ center, zoom }}
                    onClick={(e: any) => {
                      const coords = e.get("coords");
                      // mapClickHandler(coords);
                      // setGetLocationFor("1");
                      // setLocation(coords);
                      //getAddress(coords);
                    }}
                    options={{
                      maxAnimationZoomDifference: 23,
                      yandexMapDisablePoiInteractivity: false,
                    }}
                  >
                    {location?.length > 0 && (
                      <Placemark
                        options={{
                          preset: "islands#circleIcon",
                          iconColor: "#ff6633",
                          draggable: false,
                          hideIconOnBalloonOpen: false,
                          hasBalloon: true,
                          balloonShadow: false,
                          balloonPanelMaxMapArea: 0,
                        }}
                        geometry={{
                          type: "Point",
                          coordinates: location,
                        }}
                        onDragEnd={(e: any) => {
                          const coordinates =
                            e.get("target").geometry._coordinates;
                          setLocation(coordinates);
                        }}
                        properties={{
                          iconContent: `
                          <div class="myIconContentBoxAnimation">
                            
                            <div class="core-circle"></div>
                          </div>`,
                        }}
                      />
                    )}
                    <ZoomControl
                      options={{
                        position: { right: 8, top: 8 },
                      }}
                    />
                    {renderAreas(areas)}
                    {newAreaParams && (
                      <Circle
                        geometry={[
                          [newAreaParams.latitude, newAreaParams.longitude],
                          newAreaParams.radius * 1000,
                        ]}
                        options={{
                          draggable: true,
                          strokeWidth: 0,
                        }}
                        onDragEnd={(e: any) => dragEndHandler(1, e)}
                      />
                    )}
                  </Map>
                </YMaps>
              </div>
            </NotificationsBlock>
          }
          title={"Области уведомлений"}
          open={areasAccordionOpen}
          setOpen={() => setAreasAccordionOpen(!areasAccordionOpen)}
        />
      )}
    </Wrapper>
  );
};

export default memo(SettingPage);

