import styled from "styled-components";

export const Box = styled.div`
  padding: 20px 24px;
  @media only screen and (max-width: 768px) {
    padding: 18px 16px;
  }
  @media only screen and (max-width: 998px) {
    display: none;
  }
`;
export const BoxInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f7f4f1;
  .logo {
    height: 32px;
    img {
      height: 100%;
    }
  }
`;
export const RightFooter = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  @media only screen and (max-width: 800px) {
    .agreement-text {
      display: none;
    }
  }
`;
export const Typography = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #999999;
  /* margin-right: 32px; */
  cursor: pointer;
`;

export const SocialMediaWrapper = styled.div`
  width: 144px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 720px) {
    margin: 0;
  }
`;

export const SocialMediaLogo = styled.a`
  width: 32px;
  height: 32px;

  svg {
    width: 100%;
    height: 100%;
  }
`;
