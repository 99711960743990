import React, { useEffect, useState } from "react";
import { RegisterUserWrapper, SubmitButton, Title } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMe } from "../../redux/store/reducers/authSlice";
import { InputBox } from "./InputBox";
import { getCountryByPhone } from "../../utils/getCountryByPhone";
import jwtDecode from "jwt-decode";
import {
  fetchSendRespond,
  getTechTask,
  selectWorker,
} from "../../redux/store/reducers/orderSlice";
import { updateProfileInfo } from "../../redux/store/reducers/profileSlice";
import { clearRegistrationSlice } from "../../redux/store/reducers/registrationSlice";
import { CustomerRole } from "../../types";
import { inputDataList } from "../../utils/uiHelpers";

const RegisterUser: React.FC<{
  // ListItems: string[];
  onSuccess: any;
  role: CustomerRole;
  setRole: React.Dispatch<React.SetStateAction<CustomerRole>>;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  companyData?: string;
}> = ({ role }) => {
  const auth = useAppSelector((state) => state.auth);
  const registration = useAppSelector((state) => state.registration);
  const { user } = auth;
  const [formData, setFormData] = useState<any>({});
  const [registrationPending, setRegistrationPending] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth?.phoneNumber) {
      setFormData((prev: any) => ({
        ...prev,
        phone_number: auth?.phoneNumber,
      }));
    } else {
      try {
        const accessToken: string = localStorage.getItem("accessToken") || "";
        const decode: { phone_number: string } = jwtDecode(accessToken);
        const phoneNumber = decode?.phone_number;
        setFormData((prev: any) => ({
          ...prev,
          phone_number: phoneNumber,
        }));
      } catch (err) {}
    }
  }, [auth]);

  useEffect(() => {
    if (formData?.phone_number) {
      const countryFromPN = getCountryByPhone(formData?.phone_number);
      setFormData((prev: any) => ({
        ...prev,
        country: {
          ...countryFromPN,
        },
        city: user?.customer?.city
          ? { text: user?.customer?.city, open: false }
          : { text: "", open: false },
      }));
    }
  }, [formData?.phone_number]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user?.customer) {
      let { firstName, secondName, patronymic, phoneNumber, email, birthday } =
        user?.customer;

      setFormData((prev: any) => ({
        ...prev,
        first_name: firstName,
        last_name: secondName,
        patronymic: patronymic,
        phone_number: phoneNumber,
        birth_date: birthday,
        email,
      }));
    }
  }, [user]);

  const submitHandler = async () => {
    const updateProfile = {
      firstName: formData.first_name,
      secondName: formData.last_name,
      patronymic: formData.patronymic,
      city: formData.city.text,
      country: formData.country?.data?.name,
      email: formData.email,
    };

    if (
      user?.customer?.refId &&
      updateProfile.firstName &&
      updateProfile.secondName &&
      updateProfile.city &&
      updateProfile.country &&
      updateProfile.email
    ) {
      setRegistrationPending(true);
      await dispatch(
        updateProfileInfo({
          refId: user?.customer?.refId,
          data: updateProfile,
        }),
      ).then(async (updateResponse: any) => {
        await dispatch(getMe(user?.customer?.refId!));
        if (registration.workerToAppoint) {
          await dispatch(
            selectWorker({
              refId: registration.workerToAppoint.refId,
              offerId: registration.workerToAppoint.offerId,
              orderId: registration.workerToAppoint.orderId,
            }),
          );
          await dispatch(
            getTechTask({
              refId: user?.customer?.refId!,
              offerId: registration.workerToAppoint.offerId,
              coordinates: registration.workerToAppoint.coordinates,
            }),
          );
        }
        if (registration.workerRespond) {
          dispatch(fetchSendRespond({ ...registration.workerRespond })).then(
            (response) => {
              if (response.meta.requestStatus === "fulfilled") {
                if (registration.pageToRedirect) {
                  navigate(registration.pageToRedirect);
                } else {
                  navigate("/profile");
                }
              }
            },
          );
        }
        if (registration.pageToRedirect && !registration.workerRespond) {
          navigate(registration.pageToRedirect);
        } else {
          navigate("/profile");
        }
        setRegistrationPending(false);
        dispatch(clearRegistrationSlice());
      });
    }
  };

  return (
    <>
      <Title>Заполните свои данные</Title>
      <RegisterUserWrapper>
        {inputDataList.map(
          ({ label, name, width, required, type, disabled, lettersOnly }) => (
            <InputBox
              key={name}
              label={label}
              name={name}
              width={width}
              onChange={onChange}
              value={formData?.[name]}
              type={type}
              required={required}
              formData={formData}
              labelSize={"14px"}
              disabled={disabled}
              lettersOnly={lettersOnly}
            />
          ),
        )}
      </RegisterUserWrapper>

      {/* <List>
        {ListItems.map((item, index) => {
          return (
            <div className="listItem" key={index}>
              <div className="icon">
                <SelectedIconSmall />
              </div>
              <div className="text">{item}</div>
            </div>
          );
        })}
      </List> */}
      <SubmitButton
        disabled={
          !(
            // errValid.firstName &&
            // errValid.lastName &&
            (
              formData?.first_name &&
              formData?.last_name &&
              formData?.phone_number &&
              formData?.country?.data?.country_id &&
              formData?.city?.text &&
              formData?.email &&
              role
            )
          ) || registrationPending
        }
        onClick={submitHandler}
      >
        Продолжить
      </SubmitButton>
    </>
  );
};

export default RegisterUser;
