import { DatePicker } from "antd";
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { Description, PillWrapper, TimeBox } from "./styles";
import "moment/locale/ru";
import locale from "antd/es/date-picker/locale/ru_RU";
import TimePicker from "../../timePicker";

interface Props {
  onSubmit?: any;
  close?: any;
  role?: "customer" | "worker";
  setAnyTimeHandler?: (x: boolean) => void;
  tab?: (e: any) => void;
  defaultValue?: any;
  setShift?:
    | React.Dispatch<React.SetStateAction<number>>
    | ((shifts: number) => void);
  shift?: number;
}

const TimePopUp: React.FC<Props> = ({
  onSubmit,
  close,
  role,
  setAnyTimeHandler,
  tab,
  defaultValue,
  setShift,
  shift,
}) => {
  const [time, setTime] = useState<moment.Moment | null>(
    moment().minutes() > 30
      ? moment().add(2, "hours").minutes(0)
      : moment().add(1, "hours").minutes(30),
  );
  const [date, setDate] = useState<moment.Moment | null>(moment);
  const [isAny, setIsAny] = useState<boolean>(false);
  const [hideTime, setHideTime] = useState<boolean>(false);

  useEffect(() => {
    if (defaultValue?.time && defaultValue?.date) {
      // setTime(defaultValue?.time);
      setDate(defaultValue?.date);
    }
  }, []);

  return (
    <PopUpContainer
      title="Когда приступить к работе?"
      button="Продолжить"
      onSubmit={() => {
        if (isAny) {
          onSubmit("Любое время");
        } else onSubmit({ date, time });
        close();
      }}
      close={close}
      tab={tab}
    >
      <TimeBox>
        {!hideTime && (
          <>
            <div className="date input-box">
              <div className="label">Дата</div>
              <DatePicker
                autoFocus
                locale={locale}
                format={"DD MMMM YYYY"}
                placeholder="Выберите дату"
                disabledDate={(currentDate) =>
                  currentDate < moment().add(-1, "days")
                }
                value={date}
                onSelect={(d) => {
                  setDate(d);
                  setTime(moment().hour(8).minute(0));
                }}
                onChange={(d) =>
                  d &&
                  (() => {
                    setDate(d);
                    setTime(moment().hour(8).minute(0));
                  })
                }
                allowClear={false}
              />
            </div>
            <div className="time input-box">
              <div className="label">Время</div>
              <TimePicker
                date={date}
                time={time}
                setTime={setTime}
                setDate={setDate}
              />
            </div>
          </>
        )}
      </TimeBox>
      <PillWrapper>
        <div
          className={`pill ${
            date && date?.format("L") === moment().format("L") && "active"
          }`}
          onClick={() => {
            setDate(moment());
            setTime(moment().add(1, "hour"));
            setHideTime(false);
          }}
        >
          Сегодня
        </div>
        <div
          className={`pill ${
            date &&
            moment(date).format("L") === moment().add(1, "days").format("L") &&
            "active"
          }`}
          onClick={() => {
            setDate(moment().add(1, "days"));
            setTime(moment().hour(8).minute(0));
            setHideTime(false);
            setShift && shift === 0 && setShift(1);
          }}
        >
          Завтра
        </div>
        <div
          className={`pill ${!date && "active"}`}
          onClick={() => {
            setDate(null);
            setTime(null);
            setHideTime(true);
            if (role === "worker")
              if (setAnyTimeHandler) {
                setAnyTimeHandler(true);
                setIsAny(true);
                // setDate(moment(date));
              }
          }}
        >
          {role === "worker" ? "Любое время" : "По договоренности"}
        </div>
      </PillWrapper>

      <Description>
        {role === "worker"
          ? "Сроки будут согласованы с выбранным заказчиком"
          : "Сроки будут согласованы с выбранным исполнителем"}
      </Description>
    </PopUpContainer>
  );
};

export default TimePopUp;
// export default TimePopUp;
