import React, { memo, useEffect, useState } from "react";
import MyOrderEmptyPage from "../../components/emptyPage/myOrderEmptyPage";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { OrdersFilter, OrdersList } from "./styles";
import {
  getMyOrders,
  setOrdersFilter,
} from "../../redux/store/reducers/orderSlice";
import Loading from "../../components/loading";
import DeleteOrderPopUp from "../../components/popUp/deleteOrderPopUp";
import { ENDPOINT } from "../../constants/general";
import useHttpRequest from "../../hooks/useHttpRequest";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";
import Pagination from "../../UI/Pagination";
import WorkItem from "../../components/workItem";
import OrdersEmptyTag from "../../components/ordersEmptyTag";

type OrdersFilter =
  | "all"
  | "archived"
  | "in_progress"
  | "canceled"
  | "completed";

const MyOrdersPage: React.FC = () => {
  const {
    myOrders,
    myOrdersTotal,
    pagination,
    loading,
    isFetching,
    isFetchMore,
    ordersFilter,
  } = useAppSelector((state) => state.order);

  const [onDelete, setOnDelete] = useState({ popUp: false, data: null });
  const dispatch = useAppDispatch();
  const [filter, setFilter] = useState<OrdersFilter>(ordersFilter);
  const pageSize: number = 10;

  const user = useAppSelector((state: RootState) => state.auth.user);

  const handleFetchOrders = (take: number, skip: number) => {
    if (isFetching || !user?.customer?.refId) return;
    dispatch(
      getMyOrders({
        take,
        skip,
        refId: user.customer.refId,
        fetchMore: true,
        filter,
      }),
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  };

  useEffect(() => {
    if (user?.customer) {
      dispatch(
        getMyOrders({
          refId: user.customer.refId,
          take: pagination.take,
          skip: pagination.skip,
          filter,
        }),
      );
    }
  }, [user, filter]);

  const [deleteProcess, deleteRequest] = useHttpRequest({
    onSuccess: () => {
      if (user?.customer?.refId) {
        dispatch(
          getMyOrders({
            refId: user.customer.refId,
            take: 10,
            skip: 0,
          }),
        );
        setOnDelete({ popUp: false, data: null });
      }
    },
    onError: () => {
      setOnDelete({ popUp: false, data: null });
    },
  });

  const deleteOrder = (orderData: any) => {
    deleteRequest({
      method: "DELETE",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${orderData?.id}/delete`,
    });
  };

  const renderFilters = () => {
    const filters: { label: string; value: OrdersFilter }[] = [
      { label: "Активные", value: "in_progress" },
      { label: "Завершенные", value: "completed" },
      { label: "Архивные", value: "archived" },
    ];

    return filters.map(({ label, value }, index) => {
      return (
        <div
          key={`filter_${index}`}
          onClick={() => setFilter(value as OrdersFilter)}
          className={`filterItem${value === filter ? " active" : ""}`}
        >
          {label}
        </div>
      );
    });
  };

  const renderOrders = () => {
    return myOrders.map((order: any, index: number) => {
      return (
        <WorkItem
          {...order}
          //index={index + 1}
          key={`order_${index}`}
          my
          categoryId={order.categoryId}
          onDelete={() => setOnDelete({ popUp: true, data: order })}
          customer
        />
      );
    });
  };

  useEffect(() => {
    dispatch(setOrdersFilter(filter));
  }, [filter]);

  // if (loading === "succeeded" && !myOrdersTotal) {
  //   return (
  //     <PageWrapper>
  //       <OrdersEmptyTag />
  //     </PageWrapper>
  //   );
  // }

  if ((loading === "pending" || loading === "idle") && !isFetchMore) {
    return (
      <PageWrapper
        flexDirection="column"
        noHeight
        minHeight="calc(100vh - 137px)"
        alignItems="center"
      >
        <Loading title="Загрузка заказов" />
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      flexDirection="column"
      noHeight
      noFooter
      // minHeight="calc(100vh - 65px)"
      alignItems="center"
    >
      <PageHeader backButton={false} width="974px">
        Мои заказы <OrdersFilter>{renderFilters()}</OrdersFilter>
      </PageHeader>

      <OrdersList>
        <div className="filterMobileContainer">
          <OrdersFilter>{renderFilters()}</OrdersFilter>
        </div>
        {myOrders?.length ? (
          renderOrders()
        ) : (
          <OrdersEmptyTag
            header={
              filter === "completed"
                ? "завершенных"
                : filter === "archived"
                ? "архивных"
                : "активных"
            }
          />
        )}
        {myOrdersTotal > pageSize && (
          <Pagination
            listLength={myOrdersTotal}
            onClick={handleFetchOrders}
            pageSize={pageSize}
          />
        )}
      </OrdersList>

      {onDelete.popUp && (
        <DeleteOrderPopUp
          loading={deleteProcess?.loading}
          close={() => setOnDelete({ popUp: false, data: null })}
          onSubmit={() => {
            deleteOrder(onDelete?.data);
          }}
          defaultValue={onDelete?.data}
        />
      )}
    </PageWrapper>
  );
};

export default MyOrdersPage;
