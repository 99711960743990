import React, { memo, useEffect, useState } from "react";
import {
  AddBtn,
  AddUserPart,
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  InputsTitle,
  InputsWrapper,
  InputWrapper,
  RegisterForm,
  RemoveUser,
  SelectOptionsWrapper,
  SelectWrapper,
} from "./styles";
import NumberFormat from "react-number-format";
import { ENDPOINT } from "../../../constants/general";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useNavigate, useParams } from "react-router-dom";
import useHttpRequest from "../../../hooks/useHttpRequest";

import PopUpContainer from "../popUpContainer";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { httpRequest } from "../../../utils/httpRequest";
import { ReactComponent as ProfileIcon } from "../../../assets/icons/icon-bottom-profile-active.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/icons-expand-more.svg";
import PhoneInput from "react-phone-number-input/input";
import { DatePicker } from "antd";
import moment from "moment";

import cities from "../../../constants/city.json";
import countries from "../../../constants/world.json";
import { CityList } from "../../../pages/profilePage/styles";
import { ErrorLine } from "../../../pages/userRegistration/styles";
import { getCompany } from "../../../redux/store/reducers/companySlice";

const roles = [
  {
    label: "Администратор",
    value: "admin",
  },
  {
    label: "Менеджер",
    value: "manager",
  },
  {
    label: "Прораб",
    value: "driver",
  },
];

const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  formData,
  labelSize,
  disabled,
  orgPage,
  onBlur,
}: any) => {
  const [selectOpen, setSelectOpen] = useState(false);

  const city = formData?.city;
  const country = formData?.country;
  const phoneNumber = formData?.phoneNumber;
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const dateFormatList = ["DD.MM.YYYY", "DD/MM/YYYY"];
  const setCity = (value: any) => {
    onChange({ target: { name: "city", value } });
  };
  const setCountry = (value: any) => {
    onChange({ target: { name: "country", value } });
  };
  const [options, setOptions] = useState<any>([]);
  const [countryOptions, setCountryOptions] = useState<any>(
    countries.filter(({ country_id }) => country_id),
  );

  const onChangeCity = (e: any) => {
    const c_id = country?.data?.country_id;

    setCity({ text: e.target.value, open: true });

    const exist = cities.find(
      ({ name, country_id }) =>
        country_id === c_id &&
        name.toLowerCase() === e.target.value.toLowerCase(),
    );

    if (exist) {
      setCity({ text: exist.name, open: false });
    } else {
      setOptions(
        cities.filter(
          ({ name, country_id }: any) =>
            country_id === c_id &&
            (e.target.value
              ? name.toLowerCase().includes(e.target.value.toLowerCase())
              : true),
        ),
      );
    }
  };

  const onChangeCountry = (e: any) => {
    setCountry({ text: e.target.value, open: true });
    const exist = countries.find(
      ({ name }) => name.toLowerCase() === e.target.value.toLowerCase(),
    );

    if (exist) {
      setCountry({ text: exist.name, open: false, data: exist });
    } else {
      setCountryOptions(
        countries.filter(
          ({ name, country_id }: any) =>
            country_id &&
            (e.target.value
              ? name.toLowerCase().includes(e.target.value.toLowerCase())
              : true),
        ),
      );
      setCity({ text: "", open: false });
    }
  };

  const onSelect = (e: any) => {
    setCity({ text: e, open: false });
  };

  const onSelectCountry = (e: any) => {
    setCountry({ text: e.name, open: false, data: e });
  };

  useEffect(() => {
    const c_id = country?.data?.country_id;
    if (c_id && type === "city") {
      const cityList = cities.filter(({ country_id }) => country_id === c_id);
      setOptions(cityList);
    }
  }, [country?.data?.country_id]);

  const active =
    type === "country" || type === "city"
      ? value?.text?.length
      : type !== "role" && value?.length;

  return (
    <InputWrapper
      active={active}
      role={type === "role"}
      style={{ width: width ? width : "100%" }}
      className={active ? "active" : ""}
    >
      {type === "role" ? (
        <SelectWrapper>
          <div
            className="selectGroup"
            onClick={() => setSelectOpen(!selectOpen)}
          >
            <ProfileIcon />
            <div>{value?.label || "Должность сотрудника"}</div>
          </div>

          <div className="arrowIcon" onClick={() => setSelectOpen(!selectOpen)}>
            <ArrowIcon />
          </div>
          {selectOpen && (
            <SelectOptionsWrapper>
              {roles.map(({ label, value }, index) => {
                return (
                  <div
                    key={index}
                    className="option"
                    onClick={() => {
                      onChange({
                        target: {
                          name,
                          value: { label, value },
                        },
                      });
                      setSelectOpen(false);
                    }}
                  >
                    {label}
                  </div>
                );
              })}
            </SelectOptionsWrapper>
          )}
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            value ? (
              <PhoneInput
                name={name}
                value={value || ""}
                className="phone-number-input"
                prefix={"+"}
                placeholder={placeholder}
                onChange={(e: any) => {
                  onChange({ target: { value: e, name } });
                }}
                autoFocus
                spellCheck={false}
              />
            ) : (
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                spellCheck={false}
              />
            )
          ) : type === "date" ? (
            <div className="date-input">
              <DatePicker
                value={value ? moment(value) : null}
                onChange={(e, date) => {
                  onChange({
                    target: {
                      value: e?.format("YYYY-MM-DD"),
                      name,
                    },
                  });
                }}
                disabled={disabled}
                format={dateFormatList}
                onBlur={() => onBlur && onBlur(name)}
              />
            </div>
          ) : type === "country" ? (
            <div tabIndex={0} style={{ border: 0, width: "100%" }}>
              <input
                className="name"
                type="text"
                value={country?.text || ""}
                onChange={onChangeCountry}
                onClick={() => {
                  if (!country?.open) {
                    setCountry({ ...country, open: true });
                    setCountryOptions(
                      countries.filter(
                        ({ name, country_id }: any) =>
                          country_id &&
                          (country?.text
                            ? name
                                .toLowerCase()
                                .includes(country?.text.toLowerCase())
                            : true),
                      ),
                    );
                  }
                }}
                onBlur={() => {
                  setCountry({ ...value, open: false });
                  onBlur && onBlur(country);
                }}
                onFocus={() => {
                  setCountry({ ...country, open: true });
                  setCountryOptions(
                    countries.filter(
                      ({ name, country_id }: any) =>
                        country_id &&
                        (country?.text ? name.includes(country?.text) : true),
                    ),
                  );
                }}
                disabled={disabled}
                spellCheck={false}
              />
              {value?.open && countryOptions.length > 0 && (
                <CityList>
                  {countryOptions.length > 0 &&
                    countryOptions.map(
                      (
                        { id, alpha2, alpha3, name, country_id }: any,
                        index: any,
                      ) => (
                        <div
                          key={`city${index}`}
                          className="item"
                          onMouseDown={(e) => {
                            onSelectCountry({
                              id,
                              alpha2,
                              alpha3,
                              name,
                              country_id,
                            });
                          }}
                        >
                          <b>{name}</b>
                        </div>
                      ),
                    )}
                </CityList>
              )}
            </div>
          ) : type === "city" ? (
            <div tabIndex={0} style={{ border: 0, width: "100%" }}>
              <input
                className="name"
                type="text"
                value={city?.text || ""}
                onChange={onChangeCity}
                disabled={!country?.data?.country_id}
                onClick={() => {
                  if (!city?.open) {
                    setCity({ ...city, open: true });
                    setOptions(
                      cities.filter(
                        ({ name, country_id }: any) =>
                          country_id === country?.data?.country_id &&
                          (city?.text
                            ? name
                                .toLowerCase()
                                .includes(city?.text.toLowerCase())
                            : true),
                      ),
                    );
                  }
                }}
                onBlur={() => {
                  setCity({ ...city, open: false });
                  onBlur && onBlur();
                }}
                onFocus={() => {
                  setCity({ ...city, open: true });
                  setOptions(
                    cities.filter(
                      ({ name, country_id }: any) =>
                        country_id === country?.data?.country_id &&
                        (city?.text
                          ? name
                              .toLowerCase()
                              .includes(city?.text.toLowerCase())
                          : true),
                    ),
                  );
                }}
                spellCheck={false}
                placeholder={`${label}${required ? "*" : ""}`}
              />
              {city?.open && options.length > 0 && (
                <CityList>
                  {options.length > 0 &&
                    options.map(
                      (
                        {
                          coords,
                          district,
                          name,
                          population,
                          subject,
                          region,
                          country,
                        }: any,
                        index: any,
                      ) => (
                        <div
                          key={`city${index}`}
                          className="item"
                          onMouseDown={(e) => {
                            onSelect(name);
                          }}
                        >
                          <b>{name},</b>
                          {region}
                        </div>
                      ),
                    )}
                </CityList>
              )}
            </div>
          ) : type === "email" ? (
            <>
              <input
                name={name}
                value={value || ""}
                type={type || "text"}
                onChange={onChange}
                onBlur={() => onBlur && onBlur()}
              />
              {value && !emailRegEx.test(value) ? (
                <ErrorLine>Неверный е-мейл</ErrorLine>
              ) : (
                <></>
              )}
            </>
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const AddManagerPopUp: React.FC<{ close?: any; onSuccess?: any }> = ({
  close,
  onSuccess,
}) => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>([{}]);
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [errorMsgStep2, setErrorMsgStep2] = useState<any>({
    fullNameEmpty: false,
    phoneNumberEmpty: false,
    emailPattern: false,
    emailEmpty: false,
  });
  const fireBaseData = useAppSelector((state) => state?.auth.fireBaseData);
  const refId = fireBaseData?.user?.refId;
  const dispatch = useAppDispatch();
  const { objId: objectId } = useParams();

  const changeFormDataStep2 = (e: any, index: number) => {
    const { name, value } = e.target;
    setFormData((prev: any) => [
      ...prev.slice(0, index),
      {
        ...prev[index],
        [name]: value,
      },
      ...prev.slice(index + 1, prev.length),
    ]);
  };

  const inputListStep2 = [
    {
      name: "companyRole",
      width: "300px",
      type: "role",
    },
    {
      label: "Фамилия",
      name: "secondName",
      required: true,
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "Имя",
      name: "firstName",
      required: true,
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "Отчество",
      name: "patronymic",
      required: false,
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "Телефон",
      name: "phone_number",
      width: "calc((100% - 12px) / 2)",
      required: true,
      type: "tel",
    },
    {
      label: "Электронная почта",
      name: "email",
      width: "calc((100% - 12px) / 2)",
      required: true,
      type: "email",
    },
    {
      name: "country",
      label: "Страна",
      type: "country",
      width: "calc((100% - 12px) / 2)",
      required: true,
    },
    {
      name: "city",
      label: "Город",
      type: "city",
      width: "calc((100% - 12px) / 2)",
      required: true,
    },
  ];

  const [addUserProcess, addUserRequest] = useHttpRequest({
    onSuccess: async (res: any) => {
      if (objectId !== "all") {
        for (const data of res?.data?.identifiers) {
          const { id: userId } = data;
          await httpRequest({
            method: "POST",
            url: `${ENDPOINT}/companies/${refId}/objects-managers/object/${objectId}/user/${userId}`,
          });
        }
      }
      close();
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setErrorMsg([
          "Один из пользователей уже является менеджером в другой компании, либо пользователь уже зарегистрирован",
        ]);
      }
    },
  });

  const addUserToCompany = () => {
    setErrorMsgStep2({});
    const emailRegEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const filterData = formData?.filter(
      (
        { email, secondName, firstName, note, phone_number, companyRole }: any,
        index: number,
      ) => {
        const objData = {
          fullNameEmpty: false,
          phoneNumberEmpty: false,
          emailPattern: false,
          emailEmpty: false,
        };
        if (!secondName && !firstName) {
          objData.fullNameEmpty = true;
        }
        if (!phone_number) {
          objData.phoneNumberEmpty = true;
        }
        if (!email) {
          objData.emailEmpty = true;
        } else if (!email?.toLowerCase()?.match(emailRegEx)) {
          objData.emailPattern = true;
        }
        setErrorMsgStep2((prev: any) => ({
          ...prev,
          [index]: objData,
        }));
        return (
          firstName &&
          secondName &&
          email?.toLowerCase()?.match(emailRegEx) &&
          phone_number &&
          isPossiblePhoneNumber(phone_number)
        );
      },
    );

    if (filterData?.length === formData?.length) {
      setErrorMsgStep2({});
      addUserRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${refId}/add-managers`,
        data: {
          users: formData?.map(
            ({
              email,
              firstName,
              secondName,
              patronymic,
              note,
              phone_number,
              companyRole,
              country,
              city,
            }: any) => ({
              firstName,
              secondName,
              patronymic,
              phoneNumber: phone_number,
              email,
              companyDescription: note,
              companyRole: companyRole?.value,
            }),
          ),
        },
      });
    }
  };

  useEffect(() => {
    if (refId) {
      dispatch(getCompany(refId));
    }
  }, [refId]);

  return (
    <PopUpContainer big title="Сведения о сотрудниках" close={close}>
      <RegisterForm>
        <FormTitle>Сведения о сотрудниках</FormTitle>
        <FormDesc>
          Добавьте сотрудников, чтобы отслеживать их работу в системе
        </FormDesc>
        {formData?.map((v: any, i: number) => (
          <>
            {i > 0 ? <InputsTitle>Сотрудник {i + 1}</InputsTitle> : ""}
            <InputsWrapper>
              {inputListStep2.map(
                ({ label, name, width, type, required }, index) => (
                  <>
                    <InputBox
                      label={label}
                      width={width}
                      name={name}
                      onChange={(e: any) => changeFormDataStep2(e, i)}
                      value={formData?.[i]?.[name]}
                      type={type}
                      required={required}
                      formData={formData?.[i]}
                    />
                    {i > 0 && index === 0 ? (
                      <RemoveUser
                        onClick={() => {
                          setFormData((prev: any) =>
                            prev?.filter((v: any, ind: number) => i !== ind),
                          );
                        }}
                      >
                        Удалить сотрудника
                      </RemoveUser>
                    ) : (
                      ""
                    )}
                  </>
                ),
              )}
              <ErrorsWrapper>
                {errorMsgStep2?.[i]?.fullNameEmpty && (
                  <ErrorMsg>Укажите полное имя.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.phoneNumberEmpty && (
                  <ErrorMsg>Укажите номер телефона.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.emailEmpty && (
                  <ErrorMsg>Укажите адрес электронной почты.</ErrorMsg>
                )}
                {errorMsgStep2?.[i]?.emailPattern && (
                  <ErrorMsg>Электронная почта недействительна.</ErrorMsg>
                )}
              </ErrorsWrapper>
            </InputsWrapper>
          </>
        ))}

        {errorMsg.length > 0 ? (
          <ErrorsWrapper>
            {errorMsg.map((msg: string) => (
              <ErrorMsg>{msg}</ErrorMsg>
            ))}
          </ErrorsWrapper>
        ) : (
          ""
        )}

        <AddUserPart>
          У вас есть еще сотрудники?{" "}
          <u
            onClick={() => {
              setFormData((prev: any) => [...prev, {}]);
            }}
          >
            Добавьте еще одного
          </u>
        </AddUserPart>
        <AddBtn
          onClick={() => {
            addUserToCompany();
          }}
          fit
          disabled={
            formData?.filter(
              (
                {
                  email,
                  secondName,
                  firstName,
                  patronymic,
                  note,
                  phone_number,
                  city,
                  country,
                  companyRole,
                }: any,
                index: number,
              ) => {
                return (
                  secondName &&
                  firstName &&
                  city?.text?.length &&
                  country?.text?.length &&
                  email?.toLowerCase()?.match(emailRegEx) &&
                  phone_number &&
                  isPossiblePhoneNumber(phone_number) &&
                  companyRole
                );
              },
            ).length !== formData?.length
          }
        >
          Добавить
        </AddBtn>
      </RegisterForm>
    </PopUpContainer>
  );
};

export default memo(AddManagerPopUp);
