import styled from "styled-components";
import mapImg from "../../assets/images/map.png";
import { styles } from "../../components/styles/styles";

import checkIcon from "../../assets/icons/icons-check.svg";
import { SmallButton } from "../../components/form/styles";

const greenColor = "#21c17a";

export const CreateOrderWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  position: relative;
`;

export const CloseFilter = styled.div`
  margin: 0 0 0 auto;
  padding: 16px 16px 0 0;
  width: fit-content;
  display: none;

  @media screen and (max-width: 766px) {
    display: block;
  }

  svg {
    display: flex;
  }
`;

export const FilterBtn = styled(SmallButton)`
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: 121px;
  margin: 0;
  padding: 6px 15px;
  display: none;
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  align-items: center;
  z-index: 45;
  height: 40px;

  svg {
    margin-right: 6px;
  }

  path {
    stroke: white;
  }

  @media screen and (max-width: 766px) {
    display: flex;
  }
`;

export const YandexMapWrapper = styled.div`
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreateOrderIntro = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #ffffff 34.86%,
    rgba(255, 255, 255, 0.0001) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 80px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 576px) {
    padding: 30px;
  }
`;

export const IntroContainer = styled.div`
  width: 450px;
  height: fit-content;

  .title {
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  }

  .desc {
    margin: 40px 0 36px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  button {
    width: 328px;
    padding: 18px 21px;
    margin: 0 auto 0 0;
  }

  @media only screen and (max-width: 400px) {
    button {
      width: fit-content;
      padding: 10px 24px;
    }
  }
`;

export const CreateOrderBox = styled.div`
  width: fit-content;
  height: var(--vh100);
  position: absolute;
  display: flex;
  gap: 24px;
  /* top: 0; */
  right: 0;
  bottom: 0;
  left: 0;
  padding: 30px;
  transition: all 0.3s linear;

  @media only screen and (max-width: 576px) {
    height: 70%;
    top: 25%;
  }

  @media only screen and (max-width: 400px) {
    padding: 0;
  }
`;

export const CreateOrderFormBox = styled.div`
  width: 388px;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
  background-color: #fdfdfd;
  box-shadow: 0 2px 45px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  transition: all 0.3s linear;
  //scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  button {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 19px;
  }

  .text {
    opacity: 0.5;

    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #222222;
  }

  .formBottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    padding: 8px 16px 16px 16px;
  }

  @media only screen and (max-width: 768px) {
    top: auto;
    position: fixed;
    bottom: ${({ isVisible }: { isVisible?: boolean }) =>
      isVisible ? "0vh" : "-200vh"};
    transition-duration: 0.5s;
    left: 0;
    right: 0;
    margin: auto;
    height: fit-content;
    max-height: var(--vh100);
    z-index: 4;
  }

  @media only screen and (max-width: 500px) {
    width: 100%;
    top: auto;
  }
`;

export const Dropdown = styled.div``;

export const DropdownTitle = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;

  .text {
    font-size: 14px;
    opacity: 1;
  }

  .arrow {
    display: flex;
    width: 24px;
    height: 24px;
    transform: rotate(${({ open }) => (open ? "90deg" : "0deg")});
    transition-duration: 0.5s;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;

export const DropdownBodyWrapper = styled.div<{
  open?: boolean;
  height?: number;
  shift?: number;
  payment?: boolean;
}>`
  ${({ open }) => (!open ? "max-height: 0" : "max-height: 350px")};
  transition: all 0.5s linear;
  overflow: auto;
`;

export const DropdownBody = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DropdownItem = styled.div<{ active?: boolean }>`
  width: 100%;
  display: flex;
  padding: 8px;
  height: 48px;
  align-items: center;
  cursor: pointer;
  /* margin-bottom: 15px; */
  border: 1px solid
    /* ${({ active }) => (active ? greenColor : "rgba(0, 0, 0, 0.1)")}; */
    #f7f7f7;
  border-radius: 10px;
  cursor: pointer;
  /* background-color: transparent; */
  background-color: #f7f7f7;
  transition: all 0.25s linear;

  &:hover {
    background: #e8e8e8;
  }

  .icon {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;

    svg {
      display: flex;
      height: 24px;
      width: 24px;

      g {
        ${({ active }) => (active ? "fill:black;" : "")}
      }
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .active.text {
    opacity: 1;
  }

  .text {
    width: calc(100% - 76px);

    .title {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
    }

    .subtitle {
      opacity: 0.5;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: var(--color-black);
    }
  }

  .right-icon {
    display: flex;
    margin-left: 12px;
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: fit-content;
  max-height: calc(100vh - 276px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 16px 16px 0 16px;
`;

export const BarBoxWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  gap: 8px;
`;

export const NewAddressBox = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  color: #222;
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`;

export const SettingsButton = styled.div<{ active?: Boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  /* ${({ active }) => (active ? greenColor : "rgba(0, 0, 0, 0.1)")}; */
  border-radius: 10px;
  cursor: pointer;
  background-color: #f7f7f7;
  position: relative;
  transition: all 0.25s linear;
  animation: width-animation 0.25s linear;

  &:hover {
    background: #e8e8e8;
  }
`;

export const ExplanationMark = styled.div`
  position: absolute;
  top: -6px;
  right: -5px;
  background-color: ${styles.mainColor};
  width: 21px;
  height: 20px;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 9px;
  transition: all 0.25s linear;
`;

export const BarBox = styled.div<{ active?: boolean; vehicle?: boolean }>`
  width: ${({ vehicle }: { vehicle?: boolean }) =>
    vehicle ? "calc(100% - 50px - 8px)" : "100%"};
  display: flex;
  padding: 12px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* ${({ active }: { active?: boolean }) =>
    active ? greenColor : "rgba(0, 0, 0, 0.1)"}; */
  border-radius: 10px;
  cursor: pointer;
  /* background-color: white; */
  background-color: #ffffff;
  transition: all 0.25s linear;

  &:hover {
    border: 1px solid #222222;
  }

  .icon {
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    font-size: 24px;
    line-height: 24px;

    svg {
      display: flex;
      height: 24px;
      width: 24px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    width: calc(100% - 76px);
  }

  .right-icon {
    display: flex;
    margin-left: 12px;
  }

  @media screen and (max-width: 576px) {
    padding: 9px;
  }
`;

export const BarBoxAddress = styled(BarBox)`
  padding: 0;
  transition: all 0.25s linear;

  .icon {
    padding: 14px 0 14px 14px;
    box-sizing: content-box;
  }

  .name {
    padding: 14px 0;
  }

  .right-icon {
    height: fit-content;
    padding: 12px;
    margin: 2px 2px 2px 0;
    border-radius: 50%;
    background-color: ${styles.mainColor}00;
    display: flex;
    transition: all 0.25s linear;

    svg {
      display: flex;
    }

    &:hover {
      background-color: ${styles.mainColor}c0;
    }
  }

  @media screen and (max-width: 576px) {
    padding: 0px;
  }
`;

export const SmallBarWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 7px;
`;

export const SmallBar = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  /* ${({ active }: { active?: boolean }) =>
    active ? greenColor : "rgba(0,0,0,0.1)"}; */
  border-radius: 10px;
  margin: 0 8px 8px 0;
  cursor: pointer;

  .icon {
    margin-right: 8px;
    display: flex;
  }
`;

export const CounterBox = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  border-radius: 10px;

  button {
    margin: 0;
    /* background-color: ${styles.mainColor}; */
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    padding: 8px;
    border-radius: 10px;
    color: white;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid #00000019;
    background-color: #ffffff;
    transition: all 0.3s linear;

    svg,
    g {
      width: 24px;
      height: 24px;
      fill: black;
    }

    &:hover {
      border: 1px solid #757575;
    }
  }

  .indicator {
    width: 32px;
    height: 32px;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  gap: 10px 15px;
  flex-wrap: wrap;
  margin-bottom: 8px;
  margin-top: 8px;
  padding: 0 12px;
`;

export const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  user-select: none;

  input {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    appearance: none;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      border: 1px solid #979797;
      border-radius: 8px;
    }

    &:checked {
      background-image: url(${checkIcon});
    }
  }
`;

export const InputWrapper = styled.label<{ active?: boolean; error?: boolean }>`
  width: 100%;
  height: 48px;
  background-color: #f7f7f7;
  padding: 0 8px;
  border-radius: 10px;
  border: 2px solid ${({ error }) => (error ? "#ff6633" : "#f7f7f7")};
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  transition: all 0.25s linear;

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    @media screen and (max-width: 590px) {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 0.5 : 0.5)};
      white-space: nowrap;
    }
  }

  :not(.date-input) input {
    width: 100%;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: #222222;
    transition-duration: 0.5s;
    overflow: hidden;

    &[disabled] {
      -webkit-text-fill-color: #222222;
      opacity: 1;
      color: #222222;
    }
  }

  .ant-picker {
    padding: 0;
    height: 100%;
    width: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;

      &[disabled],
      fieldset[disabled] {
        -webkit-text-fill-color: #222222;
        opacity: 1;
        color: #222222;
      }
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within {
    border: 1px solid #757575;
    background: #fdfdfd;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 17px;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;

        &[disabled],
        fieldset[disabled] {
          -webkit-text-fill-color: #222222;
          opacity: 1;
          color: #222222;
        }
      }

      input[disabled] {
        color: #222222 !important;
        opacity: 1 !important;
      }
    }
  }

  &:hover {
    background: #e8e8e8;
  }
`;

export const IconPart = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;

  svg {
    width: 100%;
    height: 100%;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  line-height: 24px;

  svg {
    display: flex;
    height: 24px;
    width: 24px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export const InputPart = styled.div`
  width: calc(100% - 76px);
  position: relative;
`;

export const InputOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const ArrowRightPart = styled.div<{ address?: Boolean }>`
  width: 24px;
  height: 24px;
  margin-left: 12px;
  border-radius: 50%;
  transition-duration: 0.5s;
  ${({ address }) =>
    address &&
    `
  cursor:pointer;
  &:hover {
    background-color: ${styles?.mainColor};
    box-shadow: 0 0 0 10px ${styles?.mainColor};
  }`}
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;
