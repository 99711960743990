import { CompanyAccessSettings } from "../types";

export const makeValidAccessSettings = (
  accessSettings: CompanyAccessSettings,
  sample: CompanyAccessSettings,
): CompanyAccessSettings => {
  let validSettings = accessSettings;
  let keys = Object.keys(validSettings);
  const sampleKeys = Object.keys(sample);
  for (let key of keys) {
    if (!sampleKeys.includes(key)) {
      delete validSettings[key as keyof typeof validSettings];
      keys = Object.keys(validSettings);
    }
  }

  for (let key of sampleKeys) {
    if (!keys.includes(key)) {
      validSettings[key as keyof CompanyAccessSettings] = false;
    }
  }
  return validSettings;
};
