import React from "react";
import { ButtonWrapper } from "./styles";

interface IButtonProps {
  onClick?: any;
  width?: string;
  disabled?: boolean;
  outlined?: boolean;
  height?: string;
  fontWeight?: "normal" | "bolder" | "bold" | "400" | "500" | "800";
  fontSize?: string;
  letterSpacing?: string;
  background?: string;
  border?: string;
  variant?: "outlined" | "primary" | "secondary" | "small";
  children: React.ReactNode | string;
  borderRadius?: string;
  gap?: string;
  shadowed?: boolean;
  onDisabledAction?: any;
  marginTop?: string;
  id?: string;
  ref?: any;
  fontColor?: string;
  maxWidth?: string;
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  width,
  disabled,
  outlined,
  height,
  fontSize,
  fontWeight,
  letterSpacing,
  border,
  children,
  variant,
  borderRadius,
  gap,
  shadowed,
  onDisabledAction,
  marginTop,
  id,
  ref,
  fontColor,
  background,
  maxWidth,
}) => {
  return (
    <ButtonWrapper
      ref={ref}
      id={id}
      width={width}
      onClick={(e) =>
        disabled
          ? onDisabledAction && onDisabledAction()
          : onClick && onClick(e)
      }
      disabled={disabled}
      outlined={outlined}
      height={height}
      fontWeight={fontWeight}
      fontSize={fontSize}
      letterSpacing={letterSpacing}
      border={border}
      variant={variant}
      borderRadius={borderRadius}
      gap={gap}
      shadowed={shadowed}
      marginTop={marginTop}
      background={background}
      fontColor={fontColor}
      maxWidth={maxWidth}
    >
      {children}
    </ButtonWrapper>
  );
};

export default Button;
