import React from "react";
import { ReactComponent as EngineeringIcon } from "../../assets/icons/engineering.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search-2.svg";
import "particles.js";
const particlesJS = (window as any).particlesJS;

interface Props {
  children: JSX.Element;
  showIcon?: boolean;
}

export function ObjectDetectionImageWrapper({
  children,
  showRecognition = true,
}: {
  children: JSX.Element;
  showRecognition?: boolean;
}) {
  const url = children.props.src;
  const preloaderRef = React.createRef<HTMLDivElement>();
  const [objects, setObjects] = React.useState<ObjectDetectionResult[]>([]);
  const [selectedFrame, setSelectedFrame] = React.useState<number | null>();

  const imageRef = React.useRef<HTMLImageElement>();

  const framesRefs = React.useRef<HTMLDivElement[]>([]);

  const [loadingState, setLoadingState] = React.useState<
    "" | "loading" | "loaded"
  >("");

  function registerFrame(frame: HTMLDivElement) {
    if (framesRefs.current.includes(frame)) {
      framesRefs.current.push(frame);
    }
  }

  function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [imageDelta, setImageDelta] = React.useState<{
    x: number;
    y: number;
  }>({ x: 1, y: 1 });

  function recalcImageDelta() {
    if (imageRef.current) {
      setImageDelta({
        x: imageRef.current.clientWidth / imageRef.current.naturalWidth,
        y: imageRef.current.clientHeight / imageRef.current.naturalHeight,
      });
    }
  }
  React.useEffect(() => {
    window.addEventListener("resize", recalcImageDelta);
  }, []);
  React.useEffect(() => {
    recalcImageDelta();
  }, [imageRef.current]);

  async function getObjects() {
    if (objects.length < 1) {
      setLoadingState("loading");
      particlesJS.load("particles", "../particles.json", function () {
        console.log("callback - particles-js config loaded");
      });
      const response = await fetch(`${url}?objectdetection=1`).then((r) =>
        r.json(),
      );
      console.log({ response });
      setLoadingState("loaded");
      setObjects(response);
      recalcImageDelta();
    }
  }

  return (
    <div
      style={{
        position: "relative",
        width: "fit-content",
      }}
    >
      {showRecognition && (
        <div
          onClick={() => {
            getObjects();
          }}
          style={{
            position: "absolute",
            right: "10px",
            bottom: "10px",
            width: "40px",
            height: "40px",
            border: "3px solid",
            display: loadingState == "" ? "flex" : "none",
            justifyContent: "center",
            alignContent: "center",
            borderColor: loadingState == "" ? "black" : "white",
            borderRadius: "100%",
            cursor: "pointer",
            background: "white",
          }}
        >
          <div
            style={{
              display: "block",
              position: "relative",
              width: "24px",
              height: "24px",
              margin: "auto",
            }}
          >
            <EngineeringIcon />
          </div>
        </div>
      )}

      {React.cloneElement(children, { ref: imageRef })}
      <div
        ref={preloaderRef}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          background: "rgba(0,0,0,0.8)",
          display: loadingState == "loading" ? "block" : "none",
        }}
      >
        {loadingState == "loading" ? (
          <div
            id="particles"
            style={{
              width: "100%",
              height: "100%",
            }}
          ></div>
        ) : null}
      </div>
      {objects.map((object, index) => {
        return (
          <div
            ref={registerFrame}
            onMouseEnter={(event) => {
              setSelectedFrame(index);
            }}
            onMouseLeave={() => {
              setSelectedFrame(null);
            }}
            key={JSON.stringify(object)}
            style={{
              position: "absolute",
              cursor: "crosshair",
              display:
                selectedFrame == null
                  ? "block"
                  : selectedFrame == index
                  ? "block"
                  : "none",
              top: `${object.box.ymin * imageDelta.y}px`,
              left: `${object.box.xmin * imageDelta.x}px`,
              height: `${(object.box.ymax - object.box.ymin) * imageDelta.y}px`,
              width: `${(object.box.xmax - object.box.xmin) * imageDelta.x}px`,
              border: "solid 2px #e74c3c",
            }}
          >
            <p
              style={{
                position: "absolute",
                top: "-33px",
                width: "100%",
                display: "flex",
              }}
            >
              <p
                style={{
                  background: "rgba(255,255,255,0.5)",
                  padding: "5px",
                  borderRadius: "20px",
                  margin: "auto",
                  fontWeight: "bold",
                }}
              >
                {TranslateLabel(object.label)}
              </p>
            </p>
          </div>
        );
      })}
    </div>
  );
}

export function TranslateLabel(labelText: string): string {
  switch (labelText) {
    case "person":
      return "Человек";
    case "truck":
      return "Техника";
    case "car":
      return "Машина";
    case "train":
      return "Поезд";
    default:
      return labelText;
  }
}

export type ImageObjectDetectionLabel =
  | "N/A"
  | "person"
  | "traffic light"
  | "fire hydrant"
  | "street sign"
  | "stop sign"
  | "parking meter"
  | "bench"
  | "bird"
  | "cat"
  | "dog"
  | "horse"
  | "bicycle"
  | "sheep"
  | "cow"
  | "elephant"
  | "bear"
  | "zebra"
  | "giraffe"
  | "hat"
  | "backpack"
  | "umbrella"
  | "shoe"
  | "car"
  | "eye glasses"
  | "handbag"
  | "tie"
  | "suitcase"
  | "frisbee"
  | "skis"
  | "snowboard"
  | "sports ball"
  | "kite"
  | "baseball bat"
  | "motorcycle"
  | "baseball glove"
  | "skateboard"
  | "surfboard"
  | "tennis racket"
  | "bottle"
  | "plate"
  | "wine glass"
  | "cup"
  | "fork"
  | "knife"
  | "airplane"
  | "spoon"
  | "bowl"
  | "banana"
  | "apple"
  | "sandwich"
  | "orange"
  | "broccoli"
  | "carrot"
  | "hot dog"
  | "pizza"
  | "bus"
  | "donut"
  | "cake"
  | "chair"
  | "couch"
  | "potted plant"
  | "bed"
  | "mirror"
  | "dining table"
  | "window"
  | "desk"
  | "train"
  | "toilet"
  | "door"
  | "tv"
  | "laptop"
  | "mouse"
  | "remote"
  | "keyboard"
  | "cell phone"
  | "microwave"
  | "oven"
  | "truck"
  | "toaster"
  | "sink"
  | "refrigerator"
  | "blender"
  | "book"
  | "clock"
  | "vase"
  | "scissors"
  | "teddy bear"
  | "hair drier"
  | "boat"
  | "toothbrush";

export interface ObjectDetectionResult {
  score: number;
  label: ImageObjectDetectionLabel;
  box: {
    xmin: number;
    ymin: number;
    xmax: number;
    ymax: number;
  };
}

