import styled from "styled-components";

export const SelectMainWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : "100%")};
  position: relative;
  height: 48px;
`;

export const SelectWrapper = styled.button<{ open: boolean }>`
  width: 100%;
  padding: 12px 8px;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  background-color: white;
  outline: 0;
  cursor: pointer;

  svg {
    display: flex;
    transition: all 0.3s linear;
  }

  .arrowIcon {
    ${({ open }) => open && "transform: rotate(180deg)"};
  }
`;

export const SelectIconPart = styled.div``;

export const SelectText = styled.input`
  margin: 0 0px 0 8px;
  font-weight: normal !important;
  border: none;
  outline: none;
  appearance: none;
  width: 70%;

  &:read-only {
    cursor: pointer;
  }
`;

export const SelectDropdown = styled.div`
  margin-left: auto;
`;

export const SelectListWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  max-height: 190px;
  overflow: auto;
  border-radius: 0 0 20px 20px;
  border: solid 1px #e8e8e8;
  background-color: white;
  z-index: 999;
  /* display: flex; */
  /* flex-direction: column; */
  /* gap: 16px; */
  padding: 11px 0px 16px;
`;

export const SelectListItem = styled.div`
  /* width: fit-content; */
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  display: flex;
  cursor: pointer;
  padding: 8px 16px;
  transition: all 0.3s linear;
  .dash {
    margin-right: 16px;
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const SelectListItemVehicle = styled.div`
  display: flex;
  cursor: pointer;
  padding: 8px 16px;
  gap: 8px;
  align-items: center;
  transition: all 0.3s linear;

  .centerItem {
    width: 100%;
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }

  .vehicleInfo {
    display: flex;
    flex-direction: column;

    .vehicleName {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
    }

    .vehicleNumber {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: #757575;
    }
  }
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
