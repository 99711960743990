import styled from "styled-components";
import { Text16 } from "../../components/styles/styles";

export const SwitchToAgentWrapper = styled.div`
  width: 100vw;
  height: var(--vh100);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const SwitchToAgentBackground = styled.div`
  width: 100vw;
  height: var(--vh100);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000055;
`;
export const SwitchToAgentBox = styled.div`
  width: 700px;
  height: fit-content;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: white;
  padding: 32px 24px 24px;
  max-height: 500px;
  button {
    margin-top: 20px;
  }
  @media only screen and (max-height: 482px) {
    height: var(--vh100);
  }
  @media only screen and (max-width: 700px) {
    width: 100vw;
  }

  @media only screen and (max-width: 576px) {
    padding: 16px 12px 12px;
    button {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      border-radius: 0;
    }
  }
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 23px;
`;

export const Title = styled(Text16)``;

export const CloseBox = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export const DescriptionBox = styled.div`
  /* max-height: calc(100% - 135px); */
  max-height: 300px;
  overflow: auto;
  @media only screen and (max-width: 576px) {
    max-height: 500px;
    height: calc(100vh - 130px);
  }
`;
