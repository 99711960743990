import React, { memo } from "react";
import { Button } from "../../components/form/styles";
import {
  CloseBox,
  DescriptionBox,
  SwitchToAgentBackground,
  SwitchToAgentBox,
  SwitchToAgentWrapper,
  Title,
  TitleBox,
} from "./styles";

const SwitchToAgent: React.FC<any> = ({ closeAgentMode }) => {
  return (
    <SwitchToAgentWrapper>
      <SwitchToAgentBackground onClick={closeAgentMode} />
      <SwitchToAgentBox>
        <TitleBox>
          <Title>Примите условия сервиса</Title>
          <CloseBox onClick={closeAgentMode}>Закрыть</CloseBox>
        </TitleBox>
        <DescriptionBox>
          Противоположная точка зрения подразумевает, что базовые сценарии
          поведения пользователей и по сей день остаются уделом либералов,
          которые жаждут быть рассмотрены исключительно в разрезе маркетинговых
          и финансовых предпосылок. Как уже неоднократно упомянуто,
          непосредственные участники технического прогресса смешаны с не
          уникальными данными до степени совершенной неузнаваемости, из-за чего
          возрастает их статус бесполезности.
          <br /> <br />
          Явные признаки победы институционализации, превозмогая сложившуюся
          непростую экономическую ситуацию, превращены в посмешище, хотя само их
          существование приносит несомненную пользу обществу. Принимая во
          внимание показатели успешности, социально-экономическое развитие
          требует определения и уточнения приоритизации разума над эмоциями. Для
          современного мира высокотехнологичная концепция общественного уклада
          требует определения и уточнения благоприятных перспектив. Имеется
          спорная точка зрения, гласящая примерно следующее: явные признаки
          победы институционализации, превозмогая сложившуюся непростую
          экономическую ситуацию, представлены в исключительно положительном
          свете. Значимость этих проблем настолько очевидна, что сложившаяся
          структура организации играет определяющее значение для своевременного
          выполнения сверхзадачи.
          <br /> <br />
          Но сложившаяся структура организации, а также свежий взгляд на
          привычные вещи - безусловно открывает новые горизонты для позиций,
          занимаемых участниками в отношении поставленных задач. Также как
          повышение уровня гражданского сознания является качественно новой
          ступенью первоочередных требований.
        </DescriptionBox>
        <Button onClick={closeAgentMode}>Принимаю условия использования</Button>
      </SwitchToAgentBox>
    </SwitchToAgentWrapper>
  );
};

export default memo(SwitchToAgent);
