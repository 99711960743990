import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HeaderPart,
  LocationPart,
  OrderItemDescriptionPart,
  OrderItemMapPart,
  OrderItemWrapper,
  PricePart,
} from "./styles";
import { createSearchParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { thousandSeparator } from "../../utils/numbers";
import { ReactComponent as PositionMarker } from "../../assets/icons/icon-position-icon-bold.svg";
import ProposalPopUp from "../popUp/ProposalPopUp";
import { checkUserRegistered } from "../../utils/checkUserRegistered";
import OrderLocationPlaceholder from "../../assets/images/orderLocationPlaceholder.png";
import { ReactComponent as MobileMenuIcon } from "../../assets/icons/icons-more-vert.svg";
import {
  fetchCancelOffer,
  fetchSendRespond,
  getMyOrders,
} from "../../redux/store/reducers/orderSlice";
import {
  setPageToRedirect,
  setWorkerRespond,
} from "../../redux/store/reducers/registrationSlice";
import Button from "../../UI/Button";
import { Dropdown, MenuProps } from "antd";

const WorkItem: React.FC<any> = ({
  id,
  title,
  startDate,
  address,
  createdAt,
  city,
  budget,
  workerId,
  my,
  categoryId,
  latitude,
  longitude,
  offers,
  handleFetchOrders,
  navbarItem,
  paymentType,
  handleSearchOrders,
  setOrdersListOpen,
  setWorkDetail,
  customer,
  onDelete,
  pagination,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );
  const user = useAppSelector((state) => state.auth.user);

  const ref = useRef<any>(null);
  const [numWidth, setNumWidth] = useState<number>(0);
  const [proposalPopUp, setProposalPopUp] = useState<boolean>(false);

  const duplicate = () => {
    const params = {
      title,
      startDate,
      address,
      budget,
      paymentType,
      categoryId,
      lat: latitude,
      lng: longitude,
    };

    navigate({
      pathname: `/create-order`,
      search: `?${createSearchParams(params)}`,
    });
  };

  const items: MenuProps["items"] = [
    {
      key: `order_${id}_menu_item_1`,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            duplicate();
          }}
        >
          Дублировать
        </div>
      ),
    },
    {
      key: `order_${id}_menu_item_2`,
      label: (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onDelete();
          }}
        >
          Удалить
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (ref?.current?.clientWidth) {
      setNumWidth(ref?.current?.clientWidth);
    }
  }, [ref?.current?.clientWidth]);

  function getOffersCountQuantityWord(offersCount: any): string {
    if (offersCount === 1) {
      return " предложение";
    } else if (offersCount > 1 && offersCount < 5) {
      return " предложения";
    } else {
      return " предложений";
    }
  }

  const cancelRespondHandler = async () => {
    !!offers.length &&
      user?.customer?.refId &&
      (await dispatch(
        fetchCancelOffer({
          orderId: id,
          refId: user?.customer?.refId,
          offerId: offers.find(
            (offer: any) => offer?.workerId === user?.customer?.id,
          )?.id,
        }),
      ));
    pagination && handleSearchOrders(pagination.take, pagination.skip);
    setOrdersListOpen && setOrdersListOpen(false);
    setWorkDetail && setWorkDetail(null);
    setProposalPopUp(false);
    handleFetchOrders();
  };

  const fetchRespondHandler = useCallback(async (data: any) => {
    if (user?.customer && checkUserRegistered(user) && id) {
      dispatch(
        fetchSendRespond({
          ...data,
          orderId: id,
          refId: user?.customer?.refId,
        }),
      ).then(() => {
        dispatch(
          getMyOrders({
            refId: user?.customer?.refId,
            take: 10,
            skip: 0,
          }),
        );
        handleSearchOrders(pagination.take, pagination.skip);
        setOrdersListOpen && setOrdersListOpen(false);
        setWorkDetail && setWorkDetail(null);
      });
    } else {
      if (!checkUserRegistered(user)) {
        dispatch(setPageToRedirect(`/o/${id}`));
        dispatch(
          setWorkerRespond({
            ...data,
            orderId: id,
            refId: user?.customer?.refId,
          }),
        );
        navigate("/registration");
      }
    }
  }, []);

  return (
    <>
      <OrderItemWrapper
        className={"orderItemWrapper"}
        onClick={() => navigate(`/o/${id}`)}
        navbarItem={navbarItem}
      >
        <OrderItemMapPart navbarItem={navbarItem}>
          <div className="imageContainer">
            <PositionMarker />
          </div>
          <div className="imageContainer">
            {vehicleCategories?.find(
              (vehicle: any) => vehicle.id === categoryId,
            )?.icon ? (
              <img
                className="vehicleIcon"
                src={`https://dev.rukki.pro/${
                  vehicleCategories?.find(
                    (vehicle: any) => vehicle.id === categoryId,
                  )?.icon
                }`}
                width={"24px"}
                height={"24px"}
                alt=""
              />
            ) : (
              <img
                className="vehicleIcon"
                src={`https://dev.rukki.pro/assets/images/excavator.png`}
                width={"24px"}
                height={"24px"}
                alt=""
              />
            )}
          </div>
          <img
            className="mapImage"
            src={OrderLocationPlaceholder}
            alt=""
            width={"100%"}
            height={"100%"}
          />
        </OrderItemMapPart>

        <OrderItemDescriptionPart navbarItem={navbarItem}>
          <HeaderPart navbarItem={navbarItem}>
            <div className="columnGroup">
              <div className={"title"} onClick={() => navigate(`/o/${id}`)}>
                {vehicleCategories?.find(
                  (vehicle: any) => vehicle.id === categoryId,
                )?.icon ? (
                  <img
                    src={`https://dev.rukki.pro/${
                      vehicleCategories?.find(
                        (vehicle: any) => vehicle.id === categoryId,
                      )?.icon
                    }`}
                    width={"24px"}
                    height={"24px"}
                    alt=""
                  />
                ) : (
                  <img
                    src={`https://dev.rukki.pro/assets/images/excavator.png`}
                    width={"24px"}
                    height={"24px"}
                    alt=""
                  />
                )}
                <span className={"categoryTitle"}>{title}</span>
              </div>
              <div className={"titleDescription"}>
                Создан {createdAt ? moment(createdAt).format("ll") : ""}
                <span className={"offersCount"}>
                  {offers?.length ? offers.length : 0}
                </span>
                {getOffersCountQuantityWord(offers?.length)}
              </div>
            </div>
            {customer ? (
              <div className="columnGroup" onClick={(e) => e.stopPropagation()}>
                <Dropdown menu={{ items }} placement="bottomRight">
                  <MobileMenuIcon />
                </Dropdown>
              </div>
            ) : null}
          </HeaderPart>
          <LocationPart>
            <div className={"title"}>
              {address ? (city ? city + ", " + address : address) : city}
              {/* {`${city && city + ", "}`}
              {address} */}
            </div>
            <div className={"titleDescription"}>
              {startDate
                ? `Выполнить ${moment(startDate).format("ll")}`
                : "Время будет обсуждаться"}
            </div>
          </LocationPart>
          <PricePart
            responsed={user?.customer?.refId !== workerId}
            navbarItem={navbarItem}
          >
            <div className={"price"}>
              {thousandSeparator(budget)
                ? `${thousandSeparator(budget)} ₽`
                : "Бюджет обсуждается"}
            </div>
            <div
              className={"responseButWrapper"}
              onClick={(e) => e.stopPropagation()}
            >
              {user?.customer.role === "worker" && (
                <>
                  {offers?.find(
                    (offer: any) => offer?.workerId === user?.customer?.id,
                  ) && (
                    <>
                      {offers?.find(
                        (offer: any) => offer?.workerId === user?.customer?.id,
                      ).status === "Sent" && (
                        <div
                          className={"cancelButton"}
                          onClick={(e) => {
                            e.stopPropagation();
                            cancelRespondHandler();
                          }}
                        >
                          Отозвать отклик
                        </div>
                      )}

                      <Button
                        height="32px"
                        fontWeight="400"
                        fontSize="15px"
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                        ) => {
                          e.stopPropagation();
                          navigate(`/o/${id}`);
                        }}
                      >
                        Посмотреть заказ
                      </Button>
                    </>
                  )}
                  {!offers?.find(
                    (offer: any) => offer?.workerId === user?.customer?.id,
                  ) && (
                    <>
                      <div
                        className={"cancelButton"}
                        onClick={(e) => {
                          e.stopPropagation();
                          cancelRespondHandler();
                        }}
                      >
                        Посмотреть заказ
                      </div>
                      <Button
                        height="32px"
                        fontWeight="400"
                        fontSize="15px"
                        onClick={(
                          e: React.MouseEvent<HTMLDivElement, MouseEvent>,
                        ) => {
                          e.stopPropagation();
                          offers?.find(
                            (offer: any) =>
                              offer?.workerId === user?.customer?.id,
                          )
                            ? navigate(`/o/${id}`)
                            : my
                            ? navigate(`/o/${id}`)
                            : user?.customer?.refId !== workerId &&
                              !offers?.find(
                                (offer: any) =>
                                  offer?.workerId === user?.customer?.id,
                              ) &&
                              setProposalPopUp(true);
                        }}
                        disabled={
                          !!offers?.find(
                            (offer: any) =>
                              offer?.workerId === user?.customer?.id,
                          )
                        }
                      >
                        Откликнуться
                      </Button>
                    </>
                  )}
                </>
              )}

              {user?.customer.role === "customer" && (
                <Button
                  height="32px"
                  fontWeight="400"
                  onClick={() => navigate(`/o/${id}`)}
                >
                  Открыть заказ
                </Button>
              )}
            </div>
          </PricePart>
        </OrderItemDescriptionPart>
      </OrderItemWrapper>
      {proposalPopUp && (
        <ProposalPopUp
          close={() => setProposalPopUp(false)}
          onSubmit={fetchRespondHandler}
          orderCategoryId={categoryId}
          orderPaymentType={paymentType}
        />
      )}
    </>
  );
};

export default WorkItem;

