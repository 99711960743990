import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

interface FilterFormType {
  from: moment.Moment | null;
  to: moment.Moment | null;
  customers: string[] | null;
  vehicle: string[] | null;
  staff: string[] | null;
}

const getDashboardOrders = (
  id: string,
  options?: {
    filterParams?: FilterFormType;
    sort?: string[];
  },
) => {
  return {
    type: actionTypes.GET_DASHBOARD_ORDERS,
    payload: httpRequest({
      method: "GET",
      // path: `payout/${id}/cards`,
      url: `${ENDPOINT}/companies/${id}/orders`,
      params: {
        sort: options?.sort?.length ? options?.sort.join(",") : null,
        creatorId: options?.filterParams?.staff?.join(","),
        categoryId: options?.filterParams?.vehicle?.join(","),
        objectId: options?.filterParams?.customers?.join(","),
        startDate: options?.filterParams?.from?.format(),
        endDate: options?.filterParams?.to?.format(),
        take: 10000,
      },
    }),
  };
};

export default getDashboardOrders;
