import React, { memo, useState } from "react";
import { ProposalMenuWrapper } from "./styles";
import { ReactComponent as HideIcon } from "../../assets/icons/icon-24-intellicon-basic-eye-no.svg";
import { ReactComponent as FlagIcon } from "../../assets/icons/icon-24-intellicon-basic-flag.svg";
import { ReactComponent as GlassesIcon } from "../../assets/icons/icon-24-intellicon-basic-glasses.svg";
import ReportWorkerPopUp from "../popUp/reportWorkerPopUp";
import BetaMarker from "../../UI/BetaMarker";

const ProposalMenu: React.FC<{
  setReportWorkerOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setHideOfferOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ setReportWorkerOpen, setHideOfferOpen }) => {
  return (
    <ProposalMenuWrapper>
      <div className="buttonsList">
        <div className="button disabled">
          <GlassesIcon />
          Проверить исполнителя
          <BetaMarker />
        </div>
        <div
          className="button"
          onMouseDown={() => {
            setHideOfferOpen(true);
          }}
        >
          <HideIcon />
          Скрыть отклик исполнителя
        </div>
        <div
          className="button"
          onMouseDown={() => {
            setReportWorkerOpen(true);
          }}
        >
          <FlagIcon />
          Пожаловаться
        </div>
      </div>
    </ProposalMenuWrapper>
  );
};

export default memo(ProposalMenu);
