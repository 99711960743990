import styled from "styled-components";
import { Container, styles } from "../../components/styles/styles";
import PageHeader from "../../UI/PageHeader";

export const MainWrapper = styled.div<{ navbar: number | null }>`
  width: 100%;
  height: calc(100vh - ${({ navbar }) => navbar}px);
  min-height: 500px;
  display: flex;
  background: #ffffff;
`;

export const StyledPageHeader = styled(PageHeader)`
  width: 500px;

  @media screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const MapWrapper = styled.div`
  width: 50%;
  height: 100%;

  @media screen and (max-width: 790px) {
    display: none;
  }
`;

export const AddAddressButton = styled.div`
  font-size: 14px;
  color: #ff6633;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;

export const Wrapper = styled(Container)`
  padding: 40px 0 0 160px;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 18px;

  @media screen and (min-width: 1920px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 1440px) {
    padding: 40px 16px 16px 16px;
    align-items: center;
  }

  @media screen and (max-width: 790px) {
    width: 100%;
    max-width: 500px;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -0.1px;
  margin-bottom: 34px;
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 500px;

  @media screen and (max-width: 1050px) {
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    padding: 0 8px 8px 8px;
  }
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 6px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 4px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px 16px;

  .periodDesc {
    margin: 0 0 0 5px;
  }
`;

export const InputWrapper = styled.label<{ active?: boolean }>`
  width: 100%;
  height: 48px;
  background-color: white;
  padding: 0 16px;
  position: relative;
  border-radius: 10px;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "var(--color-light-gray)"};
  display: flex;
  flex-direction: column;
  justify-content: center;

  input {
    appearance: none;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
  }
`;

export const InputDropdown = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  position: absolute;
  top: 46px;
  left: -1px;
  border: 1px solid #999999;
  background: #ffffff;
  width: calc(100% + 2px);
  z-index: 10;
  height: fit-content;
  max-height: 200px;
  scrollbar-width: none;

  .dropdownItem {
    cursor: pointer;
    padding: 12px 16px;
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    .top {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .bottom {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.38);
    }

    &:hover {
      background: rgba(0, 0, 0, 0.12);
    }
    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 12px 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;
