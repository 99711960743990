import styled from "styled-components";

export const Wrapper = styled.div<{ direction?: "forward" | "backward" }>`
  width: 89px;
  height: 32px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #eee7e1;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  line-height: 1.14;
  letter-spacing: -0.2px;
  color: #757575;
  cursor: pointer;

  svg {
    ${({ direction }) =>
      direction === "forward" && "transform: rotate(180deg)"};
  }
`;
