export const checkUserRegistered = (user: any) => {
  let registered: boolean = true;
  if (!user?.customer?.companyId) {
    Object.entries(user?.customer).forEach(([key, value]: any) => {
      if (
        key === "firstName" ||
        key === "secondName" ||
        key === "city" ||
        key === "email"
      ) {
        if (value === null || value === "" || value === undefined) {
          registered = false;
        }
      }
    });
  }

  return registered;
};
