import { createAsyncThunk } from "@reduxjs/toolkit";
import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

export const createCompanyAsync = createAsyncThunk(
  actionTypes.CREATE_COMPANY,
  async ({ refId, data }: { refId: string; data: any }) => {
    try {
      const response = await httpRequest({
        method: "POST",
        path: `${ENDPOINT}/companies/${refId}`,
        data,
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

const createCompany = ({ refId, data }: { refId: string; data: any }) => ({
  type: actionTypes.CREATE_COMPANY,
  payload: httpRequest({
    method: "POST",
    // path: `payout/${id}/cards`,
    url: `${ENDPOINT}/companies/${refId}`,
    data: data,
  }),
});

export default createCompany;
