import styled from "styled-components";

export const HeaderWrapper = styled.div<{
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  lineHeight?: string;
  padding?: string;
}>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "18px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  color: ${({ color }) => (color ? color : "#222222")};
  ${({ lineHeight }) => lineHeight && `line-height ${lineHeight}`};
  display: flex;
  justify-content: space-between;
  ${({ padding }) => padding && `padding: ${padding}`};
  svg {
    cursor: pointer;
  }
`;
