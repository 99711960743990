import React, { memo } from "react";
import { ReactComponent as CheckIcon } from "../../assets/icons/icons-24-status-icon-check-green.svg";
import { OrderPaidWrapper } from "./styles";

const OrderPaid: React.FC = () => {
  return (
    <OrderPaidWrapper>
      <CheckIcon />
      Оплачен
    </OrderPaidWrapper>
  );
};

export default memo(OrderPaid);
