import styled from "styled-components";

export const ToastWrapper = styled.div`
  display: flex;
  gap: 16px;

  .iconContainer {
    width: 56px;
    height: 56px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    background-color: rgba(255, 243, 239, 0.24);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 32px;
    font-family: "Rubik";
  }

  .toastTextContainer {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .toastTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 1;
      color: #fdfdfd;
    }

    .toastContent {
      font-size: 12px;
      line-height: 1;
      color: #fff3ef;
    }
  }
`;
