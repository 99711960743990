import React, { memo } from "react";
import { Wrapper } from "./styles";

const CounterBadge: React.FC<{ value: string | number | undefined }> = ({
  value,
}) => {
  return <Wrapper className="counterBadge">{value}</Wrapper>;
};

export default memo(CounterBadge);
