import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { ConnectionItem, ConnectionMethodList } from "./styles";

interface Props {
  close: any;
  onSubmit: any;
  defaultValue?: any;
  tab?: any;
}

const ConnectionPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  defaultValue,
  tab,
}) => {
  const connectionOption = [
    {
      name: "Телеграм",
      value: "Телеграм",
    },
    {
      name: "What's up",
      value: "What's up",
    },
    {
      name: "Viber",
      value: "Viber",
    },
  ];

  const [text, setText] = useState<string>(defaultValue || "");
  const [connectionMethod, setConnectionMethod] = useState<any>([]);
  const [amount, setAmount] = useState<number>(0);

  const toggleConnectionMethod = (method: any) => {
    const exist = connectionOption.find(
      ({ name, value }: any) => name === method.name && value === method.value,
    );

    if (!exist) {
      setConnectionMethod([...connectionMethod, method]);
    } else {
      setConnectionMethod(
        connectionMethod.filter(
          ({ name, value }: any) =>
            name !== method.name && value !== method.value,
        ),
      );
    }
  };

  useEffect(() => {
    if (
      connectionMethod &&
      connectionMethod.name === "Подойдет любой способ связи"
    ) {
      setAmount(0);
      onSubmit({ connectionMethod, amount });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionMethod]);

  useEffect(() => {
    if (defaultValue?.connectionMethod?.length > 0 && defaultValue?.amount) {
      setConnectionMethod(defaultValue?.connectionMethod);
      setAmount(defaultValue?.amount);
    } else if (defaultValue?.amount) {
      setAmount(defaultValue?.amount);
    }
  }, []);

  return (
    <PopUpContainer
      title="Способы связи"
      close={close}
      onSubmit={
        connectionMethod &&
        (() => {
          onSubmit({ connectionMethod, amount });
          close();
        })
      }
      button={"Добавить информацию"}
      disabled={!defaultValue && !Boolean(text)}
      tab={tab}
    >
      <ConnectionMethodList>
        {connectionOption.map(({ name, value }) => (
          <ConnectionItem
            onClick={() => toggleConnectionMethod({ name, value })}
            key={value}
          >
            <input
              type="checkbox"
              defaultChecked={connectionOption.some(
                (method: any) => name === method.name && value === method.value,
              )}
            />
            <div className="name">{name}</div>
          </ConnectionItem>
        ))}
        <ConnectionItem
          onClick={() => {
            onSubmit({
              connectionMethod: [
                {
                  name: "Подойдет любой способ связи",
                  value: "Подойдет любой способ связи",
                },
              ],
              amount: 0,
            });
            close();
          }}
          key={connectionMethod.length}
        >
          <div className="name">{"Подойдет любой способ связи"}</div>
        </ConnectionItem>
      </ConnectionMethodList>
      <p style={{ marginTop: "15px" }}>Выберите удобный для Вас способ связи</p>
    </PopUpContainer>
  );
};

export default memo(ConnectionPopUp);
