import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  position: relative;
  overflow: hidden;
`;
