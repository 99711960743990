import React, { memo } from "react";
import { ContentTitle, Wrapper } from "../successPaymentPage/styles";
import { ReactComponent as Error } from "../../assets/icons/payment-error.svg";

const ErrorPaymentPage: React.FC = () => {
  return (
    <Wrapper>
      <Error width={100} height={150} />
      <ContentTitle>Что-то пошло не так!</ContentTitle>
      <ContentTitle>
        Это окно скоро закроется, но вы можете закрыть его сами
      </ContentTitle>
    </Wrapper>
  );
};

export default memo(ErrorPaymentPage);
