import React, { memo, useEffect, useState } from "react";
import {
  AvatarBox,
  LoginBtn,
  Logo,
  LogoPart,
  MenuBar,
  NavbarContainer,
  NavbarWrapper,
  NavItem,
  NavList,
  NotificationBor,
  SupportBtn,
  MenuPart,
} from "./styles";
import CounterBadge from "../../UI/CounterBadge";

import logo from "../../assets/images/Logo.png";
import notification from "../../assets/icons/icon-24-intellicon-notifications-bell.svg";
import { useLocation, useNavigate } from "react-router-dom";
import defaultAvatar from "../../assets/images/avatar.png";
import { ReactComponent as DropdownIcon } from "../../assets/icons/icon-16-basic-arrow-drop-down.svg";
import { ReactComponent as SmallRatingIcon } from "../../assets/icons/icon-10-rating-star-gray.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useGlobalContext } from "../../App";
import { getMyOrders } from "../../redux/store/reducers/orderSlice";
import { RootState } from "../../redux/store/store";
import { ReactComponent as QuestionMarkIcon } from "../../assets/icons/24-chatting-question.svg";
import NotificationSidebar from "../notificationSidebar";
import NavSidebar from "../navSidebar";
import getManagers from "../../redux/modules/getManagers";
import {
  getNotifications,
  getNotificationsCount,
  getUnreadNotifications,
} from "../../redux/store/reducers/notificationsSlice";
import { useInterval } from "../../hooks/useInterval";
import { Notification } from "../../types";
import { toast } from "react-toastify";
import ToastContainer from "../../UI/ToastContainer";

const CustomerMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  return (
    <>
      {user?.customer.companyId ? (
        user.customer.accessSettings.creatingOrder ? (
          <NavItem onClick={() => navigate("/create-order")}>
            Новый заказ
          </NavItem>
        ) : null
      ) : (
        <NavItem onClick={() => navigate("/create-order")}>Новый заказ</NavItem>
      )}

      <NavItem
        onClick={() => {
          navigate("/o");
        }}
      >
        Мои заказы <CounterBadge value={user?.ordersTotal || 0} />
      </NavItem>
      <NavItem
        onClick={() => {
          navigate("/messages");
        }}
      >
        Сообщения
      </NavItem>
    </>
  );
};

const ExecutorMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  return (
    <>
      <NavItem onClick={() => navigate("/search-order")}>Поиск заказа</NavItem>
      <NavItem
        onClick={() => {
          navigate("/search-order/results?flag=my");
        }}
      >
        Мои работы
        <CounterBadge value={user?.ordersTotal || 0} />
      </NavItem>
      <NavItem
        onClick={() => {
          navigate("/messages");
        }}
      >
        Сообщения
      </NavItem>
    </>
  );
};

const BusinessMenu = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const { data: managers } = useAppSelector((state) => state.managers);

  useEffect(() => {
    if (user?.customer?.refId) {
      getManagers(user?.customer?.refId);
    }
  }, [user?.customer?.refId]);

  return (
    <>
      <NavItem onClick={() => navigate("/dashboard/objects")}>Объекты</NavItem>
      <NavItem onClick={() => navigate("/dashboard/employees")}>
        Сотрудники
        {managers?.users ? (
          <CounterBadge value={managers?.users.length} />
        ) : null}
      </NavItem>
      <NavItem onClick={() => navigate("/dashboard/workers")}>
        Исполнители
      </NavItem>
      <NavItem onClick={() => navigate("/dashboard/order")}>Заказы</NavItem>
    </>
  );
};

const Navbar: React.FC<any> = ({ disableLoginBtn, notifications }) => {
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    if (user?.customer.refId) {
      dispatch(
        getMyOrders({
          refId: user?.customer?.refId,
          take: 10,
          skip: 0,
        }),
      );

      dispatch(getNotificationsCount({ refId: user.customer.refId }));
    }
  }, [user]);

  useInterval(() => {
    user?.customer.refId &&
      dispatch(getUnreadNotifications({ refId: user.customer.refId })).then(
        (response) => {
          if (Array.isArray(response.payload)) {
            response.payload.map((notification: Notification) => {
              toast(
                <ToastContainer
                  title={notification.title}
                  content={notification.content}
                />,
                {
                  autoClose: 5000,
                  position: "top-right",
                },
              );
            });
          }
        },
      );
  }, 15000);

  return (
    <>
      <NavbarWrapper
        id="navbar"
        style={
          pathname === "/go"
            ? { background: "none !important", position: "fixed" }
            : { background: "white" }
        }
      >
        <NavbarContainer
          full={
            pathname === "/create-order" ||
            pathname === "/search-order" ||
            pathname === "/digital-dispatcher" ||
            pathname === "/login" ||
            pathname === "/registration" ||
            pathname === "/telegram-auth"
          }
        >
          <LogoPart>
            <Logo
              to={
                pathname !== "/login" && pathname !== "/registration"
                  ? "/profile"
                  : pathname
              }
            >
              <img src={logo} alt="logo" />
            </Logo>
            {user && (
              <NavList>
                {pathname.startsWith("/dashboard") ? (
                  <BusinessMenu />
                ) : (
                  <>
                    {user?.customer?.role === "customer" && <CustomerMenu />}
                    {user?.customer?.role === "worker" && <ExecutorMenu />}
                  </>
                )}
              </NavList>
            )}
          </LogoPart>
          {user ? (
            <MenuPart>
              <NotificationBor>
                <img
                  className="notifyIcon"
                  src={notification}
                  alt="notifyBell"
                  onClick={() => setIsNavbarOpen(true)}
                />
                {notifications?.length ? <div className="dot" /> : ""}
              </NotificationBor>
              <AvatarBox onClick={() => setSideMenuOpen(true)}>
                <img
                  src={
                    user?.customer?.avatarUrl
                      ? `data:image/jpeg;base64, ${user?.customer?.avatarUrl}`
                      : defaultAvatar
                  }
                  alt="avatar"
                />
                <div className="hoverGroup">
                  <div className="name">
                    <span className="nameSpan">
                      {user?.customer?.firstName}
                    </span>
                    <DropdownIcon />
                  </div>
                  <div className="line">
                    <span>
                      {user?.customer?.role === "customer"
                        ? "Заказчик"
                        : "Исполнитель"}
                    </span>

                    <span className="rating">
                      <SmallRatingIcon />
                      {user?.customer?.rating !== null
                        ? user?.customer?.rating
                        : "0.0"}
                    </span>
                  </div>
                </div>
              </AvatarBox>
            </MenuPart>
          ) : pathname === "/registration" ||
            pathname === "/login" ||
            pathname === "/telegram-auth" ? (
            <SupportBtn href="https://t.me/Rukki_Pro" target={"_blank"}>
              <QuestionMarkIcon /> Поддержка
            </SupportBtn>
          ) : (
            <MenuPart>
              <SupportBtn href="https://t.me/Rukki_Pro" target={"_blank"}>
                <QuestionMarkIcon /> Поддержка
              </SupportBtn>
              {!localStorage.getItem("accessToken") && (
                <LoginBtn
                  onClick={() => navigate("/login")}
                  disabled={disableLoginBtn}
                >
                  Войти
                </LoginBtn>
              )}
            </MenuPart>
          )}
        </NavbarContainer>
      </NavbarWrapper>
      <NavSidebar
        menuOpen={sideMenuOpen}
        toggleMenuOpen={() => setSideMenuOpen(!sideMenuOpen)}
      />
      <NotificationSidebar
        isOpen={isNavbarOpen}
        close={() => setIsNavbarOpen(false)}
      />
    </>
  );
};

export default Navbar;

