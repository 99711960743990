import styled from "styled-components";

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000079;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PopUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  max-height: 80vh;
  background: #ffffff;
  border-radius: 10px;
  padding-top: calc(12px + 24px);
  width: 100%;
  max-width: 600px;
  position: relative;

  svg {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media screen and (max-width: 590px) {
    max-height: unset;
    max-width: unset;
  }
`;
