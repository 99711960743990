import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

const getManagerData = (refId: string, userId: string) => ({
  type: actionTypes.GET_MANAGER_DATA,
  payload: httpRequest({
    method: "GET",
    url: `${ENDPOINT}/companies/${refId}/users/${userId}`,
  }),
});

export default getManagerData;
