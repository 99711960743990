export const getCountdown = (startDate: string): string => {
  const countdown = new Date().getTime() - new Date(startDate).getTime();
  const hours = Math.floor(
    ((86400000 - countdown) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor(
    ((86400000 - countdown) % (1000 * 60 * 60)) / (1000 * 60),
  );
  const seconds = Math.floor(((86400000 - countdown) % (1000 * 60)) / 1000);

  return `${hours}:${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
};
