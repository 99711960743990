export const characteristics: any = {
  Ричстакер: {
    "Грузоподъемность техники (т)": {
      type: "slider",
      value: 0,
    },
  },
  "Экскаватор разрушитель": {
    "Максимально рабочая высота": {
      type: "slider",
      value: 0,
    },
    "Максимально рабочая длина": {
      type: "slider",
      value: 0,
    },
    "Тоннаж экскаватора": {
      type: "slider",
      value: 0,
    },
    "Тип шасси": {
      type: "radio",
      value: "",
      options: ["Гусеничный", "Колесный"],
    },
  },
  Эвакуатор: {
    "Какой автомобиль нужно перевезти?": {
      type: "radio",
      options: ["Фургон", "Легковой", "Грузовой", "Мотоцикл", "Квадроцикл"],
      value: "",
    },
  },
  Шаланда: {
    "Длина борта": {
      type: "slider",
      value: 0,
    },
  },
  "Самоcвал вездеход": {
    "Объем кузова": {
      type: "radio",
      value: "",
      options: [
        "6 м³",
        "8 м³",
        "10 м³",
        "12 м³",
        "20 м³",
        "25 м³",
        "30 м³",
        "35 м³",
      ],
    },
  },
  "Фронтальный погрузчик": {
    Грузоподъемность: {
      type: "slider",
      value: 0,
    },
    "Объем ковша": {
      type: "slider",
      value: 0,
    },
  },
  Ломовоз: {
    "Объем кузова": {
      type: "slider",
      value: 0,
    },
  },
  "Кран-вездеход": {
    Грузоподъемность: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Кран гусеничный": {
    Грузоподъемность: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  Манипулятор: {
    "Грузоподъемность борта": {
      type: "slider",
      value: 0,
    },
    "Вылет стрелы": {
      type: "slider",
      value: 0,
    },
    "Длина борта": {
      type: "slider",
      value: 0,
    },
  },
  Борт: {
    "Длина борта": {
      type: "slider",
      value: 0,
    },
    "Грузоподъемность борта": {
      type: "slider",
      value: 0,
    },
  },
  "Кран стационарный": {
    Грузодъемность: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Поливомоечная машина": {
    "Вместимость цистерны (л. куб.)": {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Низкорамный трал": {
    "Длина площадки": {
      type: "slider",
      value: 0,
    },
    Грузоподъемность: {
      type: "slider",
      value: 0,
    },
    "Высота борта": {
      type: "slider",
      value: 0,
    },
  },
  Водовозка: {
    Объем: {
      type: "slider",
      value: 0,
    },
  },
  "Манипулятор вездеход": {
    "Длина борта": {
      type: "slider",
      value: 0,
    },
    "Грузоподъемность борта": {
      type: "slider",
      value: 0,
    },
  },
  Ассенизатор: {
    Объем: {
      type: "slider",
      value: 0,
    },
  },
  Самосвал: {
    "Объем кузова": {
      type: "slider",
      value: 0,
    },
  },
  "Манипулятор-эвакуатор": {
    "Какой автомобиль нужно перевезти?": {
      type: "radio",
      value: "",
      options: ["Фургон", "Легковой", "Грузовой", "Мотоцикл", "Квадроцикл"],
    },
  },
  "Дорожная фреза": {
    "Ширина фрезирования": {
      type: "slider",
      value: 0,
    },
    "Глубина фрезирования": {
      type: "slider",
      value: 0,
    },
  },
  Ресайклер: {
    "Масса машины (т)": {
      type: "slider",
      value: 0,
      masks: {
        15.6: 15.6,
        19: 19,
        23: 23,
        27: 27,
        30: 30,
        33.5: 33.5,
      },
      min: 15.6,
      max: 33.5,
      step: 0.1,
    },
    "Рабочая ширина (м)": {
      type: "slider",
      value: 0,
      masks: {
        2: 2,
        2.1: 2.1,
        2.2: 2.2,
        2.3: 2.3,
        2.4: 2.4,
        2.5: 2.5,
      },
      min: 2,
      max: 2.5,
      step: 0.1,
    },
    "Глубина резания (мм)": {
      type: "slider",
      value: 0,
      masks: {
        0: 0,
        75: 75,
        150: 150,
        225: 225,
        300: 300,
        430: 430,
      },
      min: 0,
      max: 430,
    },
  },
  "Каток дорожный": {
    "Глубина резания (мм)": {
      type: "slider",
      value: 0,
    },
    // Масса: {
    //   type: "slider",
    //   value: 0,
    // },
  },
  Автогрейдер: {
    "Объем кузова": {
      type: "slider",
      value: 0,
    },
  },
  "Экскаватор длиннорукий": {
    Тоннаж: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  Автокран: {
    Грузоподъемность: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  Автовышка: {
    Высота: {
      type: "slider",
      value: 0,
    },
  },
  Илосос: {
    Объем: {
      type: "slider",
      value: 0,
    },
  },
  Тонар: {
    "Объем кузова": {
      type: "slider",
      value: 0,
    },
  },
  Щеповоз: {
    "Объем кузова": {
      type: "slider",
      value: 0,
    },
  },
  Траншеекопатель: {
    "Глубина копания": {
      type: "slider",
      value: 0,
    },
    "Ширина копания": {
      type: "slider",
      value: 0,
    },
  },
  "Каток комбинированный": {
    Масса: {
      type: "slider",
      value: 0,
    },
  },
  Длинномер: {
    "Длина борта": {
      type: "slider",
      value: 0,
    },
  },
  "Телескопический погрузчик": {
    Грузодъемность: {
      type: "slider",
      value: 0,
    },
    "Высота подъема": {
      type: "slider",
      value: 0,
    },
  },
  "Вилочный погрузчик": {
    Грузодъемность: {
      type: "slider",
      value: 0,
    },
  },
  "Дизель-молот (Сваебой)": {
    "Масса ударной части": {
      type: "slider",
      value: 0,
    },
    "Масса молота": {
      type: "slider",
      value: 0,
    },
  },
  "Бурильно-крановая машина": {
    "Глубина бурения": {
      type: "slider",
      value: 0,
    },
    "Диаметр лунок": {
      type: "slider",
      value: 0,
    },
  },
  "Установка горизонтального бурения (ГНБ)": {
    "Длина бурения": {
      type: "slider",
      value: 0,
    },
    "Диаметр лунок": {
      type: "slider",
      value: 0,
    },
  },
  "Бульдозер колесный": {
    "Ширина отвала": {
      type: "slider",
      value: 0,
    },
    Масса: {
      type: "slider",
      value: 0,
    },
  },
  "Бульдозер гусеничный": {
    "Ширина отвала": {
      type: "slider",
      value: 0,
    },
    Масса: {
      type: "slider",
      value: 0,
    },
  },
  "Экскаватор карьерный": {
    Тоннаж: {
      type: "slider",
      value: 0,
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Экскаватор-погрузчик": {
    "Навесное оборудования": {
      type: "radio",
      value: "",
      options: ["Ямобур", "Вилы", "Гидромолот"],
    },
  },
  "Экскаватор гусеничный": {
    Тоннаж: {
      type: "slider",
      value: 0,
    },
    Габарит: {
      type: "switch",
      value: "Негабарит",
      on: "Габарит",
      off: "Негабарит",
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Экскаватор колесный": {
    Тоннаж: {
      type: "slider",
      value: 0,
    },
    Габарит: {
      type: "switch",
      value: "Негабарит",
      on: "Габарит",
      off: "Негабарит",
    },
    "Длина стрелы": {
      type: "slider",
      value: 0,
    },
  },
  "Каток грунтовый": {
    Масса: {
      type: "slider",
      value: 0,
    },
  },
};

// export const characteristics: any = {
//   Автовышка: {
//     height: {
//       type: "slider",
//       value: 0,
//     },
//     // type_arrow: {},
//     typeChassis: {
//       type: "radio",
//       value: "",
//       options: ["Гусеничный", "Колесный"],
//     },
//     volume: {
//       type: "switch",
//       value: "Негабарит",
//       on: "Габарит",
//       off: "Негабарит",
//     },
//   },
//   Автокран: {
//     loadCapacity: {
//       type: "slider",
//       value: 0,
//     },
//     arrowReach: {
//       type: "slider",
//       value: 0,
//     },
//   },
//   Самосвал: {
//     numberAxles: {
//       type: "slider",
//       value: 0,
//     },
//     volume: {
//       type: "slider",
//       value: 0,
//     },
//   },
//   "Экскаватор-погрузчик": {
//     loadCapacity: {
//       type: "slider",
//       value: 0,
//     },
//     typeChassis: {
//       type: "radio",
//       value: "",
//       options: ["Гусеничный", "Колесный"],
//     },
//     // "equipments":{

//     // }
//   },
//   Манипулятор: {
//     loadCapacitySide: {
//       type: "slider",
//       value: 0,
//     },
//     loadCapacityArrow: {
//       type: "slider",
//       value: 0,
//     },
//   },
// };
