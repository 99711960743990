import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/24-basic-circle-x.svg";
import {
  CharacteristicsBG,
  CharacteristicsPopUpContainer,
  DescriptionBox,
  InputsList,
  InputTitle,
  ItemWrapper,
  PopUpHeader,
  PopUpSlider,
  SubmitButton,
  SwitcherWrapper,
} from "./styles";
import { InputBox } from "../../../pages/registerAsOrganisation";
import { VehicleParams } from "../../../redux/store/reducers/orderSlice";
import Checkbox from "../../../UI/Checkbox";

type Checkbox = Array<string>;

interface Characteristic {
  cId: number;
  value: string | number | Checkbox;
}

interface Props {
  close: any;
  onSubmit: any;
  defaultValue?: {
    [key: string | number]: Characteristic;
  };
  isVisible: boolean;
  tab?: any;
  vehicle?: string;
  characteristics: VehicleParams[];
  center?: boolean;
  updateVehicleParams?: (
    cId: number,
    value: string | number | Array<any>,
  ) => void;
  categoryId?: number | null | undefined;
}

const CharacteristicsPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  defaultValue,
  tab,
  vehicle,
  characteristics,
  center,
  isVisible,
  updateVehicleParams,
  categoryId,
}) => {
  const [inputs, setInputs] = useState<{
    [key: string | number]: Characteristic;
  }>(() => {
    const inputs: {
      [key: string | number]: Characteristic;
    } = {};
    if (defaultValue) {
      return { ...defaultValue };
    }
    if (characteristics?.length && characteristics?.length > 0) {
      characteristics.map(({ additional, id }, index) => {
        inputs[index] = { cId: id, value: "" };
      });
    }
    return { ...inputs };
  });

  const renderParameters = () => {
    const parameters: VehicleParams[] = [];
    characteristics.map((char) => {
      parameters.push(char);
    });
    return parameters;
  };

  const getSliderMarks = (values: number[] | string[]) => {
    const marks: { [key: string | number]: string | number } = {};
    values.map((value) => {
      marks[value] = value;
    });
    return marks;
  };

  const submitHandler = (
    cId?: number,
    value?: string | number | Array<any>,
  ) => {
    const result: Characteristic[] = [];

    if (cId && value) {
      result.push({ cId, value });
    }
    Object.entries(inputs)?.map((input: any) => {
      if (
        !result.find((item) => item.cId === inputs[input[0]].cId) &&
        inputs[input[0]].value
      ) {
        result.push({
          cId: inputs[input[0]].cId,
          value: inputs[input[0]].value,
        });
      }
    });
    onSubmit(result);
  };

  const setStatePromise = async (
    index: number,
    { cId, value }: Characteristic,
  ) =>
    new Promise((resolve) =>
      setInputs((prev: any) => ({
        ...prev,
        [index]: { cId: cId, value: value },
      })),
    );

  useEffect(() => {
    const inputs: {
      [key: string | number]: Characteristic;
    } = {};
    if (defaultValue?.length) {
      setInputs({ ...defaultValue });
    } else {
      if (characteristics?.length && characteristics?.length > 0) {
        characteristics.map(({ additional, id }, index) => {
          inputs[index] = { cId: id, value: "" };
        });
      }
      setInputs({ ...inputs });
    }
  }, [characteristics]);

  return (
    <>
      <CharacteristicsBG onClick={close} center={center} isVisible={isVisible}>
        <CharacteristicsPopUpContainer
          center={center}
          isVisible={isVisible}
          onClick={(e) => e.stopPropagation()}
        >
          <PopUpHeader>
            <div className="text">Параметры техники</div>
            <CloseIcon onClick={close} />
          </PopUpHeader>
          <DescriptionBox>
            <div className="text">{vehicle}</div>
            <InputsList>
              {characteristics &&
                characteristics?.length &&
                Object.entries(inputs)?.length &&
                renderParameters()
                  ?.filter((i) => i)
                  .map(({ additional, id }, index) => (
                    <ItemWrapper key={`characteristic_${index}`}>
                      {additional &&
                      additional?.type &&
                      additional?.type === "value" ? (
                        <>
                          <InputBox
                            label={additional.title}
                            value={
                              inputs[index]?.value !== 0
                                ? inputs[index]?.value
                                : ""
                            }
                            type={""}
                            onChange={(e: any) => {
                              if (additional.logic && updateVehicleParams) {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: Number(e.target.value),
                                  },
                                }));
                                submitHandler(id, e);
                                updateVehicleParams(id, e);
                              } else {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: Number(e.target.value),
                                  },
                                }));
                              }
                            }}
                          />
                          <PopUpSlider
                            marks={getSliderMarks(additional.values)}
                            max={Number(
                              additional.values[additional.values.length - 1],
                            )}
                            min={Number(additional.values[0])}
                            step={null}
                            value={
                              !isNaN(Number(inputs[index]?.value))
                                ? Number(inputs[index]?.value)
                                : 0
                            }
                            onChange={async (e) => {
                              if (additional.logic && updateVehicleParams) {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: e,
                                  },
                                }));
                                submitHandler(id, e);
                                updateVehicleParams(id, e);
                              } else {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: e,
                                  },
                                }));
                              }
                            }}
                          />
                        </>
                      ) : additional &&
                        additional?.type &&
                        additional?.type === "checkbox" ? (
                        <SwitcherWrapper>
                          <InputTitle>{additional?.title}</InputTitle>
                          {additional?.values?.map((value, checkboxIndex) => {
                            return (
                              <Checkbox
                                key={`checkbox_${checkboxIndex}`}
                                text={value.toString()}
                                active={
                                  inputs[index]?.value === value ||
                                  (Array.isArray(inputs[index]?.value) &&
                                    (
                                      inputs[index]?.value as Checkbox
                                    )?.includes(value as string))
                                }
                                onClick={() => {
                                  const inputValue =
                                    inputs[index]?.value === value ? "" : value;
                                  if (
                                    additional?.logic &&
                                    updateVehicleParams
                                  ) {
                                    setInputs((prev: any) => ({
                                      ...prev,
                                      [index]: {
                                        cId: id,
                                        value: inputValue,
                                      },
                                    }));
                                    submitHandler(id, inputValue);
                                    updateVehicleParams(id, inputValue);
                                  } else {
                                    if (
                                      typeof inputs[index]?.value === "string"
                                    ) {
                                      if (inputs[index]?.value === "") {
                                        setInputs((prev: any) => ({
                                          ...prev,
                                          [index]: {
                                            cId: id,
                                            value: [inputValue],
                                          },
                                        }));
                                      } else {
                                        setInputs((prev: any) => ({
                                          ...prev,
                                          [index]: {
                                            cId: id,
                                            value: [
                                              inputs[index].value,
                                              inputValue,
                                            ],
                                          },
                                        }));
                                      }
                                    } else if (
                                      Array.isArray(inputs[index]?.value)
                                    ) {
                                      if (
                                        (
                                          inputs[index].value as Checkbox
                                        ).includes(inputValue as string)
                                      ) {
                                        setInputs((prev: any) => ({
                                          ...prev,
                                          [index]: {
                                            cId: id,
                                            value: [
                                              ...(
                                                inputs[index].value as Checkbox
                                              ).filter(
                                                (item) => item !== inputValue,
                                              ),
                                            ],
                                          },
                                        }));
                                      } else {
                                        setInputs((prev: any) => ({
                                          ...prev,
                                          [index]: {
                                            cId: id,
                                            value: [
                                              ...(inputs[index]
                                                .value as Checkbox),
                                              inputValue,
                                            ],
                                          },
                                        }));
                                      }
                                    } else {
                                      setInputs((prev: any) => ({
                                        ...prev,
                                        [index]: {
                                          cId: id,
                                          value: inputValue,
                                        },
                                      }));
                                    }
                                  }
                                }}
                              />
                            );
                          })}
                        </SwitcherWrapper>
                      ) : additional &&
                        additional?.type &&
                        additional.type === "input" ? (
                        <InputBox
                          label={additional.title}
                          value={
                            inputs[index]?.value !== 0
                              ? inputs[index]?.value
                              : ""
                          }
                          type={""}
                          onChange={(e: any) => {
                            if (additional.logic && updateVehicleParams) {
                              setInputs((prev: any) => ({
                                ...prev,
                                [index]: {
                                  cId: id,
                                  value: e.target.value,
                                },
                              }));
                              submitHandler(id, e.target.value);
                              updateVehicleParams(id, e.target.value);
                            } else {
                              setInputs((prev: any) => ({
                                ...prev,
                                [index]: {
                                  cId: id,
                                  value: e.target.value,
                                },
                              }));
                            }
                          }}
                        />
                      ) : additional &&
                        additional?.type &&
                        additional.type === "radio" ? (
                        <>
                          <SwitcherWrapper>
                            <InputTitle>{additional.title}</InputTitle>
                            {additional?.values?.map((value, checkboxIndex) => {
                              return (
                                <Checkbox
                                  key={`radio_${checkboxIndex}`}
                                  text={value.toString()}
                                  active={
                                    inputs[index]?.value === value ||
                                    (Array.isArray(inputs[index]?.value) &&
                                      (
                                        inputs[index]?.value as Checkbox
                                      )?.includes(value as string))
                                  }
                                  onClick={() => {
                                    const inputValue =
                                      inputs[index]?.value === value
                                        ? ""
                                        : value;
                                    if (
                                      additional.logic &&
                                      updateVehicleParams
                                    ) {
                                      setInputs((prev: any) => ({
                                        ...prev,
                                        [index]: {
                                          cId: id,
                                          value: inputValue,
                                        },
                                      }));
                                      submitHandler(id, inputValue);
                                      updateVehicleParams(id, inputValue);
                                    } else {
                                      setInputs((prev: any) => ({
                                        ...prev,
                                        [index]: {
                                          cId: id,
                                          value: inputValue,
                                        },
                                      }));
                                    }
                                  }}
                                />
                              );
                            })}
                          </SwitcherWrapper>
                        </>
                      ) : additional &&
                        additional?.type &&
                        additional?.type === "inputRange" ? (
                        <>
                          <InputBox
                            label={additional.title}
                            value={
                              inputs[index]?.value !== 0
                                ? inputs[index]?.value
                                : ""
                            }
                            type={""}
                            onChange={(e: any) => {
                              if (additional.logic && updateVehicleParams) {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: Number(e.target.value),
                                  },
                                }));
                                submitHandler(id, e);
                                updateVehicleParams(id, e);
                              } else {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: Number(e.target.value),
                                  },
                                }));
                              }
                            }}
                          />
                          <PopUpSlider
                            marks={getSliderMarks(additional.values)}
                            max={Number(
                              additional.values[additional.values.length - 1],
                            )}
                            min={Number(additional.values[0])}
                            step={1}
                            value={
                              !isNaN(Number(inputs[index]?.value))
                                ? Number(inputs[index]?.value)
                                : 0
                            }
                            onChange={async (e) => {
                              if (additional.logic && updateVehicleParams) {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: e,
                                  },
                                }));
                                submitHandler(id, e);
                                updateVehicleParams(id, e);
                              } else {
                                setInputs((prev: any) => ({
                                  ...prev,
                                  [index]: {
                                    cId: id,
                                    value: e,
                                  },
                                }));
                              }
                            }}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </ItemWrapper>
                  ))}
            </InputsList>
            <SubmitButton
              onClick={() => {
                submitHandler();
                close();
              }}
            >
              Продолжить
            </SubmitButton>
          </DescriptionBox>
        </CharacteristicsPopUpContainer>
      </CharacteristicsBG>
    </>
  );
};

export default CharacteristicsPopUp;
