import React, { memo, useEffect, useState } from "react";
import {
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  GoBackBtn,
  InputsWrapper,
  InputWrapper,
  MainWrapper,
  MapWrapper,
  RegisterForm,
  SearchRequisite,
  SelectWrapper,
  Title,
  Wrapper,
} from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/icons-search.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { ReactComponent as ArrowRightIcon } from "../../assets/icons/arrow-right.svg";
import { Button } from "../../components/form/styles";
import NumberFormat from "react-number-format";
import { ENDPOINT, GEOCODE_MAPS } from "../../constants/general";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { createSearchParams, useNavigate } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";

import { DatePicker } from "antd";
import moment from "moment";
import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { styles } from "../../components/styles/styles";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import AddManagerAndVehicle from "./addManagerAndVehicle";
import RegisterImg from "../../assets/images/register-img.png";
import { getCompany } from "../../redux/store/reducers/companySlice";

export const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  disabled,
}: any) => {
  return (
    <InputWrapper
      active={type !== "role" && (value || value?.length)}
      style={{ width: width ? width : "100%" }}
      className={type !== "role" && (value || value?.length) ? "active" : ""}
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            value ? (
              <NumberFormat
                name={name}
                value={value || ""}
                prefix={"+"}
                placeholder={placeholder}
                onChange={onChange}
                autoFocus
                disabled={disabled}
                maxLength={12}
                spellCheck={false}
              />
            ) : (
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                spellCheck={false}
              />
            )
          ) : type === "date" ? (
            <div className="date-input">
              <DatePicker
                format={"ll"}
                value={value ? moment(value) : null}
                onChange={(e, date) => {
                  onChange({
                    target: {
                      value: e?.format("YYYY-MM-DD"),
                      name,
                    },
                  });
                }}
                disabled={disabled}
              />
            </div>
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const RegisterAsOrganization: React.FC = () => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>({});
  const [step, setStep] = useState<number>(2);
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [errorMsgStep2, setErrorMsgStep2] = useState<any>({
    fullNameEmpty: false,
    phoneNumberEmpty: false,
    emailPattern: false,
    emailEmpty: false,
  });

  const [address, setAddress] = useState<any>({});

  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [zoom, setZoom] = useState(15);
  const user = useAppSelector((state) => state?.auth.user);
  const company = useAppSelector((state) => state.company);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getData, setGetData] = useState<boolean>(false);
  const [skipStepTwoBtn, setSkipTwoBtn] = useState({
    text: "",
    clicked: false,
  });
  const { data: managers } = useAppSelector((state) => state.managers);
  const vehiclesList = useAppSelector((state) => state.vehicle.vehicleList);
  const [addressProcess, makeAddressRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const { name, description } =
        res?.data?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject;
      setAddress({ name, description, coordinates: location });
      setFormData((prev: any) => ({
        ...prev,
        location: name,
      }));
    },
  });

  const getAddress = ([latitude, longitude]: any) => {
    if (latitude && longitude) {
      makeAddressRequest({
        method: "GET",
        url: GEOCODE_MAPS,
        params: {
          geocode: [longitude, latitude].join(","),
          lang: "ru_RU",
          apikey: "7446be07-f1bc-4ce3-a5fe-0a423e51a168",
          format: "json",
        },
      });
    }
  };

  const getUserLocation = (force?: boolean) => {
    window?.navigator?.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setCenter([latitude, longitude]);
        setLocation([latitude, longitude]);
        const empty = {};
        if (address === empty || force) {
          getAddress([latitude, longitude]);
        }
      },
      (err) => {},
    );
  };

  const changeFormData = (e: any) => {
    const { name, value } = e.target;

    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [searchRequisiteProcess, searchRequisiteRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const data: any = res?.data[0]?.data;

      setFormData((prev: any) => ({
        ...prev,
        full_name: data?.name?.full_with_opf,
        short_name: data?.name?.short_with_opf,
        inn: data?.inn,
        kpp: data?.kpp,
        full_name_representative: data?.management?.name,
        representative_position: data?.management?.post,
        ogrn: data?.ogrn,
        okpo: data?.okpo,
        legal_address: data?.address?.unrestricted_value,
        actual_address: data?.address?.value,
      }));
    },
    onError: () => {},
  });

  const searchRequisite = () => {
    setGetData(true);
    searchRequisiteRequest({
      url: `${ENDPOINT}/api/customers/find-by-inn?q=${formData[
        "inn_organization"
      ]
        .trim()
        .replaceAll(" ", "")}`,
      method: "POST",
    });
  };

  const inputListStep1 = [
    {
      label: "ИНН организации",
      name: "inn_organization",
      width: "246px",
      type: "inn",
    },
    {
      name: "search-btn",
    },
    {
      label: "Полное название",
      name: "full_name",
    },
    {
      label: "Краткое название",
      name: "short_name",
    },
    {
      label: "Юридический адрес",
      name: "legal_address",
    },
    {
      label: "Фактический адрес",
      name: "actual_address",
    },
    {
      label: "ИНН",
      name: "inn",
      width: "calc((100% - 12px) / 2)",
      type: "inn",
    },
    {
      label: "КПП",
      name: "kpp",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "ОГРН",
      name: "ogrn",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "ОКПО",
      name: "okpo",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "Должность представителя",
      name: "representative_position",
    },
    {
      label: "ФИО представителя",
      name: "full_name_representative",
    },
    {
      label: "Электронная почта",
      name: "email",
    },
  ];

  const inputListStep2 = [
    {
      // label: "ИНН организации",
      name: "role",
      width: "300px",
      type: "role",
    },
    {
      label: "ФИО сотрудника",
      name: "full_name",
      required: true,
    },
    {
      label: "Телефон",
      name: "phone_number",
      width: "calc((100% - 12px) / 2)",
      required: true,
      type: "tel",
    },
    {
      label: "Электронная почта",
      name: "email",
      width: "calc((100% - 12px) / 2)",
      required: true,
    },
    {
      label: "Примечание (видно только вам)",
      name: "note",
    },
  ];

  const inputListStep3 = [
    {
      label: "Название объекта",
      name: "name",
    },
    {
      label: "Местоположение",
      name: "location",
    },
    {
      label: "Дата начала работ",
      name: "start_date",
      width: "calc((100% - 12px) / 2)",
      // required: true,
      type: "date",
    },
    {
      label: "Дата завершения работ",
      name: "end_date",
      width: "calc((100% - 12px) / 2)",
      required: false,
      type: "date",
    },
  ];

  const [registerProcess, registerRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      setErrorMsg([]);
      user?.customer?.refId && dispatch(getCompany(user?.customer?.refId));
    },
    onError: (err: any) => {},
  });

  const registerCompany = () => {
    const inn_organization = formData?.inn_organization?.split(" ").join("");

    if (formData?.email && !formData?.email?.toLowerCase()?.match(emailRegEx)) {
      setErrorMsg(["Электронная почта недействительна."]);
    } else if (
      inn_organization.length === 10 ||
      inn_organization.length === 12
    ) {
      registerRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${user?.customer?.refId}`,
        data: {
          title: formData?.full_name,
          requisites: {
            companyName: formData?.full_name,
            kpp: formData?.kpp,
            inn: inn_organization,
            fullName: formData?.full_name,
            shortName: formData?.short_name,
            address: formData?.legal_address,
            actualAddress: formData?.actual_address,
            gendir: formData?.full_name_representative,
            jobTitle: formData?.representative_position,
            okpo: Number(formData?.okpo),

            email: formData?.email,
          },
        },
      });
    } else {
      setErrorMsg(["Неверный ИНН"]);
    }
  };

  const [addUserProcess, addUserRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      setStep(3);
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setErrorMsg([
          "Один из пользователей уже является менеджером в другой компании, либо пользователь уже зарегистрирован",
        ]);
      }
    },
  });

  useEffect(() => {
    if (company.companyData && company.getCompany === "succeeded") {
      if (!formData?.users?.length) {
        setFormData((prev: any) => ({
          ...prev,
          users: [{}],
        }));
      }

      setStep(2);
    }
  }, [company.companyData, company.getCompany]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getCompany(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  useEffect(() => {
    if (step === 3) {
      getUserLocation(true);
    }
  }, [step]);

  const addObject = () => {
    const { name, location: address, start_date, end_date } = formData;
    const objData = {
      nameEmpty: false,
      locationEmpty: false,
      startDateEmpty: false,
      endDateEmpty: false,
    };
    if (!name) {
      objData.nameEmpty = true;
    }
    if (!address) {
      objData.locationEmpty = true;
    }
    if (!start_date) {
      objData.startDateEmpty = true;
    }
    if (!end_date) {
      objData.endDateEmpty = true;
    }

    if (name && address && start_date && end_date) {
      navigate({
        pathname: "/dashboard/objects",
        search: `?${createSearchParams({
          name,
          address,
          latitude: location[0],
          longitude: location[1],
          start_date,
          end_date,
        })}`,
      });
    }
  };

  return (
    <MainWrapper
      footer={document.querySelector("#footer")?.clientHeight || 0}
      navbar={document.querySelector("#navbar")?.clientHeight || 0}
    >
      <div className="row2">
        <Wrapper half={step === 3}>
          <Title>
            Шаг {step + 1} из 3{" "}
            {step === 2 &&
              (managers?.users?.length > 0 && vehiclesList?.length > 0 ? (
                <GoBackBtn
                  style={{ margin: 0 }}
                  onClick={() => {
                    navigate("/profile");
                  }}
                >
                  <span>Вперед </span>
                  <ArrowRightIcon />
                </GoBackBtn>
              ) : (
                <GoBackBtn
                  style={{ margin: 0 }}
                  onClick={() => {
                    setSkipTwoBtn({
                      text: skipStepTwoBtn?.text,
                      clicked: true,
                    });
                    setTimeout(() => {
                      document.querySelector(".skip-wrapper")?.scrollIntoView({
                        behavior: "smooth",
                      });
                    }, 100);
                  }}
                >
                  <span>Пропустить этап</span>
                  <ArrowRightIcon />
                </GoBackBtn>
              ))}
          </Title>
          {step === 1 ? (
            <RegisterForm>
              <FormTitle>Реквизиты организации</FormTitle>
              <FormDesc>
                Введите ИНН организации, чтобы мы смогли заполнить данные
                автоматически
              </FormDesc>
              <InputsWrapper>
                {inputListStep1.map(({ label, name, width, type }) =>
                  name === "search-btn" ? (
                    <SearchRequisite onClick={searchRequisite}>
                      <SearchIcon />
                      Найти реквизиты
                    </SearchRequisite>
                  ) : name === "inn_organization" || name === "email" ? (
                    <InputBox
                      label={label}
                      width={width}
                      name={name}
                      onChange={changeFormData}
                      value={formData?.[name]}
                      type={type}
                    />
                  ) : (
                    getData && (
                      <InputBox
                        label={label}
                        width={width}
                        name={name}
                        onChange={changeFormData}
                        value={formData?.[name]}
                        type={type}
                      />
                    )
                  ),
                )}
                {errorMsg.length > 0 ? (
                  <ErrorsWrapper>
                    {errorMsg.map((msg: string) => (
                      <ErrorMsg>{msg}</ErrorMsg>
                    ))}
                  </ErrorsWrapper>
                ) : (
                  ""
                )}

                <Button
                  fit
                  _m0auto
                  disabled={
                    !(
                      searchRequisiteProcess?.success &&
                      searchRequisiteProcess?.data?.[0]?.data?.inn ===
                        formData?.inn_organization?.split(" ").join("")
                    )
                  }
                  onClick={() => {
                    if (!formData?.users?.length) {
                      setFormData((prev: any) => ({
                        ...prev,
                        users: [{}],
                      }));
                    }
                    registerCompany();
                  }}
                >
                  Продолжить
                </Button>
              </InputsWrapper>
            </RegisterForm>
          ) : step === 2 ? (
            <AddManagerAndVehicle
              skip={skipStepTwoBtn}
              setSkip={setSkipTwoBtn}
            />
          ) : step === 3 ? (
            <RegisterForm>
              <FormTitle>Добавить объект</FormTitle>
              <FormDesc>
                Укажите информацию, необходимую для создания объекта
              </FormDesc>
              <>
                <InputsWrapper>
                  {inputListStep3.map(
                    ({ label, name, width, type, required }, index) => (
                      <>
                        <InputBox
                          label={label}
                          width={width}
                          name={name}
                          onChange={(e: any) => changeFormData(e)}
                          value={formData?.[name]}
                          type={type}
                          required={required}
                        />
                      </>
                    ),
                  )}
                </InputsWrapper>
              </>

              {errorMsg.length > 0 ? (
                <ErrorsWrapper>
                  {errorMsg.map((msg: string) => (
                    <ErrorMsg>{msg}</ErrorMsg>
                  ))}
                </ErrorsWrapper>
              ) : (
                ""
              )}
              <div
                style={{
                  display: "flex",
                  marginTop: "16px",
                }}
              >
                <Button
                  onClick={() => {
                    navigate("/dashboard/objects");
                  }}
                  fit
                  _btn_bg_color
                >
                  Пропустить
                </Button>
                <Button
                  onClick={() => {
                    addObject();
                  }}
                  fit
                  _m0auto
                  disabled={
                    !(
                      formData.name &&
                      formData.location &&
                      formData.start_date &&
                      formData.end_date
                    )
                  }
                >
                  Продолжить
                </Button>
              </div>
            </RegisterForm>
          ) : (
            ""
          )}
        </Wrapper>
        {step === 3 ? (
          <MapWrapper>
            <YMaps>
              <Map
                width={"50vw"}
                height={"100%"}
                defaultState={{ center, zoom: zoom }}
                state={{ center, zoom: zoom }}
                onClick={(e: any) => {
                  const coords = e.get("coords");
                  setLocation(coords);
                  getAddress(coords);
                }}
              >
                {location?.length > 0 && (
                  <Placemark
                    options={{
                      preset: "islands#circleDotIcon",
                      iconColor: styles.mainColor,
                      draggable: true,
                    }}
                    geometry={{
                      type: "Point",
                      coordinates: location,
                    }}
                    onDragEnd={(e: any) => {
                      const coordinates = e.get("target").geometry._coordinates;
                      setLocation(coordinates);
                      getAddress(coordinates);
                    }}
                  />
                )}
                <ZoomControl
                  options={{
                    position: { right: 10, top: 20 },
                  }}
                />
              </Map>
            </YMaps>
          </MapWrapper>
        ) : null}
      </div>
      <div className="row3">
        <img src={RegisterImg} alt="" />
      </div>
    </MainWrapper>
  );
};

export default memo(RegisterAsOrganization);
