import styled from "styled-components";

export const InputsWrapper = styled.div`
  margin: 0 0 10px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DFlex = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 24px 0;
`;

export const InputWrapper = styled.div`
  width: 48%;
`;

export const Desc = styled.div`
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
`;
