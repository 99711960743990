export const ConsoleLogo = `\n
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@((((((((((((((((%@@@@@@@@@@@@@@@@@@@@
@@@((((((((((((((((((@@@@@@@@@@@@@@@@@@@
@@#((((((@@@@@@((((((#@@@@@@@@@@@@@@@@@@
@@#((((((@@@@@@((((((#@////////.,,,,,,,#
@@((((((@@@@@@(((((((///////((,,,,,,,&@@
@&((((((((((((((((//////////,,,,,,,@@@@@
@(((((((((((((((((///////*,,,,,,,,@@@@@@
@(((((((@@@((((((((///////#/,,,,,,,@@@@@
@(((((((@@@&(((((((%////////,,,,,,,,@@@@
(((((((&@@@@#((((((((///////((,,,,,,,%@@
(((((((&@@@@@@(((((((#(///////@,,,,,,,@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
@@@@@@@@@@@@@<ver. 0.1.1>@@@@@@@@@@@@@@@
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
`;

