import styled from "styled-components";

export const Wrapper = styled.div<{ open: boolean }>`
  height: 100vh;
  width: 477px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 51px;
  padding: 24px;
  position: absolute;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-477px")};
  transition: all 0.25s ease-in;
  z-index: 100;
  overflow: auto;
  /* ${({ open }) =>
    open
      ? "animation: open 0.3s ease-in"
      : "animation: close 0.3s ease-out"}; */

  @keyframes wrapperOpen {
    from {
      right: -100%;
    }
    to {
      right: 0%;
    }
  }

  @keyframes wrapperClose {
    from {
      right: 0%;
    }
    to {
      right: -100%;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;

  svg {
    cursor: pointer;
  }
`;

export const WrapperBackground = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(2px);
  border: solid 1px #979797;
  background-color: #222222b3;
  position: fixed;
  top: 0;
  right: 0;
  opacity: ${({ open }) => (open ? "100%" : "0%")};
  transition: opacity 0.25s ease-in;
  z-index: ${({ open }) => (open ? "4" : "-1")};
  ${({ open }) =>
    open ? "animation: open 0.3s ease-in" : "animation: close 0.3s ease-out"};

  @keyframes open {
    from {
      /* z-index: -1; */
      background-color: #22222200;
    }
    to {
      /* z-index: 3; */
      background-color: #222222b3;
    }
  }

  @keyframes close {
    from {
      /* z-index: 3; */
      background-color: #22222200;
    }
    to {
      /* z-index: -1; */
      background-color: #222222b3;
    }
  }
`;

export const StatusCardsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StatusCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background: #f7f7f7;
  width: 100%;
  border-radius: 10px;

  .top {
    display: flex;
    align-items: center;
    gap: 8px;

    .titleGroup {
      display: flex;
      flex-direction: column;
      font-size: 15px;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;

      .subTitle {
        font-size: 12px;
        line-height: 1;
        color: #757575;
      }
    }
  }

  .bottom {
    font-size: 14px;
    line-height: 1.14;
    letter-spacing: -0.2px;
    color: #222222;
  }
`;

export const StatusTrackWrapper = styled.div`
  position: relative;

  .scrollContainer {
    scroll-snap-type: x mandatory;
    scroll-padding-left: 16px;
    display: grid;
    grid-template-columns: 225px;
    gap: 25px;
    grid-auto-flow: column;
    margin: 0 -16px;
    padding-left: 16px;
    overflow-y: hidden;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    overscroll-behavior: none;
    &::-webkit-scrollbar {
      display: none;
    }
    align-items: flex-start;
    height: fit-content;
    scroll-behavior: smooth;
  }

  .fadeLeft,
  .fadeRight {
    width: 25px;
    height: 92px;
    position: absolute;
    top: 0;
  }

  .fadeLeft {
    left: -16px;
    background-image: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      #fff 50%
    );
  }

  .fadeRight {
    right: -16px;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      #fff 50%
    );
    width: 40px;
  }

  .status {
    display: flex;
    flex-direction: column;
    gap: 13px;
    width: max-content;
    white-space: nowrap;
    min-width: 225px;
    scroll-snap-align: start;
    position: relative;

    &:last-of-type {
      margin-right: 16px;
    }

    .statusTitle {
      font-size: 15px;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
    }
    .statusIcon {
      width: 28px;
      height: 24px;
      padding: 0 2px;
      background: white;
      z-index: 2;
      border-radius: 50%;

      &.grayed {
        filter: grayscale(100%);
      }
    }

    .statusDate {
      font-size: 12px;
      line-height: 1;
      color: #a3b2ab;
      margin-top: -7px;
    }
  }

  .progressTrackContainer {
    width: calc(100% + 25px);
    height: 6px;
    background: #f7f7f7;
    position: absolute;
    top: 38px;
    left: 0px;

    .progressTrack {
      background: #21c17a;
      height: 100%;
      width: 15%;
      border-radius: 3px;
      transition: all 0.5s linear;
    }
  }
`;

