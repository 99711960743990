import { Empty } from "antd";
import React from "react";
import { DataPart, Wrapper } from "./styles";

const EmptyDashboardTable: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Wrapper>
      <DataPart>
        <Empty description={text} />
      </DataPart>
    </Wrapper>
  );
};

export default EmptyDashboardTable;
