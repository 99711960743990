import React, { memo, useEffect, useRef, useState } from "react";
import { Wrapper, Input, Select } from "./styles";
import moment from "moment";

const OptionsList: number[] = [];

(() => {
  for (let i = 6; i <= 24; i += 0.5) {
    OptionsList.push(i);
  }
})();

interface TimePickerProps {
  time: moment.Moment | null;
  setTime: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  date: moment.Moment | null;
  setDate: React.Dispatch<React.SetStateAction<moment.Moment | null>>;
  disableBefore?: number;
}

const convertNumberToTime = (num: number) => {
  if (num % 1 != 0) {
    return `${num - 0.5}:30`;
  } else {
    return `${num}:00`;
  }
};

const convertTimeToNumber = (time: moment.Moment) => {
  if (time.minutes() > 30) {
    return Number(`${time.hours()}`);
  } else {
    return Number(`${time.hours()}.5`);
  }
};

const TimePicker: React.FC<TimePickerProps> = ({
  time,
  setTime,
  date,
  setDate,
  disableBefore,
}) => {
  const [paddingTop, setPaddingTop] = useState<number>(2);
  const [touchPosition, setTouchPosition] =
    useState<React.TouchEvent<HTMLDivElement> | null>(null);
  const [currentTime, setCurrentTime] = useState<number>(
    time ? convertTimeToNumber(time) : 0,
  );
  const timeContainersRefs = React.useRef<any[]>([]);

  const addTimeToRefsArr = (elementRef: any) => {
    if (!timeContainersRefs.current.includes(elementRef)) {
      timeContainersRefs.current.push(elementRef);
    }
  };

  const scrollHandler = (e: boolean) => {
    if (e) {
      currentTime < 24 && setCurrentTime(currentTime + 0.5);
    } else {
      currentTime > 6 && setCurrentTime(currentTime - 0.5);
    }
  };

  const clickHandler = (option: number) => {
    const newTime = (date ? date : moment())
      ?.set("hour", Math.floor(option))
      .set("minute", option % 1 != 0 ? 30 : 0);
    setTime(newTime);
    setCurrentTime(option);
  };

  const getElementClassName = (element: number) => {
    if (convertNumberToTime(element) === convertNumberToTime(currentTime)) {
      return "selectItem active";
    } else if (
      element < 8 ||
      element > 20 ||
      (disableBefore && element < disableBefore)
    ) {
      return "selectItem dimmed";
    } else {
      return "selectItem";
    }
  };

  const renderOptions = () => {
    const list: any[] = [];
    OptionsList.map((option) => {
      list.push(
        <div
          ref={addTimeToRefsArr}
          id={`time_${option}`}
          key={`time_${option}`}
          onMouseDown={() => {
            clickHandler(option);
          }}
          className={getElementClassName(option)}
        >
          {convertNumberToTime(option)}
        </div>,
      );
    });
    return list;
  };

  const touchMoveHandler = (e: React.TouchEvent<HTMLDivElement>) => {
    const minMoveDelta = 84;
    const maxMoveDelta = 128;

    if (touchPosition) {
      const touchPisitionDiff =
        e.touches[0].clientY - touchPosition.touches[0].clientY;
      const up = Math.sign(touchPisitionDiff) * -1 > 0;
      const canChangePosition =
        Math.abs(touchPisitionDiff) > minMoveDelta &&
        Math.abs(touchPisitionDiff) < maxMoveDelta;
      if (canChangePosition) {
        if (up) {
          scrollHandler(true);
          setTouchPosition(e);
        } else {
          scrollHandler(false);
          setTouchPosition(e);
        }
      }
    } else {
      setTouchPosition(e);
    }
  };

  useEffect(() => {
    const timeElementPosition = OptionsList.indexOf(currentTime);
    setPaddingTop(timeElementPosition - 2);
  }, [currentTime]);

  useEffect(() => {
    if (currentTime < 6 && currentTime !== 0) {
      setCurrentTime(6);
    }
    if (currentTime > 24) {
      setCurrentTime(24);
    }
  }, [currentTime]);

  useEffect(() => {
    if (time !== null) {
      setCurrentTime(convertTimeToNumber(time));
    } else setCurrentTime(0);
  }, [time]);

  return (
    <Wrapper topmargin={paddingTop}>
      <style>{`html {
        overflow: hidden !important;
        overscroll-behavior: none !important;
      }`}</style>
      <Input
        value={currentTime !== 0 ? convertNumberToTime(currentTime) : ""}
        inputMode="none"
        onBlur={() => clickHandler(currentTime)}
        autoComplete="off"
        placeholder="Выберите дату"
        disabled={currentTime === 0}
      />

      <div
        className="select"
        onWheel={(e) => {
          scrollHandler(e.deltaY > 0);
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          setTouchPosition(e);
        }}
        onTouchMove={touchMoveHandler}
      >
        <div className="marginContainer">{renderOptions()}</div>
      </div>
      <div
        className="marker"
        onWheel={(e) => {
          scrollHandler(e.deltaY > 0);
        }}
        onTouchStart={(e) => {
          e.stopPropagation();
          setTouchPosition(e);
        }}
        onTouchMove={touchMoveHandler}
      ></div>
    </Wrapper>
  );
};

export default memo(TimePicker);

