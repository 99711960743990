import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

const getCards = (id: string) => ({
  type: actionTypes.GET_CARDS,
  payload: httpRequest({
    method: "GET",
    url: `${ENDPOINT}/pay/${id}/cards`,
  }),
});

export default getCards;
