import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ENDPOINT } from "../../../constants/general";
import { httpRequest } from "../../../utils/httpRequest";
import { ICustomer, IUserView } from "../../../types";

type Loading = "idle" | "pending" | "succeeded" | "failed";

export const createTelegramAuthSession = createAsyncThunk(
  "auth/createTelegramAuthSession",
  async () => {
    try {
      const response = await httpRequest({
        method: "POST",
        path: `sms-auth/telegram`,
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getTelegramAuthToken = createAsyncThunk(
  "auth/getTelegramAuthToken",
  async ({ sessionId }: { sessionId: string }) => {
    try {
      const response = await httpRequest({
        method: "GET",
        path: `sms-auth/telegram/${sessionId}`,
      });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getUser = createAsyncThunk(
  "auth/getUser",
  async ({ refId, id }: { refId?: string; id?: number }) => {
    try {
      const response = await httpRequest({
        method: "GET",
        path: `customers/public`,
        params: {
          id,
          refId,
        },
      });

      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getMe = createAsyncThunk("auth/getMe", async (refId: string) => {
  try {
    const response = await httpRequest({
      method: "GET",
      path: `customers/${refId}`,
    });

    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const fetchRegistration = createAsyncThunk(
  "auth/fetchRegistration",
  async ({
    data,
    navigate,
    dispatch,
    onSuccess,
  }: {
    data: any;
    navigate: any;
    dispatch?: any;
    onSuccess?: any;
  }) => {
    try {
      const response = (await httpRequest({
        path: "customers/create",
        method: "POST",
        data: data,
      })) as any;
      if (data?.customer?.role === "customer") {
        navigate("/create-order");
      } else if (data?.customer?.role === "worker") {
      }
      const refId = response?.data?.data?.id;
      onSuccess?.(refId);

      return response.data;
    } catch (err) {}
  },
);

export const fetchOrgRegistration = createAsyncThunk(
  "auth/fetchOrgRegistration",
  async ({
    data,
    refId,
    navigate,
    onSuccess,
  }: {
    data: any;
    refId?: string;
    navigate: any;
    onSuccess?: any;
  }) => {
    try {
      let response: any;
      if (refId) {
        response = (await httpRequest({
          url: `${ENDPOINT}/companies/${refId}`,
          method: "POST",
          data,
        })) as any;
      } else {
        response = (await httpRequest({
          url: `${ENDPOINT}/companies`,
          method: "POST",
          data,
        })) as any;
      }
      onSuccess?.();
      return response.data;
    } catch (err) {}
  },
);

export const getRefIDFromFireBase = createAsyncThunk(
  "auth/getRefIDFromFireBase",
  async (
    {
      uid,
      role,
      navigate,
    }: { uid: string | null; role?: "customer" | "worker"; navigate?: any },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      path: "customers/exist/by-uid",
      params: { uid, role },
    });
    if (response.data !== "") {
      await thunkApi.dispatch(getMe(response.data.refId));
      localStorage.removeItem("hideRolePicker");
    }
    return response.data;
  },
);

export const getData = createAsyncThunk(
  "auth/getData",
  async (uid: string | null, thunkApi: any) => {
    const { dispatch } = thunkApi;
    await dispatch(getRefIDFromFireBase({ uid }));
  },
);

export const fetchAuthCall = createAsyncThunk(
  "auth/fetchAuthCall",
  async (phoneNumber: string) => {
    const response = await httpRequest({
      method: "POST",
      path: `sms-auth/call?phone=${phoneNumber}`,
    });

    return response.data;
  },
);

export const fetchAuthSms = createAsyncThunk(
  "auth/fetchAuthSms",
  async (phoneNumber: string, thunkApi: any) => {
    const response = await httpRequest({
      method: "POST",
      path: `sms-auth/sms?phone=${phoneNumber}&length=6`,
    });

    return { data: response.data, phoneNumber };
  },
);

export const fetchAuthVerify = createAsyncThunk(
  "auth/fetchAuthVerify",
  async (
    {
      code,
      navigate,
      role,
    }: { code: string; navigate: any; role?: "worker" | "customer" },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `sms-auth/verify?code=${code}`,
    });
    if (response) {
      if (response.data.token !== null) {
        localStorage.setItem("accessToken", response.data.token);
        thunkApi.dispatch(authActions.setAccessToken(response.data.token));
      }

      await thunkApi.dispatch(
        getRefIDFromFireBase({
          uid: response.data.token,
          navigate,
          role,
        }),
      );
      navigate(role === "customer" ? "/create-order" : "/search-order");
    }

    return response.data;
  },
);

export const fetchAlternativeLogout = createAsyncThunk(
  "auth/fetchAlternativeLogout",
  async (
    { phoneNumber, navigate }: { phoneNumber: string; navigate: any },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `sms-auth/logout?phone=${phoneNumber}`,
    });

    localStorage.removeItem("accessToken");
    navigate("/login");

    return response.data;
  },
);

export const fetchAuthLogout = createAsyncThunk(
  "auth/fetchAuthLogout",
  async (phoneNumber: string) => {
    const response = await httpRequest({
      method: "POST",
      path: `sms-auth/logout?phone=${phoneNumber}`,
    });

    return response.data;
  },
);

// SMS RU END

interface IAuthSlice {
  user: {
    customer: ICustomer;
    referrals?: ICustomer[];
    activations?: [];
    parent?: string;
    ordersTotal?: number;
    reward?: {
      total: number;
      totalPaid?: number;
      transactionsCount: number;
    };
  } | null;
  fireBaseData: any;
  loading: Loading;
  userLoading: Loading;
  error: string | null;
  loadingReg: Loading;
  loadingRef: Loading;
  altAuth: any | null;
  userId: any | null;
  loadingAltAuth: Loading;
  phoneNumber: string | null;
  alternativeAuth: boolean;
  isCall: true;
  verifyFlag: boolean;
  userView: IUserView | null;
  accessToken: string | null;
  initChatData: {
    secondMemberId: number;
    title: string;
    order_id: number;
  } | null;
}

const initialState: IAuthSlice = {
  userLoading: "idle",
  user: null,
  fireBaseData: null,
  loading: "idle",
  error: null,
  loadingReg: "idle",
  loadingRef: "pending",
  altAuth: null,
  userId: null,
  loadingAltAuth: "idle",
  phoneNumber: null,
  alternativeAuth: true,
  isCall: true,
  verifyFlag: false,
  userView: null,
  accessToken: null,
  initChatData: null,
};

// auth store

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuth(state) {
      state.user = null;
      state.fireBaseData = null;
      state.loading = "idle";
      state.error = null;
      state.loadingReg = "idle";
      state.loadingRef = "pending";
      state.altAuth = null;
      state.userId = null;
      state.loadingAltAuth = "idle";
      state.phoneNumber = null;
      state.alternativeAuth = true;
      state.isCall = true;
      state.verifyFlag = false;
    },
    setUserPhoneNumber(state, action) {
      state.phoneNumber = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setInitChatData(
      state,
      action: PayloadAction<{
        secondMemberId: number;
        title: string;
        order_id: number;
      } | null>,
    ) {
      state.initChatData = action.payload;
    },
    setAlternativeAuth(state, action) {
      state.alternativeAuth = action.payload;
    },
    setCall(state, action) {
      state.isCall = action.payload;
    },
    setVerifyFlag(state, action) {
      state.verifyFlag = action.payload;
    },
    clearUserView(state) {
      state.userView = null;
    },
    incrementOrdersTotal(state) {
      if (state.user?.ordersTotal) {
        state.user.ordersTotal += 1;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        state.userView = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getUser.pending, (state) => {
        state.error = null;
        state.loading = "pending";
      })
      .addCase(getUser.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loading = "failed";
        state.userView = null;
      });
    builder
      .addCase(getMe.fulfilled, (state, action) => {
        state.user = {
          ...action.payload,
          customer: {
            ...action.payload.customer,
            accessSettings: action.payload.customer.companyAccessSettings
              ? JSON.parse(action.payload.customer.companyAccessSettings)
              : null,
          },
        };
        state.userLoading = "succeeded";
      })
      .addCase(getMe.pending, (state) => {
        state.error = null;
        state.userLoading = "pending";
      })
      .addCase(getMe.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.userLoading = "failed";
        state.user = null;
      });
    builder
      .addCase(getRefIDFromFireBase.fulfilled, (state, action) => {
        state.fireBaseData = action.payload;
        state.loadingRef = "succeeded";
      })
      .addCase(getRefIDFromFireBase.pending, (state) => {
        state.error = null;
        state.loadingRef = "pending";
      })
      .addCase(getRefIDFromFireBase.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingRef = "failed";
        state.fireBaseData = null;
      });
    builder
      .addCase(fetchRegistration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingReg = "succeeded";
      })
      .addCase(fetchRegistration.pending, (state) => {
        state.error = null;
        state.loadingReg = "pending";
      })
      .addCase(fetchRegistration.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingReg = "failed";
      });
    builder
      .addCase(fetchOrgRegistration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingReg = "succeeded";
      })
      .addCase(fetchOrgRegistration.pending, (state) => {
        state.error = null;
        state.loadingReg = "pending";
      })
      .addCase(fetchOrgRegistration.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingReg = "failed";
      });
    builder
      .addCase(fetchAuthCall.fulfilled, (state, action) => {
        state.error = null;
        state.loadingAltAuth = "succeeded";
        state.userId = action.payload;
      })
      .addCase(fetchAuthCall.pending, (state) => {
        state.error = null;
        state.loadingAltAuth = "pending";
      })
      .addCase(fetchAuthCall.rejected, (state, action) => {
        state.error = "Внимание какая то ошибка";
        state.loadingAltAuth = "failed";
        state.userId = null;
      });
    builder
      .addCase(fetchAuthSms.fulfilled, (state, action) => {
        state.error = null;
        state.loadingAltAuth = "succeeded";
        state.userId = action.payload.data;
        state.phoneNumber = action.payload.phoneNumber;
      })
      .addCase(fetchAuthSms.pending, (state) => {
        state.error = null;
        state.loadingAltAuth = "pending";
      })
      .addCase(fetchAuthSms.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingAltAuth = "failed";
        state.userId = null;
      });
    builder
      .addCase(fetchAuthVerify.fulfilled, (state, action) => {
        state.error = null;
        state.loadingAltAuth = "succeeded";
        state.altAuth = action.payload;
      })
      .addCase(fetchAuthVerify.pending, (state) => {
        state.error = null;
        state.loadingAltAuth = "pending";
      })
      .addCase(fetchAuthVerify.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingAltAuth = "failed";
      });
  },
});

export const { actions: authActions } = authSlice;

export default authSlice.reducer;
