import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ENDPOINT } from "../../../constants/general";
import { httpRequest } from "../../../utils/httpRequest";
import { LoadingType, Notification, NotificationArea } from "../../../types";

export const editWorkerNotificationPoint = createAsyncThunk(
  "notifications/editWorkerNotificationPoint",
  async (
    {
      refId,
      latitude,
      longitude,
      radius,
      enabled,
      pointId,
      title,
    }: {
      refId: string;
      latitude: number;
      longitude: number;
      radius: number;
      enabled: boolean;
      pointId: number;
      title: string;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "PATCH",
      path: `customers/${refId}/my-worker-notif-points/${pointId}`,
      data: {
        latitude,
        longitude,
        radius,
        enabled,
        title,
      },
    });

    thunkApi.dispatch(getWorkerNotificationPoints({ refId }));

    return response.data;
  },
);

export const createWorkerNotificationPoint = createAsyncThunk(
  "notifications/createWorkerNotificationPoint",
  async (
    {
      refId,
      latitude,
      longitude,
      radius,
      enabled,
    }: {
      refId: string;
      latitude: number;
      longitude: number;
      radius: number;
      enabled: boolean;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `customers/${refId}/my-worker-notif-points/new`,
      data: {
        latitude,
        longitude,
        radius,
        enabled,
      },
    });

    thunkApi.dispatch(getWorkerNotificationPoints({ refId }));

    return response.data;
  },
);

export const deleteWorkerNotificationPoint = createAsyncThunk(
  "notifications/createWorkerNotificationPoint",
  async (
    {
      refId,
      pointId,
    }: {
      refId: string;
      pointId: number;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `customers/${refId}/my-worker-notif-points/remove/${pointId}`,
    });

    return response.data;
  },
);

export const getWorkerNotificationPoints = createAsyncThunk(
  "notifications/getWorkerNotificationPoints",
  async (
    {
      refId,
    }: {
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      path: `customers/${refId}/my-worker-notif-points`,
    });

    return response.data;
  },
);

export const getNotifications = createAsyncThunk(
  "notifications/getNotifications",
  async (
    { refId, take, skip }: { refId: string; take: number; skip: number },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      path: `customers/${refId}/my-notifications`,
      params: {
        take,
        skip,
      },
    });

    return response.data;
  },
);

export const getUnreadNotifications = createAsyncThunk(
  "notifications/getUnreadNotifications",
  async ({ refId }: { refId: string }, thunkApi: any) => {
    const response = await httpRequest({
      method: "GET",
      path: `customers/${refId}/my-notifications/new`,
    });

    return response.data;
  },
);

export const getNotificationsCount = createAsyncThunk(
  "notifications/getNotificationsCount",
  async ({ refId }: { refId: string }, thunkApi: any) => {
    const response = await httpRequest({
      method: "GET",
      path: `customers/${refId}/my-notifications/count`,
    });

    return response.data;
  },
);

interface NotificationsSlice {
  notifications: Notification[];
  notificationAreas: NotificationArea[];
  notificationsCount: {
    all: number;
    not_read: number;
  };
  getNotificationsProcess: LoadingType;
}

const initialState: NotificationsSlice = {
  notifications: [],
  notificationAreas: [],
  notificationsCount: {
    all: 0,
    not_read: 0,
  },
  getNotificationsProcess: LoadingType.Idle,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getNotifications
    builder
      .addCase(getNotifications.pending, (state) => {
        state.getNotificationsProcess = LoadingType.Pending;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.notifications = action.payload;
        state.getNotificationsProcess = LoadingType.Succeeded;
      })
      .addCase(getNotifications.rejected, (state) => {
        state.getNotificationsProcess = LoadingType.Failed;
      });

    //getNotificationsCount
    builder
      .addCase(getNotificationsCount.pending, (state) => {})
      .addCase(getNotificationsCount.fulfilled, (state, action) => {
        state.notificationsCount = action.payload;
      })
      .addCase(getNotificationsCount.rejected, (state) => {});

    //getWorkerNotificationPoints
    builder
      .addCase(getWorkerNotificationPoints.pending, (state) => {})
      .addCase(getWorkerNotificationPoints.fulfilled, (state, action) => {
        state.notificationAreas = action.payload;
      })
      .addCase(getWorkerNotificationPoints.rejected, (state) => {});
  },
});

export default notificationsSlice.reducer;
