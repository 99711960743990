import React, { memo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { signOut } from "firebase/auth";
import { authentication } from "../../firebase/config";

type Props = {
  path?: string;
  children: any;
};

const PrivateRoute = ({ children }: Props) => {
  const token = () => localStorage.getItem("accessToken");
  const path = useLocation();

  if (
    (path.pathname.includes("/o/") || path.pathname.includes("/my-orders/")) &&
    !token()
  ) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  if (!token()) {
    signOut(authentication).then(() => {
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("uid");
    });
    if (path.pathname === "/") {
      return <Navigate to="/create-order" replace={true} />;
    } else {
      return <Navigate to="/login" replace={true} />;
    }
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export default memo(PrivateRoute);
