import React, { memo, useCallback, useState } from "react";
import { Button } from "../../components/form/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/icons/user-avatar-48-clean-contractor.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icons-expand-more.svg";
import { ReactComponent as VehicleIcon } from "../../assets/icons/icons-vehicles-hoist.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add-white.svg";
import {
  Text,
  ContentPart,
  SelectWrapper,
  Wrapper,
  SelectBody,
  Title,
  AddButton,
} from "./styles";
import avatar_worker from "../../assets/images/avatar-worker.png";
import { useAppSelector } from "../../redux/hooks";
import SingleManagerPopUp from "../popUp/singleManagerPopUp";
import { ENDPOINT_FOR_IMG } from "../../constants/general";
import SingleVehiclePopUp from "../popUp/singleVehiclePopUp";

interface IProps {
  employees?: boolean;
  vehicles?: boolean;
}

interface IListItem {
  title: string;
  label: string;
  name: string;
}

export const ManagerRow = ({
  value: {
    id, //23054
    model, //"Komatsu"
    ownerId, //8569
    vehicleId, //null
    orderId, //null
    categoryId, //271
    brand, //"Volvo"
    address, //null
    comment, //"test"
    createdAt, //"2023-02-22T12:41:31.000Z"
    updatedAt, //"2023-02-22T12:41:31.000Z"
    attachments, //null
    stateNumber, //"3131223"
    photoState, //null
    rejectPhotoReason, //null
    passportState, //null
    rejectPassportReason, //null
    defaultParameter, //null
    height, //null
    typeArrow, //null
    typeChassis, //null
    loadCapacity, //null
    loadCapacitySide, //null
    loadCapacityArrow, //null
    arrowReach, //null
    numberAxles, //null
    volume, //null
    equipments, //null
    length, //null
    productionYear, //null
    weight, //null
    width,
  },
  index,
}: any) => {
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );
  const [isOpen, setIsOpen] = useState(false);
  const vCategory = vehicleCategories.find(({ id }) => id === categoryId);
  return (
    <>
      <SelectWrapper
        key={index}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="selectGroup">
          <div className="icon">
            {/* {employees ?  */}
            {/* <UserIcon /> */}
            {/* :  */}
            {vCategory?.icon ? (
              <img src={`${ENDPOINT_FOR_IMG}/${vCategory?.icon}`} alt="" />
            ) : (
              <img
                src={`${ENDPOINT_FOR_IMG}/assets/images/excavator.png`}
                alt=""
              />
            )}
            {/* } */}
          </div>

          <SelectBody>
            <label>{productionYear} год выпуска</label>
            <div className="text">{`${vCategory?.title || ""} ${model || ""} ${
              brand || ""
            }${stateNumber ? ", " + stateNumber : ""}`}</div>
            {/* <select name="sorts" defaultValue="" id="sorts">
                  <option value="" disabled hidden>
                    <img src={avatar_worker} alt="icons" /> {name}
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select> */}
          </SelectBody>
        </div>
        <div className="arrowIcon">
          <ArrowIcon />
        </div>
      </SelectWrapper>
      {isOpen && (
        <SingleVehiclePopUp
          id={id}
          close={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

const VehicleManagementPagesDataList: React.FC<IProps> = ({
  employees,
  vehicles,
}) => {
  const vehicleList = useAppSelector((state) => state.vehicle.vehicleList);

  const renderList = useCallback(
    (listData: IListItem[]) => {
      return listData.map((value, index) => {
        return <ManagerRow value={value} index={index} />;
      });
    },
    [vehicleList],
  );

  const navigate = useNavigate();

  return (
    <Wrapper>
      <Text>
        <div className="text">
          {employees ? "Список сотрудников" : "Список техники"}
        </div>
        <AddButton onClick={() => navigate("/create-vehicle")}>
          <PlusIcon />
          <div className="text">Добавить</div>
        </AddButton>
      </Text>

      <ContentPart>{vehicleList && renderList(vehicleList)}</ContentPart>
    </Wrapper>
  );
};

export default memo(VehicleManagementPagesDataList);
