import styled from "styled-components";
import { Button } from "../form/styles";

export const MainWrapper = styled.div`
  width: 100%;
  min-height: 500px;
  height: 90vh;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 137px);
  background-color: white;
  display: flex;
  margin: auto;
`;

export const Half = styled.div<{ imgPart?: boolean }>`
  width: 50%;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
    ${({ imgPart }) => (imgPart ? "display: none;" : "")}
  }
`;

export const Box = styled.div`
  width: 375px;
  height: fit-content;
  margin: auto;
`;

export const Title = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -0.5px;
  color: #000;
`;
export const Desc = styled.div`
  margin: 18px 0 24px;
  font-size: 20px;
  line-height: 1.2;
  color: #000;
`;
export const Btn = styled(Button)``;
