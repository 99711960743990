import styled from "styled-components";
import { Button } from "../../form/styles";
import { Container, styles } from "../../styles/styles";

export const Wrapper = styled(Container)`
  padding: 40px 0;
  /* display: flex; */
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.83;
  letter-spacing: -0.1px;
  margin-bottom: 34px;
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  width: 100%;
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 18px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px 12px;
`;

export const InputWrapper = styled.label<{ active?: boolean; role?: any }>`
  width: 100%;
  background-color: white;
  padding: ${({ role }) => (role ? "0" : "12px 16px")};
  border-radius: 10px;
  border: 1px solid
    ${({ active, role }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : role
        ? "transparent"
        : "var(--color-light-gray)"};
  display: block;
  position: relative;

  /* span {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;

      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    input {
      height: 16px;
    }
  } */

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  :not(.date-input) input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
    overflow: hidden;
  }

  .ant-picker {
    padding: 0;
    height: 100%;
    width: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 16px;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;
      }
    }
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  height: fit-content;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const SelectWrapper = styled.div`
  /* width: 300px; */
  height: 48px;
  /* margin-bottom: 24px; */
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  padding: 12px 8px;
  position: relative;
  justify-content: space-between;
  align-items: center;

  .selectGroup {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .arrowIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: flex-end;
  }

  img {
    width: 24px;
    height: 24px;
  }
  select {
    width: 100%;
    border: none;
    padding: 12px 8px;
    font-size: 15px;
    line-height: 1.07;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.25px;
  }

  @media only screen and (max-width: 320px) {
    width: 275px;
  }
  @media only screen and (max-width: 480px) {
    width: 430px;
  }
`;

export const SelectOptionsWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  z-index: 2;
  border-radius: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);

  .option {
    width: 100%;
    padding: 6px 8px;
  }
`;

// export const SelectWrapper = styled.div`
//   display: flex;
//   justify-content: start;
//   align-items: center;

//   svg {
//     width: 24px;
//     height: 24px;
//     display: flex;
//   }

//   select {
//     border: 0;
//     width: calc(100% - 36px);
//     line-height: 16px;
//     font-size: 15px;
//     outline: 0;
//   }

//   p {
//     margin-left: 12px;
//     font-weight: 600;
//   }
// `;

// export const SelectHeader = styled.div``;

// export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 12px 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;

export const AddBtn = styled(Button)`
  margin: 0 auto 0 0;
  width: 48%;
  padding: 18px;
`;
