const actionTypes = {
  GET_ADDRESSES: "GET_ADDRESSES",
  GET_POSITION: "GET_POSITION",
  GET_ME: "GET_ME",
  GET_REFERRALS: "GET_REFERRALS",
  GET_REWARD: "GET_REWARD",
  GET_LINK: "GET_LINK",
  GET_PASSPORT: "GET_PASSPORT",
  GET_REGISTRATIONS: "GET_REGISTRATIONS",
  GET_PARTNERS_HISTORY: "GET_PARTNERS_HISTORY",
  GET_CARDS: "GET_CARDS",
  ADD_NEW_CARD: "ADD_NEW_CARD",
  REMOVE_CARD: "REMOVE_CARD",
  GET_CHATS: "GET_CHATS",
  GET_CHAT: "GET_CHAT",
  GET_PAYOUT_HISTORY: "GET_PAYOUT_HISTORY",
  GET_VEHICLE_CATEGORIES: "GET_VEHICLE_CATEGORIES",
  GET_VEHICLE_LIST: "GET_VEHICLE_LIST",
  GET_MANAGERS: "GET_MANAGERS",
  GET_MANAGER_DATA: "GET_MANAGER_DATA",
  SEARCH_BY_TAG: "SEARCH_BY_TAG",
  ADD_VEHICLE: "ADD_VEHICLE",
  GET_VEHICLE_BY_ID: "GET_VEHICLE_BY_ID",
  GET_COMPANY: "GET_COMPANY",
  CREATE_COMPANY: "CREATE_COMPANY",
  GET_DASHBOARD_ORDERS: "GET_DASHBOARD_ORDERS",
  GET_DASHBOARD_SINGLE_ORDER: "GET_DASHBOARD_SINGLE_ORDER",
  GET_DASHBOARD_OBJECTS: "GET_DASHBOARD_OBJECTS",
  GET_DASHBOARD_SINGLE_OBJECT: "GET_DASHBOARD_SINGLE_OBJECT",
  GET_FEEDBACK_RATING: "GET_FEEDBACK_RATING",
  REMOVE_VEHICLE_BY_ID: "REMOVE_VEHICLE_BY_ID",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",

  GET_VEHICLE_IMAGES: "GET_VEHICLE_IMAGES",
  UPLOAD_VEHICLE_IMAGES: "UPLOAD_VEHICLE_IMAGES",
  REMOVE_VEHICLE_IMAGES: "REMOVE_VEHICLE_IMAGES",

  GET_PASSPORT_IMAGES: "GET_PASSPORT_IMAGES",
  UPLOAD_PASSPORT_IMAGES: "UPLOAD_PASSPORT_IMAGES",
  REMOVE_PASSPORT_IMAGES: "REMOVE_PASSPORT_IMAGES",

  _PENDING: "_PENDING",
  _FULFILLED: "_FULFILLED",
  _REJECTED: "_REJECTED",
};

export default actionTypes;
