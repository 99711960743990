import styled from "styled-components";
import { styles } from "../../components/styles/styles";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

export const SidePart = styled.div<{ open: boolean }>`
  width: 400px;
  background-color: #ffffff;
  overflow-y: auto;
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 12px;
  border-right: 1px solid #e6e6e6;
  transition: all 0.3s linear;
  overflow-x: hidden;

  scrollbar-width: auto;
  scrollbar-color: #c4c4c4 #ffffff;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border: none;
  }

  @media only screen and (max-width: 768px) {
    width: 320px;
  }

  @media screen and (max-width: 480px) {
    width: ${({ open }) => (open ? "100%" : "0%")};
    padding: ${({ open }) => (open ? "12px" : "0px")};
  }
`;

export const UserItemBox = styled.div<{ active: boolean }>`
  display: flex;
  gap: 16px;
  cursor: pointer;
  align-items: center;
  border-radius: 16px;
  position: relative;
  padding: 8px 10px;
  transition: all 0.3s linear;
  background-color: ${({ active }) => (active ? "#FCE1D8" : "#ffffff")};

  &:hover {
    ${({ active }) => !active && `background-color: #FCE1D8`};
  }

  .avatar {
    width: 48px;
    height: 48px;
    /* padding: 8px 0 8px 10px; */
    aspect-ratio: 1 / 1;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  .counterBadge {
    margin-left: auto;
  }

  .data {
    width: calc(100% - 68px);

    margin: auto 0;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .full-name {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: black;
        display: flex;
        gap: 8px;
        align-items: baseline;

        .order-id {
          color: #00000077;
          font-size: 14px;
          font-weight: 450;
        }
      }

      .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #222222;

        mix-blend-mode: normal;
        opacity: 0.5;
      }
    }

    .desc {
      display: flex;
      gap: 6px;
      align-items: flex-end;

      .message {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 75%;
        white-space: nowrap;
      }

      .messageTime {
        margin-left: auto;
        font-variation-settings: "slnt" -8;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const MessagePart = styled.div<{ open: boolean }>`
  width: calc(100% - 400px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.3s linear;
  position: relative;

  @media only screen and (max-width: 1250px) {
    width: calc(100% - 350px);
  }

  @media only screen and (max-width: 1050px) {
    width: calc(100% - 94px);
  }

  @media only screen and (max-width: 576px) {
    position: unset;
    width: ${({ open }) => (open ? "100%" : "0%")};
  }

  @media only screen and (max-width: 480px) {
    width: ${({ open }) => (open ? "100%" : "0%")};
  }
`;

