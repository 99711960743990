import React, { memo, useEffect, useState } from "react";
import { PopupWrapper, PopupContent } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CounterOffer } from "../../types/orderSlice.types";
import { getCounterOfferDifference } from "../notificationSidebar";
import Button from "../../UI/Button";
import ProposalPopUp from "../popUp/ProposalPopUp";
import { createCounterOffer } from "../../redux/store/reducers/orderSlice";

interface CounterOfferPopupProps {
  close: () => void;
  isOpen: boolean;
  handleAccept: (counterofferId: number) => Promise<void>;
  handleDecline: (counterofferId: number) => Promise<void>;
  handleCreateOffer: (counterOffer: {
    budget: string;
    description: string;
    vehicleId: number | null;
    paymentType: string;
    startDate: string;
    endDate: string | null;
  }) => Promise<void>;
  counterOffer: CounterOffer | null;
}

const CounterOfferPopup: React.FC<CounterOfferPopupProps> = ({
  close,
  isOpen,
  handleAccept,
  handleDecline,
  counterOffer,
  handleCreateOffer,
}) => {
  const [counterOfferPopup, setCounterOfferPopUp] = useState<boolean>(false);
  const { user } = useAppSelector((state) => state.auth);

  return (
    <PopupWrapper open={isOpen} onClick={close}>
      <PopupContent
        onClick={(e) => {
          e.stopPropagation();
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
        }}
        open={isOpen}
        isInvite={false}
        width="375px"
        lineHeight="1"
      >
        <div className="popupHeader">
          Встречное предложение
          <CloseIcon onClick={close} />
        </div>
        <div className="popupBody">
          {counterOffer && getCounterOfferDifference(counterOffer)}
          <div className="buttonsWrapper">
            {counterOffer ? (
              counterOffer.status === "Accepted" ? (
                <Button height="36px" disabled>
                  Предложение принято
                </Button>
              ) : (
                counterOffer.creator.id !== user?.customer.id && (
                  <>
                    <Button
                      height="36px"
                      onClick={() => handleAccept(counterOffer.id)}
                      disabled={counterOffer.creator.id === user?.customer.id}
                    >
                      Принять
                    </Button>
                    <Button
                      height="36px"
                      variant="outlined"
                      onClick={() => handleDecline(counterOffer.id)}
                      disabled={counterOffer.creator.id === user?.customer.id}
                    >
                      Отклонить
                    </Button>
                  </>
                )
              )
            ) : (
              <Button height="36px" onClick={() => setCounterOfferPopUp(true)}>
                Сделать предложение
              </Button>
            )}
          </div>
        </div>
      </PopupContent>
      {counterOfferPopup && (
        <ProposalPopUp
          close={() => setCounterOfferPopUp(false)}
          counterOffer
          handleCounterOffer={handleCreateOffer}
        />
      )}
    </PopupWrapper>
  );
};

export default memo(CounterOfferPopup);

