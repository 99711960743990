import styled from "styled-components";

export const InputDatasetWrapper = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  border-radius: 2px;
  z-index: 200;
  height: fit-content;
`;

export const InputDatasetBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const InputBox = styled.div`
  background-color: white;
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border: 0;
  }

  .go-back {
    padding: 14px 12px 14px 16px;
    display: flex;
  }

  input {
    width: calc(100% - 72px);
    padding: 12px 0;
    border: 0;
    outline: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .close {
    padding: 14px 16px 14px 12px;
    display: flex;
  }

  .save {
    display: flex;
    margin-right: 16px;
    cursor: pointer;

    img {
      width: 20px;
      margin: auto;
    }
  }
`;

export const ListTitle = styled.div`
  padding: 16px 24px 8px 24px;
  font-size: 14px;
`;

export const DatasetList = styled.div<{
  wrapperClass?: string;
  noTitle?: boolean;
}>`
  padding: ${({ noTitle }) => (noTitle ? "24px" : "0 24px 24px 24px")};
  background-color: white;
  overflow: auto;
  height: fit-content;
  max-height: 300px;
  display: flex;
  /* justify-content: space-between; */
  gap: 16px;
  flex-wrap: wrap;
  ${({ wrapperClass }) => (wrapperClass ? "" : "")}

  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  .toplist-header {
    background-color: #dbdbdb;
    width: calc(100% + 20px);
    margin-left: -10px;
    color: #777777;
    padding: 5px 0 5px 12px;
  }
`;

export const DatasetBox = styled.div`
  width: calc((100% - 64px) / 5);
  height: 112px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  position: relative;
  border-radius: 10px;

  @media screen and (max-width: 400px) {
    width: 64px;
    height: 82px;
  }

  /* &::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -10px;
    bottom: -1px;
    left: -10px;
  } */

  /* &:hover::after {
    background-color: rgba(0, 0, 0, 0.12);
  } */

  .img {
    /* margin-right: 14px; */
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .data {
    /* width: calc(100% - 38px); */
    width: 100%;
  }

  .title {
    /* font-weight: 400;
    font-size: 12px;
    line-height: 14px; */
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    hyphens: auto;
    word-wrap: normal;
  }

  .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.38);
  }

  @media only screen and (max-width: 1000px) {
    width: calc((100% - 32px) / 3);
  }
  @media only screen and (max-width: 650px) {
    width: calc((100% - 16px) / 2);
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
`;
