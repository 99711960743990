import styled from "styled-components";

export const HiddenOffers = styled.div<{ open: boolean }>`
  display: grid;
  grid-template-rows: ${({ open }) => (open ? "1fr" : "0fr")};
  overflow: hidden;
  transition: all 0.25s linear;

  .flex-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    overflow: hidden;
  }
`;

