import styled from "styled-components";

export const Wrapper = styled.div<{ marginLeft?: string }>`
  font-size: 14px;
  letter-spacing: -0.25px;
  line-height: 1;
  color: #ff6633;
  padding: 3px 6px;
  background-color: rgba(255, 102, 51, 0.24);
  border-radius: 4px;
  align-self: flex-start;
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft}`}
`;
