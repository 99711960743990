export const inputVehicleList1 = [
  {
    // label: "ИНН организации",
    name: "vehicle",
    width: "300px",
    type: "select",
  },
  {
    label: "Марка",
    name: "mark",
    width: "calc((100% - 8px) / 2)",
    required: false,
  },
  {
    label: "Модель",
    name: "model",
    width: "calc((100% - 8px) / 2)",
    required: false,
  },
  // {
  //   label: "Img",
  //   name: "img",
  //   type: "img",
  // },
  {
    label: "Госномер средства",
    name: "vehicle_number",
    width: "calc((100% - 8px) / 2)",
    // required: true,
    type: "vehicle_number",
  },
  {
    label: "Год выпуска",
    name: "year",
    width: "calc((100% - 8px) / 2)",
    // required: true,
    type: "year",
  },
  {
    label: "Примечание",
    name: "comment",
    type: "text",
  },
];

export const inputVehicleList2 = [
  {
    name: "type",
    width: "300px",
    type: "vehicle_type",
  },
  {
    label: "Марка и модель",
    name: "mark_model",
    required: false,
  },
  {
    label: "Img",
    name: "img",
    type: "img",
  },
  {
    label: "Госномер средства",
    name: "vehicle_number",
    width: "calc((100% - 8px) / 2)",
    type: "vehicle_number",
  },
  {
    label: "Год выпуска",
    name: "year",
    width: "calc((100% - 8px) / 2)",
    type: "year",
  },
];
