import React, { memo } from "react";
import PopUpContainer from "../popUpContainer";

const HideOfferPopUp: React.FC<{
  close: () => void;
}> = ({ close }) => {
  const onSubmit = () => {
    close();
  };

  return (
    <PopUpContainer
      title={"Не удалось скрыть отклик"}
      close={close}
      onSubmit={onSubmit}
      button={"Закрыть"}
    >
      К сожалению, мы не можем скрыть отклики этого исполнителя.
    </PopUpContainer>
  );
};

export default memo(HideOfferPopUp);
