import React, { memo, useEffect, useRef, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { FilesWrapper, UploadBox } from "./styles";
import pdfIcon from "../../../assets/icons/pdf-icon-popup.png";
import xlsIcon from "../../../assets/icons/xls-icon-popup.png";
import docIcon from "../../../assets/icons/doc-icon-popup.png";
import { ReactComponent as CameraIcon } from "../../../assets/icons/add_a_photo_black_36dp.svg";
import FilePreview from "../../../UI/FilePreview";

interface Props {
  onSubmit: (files: File[], binaries: Uint8Array[]) => void;
  close: () => void;
  files: File[];
}

const UploadPopUp: React.FC<Props> = ({ close, onSubmit, files }) => {
  const [totalSize, setTotalSize] = useState<number>(0);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>(
    files.length ? files : [],
  );
  const [binariesFiles, setBinariesFiles] = useState<Uint8Array[]>([]);
  const maxFilesQuantity = 8;

  const handleRemoveFile = (fileToRemove: File) => {
    setUploadedFiles((prev) => {
      const newValue = prev.filter((file) => file.name !== fileToRemove.name);
      return newValue;
    });
  };

  const renderFiles = () => {
    return uploadedFiles.map((file, index) => {
      const fileUrl = URL.createObjectURL(file);
      const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      return (
        <FilePreview
          key={`filePreview_${index}`}
          src={fileUrl}
          onClick={() => handleRemoveFile(file)}
          fileType={file.type}
          fileExtension={fileExtension}
        />
      );
    });
  };

  const inputChangeHanlder = async (files: FileList) => {
    const filesArray = Array.from(files);
    setUploadedFiles((prev) => {
      return prev.concat(filesArray);
    });
    const binariesArray: Uint8Array[] = [];
    if (filesArray.length > 0) {
      await Promise.all(
        filesArray.map(async (file) => {
          const arrayBuffer = await file.arrayBuffer();
          const array = new Uint8Array(arrayBuffer);
          binariesArray.push(array);
        }),
      );
    }
    setBinariesFiles((prev) => {
      return prev.concat(binariesArray);
    });
  };

  return (
    <PopUpContainer
      close={close}
      onSubmit={() => {
        onSubmit(uploadedFiles, binariesFiles);
        close();
      }}
      title="Добавить файлы"
      button="Продолжить"
      disabled={uploadedFiles.length === 0 || totalSize > 10}
    >
      <input
        type="file"
        id="fileUpload"
        hidden
        multiple
        onChange={(e) => {
          if (e.target.files?.length) {
            inputChangeHanlder(e.target.files);
            e.target.value = "";
          }
        }}
      />

      <UploadBox
        htmlFor="fileUpload"
        onDrop={(e) => {
          e.preventDefault();
          console.log(e.dataTransfer.files);
          e.dataTransfer.effectAllowed = "none";
          e.dataTransfer.dropEffect = "none";
          inputChangeHanlder(e.dataTransfer.files);
        }}
        onDragEnter={(e) => e.preventDefault()}
        onDragOver={(e) => e.preventDefault()}
      >
        <CameraIcon />
        Нажмите или перетащите файлы в эту область для загрузки
      </UploadBox>
      {uploadedFiles.length > 0 && <FilesWrapper>{renderFiles()}</FilesWrapper>}
    </PopUpContainer>
  );
};

export default memo(UploadPopUp);

