import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import getManagerData from "../../../redux/modules/getManagerData";
import getManagers from "../../../redux/modules/getManagers";
import PopUpContainer from "../popUpContainer";
import {
  ButtonsWrapper,
  DataWrapper,
  InputsWrapper,
  SubTitle,
  Title,
} from "./styles";
import {
  clearSelectedVehicle,
  fetchUpdateVehicle,
  getVehicleById,
  getVehiclesList,
  removeVehicleById,
} from "../../../redux/store/reducers/vehicleSlice";
import { InputBox } from "../../form";
import Button from "../../../UI/Button";

const SingleVehiclePopUp: React.FC<any> = ({ id, close }) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const selectedVehicle = useAppSelector(
    (state) => state.vehicle.selectedVehicle,
  );
  const { vehicleCategories } = useAppSelector((state) => state.vehicle);
  const [formData, setFormData] = useState({
    model: "",
    brand: "",
    stateNumber: "",
    productionYear: "",
    categoryId: "",
    comment: "",
  });
  const [formTitle, setFormTitle] = useState("");

  useEffect(() => {
    if (selectedVehicle) {
      setFormData({
        model: selectedVehicle.model,
        brand: selectedVehicle.brand,
        stateNumber: selectedVehicle.stateNumber,
        productionYear: selectedVehicle.productionYear,
        categoryId: selectedVehicle.categoryId,
        comment: selectedVehicle.comment,
      });
      setFormTitle(
        vehicleCategories.find(
          ({ id: cId }) => selectedVehicle.categoryId === cId,
        )?.title,
      );
    }

    return () => {
      dispatch(clearSelectedVehicle());
    };
  }, [selectedVehicle]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(
        getVehicleById({
          customerId: user?.customer?.refId,
          vehicleId: id,
        }),
      );
    }
  }, [user?.customer?.refId]);

  const editVehicleHandler = () => {
    if (
      user?.customer?.refId &&
      formData.model !== "" &&
      formData.brand !== "" &&
      formData.stateNumber !== "" &&
      formData.productionYear
    )
      dispatch(
        fetchUpdateVehicle({
          customerId: user?.customer?.refId,
          vehicleId: id,
          data: {
            ...formData,
            productionYear: Number(formData.productionYear),
          },
        }),
      ).then(() => {
        dispatch(getVehiclesList(user?.customer?.refId));
        close();
      });
  };
  const deleteVehicleHandler = () => {
    user?.customer?.refId &&
      dispatch(
        removeVehicleById({
          customerId: user?.customer?.refId,
          vehicleId: id,
        }),
      ).then(() => {
        dispatch(getVehiclesList(user?.customer?.refId));
        close();
      });
  };

  return (
    <PopUpContainer middle close={close} title={`#${id}`}>
      <DataWrapper>
        <Title>{formTitle}</Title>
        <SubTitle>{/* {vCategory?.title} */}</SubTitle>
        <InputsWrapper>
          <InputBox
            type={"text"}
            label="Модель"
            value={formData.model}
            onChange={(e: any) =>
              setFormData({ ...formData, model: e.target.value })
            }
          />
          <InputBox
            type={"text"}
            label="Марка"
            value={formData.brand}
            onChange={(e: any) =>
              setFormData({ ...formData, brand: e.target.value })
            }
          />
          <InputBox
            type={"text"}
            label="Государственный номер"
            value={formData.stateNumber}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                stateNumber: e.target.value,
              })
            }
          />
          <InputBox
            type={"text"}
            label="Год выпуска"
            value={formData.productionYear}
            onChange={(e: any) =>
              !isNaN(e.target.value) &&
              setFormData({
                ...formData,
                productionYear: e.target.value,
              })
            }
          />
          <InputBox
            type={"text"}
            label="Примечание"
            value={formData.comment}
            onChange={(e: any) =>
              setFormData({
                ...formData,
                comment: e.target.value,
              })
            }
          />
        </InputsWrapper>
        <ButtonsWrapper>
          <Button
            onClick={editVehicleHandler}
            height="48px"
            disabled={
              formData.model === "" ||
              formData.brand === "" ||
              formData.stateNumber === "" ||
              !formData.productionYear ||
              formData.productionYear.toString().length !== 4
            }
          >
            Сохранить
          </Button>
          <Button
            onClick={deleteVehicleHandler}
            height="48px"
            variant="secondary"
          >
            Удалить
          </Button>
        </ButtonsWrapper>
      </DataWrapper>
    </PopUpContainer>
  );
};

export default SingleVehiclePopUp;

