import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ENDPOINT } from "../../constants/general";

const GoogleViewerUrl = (url: string) =>
  `https://docs.google.com/viewerng/viewer?url=${ENDPOINT}/api/customers/image/${url}&embed=true&embedded=true`;

const PdfViewerUrl = (url: string) =>
  `https://coder.rukki.pro/pdf/web/viewer.html?file=${ENDPOINT}/api/customers/image/${url}`;

const DocumentViewer: React.FC = () => {
  const { fileName } = useParams();
  const navigate = useNavigate();

  if (fileName) {
    return (
      <iframe
        width={"100%"}
        height={"100%"}
        src={
          fileName.includes("pdf")
            ? PdfViewerUrl(fileName)
            : GoogleViewerUrl(fileName)
        }
      ></iframe>
    );
  } else {
    navigate("/");
  }
  return <></>;
};

export default DocumentViewer;

