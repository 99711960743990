import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpRequest } from "../../../utils/httpRequest";

interface TFormData {
  bik?: string;
  rs?: string;
  companyName?: string;
  inn?: string;
}

export const fetchSendPayout = createAsyncThunk(
  "vehicle/fetchSendPayout",
  async (
    { refId, data }: { refId: string; data: TFormData },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `payout/${refId}/payment-sign`,
      data,
    });
    return response.data;
  },
);

export const fetchSendPayoutForOrder = createAsyncThunk(
  "vehicle/fetchSendPayout",
  async (
    { refId, data }: { refId: string; data: TFormData },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      path: `payout/${refId}/payment-sign`,
      data,
    });
    return response.data;
  },
);

type Loading = "idle" | "pending" | "succeeded" | "failed";

interface TVehicle {
  finance: any;
  loading: Loading;
  error: string | null;
}

const initialState: TVehicle = {
  finance: [],
  loading: "idle",
  error: null,
};

export const financeSlice = createSlice({
  name: "finance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSendPayout.fulfilled, (state, action) => {
        state.loading = "succeeded";
      })
      .addCase(fetchSendPayout.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(fetchSendPayout.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Внимание какая то ошибка";
      });
  },
});

// export const {  } = vehicleSlice.actions;

export default financeSlice.reducer;
