import React, { memo, useEffect, useRef, useState } from "react";
import {
  Address,
  BudgetDeadline,
  DataWrapper,
  Desc,
  Header,
  HeaderBtn,
  MapWrapper,
  OrderName,
  OrderWrapper,
  SubTitle,
  Title,
  TitleValue,
  Wrapper,
} from "./styles";

import { ReactComponent as Close } from "../../assets/icons/icons-close.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/icons-arrow-forward.svg";
import {
  Map,
  Placemark,
  Polyline,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { getRandomNumber, thousandSeparator } from "../../utils/numbers";
import getDashboardSingleOrder from "../../redux/modules/getDashboardSingleOrder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import vehicleIcon1 from "../../assets/icons/icons-vehicles-excavator.svg";
import vehicleIcon2 from "../../assets/icons/icons-vehicles-beton.svg";
import vehicleIcon3 from "../../assets/icons/icons-vehicles-hoist.svg";
import vehicleIcon4 from "../../assets/icons/icons-vehicles-musor.svg";
import vehicleIcon5 from "../../assets/icons/icons-vehicles-wheelbarrow.svg";

const DashboardRightOrderData: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const ref = useRef<any>(null);
  const [center, setCenter] = useState<number[]>([]);

  const [icon, setIcon] = useState<any>(null);

  const vehicleList = [
    vehicleIcon1,
    vehicleIcon2,
    vehicleIcon3,
    vehicleIcon4,
    vehicleIcon5,
  ];

  const { fireBaseData } = useAppSelector((state: RootState) => state.auth);
  const data = useAppSelector((state) => state.dashboardSingleOrders.data);
  const { data: orders } = useAppSelector((state) => state.dashboardOrders);
  const { id } = useParams();

  useEffect(() => {
    const refId = fireBaseData?.refId;
    if (refId && id) {
      dispatch(getDashboardSingleOrder(refId, id));
    }
  }, [id, fireBaseData?.refId]);

  const [coords, setCoords] = useState<number[][]>([]);

  const [ymaps, setYmaps] = useState<any>();

  const [mouseOverTime, setMouseOverTime] = useState(-1);

  const randomCoords = [
    [
      [55.742717083082205, 37.59626428298946],
      [55.74220868939031, 37.59757320098874],
      [55.741954490049984, 37.598302761840785],
      [55.741627659883164, 37.59926835708615],
      [55.74121609205903, 37.59969751052854],
      [55.74047768003663, 37.601135174560504],
      [55.73989662464608, 37.60242263488765],
      [55.73964241018064, 37.60343114547725],
      [55.739170293189986, 37.60298053436276],
      [55.73885554534305, 37.60270158462521],
      [55.73868606467728, 37.603259484100306],
      [55.73872238202503, 37.60371009521481],
    ],
    [
      [55.74006543802069, 37.60864535980222],
      [55.740840173245154, 37.608602444457986],
      [55.74160278814623, 37.60881702117916],
      [55.74243801586538, 37.60903159790035],
      [55.74311586864249, 37.609353462982135],
      [55.742655898404784, 37.61038343124387],
      [55.74202645662424, 37.611713806915255],
      [55.74156647349034, 37.61274377517696],
      [55.74128806000085, 37.61336604766843],
      [55.74067070123461, 37.61265794448848],
      [55.73988385721817, 37.6117781799316],
      [55.739169631162945, 37.61358062438961],
      [55.73950858753761, 37.61405269317624],
      [55.73962964266922, 37.6135591667175],
    ],
    [
      [55.76610526515647, 37.53813544921873],
      [55.76513747344151, 37.54534522705074],
      [55.76436322269922, 37.5521258514404],
      [55.76635930648402, 37.553692261505105],
      [55.76862141071057, 37.555559078979456],
      [55.76916574766613, 37.55624572448727],
      [55.76899639920902, 37.55684653930661],
      [55.768355287643146, 37.55746881179805],
      [55.76731497087424, 37.558627526092494],
      [55.766335112143494, 37.5597647827148],
      [55.765657664487, 37.56042997055051],
      [55.7659117087435, 37.56120244674679],
      [55.76566976187023, 37.56143848114011],
      [55.76519796113048, 37.5618676345825],
      [55.76490761936388, 37.56264011077878],
    ],
    [
      [55.82791743262203, 38.25960675888058],
      [55.83071942212874, 38.31350843124387],
      [55.8318304997685, 38.330116669464076],
      [55.83666090154533, 38.333464066314654],
      [55.83965544805779, 38.33655397109982],
      [55.84057308530391, 38.33758393936155],
      [55.842070236223314, 38.33758393936155],
      [55.84298781623368, 38.33749810867305],
      [55.845112654861445, 38.33921472244259],
      [55.84588529453563, 38.341446320342975],
      [55.84569213606295, 38.34616700820921],
      [55.84530581622586, 38.347111145782435],
    ],
    [
      [55.559343534964725, 37.60538379364003],
      [55.563040485581325, 37.60418216400136],
      [55.566202074065465, 37.60229388885487],
      [55.56829344600579, 37.601178089904664],
      [55.57164913472473, 37.60057727508533],
      [55.57364295830917, 37.60332385711657],
      [55.57490728156565, 37.60701457672108],
      [55.575004535507446, 37.611477772521866],
      [55.57451826337945, 37.61697093658435],
      [55.57427512504763, 37.62263576202382],
      [55.57378884384828, 37.632506291198624],
      [55.57349707222579, 37.63559619598379],
      [55.57101692553123, 37.63396541290272],
      [55.56882843016709, 37.632506291198624],
      [55.5686338913188, 37.63465205841053],
      [55.56829344600579, 37.636197010803095],
      [55.56770981857437, 37.63748447113025],
      [55.56712618243362, 37.636540333557015],
    ],
  ];
  useEffect(() => {
    const randomCoord =
      randomCoords[getRandomNumber(0, randomCoords.length - 1)];
    setCoords(randomCoord);
    setCenter(randomCoord[randomCoord.length - 1]);
    setIcon(vehicleList[getRandomNumber(0, vehicleList.length - 1)]);
  }, [id]);

  return (
    <Wrapper>
      <Header>
        <HeaderBtn onClick={() => navigate("/dashboard/order")}>
          <Close /> Закрыть
        </HeaderBtn>
        <HeaderBtn
          onClick={() => {
            navigate(`/o/${id}`);
          }}
        >
          <ArrowRight /> Подробнее
        </HeaderBtn>
      </Header>
      <OrderWrapper>
        <DataWrapper ref={ref}>
          <Title>{data?.title}</Title>
          <SubTitle>
            #{data?.id},{" "}
            {moment(data?.createdAt).format("LL").replace("г.", "")}
          </SubTitle>
          <OrderName>
            {data?.title ||
              orders?.orders?.find(({ id: oId }: any) => id === String(oId))
                ?.vehicle?.title ||
              "Краны-манипуляторы"}
          </OrderName>
          <BudgetDeadline>
            <TitleValue>
              <div className="title">Бюджет</div>
              <div className="value">
                {data?.budget
                  ? `${thousandSeparator(data?.budget)} ₽`
                  : "Цена обсуждается"}
              </div>
            </TitleValue>
            <TitleValue>
              <div className="title">Сроки</div>
              <div className="value">
                {moment(data?.startDate).format("LLL").replace(" ", " ")}
                {data?.endDate ? (
                  <>- {moment(data?.endDate).format("LLL").replace(" ", " ")}</>
                ) : (
                  <></>
                )}
              </div>
            </TitleValue>
            <TitleValue>
              <div className="title">Заказчик</div>
              <div className="value">{data?.customer}</div>
            </TitleValue>
          </BudgetDeadline>
          <Desc>
            <div className="title">Описание</div>
            <div className="text">{data?.description}</div>
          </Desc>
          <Address>
            <div className="title">Адрес</div>
            <div className="text">{data?.address}</div>
          </Address>
        </DataWrapper>
        {data?.latitude && data?.longitude && ref?.current?.clientHeight && (
          <MapWrapper dataHeight={ref?.current?.clientHeight}>
            <YMaps query={{ lang: "ru_RU" }}>
              <Map
                modules={["templateLayoutFactory"]}
                width={"100%"}
                height={"100%"}
                onLoad={(e: any) => setYmaps(e)}
                defaultState={{
                  center: [...center],
                  zoom: 12,
                }}
              >
                <Polyline
                  //@ts-ignore
                  geometry={coords}
                  options={{
                    strokeColor: "#ff3358",
                    strokeWidth: 4,
                    strokeStyle: "shortdash",
                    openHintOnHover: true,
                    //@ts-ignore
                    fillColor: "#00FF00",
                  }}
                />

                {ymaps &&
                  coords.slice(0, coords.length - 1).map((coord, index) => (
                    <Placemark
                      modules={[
                        "geoObject.addon.balloon",
                        "geoObject.addon.hint",
                      ]}
                      onMouseOver={() => {
                        setMouseOverTime(index);
                      }}
                      onHover={() => {
                        setMouseOverTime(index);
                      }}
                      onMouseOut={() => setMouseOverTime(-1)}
                      onMouseLeave={() => setMouseOverTime(-1)}
                      options={{
                        preset: "islands#circleIcon",
                        iconLayout: ymaps?.templateLayoutFactory?.createClass(
                          `<div class="polyline-point">
                          <div class="time ${
                            mouseOverTime === index ? "active" : ""
                          }">
                                ${moment()
                                  .add(
                                    -7.5 * (coords.length - 1 - index),
                                    "minutes",
                                  )
                                  .format("HH:mm")}, сегодня
                                </div>
                        </div>`,
                        ),
                        iconContentSize: [8, 8],
                        iconOffset: [0, 0],
                        iconShape: {
                          type: "Rectangle",
                          //@ts-ignore
                          coordinates: [
                            [0, 0],
                            [24, 24],
                          ],
                        },
                      }}
                      geometry={{
                        type: "Point",
                        coordinates: coord,
                      }}
                      properties={{
                        iconContent: `
                              <div class="polyline-point">
                                <div class="time ${
                                  mouseOverTime === index ? "active" : ""
                                }">
                                ${moment()
                                  .add(
                                    -7.5 * (coords.length - 1 - index),
                                    "minutes",
                                  )
                                  .format("HH:mm")}, сегодня
                                </div>
                              </div>`,
                      }}
                    />
                  ))}
                <Placemark
                  width="0px"
                  height="0px"
                  options={{
                    preset: "islands#circleIcon",
                    iconColor: "#423189",
                    draggable: false,
                    hideIconOnBalloonOpen: false,
                    hasBalloon: true,
                    balloonShadow: false,
                    balloonPanelMaxMapArea: 0,
                  }}
                  geometry={{
                    type: "Point",
                    coordinates: [...center],
                  }}
                  properties={{
                    iconContent: `
                        <div class="order-placemark">   
                          <img class="placemark" src=${placemark} alt="" />
                          <img class="vehicle" src=${icon} alt="" />
                        </div>`,
                  }}
                />
                <ZoomControl
                  options={{
                    position: { right: 10, top: 20 },
                  }}
                />
              </Map>
            </YMaps>
          </MapWrapper>
        )}
      </OrderWrapper>
    </Wrapper>
  );
};

export default memo(DashboardRightOrderData);
