import React, { memo, useEffect, useState } from "react";
import {
  ContentTitle,
  Wrapper,
  Text,
  InfoBlock,
  ButtonContainer,
  InputsWrapper,
} from "./styles";
import Button from "../../UI/Button";
import Input from "../../UI/Input";
import Select from "../../UI/FilterSelect";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import {
  fetchAddVehicles,
  getVehicleCategories,
  getVehiclesList,
} from "../../redux/store/reducers/vehicleSlice";
import { httpRequest } from "../../utils/httpRequest";
import { useNavigate } from "react-router-dom";
import { inputVehicleList1 } from "../../utils/vehicleHelpers";

const CreateVehiclePage: React.FC = () => {
  const [formData, setFormData] = useState<any>({
    vehicle: {
      label: "",
      value: 0,
    },
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );
  const user = useAppSelector((state: RootState) => state.auth.user);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: name === "vehicle_number" ? value.toUpperCase() : value,
    }));
  };

  const addVehicle = () => {
    const customerId = user?.customer?.refId;
    const { vehicle, mark, model, img, vehicle_number, year, comment } =
      formData;
    const data = {
      categoryId: vehicle.value,
      brand: mark,
      model,
      stateNumber: vehicle_number,
      comment: comment || "",
      productionYear: Number(year),
    };
    httpRequest({
      method: "POST",
      path: `vehicles/${customerId}/add`,
      data,
    })
      .then((res) => {
        const vehicleId = res?.data?.vehicle?.raw?.insertId;

        if (img?.length) {
          const formData = new FormData();
          img.forEach((element: any, index: number) => {
            formData.append(`file${index}`, element?.file);
          });
          httpRequest({
            method: "PUT",
            path: `vehicles/${customerId}/${vehicleId}/images/add`,
            data: formData,
          }).then(() => {
            onSubmit();
          });
        } else {
          onSubmit();
        }
      })
      .catch((err) => {});
  };

  const onSubmit = async () => {
    if (user?.customer?.refId) {
      dispatch(getVehiclesList(user?.customer?.refId));
      navigate("/vehicle-management");
    }
  };

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, []);

  return (
    <Wrapper>
      <div className="title">Информация о новом средстве</div>
      <InputsWrapper>
        {inputVehicleList1.map(
          ({ label, name, width, type, required }, index) => (
            <>
              {type === "select" ? (
                <Select
                  name={"vehicleCategory"}
                  value={formData.vehicle.label}
                  list={
                    vehicleCategories
                      ? vehicleCategories?.map((category) => {
                          return {
                            label: category.title,
                            value: category.id,
                          };
                        })
                      : []
                  }
                  placeholder="Тип техники"
                  onChange={({ name, label, value }) => {
                    setFormData({
                      ...formData,
                      vehicle: { label, value },
                    });
                  }}
                />
              ) : (
                <Input
                  placeholder={label}
                  height="48px"
                  width={width}
                  name={name}
                  onChange={(e: any) => onChange(e)}
                  value={formData?.[name]}
                  //type={type}
                  required={required}
                  maxLength={name === "year" ? 4 : undefined}
                />
              )}
            </>
          ),
        )}
      </InputsWrapper>
      <InfoBlock>
        <ButtonContainer>
          <Button
            height="48px"
            fontWeight="400"
            onClick={() => {
              // onSubmit
              addVehicle();
            }}
            disabled={
              !(
                formData?.vehicle &&
                formData?.mark &&
                formData?.model &&
                formData?.vehicle_number &&
                formData?.year
              )
            }
          >
            Добавить технику
          </Button>
        </ButtonContainer>
        <p>
          Перед тем, как допустить вашу технику к работам, мы проверим
          информацию о ней
        </p>
      </InfoBlock>
    </Wrapper>
  );
};

export default memo(CreateVehiclePage);
