import styled from "styled-components";
import { styles } from "../../styles/styles";

export const PaymentMethodList = styled.div``;

export const PaymentItem = styled.div<{ checked?: boolean }>`
  width: 100%;
  padding: 13px 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  border-bottom: 1px solid #ebebeb;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    /* background-color: rgba(0, 0, 0, 0.12); */
    position: absolute;
    top: -1px;
    right: -10px;
    bottom: -1px;
    left: -10px;
    z-index: -1;
  }

  &:hover::after,
  &.checked::after {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:last-child {
    /* border: 0; */
  }

  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #222222;
  }

  .icon {
    width: 32px;
    display: flex;
    background-color: white;
    border-radius: 3px;

    img {
      width: 100%;
    }
  }
`;

export const PaymentBox = styled.div`
  width: 100%;

  margin: 54px 0 24px;

  .ant-input-number {
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    outline: 0;
    box-shadow: unset !important;
    border-radius: 0;

    .ant-picker-suffix {
      display: none;
    }

    .ant-input-number-handler-wrap {
      display: none;
    }
  }

  input {
    width: 100%;
    border: 0;
    outline: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    padding: 0;
    border-bottom: 1px solid #e0e0e0;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    color: #666666;
  }
`;

export const BarList = styled.div`
  display: flex;
  margin: 24px 0 16px;
  justify-content: space-between;

  .barItem {
    font-weight: 500;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: rgba(153, 153, 153, 0.8);
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    padding: 7px 8px;
    cursor: pointer;

    @media screen and (max-width: 391px) {
      padding: 4px 8px;
    }

    @media screen and (max-width: 363px) {
      padding: 4px 3px;
    }
  }

  .active {
    background-color: ${styles.mainColor};
    color: white;
  }
`;
