import React, { memo, useEffect } from "react";
// import { Button } from "../../form/styles";
import Button from "../../../UI/Button";
import {
  Header,
  PopUpBackground,
  PopUpBox,
  PopUpHiddenPart,
  PopUpWrapper,
} from "./styles";
import { ReactComponent as CloseIcon } from "../../../assets/icons/Close.svg";

type Loading = "idle" | "pending" | "succeeded" | "failed";

interface Props {
  children?: any;
  close?: any;
  button?: string | null;
  onSubmit?: any;
  title?: string;
  disabled?: boolean;
  middle?: boolean;
  big?: boolean;
  mb?: string;
  loading?: Loading;
  tab?: (e: any) => void;
  boxTab?: () => void;
  simpleBtn?: boolean;
  headerless?: boolean;
  paddingless?: boolean;
  bigger?: boolean;
  closeBtn?: any;
  bottomPart?: any;
  proposal?: boolean;
}

const PopUpContainer: React.FC<Props> = ({
  children,
  close,
  button,
  onSubmit,
  title,
  disabled,
  middle,
  big,
  mb,
  loading,
  tab,
  boxTab,
  simpleBtn,
  headerless,
  paddingless,
  bigger,
  closeBtn,
  bottomPart,
  proposal,
}) => {
  // useEffect(() => {
  //   const handleEsc = (event:any) => {
  //     event.preventDefault();
  //     if (event.key === 'Escape') {
  //       close();
  //     }
  //   };
  //   window.addEventListener('keydown', handleEsc);

  //   return () => {
  //     window.removeEventListener('keydown', handleEsc);
  //   };
  // }, []);

  return (
    <>
      <style>
        {`
        html {
          overflow: hidden;
        }
      `}
      </style>
      <PopUpWrapper
        onKeyDown={(e) => {
          if (e.key === "Tab" && boxTab) {
            e.preventDefault();
            boxTab();
          }
        }}
      >
        <PopUpBackground onClick={close} />
        <PopUpHiddenPart onClick={(e) => e.stopPropagation()}>
          <PopUpBox
            paddingless={paddingless}
            middle={middle}
            big={big}
            bigger={bigger}
            proposal={proposal}
          >
            {!headerless ? (
              <Header mb={mb} proposal={proposal}>
                <div className="title">{title}</div>
                <CloseIcon onClick={close} />
              </Header>
            ) : (
              <></>
            )}
            {children}
            {bottomPart}
            {button && (
              <Button
                marginTop="8px"
                disabled={disabled}
                onClick={onSubmit}
                fontWeight="500"
                height="48px"
              >
                {button}
              </Button>
            )}
          </PopUpBox>
        </PopUpHiddenPart>
      </PopUpWrapper>
    </>
  );
};

export default memo(PopUpContainer);
