import styled from "styled-components";

export const CheckboxWrapper = styled.div<{ fontSize?: string }>`
  display: flex;
  gap: 8px;
  align-items: center;

  .checkboxText {
    cursor: pointer;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
    color: #222222;
    align-items: center;
    height: max-content;
    opacity: 1;
  }
`;

export const InputWrapper = styled.div<{ active?: boolean; error?: boolean }>`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ error }) => (error ? "#FF3358" : "#999999")};
  border-radius: 8px;
  transition: all 0.25s linear;
  cursor: pointer;
  input {
    display: none;
  }

  &:hover {
    border-color: #ff6633;
  }

  svg {
    opacity: ${({ active }) => (active ? "1" : "0")};
    transition: all 0.25s linear;
  }
`;
