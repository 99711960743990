import styled from "styled-components";
import { styles } from "../../components/styles/styles";

import { GoBack } from "./../../components/pageContainer/styles";
import { Link } from "react-router-dom";
import { Button } from "../../components/form/styles";

export const Wrapper = styled.section`
  display: flex;
  width: 100%;
  height: calc(100vh - 137px);
  max-height: 944px;

  .image {
    width: 50%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      /* object-position: left;
      object-position: right; */
    }

    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    height: calc(100vh - 64px);
  }
`;

export const Content = styled.div`
  width: 50%;
  /* height: 100%; */
  /* width: 378px; */
  /* height: calc(100vh - 136px); */
  overflow-x: hidden;
  overflow-y: auto;
  /* height: fit-content; */
  /* padding: 128px 278px 128px 316px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  .buttons {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    /* margin-bottom: 16px; */

    @media screen and (max-width: 480px) {
      padding: 0 24px;
    }

    @media screen and (max-width: 320px) {
      padding: 0 16px;
    }
  }

  @media screen and (max-width: 998px) {
    width: 100%;
  }
`;
export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 130px 20px 40px;
  margin: 0 auto;

  .content-box {
    display: flex;
    flex-direction: column;
    width: 100%;

    .buttons {
      justify-content: flex-start !important;
      margin-bottom: 16px;
    }
    @media screen and (max-width: 480px) {
      background: #f7f4f1;
    }
  }

  @media screen and (max-width: 1920px) {
    /* padding: 64px 0 40px 160px; */
  }

  @media screen and (max-width: 1440px) {
    /* padding: 40px 0 40px 64px; */
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 998px) {
    padding: 24px 24px 24px;
  }

  @media screen and (max-width: 768px) {
    /* padding: 24px 0 85px 56px; */
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled.div<{ firstStep?: boolean }>`
  display: flex;
  justify-content: flex-start !important;
  margin-bottom: 16px;
  gap: 8px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0 24px;
  }

  @media screen and (max-width: 320px) {
    padding: 0 16px;
  }
`;

export const CheckBoxWrapper = styled.label<{ error?: boolean }>`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
  transition: all 0.25s linear;

  @media screen and (max-width: 480px) {
    padding: 0 0 0 24px;
  }

  @media screen and (max-width: 320px) {
    padding: 0 0 0 16px;
  }

  .text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    transition: all 0.25s linear;
    color: ${({ error }) => (error ? "#FF3358" : "#757575")};
  }
`;

export const CheckBox = styled.div<{ active?: boolean; error?: boolean }>`
  width: 24px;
  height: 24px;
  border: 1px solid ${({ error }) => (error ? "#FF3358" : "#999999")};
  border-radius: 8px;
  transition: all 0.25s linear;
  cursor: pointer;
  input {
    display: none;
  }

  &:hover {
    border-color: #ff6633;
  }

  svg {
    opacity: ${({ active }) => (active ? "1" : "0")};
    transition: all 0.25s linear;
  }
`;

export const RegistrationStep = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .stepGroup {
    display: flex;
    gap: 20px;
  }

  .backButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #eee7e1;
    width: 101px;
    height: 36px;
    border-radius: 18px;
    /* font-family: "Google Sans", sans-serif; */
    font-size: 15px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a110f;
    cursor: pointer;

    @media screen and (max-width: 320px) {
      width: 75px;
    }
  }

  .step {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: #000;
    display: flex;
    align-items: center;
    white-space: nowrap;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }

    @media screen and (max-width: 320px) {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 16px 24px;
    margin-bottom: 0;
    background: #f7f4f1;

    .stepGroup {
      display: flex;
      gap: 8px;
    }
  }
`;

export const RolePicker = styled.div`
  display: flex;

  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    margin: 0 auto;
  }
  /*
  @media screen and (max-width: 320px) {
    padding: 0 0 0 16px;
  } */

  .role {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 125px;
    border: solid 2px #e3e3e3;
    background: #ffffff;
    color: #222222;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    transition: all 0.25s linear;
    cursor: pointer;

    &:first-child {
      border-radius: 10px 0 0 10px;
    }

    &:last-child {
      border-radius: 0 10px 10px 0;
    }
  }

  .active {
    background: #ff6633 !important;
    color: #ffffff;
    border: solid 2px #ff6633;
    transition: all 0.25s linear;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    padding: 0 0 0 24px;
  }

  @media screen and (max-width: 320px) {
    padding: 0 0 0 16px;
  }

  .listItem {
    display: flex;
    gap: 4px;
    align-items: center;

    .icon {
      width: 16px;
      height: 16px;
    }

    .text {
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.14;
      letter-spacing: normal;
      color: #757575;
    }
  }
`;

export const RoleBtn = styled.button<{
  isActive: boolean;
  width: string;
  firstStep?: boolean;
  disabled?: boolean;
}>`
  width: ${({ width }) => width};
  height: 112px;
  border: ${({ isActive }) =>
    isActive ? "2px solid #ff6633" : "2px solid #c8c8c8"};
  ${({ disabled }) => !disabled && "cursor: pointer"};
  border-radius: 10px;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s linear;
  background: ${({ isActive }) =>
    isActive ? "rgba(255, 102, 51, 0.16)" : "#ffffff"};
  ${({ disabled }) => disabled && "opacity: 0.5"};

  &:hover {
    border: ${({ isActive, disabled }) =>
      isActive && !disabled ? "2px solid #ff6633" : "2px solid #000000"};
  }

  @media screen and (max-width: 480px) {
    /* ${({ firstStep }) => "width: 100%"} */
  }

  @media only screen and (max-width: 420px) {
    width: ${({ firstStep }) => (firstStep ? "100%" : "136px")};
    height: 88px;
  }

  @media screen and (max-width: 375px) {
    /* width: ${({ firstStep }) => (firstStep ? "100%" : "156px")}; */
    /* ${({ firstStep }) => !firstStep && "height: 88px;"} */
  }

  @media screen and (max-width: 320px) {
    /* width: ${({ firstStep }) => (firstStep ? "100%" : "136px")}; */
    /* ${({ firstStep }) => !firstStep && "height: 88px"}; */
  }

  .selectedIcon {
    transition: all 0.3s linear;
    opacity: ${({ isActive }) => (isActive ? "1" : "0")};
    margin: 10.5px 12.5px 0 0;
    width: 24px;
    height: 24px;
    align-self: flex-end;

    @media screen and (max-width: 320px) {
      margin: 10px 12px 0 0;
    }
  }

  .buttonTitle {
    color: ${({ isActive }) => (isActive ? "#ff6633" : "#000000")};

    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    margin: 16px;
    transition: all 0.3s linear;

    @media screen and (max-width: 420px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const ContentTitle = styled.p`
  margin-bottom: 16px;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11;
  letter-spacing: -0.5px;
  color: #000;

  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const Paragraph = styled.p`
  width: 378px;

  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 24px;

  @media screen and (max-width: 480px) {
    padding: 0 0 0 24px;
  }

  @media screen and (max-width: 320px) {
    padding: 20px 16px 0 16px;
    font-size: 16px;
    width: 100%;
  }
`;

export const SubmitButton = styled(Button)`
  width: 280px;
  height: 56px;
  /* color: #ffffff; */
  /* background: #ff6633; */
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;

  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  transition: all 0.3s linear;
  margin: 0;

  @media screen and (max-width: 480px) {
    width: calc(100% - 48px);
    margin: 0 24px 24px;
  }

  @media screen and (max-width: 320px) {
    width: calc(100% - 32px);
    margin: 0 16px 24px;
  }

  &:hover {
    box-shadow: 0 2px 14px 0 rgba(166, 60, 24, 0.3);
  }

  /* &:disabled { */
  /* background: #ff6633; */
  /* } */
`;

export const MainWrapper = styled.div<{ navbar?: number; footer?: number }>`
  /* width: 100%; */
  /* display: flex; */
  /* min-height: calc(100vh - 80px); */

  width: 100%;
  display: flex;
  min-height: calc(
    100vh - ${({ navbar, footer }) => Number(footer) + Number(navbar) || 0}px
  );
  flex-wrap: wrap;

  .row2 {
    width: 50%;
    background-color: white;
    /* position: absolute; */
    /* left: 0; */
    height: 100%;
  }

  .row3 {
    width: 50%;
    height: 100%;
    //padding: 110px 0 0 25px;
    img {
      /* position: absolute; */
      /* right: 0; */
      width: 100%;
      /* height: calc(100vh - 165px); */
      height: 100%;
      object-fit: cover;
      object-position: center;
      object-position: left;
    }
  }

  @media only screen and (max-width: 998px) {
    img {
      display: none;
    }

    .row2 {
      width: 100%;
    }

    .row3 {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    min-height: 100vh;
  }
`;

export const MapWrapper = styled.div`
  width: 50%;
`;

export const Title = styled.div`
  font-size: 20px;
  line-height: 1.2;
  margin: 24px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    padding: 24px 0 16px 24px;
    background: #ffffff;
    margin: 24px 0 0;
  }

  @media screen and (max-width: 320px) {
    padding: 24px 0 16px 16px;
    background: #ffffff;
    margin: 24px 0 0;
  }
`;

export const LeftPart = styled.div`
  width: calc(100% - 500px);
`;

export const RegisterForm = styled.div`
  width: 100%;
  /* background-color: white; */
  /* padding: 20px; */
  /* border-radius: 8px; */
`;

export const FormTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  line-height: 1.11;
  letter-spacing: -1px;
  margin-bottom: 18px;
`;

export const FormDesc = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 16px;
`;

export const FactAddress = styled.div``;

export const InputsTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  margin: 16px 0;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 16px 12px;
  margin-bottom: 24px;
`;

export const InputWrapper = styled.label<{
  active?: boolean;
  labelSize?: string;
  error?: boolean;
  emailError?: boolean;
}>`
  width: 100%;
  height: 48px;
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  /* align-items: center; */
  display: flex;
  /* overflow: hidden; */
  border: ${({ error }) =>
    error ? "2px solid #ff3358" : "1px solid var(--color-light-gray)"};
  /* ${({ active }) =>
    active
      ? // styles.mainColor
        "var(--color-gray)"
      : "var(--color-light-gray)"}; */
  display: block;
  position: relative;
  transition-duration: 0.5s;
  transition: border 0.25s linear;
  transition: 0.25s linear;
  margin-bottom: 0;

  &:hover {
    ${({ error }) => (!error ? "border: 1px solid #222222" : "")};
  }

  &:focus-within {
    border: 2px solid #222222;
  }

  & {
    .error {
      border: 2px solid #ff3358;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;
    margin-bottom: ${({ error }) => (error ? "15px" : "0px")};
  }

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      width: 100%;
      display: inline-block;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 0.5 : 0.5)};
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      ${({ labelSize }) =>
        labelSize && `font-size: ${labelSize}; height:${labelSize};`}
    }
  }

  :not(.date-input) input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
    overflow: hidden;
  }

  .ant-picker {
    padding: 0;
    width: 100%;
    height: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
  }

  /* &:focus-within,
  &.active  */
  & {
    /* padding: 8px 16px 12px 16px; */
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 18px;
      margin: auto;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;
      }
    }
  }
`;

export const SearchRequisite = styled.div`
  height: 100%;
  width: 55px;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  svg {
    width: 24px;
    height: 24px;
    display: flex;
  }

  select {
    border: 0;
    width: calc(100% - 36px);
    line-height: 16px;
    font-size: 15px;
    outline: 0;
  }

  p {
    margin-left: 12px;
    font-weight: 600;
  }
`;

export const SelectHeader = styled.div``;

export const SelectBody = styled.div``;

export const AddUserPart = styled.div`
  font-size: 14px;
  line-height: 1.43;
  margin: 0 0 24px;

  u {
    cursor: pointer;
  }
`;

export const InputsWrapperStep2 = styled.div``;

export const RemoveUser = styled.div`
  display: flex;
  align-items: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 5px;
`;

export const ErrorMsg = styled.div`
  color: red;
  width: 100%;
  font-size: 14px;
  line-height: 16px;
`;

export const AddCardsWrapper = styled.div`
  width: 648px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;

  @media screen and (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

export const AddCardWrapper = styled.div`
  width: 90%;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #c8c8c8;

  button {
    padding: 20px 21px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
  }
`;

export const AddIconPart = styled.div`
  width: 64px;
  height: 64px;
  margin-bottom: 24px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const AddTitle = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  margin-bottom: 8px;
`;

export const AddDesc = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.15px;
  margin-bottom: 24px;
  color: #757575;
`;

export const VehicleBox = styled.div`
  padding: 34px 0 0;

  .title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 480px) {
    background: #ffffff;
    padding: 20px 16px;
  }
`;

export const VehicleList = styled.div``;

export const VehicleItem = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  padding: 8px 0;
  cursor: pointer;
  color: black;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  &:last-child {
    border: 0;
  }

  .data {
    margin-right: auto;
  }

  .year {
    opacity: 0.5;
    font-size: 12px;
    line-height: 1;
    color: #222222;
    margin-bottom: 4px;
  }

  .data-line {
    /* font-weight: 400;
    font-size: 14px;
    line-height: 17px; */
    margin-right: auto;

    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }

  .number {
    color: black;
  }

  .info-required {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #ff3358;
    margin-right: 10px;
  }

  .arrow-right {
    display: flex;
    margin-left: 10px;
  }
`;

export const VehicleMainTitle = styled.div`
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;
  gap: 10px;

  @media screen and (max-width: 480px) {
    font-size: 20px;
  }

  button {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #f63;
    /* margin-left: auto; */
    border-radius: 10px;
    border: solid 1px rgba(255, 102, 51, 0);
    background-color: rgba(255, 102, 51, 0.16);
    padding: 4px 8px;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      margin-right: 4px;
    }
  }
`;

export const GoBackBtn = styled(GoBack)`
  padding: 9px 15px 9px 21px;

  span {
    margin-right: 10px;
  }

  svg {
    margin: 0;
  }
`;

export const SkipWrapper = styled.div`
  margin-top: 40px;

  @media screen and (max-width: 480px) {
    padding: 0 24px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 320px) {
    padding: 20px 16px 0 16px;
    font-size: 16px;
    width: 100%;
  }
`;

export const SkipText = styled.div`
  margin-bottom: 24px;
  font-size: 20px;
  line-height: 1.2;
  color: #000;
  width: 464px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @media screen and (max-width: 320px) {
    font-size: 16px;
  }
`;

export const SkipBtn = styled(Button)`
  width: 296px;
  padding: 20px;
  margin: 0;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const InnPart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;

  input {
    height: 48px;
    width: 320px;
    border-radius: 10px;
    background-color: white;
    padding: 16px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #e5e5e5;
    transition: all 0.3s linear;

    &:hover {
      border: 1px solid #222222;
    }

    &:focus {
      border: 1px solid #222222;
    }

    &.error {
      border: 1px solid #ff3358;
    }

    @media screen and (max-width: 480px) {
      width: 100%;
    }
  }

  .error {
    color: #ff3358;
    font-size: 14px;
  }
`;

export const InnDataPart = styled.div`
  font-size: 14px;
  line-height: 1.14;
  width: 100%;
  /* margin-bottom: 16px; */
  @media screen and (max-width: 480px) {
    /* padding: 0 24px; */
    padding: 5px 10px 10px;
  }

  @media screen and (max-width: 320px) {
    /* padding: 0 16px; */
  }
`;

export const RegisterUserWrapper = styled.div<{ isHidden?: boolean }>`
  width: 100%;
  display: flex;
  gap: 24px 8px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 24px;
  transition: all 0.3s;
  overflow: ${({ isHidden }) => (isHidden ? "hidden" : "unset")};
  /* height: ${({ isHidden }) => (isHidden ? 0 : "210px")}; */

  @media screen and (max-width: 480px) {
    gap: 8px;
    height: ${({ isHidden }) => (isHidden ? 0 : "fit-content")};
    padding: 0 24px 24px;
    background: #ffffff;
  }

  @media screen and (max-width: 320px) {
    padding: 0 16px 24px;
    background: #ffffff;
  }
`;

export const ContentTitleWrapper = styled.div`
  align-self: start;
`;

export const ErrorLine = styled.div`
  color: red;
  /* font-size: 12px; */
  position: absolute;
  bottom: -20px;
  left: 0;
`;
