import styled from "styled-components";

export const MyOrdersWrapper = styled.div`
  width: 100%;
`;

export const OrdersList = styled.div`
  width: 100%;
  max-width: 974px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  padding-bottom: 32px;

  .filterMobileContainer {
    display: none;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    max-height: unset;
    padding: 32px 8px;
  }

  @media screen and (max-width: 480px) {
    .filterMobileContainer {
      display: flex;
      width: 100%;
    }
  }
`;

export const OrdersFilter = styled.div`
  display: flex;
  gap: 16px;
  align-self: center;
  margin-left: 16px;

  @media screen and (max-width: 480px) {
    margin-left: 0;
  }

  .filterItem {
    padding: 8px 10px;
    text-align: center;
    height: 32px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    font-size: 14px;
    line-height: 1.14;
    color: #757575;
    transition: all 0.3s linear;
    cursor: pointer;
    font-weight: 400;

    &:hover {
      color: #ff6633;
      border: 1px solid #ff6633;
    }
  }

  .active {
    border: 1px solid #ff6633;
    color: #ffffff;
    background-color: #ff6633;

    &:hover {
      border: 1px solid #ff6633;
      color: #ffffff;
      background-color: #ff6633;
    }
  }
`;
