import React, { memo, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import useHttpRequest from "../../../hooks/useHttpRequest";
import getCards from "../../../redux/modules/getCards";
import { RootState } from "../../../redux/store/store";
import { InputBox } from "../../form";
import PopUpContainer from "../popUpContainer";
import { BankData, CardDetailData, PopUpDesc } from "./styles";
import { ENDPOINT } from "../../../constants/general";

const AddCardInAppointExecutorPopUp: React.FC<any> = ({ close }) => {
  const user = useAppSelector((state: RootState) => state.auth.user);

  const dispatch = useDispatch();

  const [pan, setPan] = useState("");
  const [holder, setHolder] = useState("");
  const [cvv, setCvv] = useState("");
  const [expiresAt, setExpiresAt] = useState("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [addCardProcess, addCardRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      if (res?.data?.ErrCode && !res?.data?.Success) {
        // toast.error(
        //   res.data.ErrCode || "Что-то пошло не так. Попробуйте еще раз"
        // );
      } else {
        // toast.success("Карта успешно добавлена");
        close();
      }
      user?.customer?.refId && dispatch(getCards(user?.customer?.refId));
    },
    onError: (e: any) => {
      // toast.error(
      //   e?.response?.data?.message || "Что-то пошло не так. Попробуйте еще раз"
      // );
    },
  });

  const addCard = () => {
    addCardRequest({
      method: "POST",
      url: `${ENDPOINT}/pay/${user?.customer?.refId}/cards`,
      data: {
        pan: pan,
        emonth: Number(expiresAt.slice(0, 2)),
        eyear: Number(expiresAt.slice(2, 4)),
        cvv,
        holder,
      },
    });
  };

  if (!user) return <div>Загрузка...</div>;

  return (
    <PopUpContainer
      title="Привязать карту"
      button="Добавить карту"
      close={close}
      onSubmit={addCard}
      disabled={
        !(
          pan.length === 16 &&
          expiresAt.length === 4 &&
          cvv.length === 3 &&
          holder.length > 3
        )
      }
    >
      <CardDetailData>
        <BankData>
          {/* <img src={bankData} alt="" /> */}
          {/* <div className="text">
            Все платежи обрабатываются на стороне АО «Тинькофф Банк» и надежно
            защищены.
          </div> */}
        </BankData>
        <InputBox
          required
          label="Номер карты"
          type="card-number"
          //   prefix={<img src={cardLogo} alt="logo" />}
          autoFocus
          value={pan}
          onValueChange={(e: any) => setPan(e.value)}
        />
        <div className="data">
          <div className="half">
            <InputBox
              required
              type="expire-date"
              label="ММ/ГГ"
              value={expiresAt}
              onValueChange={(e: any) => setExpiresAt(e.value)}
            />
          </div>
          <div className="half">
            <InputBox
              required
              type="cvc"
              label="Код CVV"
              placeholder="000"
              value={cvv}
              onValueChange={(e: any) => setCvv(e.value)}
            />
          </div>
        </div>
        <InputBox
          required
          label="Данные держателя"
          placeholder="Иван Иванов"
          value={holder}
          onChange={(e: any) => setHolder(e.target.value)}
        />
      </CardDetailData>
      <PopUpDesc>
        Нажимая кнопку «Добавить карту» вы привязываете указанную банковскую
        карту для расчетов в сервисе Rukki и принимаете
        <span> Правила использования </span>
        сервиса
      </PopUpDesc>
    </PopUpContainer>
  );
};

export default memo(AddCardInAppointExecutorPopUp);
