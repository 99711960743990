import { useState } from "react";
import { httpRequest } from "../utils/httpRequest";

interface Props {
  onLoading?: any;
  onSuccess?: any;
  onError?: any;
  onFinal?: any;
  cleanTimeout?: any;
  log?: boolean;
}

interface IProcess {
  loading: boolean;
  success: boolean;
  error: boolean;
  data: any;
}

const useHttpRequest = (
  props?: Props,
): [IProcess, (data: any) => void, () => void] => {
  const defaultState = {
    loading: false,
    success: false,
    error: false,
    data: null,
  };

  const [process, setProcess] = useState<IProcess>(defaultState);

  const makeRequest = (data: any) => {
    setProcess({
      loading: true,
      success: false,
      error: false,
      data: null,
    });
    props?.onLoading?.();

    httpRequest(data)
      .then((res) => {
        setProcess({
          loading: false,
          success: true,
          error: false,
          data: res.data,
        });
        props?.onSuccess?.(res);
      })
      .catch((err) => {
        setProcess({
          loading: false,
          success: false,
          error: true,
          data: err,
        });

        props?.onError?.(err);
      })
      .finally(() => {
        if (props && props?.cleanTimeout) {
          setTimeout(() => {
            setProcess(defaultState);
            props?.onFinal?.();
          }, props?.cleanTimeout);
        } else {
          props?.onFinal?.();
        }
      });
  };
  return [process, makeRequest, () => setProcess(defaultState)];
};

export default useHttpRequest;
