import React, { memo, useState } from "react";
import { Button } from "../../components/form/styles";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/icons/user-avatar-48-clean-contractor.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icons-expand-more.svg";
import { ReactComponent as VehicleIcon } from "../../assets/icons/icons-vehicles-hoist.svg";
import {
  Text,
  ContentPart,
  SelectWrapper,
  Wrapper,
  SelectBody,
  Title,
} from "./styles";
import avatar_worker from "../../assets/images/avatar-worker.png";
import { useAppSelector } from "../../redux/hooks";
import SingleManagerPopUp from "../popUp/singleManagerPopUp";

interface IProps {
  employees?: boolean;
  vehicles?: boolean;
}

interface IListItem {
  title: string;
  label: string;
  name: string;
}

export const ManagerRow = ({
  value: {
    id,
    refId,
    name, //"Беков Астемир"
    inWork, //"2"
    orderAmount, //0
    orderTotal, //"18"
    phoneNumber, //"+76505551234"
    doneAmount, //0
    companyRole,
  },
  index,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SelectWrapper
        key={index}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        <div className="selectGroup">
          <div className="icon">
            {/* {employees ?  */}
            <UserIcon />
            {/* : <VehicleIcon />} */}
          </div>

          <SelectBody>
            <label>{orderTotal} заказа</label>
            <div className="text">{name}</div>
            {/* <b>{String(companyRole)}</b> */}
            {/* <select name="sorts" defaultValue="" id="sorts">
                  <option value="" disabled hidden>
                    <img src={avatar_worker} alt="icons" /> {name}
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select> */}
          </SelectBody>
        </div>
        <div className="arrowIcon">
          <ArrowIcon />
        </div>
      </SelectWrapper>
      {isOpen && (
        <SingleManagerPopUp
          id={id}
          close={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

const ManagementPagesDataList: React.FC<IProps> = ({ employees, vehicles }) => {
  const { data: managers } = useAppSelector((state) => state.managers);
  const buildVehicleMock: IListItem[] = [
    {
      title: "2012 год выпуска",
      label: "2012 год выпуска",
      name: "Бульдозер Komatsu PC300-7, госномер А123ВС 99RUS",
    },
    {
      title: "2014 год выпуска",
      label: "2014 год выпуска",
      name: "Автокран Volvo RC4000, госномер А123ВС 99RUS",
    },
  ];

  const renderList = (listData: IListItem[]) => {
    return listData.map((value, index) => {
      return <ManagerRow value={value} index={index} />;
    });
  };

  return (
    <>
      <Wrapper>
        <Text>
          <div>{employees ? "Список сотрудников" : "Список техники"}</div>
        </Text>

        <ContentPart>
          <Title>
            <div>{employees ? "Менеджеры" : "Строительная техника"}</div>
          </Title>
          {managers?.users && renderList(managers?.users)}
          {vehicles && renderList(buildVehicleMock)}
        </ContentPart>
      </Wrapper>
    </>
  );
};

export default memo(ManagementPagesDataList);
