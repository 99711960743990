import styled from "styled-components";

export const Content = styled.div`
  display: flex;
  gap: 16px;
  padding-top: 24px;

  @media screen and (max-width: 768px) {
    gap: 8px;
    padding-top: 16px;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:nth-child(odd) {
    width: 392px;
  }

  &:nth-child(even) {
    width: 100%;
    max-width: 984px;
  }

  .stickyContainer {
    position: sticky;
    top: 8px;
  }

  @media screen and (max-width: 998px) {
    &:nth-child(odd) {
      width: 320px;
    }
  }

  @media screen and (max-width: 480px) {
    &:nth-child(odd) {
      display: none;
    }
  }
`;
