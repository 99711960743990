import styled from "styled-components";
import { styles } from "../../styles/styles";
import { Slider } from "antd";

export const CharacteristicsBG = styled.div<{
  center?: boolean;
  isVisible?: boolean;
}>`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  opacity: ${({ isVisible }) => (isVisible ? "100%" : "0%")};

  @media screen and (max-width: 480px) {
    align-items: flex-end;
  }
`;

export const CharacteristicsPopUpContainer = styled.div<{
  position?: string;
  center?: boolean;
  isVisible?: boolean;
}>`
  display: ${({ isVisible }) => (isVisible ? "flex" : "none")};
  flex-direction: column;
  gap: 8px;
  width: 320px;
  height: fit-content;
  max-height: 580px;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 20px;
  padding: 16px;
  box-shadow: 0 2px 45px rgb(0 0 0 / 12%);
  z-index: 99;
  transition: all 0.35s linear;

  @media screen and (max-width: 576px) {
    /* position: fixed; */
    max-height: 100vh;
    bottom: 0;
  }

  @media screen and (max-width: 480px) {
    position: fixed;
    left: 0;
    bottom: ${({ isVisible }) => (isVisible ? "0px" : "-500px")};
    width: 100%;
    z-index: 10;
    border-radius: 20px 20px 0 0;
  }
`;

export const PopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  .text {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #222222;
  }

  svg {
    cursor: pointer;
  }
`;

export const PopUpSlider = styled(Slider)`
  .ant-slider-track {
    /* background: #ff6633 !important; */
    background: #d9d9d9 !important;
  }
  .ant-slider-rail {
    /* background: #ececec; */
    background: #d9d9d9;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    margin-top: -9px;
    /* border: 2px solid #ff6633; */
    border: 0;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
    background-color: #f5f5f5;
    /* &::after {
      background: #ff6633;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      top: 3px;
      left: 3px;
    } */
  }

  &:hover {
    .ant-slider-handle {
      /* border: 2px solid #ff6633 !important; */
      border: 0;
    }
  }
`;

export const DescriptionBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }

  textarea {
    width: 100%;
    outline: 0;
    border: 1px solid rgba(36, 39, 44, 0.2);
    border-radius: 10px;
    resize: none;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const InputTitle = styled.div`
  width: 100%;
  opacity: 0.5;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
`;

export const InputsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 380px;
  gap: 24px;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  @media screen and (max-width: 480px) {
    overflow-y: auto;
    overflow-x: hidden;
    gap: 8px;
    max-height: 65vh;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .ant-slider-with-marks {
    width: 88%;
    align-self: center;
  }
`;

export const SwitcherWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  /* padding: 15px 0; */
`;

export const Switch = styled.div`
  display: flex;
  gap: 4px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const RadioItem = styled.div`
  padding: 6px 18px;
  border: 1px solid
    ${({ active }: { active?: boolean }) =>
      active ? styles.mainColor : "#e3e3e3"};
  border-radius: 10px;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? styles.mainColor : "white"};
  color: ${({ active }: { active?: boolean }) => (active ? "white" : "black")};
`;

export const SubmitButton = styled.div`
  width: 288px;
  height: 56px;
  background: #ff6633;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 rgba(166, 60, 24, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;
