import { useEffect, useRef, useState } from "react";
import {
  SelectDropdown,
  SelectIconPart,
  SelectListItem,
  SelectListItemVehicle,
  SelectListWrapper,
  SelectMainWrapper,
  SelectText,
  SelectWrapper,
} from "./styles";
import { ReactComponent as ArrowBottomIcon } from "../../assets/icons/arrow-bottom.svg";

interface ISelectProps {
  list?: Array<{ label: string; value: string | number; additional?: any }>;
  Icon?: any;
  value?: string;
  searchValue?: string | null;
  placeholder?: string;
  emptyTag?: string;
  onChange?: ({
    name,
    value,
    label,
  }: {
    name: string;
    value: string | number;
    label?: string;
  }) => void;
  onInputChange?: any;
  name: string;
  vehicle?: boolean;
  listMarker?: boolean;
  width?: string;
  editable?: boolean;
  disabled?: boolean;
}

const Select: React.FC<ISelectProps> = ({
  list,
  Icon,
  value,
  searchValue,
  placeholder,
  onChange,
  name,
  vehicle,
  emptyTag,
  listMarker = true,
  editable = false,
  width,
  onInputChange,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (isOpen) {
      const handler = (e: MouseEvent) => {
        if (
          selectRef?.current &&
          !selectRef?.current.contains(e.target as Node)
        ) {
          setIsOpen(false);
        }
      };

      window.addEventListener("click", handler);
      return () => {
        window.removeEventListener("click", handler);
      };
    }
  }, [isOpen]);

  return (
    <SelectMainWrapper width={width}>
      <SelectWrapper
        open={isOpen}
        // onClick={(e) => {
        //   e.stopPropagation();
        //   setIsOpen(!isOpen);
        // }}
        onFocus={(e) => {
          !disabled && setIsOpen(!isOpen);
        }}
        onBlur={() => {
          !disabled && setIsOpen(!isOpen);
        }}
        ref={selectRef}
      >
        <SelectIconPart>{Icon && <Icon />}</SelectIconPart>
        <SelectText
          readOnly={!editable}
          value={value ? value : searchValue ? searchValue : ""}
          placeholder={placeholder}
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          disabled={disabled}
        />
        <SelectDropdown>
          <ArrowBottomIcon className="arrowIcon" />
        </SelectDropdown>
      </SelectWrapper>
      {isOpen ? (
        <SelectListWrapper>
          {vehicle ? (
            list?.length ? (
              list?.map(
                (
                  {
                    label,
                    value,
                    additional,
                  }: {
                    label: string;
                    value: string | number;
                    additional?: {
                      stateNumber: string;
                      Icon?: any;
                    };
                  },
                  index: number,
                ) => (
                  <SelectListItemVehicle
                    onMouseDown={() => {
                      !disabled && onChange?.({ name, value });
                    }}
                    key={`${name}_${index}`}
                  >
                    {additional?.Icon ? additional?.Icon : <></>}
                    <div className="vehicleInfo">
                      <div className="vehicleName">{label}</div>
                      <div className="vehicleNumber">
                        {additional?.stateNumber}
                      </div>
                    </div>
                  </SelectListItemVehicle>
                ),
              )
            ) : (
              <SelectListItemVehicle>
                <div className="centerItem">
                  Вы не добавили технику, соответствующую условиям
                </div>
              </SelectListItemVehicle>
            )
          ) : (
            list?.map(
              (
                { label, value }: { label: string; value: string | number },
                index: number,
              ) => (
                <SelectListItem
                  onMouseDown={(e) => {
                    onChange?.({ name, value, label });
                  }}
                  key={`${value}_${index}`}
                >
                  {listMarker && <div className="dash">–</div>} {label}
                </SelectListItem>
              ),
            )
          )}
        </SelectListWrapper>
      ) : (
        <></>
      )}
    </SelectMainWrapper>
  );
};

export default Select;
