import React, { memo, useEffect, useState } from "react";
import {
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  InputDropdown,
  InputsWrapper,
  InputWrapper,
  MainWrapper,
  MapWrapper,
  RegisterForm,
  SelectWrapper,
  Wrapper,
  StyledPageHeader,
  AddAddressButton,
} from "./styles";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add-orange.svg";
import Button from "../../UI/Button";
import NumberFormat from "react-number-format";
import { ENDPOINT, GEOCODE_MAPS } from "../../constants/general";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";
import Input from "../../UI/Input";
import PageHeader from "../../UI/PageHeader";
import { DatePicker } from "antd";
import moment from "moment";
import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { styles } from "../../components/styles/styles";
import getDashboardObjects from "../../redux/modules/getDashboardObjects";
import { getAddressesAsync } from "../../redux/modules/getAddresses";
import { getCompany } from "../../redux/store/reducers/companySlice";
import { DebounceInput } from "react-debounce-input";
import { getPosition } from "../../redux/modules/getPosition";
import { getAddressesString } from "../../utils/getAddressString";

const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  addressList,
  setLocation,
  setAddress,
}: any) => {
  const [dropdown, setDropdown] = useState<boolean>(false);

  return (
    <InputWrapper>
      <DebounceInput
        debounceTimeout={500}
        element={"input"}
        type="text"
        value={value || ""}
        onChange={onChange}
        onFocus={() => setDropdown(true)}
        onBlur={() => setDropdown(false)}
        placeholder={placeholder}
      />

      {dropdown && !!addressList.length && (
        <InputDropdown>
          {addressList.map((address: any, index: number) => {
            const { city, county, road, house_number, town, village, hamlet } =
              address.address;
            const { lat, lon } = address;
            return address.addresstype !== "state" ? (
              <div
                key={`dropdownItem_${index}`}
                className="dropdownItem"
                onMouseDown={() => {
                  setLocation([Number(lat), Number(lon)]);
                  onChange({
                    target: {
                      name: "address",
                      value: `${city || town || village || hamlet}${
                        road
                          ? ", " +
                            getAddressesString({
                              road,
                              house_number,
                            })
                          : ""
                      }`,
                    },
                  });
                }}
              >
                <div className="top">
                  {road
                    ? getAddressesString({
                        road,
                        house_number,
                      })
                    : city || town || village || hamlet}
                </div>
                <div className="bottom">{county}</div>
              </div>
            ) : null;
          })}
        </InputDropdown>
      )}
    </InputWrapper>
  );
};

const DashboardAddObject: React.FC = () => {
  const [formData, setFormData] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const user = useAppSelector((state) => state?.auth.user);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [location2, setLocation2] = useState<any>([]);
  const [zoom, setZoom] = useState(9);
  const [address, setAddress] = useState<any>({});
  const [address2, setAddress2] = useState<any>({});
  const [addressList, setAddressList] = useState<any[]>([]);
  const [ymaps, setYmaps] = useState<any>(null);
  const [secondAddress, setSecondAddress] = useState<boolean>(false);

  const changeFormData = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const inputList = [
    {
      label: "Название объекта",
      name: "name",
    },
    {
      label: "Адрес",
      name: "address",
      type: "address",
    },
  ];

  const [addObjProcess, addObjRequest] = useHttpRequest({
    onSuccess: () => {
      if (user?.customer?.refId) {
        dispatch(getDashboardObjects(user.customer.refId));
        navigate("/dashboard/objects");
      }
    },
  });

  const addObject = () => {
    const { name, address, start_date, end_date } = formData;
    const objData = {
      nameEmpty: false,
      locationEmpty: false,
      startDateEmpty: false,
      endDateEmpty: false,
    };
    if (!name) {
      objData.nameEmpty = true;
    }
    if (!address) {
      objData.locationEmpty = true;
    }
    if (name && address && location.length === 2) {
      const obj = {
        title: name,
        latitude: location[0],
        longitude: location[1],
        address,
      };
      addObjRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${user?.customer?.refId}/objects`,
        data: obj,
      });
    }
  };

  const getUserLocation = (force?: boolean) => {
    window?.navigator?.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setCenter([latitude, longitude]);
        setLocation([latitude, longitude]);
        const empty = {};
        if (address === empty || force) {
          dispatch(getPosition({ lat: latitude, lon: longitude })).then(
            (result) => {
              if (result.payload.addressType === "building") {
                setAddress({
                  name: getAddressesString({
                    road: result.payload.address.road,
                    house_number: result.payload.address.house_number,
                  }),
                  city:
                    result.payload.address.city ||
                    result.payload.address.town ||
                    0,
                });
              }
            },
          );
        }
      },
      (err) => {},
    );
  };

  const getAddress = ([latitude, longitude]: any) => {
    dispatch(getPosition({ lat: latitude, lon: longitude }));
  };

  const getAddressList = (searchString: string) => {
    dispatch(getAddressesAsync({ text: searchString })).then((response) => {
      setAddressList(response.payload);
    });
  };

  useEffect(() => {
    ymaps?.setCenter([location[0], location[1]], 17, {
      duration: 1000,
      timingFunction: "linear",
    });
  }, [location]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getCompany(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  useEffect(() => {
    getUserLocation(true);
  }, []);

  return (
    <MainWrapper navbar={document.querySelector("#navbar")?.clientHeight || 0}>
      <Wrapper>
        <PageHeader>Добавить объект</PageHeader>
        <RegisterForm>
          <FormDesc>
            Укажите информацию, необходимую для создания объекта
          </FormDesc>

          <InputsWrapper>
            {inputList.map(({ label, name, type }, index) => (
              <>
                {name === "start_date" ? (
                  <p className="periodDesc">
                    Укажите период строительства объекта
                  </p>
                ) : null}
                {type === "address" ? (
                  <InputBox
                    value={formData?.[name]}
                    onChange={(e: any) => {
                      changeFormData(e);
                      getAddressList(e.target.value);
                    }}
                    placeholder={"Адрес объекта"}
                    addressList={addressList}
                    setLocation={setLocation}
                    setAddress={setAddress}
                  />
                ) : (
                  // <DebounceInput
                  //   debounceTimeout={500}
                  //   element={"input"}
                  //   type="text"
                  //   value={formData?.[name]}
                  //   onChange={(e: any) => {
                  //     changeFormData(e);
                  //   }}
                  // />
                  <Input
                    height="48px"
                    placeholder={label}
                    name={name}
                    onChange={(e: any) => {
                      changeFormData(e);
                      type === "address" && getAddressList(e.target.value);
                    }}
                    value={formData?.[name]}
                    // type={type}
                    // addressList={type === "address" && addressList}
                    // setLocation={setLocation}
                    // setAddress={setAddress}
                  />
                )}
              </>
            ))}
            {secondAddress && (
              <InputBox
                label={"Дополнительный адрес"}
                name={"second_address"}
                onChange={(e: any) => {
                  changeFormData(e);
                  getAddressList(e.target.value);
                }}
                value={formData?.["second_address"]}
                type={"address"}
                addressList={addressList}
                setLocation={setLocation2}
                setAddress={setAddress2}
              />
            )}
          </InputsWrapper>

          {errorMsg.length > 0 ? (
            <ErrorsWrapper>
              {errorMsg.map((msg: string) => (
                <ErrorMsg>{msg}</ErrorMsg>
              ))}
            </ErrorsWrapper>
          ) : (
            ""
          )}
          {/*{!secondAddress && (*/}
          {/*  <AddAddressButton onClick={() => setSecondAddress(true)}>*/}
          {/*    <PlusIcon /> Добавить адрес*/}
          {/*  </AddAddressButton>*/}
          {/*)}*/}

          <Button
            onClick={() => {
              addObject();
            }}
            height={"48px"}
            width="248px"
            disabled={!(formData.name && formData.address)}
          >
            Продолжить
          </Button>
        </RegisterForm>
      </Wrapper>

      <MapWrapper>
        <YMaps>
          <Map
            width={"100%"}
            height={"100%"}
            onLoad={(e) => {
              setYmaps(e);
            }}
            instanceRef={(e) => {
              setYmaps(e);
            }}
            defaultState={{ center, zoom: zoom }}
            state={{ center, zoom: zoom }}
            onClick={(e: any) => {
              const coords = e.get("coords");
              setLocation(coords);
              getAddress(coords);
            }}
          >
            {location?.length > 0 && (
              <Placemark
                // preset={"islands#circleDotIcon"}
                options={{
                  preset: "islands#circleDotIcon",
                  iconColor: styles.mainColor,
                  draggable: true,
                }}
                geometry={{
                  type: "Point",
                  coordinates: location,
                }}
                onDragEnd={(e: any) => {
                  const coordinates = e.get("target").geometry._coordinates;
                  setLocation(coordinates);
                  getAddress(coordinates);
                }}
              />
            )}
            {location2?.length > 0 && (
              <Placemark
                // preset={"islands#circleDotIcon"}
                options={{
                  preset: "islands#circleDotIcon",
                  iconColor: styles.mainColor,
                  draggable: true,
                }}
                geometry={{
                  type: "Point",
                  coordinates: location2,
                }}
                onDragEnd={(e: any) => {
                  const coordinates = e.get("target").geometry._coordinates;
                  setLocation(coordinates);
                  getAddress(coordinates);
                }}
              />
            )}
            <ZoomControl
              options={{
                position: { right: 10, top: 20 },
              }}
            />
          </Map>
        </YMaps>
      </MapWrapper>
    </MainWrapper>
  );
};

export default memo(DashboardAddObject);
