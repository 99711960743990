import styled from "styled-components";

export const UploadBox = styled.label`
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 8px;
  border: 2px dashed #e8e8e8;
  align-items: center;
  justify-content: center;
  height: 124px;
  line-height: 1;
  font-size: 13px;
  gap: 12px;
  color: rgba(0, 0, 0, 0.5);
  padding: 8px 16px;
  cursor: pointer;
  transition: all 0.3s linear;
  
  &:hover {
    border: 2px dashed #222222;
    box-shadow: 0px 0px 4px 2px #00000066;
  }
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  transition: all 0.3s linear;
  overflow: hidden;
  margin-top: 8px;
`;

