import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 24px 20px;
  width: 100%;
`;

export const ContentPart = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
`;

export const LeftData = styled.div`
  width: calc(100% - 492px);
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  overflow-y: auto;
  gap: 16px;

  @media screen and (max-width: 805px) {
    display: none;
  }
`;

export const RightData = styled.div`
  width: 472px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  height: fit-content;
  position: sticky;
  overflow: auto;
  top: 16px;

  @media screen and (max-width: 805px) {
    position: unset;
    width: 100%;
  }
`;

export const DataWrapper = styled.div`
  padding: 20px 20px 16px;
`;

export const Title = styled.h1`
  margin: 0 0 4px;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.5px;
`;

export const SubTitle = styled.div`
  margin: 4px 0 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
`;

export const OrderName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 0.83;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 4px;

  img {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }
`;

export const BudgetDeadline = styled.div`
  display: flex;
  gap: 22px 0;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const TitleValue = styled.div`
  width: 50%;

  .title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 7px;
  }

  .value {
    font-size: 15px;
    line-height: 1.07;
  }
`;

export const Desc = styled.div`
  margin-bottom: 16px;

  .title {
    font-size: 16px;
    line-height: 1.25;
  }

  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
`;

export const Address = styled(Desc)`
  margin: 0;
`;

export const MapWrapper = styled.div`
  height: 350px;
`;
