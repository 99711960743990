import React, { memo, useEffect } from "react";
import { Wrapper, CanvasContainer } from "./styles";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  scales,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar } from "react-chartjs-2";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import getDashboardOrders from "../../redux/modules/getDashboardOrders";
import { useLocation } from "react-router-dom";
import OrdersChart from "./ordersChart";
import VehiclesChart from "./vehiclesChart";
import { getDataLensUrl } from "../../redux/store/reducers/profileSlice";
import { getCompany } from "../../redux/store/reducers/companySlice";
import Button from "../../UI/Button";
import OrdersEmptyTag from "../../components/ordersEmptyTag";

ChartJS.register(BarElement, CategoryScale, LinearScale);

const Analytics: React.FC = () => {
  const dispatch = useAppDispatch();

  const myOrdersTotal = useAppSelector((state) => state.order.myOrdersTotal);
  const user = useAppSelector((state) => state.auth.user);
  const companyData = useAppSelector((state) => state.company.companyData);
  // useEffect(() => {
  //   // if (user?.customer?.refId && location.pathname.includes("orders")) {
  //   //   dispatch(
  //   //     getDashboardOrders(user?.customer?.refId, {
  //   //       filterParams: {
  //   //         from: null,
  //   //         to: null,
  //   //         customers: null,
  //   //         vehicle: null,
  //   //         staff: null,
  //   //       },
  //   //       sort: ["-date"],
  //   //     })
  //   //   );
  //   // }
  //   if (user?.customer?.refId) {
  //     dispatch(getDataLensUrl({ refId: user?.customer?.refId }));
  //   }
  // }, [user?.customer?.refId]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getCompany(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  return (
    <PageWrapper flexDirection="column">
      {companyData?.requisites?.inn && myOrdersTotal > 0 ? (
        <>
          <PageHeader>Аналитика</PageHeader>
          {/* <CanvasContainer>
        {location.pathname.includes("orders") ? (
          <OrdersChart data={data} />
        ) : (
          <VehiclesChart />
        )}
        {location.pathname.includes("vehicles") && (
          <div className="legend">
            <div className="legendItem">
              <div className="color"></div> Работала
            </div>
            <div className="legendItem">
              <div className="color"></div>Стояла
            </div>
            <div className="legendItem">
              <div className="color"></div>Неизвестно
            </div>
          </div>
        )}
      </CanvasContainer> */}

          <CanvasContainer>
            <iframe
              src={`${process.env.REACT_APP_DATALENS_LINK}?${process.env.REACT_APP_DATALENS_INN_PARAM_NAME}=${companyData?.requisites?.inn}`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
            ></iframe>
          </CanvasContainer>
        </>
      ) : (
        <OrdersEmptyTag />
      )}
    </PageWrapper>
  );
};

export default memo(Analytics);
