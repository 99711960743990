import React, { useCallback, useEffect, useState } from "react";
import {
  InnDataPart,
  InnPart,
  InputsWrapper,
  RegisterForm,
  RegisterUserWrapper,
  SubmitButton,
  Title,
} from "./styles";
import useHttpRequest from "../../hooks/useHttpRequest";
import { ENDPOINT } from "../../constants/general";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMe } from "../../redux/store/reducers/authSlice";
import { InputBox } from "./InputBox";
import { useNavigate } from "react-router-dom";

import {
  fetchSendRespond,
  getTechTask,
  selectWorker,
} from "../../redux/store/reducers/orderSlice";
import { updateProfileInfo } from "../../redux/store/reducers/profileSlice";
import createCompany from "../../redux/modules/createCompany";
import { clearRegistrationSlice } from "../../redux/store/reducers/registrationSlice";
import { getCountryByPhone } from "../../utils/getCountryByPhone";
import { CustomerRole } from "../../types";
import {
  getCompany,
  getRequisitesByInn,
} from "../../redux/store/reducers/companySlice";
import { DebounceInput } from "react-debounce-input";

const inputList = [
  {
    label: "Фамилия",
    name: "last_name",
    required: true,
    width: "calc((100% - 16px) / 3)",
    lettersOnly: true,
  },
  {
    label: "Имя",
    name: "first_name",
    required: true,
    width: "calc((100% - 16px) / 3)",
    lettersOnly: true,
  },
  {
    label: "Отчество",
    name: "middle_name",
    width: "calc((100% - 16px) / 3)",
    lettersOnly: true,
  },
  {
    label: "Телефон",
    name: "phone_number",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "tel",
  },
  {
    label: "Электронная почта",
    name: "email",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "email",
  },
  {
    label: "Страна",
    name: "country",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "country",
  },
  {
    label: "Город",
    name: "city",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "city",
  },
  {
    label: "БИК",
    name: "bik",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "bik",
  },
  {
    label: "Расчетный счет",
    name: "rs",
    width: "calc((100% - 8px) / 2)",
    required: true,
    type: "rs",
  },
];

const RegisterOrg: React.FC<{
  onChangeStep: (step: number) => void;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  role: "customer" | "worker";
  setRole: React.Dispatch<React.SetStateAction<CustomerRole>>;
}> = ({ onChangeStep, role, setRole, setStep }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const auth = useAppSelector((state) => state.auth);
  const registration = useAppSelector((state) => state.registration);
  const { user, fireBaseData } = auth;
  const company = useAppSelector((state) => state.company.companyData);
  const refId = fireBaseData?.user?.refId;
  const [isShowCustomerFields, setIsShowCustomerFields] = useState(false);
  const [customerFormData, setCustomerFormData] = useState<any>({});
  const [companyRegErr, setCompanyRegErr] = useState<boolean>(false);
  const [requisitesFormData, setRequisitesFormData] = useState<any>({});
  const [registrationPending, setRegistrationPending] =
    useState<boolean>(false);

  const [searchRequisiteProcess, searchRequisiteRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const data: any = res?.data;
      if (data?.isExist) {
        setCompanyRegErr(true);
        setRequisitesFormData((prev: any) => ({
          ...prev,
          full_name: res?.data?.full_name,
          short_name: res?.data?.short_name,
          inn: res?.data?.inn,
          kpp: res?.data?.kpp,
          full_name_representative: res?.data?.chief,
          representative_position: res?.data?.chief_position,
          ogrn: res?.data?.ogrn,
          legal_address: res?.data?.address,
        }));
      } else {
        companyRegErr && setCompanyRegErr(false);
        setRequisitesFormData((prev: any) => ({
          ...prev,
          full_name: res?.data?.full_name,
          short_name: res?.data?.short_name,
          inn: res?.data?.inn,
          kpp: res?.data?.kpp,
          full_name_representative: res?.data?.chief,
          representative_position: res?.data?.chief_position,
          ogrn: res?.data?.ogrn,
          legal_address: res?.data?.address,
        }));
        setIsShowCustomerFields(!!data);
      }
    },
    onError: () => {
      setIsShowCustomerFields(false);
    },
  });

  const searchRequisite = (inn = "") => {
    searchRequisiteRequest({
      url: `${ENDPOINT}/api/customers/find-by-inn?q=${inn
        .trim()
        .replaceAll(" ", "")}`,
      method: "POST",
    });
  };

  const submitHandler = useCallback(async () => {
    const formData = {
      firstName: customerFormData.first_name,
      lastName: customerFormData.last_name,
      middleName: customerFormData.middle_name,
      city: customerFormData.city,
      country: customerFormData.country,
      phoneNumber: customerFormData.phone_number,
      email: customerFormData.email,
      role: role,
      rs: customerFormData.rs,
      bik: customerFormData.bik,
    };
    const {
      inn,
      okpo,
      kpp,
      ogrn,
      actual_address: actualAddress,
      full_name: fullName,
      legal_address: legalAddress,
      short_name: companyName,
    } = requisitesFormData;

    if (
      user?.customer?.refId &&
      formData.firstName &&
      formData.lastName &&
      formData.city?.text &&
      formData.country?.text &&
      formData.email
    ) {
      let data: any = {
        title: companyName,
        requisites: {
          actualAddress,
          fullName,
          legalAddress,
          companyName,
          inn,
          okpo: Number(okpo),
          kpp,
          email: formData.email,
          bik: formData.bik.replaceAll(" ", ""),
          rs: formData.rs.replaceAll(" ", ""),
          ogrn,
        },
        user: {
          customer: {
            firstName: formData.firstName,
            secondName: formData.lastName,
            patronymic: formData.middleName,
            city: formData.city?.text,
            country: formData.country?.text,
            email: formData.email,
          },
          source: "web",
        },
      };
      setRegistrationPending(true);
      await dispatch(
        updateProfileInfo({
          refId: user?.customer?.refId,
          data: data.user.customer,
        }),
      ).then(async (updateResponse: any) => {
        dispatch(
          createCompany({
            refId: user?.customer?.refId!,
            data: {
              title: data.title,
              requisites: {
                name: data?.requisites?.companyName,
                kpp: data?.requisites?.kpp,
                inn: data?.requisites?.inn,
                fullName: data?.requisites?.fullName,
                address: data?.requisites?.address,
                actualAddress: data?.requisites?.actualAddress,
                genDir: data?.requisites?.fullName,
                okpo: Number(okpo),
                ogrn,
                email: data?.requisites.email,
                bik: data?.requisites?.bik.replaceAll(" ", ""),
                rs: data?.requisites?.rs.replaceAll(" ", ""),
                userId: user?.customer?.id,
              },
            },
          }),
        );
        await dispatch(getMe(user?.customer?.refId!));
        dispatch(getCompany(user?.customer?.refId!));
      });
      await dispatch(getMe(user?.customer?.refId));
      if (registration.workerToAppoint) {
        await dispatch(
          selectWorker({
            refId: registration.workerToAppoint.refId,
            offerId: registration.workerToAppoint.offerId,
            orderId: registration.workerToAppoint.orderId,
          }),
        );
        await dispatch(
          getTechTask({
            refId: user?.customer?.refId,
            offerId: registration.workerToAppoint.offerId,
            coordinates: registration.workerToAppoint.coordinates,
          }),
        );
      }
      if (registration.workerRespond) {
        dispatch(fetchSendRespond({ ...registration.workerRespond })).then(
          (response) => {
            if (response.meta.requestStatus === "fulfilled") {
              if (registration.pageToRedirect) {
                navigate(registration.pageToRedirect);
              } else {
                navigate("/profile");
              }
            }
          },
        );
      }
      if (registration.pageToRedirect && !registration.workerRespond) {
        navigate(registration.pageToRedirect);
      } else {
        navigate("/profile");
      }
      setRegistrationPending(false);
      dispatch(clearRegistrationSlice());
    }
  }, [customerFormData, dispatch, onChangeStep, requisitesFormData]);

  const changeRequisitesFormData = (e: any) => {
    const { name, value } = e.target;

    setRequisitesFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
    if (value.length >= 10) {
      searchRequisite(value);
    }
  };

  const changeCustomerFormData = (e: any) => {
    const { name, value } = e.target;

    setCustomerFormData((prev: any) => ({
      ...prev,
      [name]: value instanceof Object ? value : value?.trim(),
    }));
  };

  useEffect(() => {
    if (auth?.phoneNumber)
      setCustomerFormData((prev: any) => ({
        ...prev,
        phone_number: auth?.phoneNumber,
      }));
  }, [auth]);

  useEffect(() => {
    if (customerFormData?.phone_number) {
      const countryFromPN = getCountryByPhone(customerFormData?.phone_number);

      setCustomerFormData((prev: any) => ({
        ...prev,
        country: {
          ...countryFromPN,
        },
        city: user?.customer?.city
          ? { text: user?.customer?.city, open: false }
          : { text: "", open: false },
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerFormData?.phone_number]);

  useEffect(() => {
    if (refId) {
      dispatch(getCompany(refId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);

  useEffect(() => {
    if (company) {
      changeRequisitesFormData({
        target: {
          name: "inn_organization",
          value: company?.requisites?.inn,
        },
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  useEffect(() => {
    if (user) {
      const { firstName, secondName, patronymic, phoneNumber, email, city } =
        user?.customer;
      setCustomerFormData((prev: any) => ({
        ...prev,
        first_name: firstName,
        last_name: secondName,
        middle_name: patronymic,
        city: { text: city },
        phone_number: phoneNumber,
        email,
      }));
    }
  }, [user]);

  return (
    <RegisterForm>
      <Title>
        {isShowCustomerFields
          ? "Данные о представителе"
          : "Реквизиты организации"}
      </Title>
      <InputsWrapper>
        <RegisterUserWrapper
          style={{ marginBottom: 0 }}
          // isHidden={!isShowCustomerFields}
        >
          <InnPart>
            <DebounceInput
              debounceTimeout={500}
              element={"input"}
              type="text"
              value={requisitesFormData?.["inn_organization"]}
              onChange={changeRequisitesFormData}
              className={`${companyRegErr ? "error" : ""}`}
              maxLength={12}
              autoFocus
            />

            {companyRegErr && (
              <div className="error">
                Такая компания уже зарегистрирована в системе
              </div>
            )}
          </InnPart>
          {requisitesFormData?.inn && requisitesFormData?.kpp && (
            <InnDataPart>
              {requisitesFormData?.short_name} <br />
              {requisitesFormData?.legal_address} <br /> <br />
              {`ИНН ${requisitesFormData?.inn} / КПП ${requisitesFormData?.kpp}`}
            </InnDataPart>
          )}
          {isShowCustomerFields ? (
            inputList.map(
              ({ label, name, width, required, type, lettersOnly }) => (
                <InputBox
                  key={name}
                  label={label}
                  name={name}
                  width={width}
                  onChange={changeCustomerFormData}
                  value={customerFormData?.[name]}
                  type={type}
                  required={required}
                  formData={customerFormData}
                  disabled={
                    type === "phone_number" &&
                    customerFormData?.[name]?.length > 0
                  }
                  lettersOnly={lettersOnly}
                />
              ),
            )
          ) : (
            <></>
          )}
        </RegisterUserWrapper>
      </InputsWrapper>
      <SubmitButton
        title={
          customerFormData?.bik?.length < 11 || !customerFormData?.bik
            ? "БИК должен состоять из 9 цифр"
            : customerFormData?.rs?.length < 25 || !customerFormData?.rs
            ? "Расчетный счет должен состоять из 20 цифр"
            : ""
        }
        disabled={
          !(
            customerFormData?.first_name &&
            customerFormData?.last_name &&
            customerFormData?.phone_number &&
            customerFormData?.email &&
            customerFormData?.country?.data?.country_id &&
            customerFormData?.city?.text &&
            customerFormData?.rs?.length === 25 &&
            customerFormData?.bik?.length === 11 &&
            // role &&
            searchRequisiteProcess?.success &&
            searchRequisiteProcess?.data?.[0]?.data?.inn ===
              requisitesFormData?.inn_organization?.replaceAll(" ", "")
          ) || registrationPending
        }
        onClick={submitHandler}
      >
        Продолжить
      </SubmitButton>
    </RegisterForm>
  );
};

export default RegisterOrg;
