import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpRequest } from "../../../utils/httpRequest";
import { getErrorMessage } from "./profileSlice";

type Loading = "idle" | "pending" | "succeeded" | "failed";

interface TVehicle {
  vehicleCategories: any[];
  vehicleList: any;
  vehicleImages: any[];
  passportImages: any[];
  vehicleSts: any[];
  loadingVehicleList: Loading;
  selectedVehicle: any | null;
  loading: Loading;
  loadingImages: Loading;
  loadingPassport: Loading;
  addStatus: Loading;
  error: string | null;
  newVehicleId: number | null;
}

const initialState: TVehicle = {
  vehicleCategories: [],
  vehicleList: [],
  loadingVehicleList: "pending",
  vehicleImages: [],
  passportImages: [],
  vehicleSts: [],
  selectedVehicle: null,
  loading: "pending",
  loadingImages: "idle",
  loadingPassport: "idle",
  addStatus: "idle",
  error: null,
  newVehicleId: null,
};

interface VehicleForm {
  categoryId: number | string;
  brand: string;
  model: string;
  stateNumber: string;
  comment?: string;
  characteristics?: any;
  productionYear?: number;
}

export const getVehicleCategories = createAsyncThunk(
  "vehicle/getVehicleCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await httpRequest({
        method: "GET",
        path: `shared/vehicles/categories`,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getVehiclesList = createAsyncThunk(
  "vehicle/getVehiclesList",
  async (id: string) => {
    const response = await httpRequest({
      method: "GET",
      path: `vehicles/${id}/all`,
    });
    return response.data;
  },
);

export const getVehicleById = createAsyncThunk(
  "vehicle/getVehicleById",
  async ({
    customerId,
    vehicleId,
  }: {
    customerId: string;
    vehicleId: string;
  }) => {
    const response = await httpRequest({
      method: "GET",
      path: `vehicles/${customerId}/${vehicleId}`,
    });
    return response.data;
  },
);

export const fetchAddVehicles = createAsyncThunk(
  "vehicle/fetchAddVehicles",
  async ({ customerId, data }: { customerId: string; data: VehicleForm }) => {
    const response = await httpRequest({
      method: "POST",
      path: `vehicles/${customerId}/add`,
      data,
    });
    return response.data;
  },
);

export const fetchUpdateVehicle = createAsyncThunk(
  "vehicle/fetchUpdateVehicle",
  async ({
    customerId,
    vehicleId,
    data,
  }: {
    customerId: string;
    vehicleId: string;
    data: VehicleForm;
  }) => {
    const response = await httpRequest({
      method: "PUT",
      path: `vehicles/${customerId}/${vehicleId}/edit`,
      data,
    });
    return response.data;
  },
);

export const removeVehicleById = createAsyncThunk(
  "vehicle/removeVehicleById",
  async ({
    customerId,
    vehicleId,
  }: {
    customerId: string;
    vehicleId: string;
  }) => {
    const response = await httpRequest({
      method: "DELETE",
      path: `vehicles/${customerId}/${vehicleId}/delete`,
    });
    return response.data;
  },
);

export const getVehicleImages = createAsyncThunk(
  "vehicle/getVehicleImages",
  async (
    { customerId, vehicleId }: { customerId: string; vehicleId: string },
    thunkApi,
  ) => {
    await thunkApi.dispatch(getVehicleById({ customerId, vehicleId }));

    const response = await httpRequest({
      method: "GET",
      path: `vehicles/${customerId}/${vehicleId}/images`,
    });
    return response.data;
  },
);

export const uploadVehicleImages = createAsyncThunk(
  "vehicle/uploadVehicleImages",
  async ({
    customerId,
    vehicleId,
    data,
  }: {
    customerId: string;
    vehicleId: string;
    data: any;
  }) => {
    const response = await httpRequest({
      method: "PUT",
      path: `vehicles/${customerId}/${vehicleId}/images/add`,
      data,
    });
    return response.data;
  },
);

export const removeVehicleImages = createAsyncThunk(
  "vehicle/removeVehicleImages",
  async ({
    customerId,
    vehicleId,
  }: {
    customerId: string;
    vehicleId: string;
  }) => {
    const response = await httpRequest({
      method: "DELETE",
      path: `vehicles/${customerId}/${vehicleId}/images/delete`,
    });
    return response.data;
  },
);

export const uploadPassportImages = createAsyncThunk(
  "vehicle/uploadPassportImages",
  async ({
    customerId,
    vehicleId,
    data,
  }: {
    customerId: string;
    vehicleId: string;
    data: any;
  }) => {
    const response = await httpRequest({
      method: "PUT",
      path: `vehicles/${customerId}/${vehicleId}/passport/add`,
      data,
    });
    return response.data;
  },
);

export const removePassportImages = createAsyncThunk(
  "vehicle/removePassportImages",
  async ({
    customerId,
    vehicleId,
  }: {
    customerId: string;
    vehicleId: string;
  }) => {
    const response = await httpRequest({
      method: "DELETE",
      path: `vehicles/${customerId}/${vehicleId}/passport/delete`,
    });
    return response.data;
  },
);

export const getPassportImages = createAsyncThunk(
  "vehicle/getPassportImages",
  async (
    { customerId, vehicleId }: { customerId: string; vehicleId: string },
    thunkApi,
  ) => {
    await await thunkApi.dispatch(getVehicleById({ customerId, vehicleId }));
    const response = await httpRequest({
      method: "GET",
      path: `vehicles/${customerId}/${vehicleId}/passport`,
    });
    return response.data;
  },
);

export const vehicleSlice = createSlice({
  name: "vihicle",
  initialState,
  reducers: {
    clearVehicleList(state) {
      state.vehicleList = [];
    },
    clearSelectedVehicle(state) {
      state.selectedVehicle = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehicleCategories.fulfilled, (state, action) => {
        state.vehicleCategories = action.payload;
      })
      .addCase(getVehicleCategories.pending, (state) => {
        state.error = null;
      })
      .addCase(getVehicleCategories.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(getVehiclesList.fulfilled, (state, action) => {
        state.vehicleList = action.payload;
        state.loadingVehicleList = "succeeded";
        state.addStatus = "idle";
      })
      .addCase(getVehiclesList.pending, (state) => {
        state.loadingVehicleList = "pending";
        state.error = null;
      })
      .addCase(getVehiclesList.rejected, (state) => {
        state.loadingVehicleList = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(getVehicleById.fulfilled, (state, action) => {
        state.selectedVehicle = action.payload;
        state.loading = "idle";
      })
      .addCase(getVehicleById.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getVehicleById.rejected, (state) => {
        state.loading = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(fetchAddVehicles.fulfilled, (state, action) => {
        state.addStatus = "succeeded";
        state.newVehicleId = action.payload.vehicle.raw.insertId;
      })
      .addCase(fetchAddVehicles.pending, (state) => {
        state.addStatus = "pending";
        state.error = null;
      })
      .addCase(fetchAddVehicles.rejected, (state) => {
        state.addStatus = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(fetchUpdateVehicle.fulfilled, (state) => {
        state.addStatus = "succeeded";
      })
      .addCase(fetchUpdateVehicle.pending, (state) => {
        state.addStatus = "pending";
        state.error = null;
      })
      .addCase(fetchUpdateVehicle.rejected, (state) => {
        state.addStatus = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(removeVehicleById.fulfilled, (state) => {
        state.addStatus = "succeeded";
      })
      .addCase(removeVehicleById.pending, (state) => {
        state.addStatus = "pending";
        state.error = null;
      })
      .addCase(removeVehicleById.rejected, (state) => {
        state.addStatus = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(getVehicleImages.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.loadingImages = "idle";
        state.vehicleImages = action.payload;
      })
      .addCase(getVehicleImages.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getVehicleImages.rejected, (state) => {
        state.loading = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(uploadVehicleImages.fulfilled, (state, action) => {
        state.loadingImages = "succeeded";
      })
      .addCase(uploadVehicleImages.pending, (state) => {
        state.loadingImages = "pending";
        state.error = null;
      })
      .addCase(uploadVehicleImages.rejected, (state) => {
        state.loadingImages = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(removeVehicleImages.fulfilled, (state, action) => {
        state.loadingImages = "succeeded";
        state.vehicleImages = [];
      })
      .addCase(removeVehicleImages.pending, (state) => {
        state.loadingImages = "pending";
        state.error = null;
      })
      .addCase(removeVehicleImages.rejected, (state) => {
        state.loadingImages = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(getPassportImages.fulfilled, (state, action) => {
        state.loading = "succeeded";
        state.passportImages = [action.payload];
      })
      .addCase(getPassportImages.pending, (state) => {
        state.loading = "pending";
        state.error = null;
      })
      .addCase(getPassportImages.rejected, (state) => {
        state.loading = "failed";
        state.error = "Внимание какая то ошибка";
        state.passportImages = [];
      });
    builder
      .addCase(uploadPassportImages.fulfilled, (state, action) => {
        state.loadingPassport = "succeeded";
      })
      .addCase(uploadPassportImages.pending, (state) => {
        state.loadingPassport = "pending";
        state.error = null;
      })
      .addCase(uploadPassportImages.rejected, (state) => {
        state.loadingPassport = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(removePassportImages.fulfilled, (state, action) => {
        state.loadingPassport = "succeeded";
        state.passportImages = [];
      })
      .addCase(removePassportImages.pending, (state) => {
        state.loadingPassport = "pending";
        state.error = null;
      })
      .addCase(removePassportImages.rejected, (state) => {
        state.loadingPassport = "failed";

        state.error = "Внимание какая то ошибка";
      });
  },
});

// uploadPassportImages
// removePassportImages

export const { clearVehicleList, clearSelectedVehicle } = vehicleSlice.actions;

export default vehicleSlice.reducer;
