import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import React, { memo, useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks";
import { getRandomNumber, getRandomNumberFloat } from "../../utils/numbers";
import { styles } from "../styles/styles";
import WorkDetailOnMap from "../workDetailOnMap";
import { MapWrapper, Wrapper } from "./styles";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import vehicleIcon1 from "../../assets/icons/icons-vehicles-excavator.svg";
import vehicleIcon2 from "../../assets/icons/icons-vehicles-beton.svg";
import vehicleIcon3 from "../../assets/icons/icons-vehicles-hoist.svg";
import vehicleIcon4 from "../../assets/icons/icons-vehicles-musor.svg";
import vehicleIcon5 from "../../assets/icons/icons-vehicles-wheelbarrow.svg";

const DashboardRightOrders: React.FC = () => {
  const [center, setCenter] = useState([55.75, 37.57]);
  const [workDetail, setWorkDetail] = useState<any>(null);
  const [coords, setCoords] = useState<
    { id: string; coord: number[]; icon: string }[]
  >([]);
  const vehicleList = [
    vehicleIcon1,
    vehicleIcon2,
    vehicleIcon3,
    vehicleIcon4,
    vehicleIcon5,
  ];
  const data = useAppSelector((state) => state.dashboardOrders.data);

  useEffect(() => {
    setCoords([]);
    data?.orders?.forEach(({ id, latitude, longitude, vehicle }: any) => {
      setCoords((prev) => [
        ...prev,
        {
          id,
          icon: vehicle?.icon
            ? `https://dev.rukki.pro/${vehicle?.icon}`
            : vehicleList[getRandomNumber(0, vehicleList.length - 1)],
          coord: [
            latitude || center[0] + getRandomNumberFloat(-0.5, 0.5),
            longitude || center[1] + getRandomNumberFloat(-0.5, 0.5),
          ],
        },
      ]);
    });
  }, [data]);

  return (
    <Wrapper>
      <MapWrapper>
        <YMaps query={{ lang: "ru_RU" }}>
          <Map
            width={"100%"}
            height={"100%"}
            defaultState={{
              center,
              zoom: 9,
            }}
          >
            <Placemark
              width="0px"
              height="0px"
              options={{
                preset: "islands#circleIcon",
                iconColor: "#423189",
                draggable: false,
                hideIconOnBalloonOpen: false,
                hasBalloon: true,
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
              }}
              geometry={{ type: "Point", coordinates: center }}
              properties={{
                iconContent: `
                          <div class="myIconContentBoxAnimation">
                            <div class="first-circle"></div>
                            <div class="second-circle"></div>
                            <div class="third-circle"></div>
                            <div class="forth-circle"></div>
                            <div class="core-circle"></div>
                          </div>`,
              }}
            />
            {coords.map(({ id, coord, icon }) => (
              <Placemark
                key={id}
                onClick={() => {
                  const orderData = data?.orders?.find(
                    ({ id: oId }: any) => oId === id,
                  );
                  setWorkDetail({
                    ...orderData,
                    title: orderData?.vehicle?.title,
                    description: `Выкопать яму под бассейн диаметр 5м, глубина 0,5 метра круглую. Засыпать дно песком 5-10 см, выровнять под "ноль". Грунт засыпать частично …`,
                    budget: orderData?.amount,
                    address: "Москва, переулок Николая Островского, 24А",
                  });
                }}
                width="0px"
                height="0px"
                options={{
                  preset: "islands#circleIcon",
                  iconColor: styles.mainColor,
                  draggable: false,
                  hideIconOnBalloonOpen: false,
                  hasBalloon: true,
                  balloonShadow: false,
                  balloonPanelMaxMapArea: 0,
                }}
                geometry={{ type: "Point", coordinates: coord }}
                properties={{
                  iconContent: `
                          <div class="order-placemark">   
                            <img class="placemark" src=${placemark} alt="" />
                            <img class="vehicle" src=${icon} alt="" />
                          </div>`,
                }}
              />
            ))}
            <ZoomControl options={{ position: { right: 10, top: 20 } }} />
          </Map>
        </YMaps>
        {workDetail && (
          <WorkDetailOnMap
            workDetail={workDetail}
            close={() => setWorkDetail(null)}
            test={true}
          />
        )}
      </MapWrapper>
    </Wrapper>
  );
};

export default memo(DashboardRightOrders);
