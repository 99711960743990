import React, { useState } from "react";
import { InputPN, InputPNWrapper } from "../form/styles";
import { Highlighted, SmallText } from "../styles/styles";
import UserAgreementPopUp from "../popUp/userAgreementPopUp";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import { SwitchRoleWrapperXL } from "../sidebar/styles";
import { useNavigate } from "react-router-dom";
import { FormText, TelegramButton } from "./styles";
import { CustomerRole } from "../../types";
import CountryPicker from "../countryPicker";
import Button from "../../UI/Button";

interface Props {
  pN: any;
  setPN: any;
  tryToLogin: any;
  error: string;
  verifyRef: any;
  flag: boolean;
  smsRu: boolean;
  isRecapcha: boolean;
  setIsRecapcha: (value: boolean) => void;
  country: string;
  setCountry: any;
  role: CustomerRole;
  setRole: React.Dispatch<React.SetStateAction<CustomerRole>>;
  tryToLoginWithAlternative: () => void;
}

function LoginForm({
  pN,
  setPN,
  tryToLogin,
  error,
  verifyRef,
  flag,
  smsRu,
  isRecapcha,
  country,
  setCountry,
  role,
  setRole,
  tryToLoginWithAlternative,
}: Props) {
  const {
    loadingAltAuth,
    alternativeAuth,
    error: authError,
  } = useAppSelector((state: RootState) => state.auth);

  const [userAgreement, setUserAgreement] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isActive = !isPossiblePhoneNumber(pN);

  return (
    <>
      <FormText>Введите номер телефона для получения смс-кода</FormText>
      {!localStorage.getItem("hideRolePicker") && (
        <SwitchRoleWrapperXL
          style={{ width: "100% !important", margin: "8px 0" }}
        >
          <div
            style={{ cursor: "pointer", width: "50% !important" }}
            className={`btn ${role === "customer" && "active"}`}
            onClick={() => setRole(CustomerRole.Customer)}
          >
            Я заказчик
          </div>
          <div
            style={{ cursor: "pointer", width: "50% !important" }}
            className={`btn ${role === "worker" && "active"}`}
            onClick={() => setRole(CustomerRole.Worker)}
          >
            Я исполнитель
          </div>
        </SwitchRoleWrapperXL>
      )}

      <InputPNWrapper>
        <CountryPicker onChange={setCountry} currentCountry={country} />
        {/* <select
          onChange={(e) => {
            setCountry(e.target.value);
          }}
          value={country}
        >
          {countries
            ?.filter(({ country_id }: any) => country_id)
            ?.map(({ dial_code, flag, code, alpha2 }: any, index) => (
              <option key={index} value={alpha2?.toUpperCase()}>
                {flag} {dial_code}
              </option>
            ))}
        </select> */}
        <InputPN
          international
          country={country}
          placeholder="(999) 000-00-00"
          value={pN}
          onChange={(e: any) => {
            setPN(e || "");
          }}
          onKeyDown={(e: any) => {
            if (
              e.key === "Enter" &&
              !(
                // loader ||
                (isActive || flag || loadingAltAuth === "pending" || isRecapcha)
              )
            ) {
              // e.preventDefault();
              tryToLogin();
            }
          }}
        />
      </InputPNWrapper>

      <Button
        fontWeight="500"
        height={"48px"}
        disabled={isActive || flag || loadingAltAuth === "pending"}
        onClick={() =>
          alternativeAuth ? tryToLoginWithAlternative() : tryToLogin()
        }
      >
        Войти
      </Button>
      <SmallText ref={verifyRef}>
        Авторизуясь в приложении, вы принимаете{" "}
        <Highlighted onClick={() => setUserAgreement(true)}>
          "Правила сервиса"
        </Highlighted>
      </SmallText>
      {/* <TelegramButton onClick={() => navigate("/telegram-auth")}>
        <TelegramIcon />
        Войти через Telegram
      </TelegramButton> */}

      {userAgreement && (
        <UserAgreementPopUp close={() => setUserAgreement(false)} />
      )}
    </>
  );
}

export default LoginForm;
