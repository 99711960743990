import { createAsyncThunk } from "@reduxjs/toolkit";
import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";
import axios from "axios";

export const getPosition = createAsyncThunk(
  actionTypes.GET_POSITION,
  async ({ lat, lon }: { lat: string | number; lon: string | number }) => {
    try {
      const response = await httpRequest({
        method: "GET",
        path: `shared/position-search`,
        params: {
          lat,
          lon,
        },
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);
