import React, { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/icons/user-avatar-48-clean-contractor.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icons-expand-more.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add-white.svg";
import {
  AddButton,
  ContentPart,
  ManagerListWrapper,
  SelectBody,
  SelectWrapper,
  Text,
  Title,
  Wrapper,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import SingleManagerPopUp from "../popUp/singleManagerPopUp";
import SideMenu from "../../pages/dashboardEmployeesPage/sideMenu";
import getManagerData from "../../redux/modules/getManagerData";

interface IProps {
  employees?: boolean;
  vehicles?: boolean;
}

interface IListItem {
  title: string;
  label: string;
  name: string;
}

export const ManagerRow = ({
  value: {
    id,
    refId,
    name,
    inWork,
    orderAmount,
    orderTotal,
    phoneNumber,
    doneAmount,
    companyRole,
  },
  index,
  onClick,
}: any) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <SelectWrapper
        key={index}
        onClick={() => {
          onClick({
            id,
            refId,
            name,
            inWork,
            orderAmount,
            orderTotal,
            phoneNumber,
            doneAmount,
            companyRole,
          });
        }}
      >
        <div className="selectGroup">
          <div className="icon">
            {/* {employees ?  */}
            <UserIcon />
            {/* : <VehicleIcon />} */}
          </div>

          <SelectBody>
            <label>{orderTotal} заказа</label>
            <div className="text">{name}</div>
            {/* <b>{String(companyRole)}</b> */}
            {/* <select name="sorts" defaultValue="" id="sorts">
                  <option value="" disabled hidden>
                    <img src={avatar_worker} alt="icons" /> {name}
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select> */}
          </SelectBody>
        </div>
        <div className="arrowIcon">
          <ArrowIcon />
        </div>
      </SelectWrapper>
      {isOpen && (
        <SingleManagerPopUp
          id={id}
          close={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

const EmployeePageDataList: React.FC<IProps> = ({ employees, vehicles }) => {
  const { data: managers } = useAppSelector((state) => state.managers);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<any>(null);

  const navigate = useNavigate();

  const renderList = (listData: IListItem[]) => {
    return (
      <ManagerListWrapper>
        {listData.map((value, index) => {
          return (
            <ManagerRow
              value={value}
              index={index}
              onClick={(user: any) => {
                setSelectedEmployee(user);
                setMenuOpen(true);
              }}
            />
          );
        })}
      </ManagerListWrapper>
    );
  };

  useEffect(() => {
    user?.customer?.refId &&
      selectedEmployee &&
      dispatch(getManagerData(user?.customer?.refId, selectedEmployee.id));
  }, [selectedEmployee]);

  return (
    <Wrapper>
      <Text>
        Список сотрудников
        <AddButton onClick={() => navigate("/create-employee")}>
          <PlusIcon />
          <div className="text">Добавить</div>
        </AddButton>
      </Text>

      <ContentPart>
        <Title>
          <div>Менеджеры</div>
        </Title>
        {!!managers?.users.filter(
          ({ companyRole }: any) => !companyRole || companyRole === "manager",
        )?.length &&
          renderList(
            managers?.users.filter(
              ({ companyRole }: any) =>
                !companyRole || companyRole === "manager",
            ),
          )}
      </ContentPart>

      <ContentPart>
        {!!managers?.users.filter(
          ({ companyRole }: any) => companyRole === "driver",
        )?.length && (
          <Title>
            <div>Водители</div>
          </Title>
        )}
        {!!managers?.users.filter(
          ({ companyRole }: any) => companyRole === "driver",
        )?.length &&
          renderList(
            managers?.users.filter(
              ({ companyRole }: any) => companyRole === "driver",
            ),
          )}
      </ContentPart>
    </Wrapper>
  );
};

export default memo(EmployeePageDataList);
