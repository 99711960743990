import React, { memo, useState } from "react";
import {
  ActualAddress,
  AddAddressText,
  CloseAddAddress,
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  InputsWrapper,
  InputWrapper,
  MainWrapper,
  RegisterForm,
  SearchRequisite,
  SelectWrapper,
  Wrapper,
} from "./styles";
import { ReactComponent as SearchIcon } from "../../assets/icons/icons-search.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { Button } from "../../components/form/styles";
import NumberFormat from "react-number-format";
import { ENDPOINT } from "../../constants/general";
import { useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";
import { DatePicker } from "antd";
import moment from "moment";
import { GoBack } from "../../components/pageContainer/styles";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";

export const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  height,
  disabled,
  small,
  orgPage,
  borderColor,
  onBlur,
}: any) => {
  return (
    <InputWrapper
      active={type !== "role" && value?.length}
      style={{
        width: width ? width : "100%",
        height: height ? height : "",
        borderColor: borderColor && borderColor,
      }}
      className={type !== "role" && value?.length ? "active" : ""}
      small={small}
      orgPage={orgPage}
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              // mask={"### ### ####"}
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            value ? (
              <NumberFormat
                // format="### ### ######"
                name={name}
                value={value || ""}
                // type={type || "text"}
                prefix={"+"}
                placeholder={placeholder}
                onChange={onChange}
                autoFocus={!orgPage}
                disabled={disabled}
                onBlur={onBlur}
                spellCheck={false}
              />
            ) : (
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                disabled={disabled}
                onBlur={onBlur}
                spellCheck={false}
              />
            )
          ) : type === "date" ? (
            <div className="date-input">
              <DatePicker
                format={"ll"}
                value={value ? moment(value) : null}
                onChange={(e, date) => {
                  onChange({
                    target: {
                      value: e?.format("YYYY-MM-DD"),
                      name,
                    },
                  });
                }}
                disabled={disabled}
                onBlur={onBlur}
              />
            </div>
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              disabled={disabled}
              onBlur={onBlur}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const AddOrgPage: React.FC = () => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [addAddress, setAddAddress] = useState(false);
  const [getRequisites, setGetRequisites] = useState<boolean>(false);

  const user = useAppSelector((state) => state?.auth.user);
  const navigate = useNavigate();

  const changeFormData = (e: any) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [searchRequisiteProcess, searchRequisiteRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const data: any = res?.data[0]?.data;

      setFormData((prev: any) => ({
        ...prev,
        full_name: data?.name?.full_with_opf,
        short_name: data?.name?.short_with_opf,
        inn: data?.inn,
        kpp: data?.kpp,
        full_name_representative: data?.management?.name,
        representative_position: data?.management?.post,
        ogrn: data?.ogrn,
        okpo: data?.okpo,
        legal_address: data?.address?.unrestricted_value,
        actual_address: data?.address?.value,
      }));
    },
    onError: () => {},
  });

  const searchRequisite = () => {
    setGetRequisites(true);
    searchRequisiteRequest({
      url: `${ENDPOINT}/api/customers/find-by-inn?q=${formData[
        "inn_organization"
      ]
        .trim()
        .replaceAll(" ", "")}`,
      method: "POST",
    });
  };

  const inputListStep1 = [
    {
      label: "ИНН организации",
      name: "inn_organization",
      width: "246px",
      type: "inn",
    },
    {
      name: "search-btn",
    },
    {
      type: "user_role",
      name: "role",
    },
    {
      label: "Полное название",
      name: "full_name",
    },
    {
      label: "Краткое название",
      name: "short_name",
    },
    {
      label: "Юридический адрес",
      name: "legal_address",
    },
    {
      label: "Фактический адрес",
      name: "actual_address",
    },
    {
      label: "ИНН",
      name: "inn",
      width: "calc((100% - 12px) / 2)",
      type: "inn",
    },
    {
      label: "КПП",
      name: "kpp",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "ОГРН",
      name: "ogrn",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "ОКПО",
      name: "okpo",
      width: "calc((100% - 12px) / 2)",
    },
    {
      label: "Должность представителя",
      name: "representative_position",
    },
    {
      label: "ФИО представителя",
      name: "full_name_representative",
    },
    {
      label: "Электронная почта",
      name: "email",
    },
  ];

  const [registerProcess, registerRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      navigate("/profile");
    },
    onError: (err: any) => {},
  });

  const registerCompany = () => {
    const inn_organization = formData?.inn_organization?.split(" ").join("");

    if (formData?.email && !formData?.email?.toLowerCase()?.match(emailRegEx)) {
      setErrorMsg(["Электронная почта недействительна."]);
    } else if (
      inn_organization.length === 10 ||
      inn_organization.length === 12
    ) {
      registerRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${user?.customer?.refId}`,
        data: {
          title: formData?.full_name,
          requisites: {
            companyName: formData?.full_name,
            kpp: formData?.kpp,
            inn: inn_organization,
            fullName: formData?.full_name,
            shortName: formData?.short_name,
            address: formData?.legal_address,
            actualAddress: addAddress ? formData?.actual_address : null,
            gendir: formData?.full_name_representative,
            jobTitle: formData?.representative_position,
            okpo: Number(formData?.okpo),
            email: formData?.email,
          },
        },
      });
    } else {
      setErrorMsg(["Неверный ИНН"]);
    }
  };

  return (
    <MainWrapper>
      <Wrapper>
        <GoBack onClick={() => navigate(`/registration`)}>
          <LeftArrow /> Назад
        </GoBack>
        <RegisterForm>
          <FormTitle>Реквизиты организации</FormTitle>
          <FormDesc>
            Введите ИНН организации, чтобы мы смогли заполнить данные
            автоматически
          </FormDesc>
          <InputsWrapper>
            {inputListStep1.map(({ label, name, width, type }) =>
              name === "search-btn" ? (
                <SearchRequisite onClick={searchRequisite}>
                  <SearchIcon />
                  Найти реквизиты
                </SearchRequisite>
              ) : name === "actual_address" ? (
                <ActualAddress>
                  {addAddress ? (
                    <>
                      <InputBox
                        label={label}
                        width={width}
                        name={name}
                        onChange={changeFormData}
                        value={formData?.[name]}
                        type={type}
                      />
                      <CloseAddAddress
                        onClick={() => {
                          setAddAddress(false);
                          setFormData((prev: any) => ({
                            ...prev,
                            actual_address: null,
                          }));
                        }}
                      >
                        <X />
                      </CloseAddAddress>
                    </>
                  ) : (
                    getRequisites && (
                      <AddAddressText>
                        Фактический адрес отличается?{" "}
                        <u onClick={() => setAddAddress(true)}>Добавьте его</u>
                      </AddAddressText>
                    )
                  )}
                </ActualAddress>
              ) : label === "ИНН организации" ? (
                <InputBox
                  label={label}
                  width={width}
                  name={name}
                  onChange={changeFormData}
                  value={formData?.[name]}
                  type={type}
                />
              ) : getRequisites ? (
                type === "user_role" ? (
                  <>
                    <button className="roleBtn">Администратор</button>
                    <button className="roleBtn">Менеджер</button>
                    <button className="roleBtn">Рабочий</button>
                  </>
                ) : (
                  <InputBox
                    label={label}
                    width={width}
                    name={name}
                    onChange={changeFormData}
                    value={formData?.[name]}
                    type={type}
                  />
                )
              ) : null,
            )}
            {errorMsg.length > 0 ? (
              <ErrorsWrapper>
                {errorMsg.map((msg: string) => (
                  <ErrorMsg>{msg}</ErrorMsg>
                ))}
              </ErrorsWrapper>
            ) : (
              ""
            )}
            <Button
              fit
              _m0auto
              disabled={
                !(
                  searchRequisiteProcess?.success &&
                  searchRequisiteProcess?.data?.[0]?.data?.inn ===
                    formData?.inn_organization?.split(" ").join("")
                )
              }
              onClick={() => {
                if (!formData?.users?.length) {
                  setFormData((prev: any) => ({
                    ...prev,
                  }));
                }
                registerCompany();
              }}
            >
              Сохранить
            </Button>
          </InputsWrapper>
        </RegisterForm>
      </Wrapper>
    </MainWrapper>
  );
};

export default AddOrgPage;
