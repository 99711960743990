import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ENDPOINT } from "../../../constants/general";
import { httpRequest } from "../../../utils/httpRequest";
import { getToken } from "../../../utils/uiHelpers";
import { DocumentType, Offer, OrderActionsItem } from "../../../types";
import { CounterOffer } from "../../../types/orderSlice.types";

export const getCounterOfferById = createAsyncThunk(
  "order/getCounterOfferById",
  async (
    { refId, counterofferId }: { refId: string; counterofferId: number },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/counteroffer/${counterofferId}`,
    });

    return response.data;
  },
);

export const declineCounterOffer = createAsyncThunk(
  "order/declineCounterOffer",
  async (
    { refId, counterofferId }: { refId: string; counterofferId: number },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/counteroffers/${counterofferId}/decline`,
    });
    return response.data;
  },
);

export const acceptCounterOffer = createAsyncThunk(
  "order/acceptCounterOffer",
  async (
    { refId, counterofferId }: { refId: string; counterofferId: number },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/counteroffers/${counterofferId}/accept`,
    });
    return response.data;
  },
);

export const getCounterOffers = createAsyncThunk(
  "order/getCounterOffers",
  async (
    { refId, orderId }: { refId: string; orderId: number | string },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/counteroffers/${orderId}`,
    });
    return response.data;
  },
);

export const createCounterOffer = createAsyncThunk(
  "order/createCounterOffer",
  async (
    {
      refId,
      orderId,
      counterOffer,
    }: {
      refId: string;
      orderId: number | string;
      counterOffer: {
        budget: string;
        description: string;
        vehicleId: number | null;
        paymentType: string;
        startDate: string;
        endDate: string | null;
        offer_id: number;
      };
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/add-counteroffers/${orderId}`,
      data: { ...counterOffer, order_id: Number(orderId) },
    });
    await thunkApi.dispatch(getCounterOffers({ refId, orderId }));
    return response.data;
  },
);

export const doneOrder = createAsyncThunk(
  "order/doneOrder",
  async (
    {
      refId,
      orderId,
      location,
    }: {
      refId: string;
      orderId: number | string;
      location: [number, number];
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/done`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    await thunkApi.dispatch(
      getSingleOrders({ refId, orderId: orderId.toString() }),
    );
    thunkApi.dispatch(getOrderDocs({ refId, orderId }));
    return response.data;
  },
);

export const finishOrder = createAsyncThunk(
  "order/finishOrder",
  async (
    {
      refId,
      orderId,
      location,
    }: {
      refId: string;
      orderId: number | string;
      location: [number, number];
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/waiting-for-done`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    await thunkApi.dispatch(
      getSingleOrders({ refId, orderId: orderId.toString() }),
    );
    thunkApi.dispatch(getOrderDocs({ refId, orderId }));
    return response.data;
  },
);

export const cancelOrder = createAsyncThunk(
  "order/cancelOrder",
  async (
    { refId, orderId }: { refId: string; orderId: number | string },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/cancel`,
    });
    thunkApi.dispatch(getSingleOrders({ refId, orderId: orderId.toString() }));
    return response.data;
  },
);

export const startWork = createAsyncThunk(
  "order/startWork",
  async (
    {
      refId,
      orderId,
      location,
    }: {
      refId: string;
      orderId: number | string;
      location: [number, number];
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/start`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    thunkApi.dispatch(getSingleOrders({ refId, orderId: orderId.toString() }));
    return response.data;
  },
);

export const arrivedAtWork = createAsyncThunk(
  "order/arrivedAtWork",
  async (
    {
      refId,
      orderId,
      location,
    }: {
      refId: string;
      orderId: number | string;
      location: [number, number];
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/set-out`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    thunkApi.dispatch(getSingleOrders({ refId, orderId: orderId.toString() }));
    return response.data;
  },
);

export const goingToWork = createAsyncThunk(
  "order/goingToWork",
  async (
    {
      refId,
      orderId,
      location,
    }: {
      refId: string;
      orderId: number | string;
      location: [number, number];
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/going`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    thunkApi.dispatch(getSingleOrders({ refId, orderId: orderId.toString() }));
    return response.data;
  },
);

export const editOrderData = createAsyncThunk(
  "order/editOrderData",
  async (
    {
      refId,
      orderId,
      orderData,
    }: { refId: string; orderId: number | string; orderData: any },
    thunkApi,
  ) => {
    const path = `${ENDPOINT}/orders/${refId}?orderId=${orderId}`;

    const response = await httpRequest({
      method: "PUT",
      url: path,
      data: {
        ...orderData,
      },
    });

    return response.data;
  },
);

export const addOrderImages = createAsyncThunk(
  "order/addOrderImages",
  async (
    {
      refId,
      orderId,
      images,
    }: { refId: string; orderId: number | string; images: any },
    thunkApi,
  ) => {
    const path = `${ENDPOINT}/orders/${refId}/${orderId}/images`;

    const response = await httpRequest({
      method: "PUT",
      url: path,
      data: images,
    });

    return response.data;
  },
);

export const getVehicleParams = createAsyncThunk(
  "order/getVehicleParams",
  async (
    {
      categoryId,
      additional,
    }: {
      categoryId: number;
      additional?: [{ cId: number; value: number | string | Array<string> }];
    },
    thunkApi,
  ) => {
    const path = `${ENDPOINT}/api/shared/vehicles/characteristics`;

    const response = await httpRequest({
      method: "POST",
      url: path,
      data: {
        categoryId: categoryId,
        additional,
      },
    });

    return response.data;
  },
);

export const fetchCancelOffer = createAsyncThunk(
  "order/fetchCancelOffer",
  async (
    {
      refId,
      offerId,
      orderId,
    }: {
      refId: string | number;
      offerId: string | number;
      orderId: string | number;
    },
    thunkApi,
  ) => {
    const path = `${ENDPOINT}/orders/${refId}/${orderId}/respond?offerId=${offerId}`;
    const response = await httpRequest({
      method: "DELETE",
      url: path,
    });

    return response.data;
  },
);

export const selectWorker = createAsyncThunk(
  "order/selectWorker",
  async (
    {
      refId,
      offerId,
      orderId,
    }: {
      refId: string | number;
      offerId: string | number;
      orderId: string | number;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/waiting-for-payment?offerId=${offerId}`,
    });
    return response.data;
  },
);

export const requestCall = createAsyncThunk(
  "order/requestCall",
  async (
    {
      refId,
      offerId,
      orderId,
    }: {
      refId: string | number;
      offerId: string | number;
      orderId: string | number;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/${offerId}/call`,
    });
    return response.data;
  },
);

export const getWorkerAgreement = createAsyncThunk(
  "order/getWorkerAgreement",
  async (
    {
      refId,
    }: {
      refId: string | number;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/get-user-agreement`,
    });

    return response?.data;
  },
);

export const createWorkerAgreement = createAsyncThunk(
  "order/createWorkerAgreement",
  async (
    {
      refId,
    }: {
      refId: string | number;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/user-agreement`,
    });

    return response?.data;
  },
);

export const signWorkerAgreement = createAsyncThunk(
  "order/signWorkerAgreement",
  async (
    {
      refId,
      coordinates,
      fileId,
    }: {
      refId: string | number;
      coordinates: [number, number];
      fileId: number;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${refId}/sign-user-agreement`,
      data: {
        fileId,
        coordinates,
      },
    });

    return response?.data;
  },
);

export const getTechTask = createAsyncThunk(
  "order/getTechTask",
  async (
    {
      refId,
      offerId,
      coordinates,
    }: {
      refId: string | number;
      offerId: string | number;
      coordinates?: [number, number];
    },
    thunkApi,
  ) => {
    const response = await axios.put(
      `${ENDPOINT}/orders/${refId}/tech-task/${offerId}`,
      { coordinates },
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response?.data;
  },
);

type MyOrdersFilter =
  | "all"
  | "archived"
  | "in_progress"
  | "canceled"
  | "completed";

export const getMyOrders = createAsyncThunk(
  "order/getMyOrders",
  async (
    {
      take,
      skip,
      filter = "in_progress",
      refId,
      fetchMore = false,
    }: {
      take: number;
      skip: number;
      refId: string;
      filter?: MyOrdersFilter;
      fetchMore?: boolean;
    },
    thunkApi,
  ) => {
    thunkApi.dispatch(setFetchMore({ isFetchMore: fetchMore }));
    const response = await axios.get(`${ENDPOINT}/orders/${refId}`, {
      params: { take, skip, date: "desc", filter },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });

    return response?.data;
  },
);

export const startWorkAsExecutor = createAsyncThunk(
  "order/startWorkAsExecutor",
  async ({ refId, orderId }: { refId: string; orderId: string }) => {
    const response = await axios.post(
      `${ENDPOINT}/orders/${refId}/${orderId}/start`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      },
    );

    return response?.data;
  },
);

export const sendFeedback = createAsyncThunk(
  "order/sendFeedback",
  async (
    {
      refId,
      userId,
      data,
    }: {
      refId: string;
      userId: number;
      data: {
        description: string;
        rating: number;
        orderId: number;
        rewards: [];
      };
    },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/feedback/${refId}/user/${userId}`,
      data,
    });
    thunkApi.dispatch(
      getSingleOrders({ refId, orderId: data.orderId.toString() }),
    );
    return response.data;
  },
);

export const getSingleOrders = createAsyncThunk(
  "order/getSingleOrders",
  async (
    { refId, orderId }: { refId: string; orderId: any },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/${orderId}`,
    });
    thunkApi.dispatch(getOrderImages({ refId, orderId }));

    return response.data;
  },
);

export const getOrderWithoutSignIn = createAsyncThunk(
  "order/without-sign-in",
  async (orderId: any, thunkApi?: any) => {
    const { data } = await axios.get(
      `${ENDPOINT}/orders/without-sign-up/${orderId}`,
    );

    return data;
  },
);

export const getOrderImages = createAsyncThunk(
  "order/getOrderImages",
  async (
    { refId, orderId }: { refId: string; orderId: any },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/images`,
    });
    return response.data;
  },
);

export const getOrderOffers = createAsyncThunk(
  "order/getOrderOffers",
  async (
    { refId, orderId }: { refId: string; orderId: any },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/offers`,
    });
    return response.data;
  },
);

export const getOrderDocs = createAsyncThunk(
  "order/getOrderDocs",
  async (
    { refId, orderId }: { refId: string; orderId: any },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/${orderId}/docs`,
    });
    return response.data;
  },
);

export const getOrderActions = createAsyncThunk(
  "order/getOrderActions",
  async (
    { refId, orderId }: { refId: string; orderId: any },
    thunkApi?: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/orders/${refId}/actions/${orderId}`,
    });
    return response.data;
  },
);

export const fetchGetUserForOrder = createAsyncThunk(
  "order/fetchGetUserForOrder",
  async (
    {
      refId,
      orderId,
      userId,
    }: { refId: string; orderId: string; userId: string },
    thunkApi?: any,
  ) => {
    const path = `${ENDPOINT}/orders/${refId}/${orderId}/users?userId=${userId}`;

    const response = await httpRequest({
      method: "GET",
      url: path,
    });
    return response.data;
  },
);

export const fetchSetWorker = createAsyncThunk(
  "order/fetchSetWorker",
  async (
    {
      refId,
      orderId,
      offerId,
    }: { refId: string; orderId: string; offerId: string },
    thunkApi?: any,
  ) => {
    // https://ref.rukki.space/orders/{ref-id}/{order-id}/set-worker?userId={user-id}

    const path = `${ENDPOINT}/pay/${refId}/init-session`;

    const response = await httpRequest({
      method: "POST",
      url: path,
      data: {
        type: "order",
        orderId: +orderId,
        offerId,
      },
    });
    return response.data;
  },
);

interface SearchOrder {
  refId?: any;
  date?: string;
  startDate?: string;
  budget?: string;
  distance?: string;
  longitude?: string;
  latitude?: string;
  categoryId?: any;
  me?: any;
  sortDate: "ASC" | "DESC";
  take?: any;
  skip?: any;
}

export const searchOrders = createAsyncThunk(
  "order/searchOrders",
  async (data: SearchOrder, thunkApi?: any) => {
    let paramsData = Object.create(null) as SearchOrder;

    if (data?.date) paramsData.date = data.date;
    if (data?.startDate) paramsData.startDate = data.startDate;
    if (data?.budget && parseInt(data.budget) !== 0)
      paramsData.budget = data.budget;
    if (data?.longitude) paramsData.longitude = data.longitude;
    if (data?.latitude) paramsData.latitude = data.latitude;
    if (data?.distance) paramsData.distance = data.distance;
    if (data?.categoryId) paramsData.categoryId = data.categoryId;
    if (data?.me) paramsData.me = data.me;
    if (data?.take) paramsData.take = data.take;
    if (data?.skip || data?.skip === 0) paramsData.skip = data.skip;
    paramsData.sortDate = data.sortDate;
    const params = new URLSearchParams({ ...paramsData });

    const path = `${ENDPOINT}/orders/${data.refId}/find`;

    const response = await httpRequest({
      method: "GET",
      url: path,
      params,
    });
    return response.data;
  },
);

export const fetchSendRespond = createAsyncThunk(
  "order/fetchSendRespond",
  async (data: any, thunkApi?: any) => {
    // const params = new URLSearchParams({...paramsData});

    const path = `${ENDPOINT}/orders/${data.refId}/${data.orderId}/respond`;
    const response = await httpRequest({
      method: "POST",
      url: path,
      data: {
        ...data,
      },
    });
    localStorage.setItem("offerId", response.data.offerId);
    return response.data;
  },
);

type Loading = "idle" | "pending" | "succeeded" | "failed";

interface IOrderDoc {
  createdAt: string;
  filename: string;
  fullpath: string;
  id: number;
  isSignedByCustomer: number;
  isSignedByWorker: number;
  type: any;
  documentType?: DocumentType;
  signDate?: string;
}

interface TOrder {
  myOrders: any;
  loading: Loading;
  orderDetail: any | null;
  orderDetailLoading: Loading;
  error: string | null;
  techTaskLoading: string;
  singleOrder: any;
  singleOrderLoading: Loading;
  singleOrderError: string | null;
  singleOrderNotFound: boolean;
  images: any;
  imagesLoading: string;
  imagesError: string | null;
  offers: Offer[];
  offersLoading: string;
  offersError: string | null;
  orderDocs: IOrderDoc[] | null;
  orderDocsLoading: Loading;
  orderActions: OrderActionsItem[] | null;
  orderActionsLoading: Loading;
  executorData: any;
  executorDataLoading: Loading;
  setWorkerLoader: Loading;
  orderList: any[];
  totalFoundOrders: number | null;
  loadingOrderList: Loading;
  loadingRespond: Loading;
  isFetching: boolean;
  isFetchMore: boolean;
  isWorkStart: boolean | null;
  selectWorkerLoading: Loading;
  cancelOfferLoading: Loading;
  myOrdersTotal: number;
  vehicleParams: VehicleParams[];
  vehicleParamsLoading: Loading;
  returnToDashboard: boolean | null;
  editProcess: Loading;
  createWorkerAgreementProcess: Loading;
  signWorkerAgreementProcess: Loading;
  getWorkerAgreementProcess: Loading;
  workerAgreement: any;
  pagination: {
    take: number;
    skip: number;
    page: number;
  };
  ordersFilter: MyOrdersFilter;
  orderStatusProcess: Loading;
}

export interface VehicleParams {
  id: number;
  additional: {
    title: string;
    values: string[] | number[];
    logic: boolean;
    type: "value" | "checkbox" | "input" | "radio" | "inputRange";
    index: number;
    measure: {
      id: number;
      fullName: string;
      shortName: string;
    };
  };
}

const initialState: TOrder = {
  myOrders: [],
  loading: "idle",
  orderDetail: null,
  orderDetailLoading: "idle",
  error: null,
  singleOrder: null,
  singleOrderLoading: "idle",
  singleOrderError: null,
  singleOrderNotFound: false,
  techTaskLoading: "idle",
  images: [],
  imagesLoading: "idle",
  imagesError: null,
  offers: [],
  offersLoading: "idle",
  offersError: null,
  orderDocs: null,
  orderActions: null,
  orderActionsLoading: "idle",
  orderDocsLoading: "idle",
  executorData: null,
  executorDataLoading: "idle",
  setWorkerLoader: "idle",
  orderList: [],
  totalFoundOrders: null,
  loadingOrderList: "idle",
  loadingRespond: "idle",
  isFetching: false,
  isFetchMore: false,
  isWorkStart: false,
  selectWorkerLoading: "idle",
  cancelOfferLoading: "idle",
  myOrdersTotal: 0,
  vehicleParams: [],
  vehicleParamsLoading: "idle",
  returnToDashboard: null,
  editProcess: "idle",
  createWorkerAgreementProcess: "idle",
  signWorkerAgreementProcess: "idle",
  getWorkerAgreementProcess: "idle",
  workerAgreement: null,
  pagination: {
    take: 10,
    skip: 0,
    page: 1,
  },
  ordersFilter: "in_progress",
  orderStatusProcess: "idle",
};

// order store {}

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    clearOrders(state) {
      state.myOrders = [];
      state.loading = "idle";
      state.orderDetail = null;
      state.orderDetailLoading = "idle";
      state.error = null;
      state.singleOrder = null;
      state.singleOrderLoading = "idle";
      state.singleOrderError = null;
      state.images = [];
      state.imagesLoading = "idle";
      state.imagesError = null;
      state.offers = [];
      state.offersLoading = "idle";
      state.offersError = null;
      state.orderDocs = null;
      state.orderDocsLoading = "idle";
      state.orderActions = null;
      state.orderActionsLoading = "idle";
      state.executorData = null;
      state.executorDataLoading = "idle";
      state.setWorkerLoader = "idle";
      state.orderList = [];
      state.loadingOrderList = "idle";
      state.loadingRespond = "idle";
      state.cancelOfferLoading = "idle";
    },
    clearSingleOrder(state) {
      state.singleOrder = null;
    },
    setReturnToDashboard(state) {
      state.returnToDashboard = true;
    },
    clearReturnToDashboard(state) {
      state.returnToDashboard = null;
    },
    setPagination(
      state,
      action: PayloadAction<{ take: number; skip: number; page: number }>,
    ) {
      state.pagination = { ...action.payload };
    },
    clearPagination(state) {
      state.pagination = { take: 10, skip: 0, page: 1 };
    },
    setFetchMore(state, action: PayloadAction<{ isFetchMore: boolean }>) {
      state.isFetchMore = true;
    },
    setOrdersFilter(state, action: PayloadAction<MyOrdersFilter>) {
      state.ordersFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkerAgreement.fulfilled, (state, action) => {
        state.getWorkerAgreementProcess = "succeeded";
        state.workerAgreement = action.payload;
        // state.vehicleParams = [...action.payload];
      })
      .addCase(getWorkerAgreement.pending, (state) => {
        state.getWorkerAgreementProcess = "pending";
      })
      .addCase(getWorkerAgreement.rejected, (state) => {
        state.getWorkerAgreementProcess = "failed";
      });
    builder
      .addCase(createWorkerAgreement.fulfilled, (state, action) => {
        state.createWorkerAgreementProcess = "succeeded";
        // state.vehicleParams = [...action.payload];
      })
      .addCase(createWorkerAgreement.pending, (state) => {
        state.createWorkerAgreementProcess = "pending";
      })
      .addCase(createWorkerAgreement.rejected, (state) => {
        state.createWorkerAgreementProcess = "failed";
      });
    builder
      .addCase(signWorkerAgreement.fulfilled, (state, action) => {
        state.signWorkerAgreementProcess = "succeeded";
        state.workerAgreement = action.payload;
      })
      .addCase(signWorkerAgreement.pending, (state) => {
        state.signWorkerAgreementProcess = "pending";
      })
      .addCase(signWorkerAgreement.rejected, (state) => {
        state.signWorkerAgreementProcess = "failed";
      });

    builder
      .addCase(getVehicleParams.fulfilled, (state, action) => {
        state.vehicleParamsLoading = "succeeded";
        state.vehicleParams = [...action.payload];
      })
      .addCase(getVehicleParams.pending, (state) => {
        state.vehicleParamsLoading = "pending";
      })
      .addCase(getVehicleParams.rejected, (state) => {
        state.vehicleParamsLoading = "failed";
      });
    builder
      .addCase(fetchCancelOffer.fulfilled, (state) => {
        state.cancelOfferLoading = "succeeded";
      })
      .addCase(fetchCancelOffer.pending, (state) => {
        state.cancelOfferLoading = "pending";
      })
      .addCase(fetchCancelOffer.rejected, (state) => {
        state.cancelOfferLoading = "failed";
      });
    builder
      .addCase(getMyOrders.fulfilled, (state, action: any) => {
        state.loading = "succeeded";
        if (action?.meta?.arg?.skip === 0) {
          state.myOrders = [
            // ...state.myOrders,
            ...action?.payload?.orders,
          ];
        } else {
          state.myOrders = [...action?.payload?.orders];
        }
        state.myOrdersTotal = action?.payload?.total;
        state.isFetchMore = action?.payload?.isFetchMore;
        state.isFetching = false;
      })
      .addCase(getMyOrders.pending, (state) => {
        state.loading = "pending";
        state.error = null;
        state.isFetching = true;
        state.isFetchMore = false;
      })
      .addCase(getMyOrders.rejected, (state, action) => {
        state.loading = "failed";
        state.error = "Внимание какая то ошибка";
        state.isFetching = false;
        state.isFetchMore = false;
      });

    builder
      .addCase(selectWorker.fulfilled, (state, action) => {
        state.selectWorkerLoading = "succeeded";
      })
      .addCase(selectWorker.rejected, (state, action) => {
        state.selectWorkerLoading = "failed";
      })
      .addCase(selectWorker.pending, (state, action) => {
        state.selectWorkerLoading = "pending";
      });

    builder
      .addCase(getSingleOrders.fulfilled, (state, action) => {
        state.singleOrderLoading = "succeeded";
        state.singleOrder = action.payload;
      })
      .addCase(getSingleOrders.pending, (state) => {
        state.singleOrderLoading = "pending";
        state.singleOrderError = null;
        state.singleOrderNotFound = false;
      })
      .addCase(getSingleOrders.rejected, (state, action) => {
        state.singleOrderLoading = "failed";
        state.singleOrderError = "Внимание какая то ошибка";
        state.singleOrderNotFound = Boolean(
          action?.error?.message?.includes("404"),
        );
      });

    builder
      .addCase(getTechTask.fulfilled, (state, action) => {
        state.techTaskLoading = "fulfilled";
      })
      .addCase(getTechTask.pending, (state, action) => {
        state.techTaskLoading = "pending";
      })
      .addCase(getTechTask.rejected, (state, action) => {
        state.techTaskLoading = "failed";
      });

    builder
      .addCase(getOrderWithoutSignIn.fulfilled, (state, action) => {
        state.singleOrderLoading = "succeeded";
        state.singleOrder = action.payload;
      })
      .addCase(getOrderWithoutSignIn.pending, (state) => {
        state.singleOrderLoading = "pending";
        state.singleOrderError = null;
      })
      .addCase(getOrderWithoutSignIn.rejected, (state) => {
        state.singleOrderLoading = "failed";
        state.singleOrderError = "Внимание какая то ошибка";
      });

    builder
      .addCase(getOrderImages.fulfilled, (state, action) => {
        state.imagesLoading = "succeeded";
        state.images = action?.payload;
      })
      .addCase(getOrderImages.pending, (state) => {
        state.imagesLoading = "pending";
        state.imagesError = null;
      })
      .addCase(getOrderImages.rejected, (state, action) => {
        state.imagesLoading = "failed";
        state.imagesError = "Внимание какая то ошибка";
      });

    builder
      .addCase(getOrderOffers.fulfilled, (state, action) => {
        state.offersLoading = "succeeded";
        state.offers = action?.payload;
      })
      .addCase(getOrderOffers.pending, (state) => {
        state.offersLoading = "pending";
        state.offersError = null;
      })
      .addCase(getOrderOffers.rejected, (state, action) => {
        state.offersLoading = "failed";
        state.offersError = "Внимание какая то ошибка";
      });

    builder
      .addCase(getOrderActions.fulfilled, (state, action) => {
        state.orderActionsLoading = "succeeded";
        state.orderActions = action?.payload;
      })
      .addCase(getOrderActions.pending, (state) => {
        state.orderActionsLoading = "pending";
        state.error = null;
      })
      .addCase(getOrderActions.rejected, (state, action) => {
        state.orderActionsLoading = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(getOrderDocs.fulfilled, (state, action) => {
        state.orderDocsLoading = "succeeded";
        state.orderDocs = action?.payload;
      })
      .addCase(getOrderDocs.pending, (state) => {
        state.orderDocsLoading = "pending";
        state.error = null;
      })
      .addCase(getOrderDocs.rejected, (state, action) => {
        state.orderDocsLoading = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(fetchGetUserForOrder.fulfilled, (state, action) => {
        state.executorDataLoading = "succeeded";
        state.executorData = action.payload;
      })
      .addCase(fetchGetUserForOrder.pending, (state) => {
        state.executorDataLoading = "pending";
        state.error = null;
      })
      .addCase(fetchGetUserForOrder.rejected, (state, action) => {
        state.executorDataLoading = "failed";
        state.error = "Внимание какая то ошибка";
        state.executorData = null;
      });
    builder
      .addCase(fetchSetWorker.fulfilled, (state, action) => {
        state.setWorkerLoader = "succeeded";
        // state.executorData = action.payload;
      })
      .addCase(fetchSetWorker.pending, (state) => {
        state.setWorkerLoader = "pending";
        state.error = null;
      })
      .addCase(fetchSetWorker.rejected, (state, action) => {
        state.setWorkerLoader = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(startWorkAsExecutor.fulfilled, (state) => {
        state.isWorkStart = true;
      })
      .addCase(startWorkAsExecutor.pending, (state) => {
        state.isWorkStart = null;
      })
      .addCase(startWorkAsExecutor.rejected, (state, action) => {
        state.isWorkStart = false;
        state.error = "Произошла ошибка при старте работы";
      });
    builder
      .addCase(searchOrders.fulfilled, (state, action) => {
        state.loadingOrderList = "succeeded";
        // state.executorData = action.payload;
        state.orderList = action.payload.orders;
        state.totalFoundOrders = action.payload.total;
      })
      .addCase(searchOrders.pending, (state) => {
        state.loadingOrderList = "pending";
        state.error = null;
      })
      .addCase(searchOrders.rejected, (state) => {
        state.loadingOrderList = "failed";
        state.error = "Внимание какая то ошибка";
        // state.executorData = null;
      });
    builder
      .addCase(fetchSendRespond.fulfilled, (state) => {
        state.loadingRespond = "succeeded";
        // state.executorData = action.payload;
        // state.orderList = action.payload;
      })
      .addCase(fetchSendRespond.pending, (state) => {
        state.loadingRespond = "pending";
        state.error = null;
      })
      .addCase(fetchSendRespond.rejected, (state, action) => {
        state.loadingRespond = "failed";
        state.error = "Внимание какая то ошибка";
        // state.executorData = null;
      });
    builder
      .addCase(editOrderData.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(editOrderData.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(editOrderData.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(doneOrder.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(doneOrder.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(doneOrder.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });
    builder
      .addCase(finishOrder.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(finishOrder.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(finishOrder.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });

    builder
      .addCase(cancelOrder.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(cancelOrder.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(cancelOrder.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });

    builder
      .addCase(startWork.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(startWork.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(startWork.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });

    builder
      .addCase(arrivedAtWork.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(arrivedAtWork.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(arrivedAtWork.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });

    builder
      .addCase(goingToWork.fulfilled, (state, action) => {
        state.editProcess = "succeeded";
      })
      .addCase(goingToWork.pending, (state) => {
        state.editProcess = "pending";
        state.error = null;
      })
      .addCase(goingToWork.rejected, (state, action) => {
        state.editProcess = "failed";
        state.error = "Внимание какая то ошибка";
      });
  },
});

export const {
  clearOrders,
  clearSingleOrder,
  setReturnToDashboard,
  clearReturnToDashboard,
  setPagination,
  clearPagination,
  setFetchMore,
  setOrdersFilter,
} = orderSlice.actions;
export { IOrderDoc };
export default orderSlice.reducer;

