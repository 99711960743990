import React, { memo, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const RedirectRef: React.FC = () => {
  const { refId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/login/${refId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refId]);

  return <div></div>;
};

export default memo(RedirectRef);
