import { createAsyncThunk } from "@reduxjs/toolkit";
import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";
import axios from "axios";

export const getAddressesAsync = createAsyncThunk(
  actionTypes.GET_ADDRESSES,
  async ({ text, center }: { text: string; center?: any }) => {
    try {
      const response = await httpRequest({
        method: "POST",
        path: `shared/adresses`,
        data: {
          query: text,
          count: 50,
        },
      });
      return response.data;
    } catch (err) {
      console.error(err);
    }
  },
);

export const getAddresses = (text: string, center?: any) => {
  return {
    type: actionTypes.GET_ADDRESSES,
    payload: httpRequest({
      method: "POST",
      url: `${ENDPOINT}/api/shared/dadata-sugest`,
      data: {
        query: text,
        count: 10,
        from_bound: { value: "city" },
        to_bound: { value: "house" },
        // lang: "ru_RU",
        // // lang: "en",
        // apikey: YANDEX_PLACE_APIKEY,
        // results: 4,
        // // ll: `${center.join(",")}~${center.join(",")}`,
        // // ll: "55.756994,37.618920",
        // ll: center.reverse().join(","),
        // spn: "0.552069,0.400552",
        // rspn: 0
        // bbox: "36.83,55.67~38.24,55.91"
        //ll: center?.reverse()?.join(","),
      },
    }),
    // payload: httpRequest({
    //   method: "GET",
    //   url: "https://suggest-maps.yandex.com/suggest-geo",
    //   params: {
    //     add_chains_loc: 1,
    //     add_rubrics_loc: 1,
    //     bases: "geo,biz,transit",
    //     client_reqid: "1679245050066_977313",
    //     exprt: 29,
    //     fullpath: 1,
    //     lang: "en_US",
    //     ll: "69.21753689999998,41.35202060002773",
    //     origin: "maps-search-form",
    //     outformat: "json",
    //     part: text,
    //     pos: 5,
    //     spn: "0.03546953201293945,0.006677667286076883",
    //     ull: "69.217537,41.352021",
    //     v: 9,
    //     yu: "111248481656401076",
    //     // text,
    //     // lang: "ru_RU",
    //     // // lang: "en",
    //     // apikey: YANDEX_PLACE_APIKEY,
    //     // results: 4,
    //     // // ll: `${center.join(",")}~${center.join(",")}`,
    //     // ll: "55.756994,37.618920",
    //     // spn: "0.552069,0.400552",
    //     // // rspn: 0
    //     // bbox: "36.83,55.67~38.24,55.91"
    //     // //ll: center?.reverse()?.join(","),
    //     // // spn: `0.1,0.1`,
    //   },
    // }),
  };
};
