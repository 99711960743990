import React, { memo, useEffect, useRef, useState } from "react";
import {
  Address,
  BudgetDeadline,
  ContentPart,
  DataWrapper,
  Desc,
  LeftData,
  MapWrapper,
  OrderName,
  RightData,
  SubTitle,
  Title,
  TitleValue,
  Wrapper,
} from "./styles";
import { useParams } from "react-router-dom";
import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { ReactComponent as Excavator } from "../../assets/icons/icons-vehicles-excavator.svg";
import OrderProcess from "../../components/orderProcess";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import getDashboardSingleOrder from "../../redux/modules/getDashboardSingleOrder";
import PageHeader from "../../UI/PageHeader";
import PageWrapper from "../../UI/PageWrapper";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { Skeleton } from "antd";
import moment from "moment";
import { getUser } from "../../redux/store/reducers/authSlice";
import { ENDPOINT_FOR_IMG } from "../../constants/general";
import {
  getOrderDocs,
  getSingleOrders,
} from "../../redux/store/reducers/orderSlice";
import Docs from "../../components/infoAboutOrderContainer/Docs";

const DashboardOrderData: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const ref = useRef<any>(null);

  const [center, setCenter] = useState([55.75, 37.57]);
  const [ymap, setYmap] = useState<any>(null);

  const { user, userView } = useAppSelector((state: RootState) => state.auth);
  const orderDocs = useAppSelector((state) => state.order.orderDocs);
  const data = useAppSelector(
    (state: RootState) => state.dashboardSingleOrders.data,
  );
  const { vehicleCategories } = useAppSelector(
    (state: RootState) => state.vehicle,
  );

  const getOrderTitle = () => {
    if (data && vehicleCategories) {
      const { title, icon }: { title: string; icon: string | null } =
        vehicleCategories?.find(
          (vehicle: any) => vehicle.id === data.categoryId,
        );
      return (
        <>
          <img
            src={`${ENDPOINT_FOR_IMG}/${icon ? icon : "excavator.png"}`}
            alt=""
            width={"24px"}
            height={"24px"}
          />
          {title}
        </>
      );
    }
  };

  useEffect(() => {
    if (user?.customer?.refId && id) {
      dispatch(getDashboardSingleOrder(user?.customer?.refId, id));
      dispatch(getOrderDocs({ refId: user?.customer?.refId!, orderId: id }));
    }
  }, [user, id]);

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, []);

  useEffect(() => {
    if (data && user?.customer?.refId) {
      dispatch(getUser({ id: Number(data?.creatorId) }));
      dispatch(
        getSingleOrders({
          refId: user?.customer?.refId,
          orderId: data?.id,
        }),
      );
      setCenter([data.latitude, data.longitude]);
      ymap?.setCenter([data.latitude, data.longitude], 17, {
        duration: 1000,
        timingFunction: "linear",
      });
    }
  }, [data]);

  return (
    <PageWrapper flexDirection="column" minHeight={"calc(100vh - 65px)"}>
      <PageHeader>
        Заказ №
        {data ? (
          data?.id
        ) : (
          <Skeleton
            paragraph={{ rows: 0, style: { margin: 0 } }}
            style={{ height: "24px" }}
            title={{
              width: "80px",
              style: { margin: 0, height: "24px" },
            }}
            active
          />
        )}
      </PageHeader>
      <ContentPart>
        <RightData>
          <MapWrapper>
            <YMaps query={{ lang: "ru_RU" }}>
              <Map
                onLoad={(e) => setYmap(e)}
                instanceRef={(e) => setYmap(e)}
                width={"100%"}
                height={"100%"}
                defaultState={{
                  center,
                  zoom: 9,
                }}
              >
                <Placemark
                  width="0px"
                  height="0px"
                  options={{
                    preset: "islands#circleIcon",
                    iconColor: "#423189",
                    draggable: false,
                    hideIconOnBalloonOpen: false,
                    hasBalloon: true,
                    balloonShadow: false,
                    balloonPanelMaxMapArea: 0,
                  }}
                  geometry={{
                    type: "Point",
                    coordinates: center,
                  }}
                  onDragEnd={(e: any) => {
                    const coordinates = e.get("target").geometry._coordinates;
                    //   setLocation(coordinates);
                    //   getAddress(coordinates);
                  }}
                  properties={{
                    iconContent: `
                          <div class="myIconContentBoxAnimation">
                            <div class="first-circle"></div>
                            <div class="second-circle"></div>
                            <div class="third-circle"></div>
                            <div class="forth-circle"></div>
                            <div class="core-circle"></div>
                          </div>`,
                  }}
                />
                <ZoomControl
                  options={{
                    position: { right: 10, top: 20 },
                  }}
                />
              </Map>
            </YMaps>
          </MapWrapper>
          <DataWrapper ref={ref}>
            <SubTitle>
              {data ? (
                <>{`#${data.id}, ${moment(data.createdAt).format(
                  "DD MMMM YYYY",
                )}`}</>
              ) : (
                <Skeleton
                  paragraph={{
                    rows: 0,
                    style: { margin: 0 },
                  }}
                  style={{ height: "17px" }}
                  title={{
                    width: "35%",
                    style: { margin: 0, height: "17px" },
                  }}
                  active
                />
              )}
            </SubTitle>
            <OrderName>
              {data && vehicleCategories ? (
                <>{getOrderTitle()}</>
              ) : (
                <Skeleton
                  paragraph={{
                    rows: 0,
                    style: { margin: 0 },
                  }}
                  style={{ height: "24px" }}
                  title={{
                    width: "75%",
                    style: { margin: 0, height: "24px" },
                  }}
                  active
                />
              )}
            </OrderName>
            <BudgetDeadline>
              <TitleValue>
                <div className="title">Бюджет</div>
                {data ? (
                  data?.budget ? (
                    <div className="value">{data?.budget}</div>
                  ) : (
                    <div className="value">По договоренности</div>
                  )
                ) : (
                  <Skeleton
                    paragraph={{
                      rows: 0,
                      style: { margin: 0 },
                    }}
                    style={{ height: "17px" }}
                    title={{
                      width: "75%",
                      style: {
                        margin: 0,
                        height: "17px",
                      },
                    }}
                    active
                  />
                )}
              </TitleValue>
              <TitleValue>
                <div className="title">Сроки</div>
                {data ? (
                  data?.startDate ? (
                    <div className="value">
                      {moment(data?.startDate).format("DD MMMM, HH:mm")}
                    </div>
                  ) : (
                    <div className="value">По договоренности</div>
                  )
                ) : (
                  <Skeleton
                    paragraph={{
                      rows: 0,
                      style: { margin: 0 },
                    }}
                    style={{ height: "17px" }}
                    title={{
                      width: "75%",
                      style: {
                        margin: 0,
                        height: "17px",
                      },
                    }}
                    active
                  />
                )}
              </TitleValue>
              <TitleValue>
                <div className="title">Заказчик</div>
                {userView ? (
                  <div className="value">{`${userView?.firstName} ${userView?.secondName}`}</div>
                ) : (
                  <Skeleton
                    paragraph={{
                      rows: 0,
                      style: { margin: 0 },
                    }}
                    style={{ height: "17px" }}
                    title={{
                      width: "75%",
                      style: {
                        margin: 0,
                        height: "17px",
                      },
                    }}
                    active
                  />
                )}
              </TitleValue>
            </BudgetDeadline>
            <Address>
              <div className="title">Адрес</div>
              {data ? (
                <div className="value">{data?.address}</div>
              ) : (
                <Skeleton
                  paragraph={{
                    rows: 0,
                    style: { margin: 0 },
                  }}
                  style={{ height: "17px" }}
                  title={{
                    width: "75%",
                    style: { margin: 0, height: "17px" },
                  }}
                  active
                />
              )}
            </Address>
          </DataWrapper>
        </RightData>
        <LeftData>
          <OrderProcess />
          {user?.customer && !!orderDocs?.length && (
            <Docs
              userInfo={{
                ...user?.customer!,
              }}
            />
          )}
        </LeftData>
      </ContentPart>
    </PageWrapper>
  );
};

export default memo(DashboardOrderData);
