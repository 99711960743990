import styled from "styled-components";

export const AccordionWrapper = styled.div<{ margin?: string; open: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
  ${({ margin }) => margin && `margin: ${margin}`};
`;

export const AccordionHeader = styled.div<{ open: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  line-height: 1.2;
  color: #000;
  cursor: pointer;

  svg {
    transition: all 0.3s linear;
    transform: ${({ open }) => (open ? "rotate(360deg)" : "rotate(270deg)")};
  }
`;

export const OrdersListWrapper = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;
export const OrderItem = styled.div`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(226, 226, 226, 0.12);
  border: solid 1px #fcfcfc;
  cursor: pointer;
  transition: all 0.5s ease;
  animation: anim 0.3s ease-out;

  :hover {
    border-bottom: 1px solid #21c17a;
    box-shadow: 0 2px 12px 0 rgba(102, 102, 102, 0.12);
  }
`;

export const AccordionBody = styled.div<{
  open: boolean;
  maxHeight?: string;
  buttonVisible?: boolean;
  title?: string;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  overflow: hidden;
  transition: all 0.35s ease-in;
  margin-top: ${({ open }) => (open ? "0px" : "-16px")};
  max-height: ${({ open, maxHeight }) =>
    open ? (maxHeight ? maxHeight : "100%") : "0px"};

  .hiddenButton {
    width: 100%;
    height: ${({ buttonVisible }) => (buttonVisible ? "48px" : "0px")};
    margin-top: ${({ buttonVisible }) => (buttonVisible ? "0px" : "-16px")};
    transition: all 0.3s linear;
  }

  ::-webkit-scrollbar {
    width: 3px;
  }

  ::-webkit-scrollbar-track {
    background: #e5e5e5;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(63, 63, 63, 0);
    border-radius: 8px;
    border: 3px solid #a1a1a1;
  }
  ::-webkit-scrollbar:hover {
    width: 3px;
  }

  ::-webkit-scrollbar-thumb:hover {
    width: 6px;
    color: #21c17a; /* width of the entire scrollbar */
  }

  @keyframes accordionOpen {
  }

  @keyframes accordionClose {
  }
`;
