import React, { memo, useEffect, useRef } from "react";
import { SideMenuBackground, SideMenuHeader, SideMenuWrapper } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-24-intellicon-basic-circle-x.svg";
import { StyledComponent } from "styled-components";

interface SideMenuProps {
  menuOpen: boolean;
  menuTitle?: string;
  children?: any[] | string | React.ReactNode;
  toggleMenuOpen: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({
  menuOpen,
  menuTitle,
  children,
  toggleMenuOpen,
}) => {
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    menuOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
    // if (!menuOpen) {
    //   setTimeout(() => {
    //     if (menuWrapperRef.current) {
    //       menuWrapperRef.current.style.display = "none";
    //     }
    //   }, 300);
    // } else {
    //   if (menuWrapperRef.current) {
    //     menuWrapperRef.current.style.display = "flex";
    //   }
    // }
  }, [menuOpen]);

  return (
    <>
      <SideMenuBackground
        open={menuOpen}
        onClick={toggleMenuOpen}
      ></SideMenuBackground>
      <SideMenuWrapper open={menuOpen} ref={menuWrapperRef}>
        <SideMenuHeader>
          {menuTitle} <CloseIcon onClick={toggleMenuOpen} />
        </SideMenuHeader>
        {children}
      </SideMenuWrapper>
    </>
  );
};

export default memo(SideMenu);
