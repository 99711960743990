import { Empty } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import getManagerData from "../../../redux/modules/getManagerData";
import getManagers from "../../../redux/modules/getManagers";
import PopUpContainer from "../popUpContainer";
import { PopUpWrapper } from "../popUpContainer/styles";
import {
  Address,
  BodyPart,
  BudgetDeadline,
  DataWrapper,
  Desc,
  FooterPart,
  OrderItem,
  OrdersListWrapper,
  OrderWrapper,
  SubTitle,
  Title,
  TitlePart,
  TitleValue,
} from "./styles";

import { ReactComponent as Excavator } from "../../../assets/icons/icons-vehicles-excavator.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/icons-chevron-right.svg";
import avatarWorker from "../../../assets/images/avatar-worker.png";
import { useNavigate } from "react-router-dom";

const SingleManagerPopUp: React.FC<any> = ({ id, close }) => {
  const dispatch = useDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { data } = useAppSelector((state) => state.managerData);
  const { data: managers } = useAppSelector((state) => state.managers);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getManagerData(user?.customer?.refId, id));
      dispatch(getManagers(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);
  return (
    <PopUpContainer middle close={close}>
      <DataWrapper>
        <Title>
          {data?.firstName} {data?.secondName}
        </Title>
        <SubTitle>
          #{data?.id}, создан {moment(data?.createdAt).format("LL")}
        </SubTitle>
        <BudgetDeadline>
          <TitleValue>
            <div className="title">Телефон</div>
            <div className="value">{data?.phoneNumber}</div>
          </TitleValue>
          <TitleValue />
          <TitleValue>
            <div className="title">Заказов всего</div>
            <div className="value">{data?.orders?.length || 0}</div>
          </TitleValue>
          {data?.orders && (
            <TitleValue>
              <div className="title">Заказчик</div>
              <div className="value">{data?.customer}</div>
            </TitleValue>
          )}
        </BudgetDeadline>
        {data?.orders && (
          <>
            <Desc>
              <div className="title">Примечание</div>
              <div className="text">{data?.description}</div>
            </Desc>
            <Address>
              <div className="title">Адрес</div>
              <div className="text">{data?.address}</div>
            </Address>
          </>
        )}
      </DataWrapper>
      {data?.orders.length === 0 ? (
        <Empty className="emptyTag" />
      ) : (
        <OrdersListWrapper>
          {data?.orders?.map(
            ({
              id,
              title,
              categoryId,
              createdAt,
              address,
              status,
              startDate,
              endDate,
              category,
              offerCount,
            }: any) => (
              <OrderItem key={id} onClick={() => navigate(`/o/${id}`)}>
                <TitlePart>
                  <Excavator />
                  <div className="text">{title}</div>
                  <div className="date">{moment(createdAt).format("ll")}</div>
                </TitlePart>
                <BodyPart>
                  <div>{address}</div>
                  <div>
                    Выполнить &#160;
                    {moment(startDate).format("ll")}
                  </div>
                </BodyPart>
                <FooterPart>
                  <div className="offer">
                    <img src={avatarWorker} alt="" />
                    Откликов: {offerCount}
                  </div>
                  <div className="status process">
                    <ChevronRight />
                    {status === "InProgress"
                      ? "Идут работы"
                      : status === "Finished"
                      ? "Завершено"
                      : status === "Published"
                      ? "Опубликовано"
                      : status === "Done"
                      ? "Завершен"
                      : status === "WorkerSetOut"
                      ? "Идут работы"
                      : status === "WorkerSelected"
                      ? "Исполнитель выбран"
                      : status}
                  </div>
                </FooterPart>
              </OrderItem>
            ),
          )}
        </OrdersListWrapper>
      )}
    </PopUpContainer>
  );
};

export default SingleManagerPopUp;
