import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

const getFeedbackRating = (userId: string | number, refId: string) => ({
  type: actionTypes.GET_FEEDBACK_RATING,
  payload: httpRequest({
    method: "GET",
    url: `${ENDPOINT}/feedback/user/${userId}`,
    params: {
      id: refId,
    },
  }),
});

export default getFeedbackRating;
