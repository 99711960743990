import React, { memo, useCallback, useEffect, useState } from "react";
import StatusTrack from "../statusHistorySidebar/statusTrack";
import StatusHistorySidebar from "../statusHistorySidebar";
import { Wrapper, CardWrapper, StatusLabel } from "./styles";
import { ReactComponent as WaitIcon } from "../../assets/icons/icons-24-status-icon-waiting.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-24-basic-chevron-right-gray.svg";
import { ReactComponent as BellIcon } from "../../assets/icons/icon-24-intellicon-notifications-bell-white.svg";
import Button from "../../UI/Button";
import {
  WorkerOrderStatuses,
  CustomerOrderStatuses,
} from "../../constants/orderStatusCards";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import OrderStatusConfirmPopup from "../popUp/orderStatusConfirmPopup";
import { toast } from "react-toastify";
import { CustomerRole } from "../../types";
import PopUpContainer from "../popUp/popUpContainer";
import { InputBox } from "../form";
import { PopUpRateBody } from "../orderProcess/styles";
import { Rate } from "antd";
import { StarFilled } from "@ant-design/icons";
import { sendFeedback } from "../../redux/store/reducers/orderSlice";
import { CompanyAccessSettings } from "../../types/companyAccessSettings.types";

const OrderStatus: React.FC<{
  history: any[];
  refId: string;
  userId: number;
  orderId: string | number;
  role: CustomerRole;
  status: string | undefined;
  workerId: number | undefined;
  feedbacks?: any[];
  userAccessSettings?: CompanyAccessSettings | null;
}> = ({
  history,
  refId,
  orderId,
  role,
  status,
  workerId,
  feedbacks,
  userId,
  userAccessSettings,
}) => {
  const dispatch = useAppDispatch();
  const editProcess = useAppSelector((state) => state.order.editProcess);
  const user = useAppSelector((state) => state.auth.user);

  const [statusHistoryOpen, setStatusHistoryOpen] = useState<boolean>(false);
  const [statusConfirmPopup, setStatusConfirmPopup] = useState<string>("");
  const [location, setLocation] = useState<[number, number] | null>(null);
  const [rateOpen, setRateOpen] = useState<boolean>(false);
  const [rateData, setRateData] = useState<{ text: string; rate: number }>({
    text: "",
    rate: 0,
  });
  const getUserLocation = () => {
    window?.navigator?.geolocation?.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position?.coords;
        setLocation([latitude, longitude]);
      },
      (err) => {
        toast.error(
          "Чтобы видеть вашу геопозицию, откройте доступ в настройках браузера.",
          {
            autoClose: 7000,
          },
        );
      },
    );
  };

  const handleSendRate = () => {
    dispatch(
      sendFeedback({
        refId,
        userId: workerId!,
        data: {
          rating: rateData.rate,
          description: rateData.text,
          orderId: Number(orderId),
          rewards: [],
        },
      }),
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setRateOpen(false);
      }
    });
  };

  const getStatusCard = useCallback(() => {
    const lastStatusData = (
      role === "worker" ? WorkerOrderStatuses : CustomerOrderStatuses
    ).find((orderStatus) => orderStatus.type === status);
    if (lastStatusData) {
      return (
        <CardWrapper>
          <StatusLabel>
            <div className="icon">
              <WaitIcon />
            </div>

            <div className="titleGroup">
              {lastStatusData.title}
              {/* <div className="subTitle">
                {moment(lastStatus.createdAt).format("HH:mm, DD MMMM YYYY")}
              </div> */}
            </div>
          </StatusLabel>
          <div className="text">{lastStatusData.text}</div>
          {lastStatusData?.button && lastStatusData?.type && (
            <Button
              height="48px"
              fontWeight="normal"
              width="fit-content"
              gap={"8px"}
              onClick={() => {
                lastStatusData.type === "done"
                  ? setRateOpen(true)
                  : setStatusConfirmPopup(lastStatusData?.type);
              }}
              onDisabledAction={
                lastStatusData.type !== "done" ? getUserLocation : undefined
              }
              disabled={
                (!location && lastStatusData.type !== "done") ||
                editProcess === "pending" ||
                lastStatusData.type === "done"
                  ? !!feedbacks?.find(
                      (feedback) => feedback.createdBy.id === userId,
                    ) ||
                    (!!userAccessSettings &&
                      !userAccessSettings?.feedbackWriting)
                  : lastStatusData.type === "worker_finished"
                  ? !!userAccessSettings && !userAccessSettings?.acceptanceWork
                  : false
              }
            >
              <BellIcon />
              {lastStatusData?.button}
            </Button>
          )}
          {lastStatusData?.type === "created" && (
            <div
              className="historyButton"
              onClick={() => setStatusHistoryOpen(true)}
            >
              Показать пример заказа
              <ArrowIcon />
            </div>
          )}
        </CardWrapper>
      );
    } else return null;
  }, [status, feedbacks, history]);

  useEffect(() => {
    getUserLocation();
  }, [user]);

  useEffect(() => {
    statusHistoryOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [setStatusHistoryOpen]);

  return (
    <Wrapper id="order_process">
      <CardWrapper>
        <div className="cardTitle">Выполнение заказа</div>
        <StatusTrack
          history={history}
          role={role}
          refId={refId}
          orderId={orderId}
          status={status}
        />
        <div
          className="historyButton"
          onClick={() => setStatusHistoryOpen(true)}
        >
          История событий
          <ArrowIcon />
        </div>
      </CardWrapper>
      {getStatusCard()}
      <StatusHistorySidebar
        open={statusHistoryOpen}
        toggleOpen={setStatusHistoryOpen}
        history={history}
        role={role}
        refId={refId}
        orderId={orderId}
        status={status}
      />
      {statusConfirmPopup && (
        <OrderStatusConfirmPopup
          close={() => setStatusConfirmPopup("")}
          type={statusConfirmPopup}
          refId={refId}
          orderId={orderId}
          location={location!}
        />
      )}
      {rateOpen && (
        <PopUpContainer
          title={
            role === "customer" ? "Оценка исполнителя" : "Оценка заказчика"
          }
          middle
          close={() => setRateOpen(false)}
          button="Отправить отзыв"
          onSubmit={handleSendRate}
          //disabled={rateProcess.loading || statusLoading}
        >
          <PopUpRateBody>
            <div className="rate-box">
              <Rate
                character={
                  <div
                    style={{
                      fontSize: "45px",
                    }}
                  >
                    <StarFilled />
                  </div>
                }
                value={rateData.rate}
                onChange={(e: number) => setRateData({ ...rateData, rate: e })}
              />
            </div>
            <InputBox
              label="Подробный отзыв"
              type="textarea"
              autoFocus
              value={rateData.text}
              placeholder={
                rateData.rate > 2
                  ? "Подробный отзыв"
                  : "Подскажите что вас не устроило в заказе?"
              }
              onChange={(e: any) =>
                setRateData({
                  ...rateData,
                  text: e.target.value,
                })
              }
            />
            <div className="desc">
              {`${
                role === "customer" ? "Исполнитель" : "Заказчик"
              } уже оставил отзыв. Чтобы увидеть его, отправьте свою оценку.`}
            </div>
          </PopUpRateBody>
        </PopUpContainer>
      )}
    </Wrapper>
  );
};

export default memo(OrderStatus);

