import { Container, TextLight12 } from "./../styles/styles";
import styled from "styled-components";
import { styles, Text16 } from "../styles/styles";

export const InputWrapper = styled.label<{ active?: boolean }>`
  width: 100%;
  height: 54px;
  background-color: white;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid
    ${({ active }) =>
      active
        ? // styles.mainColor
          "var(--color-gray)"
        : "var(--color-light-gray)"};
  display: block;

  span.label {
    width: 100%;
    display: block;
    font-size: 12px;
    line-height: 1;

    span.required {
      //color: ${styles.mainColor};
      display: inline;
    }

    .text {
      display: inline;
      /* color:${styles.mainColor}; */
      color: var(--color-black);
      opacity: ${({ active }) => (active ? 1 : 0.5)};
    }
  }

  :not(.date-input) input {
    width: 100%;
    height: 0;
    display: block;
    border: 0;
    background-color: transparent;
    outline: 0;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
    transition-duration: 0.5s;
    overflow: hidden;
  }

  .ant-picker {
    padding: 0;
    height: 100%;
    width: 100%;
    border: 0;

    .ant-picker-suffix {
      display: none;
    }
  }

  .ant-picker-focused {
    border: 0;
    box-shadow: unset !important;
    outline: 0;
  }

  .date-input {
    height: 0;
    display: flex;
    transition-duration: 0.5s;
    overflow: hidden;

    input {
      height: unset;
    }
  }

  .label,
  .text,
  .required {
    font-size: 16px;
    transition-duration: 0.5s;
    padding: 6px 0;
  }

  &:focus-within,
  &.active {
    .label,
    .text,
    .required {
      font-size: 12px;
      padding: 0;
    }

    :not(.date-input) input {
      height: 16px;
    }

    .date-input {
      height: 16px;

      input {
        height: inherit;
      }
    }
  }
`;

export const MainWrapper = styled(Container)``;

export const BoxesWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const PaymentDetailWrapper = styled.div`
  width: 325px;
  height: fit-content;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const PromoCodeText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #21c17a;
  margin-top: 8px;
`;

export const ContactDetail = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 24px;
`;

export const InputsWrapper = styled.div`
  display: flex;
  gap: 17px;

  @media screen and (max-width: 631px) {
    flex-direction: column;
    gap: 8px;
  }
`;

export const ContactDesc = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  margin-top: 4px;

  @media screen and (max-width: 440px) {
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const FirstColumn = styled.div`
  width: calc(100% - (325px + 16px));
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TimerWrapper = styled.div`
  width: 100%;
  height: 80px;
  padding: 16px 24px;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 10px;

  .text,
  .timer > .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #ff6633;
  }

  .timer {
    display: flex;
    align-items: center;
    gap: 4px;
    background: #fff3ef;
    width: 107px;
    height: 32px;
    padding: 4px 8px;
    border-radius: 10px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 24px;
  background-color: white;
  border-radius: 10px;
`;

export const Title = styled.div`
  margin-bottom: 16px;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
`;

export const Desc = styled.div`
  width: 100%;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #222222;
`;

export const PaymentMethodList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px 12px;
`;

export const PaymentTitle = styled(Title)`
  width: 100%;
  margin: 0;
`;

export const PaymentMethodBox = styled.div<{ active?: boolean }>`
  width: 152px;
  height: 104px;
  padding: 8px 8px 16px 16px;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${({ active }) => (active ? styles.mainColor : "white")};

  &:hover {
    background: ${({ active }) =>
      active ? "rgba(255, 102, 51, 0.9)" : "rgba(0, 0, 0, 0.05)"};
  }

  @media screen and (max-width: 440px) {
    width: 100%;
  }
`;

export const PaymentHeader = styled.div<{ active?: boolean }>`
  display: flex;

  .text {
    width: calc(100% - 30px);
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: ${({ active }) => (active ? "white" : "black")};
  }

  .circle {
    width: 23px;
    height: 23px;
    margin-left: 7px;
    background: #ffffff;
    border: 1px solid #9e9e9e;
    border-radius: 50%;
  }

  svg.circle {
    border: 0;
    background-color: transparent;
  }

  .check {
    width: 23px;
    height: 23px;
  }
`;

export const PaymentDesc = styled.div<{ active?: boolean }>`
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: ${({ active }) => (active ? "white" : "black")};

  @media screen and (max-width: 440px) {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const PaymentDescInfo = styled.div<{ active?: boolean }>`
  font-size: 12px;
  line-height: 1;
  letter-spacing: -0.25px;
  color: #999999;
  margin-top: 5px;

  @media screen and (max-width: 440px) {
    font-size: 12px;
    margin-top: 5px;
  }
`;

export const Footer = styled(TextLight12)<{ active?: boolean }>`
  margin-top: auto;
  margin-bottom: 0;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  color: ${({ active }) =>
      active ? "white;opacity:1;" : "#222222;opacity:0.5;"}
    small {
    font-size: 11px;
    line-height: 14px;
  }
`;

export const CardData = styled.div`
  margin-top: 4px;

  b {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-right: 4px;
  }

  small {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    mix-blend-mode: normal;
    opacity: 0.5;
  }
`;

export const PaymentBox = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1050px) {
    gap: 25px;
  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
  }
`;

export const Half = styled.div`
  width: 328px;
  @media screen and (max-width: 640px) {
    width: 100%;
    .half_btn {
      max-width: 320px;
      width: 100%;
    }
  }
`;

export const PromoCodeInputBox = styled.div`
  display: flex;
  align-items: flex-end;

  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
  }

  button {
    width: fit-content;
    height: fit-content;
    margin: 0 0 5px auto;
    padding: 8px 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
  }
`;

export const PromoCodeResult = styled(Text16)`
  color: #21c17a;
  margin-top: 3px;
`;

export const AgreePrivacy = styled.div`
  color: #666666;
  margin-bottom: 0px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: 0;
  white-space: break-spaces;

  span {
    color: ${styles.mainColor};
    cursor: pointer;
  }
`;

export const PrivacyBox = styled(Half)`
  .icon {
    width: 32px;
    height: 32px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    margin: 12px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  .desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  @media screen and (max-width: 640px) {
    width: 100%;
    .desc {
      width: 100%;
    }
  }
`;

export const PaymentDetail = styled.div`
  margin-top: 40px;
  margin-bottom: 16px;

  .title {
    color: #222222;
    margin-bottom: 12px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }
`;

export const PaymentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 7px;
  align-items: center;

  &.total {
    margin-top: 16px;
    margin-bottom: 0;
  }

  .name {
    color: #222222;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
  }

  .price {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .red {
    color: #ff3358;
  }

  .total {
    color: black;
    opacity: 1;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
  }
`;

export const PopUpDesc = styled.div`
  margin: 32px 0 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #909090;

  span {
    color: ${styles.mainColor};
    font-weight: bold;
  }
`;

export const CardDetailData = styled.div`
  .data {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    .half {
      width: 48%;
    }
  }
`;

export const BankData = styled.div`
  margin-bottom: 29px;

  img {
    margin-bottom: 8px;
    height: 32px;
  }

  .text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;

    color: #909090;
  }

  input {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }
`;

export const ErrorBody = styled.div`
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg {
      width: 24px;
      height: 24px;
      margin-right: 12px;
    }
  }

  .desc {
    margin-left: 36px;
  }
`;
