import styled from "styled-components";

export const Wrapper = styled.div<{ footer: number; navbar: number }>`
  width: 100vw;
  height: calc(100vh - ${({ footer, navbar }) => footer + navbar}px);
  overflow: hidden;
`;

export const FilterWrapper = styled.div`
  width: 100%;
  height: 80px;
  background-color: white;
  border-top: 1px solid rgba(153, 153, 153, 0.4);
  padding: 16px 24px;
  display: flex;
  gap: 16px;

  .rangeInputWrapper {
    width: max-content;
    height: max-content;

    @media screen and (max-width: 998px) {
      display: none;
    }
  }
`;

export const ListButton = styled.div<{ map?: boolean }>`
  width: ${({ map }) => (map ? "215px" : "203px")};
  height: 48px;
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
  border: solid 1px #e8e8e8;
  border-radius: 10px;
  padding: 0 16px;
  background: #ffffff;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s linear;

  svg {
    transition: all 0.3s linear;
    path {
      transition: all 0.3s linear;
    }
  }

  &:hover {
    border: solid 1px #ff6633;
    color: #ff6633;

    svg {
      path {
        fill: #ff6633;
      }
    }
  }

  @media screen and (max-width: 1609px) {
    position: absolute;
    right: 50px;
    top: 150px;
  }

  @media screen and (max-width: 576px) {
    right: unset;
    top: unset;
    left: 10px;
    bottom: 10px;
  }
`;

export const GeolocationButton = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #e8e8e8;
  border-radius: 10px;
  background: #ffffff;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s linear;
  bottom: 10px;
  right: 10px;
  position: absolute;
  svg {
    transition: all 0.3s linear;
    path {
      transition: all 0.3s linear;
    }
  }

  &:hover {
    border: solid 1px #ff6633;
    color: #ff6633;

    svg {
      path {
        fill: #ff6633;
      }
    }
  }
`;

export const FiltersButton = styled.div`
  width: 152px;
  height: 48px;
  align-items: center;
  padding: 12px 8px;
  display: none;
  position: relative;
  border-radius: 10px;
  border: solid 1px #00000019;
  gap: 8px;
  cursor: pointer;
  background: #ffffff;
  z-index: 10;

  .counter {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff6633;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #ffffff;
    width: 24px;
    height: 20px;
    border-radius: 9px;
    top: -10px;
    right: -10px;
  }

  .text {
    display: flex;
    align-items: center;

    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  @media screen and (max-width: 998px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 480px) {
    width: 48px;
  }
`;

export const OrdersListMenu = styled.div<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  top: 145px;
  z-index: 10;
  width: ${({ open }) => (open ? "352px" : "0px")};
  overflow-x: hidden;
  height: 100%;
  transition: all 0.3s linear;
  background: #f7f4f1;
  padding: ${({ open }) => (open ? "16px" : "0px")};
  overflow-y: auto;
  gap: 16px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 100%;

    .text {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #000;
    }

    svg {
      cursor: pointer;
    }
  }

  .ordersWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
  }

  @media screen and (max-width: 480px) {
    width: ${({ open }) => (open ? "100%" : "0%")};
    top: 0px;
  }
`;

export const OrdersListItem = styled.div`
  width: 320px;
  height: 368px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 2px 8px 0 #00000014;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LocationInputWrapper = styled.div`
  width: calc(100% - (238px + 180px + 254px + 16px * 3));
  max-width: 656px;
  height: 48px;

  @media screen and (max-width: 1660px) {
    max-width: 620px;
  }

  @media screen and (max-width: 998px) {
    width: calc(100% - 152px - 16px);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    max-width: calc(100% - 48px - 16px);
  }
`;

export const PeriodWrapper = styled.div`
  width: 238px;

  @media screen and (max-width: 998px) {
    display: none;
  }
`;
export const RadiusWrapper = styled.div`
  width: 180px;

  @media screen and (max-width: 998px) {
    display: none;
  }
`;
export const MyVehicleWrapper = styled.div`
  width: 254px;

  @media screen and (max-width: 998px) {
    display: none;
  }
`;

export const YandexMapWrapper = styled.div`
  /* width: 100vw; */
  height: calc(100% - 80px);
  display: flex;
  @media only screen and (max-width: 576px) {
    height: calc(100vh - 0px);
    /* min-height: calc(100vh - 0px); */
  }
`;

