import React, { memo } from "react";
import { Wrapper } from "./styles";
import { Empty } from "antd";

const FeedbackEmptyPage: React.FC = () => {
  return (
    <Wrapper>
      <Empty description="У вас еще нет ни одного отзыва." />
    </Wrapper>
  );
};

export default memo(FeedbackEmptyPage);
