import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { useAppDispatch } from "../../../redux/hooks";
import {
  arrivedAtWork,
  cancelOrder,
  doneOrder,
  finishOrder,
  goingToWork,
  startWork,
} from "../../../redux/store/reducers/orderSlice";
import { getUserLocation } from "../../../utils/getUserLocation";

const OrderStatusConfirmPopup: React.FC<{
  type: string;
  close: () => void;
  location: [number, number];
  refId: string;
  orderId: string | number;
}> = ({ type, close, location, refId, orderId }) => {
  const [popupData, setPopupData] = useState<any>();
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    switch (type) {
      case "waiting_for_payment":
        dispatch(goingToWork({ refId, orderId, location }));
        break;
      case "worker_on_the_way":
        dispatch(arrivedAtWork({ refId, orderId, location }));
        break;
      case "worker_set_out":
        dispatch(startWork({ refId, orderId, location }));
        break;
      case "cancelWork":
        dispatch(cancelOrder({ refId, orderId }));
        break;
      case "worker_started":
        dispatch(finishOrder({ refId, orderId, location }));
        break;
      case "worker_finished":
        dispatch(doneOrder({ refId, orderId, location }));
        break;
      default:
        break;
    }
    close();
  };

  useEffect(() => {
    switch (type) {
      case "waiting_for_payment":
        setPopupData({
          title: "Еду к месту назначения",
          button: "Еду к месту назначения",
          text: "Вы едете к месту назначения",
        });
        break;
      case "worker_on_the_way":
        setPopupData({
          title: "Прибыл на объект",
          button: "Прибыл на объект",
          text: "Вы уверены, что прибыли в место назначения?",
        });
        break;
      case "worker_set_out":
        setPopupData({
          title: "Начать работу",
          button: "Начать работу",
          text: "Вы уверены, что хотите начать работу?",
        });
        break;
      case "cancelWork":
        setPopupData({
          title: "Отменить работу",
          button: "Отменить работу",
          text: "Вы действительно хотите отменить эту работу?",
        });
        break;
      case "worker_started":
        setPopupData({
          title: "Закончить работу",
          button: "Закончить работу",
          text: "Вы действительно закончили эту работу?",
        });
        break;
      case "worker_finished":
        setPopupData({
          title: "Вы принимаете работу",
          button: "Да, принять работу",
          text: "Нажимая кнопку ниже, вы соглашаетесь с тем, что задание выполнено и вы принимаете выполненный результат, не имея претензий. \n\nПосле подтверждения приема работы исполнитель получит свою оплату",
        });
        break;
      default:
        break;
    }
  }, [type]);
  return popupData ? (
    <PopUpContainer
      title={popupData.title}
      close={close}
      onSubmit={onSubmit}
      button={popupData.button}
      //disabled={rateProcess.loading || statusLoading || buttonClicked}
    >
      {popupData.text}
    </PopUpContainer>
  ) : null;
};

export default memo(OrderStatusConfirmPopup);

