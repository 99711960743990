import styled from "styled-components";

export const ButtonWrapper = styled.div<{
  outlined?: boolean;
  width?: string;
  maxWidth?: string;
  disabled?: boolean;
  height?: string;
  fontSize?: string;
  fontWeight?: string;
  fontColor?: string;
  letterSpacing?: string;
  background?: string;
  border?: string;
  variant?: "outlined" | "primary" | "secondary" | "small";
  borderRadius?: string;
  gap?: string;
  shadowed?: boolean;
  marginTop?: string;
}>`
  display: flex;

  padding: 4px 12px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  transition: all 0.3s linear;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "500")};
  width: ${({ width }) => (width ? width : "260px")};
  height: ${({ height }) => (height ? height : "48px")};
  background: ${({ variant }) =>
    variant === "outlined" || variant === "secondary" ? "#ffffff" : "#ff6633"};
  gap: ${({ gap }) => (gap ? gap : "4px")};
  border: ${({ border, variant }) =>
    border
      ? border
      : variant === "secondary"
      ? `1px solid #e3e3e3`
      : `1px solid ${variant === "outlined" ? "#c8c8c8" : "#ff6633"}`};
  letter-spacing: ${({ letterSpacing }) =>
    letterSpacing ? letterSpacing : "normal"};
  color: ${({ variant }) =>
    variant === "outlined" || variant === "secondary" ? "#222222" : "#ffffff"};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? borderRadius : "10px"};
  width: ${({ width }) => (width ? width : "100%")};

  ${({ disabled, shadowed }) =>
    !disabled && shadowed && "box-shadow: 0 2px 14px 0 rgba(166, 60, 24, 0.3)"};
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}`};
  ${({ disabled }) => disabled && "background: #f7f4f1 !important"};
  ${({ disabled }) => disabled && "color: #999999 !important"};
  ${({ disabled }) => disabled && "border: 2px solid #f7f4f1 !important"};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};

  &:hover {
    ${({ variant }) => variant === "secondary" && `border: 1px solid #222222`};
  }
`;
