import React, { memo, useEffect, useState } from "react";
import {
  AvatarBox,
  BetweenSpace,
  NavItem,
  NavsWrapper,
  UserData,
  UserDataPart,
  Wrapper,
} from "./styles";

import defaultAvatar from "../../assets/images/avatar.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { roles } from "../../constants/translations";
import { ReactComponent as LogOutIcon } from "../../assets/icons/logout_black_24dp.svg";

import { useLocation, useNavigate } from "react-router-dom";

import RatingBox from "../ratingBox";
import SideMenu from "../../UI/SideMenu";
import {
  CompanyMenu,
  CompanySecondMenu,
  CustomerMenu,
  CustomerSecondMenu,
  DashboardMenu,
  WorkerMenu,
  WorkerSecondMenu,
} from "./menu";
import { signOut } from "firebase/auth";
import { authentication } from "../../firebase/config";
import { clearOrders } from "../../redux/store/reducers/orderSlice";
import { authActions } from "../../redux/store/reducers/authSlice";

interface NavSidebarProps {
  menuOpen: boolean;
  toggleMenuOpen: () => void;
}

const NavSidebar: React.FC<NavSidebarProps> = ({
  menuOpen,
  toggleMenuOpen,
}) => {
  const user = useAppSelector((state) => state?.auth.user);

  const [firstMenu, setFirstMenu] = useState<
    {
      name: string;
      link: string;
      Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
    }[]
  >([]);

  const [secondMenu, setSecondMenu] = useState<
    {
      name: string;
      link: string;
      Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
    }[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const signUserOut = () => {
    if (user?.customer?.phoneNumber) {
      localStorage.removeItem("_grecaptcha");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("uid");

      signOut(authentication).then(() => {
        localStorage.clear();
      });
      dispatch(authActions.clearAuth());
      dispatch(clearOrders());
      toggleMenuOpen();
      navigate("/login");
    }
  };

  const renderNavigationList = (
    navigationArray: {
      name: string;
      link: string;
      Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
    }[],
  ) => {
    return navigationArray.map(({ name, link, Icon }, index) => {
      return (
        <NavItem
          key={`menuItem1_${index}`}
          active={pathname === link}
          onClick={() => {
            navigate(link);
            toggleMenuOpen();
          }}
        >
          <div className="name">{name}</div>
          <div className="icon">{Icon && <Icon />}</div>
        </NavItem>
      );
    });
  };

  useEffect(() => {
    if (user?.customer?.role === "worker") {
      setFirstMenu(WorkerMenu);
      setSecondMenu(WorkerSecondMenu);
    }
    if (user?.customer?.role === "customer") {
      if (user.customer.companyId) {
        setFirstMenu(CompanyMenu);
        setSecondMenu(CompanySecondMenu);
      } else {
        setFirstMenu(CustomerMenu);
        setSecondMenu(CustomerSecondMenu);
      }
    }
  }, [user?.customer?.role]);

  useEffect(() => {
    if (pathname.includes("dashboard")) {
      setSecondMenu(DashboardMenu);
    } else {
      if (user?.customer?.role === "worker") {
        setSecondMenu(WorkerSecondMenu);
      }
      if (user?.customer?.role === "customer") {
        if (user.customer.companyId) {
          setSecondMenu(CompanySecondMenu);
        } else {
          setSecondMenu(CustomerSecondMenu);
        }
      }
    }
  }, [pathname]);

  const MenuContent = () => {
    return (
      <Wrapper>
        <UserData>
          <UserDataPart>
            <div
              className="full-name"
              onClick={() => {
                toggleMenuOpen();
                navigate("/profile");
              }}
            >
              {`${user?.customer?.firstName ? user?.customer?.firstName : ""} ${
                user?.customer?.secondName ? user?.customer?.secondName : ""
              }`}
            </div>
            {user?.customer?.role && (
              <div className="role">{roles[user?.customer?.role]}</div>
            )}

            <div
              className="rating-part"
              onClick={() => {
                toggleMenuOpen();
                navigate("/profile/rating");
              }}
            >
              <RatingBox rating={user?.customer?.rating} />{" "}
              <span>{user?.customer?.rating}</span>
            </div>
          </UserDataPart>
          <AvatarBox>
            <img
              style={{ borderRadius: "50px" }}
              src={
                user?.customer?.avatarUrl
                  ? `data:image/jpeg;base64, ${user?.customer?.avatarUrl}`
                  : defaultAvatar
              }
              alt=""
            />
          </AvatarBox>
        </UserData>
        <NavsWrapper>{renderNavigationList(firstMenu)}</NavsWrapper>
        <BetweenSpace />
        <NavsWrapper>{renderNavigationList(secondMenu)}</NavsWrapper>
        <NavItem
          style={{ marginTop: "auto" }}
          active={false}
          onClick={signUserOut}
        >
          <div className="name">Выйти</div>
          <div className="icon">
            <LogOutIcon />
          </div>
        </NavItem>
      </Wrapper>
    );
  };

  return (
    <>
      <SideMenu
        menuOpen={menuOpen}
        toggleMenuOpen={toggleMenuOpen}
        children={<MenuContent />}
      />
    </>
  );
};

export default NavSidebar;
function useCallBack(
  arg0: (
    navigationArray: {
      name: string;
      link: string;
      Icon: React.FunctionComponent<
        React.SVGProps<SVGSVGElement> & { title?: string }
      >;
    }[],
  ) => JSX.Element[],
  arg1: unknown,
) {
  throw new Error("Function not implemented.");
}

