import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 24px;
  background: white;
  border-radius: 16px;
  transition: all 0.3s linear;

  @media screen and (max-width: 480px) {
    border-radius: 0;
  }

  .radarIcon {
    animation: rotation 8s linear infinite;
    border-radius: 50%;
  }

  @keyframes shadow {
    0% {
      box-shadow: 0px 0px 0px 0px;
    }
    50% {
      box-shadow: 0px 0px 5px 15px;
    }
    100% {
      box-shadow: 0px 0px 0px 0px;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .cardHeader {
    font-size: 20px;
    line-height: 1.2;
    color: #000;
  }

  .mapContainer {
    width: 100%;
    max-width: 935px;
    height: 415px;
    overflow: hidden;
    border-radius: 10px;
  }

  .subTitle {
    font-size: 12px;
    line-height: 1;
    color: #999999;
    margin-bottom: -12px;
  }

  .cardText {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      aspect-ratio: 1 / 1;
      width: 24px;
    }
  }

  .bottomText {
    font-size: 14px;
    color: #222222;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s linear;

    &:hover {
      color: #ff6633;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 24px 8px 0;

  @media screen and (max-width: 998px) {
    flex-direction: column;
    gap: 8px;
  }

  @media screen and (max-width: 480px) {
    padding: 0;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  &:nth-child(odd) {
    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  &:nth-child(even) {
    max-width: 984px;
    width: calc(1392px - 336px);
    padding-bottom: 16px;
  }

  .mobileNavigation {
    display: none;
  }

  @media screen and (max-width: 998px) {
    width: 100% !important;
    max-width: 100%;

    .mobileNavigation {
      display: flex;
    }
  }

  .stickyContainer {
    position: sticky;
    top: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
    height: calc(100vh - 89px);
    max-width: 392px;

    @media screen and (max-width: 998px) {
      height: unset;
    }

    .group {
      display: flex;
      flex-direction: column;
      gap: 16px;

      &:last-child {
        @media screen and (max-width: 998px) {
          display: none;
        }
      }
    }
  }

  .header {
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: -0.5px;
    color: #000;

    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  .selectedWorker {
    margin: 18px 0 16px 0;

    .profileInfo {
      display: flex;
      align-items: center;
      gap: 16px;

      .profilePhoto {
        width: 44px;
        height: 44px;
        border-radius: 50%;

        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        gap: 2px;

        .name {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: normal;
          color: #222222;
        }
        .role {
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: normal;
          color: #222222;
        }
      }
    }

    @media screen and (max-width: 998px) {
      display: none;
    }
  }

  .responseBlock {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;
    max-width: 100%;
    position: sticky;
    top: calc(334px + 16px);
    border-radius: 16px;

    .responseHeader {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.1px;
      color: #000;
    }

    .text {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
      margin-bottom: 16px;
    }

    .cancelOffer {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #757575;
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        color: #222222;
      }
    }

    @media screen and (max-width: 480px) {
      border-radius: 0;
    }
  }

  .aboutBlock {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    border-radius: 8px;
    background-color: #ffffff;
    max-width: 392px;
    position: sticky;
    top: calc(334px + 16px);

    .header {
      font-size: 32px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: -0.5px;
      color: #000;
    }

    .text {
      opacity: 0.7;
      font-family: "Google Sans";
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1a110f;
    }

    @media screen and (max-width: 998px) {
      display: none;
    }
  }
`;

export const ContactsBlock = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  background: white;
  align-items: center;
  padding: 16px;
  border-radius: 16px;

  .imageContainer {
    width: 48px;
    height: 48px;
    aspect-ratio: 1 / 1;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  svg {
    cursor: pointer;
  }

  .contactInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .contactTitle {
      font-size: 16px;
      line-height: 1;
      color: #222222;
    }

    .contactSubtitle {
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: -0.2px;
      color: #222222;
    }
  }

  .contactButtons {
    display: flex;
    gap: 8px;
    margin-left: auto;
  }
`;

export const Support = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #dddbda3d;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 16px;
  max-width: 384px;

  .supportHeader {
    font-size: 20px;
    line-height: 1.2;
    color: #000;
  }

  .callMeWidget {
    width: 239px !important;
    margin: 6px 0 6px 6px;
  }

  .text {
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
    margin-bottom: 8px;
  }
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;

  .row {
    display: flex;
    gap: 24px;
    align-items: center;
    font-size: 12px;
    line-height: 1;
    color: #757575;

    div {
      cursor: pointer;
    }
  }
`;

export const WaitingOffer = styled.div`
  display: flex;
`;

export const OrderPaidWrapper = styled.div`
  display: flex;
  gap: 4px;
  height: 32px;
  padding: 4px 8px;
  background-color: #dbf5e9;
  color: #21c17a;
  font-size: 15px;
  letter-spacing: -0.25px;
  align-items: center;
  border-radius: 10px;
`;

