import React, { memo, useEffect } from "react";
import { NavItem, Wrapper } from "./styles";
import CounterBadge from "../CounterBadge";

export interface NavigationItem {
  label: string;
  id: string;
  counterValue?: number | string;
  disabled?: boolean;
}

interface IPageNavigationProps {
  list: NavigationItem[];
  currentItem: string;
  setCurrentItem: (item: string) => void;
  children?: React.ReactNode | string;
}

const PageNavigation: React.FC<IPageNavigationProps> = ({
  list,
  currentItem,
  setCurrentItem,
  children,
}) => {
  const clickHandler = ({
    label,
    id,
    counterValue,
    disabled,
  }: NavigationItem) => {
    setCurrentItem(label);
    const item = document.getElementById(id);
    item &&
      window.requestAnimationFrame(() =>
        item.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        }),
      );
    // document.getElementById(id) &&
    //   window.scrollBy({
    //     top: document.getElementById(id)!.getBoundingClientRect().top - 46,
    //     behavior: "smooth",
    //   });

    const container = document.getElementById("navsContainer");
    const itemToScroll = document.getElementById(`${id}_button`);
    if (container && itemToScroll) {
      container.scrollLeft = itemToScroll.offsetLeft;
    }
  };

  useEffect(() => {
    window.parent.scrollTo(0, 0);
  }, []);

  const NavsList = () => {
    return list.map(({ id, label, counterValue, disabled }, index) => {
      return (
        <NavItem
          onClick={(e) => {
            e.stopPropagation();
            !disabled && clickHandler({ id, label, counterValue, disabled });
          }}
          active={currentItem === label}
          id={`${id}_button`}
          disabled={!!disabled}
          key={`navigationItem_${index}`}
        >
          {label}
          {!!counterValue && <CounterBadge value={counterValue} />}
        </NavItem>
      );
    });
  };

  return (
    <Wrapper id="navsContainer">
      {children && children}
      {NavsList()}
    </Wrapper>
  );
};

export default memo(PageNavigation);
