import React, { useEffect } from "react";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getVehiclesList } from "../../redux/store/reducers/vehicleSlice";
import { DropdownItem, DropdownWrapper, InputWrapper } from "./styles";

export const Form: React.FC = () => {
  return <div>Form</div>;
};

export const InputBox: React.FC<any> = ({
  value,
  label,
  type,
  children,
  prefix,
  required,
  placeholder,
  onChange,
  proposal,
  width,
  textareaRows,
  ...props
}) => {
  const user = useAppSelector((state) => state.auth.user);
  const vehicleList = useAppSelector((state) => state.vehicle.vehicleList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getVehiclesList(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  return (
    <InputWrapper small={proposal} width={width}>
      <label>
        {label} <span className="required">{required ? "*" : ""}</span>
      </label>
      <div>
        {prefix || ""}

        {children ? (
          children
        ) : type === "tel" ? (
          <NumberFormat
            // format={"+7 (###) ###-##-##"}
            // placeholder="+7"
            defaultValue={""}
            onChange={onChange}
            {...props}
          />
        ) : type === "b-day" ? (
          <NumberFormat
            format={"##/##/####"}
            placeholder="01/01/1970"
            onChange={onChange}
            {...props}
          />
        ) : type === "card-number" ? (
          <NumberFormat
            format={
              // "#### #### #### #### ####"
              "#### #### #### ####"
            }
            placeholder={
              // "0000 0000 0000 0000 0000"
              "0000 0000 0000 0000"
            }
            onChange={onChange}
            {...props}
          />
        ) : type === "inn" ? (
          <NumberFormat
            format={
              // "#### #### #### #### ####"
              "#### #### ####"
            }
            placeholder={
              // "0000 0000 0000 0000 0000"
              "0000 0000 0000"
            }
            onChange={onChange}
            {...props}
          />
        ) : type === "kpp" ? (
          <NumberFormat
            value={value || ""}
            format={
              // "#### #### #### #### ####"
              "#### ## ###"
            }
            placeholder={
              // "0000 0000 0000 0000 0000"
              "0000 00 000"
            }
            onChange={onChange}
            {...props}
          />
        ) : type === "bic-number" ? (
          <NumberFormat
            format={"#########"}
            placeholder="000000000"
            onChange={onChange}
            {...props}
          />
        ) : type === "account-number" ? (
          <NumberFormat
            format={"#### #### #### #### ####"}
            placeholder="0000 0000 0000 0000 0000"
            onChange={onChange}
            {...props}
          />
        ) : type === "expire-date" ? (
          <NumberFormat
            format={"##/##"}
            placeholder="ММ/ГГ"
            onChange={onChange}
            {...props}
          />
        ) : type === "cvc" ? (
          <NumberFormat
            onChange={onChange}
            format={"###"}
            placeholder=""
            {...props}
          />
        ) : type === "textarea" ? (
          <textarea
            placeholder={placeholder}
            rows={textareaRows}
            onChange={onChange}
            {...props}
          />
        ) : type === "vehicle" ? (
          <>
            <input
              placeholder="yesyyey sgbf"
              type="text"
              onFocus={() =>
                onChange({
                  target: {
                    name: "vehicle",
                    value: { ...value, open: true },
                  },
                })
              }
              onBlur={() =>
                onChange({
                  target: {
                    name: "vehicle",
                    value: { ...value, open: false },
                  },
                })
              }
              value={
                value?.data
                  ? `${value?.data?.model || ""} ${value?.data?.brand || ""}`
                  : ""
              }
              {...props}
            />
            <DropdownWrapper>
              {value.open &&
                vehicleList.map((v: any) => {
                  const {
                    id, //23054
                    model, //"Komatsu"
                    ownerId, //8569
                    vehicleId, //null
                    orderId, //null
                    categoryId, //271
                    brand, //"Volvo"
                    address, //null
                    comment, //"test"
                    createdAt, //"2023-02-22T12:41:31.000Z"
                    updatedAt, //"2023-02-22T12:41:31.000Z"
                    attachments, //null
                    stateNumber, //"3131223"
                    photoState, //null
                    rejectPhotoReason, //null
                    passportState, //null
                    rejectPassportReason, //null
                    defaultParameter, //null
                    height, //null
                    typeArrow, //null
                    typeChassis, //null
                    loadCapacity, //null
                    loadCapacitySide, //null
                    loadCapacityArrow, //null
                    arrowReach, //null
                    numberAxles, //null
                    volume, //null
                    equipments, //null
                    length, //null
                    productionYear, //null
                    weight, //null
                    width,
                  } = v;
                  return (
                    <DropdownItem
                      onMouseDown={() =>
                        onChange({
                          target: {
                            name: "vehicle",
                            value: {
                              data: v,
                              open: false,
                            },
                          },
                        })
                      }
                    >{`${model || ""} ${brand || ""}`}</DropdownItem>
                  );
                })}
            </DropdownWrapper>
          </>
        ) : (
          <input
            type="text"
            onChange={onChange}
            value={value || ""}
            placeholder={placeholder}
            {...props}
            style={width ? { width: width } : {}}
          />
        )}
      </div>
    </InputWrapper>
  );
};
