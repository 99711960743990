import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import NumberFormat from "react-number-format";
import { CityList } from "../profilePage/styles";
import { InputWrapper, SelectWrapper } from "./styles";
import cities from "../../constants/city.json";
import countries from "../../constants/world.json";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import PhoneInput from "react-phone-number-input/input";

export const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  formData,
  labelSize,
  disabled,
  orgPage,
  onBlur,
  error,
  lettersOnly,
}: any) => {
  const city = formData?.city;
  const country = formData?.country;
  const lettersRegex = new RegExp("^[а-яА-Я]+$");
  const bikRegex = /^[0-9]{3}\s[0-9]{3}\s[0-9]{3}$/i;
  const rsRegex =
    /^[0-9]{3}\s[0-9]{2}\s[0-9]{3}\s[0-9]{1}\s[0-9]{4}\s[0-9]{7}$/i;
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const dateFormatList = ["DD.MM.YYYY", "DD/MM/YYYY"];
  const setCity = (value: any) => {
    onChange({ target: { name: "city", value } });
  };
  const setCountry = (value: any) => {
    onChange({ target: { name: "country", value } });
  };
  const [options, setOptions] = useState<any>(
    cities.filter(({ country_id }) => country_id === country?.data?.country_id),
  );
  const [countryOptions, setCountryOptions] = useState<any>(
    countries.filter(({ country_id }) => country_id),
  );

  const onChangeCity = (e: any) => {
    const c_id = country?.data?.country_id;

    setCity({ text: e.target.value, open: true });

    const exist = cities.find(
      ({ name, country_id }) =>
        country_id === c_id &&
        name.toLowerCase() === e.target.value.toLowerCase(),
    );

    if (exist) {
      setCity({ text: exist.name, open: false });
    } else {
      setOptions(
        cities.filter(
          ({ name, country_id }: any) =>
            country_id === c_id &&
            (e.target.value
              ? name.toLowerCase().startsWith(e.target.value.toLowerCase())
              : true),
        ),
      );
    }
  };

  const onChangeCountry = (e: any) => {
    setCountry({ text: e.target.value, open: true });
    const exist = countries.find(
      ({ name }) => name.toLowerCase() === e.target.value.toLowerCase(),
    );

    if (exist) {
      setCountry({ text: exist.name, open: false, data: exist });
    } else {
      setCountryOptions(
        countries.filter(
          ({ name, country_id }: any) =>
            country_id &&
            (e.target.value
              ? name.toLowerCase().startsWith(e.target.value.toLowerCase())
              : true),
        ),
      );
      setCity({ text: "", open: false });
    }
  };

  const onSelect = (e: any) => {
    setCity({ text: e, open: false });
  };

  const onSelectCountry = (e: any) => {
    setCountry({ text: e.name, open: false, data: e });
  };

  useEffect(() => {
    const c_id = country?.data?.country_id;
    if (c_id) {
      setOptions(cities.filter(({ country_id }) => country_id === c_id));
    }
  }, [country?.data?.country_id]);

  return (
    <InputWrapper
      active={
        type === "country" || type === "city"
          ? value?.text?.length
          : value?.length
      }
      labelSize={labelSize ? labelSize : "16px"}
      style={{ width: width ? width : "100%" }}
      error={
        (name === "email" && value && !emailRegEx.test(value)) ||
        (name === "bik" && value && !bikRegex.test(value)) ||
        (name === "rs" && value && !rsRegex.test(value)) ||
        error
      }
      className={
        (type === "country" || type === "city") && value?.text?.length
          ? "active"
          : value?.length
          ? "active"
          : ""
      }
      emailError={
        (type === "email" && value && !emailRegEx.test(value)) ||
        (name === "bik" && value && !bikRegex.test(value)) ||
        (name === "rs" && value && !rsRegex.test(value))
      }
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
          {/*<select name="">*/}
          {/*  <option value="manager">Менеджер</option>*/}
          {/*  <option value="manager">Менеджер</option>*/}
          {/*</select>*/}
        </SelectWrapper>
      ) : (
        <>
          {/* <span className="label">
            <span className="text">
              {label}
              {required ? "*" : ""}
            </span>
          </span> */}
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              // mask={"### ### ####"}
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={`${label}${required ? "*" : ""}`}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
            />
          ) : type === "bik" ? (
            <NumberFormat
              format="### ### ###"
              // mask={"### ### ####"}
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={`${label}${required ? "*" : ""}`}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
              title={
                !bikRegex.test(value) ? "БИК должен состоять из 9 цифр" : ""
              }
            />
          ) : type === "rs" ? (
            <NumberFormat
              format="### ## ### # #### #######"
              // mask={"### ### ####"}
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={`${label}${required ? "*" : ""}`}
              onChange={onChange}
              disabled={disabled}
              spellCheck={false}
              title={
                !rsRegex.test(value)
                  ? "Расчетный счет должен состоять из 20 цифр"
                  : ""
              }
            />
          ) : type === "tel" ? (
            value ? (
              <PhoneInput
                name={name}
                value={value || ""}
                // type={type || "text"}
                className="phone-number-input"
                prefix={"+"}
                placeholder={`${label}${required ? "*" : ""}`}
                onChange={(e: any) => {
                  onChange({ target: { value: e, name } });
                }}
                // autoFocus={!orgPage}
                autoFocus={!orgPage || value?.length === 2}
                disabled={disabled}
                spellCheck={false}
                onBlur={() => onBlur && onBlur(name)}
              />
            ) : (
              // <NumberFormat
              //   // format="### ### ######"
              //   name={name}
              //   value={value || ""}
              //   // type={type || "text"}
              //   prefix={"+"}
              //   placeholder={`${label}${required?"*":""}`}
              //   onChange={onChange}
              //   autoFocus={!orgPage}
              //   disabled={disabled}
              // />
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={`${label}${required ? "*" : ""}`}
                onChange={onChange}
                disabled={disabled}
                spellCheck={false}
                onBlur={() => onBlur && onBlur(name)}
              />
            )
          ) : type === "date" ? (
            <div className="date-input">
              <DatePicker
                //format={"ll"}
                value={value ? moment(value) : null}
                onChange={(e, date) => {
                  onChange({
                    target: {
                      value: e?.format("YYYY-MM-DD"),
                      name,
                    },
                  });
                }}
                disabled={disabled}
                format={dateFormatList}
                onBlur={() => onBlur && onBlur(name)}
              />
            </div>
          ) : type === "country" ? (
            <div
              tabIndex={0}
              style={{ border: 0, width: "100%" }}
              onBlur={async () => {
                await setCountry({ ...value, open: false });
                onBlur && onBlur("country");
              }}
              onFocus={() => {
                setCountry({ ...country, open: true });
                setCountryOptions(
                  countries.filter(
                    ({ name, country_id }: any) =>
                      country_id &&
                      (country?.text ? name.includes(country?.text) : true),
                  ),
                );
              }}
            >
              <input
                className="name"
                type="text"
                // placeholder="Россия"
                value={country?.text || ""}
                onChange={onChangeCountry}
                // ref={cityRef}
                onClick={() => {
                  if (!country?.open) {
                    setCountry({ ...country, open: true });
                    onBlur && onBlur("country");
                    setCountryOptions(
                      countries.filter(
                        ({ name, country_id }: any) =>
                          country_id &&
                          (country?.text
                            ? name
                                .toLowerCase()
                                .includes(country?.text.toLowerCase())
                            : true),
                      ),
                    );
                  }
                }}
                disabled={disabled}
                spellCheck={false}
                placeholder={`${label}${required ? "*" : ""}`}
              />
              {value?.open && countryOptions.length > 0 && (
                <CityList>
                  {countryOptions.length > 0 &&
                    countryOptions
                      // .slice(0, 5)
                      .map(
                        (
                          {
                            // coords,
                            // district,
                            // name,
                            // population,
                            // subject,

                            id,
                            alpha2,
                            alpha3,
                            name,
                            country_id,
                          }: any,
                          index: any,
                        ) => (
                          <div
                            key={`city${index}`}
                            className="item"
                            onMouseDown={(e) => {
                              onSelectCountry({
                                id,
                                alpha2,
                                alpha3,
                                name,
                                country_id,
                              });
                            }}
                          >
                            <b>{name}</b>
                            {/* {district} */}
                          </div>
                        ),
                      )}
                </CityList>
              )}
            </div>
          ) : type === "city" ? (
            <div tabIndex={0} style={{ border: 0, width: "100%" }}>
              <input
                className="name"
                type="text"
                // placeholder="Москва"
                value={city?.text || ""}
                onChange={onChangeCity}
                // ref={cityRef}
                disabled={!country?.data?.country_id}
                onClick={() => {
                  if (!city?.open) {
                    setCity({ ...city, open: true });
                    onBlur && onBlur("city");
                    setOptions(
                      // russianCities.filter(({ name }: any) =>
                      cities.filter(
                        ({ name, country_id }: any) =>
                          country_id === country?.data?.country_id &&
                          (city?.text
                            ? name
                                .toLowerCase()
                                .includes(city?.text.toLowerCase())
                            : true),
                      ),
                    );
                  }
                }}
                onBlur={async () => {
                  await setCity({ ...city, open: false });
                  onBlur && onBlur("city");
                }}
                onFocus={() => {
                  setCity({ ...city, open: true });
                  setOptions(
                    // russianCities.filter(({ name }: any) =>
                    cities.filter(
                      ({ name, country_id }: any) =>
                        // country_id === country?.data?.country_id &&
                        // (city?.text ? name.includes(city?.text) : true)
                        country_id === country?.data?.country_id &&
                        (city?.text
                          ? name
                              .toLowerCase()
                              .includes(city?.text.toLowerCase())
                          : true),
                    ),
                  );
                }}
                spellCheck={false}
                placeholder={`${label}${required ? "*" : ""}`}
              />
              {city?.open && options.length > 0 && (
                <CityList>
                  {/* {options.length === 0 && (
                  <div className="not-found">NOT FOUND</div>
                )} */}
                  {options.length > 0 &&
                    options.map(
                      (
                        {
                          coords,
                          district,
                          name,
                          population,
                          subject,
                          region,
                          country,
                        }: any,
                        index: any,
                      ) => (
                        <div
                          key={`city${index}`}
                          className="item"
                          onMouseDown={(e) => {
                            onSelect(name);
                          }}
                        >
                          <b>{name},</b>
                          {region}
                          {/* , {country} */}
                        </div>
                      ),
                    )}
                </CityList>
              )}
            </div>
          ) : type === "email" ? (
            <>
              <input
                name={name}
                value={value || ""}
                type={type || "text"}
                placeholder={`${label}${required ? "*" : ""}`}
                onChange={onChange}
                onBlur={() => onBlur && onBlur(name)}
              />
            </>
          ) : (
            // <input
            //   name={name}
            //   value={value || ""}
            //   type={type || "text"}
            //   placeholder={`${label}${required?"*":""}`}
            //   onChange={onChange}
            // />
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={`${label}${required ? "*" : ""}`}
              onChange={(e) => {
                if (lettersOnly && e.target.value !== "") {
                  lettersRegex.test(e.target.value) && onChange(e);
                } else {
                  onChange(e);
                }
              }}
              spellCheck={false}
              onBlur={() => onBlur && onBlur(name)}
              disabled={disabled}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};
