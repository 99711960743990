import React from "react";
import { PopUpContainer, PopUpWrapper } from "./styles";
import { ReactComponent as CloseIcon } from "../../../assets/icons/icons-close.svg";

const UserAgreementPopUp: React.FC<{ close: any }> = ({ close }) => {
  return (
    <PopUpContainer onClick={close} style={{ zIndex: "1001" }}>
      <PopUpWrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <CloseIcon onClick={close} />
        <iframe src="https://docs.google.com/document/d/e/2PACX-1vRmZgbRYTPZ_G682axGo6jNiut5w0_d39t94nnaQPscEV-2EBi9IU8eZlShpJ1m15MHQEZ5zude6sko/pub?embedded=true"></iframe>
      </PopUpWrapper>
    </PopUpContainer>
  );
};

export default UserAgreementPopUp;
