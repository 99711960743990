import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background: #ffffff;
  border-radius: 16px;
  min-width: 320px;
  height: fit-content;
  position: sticky;
  top: 0px;
  max-width: 392px;

  @media screen and (max-width: 998px) {
    flex-direction: row;
    background: #f7f4f1;
    height: 46px;
    width: 100%;
    max-width: unset;
    overflow-x: auto;
    padding: 5px 0;
    border-radius: 0;
    gap: 16px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
    height: unset;
  }
`;

export const NavItem = styled.div<{ active: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  border-radius: 8px;
  padding: 8px;
  color: #222222;
  background: ${({ active }) => (active ? "#ffe6de" : "#ffffff")};
  ${({ disabled }) => disabled && "opacity: 0.5"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  gap: 8px;
  transition: all 0.3s linear;
  white-space: nowrap;

  @media screen and (max-width: 768px) {
    background: ${({ active }) => (active ? "#ff6633" : "none")};
    color: ${({ active }) => (active ? "#ffffff" : "#222222")};
    height: 36px;
    white-space: nowrap;
  }
`;
