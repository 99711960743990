import React, { memo, useRef, useState } from "react";
import { Wrapper } from "./styles";
import Countries from "../../constants/world.json";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-24-basic-expand-more.svg";

interface CountryPickerProps {
  onChange: (country: string) => void;
  currentCountry: string;
}

const CountryPicker: React.FC<CountryPickerProps> = ({
  onChange,
  currentCountry,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const buttonRef = useRef(null);

  const getCountry = () => {
    const result = Countries.find(
      (country) => country.alpha2 === currentCountry.toLowerCase(),
    );

    return (
      <>
        <img
          className="flag countryIcon"
          width={"18px"}
          height={"12px"}
          src={require(`../../assets/icons/countries/${result?.img}`)}
          alt=""
        />
        {result?.dial_code}
      </>
    );
  };

  const renderCountries = () => {
    return Countries?.map(({ dial_code, name, img, alpha2 }, index) => {
      return (
        <div
          onClick={(e) => {
            e.stopPropagation();
            onChange(alpha2.toUpperCase());
            setOpen(false);
            //@ts-ignore
            buttonRef?.current?.blur();
          }}
          className={`dropdownItem${
            currentCountry.toLowerCase() === alpha2 ? " active" : ""
          }`}
          key={`country_${index}`}
        >
          <div className="code">
            <img
              className="countryIcon"
              width={"18px"}
              height={"12px"}
              src={require(`../../assets/icons/countries/${img}`)}
              alt=""
            />
            {dial_code}
          </div>
          {name}
        </div>
      );
    });
  };

  return (
    <Wrapper
      ref={buttonRef}
      open={open}
      onFocus={() => setOpen(true)}
      onBlur={() => setOpen(false)}
    >
      <div className="value">
        {getCountry()}
        <ArrowIcon />
      </div>

      <div className="dropdown">{renderCountries()}</div>
    </Wrapper>
  );
};

export default memo(CountryPicker);
