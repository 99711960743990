import moment, { Moment } from "moment";
import React, { memo, useEffect, useState } from "react";
import {
  AddVehicleBlock,
  BodyWrapper,
  CommissionWrapper,
  ItemWrapper,
  PopUpBackground,
  PopUpContainer,
  PopUpWrapper,
  Row,
  WrapperBottom,
} from "./styles";

import "moment/locale/ru";
import { ReactComponent as CloseIcon } from "../../../assets/icons/24-basic-circle-x.svg";
import { InputBox } from "../../form";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store/store";
import {
  fetchAddVehicles,
  getVehicleCategories,
  getVehiclesList,
} from "../../../redux/store/reducers/vehicleSlice";
import Select from "../../../UI/FilterSelect";
import Button from "../../../UI/Button";
import Header from "../../../UI/Header";
import Input from "../../../UI/Input";
import { ENDPOINT_FOR_IMG } from "../../../constants/general";
import { DatePicker } from "antd";
import TimePicker from "../../timePicker";
import { checkIsToday } from "../../../utils/checkIsToday";

interface Props {
  onSubmit?: any;
  close?: any;
  role?: "customer" | "worker";
  setAnyTimeHandler?: (x: boolean) => void;
  orderCategoryId?: number;
  orderPaymentType?: string;
  counterOffer?: boolean;
  handleCounterOffer?: (counterOffer: {
    budget: string;
    description: string;
    vehicleId: number | null;
    paymentType: string;
    startDate: string;
    endDate: string | null;
  }) => void;
}

interface FormData {
  budget: string;
  description: string;
  vehicleId: number | null;
  paymentType: string | null;
}

interface VehiclesList {
  label: string;
  value: number;
  additional?: {
    stateNumber: string;
    Icon?: any;
  };
}

const PaymentTypesList = [
  {
    label: "Безналичный",
    value: "bn",
  },
  {
    label: "Безналичный с НДС",
    value: "bnnds",
  },
];

const getTimeDisabledBefore = (startDate: Moment) => {
  return startDate.minutes() > 30
    ? Number(`${startDate.hours()}`)
    : Number(`${startDate.hours()}.5`);
};

const ProposalPopUp: React.FC<Props> = ({
  onSubmit,
  close,
  orderCategoryId = null,
  orderPaymentType,
  counterOffer = false,
  handleCounterOffer,
}) => {
  const [startDate, setStartDate] = useState<Moment | null>(moment());
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [vehiclesListForSelect, setVehiclesListForSelect] = useState<
    VehiclesList[]
  >([]);
  const [categoriesListForSelect, setCategoriesListForSelect] = useState<
    VehiclesList[]
  >([]);
  const [addVehicleForm, setAddVehicleForm] = useState<{
    categoryId: number | null;
    brand: string;
    model: string;
    stateNumber: string;
    comment: string;
    characteristics: Array<any>;
  }>({
    categoryId: orderCategoryId,
    brand: "",
    model: "",
    stateNumber: "",
    comment: "",
    characteristics: [],
  });
  const [addVehicleOpen, setAddVehicleOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    budget: "",
    description: "",
    vehicleId: null,
    paymentType: orderPaymentType ? orderPaymentType : null,
  });
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { loadingRespond } = useAppSelector((state: RootState) => state.order);
  const { vehicleList, vehicleCategories, newVehicleId } = useAppSelector(
    (state: RootState) => state.vehicle,
  );

  const ScreenWidth = window.screen.width;

  const dispatch = useAppDispatch();

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const addVehicleFormChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { name, value } = e.target;
    setAddVehicleForm((prevState) => ({
      ...prevState,
      [name]: name === "stateNumber" ? value.toUpperCase() : value,
    }));
  };

  const addVehicleHandler = async () => {
    if (
      user?.customer?.refId &&
      addVehicleForm.brand !== "" &&
      addVehicleForm.model !== "" &&
      addVehicleForm.stateNumber !== "" &&
      addVehicleForm.categoryId !== null
    ) {
      await dispatch(
        fetchAddVehicles({
          customerId: user?.customer?.refId,
          //@ts-ignore
          data: { ...addVehicleForm! },
        }),
      );
      dispatch(getVehiclesList(user?.customer?.refId));
      setAddVehicleOpen(false);
      setAddVehicleForm({
        categoryId: 0,
        brand: "",
        model: "",
        stateNumber: "",
        comment: "",
        characteristics: [],
      });
    }
  };

  const getVehiclesListForSelect = () => {
    const list: VehiclesList[] = [];
    if (vehicleList && vehicleList?.length) {
      vehicleList.map((vehicle: any) => {
        const iconPath = vehicleCategories.find(
          ({ id, icon }: { id: any; icon: any }) =>
            id === vehicle.categoryId && icon,
        )?.icon;
        const title = vehicleCategories.find(
          ({ id }: { id: any; icon: any }) => id === vehicle.categoryId,
        )?.title;
        if (
          (!counterOffer && vehicle.categoryId === orderCategoryId) ||
          counterOffer
        ) {
          list.push({
            label: `${title} ${vehicle.brand} ${vehicle.model}`,
            value: vehicle.id,
            additional: {
              stateNumber: vehicle.stateNumber.toUpperCase(),
              Icon: (
                <img
                  src={`${ENDPOINT_FOR_IMG}/${
                    iconPath ? iconPath : "assets/images/excavator.svg"
                  }`}
                  alt=""
                  width={"24px"}
                  height={"24px"}
                />
              ),
            },
          });
        }
      });
    }
    setVehiclesListForSelect([...list]);
  };

  const getCategoriesListForSelect = () => {
    const list: VehiclesList[] = [];
    if (vehicleCategories && vehicleCategories?.length) {
      vehicleCategories.map((category: any) => {
        list.push({
          label: category.title,
          value: category.id,
        });
      });
    }
    setCategoriesListForSelect([...list]);
  };

  useEffect(() => {
    if (newVehicleId) {
      setFormData({ ...formData, vehicleId: newVehicleId });
    }
  }, [newVehicleId, getVehiclesList]);

  useEffect(() => {
    user?.customer?.refId && dispatch(getVehiclesList(user?.customer?.refId));
    dispatch(getVehicleCategories());
  }, [user?.customer, fetchAddVehicles]);

  useEffect(() => {
    if (vehicleList?.length) {
      getVehiclesListForSelect();
    }
  }, [vehicleList]);

  useEffect(() => {
    if (vehicleCategories?.length) {
      getCategoriesListForSelect();
    }
  }, [vehicleCategories]);

  return (
    <>
      <style>
        {`html {
        overflow: hidden;
      }`}
      </style>
      <PopUpContainer>
        <PopUpBackground onClick={close} />
        <PopUpWrapper>
          <BodyWrapper>
            <Header
              fontSize="24px"
              fontWeight="400"
              lineHeight="24px"
              onClick={close}
              text={
                counterOffer ? "Встречное предложение" : "Откликнуться на заказ"
              }
              Icon={CloseIcon}
              padding="0 24px"
            />
            {user?.customer.role === "worker" && (
              <>
                <ItemWrapper>
                  <Select
                    list={vehiclesListForSelect}
                    value={
                      formData.vehicleId
                        ? vehiclesListForSelect.find(
                            (vehicle) => vehicle.value === formData.vehicleId,
                          )?.label
                        : undefined
                    }
                    name="vehicleId"
                    placeholder="Выбрать технику"
                    vehicle
                    onChange={({ name, value }) =>
                      setFormData((prevState) => ({
                        ...prevState,
                        [name]: value,
                      }))
                    }
                  />
                </ItemWrapper>
                <AddVehicleBlock isOpen={addVehicleOpen}>
                  <div
                    className="title"
                    onClick={() => !addVehicleOpen && setAddVehicleOpen(true)}
                  >
                    Добавить технику
                  </div>

                  {addVehicleOpen && (
                    <div className="inputsContainer">
                      <div
                        style={{
                          width: ScreenWidth > 480 ? "269px" : "100%",
                        }}
                      >
                        <Select
                          list={categoriesListForSelect.filter((item) => {
                            return orderCategoryId
                              ? item.value === orderCategoryId
                              : item;
                          })}
                          name="categoryId"
                          placeholder="Выбрать категорию"
                          vehicle
                          value={
                            addVehicleForm.categoryId
                              ? categoriesListForSelect.find(
                                  (vehicle) =>
                                    vehicle.value === addVehicleForm.categoryId,
                                )?.label
                              : undefined
                          }
                          onChange={({ name, value }) =>
                            setAddVehicleForm((prevState) => ({
                              ...prevState,
                              [name]: value,
                            }))
                          }
                        />
                      </div>

                      <Input
                        placeholder={"Марка"}
                        width={ScreenWidth > 480 ? "269px" : "100%"}
                        name="brand"
                        disabled={loadingRespond === "pending"}
                        onChange={addVehicleFormChangeHandler}
                        value={addVehicleForm.brand}
                      />
                      <Input
                        placeholder={"Модель"}
                        width={ScreenWidth > 480 ? "269px" : "100%"}
                        name="model"
                        disabled={loadingRespond === "pending"}
                        onChange={addVehicleFormChangeHandler}
                        value={addVehicleForm.model}
                      />
                      <Input
                        width={ScreenWidth > 480 ? "269px" : "100%"}
                        placeholder={"Госномер"}
                        name="stateNumber"
                        disabled={loadingRespond === "pending"}
                        onChange={addVehicleFormChangeHandler}
                        value={addVehicleForm.stateNumber}
                      />
                    </div>
                  )}

                  <div className="buttonsWrapper">
                    <div
                      className="cancelButton"
                      onClick={() => setAddVehicleOpen(false)}
                    >
                      Отменить
                    </div>
                    <Button
                      onClick={() => addVehicleHandler()}
                      height="32px"
                      width="fit-content"
                      fontSize="15px"
                      fontWeight="400"
                      letterSpacing="-0.25px"
                      disabled={
                        addVehicleForm.brand === "" ||
                        addVehicleForm.model === "" ||
                        addVehicleForm.stateNumber === "" ||
                        addVehicleForm.categoryId === 0
                      }
                    >
                      Добавить технику
                    </Button>
                  </div>
                </AddVehicleBlock>
              </>
            )}

            <ItemWrapper>
              <Row>
                <DatePicker
                  className="datePicker"
                  format={"D MMMM"}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(moment(date));
                    } else setStartDate(null);
                  }}
                  placeholder="Дата начала"
                  disabledDate={(current) => current.isBefore(moment(), "day")}
                  defaultValue={moment()}
                />
                <TimePicker
                  time={startDate}
                  setTime={setStartDate}
                  date={startDate}
                  setDate={setStartDate}
                  disableBefore={
                    startDate
                      ? checkIsToday(startDate)
                        ? getTimeDisabledBefore(startDate)
                        : undefined
                      : undefined
                  }
                />
              </Row>
            </ItemWrapper>
            <ItemWrapper>
              <Row>
                <DatePicker
                  className="datePicker"
                  format={"D MMMM"}
                  onChange={(date) => {
                    if (date) {
                      setEndDate(moment(date));
                    } else {
                      setEndDate(null);
                    }
                  }}
                  placeholder="Дата окончания"
                  disabledDate={(current) =>
                    current.isBefore(moment(startDate), "day")
                  }
                />
                <TimePicker
                  time={endDate}
                  setTime={setEndDate}
                  date={endDate}
                  setDate={setEndDate}
                  disableBefore={
                    startDate
                      ? startDate.isSame(endDate, "day")
                        ? getTimeDisabledBefore(startDate)
                        : undefined
                      : undefined
                  }
                />
              </Row>
            </ItemWrapper>
            <ItemWrapper>
              <Row>
                <Input
                  placeholder="Готов выполнить за"
                  autoComplete="off"
                  name="budget"
                  height="48px"
                  disabled={loadingRespond === "pending"}
                  width={"100% !important"}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (Number.isInteger(Number(e.target.value))) {
                      onChangeHandler(e);
                    }
                  }}
                  value={
                    (formData &&
                      formData.hasOwnProperty("budget") &&
                      formData?.budget) ||
                    ""
                  }
                />
                <Select
                  list={PaymentTypesList}
                  value={
                    formData.paymentType
                      ? PaymentTypesList.find(
                          (type) => type.value === formData.paymentType,
                        )?.label
                      : undefined
                  }
                  name="paymentType"
                  placeholder="Способ оплаты"
                  vehicle
                  onChange={({ name, value }) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                />
              </Row>
            </ItemWrapper>
            <ItemWrapper>
              <CommissionWrapper>
                <div className="commission-desc">
                  Включая комиссию сервиса 9%
                </div>
                <div className="commission-sum">
                  {formData.budget.length &&
                    Math.floor(Number(formData.budget) * 0.09)}{" "}
                  &#8381;
                </div>
              </CommissionWrapper>
            </ItemWrapper>
            {!counterOffer && (
              <ItemWrapper>
                <div className="label">Комментарий</div>
                <InputBox
                  proposal
                  type="textarea"
                  textareaRows={
                    addVehicleOpen ? (ScreenWidth > 480 ? 3 : 2) : 3
                  }
                  name="description"
                  disabled={loadingRespond === "pending"}
                  onChange={onChangeHandler}
                  value={
                    (formData &&
                      formData.hasOwnProperty("description") &&
                      formData.description) ||
                    ""
                  }
                />
              </ItemWrapper>
            )}
            <WrapperBottom>
              <Button
                onClick={() => {
                  if (
                    formData.budget !== "" &&
                    formData.paymentType !== null &&
                    ((!counterOffer && formData.vehicleId !== null) ||
                      counterOffer)
                  ) {
                    if (!counterOffer) {
                      onSubmit({
                        ...formData,
                        startDate: moment(startDate).toISOString(),
                        endDate: endDate ? endDate.toISOString() : null,
                        budget: Number(formData.budget),
                      });
                      close();
                    } else {
                      handleCounterOffer &&
                        //@ts-ignore
                        handleCounterOffer({
                          ...formData,
                          startDate: moment(startDate).toISOString(),
                          endDate: endDate ? endDate.toISOString() : null,
                        });
                      close();
                    }
                  }
                }}
                height="48px"
                width="100%"
                fontSize="16px"
                fontWeight="500"
                disabled={
                  formData.budget === "" ||
                  formData.paymentType === null ||
                  (user?.customer.role === "worker" &&
                    formData.vehicleId === null)
                }
              >
                {counterOffer
                  ? "Сделать встречное предложение"
                  : "Откликнуться"}
              </Button>
            </WrapperBottom>
          </BodyWrapper>
        </PopUpWrapper>
      </PopUpContainer>
    </>
  );
};

export default ProposalPopUp;

