import styled from "styled-components";
import { Pagination } from "antd";

export const CommonPagination = styled(Pagination)`
  justify-content: flex-start;
  a {
    font-size: 15px;
    line-height: 48px;
    color: #000;
  }
  a:hover {
    color: #ff6633;
  }
  .ant-pagination-item {
    border: none;
    border-radius: 10px;
    background: #f7f4f1;
  }
  .ant-pagination-item:hover {
    border: 1px solid #ff6633;
    border-color: #ff6633;
    color: #ff6633;
    transition: 0.1s;
  }
  .ant-pagination-item-link {
    border: none;
    background: #f7f4f1;
    line-height: 50px;
    margin-bottom: 0;
    border-radius: 10px;
  }
  .ant-pagination-item-link:hover {
    color: #ff6633;
  }
  li {
    width: 48px;
    height: 48px;
  }
  .ant-pagination-total-text {
    font-size: 12px;
    margin-right: 70px;
    background: inherit;
    width: auto;
    line-height: 48px;
  }
  .ant-pagination-item-active {
    border-color: #f63;
    background: rgba(255, 102, 51, 0.08);
  }
  .ant-pagination-options {
    display: none;
  }
  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: #ff6633;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  max-width: 974px;
  width: 100%;
  justify-content: space-between;
  padding: 32px 20px 0 20px;

  .titleText {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.1px;
    color: #000;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  div {
    position: inherit;
  }
`;

export const Wrapper = styled.div<{ my?: boolean }>`
  width: 100%;
  max-width: 984px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  gap: 16px;

  @media screen and (max-width: 998px) {
    padding-bottom: 24px;
  }
`;

export const CreateOrderFormBox = styled.div`
  width: 100%;
  height: 100%;
  //background-color: white;
  padding: 0;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  button {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 19px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
  }

  @media only screen and (max-width: 1250px) {
    /* width: 90%; */
  }

  @media only screen and (max-width: 576px) {
    width: 100%;
    /* margin-bottom: 50px; */
    //margin-top: 60px;
  }
  @media only screen and (max-width: 400px) {
    height: 100%;
    width: 100%;
  }
`;

export const OrdersList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;

  .filterMobileContainer {
    display: none;
    width: 100%;
  }

  @media screen and (max-width: 480px) {
    .filterMobileContainer {
      display: flex;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1255px) {
    width: 100%;
  }

  @media only screen and (max-width: 998px) {
    padding: 0 8px;
  }

  @media only screen and (max-width: 576px) {
    gap: 8px;
  }
`;
