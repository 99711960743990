import React, { memo, useEffect, useRef, useState } from "react";
import {
  DatasetBox,
  DatasetList,
  InputBox,
  InputDatasetWrapper,
} from "./styles";
import { ReactComponent as Back } from "../../assets/icons/Back.svg";
import { ReactComponent as Close } from "../../assets/icons/Close.svg";
import { useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { DebounceInput } from "react-debounce-input";
import { getAddressesString } from "../../utils/getAddressString";

interface Props {
  setValue: any;
  close: any;
  list: any;
  search?: any;
  setLocation?: any;
  category?: any;
  defaultValue?: any;
  onChange?: any;
  tab?: any;
  onFocus?: any;
  onClick?: any;
  type?: "vehicle" | "city";
  setVehicleInputIcon?: any;
  isAddress?: boolean;
  wrapperClass?: string;
}

const InputDataset: React.FC<Props> = ({
  setValue,
  close,
  list,
  search,
  category,
  defaultValue,
  onChange,
  tab,
  type,
  setVehicleInputIcon,
  isAddress,
  wrapperClass,
}) => {
  const searchByTagResult = useAppSelector(
    (state: RootState) => state.searchByTag,
  );

  const [searchInput, setSearchInput] = useState<string>("");
  const ref = useRef<any>();

  useEffect(() => {
    if (defaultValue) {
      setSearchInput(defaultValue);
    }
  }, []);

  return (
    <InputDatasetWrapper>
      <InputBox listOpen={!!list?.length}>
        <div className="go-back" onClick={close}>
          <Back />
        </div>
        {isAddress ? (
          <DebounceInput
            debounceTimeout={500}
            element={"input"}
            type="text"
            ref={ref}
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchInput(e.target.value);
              onChange && onChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // e.preventDefault();
                setValue({ data: { title: searchInput } });
              } else if (e.key === "Tab") {
                e.preventDefault();
                tab?.();
              }
            }}
            autoFocus
          />
        ) : (
          <input
            type="text"
            ref={ref}
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchInput(e.target.value);
              onChange && onChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setValue({ data: { title: searchInput } });
              } else if (e.key === "Tab") {
                e.preventDefault();
                tab?.();
              }
            }}
            autoFocus
          />
        )}
        <div
          className="close"
          onClick={() => {
            if (searchInput) {
              setSearchInput("");
            } else {
              close();
            }
          }}
        >
          <Close />
        </div>
      </InputBox>
      <DatasetList wrapperClass={wrapperClass}>
        {!searchByTagResult?.data?.length && type === "vehicle" && list ? (
          <div className={"toplist-header"}>Популярные запросы:</div>
        ) : null}
        {!!list?.length &&
          list?.map((item: any, index: number) => {
            if (!isAddress) {
              const { description, name, img } = item;
              return (
                <DatasetBox
                  key={index}
                  onClick={() => {
                    setValue(item);
                    setVehicleInputIcon(img);
                  }}
                >
                  <div className="img">{img && <img src={img} alt="" />}</div>
                  <div className="data">
                    <div className="title">{name}</div>
                    <div className="subtitle">{description}</div>
                  </div>
                </DatasetBox>
              );
            } else {
              const {
                city,
                county,
                road,
                house_number,
                lat,
                lon,
                town,
                village,
                hamlet,
                addresstype,
                locality,
                state,
                natural,
              } = item;
              const place =
                city || town || village || hamlet || locality || natural || "";
              const area = county ? county : state;
              return addresstype !== "state" ? (
                <DatasetBox
                  key={index}
                  onClick={() => {
                    setValue({
                      coordinates: [lat, lon],
                      name: getAddressesString(item),
                      city: place,
                    });
                  }}
                >
                  <div className="data">
                    <div className="title">
                      {road
                        ? getAddressesString({
                            road,
                            house_number,
                          })
                        : place}
                    </div>
                    <div className="subtitle">
                      {road ? place : ""}
                      {area ? (place !== "" && road ? ", " + area : area) : ""}
                    </div>
                  </div>
                </DatasetBox>
              ) : null;
            }
          })}
      </DatasetList>
    </InputDatasetWrapper>
  );
};

export default memo(InputDataset);

