import React, { memo } from "react";
import {
  ClosePreview,
  ImageBackground,
  ImageBox,
  ImageWrapper,
} from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ObjectDetectionImageWrapper } from "../ObjectDetectionImageWrapper";

interface Props {
  url: string;
  close: any;
  showRecognition?: boolean;
}

const ImagePreview: React.FC<Props> = ({ url, close, showRecognition }) => {
  return (
    <ImageWrapper>
      <ImageBackground />
      <ImageBox>
        <ObjectDetectionImageWrapper showRecognition={showRecognition}>
          <img src={url} alt={url} />
        </ObjectDetectionImageWrapper>
      </ImageBox>
      <ClosePreview onClick={close}>
        <CloseIcon />
      </ClosePreview>
    </ImageWrapper>
  );
};

export default memo(ImagePreview);

