export const clearAddressString = (
  address: string,
  removeCity?: boolean,
): string => {
  let addressToDisplay: string | string[] = address.split(", ");
  if (addressToDisplay[0].includes(" обл")) {
    addressToDisplay.shift();
  }
  if (
    removeCity &&
    (addressToDisplay[0].includes("г ") || addressToDisplay[0].includes("г. "))
  ) {
    addressToDisplay.shift();
  }
  addressToDisplay = addressToDisplay.join(", ");
  addressToDisplay = addressToDisplay.replace("г ", "г. ");
  addressToDisplay = addressToDisplay.replace("д ", "д. ");
  addressToDisplay = addressToDisplay.replace("ул ", "ул. ");
  return addressToDisplay;
};
