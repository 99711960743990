export const getAccessSettingName = (settingName: string) => {
  switch (settingName) {
    case "companyRegistration":
      return "Регистрация компании";
    case "addingEmployees":
      return "Добавление сотрудников";
    case "changingEmployeesPersonalData":
      return "Изменение данных сотрудников";
    case "changingEmployeesAccessSettings":
      return "Изменение прав сотрудников";
    case "removingEmployees":
      return "Удаление сотрудников";
    case "creatingOrder":
      return "Создание заявки";
    case "editingApplication":
      return "Редактирование заявки";
    case "communicationWithContractor":
      return "Коммуникация с исполнителем";
    case "assigningContractor":
      return "Назначение исполнителя";
    case "signingDocuments":
      return "Подписание документов";
    case "payingOrder":
      return "Оплата заказа";
    case "monitoringContractor":
      return "Мониторинг исполнителя";
    case "communicationEmployees":
      return "Коммуникация с сотрудниками";
    case "acceptanceWork":
      return "Приемка работ";
    case "participationArbitration":
      return "Участие в арбитраже";
    case "feedbackWriting":
      return "Написание отзыва";
    default:
      return "";
  }
};
