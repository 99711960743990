import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpRequest } from "../../../utils/httpRequest";
import axios from "axios";
import { ENDPOINT } from "../../../constants/general";
import { getToken } from "../../../utils/uiHelpers";

type Loading = "idle" | "pending" | "succeeded" | "failed";

interface TVehicle {
  user: any;
  fireBaseData: any;
  loading?: Loading;
  error: string | null;
  loadingReg: Loading;
  loadingRef: Loading;
}

const initialState: TVehicle = {
  user: null,
  fireBaseData: null,
  loading: "idle",
  error: null,
  loadingReg: "idle",
  loadingRef: "pending",
};

export function getErrorMessage(error: unknown) {
  if (error instanceof Error) return error.message;
  return String(error);
}

export const getDataLensUrl = createAsyncThunk(
  "auth/getDataLensUrl",
  async ({ refId }: { refId: string }, thunkApi: any) => {
    const result = await axios.get(`${ENDPOINT}/companies/get-url-data-lens`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    });
    return result;
  },
);

export const updateProfileInfo = createAsyncThunk(
  "auth/updateProfileInfo",
  async (
    { refId, data }: { refId: string; data: any },
    { dispatch, rejectWithValue, fulfillWithValue },
  ) => {
    try {
      const response = await httpRequest({
        method: "PUT",
        path: `customers/${refId}/edit-profile`,
        data: data,
      });

      if (response.data) dispatch(getMe(response.data.refId));
      return fulfillWithValue(data).payload;
    } catch (error) {
      return rejectWithValue(getErrorMessage(error));
    }
  },
);

export const getMe = createAsyncThunk("auth/getMe", async (refId: string) => {
  const response = await httpRequest({
    method: "GET",
    path: `customers/${refId}`,
  });

  return response.data;
});

export const fetchRegistration = createAsyncThunk(
  "auth/fetchRegistration",
  async ({ data, navigate }: { data: any; navigate: any }) => {
    try {
      const response = await httpRequest({
        path: "customers/create",
        method: "POST",
        data: data,
      });

      navigate("/profile");
      return response.data;
    } catch (err) {}
  },
);

export const getRefIDFromFireBase = createAsyncThunk(
  "auth/getRefIDFromFireBase",
  async ({ uid, navigate }: { uid: string; navigate?: any }, thunkApi: any) => {
    const response = await httpRequest({
      method: "GET",
      path: "customers/exist/by-uid",
      params: { uid },
    });

    if (response.data !== "") {
      await thunkApi.dispatch(getMe(response.data.refId));
    } else {
      navigate("/registration");
    }
    return response.data;
  },
);

export const getData = createAsyncThunk(
  "auth/getData",
  async (uid: string, thunkApi: any) => {
    const { dispatch } = thunkApi;
    await dispatch(getRefIDFromFireBase({ uid }));
  },
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearAuth(state) {
      state.user = null;
      state.fireBaseData = null;
      state.loading = "idle";
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = "succeeded";
      })
      .addCase(getMe.pending, (state) => {
        state.error = null;
        state.loading = "pending";
      })
      .addCase(getMe.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loading = "failed";
        state.user = null;
      });
    builder
      .addCase(getRefIDFromFireBase.fulfilled, (state, action) => {
        state.fireBaseData = action.payload;
        state.loadingRef = "succeeded";
      })
      .addCase(getRefIDFromFireBase.pending, (state) => {
        state.error = null;
        state.loadingRef = "pending";
      })
      .addCase(getRefIDFromFireBase.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingRef = "failed";
        state.fireBaseData = null;
      });
    builder
      .addCase(fetchRegistration.fulfilled, (state, action) => {
        state.error = null;
        state.loadingReg = "succeeded";
      })
      .addCase(fetchRegistration.pending, (state) => {
        state.error = null;
        state.loadingReg = "pending";
      })
      .addCase(fetchRegistration.rejected, (state) => {
        state.error = "Внимание какая то ошибка";
        state.loadingReg = "failed";
      });
  },
});

export const { reducer: profileReducer, actions: profileActions } =
  profileSlice;

export default profileSlice.reducer;
