import styled from "styled-components";

export const Wrapper = styled.div`
  width: 74px;
  height: 74px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  animation: fileOpen 0.3s ease-in;
  user-select: none;
  background: #000000;

  &:hover {
    .closeButton {
      opacity: 100%;
    }
  }

  &.fileClose {
    animation: fileClose 0.3s ease-in !important;
  }

  .file {
    width: 100%;
    height: 100%;
    font-family: "Rubik";
    font-weight: 450;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    user-select: none;
    background-color: rgb(243, 247, 248);
    color: #222222;
    line-height: 1;
    padding: 8px 8px 4px;
  }

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border: 1px solid #222222;
    user-select: none;
    border-radius: 8px;
  }

  .closeButton {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s linear;
    opacity: 0%;
  }

  @keyframes fileOpen {
    0% {
      scale: 0%;
    }
    100% {
      scale: 100%;
    }
  }

  @keyframes fileClose {
    0% {
      scale: 100%;
    }
    100% {
      scale: 0%;
    }
  }
`;

