import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 0;
  background-color: transparent;
  border-radius: 10px;
`;

export const ContentPart = styled.div`
  width: calc(100% - 422px);
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "transparent" : "white"};
  border-radius: 10px;
  padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
    raw ? "0px" : "32px 32px 40px"};

  @media only screen and (min-width: 1440px) {
    width: calc(100% - 408px);
  }

  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
  @media only screen and (max-width: 1050px) {
    width: 100%;
    padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
      raw ? "0" : "16px 16px 20px"};
  }
  @media only screen and (max-width: 720px) {
    width: 100%;
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
    /* padding: 40px 16px; */
    padding: ${({ raw }: { raw?: boolean; mobile?: boolean }) =>
      raw ? "0 16px 16px" : "40px 16px"};
    /* display: ${({ mobile }: { mobile?: boolean }) =>
      mobile ? "none" : "block"}; */
    height: fit-content;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 251px;
  img {
    width: 64px;
    height: 64px;
    margin: 0px 0px 24px 0;
    object-fit: contain;
    color: #c8c8c8;
  }
  .text {
    margin-bottom: 4px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }
  p {
    width: 100%;
    margin: 8px 0 24px;
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.15px;
    color: #757575;
  }
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 251px;
  height: 56px;
  cursor: pointer;
  background: #ff6633;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 rgba(166, 60, 24, 0.3);
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
`;
