import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container, styles } from "../styles/styles";

export const NavbarWrapper = styled.nav<{ fixed?: boolean; full?: boolean }>`
  width: 100%;
  /* height: 80px; */

  //background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.12));
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12) !important;

  @media only screen and (max-width: 768px) {
    min-height: 60px;
    height: 60px;
  }
  @media only screen and (max-width: 576px) {
    padding: 0 20px;
    ${({ fixed }) => fixed && "position:fixed;top:0;right:0;left:0;"}
    z-index: 1;
    height: fit-content;
  }
`;

export const Badge = styled.div`
  padding: 0 6px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: #ff6633;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #ffffff;
`;

export const NavbarContainer = styled(Container)<{ full?: boolean }>`
  max-width: unset;
  width: 100%;
  /* padding-right: 20px;
  padding-left: 20px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px 5px;
  padding: 8px 24px;

  @media only screen and (max-width: 998px) {
    padding: 8px 20px;
  }
  @media only screen and (max-width: 768px) {
    padding: 6px 20px;
  }
  @media only screen and (max-width: 576px) {
    /* ${({ full }) => (full ? "" : "")} */
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

export const HelpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #fdfdfd33;
  border: solid 1px rgba(253, 253, 253, 0.2);
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #000;
`;

export const LogoPart = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 0;
`;

export const MenuBar = styled.div`
  margin-right: 16px;
  width: 24px;
  height: 24px;
  padding: 3px;
  display: flex;
  border-radius: 5px;

  svg {
    display: flex;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 576px) {
    display: block;
  }

  @media only screen and (max-width: 320px) {
    display: block;
    gap: 0 0;
  }
`;

export const Logo = styled(Link)`
  display: block;
  height: 24px;
  width: fit-content;

  img {
    width: auto;
    height: 100%;
  }
`;

export const LoginBtn = styled.button`
  width: 130px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.mainColor};
  color: white;
  border-radius: 10px;
  font-size: 13px;
  border: 0;
  cursor: pointer;
`;

export const AvatarBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  .notifyIcon {
    width: 25px;
    height: 25px;
    box-shadow: none;
    //margin-left: 5px;
    transition: all 0.4s ease-out;
    animation: anim 0.2s ease;
  }

  .notifyIcon:hover {
    opacity: 0.5;
  }

  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    box-shadow: 1px 2px 10px 1px #e4e4e4;
  }

  .hoverGroup {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
  }

  .name {
    display: flex;
    gap: 2px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: #222222;
  }

  .line {
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: #757575;

    .rating {
      display: flex;
      gap: 4px;
      align-items: center;
    }
  }

  .anticon,
  .anticon-down {
    margin-left: 5px;
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 40px;
      height: 40px;
    }
  }

  @media only screen and (max-width: 768px) {
    img {
      margin-right: 4px;
    }
  }

  @media screen and (max-width: 330px) {
    .nameSpan {
      display: none;
    }
    .line {
      display: none;
    }
  }
`;

export const NavList = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-left: 48px;

  @media only screen and (max-width: 998px) {
    display: none;
  }
`;

export const NavItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  .number {
    margin-left: 4px;
    font-size: 14px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${styles.mainColor};
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const MobileNavbar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: white;
  display: flex;
  background-color: white;
  z-index: 99;
  overflow: auto;
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 15px;
  left: 20px;
  width: 30px;
  height: 30px;

  svg {
    width: 100%;
    height: 100%;
  }
`;

export const NavsListWrapper = styled.div`
  width: 90%;
  margin: auto;
`;

export const NotificationBor = styled.div`
  position: relative;
  box-shadow: 0;

  img {
    width: 100%;
    height: 100%;
  }

  .dot {
    position: absolute;
    top: 0;
    right: 10px;
    width: 10px;
    height: 10px;
    background-color: ${styles.mainColor};
    border-radius: 50%;
  }
`;

export const MenuPart = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 480px) {
    gap: 12px;
  }
`;

export const SupportBtn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  background-color: #fdfdfd33;
  border: solid 1px #fdfdfd;
  border-radius: 10px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s linear;
  width: 132px;
  height: 32px;
  padding: 0 10px;

  svg {
    transition: all 0.3s linear;
    path {
      transition: all 0.3s linear;
    }
  }

  &:hover {
    color: #ff6633;
    border: solid 1px #ff6633;
    svg {
      width: 24px;
      height: 24px;
      path {
        fill: #ff6633;
      }
    }
  }

  @media screen and (max-width: 480px) {
    display: none;
  }
`;
