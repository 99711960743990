import styled from "styled-components";

import checkIcon from "../../assets/icons/CheckStroke.svg";

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  /* padding: 32px; */
  padding: 24px;
  border-radius: 16px;
  @media only screen and (min-width: 1440px) {
    /* padding: 26px; */
    padding: 24px 26px 29px;
  }
  @media only screen and (max-width: 1050px) {
    padding: 16px;
  }

  .editPen {
    cursor: pointer;
    width: 20px;
    height: 16px;
    opacity: 0.6;
    border: 1px solid grey;
    border-radius: 4px;
    padding: 1px;
    transition: all 0.4s ease-out;
    animation: anim 0.3s ease;

    :hover {
      opacity: 1;
      box-shadow: 1px 2px 7px 4px #ececec;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  svg,
  img {
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }

  div.title {
    width: calc(100% - 32px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    /* font-size: 32px; */
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.5px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
`;

export const LabelText = styled.div<{
  half?: boolean;
  full?: boolean;
  oneOfThird?: boolean;
  biggerText?: boolean;
}>`
  /* margin-bottom: 16px; */
  width: ${({ half, full, oneOfThird }) =>
    half
      ? "calc((100% - 20px) / 2)"
      : oneOfThird
      ? "calc((100% - 40px) / 3)"
      : "100%"};

  .label {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #999999;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    word-wrap: break-word;
    font-size: ${({ biggerText }) => (biggerText ? 20 : 16)}px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000;
  }
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const ShortData = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin-bottom: 40px;
  flex-wrap: wrap;

  .box {
    width: calc((100% - 20px) / 2);
  }

  .label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 4px;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    word-wrap: break-word;
  }

  @media only screen and (max-width: 1250px) {
    gap: 25px;
  }
  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
`;

export const DescWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const ForemanContacts = styled.div`
  /* margin-bottom: 16px; */
  width: calc((100% - 20px) / 2);

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #999999;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    word-wrap: break-word;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }

  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  width: 100%;
  max-width: 635px;

  .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #999999;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    word-wrap: break-word;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #000;
  }
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const CharacteristicsBox = styled(Description)`
  width: 100%;
  @media only screen and (max-width: 750px) {
    width: 100%;
  }
`;

export const DocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  max-width: 792px;

  .imageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 152px;
    height: 152px;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    animation: anim 0.2 ease-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      box-shadow: 1px 4px 10px 4px #eeeeee;
    }
  }

  .add {
    border: 2px dashed #ff6633;
    font-size: 56px;
    font-family: Times;
    font-weight: lighter;
    color: #ff6633;
  }

  @media screen and (max-width: 480px) {
    .imageContainer {
      max-width: 125px;
      max-height: 125px;
    }
  }
`;

export const ImageItem = styled.div`
  width: calc((100% - 32px) / 5);
  padding-top: calc((100% - 32px) / 5);
  height: fit-content;
  position: relative;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    border-radius: 10px;
    object-fit: contain;
    object-position: center;
    /* display: block; */
  }

  .pdf-box {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding-top: 100%;
    height: fit-content;

    img.pdf {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }

  .img-name {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media only screen and (max-width: 1050px) {
    width: calc((100% - 24px) / 4);
    padding-top: calc((100% - 24px) / 4);
  }
  @media only screen and (max-width: 576px) {
    width: calc((100% - 16px) / 3);
    padding-top: calc((100% - 16px) / 3);
    .img-name {
      font-size: 12px;
    }
  }
`;

export const AddressBox = styled.div`
  margin-top: 32px;
  display: ${({ process }: { process?: boolean }) =>
    process ? "flex" : "block"};
  /* flex-wrap: wrap; */

  .contact,
  .data {
    width: ${({ process }: { process?: boolean }) =>
      process ? "50%" : "100%"};

    .title {
      margin-bottom: 4px;
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #999999;
    }

    .text {
      width: ${({ process }: { process?: boolean }) =>
        process ? "220px" : "100%"};
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.25px;
      color: #000;
    }
  }

  .contact {
    margin-top: 15px;
  }

  .map-box {
    margin-top: ${({ process }: { process?: boolean }) =>
      process ? "0px" : "24px"};
    width: ${({ process }: { process?: boolean }) =>
      process ? "50%" : "100%"};
    height: ${({ process }: { process?: boolean }) =>
      process ? "140px" : "416px"};
  }

  @media only screen and (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    .data {
      width: 100%;
      margin-bottom: 16px;

      .text {
        width: 100%;
      }
    }

    .map-box {
      width: 100%;
      height: 250px;
    }
  }
`;

export const CheckboxWrapper = styled.div`
  /* display: flex; */
  gap: 10px 15px;
  flex-wrap: wrap;
  margin-bottom: 10px;

  input[type="checkbox"] {
    accent-color: grey;
    opacity: 0.8;
  }

  .bigcheck {
    margin-right: 10px;
  }
`;

export const CheckboxItem = styled.label`
  display: flex;
  align-items: center;
  user-select: none;
  input {
    margin-right: 5px;
    width: 20px;
    height: 20px;
    appearance: none;
    position: relative;
    &::after {
      content: "";
      background-image: url(${checkIcon});
      background-position: center;
      background-size: cover;
      position: absolute;
      height: 100%;
      width: 100%;
    }
  }
`;

export const ShiftBox = styled.div``;
