import React, { memo, useState } from "react";
import {
  FieldsWrapper,
  SideMenuBackground,
  SideMenuHeader,
  SideMenuWrapper,
} from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-24-intellicon-basic-circle-x.svg";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import { useAppDispatch } from "../../redux/hooks";
import { addCompanyUser } from "../../redux/store/reducers/companySlice";
import { JobTitles } from ".";
import FilterSelect from "../../UI/FilterSelect";

interface AddEmployeeProps {
  menuOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customerRefId?: string | undefined;
}

const Fields = [
  {
    name: "secondName",
    placeholder: "Фамилия",
    required: true,
  },
  {
    name: "firstName",
    placeholder: "Имя",
    required: true,
  },
  {
    name: "patronymic",
    placeholder: "Отчество",
    required: false,
  },
  {
    name: "email",
    placeholder: "Email",
    required: true,
  },
  {
    name: "phoneNumber",
    placeholder: "Телефон",
    required: true,
  },
];

const AddEmployeeSideMenu: React.FC<AddEmployeeProps> = ({
  menuOpen,
  toggleOpen,
  customerRefId,
}) => {
  const dispatch = useAppDispatch();

  const [createUserError, setCreateUserError] = useState<string | null>(null);
  const [role, setRole] = useState<{
    name: string;
    value: string;
  } | null>(null);
  const [fieldsData, setFieldsData] = useState<{ [key: string]: string }>({
    firstName: "",
    secondName: "",
    patronymic: "",
    email: "",
    phoneNumber: "",
  });

  const handeCreateEmployee = () => {
    customerRefId &&
      role &&
      dispatch(
        addCompanyUser({
          refId: customerRefId,
          data: {
            companyRole: role.value,
            firstName: fieldsData.firstName,
            secondName: fieldsData.secondName,
            patronymic: fieldsData.patronymic,
            phoneNumber: fieldsData.phoneNumber,
            email: fieldsData.email,
          },
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toggleOpen(false);
          setFieldsData({
            firstName: "",
            secondName: "",
            patronymic: "",
            email: "",
            phoneNumber: "",
          });
          setRole(null);
          setCreateUserError(null);
        } else {
          //@ts-ignore
          if (response.error.message.includes("400")) {
            setCreateUserError("Пользователь уже принадлежит другой компании");
          }
          //setErrorMsg(["При добавлении пользователя произошла ошибка"]);
        }
      });
  };

  const renderFileds = () => {
    return [
      <FilterSelect
        name="role"
        list={JobTitles.filter((job) => job.value !== "ceo")}
        listMarker={false}
        value={
          role
            ? JobTitles.find((job) => job.value === role.value)?.label
            : "Должность сотрудника"
        }
        onChange={({ name, value }) => {
          setRole({ name, value: value as string });
        }}
      />,
      Fields.map((field) => {
        return (
          <Input
            placeholder={field.placeholder}
            name={field.name}
            value={fieldsData[field.name]}
            required={field.required}
            maxLength={field.name === "phoneNumber" ? 12 : undefined}
            onChange={(e: any) => {
              if (e.target.name === "phoneNumber") {
                const numberRegEx = new RegExp("[0-9]");
                if (fieldsData.phoneNumber === "") {
                  if (e.target.value === "+") {
                    setFieldsData({
                      ...fieldsData,
                      phoneNumber: "+7",
                    });
                  }
                  if (e.target.value === "7" || e.target.value === "8") {
                    setFieldsData({
                      ...fieldsData,
                      phoneNumber: "+7",
                    });
                  }
                  if (e.target.value === "9") {
                    setFieldsData({
                      ...fieldsData,
                      phoneNumber: "+79",
                    });
                  }
                  if (numberRegEx.test(e.target.value.substring(1))) {
                    setFieldsData({
                      ...fieldsData,
                      phoneNumber: "+7" + e.target.value,
                    });
                  }
                } else {
                  if (numberRegEx.test(e.target.value.substring(1))) {
                    setFieldsData({
                      ...fieldsData,
                      phoneNumber: e.target.value,
                    });
                  }
                }
              } else {
                setFieldsData({
                  ...fieldsData,
                  [e.target.name]: e.target.value,
                });
              }
            }}
            height="48px"
            width="100%"
          />
        );
      }),
    ];
  };

  return (
    <>
      <SideMenuBackground
        open={menuOpen}
        onClick={() => toggleOpen(false)}
      ></SideMenuBackground>
      <SideMenuWrapper open={menuOpen}>
        <SideMenuHeader>
          Добавление сотрудника <CloseIcon onClick={() => toggleOpen(false)} />
        </SideMenuHeader>
        <FieldsWrapper>
          {renderFileds()}
          <Button
            onClick={handeCreateEmployee}
            disabled={
              !fieldsData.firstName ||
              !fieldsData.secondName ||
              !fieldsData.phoneNumber ||
              !fieldsData.email ||
              !role
            }
            width="100%"
          >
            Добавить
          </Button>

          <div
            className="errorMessage"
            style={{
              opacity: createUserError ? "100%" : "0%",
              transition: "all 0.3s linear",
            }}
          >
            Пользователь уже принадлежит другой компании
          </div>
        </FieldsWrapper>
      </SideMenuWrapper>
    </>
  );
};

export default memo(AddEmployeeSideMenu);
