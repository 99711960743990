import actionTypes from "../../constants/actionTypes";
import { ENDPOINT } from "../../constants/general";
import { httpRequest } from "../../utils/httpRequest";

const getDashboardSingleOrder = (id: string, orderId: any) => ({
  type: actionTypes.GET_DASHBOARD_SINGLE_ORDER,
  payload: httpRequest({
    method: "GET",
    // path: `payout/${id}/cards`,
    url: `${ENDPOINT}/companies/${id}/orders/${orderId}`,
    params: {},
  }),
});

export default getDashboardSingleOrder;
