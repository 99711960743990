import styled from "styled-components";

export const OrgDataPageWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  max-width: 984px;

  @media screen and (max-width: 768px) {
    gap: 8px;
  }
`;

export const Button = styled.div`
  width: 300px;
  height: 48px;
  border-radius: 10px;
  background: #ff6633;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ContentCard = styled.div<{ width?: string; half?: boolean }>`
  display: flex;
  padding: 24px;
  width: ${({ width, half }) =>
    width ? width : half ? "calc((100% - 16px) / 2 )" : "100%"};
  background: #ffffff;
  border-radius: 8px;

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    padding-left: 10px;
    border-radius: 0;
    width: 100% !important;

    &:nth-child(1) {
      margin-top: -16px;
    }
  }
`;

export const InputContainerInline = styled.div`
  width: calc((100% - 12px) / 2);
  @media only screen and (max-width: 1440px) {
    display: flex;
    width: calc((100% - 12px) / 2);
  }

  @media only screen and (max-width: 480px) {
    display: flex;
    width: calc((100% - 12px) / 2);
  }

  @media only screen and (max-width: 320px) {
    display: flex;
    width: 100%;
  }
`;

export const ProfileDataCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;

  .title {
    font-family: "Rubik";
    font-size: 20px;
    line-height: 1.2;
    color: #000;
    width: 100%;

    span {
      color: #ff3358;
      font-size: 15px;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 8px;

    label {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 480px) {
    gap: 8px;
  }
`;

export const Document = styled.div`
  width: 399px;
  height: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: solid 2px #222222;
  padding: 16px 15px;

  @media screen and (max-width: 320px) {
    flex-direction: row-reverse;
    gap: 16px;
    justify-content: flex-end;
  }

  .documentGroup {
    display: flex;
    gap: 16px;
    align-items: center;

    @media screen and (max-width: 320px) {
      img {
        display: none;
      }
    }

    .documentInfo {
      display: flex;
      flex-direction: column;

      .title {
        /* font-family: "Google Sans", sans-serif; */
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
      }

      .text {
        /* font-family: "Google Sans", sans-serif; */
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
      }
    }
  }

  @media only screen and (max-width: 480px) {
    height: auto;
  }
`;

export const InputsWrapperRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  gap: 16px 12px;

  @media screen and (max-width: 768px) {
    gap: 8px;
  }

  @media only screen and (max-width: 480px) {
    margin-bottom: 4px;
  }
`;

export const SearchRequisite = styled.div`
  padding: 14px 17px 14px 11px;
  /* height: fit-content; */
  height: 48px;
  display: flex;
  border-radius: 10px;
  border: solid 1px #e3e3e3;
  align-items: center;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 7.5px;
  }
`;

export const DocumentsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 814px;

  .title {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000;
    width: 100%;
  }

  @media only screen and (max-width: 480px) {
    width: 100% !important;
  }
`;

export const OrgDataCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 510px;

  .title {
    font-family: "Rubik";
    font-size: 20px;
    line-height: 1.2;
    color: #000;
    width: 100%;

    @media only screen and (max-width: 480px) {
      margin-bottom: 8px;
    }
  }

  @media screen and (max-width: 768px) {
    gap: 8px;
  }

  @media only screen and (max-width: 480px) {
    /* padding-left: 10px; */
    border-radius: none;
    width: 100%;
    gap: 8px;
  }
`;

export const CdCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 232px;
  justify-content: space-between;

  img {
    width: 64px;
    height: 64px;

    @media only screen and (max-width: 320px) {
      width: 40px;
      height: 40px;
      margin-bottom: 4px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    a {
      text-decoration: none;
      color: inherit;
    }

    .title {
      font-family: "Rubik";
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #000;
      margin-bottom: 8px;
    }

    .text {
      font-size: 15px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.07;
      letter-spacing: -0.15px;
      color: #757575;
      margin-bottom: 16px;
    }

    .button {
      max-width: 244px;
      height: 32px;
      padding: 7px 16px 9px;
      border-radius: 10px;
      background-color: #ff6633;

      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 320px) {
    gap: 20px;
    height: auto;
  }
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  height: 232px;
  justify-content: space-between;

  @media screen and (max-width: 480px) {
    height: unset;
    gap: 16px;
    justify-content: unset;
  }

  #rolePicker {
    display: none;

    @media screen and (max-width: 480px) {
      display: flex;
    }
  }

  .profilePhoto {
    display: flex;
    gap: 16px;
    height: 80px;
    align-items: center;

    .imgContainer {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #c4c4c4;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .profilePhotoGroup {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .title {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        color: #000;
        white-space: nowrap;
      }

      .buttons {
        display: flex;
        gap: 16px;

        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        color: #222222;
        white-space: nowrap;
      }
    }
  }

  .profileInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      /* font-family: "Google Sans", sans-serif; */
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
    }

    .text {
      display: flex;
      flex-direction: column;
      gap: 6px;

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #757575;

      div {
        display: flex;
        gap: 4px;
        align-items: center;
      }
    }
  }
`;
