import styled from "styled-components";

export const Wrapper = styled.div<{
  noWidth?: boolean;
  noHeight?: boolean;
  flexDirection?: string;
  minHeight?: string;
  noFooter?: boolean;
  gap?: string;
  alignItems?: string;
  paddingTop?: string;
}>`
  display: flex;
  width: 100%;
  padding-bottom: 8px !important;

  max-width: ${({ noWidth }) => (noWidth ? "unset" : "1392px")};
  gap: ${({ gap }) => (gap ? gap : "16px")};
  height: ${({ noHeight, minHeight, noFooter }) =>
    !noHeight && !minHeight
      ? noFooter
        ? "calc(100dvh - 65px)"
        : "calc(100dvh - 137px)"
      : minHeight
      ? minHeight
      : "unset"};
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};

  ${({ noWidth }) => !noWidth && "margin: 0 auto"};

  ${({ minHeight }) => minHeight && `min-height: ${minHeight}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ noWidth }) => !noWidth && "padding: 0 8px"};
  ${({ paddingTop }) => paddingTop && `padding-top: ${paddingTop}`};

  @media screen and (max-width: 998px) {
    ${({ noHeight }) => !noHeight && "height: calc(100% - 64px)"};
  }

  @media screen and (max-width: 480px) {
    padding: 0 !important;
  }
`;
