import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/form/styles";
import { BG, ButtonWrapper, DataPart, Wrapper } from "./styles";

const GoPage: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Wrapper>
      <BG />
      <DataPart>
        <p>Пожалуйста, выберите, что вы хотели бы:</p>
        <ButtonWrapper>
          <Button fit onClick={() => navigate("/search-order")}>
            Найти заказ
          </Button>
          <Button fit onClick={() => navigate("/create-order")}>
            Заказать технику
          </Button>
        </ButtonWrapper>
      </DataPart>
    </Wrapper>
  );
};

export default GoPage;
