import React, { memo, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import ProposalItem from "../proposalItem";
import { useNavigate } from "react-router-dom";
import ProposalPopUp from "../popUp/ProposalPopUp";
import { checkUserRegistered } from "../../utils/checkUserRegistered";
import {
  fetchSendRespond,
  getCounterOffers,
  getMyOrders,
  getOrderOffers,
} from "../../redux/store/reducers/orderSlice";
import {
  setPageToRedirect,
  setWorkerRespond,
} from "../../redux/store/reducers/registrationSlice";
import { Wrapper } from "../../pages/infoAboutOrderPage/styles";
import { CounterOffer } from "../../types/orderSlice.types";
import { Offer } from "../../types";
import { HiddenOffers } from "./styles";

const Proposals: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const singleOrder = useAppSelector((state) => state.order.singleOrder);
  const orderDocs = useAppSelector((state) => state.order.orderDocs);
  const offers = useAppSelector((state: RootState) => state.order.offers);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const [proposalPopUp, setProposalPopUp] = useState<boolean>(false);
  const [counterOffers, setCounterOffers] = useState<CounterOffer[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<[Offer] | null>(null);
  const [hiddenOffersOpen, setHiddenOffersOpen] = useState<boolean>(false);

  const getVehicleCategory = (categoryId: number): string => {
    return vehicleCategories.find((category) => category.id === categoryId)
      ?.title;
  };

  const fetchRespondHandler = async (data: any) => {
    if (user && checkUserRegistered(user)) {
      await dispatch(
        fetchSendRespond({
          ...data,
          orderId: singleOrder?.order?.id,
          refId: user?.customer?.refId,
        }),
      ).then(() => {
        dispatch(
          getOrderOffers({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
        dispatch(
          getMyOrders({
            refId: user?.customer?.refId,
            take: 10,
            skip: 0,
          }),
        );
      });
    } else {
      dispatch(setPageToRedirect(`/o/${singleOrder?.order?.id}`));
      dispatch(
        setWorkerRespond({
          ...data,
          orderId: singleOrder?.order?.id,
          refId: user?.customer?.refId,
        }),
      );
      navigate("/registration");
    }
  };

  const getCardHeader = (): string => {
    const offersCount = offers?.length.toString();
    if (selectedOffer) {
      if (
        user?.customer.id &&
        singleOrder.order.creatorId === user?.customer.id
      ) {
        return "Ваш исполнитель";
      }
      if (
        user?.customer.id &&
        selectedOffer[0].workerId === user?.customer.id
      ) {
        return "Вас назначили исполнителем";
      }
    }
    if (
      ["0", "5", "6", "7", "8", "9"].includes(
        offersCount[offersCount.length - 1],
      )
    ) {
      return `${offersCount} предложений`;
    } else if (["2", "3", "4"].includes(offersCount[offersCount.length - 1])) {
      return `${offersCount} предложения`;
    } else {
      return `${offersCount} предложение`;
    }
  };

  useEffect(() => {
    if (user?.customer.refId) {
      dispatch(
        getCounterOffers({
          refId: user.customer.refId,
          orderId: singleOrder?.order?.id,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setCounterOffers(response.payload);
        }
      });
    }
  }, [user]);

  useEffect(() => {
    const selectedOffer = offers.find(
      (offer) =>
        offer.status === "Accepted" || offer.status === "WaitingForPayment",
    );
    selectedOffer && setSelectedOffer([selectedOffer]);
  }, [offers]);

  const renderOffers = useCallback(
    (offers: Offer[], withoutSelectedOffer: boolean = false) => {
      return offers.map((offer, index) => {
        let shouldReturnComponent = true;
        if (
          withoutSelectedOffer &&
          selectedOffer &&
          offer.id === selectedOffer![0].id
        ) {
          shouldReturnComponent = false;
        }
        if (shouldReturnComponent) {
          return (
            <ProposalItem
              {...offer}
              orderStatus={singleOrder?.order?.status}
              getVehicleCategory={getVehicleCategory}
              key={`offer_${index}`}
              isAbleToSelect={
                !offers?.find(
                  (offer) =>
                    offer.status === "WaitingForPayment" ||
                    offer.status === "Accepted",
                )
              }
              worker={offer?.worker}
              counterOffer={
                counterOffers
                  ? counterOffers.reverse().find((counterOffer) => {
                      return counterOffer.offer_id.id === offer.id;
                    })
                  : null
              }
              setCounterOffers={setCounterOffers}
            />
          );
        }
      });
    },
    [offers, counterOffers, orderDocs],
  );

  return (
    <Wrapper id="order_proposals">
      <div className="cardHeader">{`${getCardHeader()}`}</div>
      {selectedOffer && renderOffers(selectedOffer)}
      {!selectedOffer && renderOffers(offers)}

      {proposalPopUp && (
        <ProposalPopUp
          orderPaymentType={singleOrder?.order?.paymentType}
          close={() => setProposalPopUp(false)}
          onSubmit={fetchRespondHandler}
          orderCategoryId={singleOrder?.order?.categoryId}
        />
      )}
      {offers.length > 1 && selectedOffer && (
        <>
          <div
            className="bottomText"
            onClick={() => setHiddenOffersOpen(!hiddenOffersOpen)}
          >
            {`${hiddenOffersOpen ? "Скрыть" : "Показать"} другие предложения`}
          </div>
          <HiddenOffers open={hiddenOffersOpen}>
            <div className="flex-container">{renderOffers(offers, true)}</div>
          </HiddenOffers>
        </>
      )}
    </Wrapper>
  );
};

export default memo(Proposals);

