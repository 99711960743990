import React, { memo } from "react";
import PopUpContainer from "../popUpContainer";

const ReportWorkerPopUp: React.FC<{
  close: () => void;
}> = ({ close }) => {
  const onSubmit = () => {
    close();
  };

  return (
    <PopUpContainer
      title={"Жалоба на исполнителя"}
      close={close}
      onSubmit={onSubmit}
      button={"Закрыть"}
    >
      Спасибо за Ваше обращение, мы проверим данного исполнителя и заблокируем в
      случае нарушения правил платформы.
    </PopUpContainer>
  );
};

export default memo(ReportWorkerPopUp);
