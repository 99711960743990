import styled from "styled-components";

export const ImageWrapper = styled.div`
  width: 100vw;
  height: var(--vh100);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 19;
`;

export const ImageBackground = styled.div`
  width: 100vw;
  height: var(--vh100);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(39, 39, 39, 0.41);
`;

export const ImageBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;

  img {
    max-width: 90vw;
    min-width: 200px;
    max-height: 90vh;
    min-height: 200px;
    border-radius: 8px;
  }

  .video {
    max-width: 90vw;
    min-width: 200px;
    max-height: 90vh;
    min-height: 200px;
    border-radius: 8px;
  }
`;

export const ClosePreview = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 70px;
  height: 70px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  border-radius: 8px;

  svg {
    width: 100%;
    height: 100%;
    color: white;
  }

  @media screen and (max-width: 480px) {
    width: 45px;
    height: 45px;
  }
`;

