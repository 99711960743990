export const getPageTitle = (pathname: string, search: string) => {
  if (pathname === "/registration") {
    document.title = "Регистрация";
  }
  if (pathname === "/login") {
    document.title = "Вход в Рукки";
  }
  if (pathname === "/create-order") {
    document.title = "Создание заказа";
  }
  if (pathname.includes("search-order")) {
    document.title = search === "?flag=my" ? "Мои работы" : "Поиск заказов";
  }
  if (pathname === "/profile") {
    document.title = "Профиль";
  }
  if (pathname === "/profile/employee-management") {
    document.title = "Управление сотрудниками";
  }
  if (pathname === "/profile/vehicle-management") {
    document.title = "Управление техникой";
  }
  if (pathname === "/profile/rating") {
    document.title = "Отзывы и рейтинг";
  }
  if (pathname === "/dashboard/employees") {
    document.title = "Сотрудники";
  }
  if (pathname === "/profile/settings") {
    document.title = "Настройки";
  }
  if (pathname.startsWith("/o")) {
    const id = pathname.split("/")[2];
    if (id) {
      if (pathname.includes("edit")) {
        document.title = "Редактирование заказа";
      } else document.title = `Заказ №${id}`;
    } else document.title = "Мои заказы";
  }
  if (pathname === "/messages") {
    document.title = "Сообщения";
  }
  if (pathname.includes("dashboard")) {
    if (pathname.includes("objects")) {
      document.title = "Объекты";
    }
    if (pathname.includes("manager")) {
      document.title = "Сотрудники";
    }
    if (pathname.includes("order")) {
      document.title = "Заказы";
    }
  }
};

