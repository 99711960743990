import React, { memo, useState } from "react";
import { SetMessageFileState } from "../../pages/messagesPage";
import ImagePreview from "../imagePreview";
import VideoPreview from "../videoPreview";
import { useNavigate } from "react-router";

interface MessageFileProps {
  messageFile: SetMessageFileState | undefined;
  fileExtension: string | null;
  fileName: string;
}

const getClassname = (fileExtension: string | null) => {
  if (
    fileExtension === "png" ||
    fileExtension === "jpg" ||
    fileExtension === "jpeg"
  ) {
    return "image";
  }
  if (fileExtension === "mp3" || fileExtension === "wav") {
    return "audio";
  }
  return "image";
};

const MessageFile: React.FC<MessageFileProps> = ({
  messageFile,
  fileExtension,
  fileName,
}) => {
  const [viewImage, setViewImage] = useState<boolean>(false);
  const [viewVideo, setViewVideo] = useState<boolean>(false);
  const navigate = useNavigate();
  const redirectUrl = `/document/${fileName}`;
  console.log(messageFile?.fileUrl);
  if (messageFile) {
    const fileType = `${messageFile?.type?.split("/")[0]}`;
    if (fileType === "audio") {
      return (
        <audio controls>
          <source
            src={`data:${messageFile.type};base64, ${messageFile.fileUrl}`}
          />
        </audio>
      );
    }

    if (fileType === "video") {
      return (
        <>
          <video autoPlay={!viewVideo} muted onClick={() => setViewVideo(true)}>
            <source
              src={`data:${messageFile.type};base64, ${messageFile.fileUrl}`}
            />
          </video>
          {viewVideo && (
            <VideoPreview
              videoFile={messageFile}
              close={() => setViewVideo(false)}
            />
          )}
        </>
      );
    }

    if (fileType === "application") {
      return (
        <a href={`/document/${fileName}`}>
          <div className="file">{`.${fileExtension}`}</div>
        </a>
      );
    }

    if (fileType === "image") {
      return (
        <>
          <img
            src={`data:${messageFile.type};base64, ${messageFile.fileUrl}`}
            alt=""
            onClick={() => setViewImage(true)}
          />
          {viewImage && (
            <ImagePreview
              url={`data:${messageFile.type};base64, ${messageFile.fileUrl}`}
              close={() => setViewImage(false)}
              showRecognition={false}
            />
          )}
        </>
      );
    }
  } else {
    return (
      <div className={`fileLoadingIndicator ${getClassname(fileExtension)}`}>
        <span></span>
      </div>
    );
  }

  return null;
};

export default memo(MessageFile);

