import NumberFormat from "react-number-format";
import styled from "styled-components";
import { BigTitle, styles } from "../styles/styles";
import InputPhoneNumber from "react-phone-number-input/input";
// import InputPhoneNumber from "react-phone-number-input";

export const FormWrapper = styled.div`
  width: 320px;
  margin: auto;
`;

export const FormTitle = styled(BigTitle)<{ verification?: boolean }>`
  margin-bottom: 36px;
  text-align: center;
  position: relative;
  font-weight: 600;
  margin-bottom: 0;
  font-family: "Rubik";

  .go-back {
    display: none;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -40px;
  }
  @media only screen and (max-width: 480px) {
    ${({ verification }) =>
      verification &&
      `
      font-size: 20px;
      font-weight:normal;
      line-height: 24px;
      .go-back {
        display:block;
      }
    `}
  }
  @media only screen and (max-width: 440px) {
    ${({ verification }) =>
      verification &&
      `
      display:flex;
      align-items: center;
      .go-back {
        left:0;
        margin-right: 10px;
        position: relative;
      }`}
  }
  @media only screen and (max-width: 350px) {
    font-size: 32px;
    line-height: 36px;
    ${({ verification }) =>
      verification &&
      `
      font-size: 20px;
      font-weight:normal;
      line-height: 24px;`}
  }
`;

export const FormDesc = styled.p`
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 32px;
  text-align: ${({ center }: { center?: boolean }) =>
    center ? "center" : "left"};

  @media only screen and (max-width: 576px) {
    font-size: 24px;
    line-height: 28px;
  }
  @media only screen and (max-width: 400px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

export const NumberFormatInput = styled(NumberFormat)`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 24px;
  border-radius: 10px;
  border: 1px solid rgba(147, 147, 147, 1);
  outline: 0;

  &:focus {
    border-color: rgba(254, 200, 38, 1);
  }
`;

export const InputPNWrapper = styled.div`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  border-radius: 10px;
  box-shadow: 0 2px 14px 0 #fec8260c;
  border: solid 1px #c8c8c8;
  background-color: #ffffff80;
  outline: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  padding: 4px;
  height: 48px;

  select {
    outline: none;
    width: 92px;
    text-align: center;
    border: solid 1px rgba(0, 0, 0, 0.1);
    height: 40px;
    margin: 0 0px 0 4px;
    padding: 8px;
    border-radius: 10px;
    font-family: "Twemoji", sans-serif;

    option {
      font-family: "Twemoji", sans-serif;
    }
  }
`;
export const InputPN = styled(InputPhoneNumber)`
  width: 60%;
  font-size: 20px;
  line-height: 20px;
  padding: 8px;
  border: 0;
  outline: 0;
`;

export const Input = styled.input`
  width: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 15px 24px;
  border-radius: 10px;
  border: 1px solid rgba(147, 147, 147, 1);
  outline: 0;

  &:focus {
    border-color: rgba(254, 200, 38, 1);
  }
`;

export const InputWrapper = styled.div<{ small?: boolean; width?: string }>`
  position: relative;
  ${({ width }) => width && `width: ${width} !important`};

  &:focus-within {
    label {
      color: #222222;
    }

    &::after {
      background-color: ${styles.mainColor};
      height: 2px;
    }
  }

  label {
    display: block;
    width: fit-content;
    color: #2222227f;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    transition: all 0.3s linear;

    span.required {
      color: ${styles.mainColor};
    }
  }

  div {
    display: flex;
    align-items: center;

    img {
      width: 22px;
      height: fit-content;
      margin-right: 7px;
    }
  }

  input,
  textarea {
    display: block;
    width: 100%;
    border: 1px solid rgba(36, 39, 44, 0.2);
    border-radius: 10px;
    padding: 5px 10px;
    position: relative;
    outline: 0;
    // font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: -0.25px;
    transition: all 0.3s linear;

    &::placeholder {
      color: rgba(0, 0, 0, 0.38);
    }
    resize: none;
    &:focus {
      border: 1px solid #222222;
    }
  }
  input {
    width: ${({ small }) => (small ? "55%" : "100%")};
    height: 48px;
  }
  // &::after {
  //   content: "";
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 1px;
  //   background-color: rgba(0, 0, 0, 0.12);
  // }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  top: 28px;
  max-height: 200px;
  height: fit-content;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 10px; */
  overflow: auto;
  z-index: 99;
  box-shadow: 0 0 5px lightgray;
  border-radius: 10px;
`;

export const DropdownItem = styled.div`
  width: 100%;
  height: 28px;
  border-bottom: 1px solid lightgray;
  padding: 15px 10px;
  &:last-child {
    border-bottom: 0;
  }
`;

export const Button = styled.button<{
  width?: string;
  fit?: boolean;
  _p621?: boolean;
  _m0?: boolean;
  _m10?: boolean;
  _bg_color?: boolean;
  _mt?: number;
  _m0auto?: boolean;
  _m12auto?: boolean;
  _btn_bg_color?: boolean;
  _mauto?: boolean;
  _p20?: boolean;
  height?: string;
  proposal?: boolean;
}>`
  width: ${({ width, fit }) => (fit ? "fit-content" : width ? width : "100%")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${styles.mainColor};
  border-radius: 10px;
  border: 0;
  padding: ${({ proposal }) => (proposal ? "0 10px" : "10px 10px")};
  cursor: pointer;
  transition-duration: 5ms;
  //margin: auto;
  ${({ proposal }) => proposal && "height: 48px !important"};
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  gap: 8px;
  /* identical to box height, or 125% */
  transition: all 0.3s linear;
  text-align: center;

  color: #ffffff;

  ${({ _p621 }) => _p621 && `padding: 6px 21px`};
  ${({ _m0 }) => _m0 && `margin: 0`};
  ${({ _m10 }) => _m10 && `margin: 10px 0 0`};
  ${({ _bg_color }) => _bg_color && `background: transparent; color: #FF6633`};
  ${({ _mt }) => _mt !== undefined && `margin-top: ${_mt}px`};
  ${({ _m0auto }) => _m0auto && `margin: 0 0 0 auto`};
  ${({ _m12auto }) => _m12auto && `margin: 12px 0 0 auto`};
  ${({ _p20 }) => _p20 && "padding: 21px"};
  ${({ height }) => height && `height: ${height}`};
  ${({ _btn_bg_color }) =>
    _btn_bg_color &&
    `margin: 0 auto 0 0; background-color: transparent; font-weight: 400; color: #252424; text-decoration: underline;`}
  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  &:disabled {
    background-color: #cccccc;
    color: #666666;
  }
`;

export const SmallButton = styled(Button)`
  width: fit-content;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  padding: 8px 16px;
`;
