import React, { memo, useEffect } from "react";
import AddManagementItemEmpty from "../../components/addManagementItemEmpty";
import VehicleManagementPagesDataList from "../../components/vehicleManagementPagesDataList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getVehicleCategories,
  getVehiclesList,
} from "../../redux/store/reducers/vehicleSlice";
import { Wrapper } from "./styles";

const VehicleManagementPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const vehicleList = useAppSelector((state) => state.vehicle.vehicleList);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getVehiclesList(user?.customer?.refId));
      dispatch(getVehicleCategories());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.customer?.refId]);

  return (
    <Wrapper>
      {!!vehicleList?.length ? (
        <VehicleManagementPagesDataList vehicles />
      ) : (
        <AddManagementItemEmpty vehicles />
      )}
    </Wrapper>
  );
};

export default memo(VehicleManagementPage);
