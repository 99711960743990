import styled from "styled-components";

export const AddButton = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
`;

export const FilterText = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin-right: 20px;
`;

export const TRowTitle = styled.div`
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
  display: flex;
  align-items: flex-end;

  svg,
  img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
`;

export const DropdownWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin: auto;
`;

export const DropdownBtn = styled.div`
  cursor: pointer;
  ${({ _someStyles }: { _someStyles?: boolean }) =>
    _someStyles &&
    `
    width: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #e3e3e3;
    border-radius: 10px;
    padding: 2px;`}
`;

export const DropdownListBG = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
`;

export const DropdownList = styled.div`
  position: absolute;
  top: 30px;
  background-color: white;
  border: solid 1px #e3e3e3;
  width: 100%;
  z-index: 5;
  border-radius: 10px;
  padding: 5px 10px;
`;

export const DropdownItem = styled.label`
  width: fit-content;
  display: flex;
  align-items: center;
  user-select: none;
  margin: 5px 0;
  cursor: pointer;

  input {
    margin-right: 5px;
    accent-color: gray;
  }
`;
