import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IRegistrationSlice {
  isCompanyRegistration: boolean;
  pageToRedirect: string | null;
  workerRespond: any;
  openAppointPopUp: boolean;
  workerToAppoint: {
    refId: string;
    offerId: number | string;
    orderId: number | string;
    coordinates: [number, number];
  } | null;
}

const initialState: IRegistrationSlice = {
  isCompanyRegistration: false,
  pageToRedirect: null,
  workerRespond: null,
  workerToAppoint: null,
  openAppointPopUp: false,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    clearRegistrationSlice(state) {
      state.isCompanyRegistration = false;
      state.pageToRedirect = null;
      state.workerRespond = null;
      state.workerToAppoint = null;
    },
    setCompanyRegistration(state, action: PayloadAction<boolean>) {
      state.isCompanyRegistration = action.payload;
    },
    setPageToRedirect(state, action: PayloadAction<string | null>) {
      state.pageToRedirect = action.payload;
    },
    setOpenProposalPopUp(state, action: PayloadAction<boolean>) {
      state.openAppointPopUp = action.payload;
    },
    setWorkerRespond(state, action) {
      state.workerRespond = action.payload;
    },
    setWorkerToAppoint(
      state,
      action: PayloadAction<{
        refId: string;
        offerId: number | string;
        orderId: number | string;
        coordinates: [number, number];
      }>,
    ) {
      state.workerToAppoint = action.payload;
    },
  },
});

export const {
  clearRegistrationSlice,
  setCompanyRegistration,
  setPageToRedirect,
  setWorkerRespond,
  setWorkerToAppoint,
  setOpenProposalPopUp,
} = registrationSlice.actions;

export default registrationSlice.reducer;
