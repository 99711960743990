import React, { memo, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { DescriptionBox } from "./styles";

interface Props {
  close: any;
  onSubmit: any;
  defaultValue?: string;
  tab?: any;
}

const DescriptionPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  defaultValue,
  tab,
}) => {
  const [text, setText] = useState<string>(defaultValue || "");

  return (
    <PopUpContainer
      title="Добавить информацию"
      close={close}
      onSubmit={() => {
        onSubmit(text);
        close();
      }}
      button={"Добавить информацию"}
      disabled={!defaultValue && !Boolean(text)}
      tab={tab}
    >
      <DescriptionBox>
        <div className="text">Расскажите подробнее о задании</div>
        <textarea
          rows={6}
          placeholder={"Например, размеры или нюансы"}
          autoFocus
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              onSubmit(text);
              close();
            }
          }}
        />
      </DescriptionBox>
    </PopUpContainer>
  );
};

export default memo(DescriptionPopUp);
