import React, { memo } from "react";
import { ReactComponent as LoadingIcon } from "../../assets/icons/loading.svg";
import { LoadingBox } from "./styles";

interface Props {
  title?: string;
  description?: string;
  half?: boolean;
  noHeight?: boolean;
  height?: string;
}

const Loading: React.FC<Props> = ({
  title,
  description,
  half,
  noHeight,
  height,
}) => {
  return (
    <LoadingBox half={half} noHeight={noHeight} height={height}>
      <div className="box">
        <div className="loading">
          <LoadingIcon />
        </div>
        <div className="text">
          <div className="title">{title}</div>
          <div className="desc">{description}</div>
        </div>
      </div>
    </LoadingBox>
  );
};

export default memo(Loading);
