import axios from "axios";
import React, { memo, useCallback, useEffect, useState } from "react";
import { ENDPOINT } from "../../../constants/general";
import useHttpRequest from "../../../hooks/useHttpRequest";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getMe } from "../../../redux/store/reducers/authSlice";
import { getOrderDocs } from "../../../redux/store/reducers/orderSlice";
import { RootState } from "../../../redux/store/store";
import { getToken } from "../../../utils/uiHelpers";
import { InputBox } from "../../form";
import { IDocument, IUserInfo } from "../../infoAboutOrderContainer/Docs";
import { Highlighted } from "../../styles/styles";
import PopUpContainer from "../popUpContainer";
import UserAgreementPopUp from "../userAgreementPopUp";
import { Desc, InputsWrapper } from "./styles";
import { CustomerRole } from "../../../types";

interface TFormData {
  firstName?: string;
  secondName?: string;
  patronymic?: string;
  email?: string | null;
  phoneNumber?: string;
  role?: CustomerRole;
}

const initialState: TFormData = {
  firstName: "",
  secondName: "",
  patronymic: "",
  email: "",
  phoneNumber: "",
  role: CustomerRole.Customer,
};

const SignPopUp: React.FC<any> = ({
  close,
  loading,
  list,
  doc,
  orderId,
  setSigned,
  userInfo,
  refreshDocs,
}) => {
  const [agreement, setAgreement] = useState<boolean>(false);
  const [formData, setFormData] = useState<TFormData>(initialState);
  const [formError, setFormError] = useState<string>("");
  const [isFirstSign, setIsFirstSign] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const singleOrder = useAppSelector(
    (state: RootState) => state.order.singleOrder,
  );

  useEffect(() => {
    for (let key in userInfo) {
      if (userInfo[key] !== null) {
        setFormData((prevState) => ({
          ...prevState,
          [key]: userInfo[key],
        }));
      }
    }
  }, []);

  const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const sign = async () => {
    if (list.length > 1) {
      await list.map(async (document: IDocument) => {
        await axios.put(
          `${ENDPOINT}/orders/${user?.customer?.refId}/${orderId}/sign-an-agreement`,
          {
            fileId: document.id,
            docType: document.type,
            coordinates: [
              singleOrder?.order?.latitude,
              singleOrder?.order?.longitude,
            ],
          },
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
          },
        );
      });
      setSigned(true);

      close();
    } else {
      await axios.put(
        `${ENDPOINT}/orders/${user?.customer?.refId}/${orderId}/sign-an-agreement`,
        {
          fileId: doc.id,
          docType: doc.type,
          coordinates: [
            singleOrder?.order?.latitude,
            singleOrder?.order?.longitude,
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      );
      setSigned(true);
      close();
    }
    user?.customer?.refId &&
      dispatch(
        getOrderDocs({
          refId: user?.customer?.refId,
          orderId: orderId,
        }),
      );
  };

  const requiredError = (formData: TFormData) => {
    if (
      formData.firstName === "" ||
      formData.secondName === "" ||
      formData.patronymic === ""
    ) {
      return true;
    }

    return false;
  };

  const [updateProcess, updateRequest] = useHttpRequest({
    onSuccess: () => {
      if (user?.customer?.refId) dispatch(getMe(user?.customer?.refId));
    },
  });

  const [signProcess, signRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      if (user?.customer?.refId)
        dispatch(getOrderDocs({ refId: user.customer.refId, orderId }));
      close();
    },
    onError: (err: any) => {
      // toast.error("Что-то пошло не так при подписании документа");
    },
  });

  const submitHandler = useCallback(async () => {
    if (!requiredError(formData)) {
      let userDataUpdated: any = {};
      if (formData.email === "") formData.email = null;

      Object.entries(formData).forEach(([key, value], index) => {
        if (value) {
          if ((user?.customer as any)[key] !== value) {
            userDataUpdated[key] = value;
          }
        }
      });

      updateRequest({
        method: "PUT",
        path: `customers/${user?.customer?.refId}/edit-profile`,
        data: userDataUpdated,
      });

      await sign();
    } else setFormError("Нужно заполнить все обязательные поля");
  }, [user, formData]);

  useEffect(() => {
    !user?.customer.patronymic && setIsFirstSign(true);
  }, [user]);

  useEffect(() => {
    return setFormError("");
  }, []);

  return (
    <div>
      <PopUpContainer
        title="Ваши данные"
        button={isFirstSign ? "Подписать" : "Сохранить данные"}
        close={close}
        onSubmit={submitHandler}
        disabled={requiredError(formData)}
      >
        <InputsWrapper>
          <InputBox
            placeholder="Фамилия"
            // required
            disabled={loading === "pending"}
            // label="Фамилия"
            type="text"
            name="secondName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={formData.secondName}
          />
          <InputBox
            placeholder="Имя"
            // required
            disabled={loading === "pending"}
            // label="Имя"
            type="text"
            name="firstName"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={formData.firstName}
          />
          <InputBox
            placeholder="Отчество"
            // required
            disabled={loading === "pending"}
            // label="Отчество"
            type="text"
            name="patronymic"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={formData.patronymic}
          />
          <InputBox
            placeholder="Должность"
            disabled={loading === "pending"}
            // label="Должность"
            type="text"
            name="role"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={userInfo.role === "worker" ? "Исполнитель" : "Заказчик"}
          />
          <InputBox
            placeholder="Email"
            disabled={loading === "pending"}
            // label="Email"
            type="text"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={formData.email}
          />
          <InputBox
            placeholder="Телефон"
            disabled={loading === "pending"}
            // label="Телефон"
            type="text"
            name="phoneNumber"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeHandler(e)
            }
            value={formData.phoneNumber}
          />
        </InputsWrapper>
        <div
          style={{
            color: "red",
            padding: "5px",
            fontSize: "12px",
          }}
        >
          * обязательные к заполнению поля
        </div>
        <Desc>
          Нажимая кнопку{" "}
          {`${isFirstSign ? '"Подписать"' : '"Сохранить данные"'}`} вы
          привязываете указанные реквизиты для расчётов и подписания документов
          в сервисе Rukki и принимаете{" "}
          <Highlighted
            onClick={() => {
              setAgreement(true);
            }}
          >
            "Правила сервиса"
          </Highlighted>
        </Desc>
        {formError !== "" && (
          <div
            style={{
              color: "red",
              padding: "5px",
              border: "1px solid red",
              borderRadius: "8px",
              marginTop: "10px",
            }}
          >
            {formError}
          </div>
        )}
      </PopUpContainer>
      {agreement && <UserAgreementPopUp close={() => setAgreement(false)} />}
    </div>
  );
};

export default memo(SignPopUp);
