import React from "react";
import PopUpContainer from "../popUpContainer";

const ConfidentialityPopUp: React.FC<{ close: any }> = ({ close }) => {
  return (
    <PopUpContainer big close={close}>
      <div style={{ padding: "0 10px" }}>
        <p style={{ textAlign: "center" }}>
          <strong>ПОЛИТИКА</strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <strong>В ОТНОШЕНИИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </p>
        <br />
        <p>
          <strong>1. ОБЩИЕ ПОЛОЖЕНИЯ</strong>
        </p>

        <br />

        <p>
          1.1. &nbsp; Настоящий документ определяет политику (далее &ndash;
          &laquo;Политика&raquo;) Общества с ограниченной ответственностью
          &laquo;РУККИ&raquo; ОГРН 1227700503964 (далее &ndash;
          &laquo;Общество&raquo;) в отношении обработки персональных данных.
        </p>
        <p>
          1.2. &nbsp; Настоящая Политика разработана и утверждена Обществом в
          соответствии с требованиями статьи 18.1 Федерального закона от
          27.07.2006 г. №152-ФЗ &laquo;О персональных данных&raquo; (далее
          &ndash; &laquo;Закон&raquo;) и действует в отношении всех персональных
          данных, обрабатываемых в Обществе.&nbsp;
        </p>
        <p>
          1.3. &nbsp; Целью настоящей Политики является обеспечение защиты прав
          и свобод человека и гражданина при обработке его персональных данных,
          а также интересов Общества.&nbsp;
        </p>
        <p>
          1.4. &nbsp; Настоящая Политика определяет цели, принципы и условия
          обработки персональных данных сотрудников и иных лиц, чьи персональные
          данные обрабатываются Обществом, а также включает перечень мер,
          применяемых в целях обеспечения безопасности персональных данных при
          их обработке.
        </p>
        <p>
          1.5. &nbsp; Политика распространяется на отношения в области обработки
          персональных данных, возникшие у Общества как до, так и после
          утверждения настоящей Политики.
        </p>
        <p>
          1.6. &nbsp; Во исполнение требований ч. 2 ст. 18.1 Закона настоящая
          Политика публикуется в свободном доступе в
          информационно-телекоммуникационной сети Интернет на Сайте
        </p>
        <p>
          1.7. &nbsp; Правовым основанием обработки персональных данных является
          совокупность нормативных правовых актов, во исполнение которых и в
          соответствии с которыми Общество осуществляет обработку персональных
          данных.
        </p>
        <p>
          1.8. &nbsp; Использование сервисов Сайта означает безоговорочное
          согласие Пользователя с настоящей Политикой и согласие Пользователя на
          обработку любой информации, в том числе необходимой для корректной
          работы и/или сбора статистики использования сервисов Сайта (в том
          числе cookie) или предоставляемой Пользователем самостоятельно,
          включая Персональные данные, а также согласие на получение им
          информационных (рекламных) оповещений. Указанные оповещения могут быть
          предоставлены с применением неголосовых коммуникаций (путем рассылки
          по сети подвижной радиотелефонной связи коротких текстовых
          sms-сообщений, рассылки ussd-сообщений и др.), посредством направления
          сведений по информационно-телекоммуникационной сети
          &laquo;Интернет&raquo; на предоставленный Пользователем номер телефона
          и (или) адрес электронной почты.&nbsp;
        </p>
        <p>
          Пользователь подтверждает, что, давая такое согласие, действует по
          собственной воле и в своих интересах.
        </p>
        <p>
          <br />
        </p>
        <p>
          <strong>
            2.&nbsp;ОСНОВНЫЕ ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ, ИСПОЛЬЗУЕМЫЕ В ПОЛИТИКЕ
          </strong>
        </p>
        <br />
        <p>
          <strong>
            2.1.&nbsp; Автоматизированная обработка персональных данных
          </strong>{" "}
          - обработка персональных данных с помощью средств вычислительной
          техники.
        </p>
        <p>
          <strong>2.2.&nbsp; Биометрические персональные данные</strong> -
          сведения, которые характеризуют физиологические и биологические
          особенности человека, на основании которых можно установить его
          личность и которые используются оператором для установления личности
          субъекта персональных данных.
        </p>
        <p>
          <strong>2.3.&nbsp;Блокирование персональных данных</strong> -
          временное прекращение обработки персональных данных (за исключением
          случаев, если обработка необходима для уточнения персональных данных).
        </p>
        <p>
          <strong>2.4.&nbsp;Безопасность персональных данных</strong> -
          состояние защищенности персональных данных, характеризуемое
          способностью Пользователей, технических средств и информационных
          технологий обеспечить конфиденциальность, целостность и доступность
          персональных данных при их обработке в информационных системах
          персональных данных.
        </p>
        <p>
          <strong>2.5.&nbsp;Информационная система персональных данных</strong>{" "}
          - совокупность баз персональных данных, информационных технологий и
          технических средств, позволяющих осуществлять обработку таких
          персональных данных с использованием средств автоматизации или без
          таковых.
        </p>
        <p>
          <strong>2.6.&nbsp;Конфиденциальность персональных данных</strong> -
          обязательное для соблюдения Обществом или иным получившим доступ к
          персональным данным лицом требование не допускать их распространение
          без согласия Пользователя или наличия иного законного основания.&nbsp;
        </p>
        <p>
          <strong>2.7.&nbsp;Обработка персональных данных -&nbsp;</strong>
          любое действие (операция) или совокупность действий (операций) с
          персональными данными, совершаемых с использованием средств
          автоматизации или без их использования. Обработка персональных данных
          включает в себя в том числе:
          <ul style={{ paddingLeft: "40px" }}>
            <li>сбор;</li>
            <li>запись;</li>
            <li>систематизацию;</li>
            <li>накопление;</li>
            <li>хранение;</li>
            <li>уточнение (обновление, изменение);</li>
            <li>извлечение;</li>
            <li>использование;</li>
            <li>передачу (распространение, предоставление, доступ);</li>
            <li>обезличивание;</li>
            <li>блокирование;</li>
            <li>удаление;</li>
            <li>уничтожение;</li>
          </ul>
        </p>
        <p>
          <strong>2.8.&nbsp;Общедоступные персональные данные</strong> -
          персональные данные, доступ неограниченного круга лиц к которым
          предоставлен с согласия Пользователя или на которые в соответствии с
          федеральными законами не распространяется требование соблюдения
          конфиденциальности.
        </p>
        <p>
          <strong>2.9.&nbsp;Обезличивание персональных данных</strong> -
          действия, в результате которых становится невозможным без
          использования дополнительной информации определить принадлежность
          Персональных данных конкретному Пользователю.&nbsp;
        </p>
        <p>
          <strong>2.10.&nbsp;Предоставление персональных данных</strong> -
          действия, направленные на раскрытие персональных данных определенному
          лицу или определенному кругу лиц.&nbsp;
        </p>
        <p>
          <strong>2.11.&nbsp;Персональные данные</strong> - любая информация,
          относящаяся к прямо или косвенно определенному, или определяемому
          физическому лицу (субъекту персональных данных - Пользователю).
        </p>
        <p>
          <strong>2.12.&nbsp;Пользователь&nbsp;</strong>&ndash; лицо, пользующее
          и/или намеревающееся использовать Сайт, являющееся субъектом
          Персональных данных;
        </p>
        <p>
          <strong>2.13.&nbsp;Распространение персональных данных</strong> -
          действия, направленные на раскрытие персональных данных
          неопределенному кругу лиц.
        </p>
        <p>
          <strong>2.14.&nbsp;Сайт&nbsp;</strong>- совокупность программ для
          электронных вычислительных машин и иной информации, доступ к которой
          обеспечивается посредством информационно-телекоммуникационной сети
          &laquo;Интернет&raquo; [asterus-development.com]
        </p>
        <p>
          <strong>
            2.15.&nbsp;Трансграничная передача персональных данных
          </strong>{" "}
          - передача персональных данных на территорию иностранного государства
          органу власти иностранного государства, иностранному физическому лицу
          или иностранному юридическому лицу.&nbsp;
        </p>
        <p>
          <strong>2.16.&nbsp;Уничтожение персональных данных</strong> -
          действия, в результате которых становится невозможным восстановить
          содержание персональных данных в информационной системе персональных
          данных и/или в результате которых уничтожаются материальные носители
          персональных данных.&nbsp;
        </p>
        <br />

        <p>
          <strong>3. &nbsp;ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </p>

        <br />
        <p>
          <strong>3.1.</strong>&nbsp; Обработка персональных данных
          ограничивается достижением конкретных, заранее определенных и законных
          целей. Не допускается обработка персональных данных, несовместимая с
          целями сбора персональных данных.
        </p>
        <p>
          <strong>3.2.</strong>&nbsp; Обработке подлежат только персональные
          данные, которые отвечают целям их обработки.
        </p>
        <p>
          <strong>3.3.</strong>&nbsp; Общество осуществляет обработку
          персональных данных в целях:&nbsp;
        </p>
        <ul style={{ paddingLeft: "40px" }}>
          <li>
            обеспечения соблюдения Конституции Российской Федерации,
            законодательных и иных нормативных правовых актов Российской
            Федерации, локальных нормативных актов Общества;&nbsp;
          </li>
          <li>
            заключения с Пользователем любых договоров и соглашений, и их
            дальнейшего исполнения;&nbsp;
          </li>
          <li>осуществления своей деятельности;&nbsp;</li>
          <li>
            предоставления информации об Обществе, его услугах, акциях и
            мероприятиях;&nbsp;
          </li>
          <li>коммуникации с Пользователем;&nbsp;</li>
          <li>
            направления Пользователю новостных и/или рекламных материалов;
          </li>
          <li>
            обеспечения функционирования, безопасности и улучшения качества
            Сайта;
          </li>
          <li>осуществления пропускного режима;</li>
          <li>
            а также других целей, достижение которых не запрещено федеральным
            законодательством, международными договорами Российской
            Федерации.&nbsp;
          </li>
        </ul>
        <p>
          3.4.&nbsp; Обработка персональных данных Пользователей может
          осуществляться исключительно в целях обеспечения соблюдения законов и
          иных нормативных правовых актов.
        </p>
        <p>
          3.5.&nbsp; Обработка Персональных данных Обществом осуществляется на
          основе следующих принципов:&nbsp;
        </p>
        <ul style={{ paddingLeft: "40px" }}>
          <li>
            законности целей и способов обработки Персональных данных;&nbsp;
          </li>
          <li>
            соответствия целей обработки Персональных данных целям, заранее
            определенным и заявленным при сборе Персональных данных;&nbsp;
          </li>
          <li>
            соответствия состава и объема обрабатываемых Персональных данных, а
            также способов обработки Персональных данных заявленным целям
            обработки;&nbsp;
          </li>
          <li>
            достоверности Персональных данных, их достаточности для целей
            обработки, недопустимости обработки Персональных данных, избыточных
            по отношению к целям, заявленным при сборе Персональных
            данных;&nbsp;
          </li>
          <li>
            недопустимости обработки Персональных данных, несовместимых с целями
            сбора Персональных данных;&nbsp;
          </li>
          <li>
            обеспечения хранения Персональных данных не дольше, чем этого
            требуют цели Обработки персональных данных, если срок хранения
            Персональных данных не установлен действующим законодательством или
            соглашением между Пользователем и Обществом;&nbsp;
          </li>
          <li>
            уничтожения или обезличивания Персональных данных по достижении
            целей обработки или в случае утраты необходимости в достижении этих
            целей, если иное не установлено действующим законодательством или
            соглашением между Пользователем и Обществом.
          </li>
        </ul>
        <br />
        <p>
          <strong>
            4. &nbsp;ОБЪЕМ И КАТЕГОРИИ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </strong>
        </p>
        <br />
        <p>
          4.1.&nbsp; Обработка Персональных данных ограничивается достижением
          конкретных, заранее определенных и законных целей. Не допускается
          обработка Персональных данных, несовместимая с целями сбора
          Персональных данных.
        </p>
        <p>
          4.2.&nbsp; Общество может обрабатывать следующие Персональные данные
          Пользователя:
        </p>
        <ul style={{ paddingLeft: "40px" }}>
          <li>фамилия, имя, отчество;</li>
          <li>
            контактные данные (включая, но не ограничиваясь: телефонный номер,
            адрес электронной почты, идентификаторы в социальных сетях и т.п.);
          </li>
          <li>иные персональные данные, предоставляемые Пользователем.</li>
        </ul>
        <p>
          4.3.&nbsp; Обществом не осуществляется обработка специальных категорий
          персональных данных, касающихся расовой, национальной принадлежности,
          политических взглядов, религиозных или философских убеждений,
          состояния здоровья, интимной жизни, за исключением случаев,
          предусмотренных законодательством РФ.
        </p>
        <br />
        <p>
          <strong>5. &nbsp;УСЛОВИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</strong>
        </p>
        <br />
        <p>
          5.1.&nbsp; Обработка персональных данных осуществляется с соблюдением
          принципов и правил, установленных Законом.
        </p>
        <p>
          5.2.&nbsp; Общество осуществляет Обработку персональных данных, как с
          использованием средств автоматизации, так и без использования средств
          автоматизации (автоматизированная и/или неавтоматизированная Обработка
          персональных данных).&nbsp;
        </p>
        <p>
          5.3.&nbsp; Общество может включать Персональные данные Пользователя в
          общедоступные источники Персональных данных, при этом Общество берет
          письменное согласие Пользователя на Обработку персональных данных.
        </p>
        <p>
          5.4.&nbsp; Биометрические персональные данные в Обществе не
          обрабатываются.&nbsp;
        </p>
        <p>
          5.5.&nbsp; Общество не осуществляет Трансграничную передачу
          персональных данных.&nbsp;
        </p>
        <p>
          5.6.&nbsp; К обработке персональных данных допускаются работники
          Общества, в должностные обязанности которых входит обработка
          персональных данных.
        </p>
        <p>
          5.7.&nbsp; Обработка персональных данных осуществляется путем:
          <div style={{ display: "flex" }}>
            <p>5.7.1.&nbsp;</p>
            <p>
              получения Персональных данных в устной и письменной форме
              непосредственно от Пользователя;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>5.7.2.&nbsp;</p>
            <p>получения Персональных данных из общедоступных источников;</p>
          </div>
          <div style={{ display: "flex" }}>
            <p>5.7.3.&nbsp;</p>
            <p>
              внесения/предоставления Персональных данных Пользователем по
              средствам сервисов Сайта и/или внесения/предоставления
              Персональных данных Пользователем в журналы, реестры и иные
              информационные системы Общества;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>5.7.4.&nbsp;</p>
            <p>использования иных способов Обработки персональных данных.</p>
          </div>
        </p>
        <p>
          5.8.&nbsp; Не допускается раскрытие третьим лицам и распространение
          Персональных данных без согласия Пользователя, если иное не
          предусмотрено действующим законодательством
        </p>
        <p>
          5.9.&nbsp; При отсутствии необходимости письменного согласия
          Пользователя на обработку его Персональных данных согласие может быть
          дано Пользователем или его представителем в любой позволяющей получить
          факт его получения форме, в том числе согласие считается данным в
          случае использования Пользователем сервисов Сайта;
        </p>
        <p>
          5.10.&nbsp; Общество вправе поручить обработку Персональных данных
          другому лицу с согласия Пользователя, если иное не предусмотрено
          действующим законодательством, на основании заключаемого с этим лицом
          договора. При этом Общество в договоре обязывает лицо, осуществляющее
          обработку Персональных данных по поручению Общества, соблюдать
          принципы и правила обработки персональных данных, предусмотренные
          Законом.&nbsp;
        </p>
        <p>
          5.11.&nbsp; Предоставление доступа органам государственной власти (в
          том числе, контролирующих, надзорных, правоохранительных и иных
          органов) к Персональным данным, обрабатываемым Обществом
          осуществляется в объёме и порядке, установленным соответствующим
          законодательством.&nbsp;
        </p>
        <br />
        <p>
          <strong>
            6.&nbsp; ПРАВА И ОБЯЗАННОСТИ ПОЛЬЗОВАТЕЛЯ (СУБЪЕКТА ПЕРСОНАЛЬНЫХ
            ДАННЫХ)
          </strong>
        </p>
        <br />
        <p>
          <strong>6.1.&nbsp;</strong>
          Пользователь имеет право:
          <div style={{ display: "flex" }}>
            <p>6.1.1.&nbsp;</p>
            <p>
              получать информацию, касающуюся обработки его Персональных данных,
              в порядке, форме и сроки, установленные законодательством;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>6.1.2.&nbsp;</p>
            <p>
              требовать уточнения своих Персональных данных, их блокирования и
              уничтожения в случае, если Персональные данные являются неполными,
              устаревшими, неточными, незаконно полученными или не являются
              необходимыми для заявленной цели обработки;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>6.1.3.&nbsp;</p>
            <p>
              принимать предусмотренные законом меры по защите своих прав;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>6.1.4.&nbsp;</p>
            <p>
              отозвать свое Согласие на обработку персональных данных, направив
              письменное уведомление об этом Обществу;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>6.1.5.&nbsp;</p>
            <p>пользоваться всеми имеющимися на Сайте сервисами;</p>
          </div>
          <div style={{ display: "flex" }}>
            <p>6.1.6.&nbsp;</p>
            <p>
              пользоваться Сайтом исключительно в целях и порядке,
              предусмотренных Соглашением и не запрещенных законодательством
              Российской Федерации.
            </p>
          </div>
        </p>
        <p>
          6.2.&nbsp; Пользователь (субъект Персональных данных) обязан
          предоставить полные, точные и достоверные сведения о своих
          Персональных данных.&nbsp;
        </p>
        <br />
        <p>
          <strong>
            7. &nbsp; ПРАВА И ОБЯЗАННОСТИ ОБЩЕСТВА ПРИ ОБРАБОТКЕ ПЕРСОНАЛЬНЫХ
            ДАННЫХ
          </strong>
        </p>
        <br />
        <p>
          <strong>7.1.&nbsp;</strong>
          Общество имеет право:&nbsp;
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.1.&nbsp;</strong>
            </p>
            <p>
              обрабатывать Персональные данные Пользователя в соответствии с
              заявленной целью;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.2.&nbsp;</strong>
            </p>
            <p>
              требовать от Пользователя предоставления достоверных Персональных
              данных, необходимых для заключения, исполнения договора, оказания
              услуги, идентификации субъекта персональных данных, а также в иных
              случаях, предусмотренных законодательством о персональных
              данных;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.3.&nbsp;</strong>
            </p>
            <p>
              ограничить доступ Пользователя к его Персональным данным в
              случаях, предусмотренных законодательством Российской
              Федерации;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.4.&nbsp;</strong>
            </p>
            <p>
              обрабатывать общедоступные Персональные данные физических
              лиц;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.5.&nbsp;</strong>
            </p>
            <p>
              осуществлять обработку Персональных данных, подлежащих
              опубликованию или обязательному раскрытию в соответствии с
              законодательством Российской Федерации;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.6.&nbsp;</strong>
            </p>
            <p>
              уточнять обрабатываемые Персональные данные, блокировать или
              удалять, если Персональные данные являются неполными, устаревшими,
              неточными, незаконно полученными или не являются необходимыми для
              заявленной цели обработки;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>
              <strong>7.1.7.&nbsp;</strong>
            </p>
            <p>
              поручить обработку Персональных данных другому лицу с согласия
              Пользователя.
            </p>
          </div>
        </p>
        <p>
          7.2.&nbsp; В соответствии с требованиями Закона Общество
          обязано:&nbsp;
          <div style={{ display: "flex" }}>
            <p>7.2.1.&nbsp;</p>
            <p>
              предоставлять Пользователю по его запросу информацию, касающуюся
              обработки его Персональных данных, либо на законных основаниях
              предоставить отказ;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>7.2.2.&nbsp;</p>
            <p>
              по требованию Пользователя уточнять обрабатываемые Персональные
              данные, блокировать или удалять, если Персональные данные являются
              неполными, устаревшими, неточными, незаконно полученными или не
              являются необходимыми для заявленной цели обработки;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>7.2.3.&nbsp;</p>
            <p>вести учет обращений Пользователей;&nbsp;</p>
          </div>
          <div style={{ display: "flex" }}>
            <p>7.2.4.&nbsp;</p>
            <p>
              уведомлять Пользователя об Обработке персональных данных в том
              случае, если Персональные данные были получены не от Пользователя
              за исключением предусмотренных законом Российской Федерации
              случаев;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>7.2.5.&nbsp;</p>
            <p>
              в случае достижения цели обработки персональных данных прекратить
              Обработку персональных данных и уничтожить соответствующие
              Персональные данные в срок, не превышающий тридцати дней с даты
              достижения цели обработки Персональных данных, если иное не
              предусмотрено соглашением с Пользователем;&nbsp;
            </p>
          </div>
          <div style={{ display: "flex" }}>
            <p>7.2.6.&nbsp;</p>
            <p>
              в случае отзыва Пользователем согласия на обработку своих
              Персональных данных прекратить Обработку персональных данных и
              уничтожить персональные данные в срок, не превышающий тридцати
              дней с даты поступления указанного отзыва, если иное не
              предусмотрено соглашением с Пользователем.&nbsp;
            </p>
          </div>
        </p>
        <br />
        <p>
          <strong>
            8. &nbsp; МЕРЫ ПО ОБЕСПЕЧЕНИЮ БЕЗОПАСНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ
          </strong>
        </p>
        <br />
        <p>
          <strong>8.1. &nbsp;</strong>
          При Обработке персональных данных Общество принимает необходимые
          правовые, организационные и технические меры для защиты Персональных
          данных от неправомерного или случайного доступа к ним, уничтожения,
          изменения, блокирования, копирования, предоставления, распространения
          персональных данных, а также от иных неправомерных действий в
          отношении персональных данных.
        </p>
        <br />
        <p>
          <strong>9. &nbsp;ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</strong>
        </p>
        <br />
        <p>
          <strong>9.1. &nbsp;</strong>
          Настоящая Политика является внутренним документом Общества, является
          общедоступной и подлежит размещению на официальном Сайте.
        </p>
        <p>
          <strong>9.2. &nbsp;</strong>
          Общество имеет право вносить изменения в настоящую Политику без
          дополнительных уведомлений. Нововведения вступают в силу с момента их
          опубликования.
        </p>
        <p>
          9.3. &nbsp; Пользователи могут отслеживать изменения в Политике
          конфиденциальности самостоятельно.
        </p>
        <p>
          9.4. &nbsp; К настоящей Политике и отношениям между Пользователем и
          Обществом, возникающим в связи с применением Политики, подлежит
          применению право Российской Федерации.
        </p>
      </div>
    </PopUpContainer>
  );
};

export default ConfidentialityPopUp;
