import styled from "styled-components";

export const PopUpWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.64);
  z-index: 1000;
  padding: 164px 24px 0 24px;

  @media screen and (max-width: 480px) {
    padding: 0;
    display: flex;
    align-items: flex-end;
  }
`;

export const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-radius: 20px;
  padding-bottom: 16px;
  //padding: 35px 24px 24px;

  @media screen and (max-width: 480px) {
    border-radius: 20px 20px 0 0;
  }

  .cancelButton {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #f63;
    margin: 16px 24px;
    display: none;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      display: flex;
    }
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 35px 24px 52px 24px;
    border-bottom: 1px solid #999999;

    .row {
      display: flex;
      gap: 40px;
      flex-wrap: wrap;

      .label {
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.07;
        letter-spacing: -0.25px;
        color: #000;
        height: 32px;
        display: flex;
        align-items: center;
        width: 178px;
      }
    }

    @media screen and (max-width: 480px) {
      gap: 24px;
      padding: 0 24px 24px;
      .row {
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .buttons {
    display: flex;
    padding: 16px 24px 0 24px;
    justify-content: flex-end;
    gap: 16px;

    @media screen and (max-width: 480px) {
      justify-content: center;

      div:first-child {
        display: none;
      }
      div:last-child {
        width: 296px !important;
      }
    }
  }
`;
