import styled from "styled-components";
import { SmallTitle, SmallTitleSameHeight } from "../styles/styles";

export const WorkDetailWrapper = styled.div`
  width: 360px;
  position: absolute;
  top: 20px;
  right: 40px;
  padding: 24px 20px;
  box-shadow: 0 2px 10px rgba(25, 25, 25, 0.05);
  border-radius: 10px;
  z-index: 10;
  overflow: auto;
  max-height: calc(100% - 40px);
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(8px);

  @media only screen and (max-width: 560px) {
    max-width: 360px;
    width: 95%;
    bottom: 60px;
    top: inherit;
    left: 50%;
    transform: translate(-50%, 0px);
    position: fixed;
    overflow: auto;
    max-height: calc(100vh - 130px);
    height: fit-content;
  }
`;

export const TransparentBack = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: red;
`;

export const Title = styled(SmallTitle)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  .icon {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    object-fit: cover;
    object-position: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    width: calc(100% - 32px);
  }
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const Price = styled(SmallTitleSameHeight)`
  margin-bottom: 16px;
`;

export const DeadLine = styled.div`
  margin-bottom: 20px;

  .title {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .date {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }
`;

export const Desc = styled.div`
  margin-bottom: 24px;

  .name {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 2px;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 10px 24px;
    margin: 0;
    cursor: pointer;
  }
`;

export const ImagesWrapper = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 24px;
`;

export const ImgItem = styled.div`
  padding-top: 100%;
  position: relative;
  border-radius: 10px;

  .item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 7px;
    }

    .cover {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #ffffff;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(34, 34, 34, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px;
    }
  }
`;

export const CloseBtn = styled.button`
  display: block;
  border: 0;
  background-color: transparent;
`;
