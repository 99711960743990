import styled from "styled-components";

export const OrderWrapper = styled.div`
  width: 100%;
  /* height: calc(100% - 0px); //calc(100% - 50px) */
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2); */
  position: relative;
  /* overflow: auto; */
`;

export const DataWrapper = styled.div`
  /* padding: 20px 20px 16px; */
`;

export const Title = styled.h1`
  margin: 0 0 4px;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: -0.5px;
`;

export const SubTitle = styled.div`
  margin: 4px 0 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
`;

export const OrderName = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 0.83;
  letter-spacing: -0.1px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    margin-right: 4px;
    width: 24px;
    height: 24px;
  }
`;

export const BudgetDeadline = styled.div`
  display: flex;
  gap: 22px 0;
  flex-wrap: wrap;
  margin-bottom: 16px;
`;

export const TitleValue = styled.div`
  width: 50%;
  .title {
    font-size: 16px;
    line-height: 1.25;
    margin-bottom: 7px;
  }
  .value {
    font-size: 15px;
    line-height: 1.07;
  }
`;

export const Desc = styled.div`
  margin-bottom: 16px;
  .title {
    font-size: 16px;
    line-height: 1.25;
  }
  .text {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
`;

export const Address = styled(Desc)`
  margin: 0;
`;

export const MapWrapper = styled.div<{ dataHeight: number }>`
  height: calc(100% - ${({ dataHeight }) => dataHeight || 0}px);
`;

export const OrdersListWrapper = styled.div`
  margin-top: 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 10px 20px; */
`;

export const OrderItem = styled.div`
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #fcfcfc;
  cursor: pointer;
`;

export const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
  flex-wrap: wrap;
  gap: 5px 0;
  svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
  .text {
    width: calc(100% - 142px);
    min-width: fit-content;
    /* max-width: calc(100% - 150px); */
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.83;
    letter-spacing: -0.1px;
    margin-right: 10px;
  }
  .date {
    width: 100px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    color: var(--color-black);
    white-space: nowrap;
  }
`;

export const BodyPart = styled.div`
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  margin-bottom: 33px;
  div {
    margin-bottom: 3px;
  }
`;

export const FooterPart = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
  .offer {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    img {
      width: 24px;
      height: 24px;
      margin-right: 5px;
    }
  }
  .status {
    display: flex;
    align-items: center;
    svg {
      margin-right: 4px;
    }
  }
  .process {
    padding: 4px 14px 4px 8px;
    border-radius: 10px;
    border: solid 1px var(--color-green);
    font-size: 15px;
    line-height: 1.07;
    letter-spacing: -0.25px;
  }
  .not-active {
    font-size: 13px;
    text-align: center;
    color: rgba(153, 153, 153, 0.8);
    padding: 7px 11px 9px;
    border-radius: 10px;
    border: solid 1px #e3e3e3;
  }
`;
