import React, { memo } from "react";
import PopUpContainer from "../popUpContainer";

const DownloadDisabled: React.FC<{
  close: () => void;
}> = ({ close }) => {
  const onSubmit = () => {
    close();
  };

  return (
    <PopUpContainer
      title={"Скачивание недоступно"}
      close={close}
      onSubmit={onSubmit}
      button={"Закрыть"}
    >
      Возможность скачивания мобильного приложения временно ограничена,
      попробуйте позже.
    </PopUpContainer>
  );
};

export default memo(DownloadDisabled);
