import React, { memo, useEffect, useState } from "react";
import WorkItem from "../../../components/workItem";
import {
  CommonPagination,
  Wrapper,
  CreateOrderFormBox,
  OrdersList,
  Title,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store/store";
import {
  getMyOrders,
  setOrdersFilter,
} from "../../../redux/store/reducers/orderSlice";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Empty } from "antd";
import { ListButton } from "../styles";
import { ReactComponent as MapIcon } from "../../../assets/icons/24-maps-map.svg";
// import { Pagination } from "../SearchOrderPage";
import { getWord } from "../../../utils/getWord";
import Pagination, { PaginationType } from "../../../UI/Pagination";
import PageHeader from "../../../UI/PageHeader";
import { OrdersFilter } from "../../myOrdersPage/styles";
import OrdersEmptyTag from "../../../components/ordersEmptyTag";

interface ResultComponentProps {
  handleSearchOrders: (take: number, skip: number) => void;
  pagination: PaginationType;
  setPagination: React.Dispatch<React.SetStateAction<PaginationType>>;
}

type OrdersFilter =
  | "all"
  | "archived"
  | "in_progress"
  | "canceled"
  | "completed";

const ResultComponent: React.FC<ResultComponentProps> = ({
  handleSearchOrders,
  pagination,
  setPagination,
}) => {
  const ordersFilter = useAppSelector((state) => state.order.ordersFilter);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [filter, setFilter] = useState<OrdersFilter>(ordersFilter);
  const [searchParams] = useSearchParams();
  const { orderList, myOrders, isFetching, myOrdersTotal, totalFoundOrders } =
    useAppSelector((state: RootState) => state.order);

  const user = useAppSelector((state: RootState) => state.auth.user);

  const renderFilters = () => {
    const filters = [
      { label: "Активные", value: "in_progress" },
      { label: "Завершенные", value: "completed" },
      { label: "Архивные", value: "archived" },
    ];

    return filters.map(({ label, value }, index) => {
      return (
        <div
          key={`filter_${index}`}
          onClick={() => setFilter(value as OrdersFilter)}
          className={`filterItem${value === filter ? " active" : ""}`}
        >
          {label}
        </div>
      );
    });
  };

  const renderOrders = () => {
    return (
      searchParams.get("flag") === "my"
        ? myOrders
        : searchParams.get("flag") === "all"
        ? orderList
        : []
    )?.map((order: any, index: number) => {
      return (
        <WorkItem
          my={searchParams.get("flag") === "my"}
          {...order}
          key={`ord${index}`}
          index={index + 1}
          categoryId={order.categoryId}
          handleFetchOrders={handleFetchOrders}
          isFetching={isFetching}
          handleSearchOrders={handleSearchOrders}
          pagination={pagination}
        />
      );
    });
  };

  const handleFetchOrders = (take: number, skip: number) => {
    if (isFetching || !user?.customer?.refId) return;
    if (searchParams.get("flag") === "my") {
      dispatch(getMyOrders({ take, skip, refId: user.customer.refId, filter }));
    } else {
      handleSearchOrders(take, skip);
    }

    setPagination({
      take: take,
      skip: skip,
    });
  };

  useEffect(() => {
    if (pagination.take === 50) {
      setPagination({ ...pagination, take: 10 });
    } else {
      if (user?.customer && !isFetching) {
        dispatch(
          getMyOrders({
            refId: user?.customer?.refId,
            ...pagination,
            filter,
          }),
        );
      }
    }
  }, [user, pagination, filter]);

  useEffect(() => {
    //@ts-ignore
    dispatch(setOrdersFilter(filter));
  }, [filter]);

  // if (searchParams.get("flag") === "my" && !myOrders.length) {
  //   return <OrdersEmptyTag />;
  // }

  return (
    <Wrapper>
      {searchParams.get("flag") === "my" ? (
        <PageHeader width="974px">
          Мои работы <OrdersFilter>{renderFilters()}</OrdersFilter>
        </PageHeader>
      ) : (
        <Title>
          <div className="titleText">
            {`${totalFoundOrders ? totalFoundOrders : 0}`}
            {` ${getWord(myOrdersTotal, "заказ")}` + " по вашим параметрам"}
          </div>
          <ListButton
            map
            onClick={() => {
              navigate("/search-order");
            }}
          >
            <MapIcon />
            Показать на карте
          </ListButton>
        </Title>
      )}

      <OrdersList style={{ paddingBottom: myOrdersTotal ? "24px" : "0px" }}>
        {searchParams.get("flag") === "my" && (
          <div className="filterMobileContainer">
            <OrdersFilter>{renderFilters()}</OrdersFilter>
          </div>
        )}
        {searchParams.get("flag") === "my" ? (
          myOrdersTotal ? (
            renderOrders()
          ) : (
            <OrdersEmptyTag
              header={
                filter === "completed"
                  ? "завершенных"
                  : filter === "archived"
                  ? "архивных"
                  : "активных"
              }
            />
          )
        ) : (
          renderOrders()
        )}
        {searchParams.get("flag") === "all" && orderList.length === 0 && (
          <Empty description="Нет работы" />
        )}
      </OrdersList>
      {((searchParams.get("flag") === "my" && myOrdersTotal > 10) ||
        (searchParams.get("flag") === "all" &&
          Number(totalFoundOrders) > 10)) && (
        <Pagination
          listLength={
            searchParams.get("flag") === "my"
              ? myOrdersTotal
              : totalFoundOrders || 0
          }
          onClick={handleFetchOrders}
        />
      )}
    </Wrapper>
  );
};

export default memo(ResultComponent);

