import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { BarList, Input, PaymentBox } from "./styles";
import { ReactComponent as MirCard } from "../../../assets/icons/mir-card.svg";
import { ReactComponent as SberPay } from "../../../assets/icons/visa.svg";
import { InputNumber } from "antd";

interface Props {
  close?: any;
  onSubmit?: any;
  role?: "customer" | "worker";
  tab?: any;
  defaultValue?: any;
}

const PaymentAmountPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  role,
  tab,
  defaultValue,
}) => {
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    !isNaN(defaultValue.amount) && setAmount(defaultValue.amount);
  }, [defaultValue]);

  const inputChangeHandler = (value: string) => {
    const valueRegEx = new RegExp("^[0-9 ]+$");
    if ((valueRegEx.test(value) && value.length <= 12) || value === "") {
      setAmount(Number(value.split(" ").join("")));
    }
  };

  return (
    <PopUpContainer
      title={"Планируемый бюджет"}
      close={close}
      button={"Продолжить"}
      onSubmit={() => {
        onSubmit({ amount: amount ? amount : null });
        close();
      }}
      disabled={amount === 0}
      tab={tab}
      boxTab={tab}
    >
      <PaymentBox>
        <Input
          placeholder="Бюджет"
          value={`${amount}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
          onChange={(e) => inputChangeHandler(e.target.value)}
          autoFocus
        />
        <BarList>
          <div
            className={`barItem ${amount === 10000 ? "active" : ""}`}
            onClick={() => {
              setAmount(10000);
            }}
          >
            10 000
          </div>
          <div
            className={`barItem ${amount === 20000 ? "active" : ""}`}
            onClick={() => setAmount(20000)}
          >
            20 000
          </div>
          <div
            className={`barItem ${amount === 50000 ? "active" : ""}`}
            onClick={() => setAmount(50000)}
          >
            50 000
          </div>
          <div
            className={`barItem ${amount === 100000 ? "active" : ""}`}
            onClick={() => setAmount(100000)}
          >
            100 000
          </div>
        </BarList>
        <div className="description">
          Окончательная цена будет сформирована при выборе исполнителя
        </div>
      </PaymentBox>
    </PopUpContainer>
  );
};

export default PaymentAmountPopUp;
