import React, { memo, useEffect, useRef, useState } from "react";
import {
  DatasetBox,
  DatasetList,
  InputBox,
  InputDatasetWrapper,
  ListTitle,
} from "./styles";
import { ReactComponent as Back } from "../../../assets/icons/Back.svg";
import { ReactComponent as Close } from "../../../assets/icons/Close.svg";
import { useAppSelector } from "../../../redux/hooks";
import { RootState } from "../../../redux/store/store";
import { DebounceInput } from "react-debounce-input";
import PopUpContainer from "../popUpContainer";

interface Props {
  setValue: any;
  close: any;
  list: any;
  search?: any;
  setLocation?: any;
  category?: any;
  defaultValue?: any;
  onChange?: any;
  tab?: any;
  onFocus?: any;
  onClick?: any;
  type?: "vehicle" | "city";
  setVehicleInputIcon?: any;
  isAddress?: boolean;
  wrapperClass?: string;
  noSearchResult?: boolean;
}

const SearchVehiclePopUp: React.FC<Props> = ({
  setValue,
  close,
  list,
  search,
  category,
  defaultValue,
  onChange,
  tab,
  type,
  setVehicleInputIcon,
  isAddress,
  wrapperClass,
  noSearchResult,
}) => {
  const searchByTagResult = useAppSelector(
    (state: RootState) => state.searchByTag,
  );

  const [searchInput, setSearchInput] = useState<string>("");
  const ref = useRef<any>();

  useEffect(() => {
    if (defaultValue) {
      setSearchInput(defaultValue);
    }
  }, []);

  return (
    <PopUpContainer close={close} paddingless headerless bigger>
      <InputBox>
        <div className="go-back" onClick={close}>
          <Back />
        </div>
        {isAddress ? (
          <DebounceInput
            debounceTimeout={500}
            element={"input"}
            type="text"
            ref={ref}
            value={searchInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchInput(e.target.value);
              onChange && onChange(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // e.preventDefault();
                setValue({ data: { title: searchInput } });
              } else if (e.key === "Tab") {
                e.preventDefault();
                tab?.();
              }
            }}
            autoFocus
          />
        ) : (
          <input
            type="text"
            ref={ref}
            value={searchInput}
            placeholder="Напишите что сделать или выберите технику?"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchInput(e.target.value);
              onChange && onChange(e.target.value);
            }}
            maxLength={80}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                setValue({ data: { title: searchInput } });
              } else if (e.key === "Tab") {
                e.preventDefault();
                tab?.();
              }
            }}
            autoFocus
          />
        )}
        <div
          className="close"
          onClick={() => {
            if (searchInput) {
              setSearchInput("");
              ref.current.focus();
            } else {
              close();
            }
          }}
        >
          <Close />
        </div>
      </InputBox>

      {noSearchResult && <ListTitle>Популярные запросы:</ListTitle>}

      <DatasetList wrapperClass={wrapperClass} noTitle={!noSearchResult}>
        {/* {!searchByTagResult?.data?.length && type === "vehicle" && list ? (
          <div className={"toplist-header"}>Популярные запросы:</div>
        ) : null} */}
        {list?.map((value: any, index: number) => {
          const { description, name, img } = value;
          return (
            <DatasetBox
              key={index}
              onClick={() => {
                setValue(value);
                setVehicleInputIcon(img);
              }}
            >
              <div className="img">{img && <img src={img} alt="" />}</div>
              <div className="data">
                <div className="title">{name}</div>
                {/* <div className="subtitle">{description}</div> */}
              </div>
            </DatasetBox>
          );
        })}
      </DatasetList>
    </PopUpContainer>
  );
};

export default memo(SearchVehiclePopUp);
