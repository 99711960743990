import styled from "styled-components";

export const ConnectionMethodList = styled.div``;

export const ConnectionItem = styled.div`
  width: 100%;
  padding: 13px 0;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  border-bottom: 1px solid #ebebeb;
  position: relative;
  cursor: pointer;

  &::after {
    content: "";
    /* background-color: rgba(0, 0, 0, 0.12); */
    position: absolute;
    top: -1px;
    right: -10px;
    bottom: -1px;
    left: -10px;
    z-index: -1;
  }

  &:hover::after {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:last-child {
    /* border: 0; */
  }
  input {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  .name {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #222222;
  }
  .icon {
    width: 32px;
    display: flex;
    background-color: white;
    border-radius: 3px;
    img {
      width: 100%;
    }
  }
`;

export const ConnectionBox = styled.div`
  width: 100%;
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #222222;
    margin-bottom: 16px;
  }

  textarea {
    width: 100%;
    outline: 0;
    border: 1px solid rgba(36, 39, 44, 0.2);
    border-radius: 10px;
    resize: none;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;
