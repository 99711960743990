import styled from "styled-components";

export const DescriptionBox = styled.div`
  width: 100%;
  .text {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    color: #222222;
    margin-bottom: 16px;
  }

  textarea {
    width: 100%;
    outline: 0;
    border: 1px solid rgba(36, 39, 44, 0.2);
    border-radius: 10px;
    resize: none;
    padding: 10px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;
