import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Desc, Half, Title, Wrapper } from "./styles";
import Button from "../../UI/Button";
import rightImg from "../../assets/images/right-block.png";
import { useAppSelector } from "../../redux/hooks";
import PageWrapper from "../../UI/PageWrapper";

const ClosedOrder: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  return (
    <PageWrapper noWidth noFooter>
      <Half>
        <Box>
          <Title>Этот заказ закрыт</Title>
          <Desc>
            {!user?.customer?.role || user?.customer?.role === "customer" ? (
              <>Вы можете создать новый заказ.</>
            ) : (
              <>Вы можете найти новые заказы.</>
            )}
          </Desc>
          <Button
            variant="primary"
            shadowed
            height={"56px"}
            onClick={() =>
              !user?.customer?.role || user?.customer?.role === "customer"
                ? navigate("/create-order")
                : navigate("/search-order")
            }
          >
            {!user?.customer?.role || user?.customer?.role === "customer"
              ? "Новый заказ"
              : "Найти заказ"}
          </Button>
        </Box>
      </Half>
      <Half imgPart>
        <img src={rightImg} alt="" />
      </Half>
    </PageWrapper>
  );
};

export default ClosedOrder;
