import styled from "styled-components";

export const SideMenuBackground = styled.div<{ open: boolean }>`
  width: 100vw;
  height: 100dvh;
  max-height: 100dvh;
  backdrop-filter: blur(2px);
  border: solid 1px #979797;
  background-color: ${({ open }) => (open ? "#222222b3" : "#00000000")};
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in, display 0.2s;
  display: ${({ open }) => (open ? "block" : "none")};

  z-index: 5;
  animation: ${({ open }) =>
    open
      ? "SideBackgroundOpen 0.2s ease-in"
      : "SideBackgroundClose 0.2s ease-out"};

  @keyframes SideBackgroundOpen {
    from {
      background-color: #22222200;
      display: none;
    }
    to {
      background-color: #222222b3;
      display: block;
    }
  }

  @keyframes SideBackgroundClose {
    from {
      background-color: #222222b3;

      display: block;
    }
    to {
      background-color: #22222200;
      display: none;
    }
  }
`;

export const SideMenuWrapper = styled.div<{ open: boolean }>`
  height: 100dvh;
  width: 477px;
  background: #ffffff;
  transition: all 0.3s ease-in, display 0.3s;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  gap: 32px;
  padding: 24px;
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-477px")};
  z-index: 100;
  overflow: auto;
  scrollbar-gutter: stable;
  animation: ${({ open }) =>
    open ? "SideWrapperOpen 0.3s ease-in" : "SideWrapperClose 0.3s ease-out"};

  .menuItemGroup {
    display: flex;
    justify-content: space-between;
  }

  .title {
    font-size: 20px;
    line-height: 1.2;
    color: #000;
  }

  .menuItem {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .text {
      font-size: 16px;
      color: #000;
    }

    &:nth-child(2) {
      margin-bottom: 0;
    }
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }

  @keyframes SideWrapperOpen {
    from {
      right: -477px;
      display: flex;
    }
    to {
      right: 0px;
      display: flex;
    }
  }

  @keyframes SideWrapperClose {
    from {
      right: 0px;
      display: flex;
    }
    to {
      right: -477px;
      display: none;
    }
  }
`;

export const SideMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #000;

  svg {
    cursor: pointer;
  }
`;
