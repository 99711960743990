import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import React, { useCallback, useEffect, useState } from "react";
import { styles } from "../../components/styles/styles";
import { GEOCODE_MAPS } from "../../constants/general";
import useHttpRequest from "../../hooks/useHttpRequest";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  FiltersButton,
  FilterWrapper,
  GeolocationButton,
  ListButton,
  LocationInputWrapper,
  MyVehicleWrapper,
  OrdersListItem,
  OrdersListMenu,
  PeriodWrapper,
  RadiusWrapper,
  Wrapper,
  YandexMapWrapper,
} from "./styles";

import vehicleIcon1 from "../../assets/icons/icons-vehicles-excavator.svg";
import vehicleIcon2 from "../../assets/icons/icons-vehicles-beton.svg";
import vehicleIcon3 from "../../assets/icons/icons-vehicles-hoist.svg";
import vehicleIcon4 from "../../assets/icons/icons-vehicles-musor.svg";
import vehicleIcon5 from "../../assets/icons/icons-vehicles-wheelbarrow.svg";
import { ReactComponent as OptionsIcon } from "../../assets/icons/icons-24-basic-options.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/24-basic-circle-x.svg";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  getAddresses,
  getAddressesAsync,
} from "../../redux/modules/getAddresses";
import LocationInputDataset from "./locationInputDataset";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icons-calendar (2).svg";
import { ReactComponent as RadarIcon } from "../../assets/icons/24-maps-radar.svg";
import { ReactComponent as HoistIcon } from "../../assets/icons/icons-vehicles-hoist-small.svg";
import { ReactComponent as PinMap } from "../../assets/icons/map-pin-location.svg";
import { ReactComponent as ListIcon } from "../../assets/icons/24-grid-grid-row-3.svg";

import { searchOrders } from "../../redux/store/reducers/orderSlice";
import moment from "moment";
import Select from "../../UI/FilterSelect";
import {
  OrderDate,
  SearchRadius,
  UserVehicleList,
} from "../../types/filters.types";
import FiltersPopUp from "../../components/popUp/filtersPopUp";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { DatePicker } from "antd";
import ResultComponent from "./ResultComponent";
import WorkItem from "../../components/workItem";
import workItem from "../../components/workItem";
import { clearAddressString } from "../../utils/clearAddressString";
import PageWrapper from "../../UI/PageWrapper";
import { PaginationType } from "../../UI/Pagination";
import { getPosition } from "../../redux/modules/getPosition";
import { getAddressesString } from "../../utils/getAddressString";

const { RangePicker } = DatePicker;

interface Work {
  id: string;
  title: string;
  description: string;
  budget: string;
  startDate: string;
  endDate: string;
  address: string;
  categoryId?: string;
}

export interface Pagination {
  take: number;
  skip: number;
}

const vehicleList = [
  vehicleIcon1,
  vehicleIcon2,
  vehicleIcon3,
  vehicleIcon4,
  vehicleIcon5,
];

const dateList: OrderDate[] = [
  { label: "Сегодня", value: "today" },
  { label: "Вчера", value: "yesterday" },
  { label: "За неделю", value: "last_seven_days" },
  { label: "За 10 дней", value: "last_ten_days" },
  { label: "Все", value: "all" },
];

const radiusList: SearchRadius[] = [
  { label: "10 км", value: "10" },
  { label: "20 км", value: "20" },
  { label: "50 км", value: "50" },
  { label: "100 км", value: "100" },
  { label: "Все", value: "-1" },
];

const myVehicleList: UserVehicleList[] = [
  { label: "Только для моей техники", value: "only_my_vehicle" },
  { label: "Для любой техники", value: "all" },
];

const SearchOrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const windowLocation = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navLoc = useLocation();
  const [address, setAddress] = useState<any>({});
  const [ymaps, setYmaps] = useState<any>(null);
  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [zoom, setZoom] = useState(17);
  const [workDetail, setWorkDetail] = useState<Work | null>(null);
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const [firstLocation, setFirstLocation] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>();
  const [ordersListOpen, setOrdersListOpen] = useState<boolean>(false);
  const [pagination, setPagination] = useState<PaginationType>({
    take: 50,
    skip: 0,
  });

  const [filter, setFilter] = useState<{
    period?: string;
    radius?: string;
    vehicle?: string;
  }>({
    period: "all",
    vehicle: "all",
    radius: "50",
  });
  const addresses = useAppSelector((state) => state.addresses);
  const { width, height } = useWindowDimensions();

  const { orderList, loadingOrderList } = useAppSelector(
    (state) => state.order,
  );

  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const user = useAppSelector((state) => state.auth.user);

  const [ordersArray, setOrdersArray] = useState<any[]>([]);
  const [ordersTotalAmount, setOrdersTotalAmount] = useState<number>(0);

  const getAddressList = (searchInput: any) => {
    dispatch(
      getAddressesAsync({
        text: searchInput,
      }),
    );
  };

  const getAddress = ([latitude, longitude]: any) => {
    dispatch(getPosition({ lat: latitude, lon: longitude })).then((result) => {
      setAddress({
        name: getAddressesString({
          road: result.payload.address.road,
          house_number: result.payload.address.house_number,
        }),
        city: result.payload.address.city || result.payload.address.town,
      });
    });
    ymaps?.setCenter([latitude, longitude], 17, {
      duration: 1000,
      timingFunction: "linear",
    });
  };

  const getUserLocation = async (mount?: boolean) => {
    console.log("location");
    const permissionResult = await window.navigator.permissions.query({
      name: "geolocation",
    });

    if (permissionResult.state === "granted") {
      window.navigator?.geolocation.getCurrentPosition(
        (position) => {
          const { longitude, latitude } = position.coords;
          setCenter([latitude, longitude]);
          setLocation([latitude, longitude]);
          console.log({ latitude, longitude });
          if (mount) {
            setFirstLocation([latitude, longitude]);
            ymaps?.setCenter([latitude, longitude], 17, {
              duration: 1000,
              timingFunction: "linear",
            });
          }
          getAddress([latitude, longitude]);
        },
        (err) => {
          console.warn("geo error", err);
          //error message
        },
        {
          timeout: 5000,
        },
      );
    } else {
      //error message
    }
  };

  const resetFilters = () => {
    setFilter({
      period: "today",
      radius: "",
      vehicle: "only_my_vehicle",
    });
  };

  useEffect(() => {
    setPagination({ take: 50, skip: 0 });
  }, [filter, address]);

  useEffect(() => {
    getUserLocation(true);
    dispatch(getVehicleCategories());
  }, []);

  useEffect(() => {
    if (user && user.hasOwnProperty("customer") && firstLocation.length)
      dispatch(
        searchOrders({
          refId: user?.customer?.refId,
          distance: filter.radius ? String(filter.radius) : "50",
          latitude: firstLocation[0],
          longitude: firstLocation[1],
          sortDate: "DESC",
          take: pagination?.take,
          skip: pagination?.skip,
        }),
      );
  }, [user, firstLocation]);

  const getVehicleIcon = (categoryId: number) => {
    const icon = vehicleCategories?.find(
      (vehicle: any) => vehicle.id === categoryId,
    )?.icon;

    return icon ? icon : "assets/images/excavator.png";
  };

  const handleSearchOrders = (
    take: number = 10,
    skip: number = 0,
    rewriteOrdersArray: boolean = true,
  ) => {
    const date =
      dateRange && dateRange?.[0]
        ? moment(dateRange[0]).hours(0).minute(0).second(0)
        : moment().hours(0).minute(0).second(0);
    const distance = Number(filter.radius) || 50;
    const me = filter.vehicle === "only_my_vehicle";
    if (dateRange?.length) {
      const startDate = moment(dateRange[1]).hours(23).minute(59).second(0);

      dispatch(
        searchOrders({
          refId: user?.customer?.refId,
          date: date?.format(),
          startDate: startDate?.format(),
          distance: distance ? String(distance) : "50",
          latitude: location[0],
          longitude: location[1],
          sortDate: "DESC",
          me,
          take,
          skip,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          if (rewriteOrdersArray) {
            setOrdersArray(response.payload.orders);
          } else {
            setOrdersArray([...ordersArray.concat(response.payload.orders)]);
          }
          setOrdersTotalAmount(response.payload.total);
        }
      });
    } else {
      const startDate =
        filter?.period === "today"
          ? moment().hours(23).minute(59).second(0)
          : filter?.period === "yesterday"
          ? moment().subtract(1, "days").hours(23).minute(59).second(59)
          : filter?.period === "last_seven_days"
          ? moment().add(7, "days").hours(23).minute(59).second(0)
          : filter?.period === "last_ten_days"
          ? moment().add(10, "days").hours(23).minute(59).second(0)
          : null;
      dispatch(
        searchOrders({
          refId: user?.customer?.refId,
          date: date?.format(),
          startDate: startDate?.format(),
          distance: distance ? String(distance) : "50",
          latitude: location[0],
          longitude: location[1],
          sortDate: "DESC",
          me,
          take,
          skip,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          if (rewriteOrdersArray) {
            setOrdersArray(response.payload.orders);
            setPagination({ take: 50, skip: 0 });
          } else {
            setOrdersArray([...ordersArray.concat(response.payload.orders)]);
          }
          setOrdersTotalAmount(response.payload.total);
        }
      });
    }
  };

  const renderOrdersOnMap = useCallback(
    (ordersArray: any[]) => {
      return ordersArray
        ?.filter((order: any) => order?.creatorId !== user?.customer?.id)
        ?.map((order: any, index: number) => {
          return (
            <Placemark
              key={`placemarkMain${index}`}
              options={{
                zIndex: 999,
                preset: "islands#circleIcon",
                iconColor: "#423189",
                draggable: false,
                hideIconOnBalloonOpen: false,
                hasBalloon: true,
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
              }}
              modules={["geoObject.addon.balloon"]}
              geometry={{
                type: "Point",
                coordinates: [order.latitude, order.longitude],
              }}
              properties={{
                iconContent: `
                              <div class="order-placemark">  
                                <img class="placemark" src=${placemark} alt="" />
                                <img class="vehicle" src="https://dev.rukki.pro/${getVehicleIcon(
                                  order?.category?.id
                                    ? order.category.id
                                    : order.categoryId,
                                )}" alt="" />
                              </div>`,
              }}
              onClick={(e: any) => {
                setWorkDetail(order);
                setOrdersListOpen(true);
              }}
            />
          );
        });
    },
    [ordersArray],
  );

  const fillOrdersArray = async () => {
    let take = pagination.take;
    let skip = pagination.skip;
    const distance = Number(filter.radius) || 50;
    const date =
      dateRange && dateRange?.[0]
        ? moment(dateRange[0]).hours(0).minute(0).second(0)
        : moment().hours(0).minute(0).second(0);
    if (ordersArray.length < ordersTotalAmount) {
      await dispatch(
        searchOrders({
          refId: user?.customer?.refId,
          date: date?.format(),
          startDate: startDate?.format(),
          distance: String(distance),
          latitude: location[0],
          longitude: location[1],
          sortDate: "DESC",
          me: false,
          take,
          skip: skip + 50,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setOrdersArray([...ordersArray.concat(response.payload.orders)]);
          if (response.payload.total !== ordersTotalAmount) {
            setOrdersTotalAmount(response.payload.total);
          }
          setPagination({ ...pagination, skip: skip + 50 });
        }
      });
    }
  };

  useEffect(() => {
    fillOrdersArray();
  }, [ordersArray]);

  useEffect(() => {
    if (user?.customer?.refId && location.length) {
      handleSearchOrders(50, 0);
    }
  }, [user, location, filter]);

  useEffect(() => {
    if (searchParams.get("flag") === "my") {
      document.title = "Мои работы";
    } else {
      document.title = "Поиск заказа";
    }
  }, [navLoc.pathname, navLoc]);

  return (
    <PageWrapper
      noWidth
      minHeight="calc(100dvh - 65px)"
      noFooter
      flexDirection="column"
      gap="0"
      //minHeight="calc(100vh - 137px)"
    >
      {searchParams.get("flag") !== "my" && (
        <FilterWrapper>
          <LocationInputWrapper>
            <LocationInputDataset
              setValue={(a: any) => {
                const [latitude, longitude] = a?.coordinates;
                setAddress(a);
                setLocation([latitude, longitude]);
                setCenter([latitude, longitude]);
                setZoom(15);
                ymaps?.setCenter([latitude, longitude], 17, {
                  duration: 1000,
                  timingFunction: "linear",
                });
              }}
              defaultValue={address?.name ? address?.name : ""}
              close={() => {
                // makeAddressInputEmpty()
              }}
              list={addresses.data?.map(
                ({
                  address: { city, county, road, house_number, town },
                  lat,
                  lon,
                  addresstype,
                }: any) => ({
                  city,
                  county,
                  road,
                  house_number,
                  lat,
                  lon,
                  town,
                  addresstype,
                }),
              )}
              onChange={(searchInput: any) => {
                getAddressList(searchInput);
              }}
              getUserLocation={getUserLocation}
            />
          </LocationInputWrapper>
          <div className="rangeInputWrapper">
            <RangePicker
              format={"DD MMM YYYY"}
              onChange={(values) => {
                setDateRange(values);
              }}
            />
          </div>

          <RadiusWrapper>
            <Select
              Icon={RadarIcon}
              list={radiusList}
              value={
                radiusList?.find(({ value }) => value === filter?.radius)?.label
              }
              placeholder={"Радиус поиска"}
              name={"radius"}
              onChange={({ name, value }) => {
                setFilter({ ...filter, [name]: value });
              }}
            />
          </RadiusWrapper>
          <MyVehicleWrapper>
            <Select
              Icon={HoistIcon}
              list={myVehicleList}
              value={
                myVehicleList.find(({ value }) => value === filter?.vehicle)
                  ?.label
              }
              placeholder={""}
              name={"vehicle"}
              onChange={({ name, value }) => {
                setFilter({ ...filter, [name]: value });
              }}
            />
          </MyVehicleWrapper>
          {!navLoc.pathname.includes("results") && (
            <>
              <ListButton
                onClick={() => {
                  navigate("/search-order/results?flag=all");
                  setPagination({ take: 10, skip: 0 });
                }}
              >
                <ListIcon />
                Показать список
              </ListButton>
              <GeolocationButton onClick={() => getUserLocation(true)}>
                <PinMap />
              </GeolocationButton>
            </>
          )}
          <FiltersButton
            onClick={() => {
              setFilterOpen(true);
            }}
          >
            <OptionsIcon />
            <div className="text">Все фильтры</div>
            <div className="counter">{Object.keys(filter).length}</div>
          </FiltersButton>
          {filterOpen && (
            <FiltersPopUp
              filter={filter}
              resetFilters={resetFilters}
              onFilterSelect={({ name, value }) => {
                setFilter({ ...filter, [name]: value });
              }}
              close={() => {
                setFilterOpen(false);
              }}
            />
          )}
        </FilterWrapper>
      )}

      {navLoc.pathname !== "/search-order/results" ? (
        <YandexMapWrapper>
          <OrdersListMenu open={ordersListOpen}>
            <div className="header">
              <div className="text">{`${1} заказ`}</div>
              <CloseIcon onClick={() => setOrdersListOpen(false)} />
            </div>
            <div className="ordersWrapper">
              {workDetail && (
                <WorkItem
                  my={false}
                  {...workDetail}
                  id={workDetail?.id}
                  orderId={workDetail?.id}
                  navbarItem
                  categoryId={workDetail?.categoryId}
                  handleFetchOrders={handleSearchOrders}
                  handleSearchOrders={handleSearchOrders}
                  setOrdersListOpen={setOrdersListOpen}
                  setWorkDetail={setWorkDetail}
                  pagination={pagination}
                  //isFetching={isFetching}
                />
              )}
            </div>
          </OrdersListMenu>
          <YMaps>
            <Map
              onLoad={(e) => {
                setYmaps(e);
              }}
              instanceRef={(e) => {
                setYmaps(e);
              }}
              width={"100vw"}
              height={"100%"}
              defaultState={{ center, zoom: zoom }}
              onClick={(e: any) => {
                const coords = e.get("coords");
                setLocation(coords);
                getAddress(coords);
              }}
              options={{
                maxAnimationZoomDifference: 23,
                yandexMapDisablePoiInteractivity: false,
              }}
            >
              {location.length > 0 && (
                <Placemark
                  modules={["geoObject.addon.balloon"]}
                  width="0px"
                  height="0px"
                  options={{
                    preset: "islands#circleIcon",
                    iconColor: "#423189",
                    draggable: false,
                    hideIconOnBalloonOpen: false,
                    hasBalloon: true,
                    balloonShadow: false,
                    balloonPanelMaxMapArea: 0,
                  }}
                  geometry={{
                    type: "Point",
                    coordinates: location,
                  }}
                  onDragEnd={(e: any) => {
                    const coordinates = e.get("target").geometry._coordinates;
                    setLocation(coordinates);
                    getAddress(coordinates);
                  }}
                  properties={{
                    iconContent: `
                          <div class="myIconContentBoxAnimation">
                            <div class="first-circle"></div>
                            <div class="second-circle"></div>
                            <div class="third-circle"></div>
                            <div class="forth-circle"></div>
                            <div class="core-circle"></div>
                          </div>`,
                  }}
                />
              )}
              {renderOrdersOnMap(ordersArray)}
              <ZoomControl
                options={{
                  position: {
                    right: 10,
                    top: width > 576 ? 20 : 80,
                  },
                }}
              />
            </Map>
          </YMaps>
        </YandexMapWrapper>
      ) : (
        <ResultComponent
          handleSearchOrders={handleSearchOrders}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </PageWrapper>
  );
};

export default SearchOrderPage;

