import React, { memo, useEffect, useState } from "react";
import { PinInputActions, usePinInput } from "react-pin-input-hook";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { authActions } from "../../redux/store/reducers/authSlice";
import { RootState } from "../../redux/store/store";
import { FormTitle } from "../form/styles";
import Button from "../../UI/Button";
import {
  Back,
  ButtonsWrapper,
  DidNotReceiveCode,
  ErrorMsg,
  FormDesc,
  PinInput,
  PinInputField,
} from "./styles";
import { ReactComponent as Arrow } from "../../assets/icons/Arrow Back.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";
import { styles } from "../styles/styles";

interface Props {
  country: string;
  phoneNumber: any;
  setOtp: (otp: string) => void;
  verifyOtp: (otp: string) => void;
  error: string;
  verifyLoading: boolean;
  smsRu: boolean;
  setFlag: (x: any) => void;
  tryToLogin: any;
  setCallHandler: any;
  tryToLoginWithAlternative: () => void;
  setError: React.Dispatch<any>;
}

const VerifyCodeForm: React.FC<Props> = ({
  country,
  phoneNumber,
  verifyOtp,
  error,
  verifyLoading,
  smsRu,
  setFlag,
  tryToLogin,
  setCallHandler,
  tryToLoginWithAlternative,
  setError,
}) => {
  const actionRef = React.useRef<PinInputActions>(null);
  const { loadingAltAuth, alternativeAuth } = useAppSelector(
    (state: RootState) => state.auth,
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [values, setValues] = useState(Array(6).fill(""));

  const { fields } = usePinInput({
    values,
    otp: true,
    autoFocus: true,
    onChange: (values) => {
      setValues(values);
    },
    onComplete: (value) => {
      verifyOtp(value);
    },
    actionRef,
    placeholder: "",
  });

  const [timer, setTimer] = useState(30);
  const [codeError, setCodeError] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
    if (timer === 0) {
      clearTimeout(timeout);
    }
  }, [timer]);

  const [myOtp, setMyOtp] = useState("");

  // useEffect(() => {
  //   if ("OTPCredential" in window) {
  //     console.log(window);
  //     navigator.credentials
  //       .get({
  //         // @ts-ignore
  //         otp: { transport: ["sms"] },
  //       })
  //       .then((otp: any) => {
  //         const code = otp.code;
  //         setMyOtp(code);
  //         code &&
  //           Number(code) >= 100000 &&
  //           Number(code) <= 999999 &&
  //           setValues(String(code).split(""));
  //       })
  //       .catch((err) => {});
  //   }
  // }, []);

  useEffect(() => {
    if (error === "Неправильный код подверждения, попробуйте еще раз") {
      const input = document.getElementById("pinInput_5");
      input && input.focus();
    }
  }, [error]);

  return (
    <>
      <Back
        onClick={() => {
          setFlag(false);
          dispatch(authActions.setCall(true));
          localStorage.setItem("phoneNumberInLogin", phoneNumber);
          localStorage.setItem("phoneNumberCountryInLogin", country);
        }}
      >
        <LeftArrow />
        Назад
      </Back>
      <FormTitle verification style={{ textAlign: "left" }}>
        <span
          onClick={() => {
            setFlag(false);
            dispatch(authActions.setCall(true));
            localStorage.setItem("phoneNumberInLogin", phoneNumber);
          }}
          className="go-back"
        >
          <Arrow />
        </span>
        Проверка номера
      </FormTitle>
      <FormDesc>
        Введите код из смс, отправленного <br /> на номер <b>{phoneNumber}</b>
      </FormDesc>
      <PinInput className="pin-input">
        {fields.map((propsField, index) => (
          <PinInputField
            id={`pinInput_${index}`}
            error={!!error.length}
            key={index}
            className="pin-input__field"
            {...propsField}
            disabled={verifyLoading || loadingAltAuth === "pending"}
            style={{ color: error !== "" ? "#FF3358" : "inherit" }}
          />
        ))}
      </PinInput>

      <div
        style={{
          textAlign: "left",
          transition: "opacity 0.25s linear",
          opacity: `${error !== "" ? "100%" : "0%"}`,
          minHeight: "44px",
        }}
      >
        {error ? error : " "}
      </div>

      <ButtonsWrapper>
        <Button
          height={"48px"}
          fontWeight={"500"}
          disabled={timer !== 0}
          onClick={() => {
            if (timer === 0) {
              dispatch(authActions.setAlternativeAuth(true));
              setTimer(30);
            } else {
              tryToLogin();
            }
            setValues(Array(6).fill(""));
            setError("");
            setTimeout(() => {
              document.getElementById("pinInput_0")?.focus();
            }, 500);
          }}
        >
          {`${"Отправить код повторно"} ${timer > 0 ? `(${timer} сек)` : ""}`}
        </Button>
      </ButtonsWrapper>
      {/*<TelegramButton onClick={() => navigate("/telegram-auth")}>*/}
      {/*  <TelegramIcon />*/}
      {/*  Войти через Telegram*/}
      {/*</TelegramButton>*/}
      {alternativeAuth ? (
        <DidNotReceiveCode>
          <div>
            Не получается войти? <br />
            <a
              href="tel:+74992832869"
              style={{
                fontWeight: "bold",
                color: styles.mainColor,
              }}
            >
              Напишите
            </a>{" "}
            в поддержку
          </div>
        </DidNotReceiveCode>
      ) : (
        <></>
      )}
    </>
  );
};

export default memo(VerifyCodeForm);

