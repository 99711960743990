import React, { memo, useEffect, useState } from "react";
import { Typography } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { AlertBadge, FileItem, SignButton, Title } from "./styles";
import { Wrapper } from "../../pages/infoAboutOrderPage/styles";
import moment from "moment";
// import { Button } from "../form/styles";
import useHttpRequest from "../../hooks/useHttpRequest";
import { BASE_URL, ENDPOINT } from "../../constants/general";
import {
  IOrderDoc,
  getOrderDocs,
  getOrderImages,
} from "../../redux/store/reducers/orderSlice";
import { DocumentsWrapper } from "../orderDetailedInfo/styles";
import ImagePreview from "../imagePreview";
import pdfImg from "../../assets/icons/pdf.png";
import docImg from "../../assets/icons/doc.png";
import xlsImg from "../../assets/icons/xls.png";
import { ReactComponent as SignIcon } from "../../assets/icons/24-security-verified.svg";
import { ReactComponent as AlertIcon } from "../../assets/icons/icons-24-status-icon-alert.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/icons-24-status-icon-check-green.svg";
import { ReactComponent as DoubleCheckIcon } from "../../assets/icons/icons-24-status-icon-double-check-green.svg";
import { ReactComponent as WaitIcon } from "../../assets/icons/icons-24-status-icon-waiting.svg";
import { ReactComponent as DocImg } from "../../assets/icons/icon-24-basic-file-text.svg";
import SignPopUp from "../popUp/signPopUp";
import ImagesEmptyPage from "../emptyPage/imagesEmptyPage";
import PhotoVideoPopUp from "../popUp/photoVideoPopUp";
import axios from "axios";
import { getToken } from "../../utils/uiHelpers";
import { removeFileExtension } from "../../utils/removeFileExtension";
import { ICustomer } from "../../types";
import Button from "../../UI/Button";

export interface LinkItem {
  createdAt: string;
  type: number;
  filename: string;
  id: number;
  orderId: string;
  signature: string;
}

export interface IUserInfo {
  firstName: string;
  secondName: string;
  patronymic: string | null;
  email: string | null;
  phoneNumber: string | null;
  role: "worker" | "customer";
}

export interface IDocument {
  createdAt: string;
  filename: string;
  fullpath: string;
  id: number;
  isSignedByCustomer: number;
  isSignedByWorker: number;
  type: any;
}

interface IDocsProps {
  userInfo: ICustomer;
  paddingless?: boolean;
}

export const DocumentType = [
  "waybill",
  "tech_task",
  "bill",
  "act",
  "invoice",
  "committent_report",
  "framework",
  "user_agreement_customer",
  "committent_report_worker",
  "completed_work",
];

const Docs: React.FC<IDocsProps> = ({ userInfo, paddingless }) => {
  const singleOrder = useAppSelector(
    (state: RootState) => state.order.singleOrder,
  );
  const orderDocs = useAppSelector((state: RootState) => state.order.orderDocs);
  const { fireBaseData, user } = useAppSelector(
    (state: RootState) => state.auth,
  );

  const dispatch = useAppDispatch();
  const offers = useAppSelector((state: RootState) => state.order.offers);

  const [imgPreview, setImgPreview] = useState({ open: false, url: "" });
  const [signed, setSigned] = useState<boolean>(false);
  const [signPopUp, setSignPopUp] = useState<any>({ popUp: false });
  const [selectedList, setSelectedList] = useState<IDocument[]>([]);
  const [actionNeeded, setActionNeeded] = useState<boolean>(false);
  const [signInProcess, setSignInProcess] = useState<boolean>(false);
  const [imagePopUp, setImagePopUp] = useState<boolean>(false);
  let photosCounter = 0;
  let docsCounter = 0;

  const [signProcess, signRequest] = useHttpRequest({
    onSuccess: () => {
      setSignInProcess(false);
      dispatch(
        getOrderDocs({
          refId: user?.customer.refId!,
          orderId: singleOrder.order.id,
        }),
      );
      //close();
    },
    onError: (err: any) => {
      // toast.error("Что-то пошло не так при подписании документа");
    },
  });

  interface Alert {
    text: string;
    doc: any;
  }

  const updateDocs = () => {
    const refId = fireBaseData?.refId;
    if (refId) {
      dispatch(getOrderDocs({ refId, orderId: singleOrder?.order?.id }));
    }
  };

  const [uploadFileProcess, uploadFileRequest] = useHttpRequest({
    onSuccess: () => {
      dispatch(
        getOrderImages({
          refId: fireBaseData?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
    },
  });

  const signDoc = async (id: any, item: any) => {
    let signedOnce = false;
    setSignInProcess(true);
    orderDocs?.forEach((item: any, index: number) => {
      if (
        user &&
        user?.customer?.role === "customer" &&
        (user?.customer?.id === singleOrder?.order?.creatorId ||
          user?.customer?.companyId === singleOrder?.order?.company?.id) &&
        item.isSignedByCustomer
      ) {
        signedOnce = true;
      }

      if (
        user &&
        user?.customer?.role === "worker" &&
        user?.customer?.id ===
          offers.find(({ status }: any) => status === "Accepted")?.workerId &&
        item.isSignedByWorker
      ) {
        signedOnce = true;
      }
    });

    if (signedOnce) {
      await signRequest({
        method: "POST",
        path: `analytics/${user?.customer?.refId}/requisites/${user?.customer?.id}`,
        data: userInfo,
      });
      await axios.put(
        `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/sign-an-agreement`,
        {
          fileId: id,
          docType: item.type,
          coordinates: [
            singleOrder?.order?.latitude,
            singleOrder?.order?.longitude,
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        },
      );
      updateDocs();
      checkActionNeeded();
    } else {
      setSignPopUp({ popUp: true, list: [id], item });
    }
  };

  const checkActionNeeded = () => {
    let needed = false;

    orderDocs?.forEach((item: any, index: number) => {
      const { isSignedByCustomer, isSignedByWorker, documentType } = item;
      if (
        user &&
        user?.customer?.role === "customer" &&
        (user?.customer?.id === singleOrder?.order?.creatorId ||
          user?.customer?.companyId === singleOrder?.order?.company?.id) &&
        documentType !== "committent_report" &&
        documentType !== "committent_report_worker" &&
        documentType !== "invoice" &&
        documentType !== null
      ) {
        if (!isSignedByCustomer) {
          setActionNeeded(true);
          needed = true;
        }
      } else !needed && setActionNeeded(false);
      if (
        user &&
        user?.customer?.role === "worker" &&
        user?.customer?.id ===
          offers.find(({ status }: any) => status === "Accepted")?.workerId &&
        documentType !== "committent_report" &&
        documentType !== "bill" &&
        documentType !== "committent_report_worker" &&
        documentType !== "invoice" &&
        documentType !== null
      ) {
        if (!isSignedByWorker) {
          setActionNeeded(true);
          needed = true;
        }
      } else !needed && setActionNeeded(false);
    });
  };

  useEffect(() => {
    if (signProcess.loading) {
      setSignInProcess(true);
    }
    if (signProcess.success) {
      setSignInProcess(false);
    }
  }, [signProcess]);

  useEffect(() => {
    orderDocs && checkActionNeeded();
  }, [orderDocs]);

  const uploadFile = async (files: any) => {
    if (files.length) {
      const formData = new FormData();
      files.forEach(({ file }: any) => {
        //if(file.type.includes("image") || file.type === "application/pdf") {
        formData.append("", file);
        //}
      });
      await uploadFileRequest({
        method: "PUT",
        url: `${ENDPOINT}/orders/${fireBaseData?.refId}/${singleOrder?.order?.id}/images`,
        data: formData,
      });
      setTimeout(() => {
        updateDocs();
        checkActionNeeded();
      }, 2000);
    }
  };

  return (
    <Wrapper id="order_docs">
      {imgPreview.open && (
        <ImagePreview
          close={() => setImgPreview({ open: false, url: "" })}
          url={imgPreview.url}
        />
      )}
      <div className="cardHeader">
        Документы по заказу
        {!!orderDocs && actionNeeded && (
          <AlertBadge red>
            <AlertIcon />
            <div className="text">Требуется действие</div>
          </AlertBadge>
        )}
      </div>

      <DocumentsWrapper>
        {(user?.customer?.role === "customer" ||
          (user?.customer?.role === "worker" &&
            user?.customer?.id ===
              offers.find(
                ({ status }: any) =>
                  status === "Accepted" || status === "WaitingForPayment",
              )?.workerId)) &&
          orderDocs?.map((item: IOrderDoc, i: number) => {
            const {
              id,
              fullpath,
              filename,
              isSignedByCustomer,
              isSignedByWorker,
              documentType,
            } = item;

            if (!documentType && filename.includes(".pdf")) docsCounter++;
            if (
              !documentType &&
              (filename.includes(".png") ||
                filename.includes(".jpg") ||
                filename.includes(".jpeg") ||
                filename.includes(".gif"))
            )
              photosCounter++;

            if (
              user?.customer?.role === "worker" &&
              (documentType === "committent_report" ||
                documentType === "user_agreement_customer")
            ) {
              return null;
            }
            if (
              user?.customer?.role === "customer" &&
              documentType === "committent_report_worker"
            ) {
              return null;
            }
            if (
              user?.customer?.role === "customer" &&
              (documentType === "completed_work" ||
                documentType === "waybill" ||
                documentType === "act") &&
              !isSignedByWorker
            ) {
              return null;
            }
            if (
              user?.customer?.role === "worker" &&
              documentType === "committent_report_worker"
            ) {
              const act = orderDocs.find(
                (document) => document.documentType === "act",
              );
              if (!act?.isSignedByCustomer || !act?.isSignedByWorker) {
                return null;
              }
            }
            return (
              <FileItem key={`document_${i}`}>
                <div
                  className="file-name"
                  onClick={() => {
                    window.open(fullpath, "_blank");
                  }}
                >
                  <div
                    className={`${
                      (user?.customer?.role === "customer" &&
                        isSignedByCustomer) ||
                      (user?.customer?.role === "worker" && isSignedByWorker)
                        ? "iconContainer"
                        : "iconContainer orange"
                    }`}
                  >
                    <DocImg />
                  </div>

                  <div className={"text"}>
                    <div className="date">
                      {moment(item.createdAt).format("ll")}
                      &nbsp;
                    </div>
                    <div className="name">
                      {DocumentType.includes(documentType!)
                        ? documentType === "completed_work"
                          ? `ЭСМ-7 ${removeFileExtension(filename)}`
                          : documentType === "waybill"
                          ? `ЭСМ-2 ${removeFileExtension(filename)}`
                          : removeFileExtension(filename)
                        : !documentType && filename.includes(".pdf")
                        ? `Документ ${docsCounter}`
                        : `Фото ${photosCounter}`}
                    </div>
                  </div>
                </div>
                <div className="right">
                  {user?.customer?.role === "customer" &&
                  (user?.customer?.id === singleOrder?.order?.creatorId ||
                    user?.customer?.companyId ===
                      singleOrder?.order?.company?.id) &&
                  documentType !== "committent_report" &&
                  documentType !== "committent_report_worker" &&
                  documentType !== "bill" &&
                  documentType !== "invoice" &&
                  !isSignedByWorker &&
                  isSignedByCustomer &&
                  !filename.includes("User_Agreement") ? (
                    <AlertBadge>
                      <WaitIcon width="24px" height="24px" />
                      <div className="text">Ждем подпись исполнителя</div>
                    </AlertBadge>
                  ) : (
                    <></>
                  )}
                  {user?.customer?.role === "worker" &&
                  user?.customer?.id ===
                    offers.find(
                      ({ status }: any) =>
                        status === "Accepted" || status === "WaitingForPayment",
                    )?.workerId &&
                  documentType !== "committent_report" &&
                  documentType !== "committent_report_worker" &&
                  documentType !== "invoice" &&
                  !isSignedByCustomer &&
                  isSignedByWorker ? (
                    <AlertBadge>
                      <WaitIcon width="24px" height="24px" />
                      <div className="text">Ждем подпись заказчика</div>
                    </AlertBadge>
                  ) : (
                    <></>
                  )}
                  {(isSignedByCustomer || isSignedByWorker) &&
                  (filename.includes("User_Agreement") ||
                    documentType === "committent_report" ||
                    documentType === "committent_report_worker") ? (
                    <AlertBadge>
                      <CheckIcon />
                      <div className="text">Подписано вами</div>
                    </AlertBadge>
                  ) : (
                    <></>
                  )}
                  {isSignedByCustomer && isSignedByWorker ? (
                    <AlertBadge>
                      <DoubleCheckIcon />
                      <div className="text">Подписано сторонами</div>
                    </AlertBadge>
                  ) : (
                    <></>
                  )}
                </div>
              </FileItem>
            );
          })}
        {selectedList.length > 0 && (
          <Button
            onClick={() => {
              setSignPopUp({ popUp: true, list: selectedList });
            }}
          >
            Подписать все выбранные
          </Button>
        )}
      </DocumentsWrapper>
      {signPopUp.popUp && (
        <SignPopUp
          loading={false}
          close={() => setSignPopUp({ popUp: false, list: [] })}
          list={signPopUp?.list}
          doc={signPopUp?.item}
          orderId={singleOrder?.order?.id}
          setSigned={setSigned}
          userInfo={userInfo}
        />
      )}
      {imagePopUp && (
        <PhotoVideoPopUp
          onSubmit={uploadFile}
          close={() => setImagePopUp(false)}
          defaultPhotoVideo={[]}
        />
      )}
    </Wrapper>
  );
};

export default memo(Docs);

