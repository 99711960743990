import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const SwitchItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: ${({ active }) => (active ? "#ffffff" : "#000000")};
  background: ${({ active }) => (active ? "#ff6633" : "#ffffff")};
  border: 1px solid ${({ active }) => (active ? "#ff6633" : "#000000")};
  border-radius: 10px;
  padding: 8px;
  height: 32px;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    border: 1px solid #ff6633;
    ${({ active }) => !active && "color: #ff6633"}
  }
`;
