import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import {
  FormWrapper,
  LoginWrapper,
  Title,
  Wrapper,
  PageContainer,
} from "./styles";
import LoginForm from "../../components/loginForm";
import VerifyCodeForm from "../../components/verifyCodeForm";
import { useGlobalContext } from "../../App";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import {
  authActions,
  fetchAuthSms,
  fetchAuthVerify,
  fetchRegistration,
  getMe,
  getRefIDFromFireBase,
} from "../../redux/store/reducers/authSlice";
import { CustomerRole } from "../../types";
import { setPageToRedirect } from "../../redux/store/reducers/registrationSlice";

const firebaseError = (error: string): string => {
  if (error.includes("(auth/invalid-phone-number)"))
    return "Неверный номер телефона, попробуйте еще раз";
  if (error.includes("(auth/too-many-requests)"))
    return "Ошибка, слишком много попыток аутентификации";
  if (error.includes("(auth/argument-error)"))
    return "Вы не прошли проверку, попробуйте еще раз";
  if (error.includes("(auth/invalid-verification-code)"))
    return "Неправильный код подверждения, попробуйте еще раз";
  // return "Ошибка авторизации: неизвестная ошибка, отправьте запрос еще раз!";
  return "";
};

const LoginPage: React.FC<any> = ({ setDisable }) => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loadingAltAuth, altAuth } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const [role, setRole] = useState<CustomerRole>(CustomerRole.Customer);
  const { setUpRecaptcha } = useGlobalContext();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [otp, setOtp] = useState<any>("");
  const [error, setError] = useState<any>("");
  const [result, setResult] = useState<any>("");
  const [flag, setFlag] = useState<boolean>(false);
  const { fireBaseData, alternativeAuth, loadingRef } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const { pageToRedirect } = useAppSelector((state) => state.registration);
  const isCall = false;
  const dispatch = useAppDispatch();
  const { user } = useGlobalContext();
  const [verifyLoading, setVerifyLoading] = useState<boolean>(false);
  const [isRegistration, setIsRegistration] = useState<boolean>(false);
  const [isRecapcha, setIsRecapcha] = useState<boolean>(false);
  const [pN, setPN] = useState<string>("");
  const [country, setCountry] = useState("RU");

  const nav = searchParams.get("nav");
  const roleQuery = searchParams.get("role");

  useEffect(() => {
    if (loadingRef === "succeeded") setFlag(false);
  }, [loadingRef]);

  const tryToLoginWithAlternative = () => {
    const phoneNumberToSave = pN;
    dispatch(authActions.setUserPhoneNumber(phoneNumberToSave));
    let number = pN;
    number = number.slice(1);

    dispatch(authActions.setCall(false));
    dispatch(fetchAuthSms(number));
  };

  const tryToLogin = async () => {
    const phoneNumberToSave = pN;
    dispatch(authActions.setUserPhoneNumber(phoneNumberToSave));
    let number = pN;

    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    if (alternativeAuth) {
      dispatch(authActions.setUserPhoneNumber(phoneNumberToSave));
      number = number.slice(1);

      if (isCall) {
        dispatch(authActions.setCall(false));
        dispatch(fetchAuthSms(number));
      } else dispatch(fetchAuthSms(number));
    } else {
      try {
        setIsRecapcha(true);
        const response = await setUpRecaptcha(number);
        setResult(response);
        setFlag(true);
      } catch (err: any) {
        setError(firebaseError(err.message));
        setIsRecapcha(false);
        if (
          firebaseError(err.message) === ""
          // "Ошибка авторизации: неизвестная ошибка, отправьте запрос еще раз!"
        ) {
          dispatch(authActions.setAlternativeAuth(true));
          // @ts-ignore
          //verifyRef.current.innerHTML = "";
        } else dispatch(authActions.setAlternativeAuth(true));
      }
    }
  };

  const verifyOtp = async (otp: string) => {
    setError("");
    if (otp === "" || otp === null) return;
    if (alternativeAuth) {
      dispatch(fetchAuthVerify({ code: otp, navigate, role })).then(
        (response: any) => {
          if (response?.error?.message?.includes("401")) {
            setError("Неправильный код подверждения, попробуйте еще раз");
          }
        },
      );
    } else {
      try {
        setVerifyLoading(true);
        await result.confirm(otp);
      } catch (err: any) {
        setVerifyLoading(false);
        setError(firebaseError(err.message));
      }
    }
  };

  const registerUser = () => {
    const data = {
      customer: {
        phoneNumber: pN,
        role: "customer",
      },
    };

    dispatch(
      fetchRegistration({
        data,
        navigate,
        onSuccess: async (refId: string) => {
          await dispatch(getMe(refId));
          navigate("/create-order");
        },
      }),
    );
  };

  useEffect(() => {
    if (user && user.uid) {
      dispatch(getRefIDFromFireBase({ uid: user.uid, navigate, role }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const phone = localStorage.getItem("phoneNumberInLogin");
    const country = localStorage.getItem("phoneNumberCountryInLogin");
    phone && setPN(phone);
    country && setCountry(country);
    localStorage.removeItem("phoneNumberInLogin");
    localStorage.removeItem("phoneNumberCountryInLogin");
  }, [user]);

  useEffect(() => {
    if (roleQuery) setRole(roleQuery as CustomerRole);
  }, []);

  useMemo(() => {
    if (fireBaseData === "") registerUser();
    else if (fireBaseData === null) {
      if (params.referralId) navigate(`/login/${params.referralId}`);
      else navigate(`/login`);
    } else {
      if (role === "worker") {
        pageToRedirect ? navigate(pageToRedirect) : navigate("/profile");
        dispatch(setPageToRedirect(null));
      } else if (role === "customer") {
        navigate("/create-order");
      }
    }
    setVerifyLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fireBaseData]);

  const verifyRef = useRef();

  useEffect(() => {
    if (loadingAltAuth === "succeeded") setFlag(true);
  }, [loadingAltAuth]);

  useEffect(() => {
    if (altAuth && altAuth.act === "registration") setIsRegistration(true);
  }, [altAuth]);

  useEffect(() => {
    if (isRegistration) {
      registerUser();
    }
  }, [isRegistration, pN]);

  const setCallHandler = () => {
    dispatch(authActions.setCall(true));
  };

  return (
    <Wrapper footer={72} navbar={64}>
      <div id="recaptcha-container"></div>
      <div className="row2">
        <PageContainer>
          <LoginWrapper verification={flag}>
            <FormWrapper>
              {flag ? (
                ""
              ) : (
                <>
                  <Title className="titleOfLogIn">Вход в Rukki</Title>
                </>
              )}
              {!flag ? (
                <LoginForm
                  pN={pN}
                  setPN={setPN}
                  tryToLogin={tryToLogin}
                  error={error}
                  verifyRef={verifyRef}
                  flag={flag}
                  smsRu={alternativeAuth}
                  isRecapcha={isRecapcha}
                  setIsRecapcha={setIsRecapcha}
                  country={country}
                  setCountry={setCountry}
                  role={role}
                  setRole={setRole}
                  tryToLoginWithAlternative={tryToLoginWithAlternative}
                />
              ) : (
                <VerifyCodeForm
                  country={country}
                  phoneNumber={pN}
                  setOtp={setOtp}
                  verifyOtp={verifyOtp}
                  error={error}
                  setError={setError}
                  verifyLoading={verifyLoading}
                  smsRu={alternativeAuth}
                  setFlag={setFlag}
                  tryToLogin={tryToLogin}
                  setCallHandler={setCallHandler}
                  tryToLoginWithAlternative={tryToLoginWithAlternative}
                />
              )}
            </FormWrapper>
          </LoginWrapper>
        </PageContainer>
      </div>
      <div className="row3">
        <div className="left-item">
          <div className="gradient" />
        </div>
      </div>
    </Wrapper>
  );
};

export default LoginPage;
