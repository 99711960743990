import React, { memo, useEffect, useRef } from "react";
import {
  Address,
  BodyPart,
  BudgetDeadline,
  DataWrapper,
  Desc,
  FooterPart,
  Header,
  OrderItem,
  OrdersListWrapper,
  OrderWrapper,
  SubTitle,
  Title,
  TitlePart,
  TitleValue,
  Wrapper,
} from "./styles";
import { ReactComponent as Excavator } from "../../assets/icons/icons-vehicles-excavator.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/icons-chevron-right.svg";
import avatarWorker from "../../assets/images/avatar-worker.png";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";
import getManagerData from "../../redux/modules/getManagerData";
import { Empty } from "antd";

const DashboardRightManagerData: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ref = useRef<any>(null);

  const user = useAppSelector((state) => state.auth.user);
  const data = useAppSelector((state) => state.managerData.data);
  const { data: managers } = useAppSelector((state) => state.managers);
  const { id, objId } = useParams();

  useEffect(() => {
    if (id && user?.customer?.refId) {
      dispatch(getManagerData(user.customer.refId, id));
    }
  }, [id, user?.customer?.refId]);

  return (
    <Wrapper>
      <Header>
        <div style={{ display: "flex", gap: "16px" }}>
          {/* <HeaderBtn onClick={() => navigate(`/dashboard/manager/${objId}`)}>
              <Close/> Закрыть
            </HeaderBtn> */}
          {/* <HeaderBtn> */}
          {/* <EditIcon /> Редактировать */}
          {/* </HeaderBtn> */}
        </div>
        {/* <HeaderBtn>
          <Delete />
          Удалить менеджера
        </HeaderBtn> */}
      </Header>
      <OrderWrapper>
        <DataWrapper ref={ref}>
          <Title>
            {data?.firstName} {data?.secondName}
          </Title>
          <SubTitle>
            #{data?.id}, создан {moment(data?.createdAt).format("LL")}
          </SubTitle>
          <BudgetDeadline>
            <TitleValue>
              <div className="title">Телефон</div>
              <div className="value">{data?.phoneNumber}</div>
            </TitleValue>
            <TitleValue />
            <TitleValue>
              <div className="title">Заказов в работе</div>
              <div className="value">
                {
                  managers?.users?.find(
                    ({ id: mId }: any) => String(mId) === id,
                  )?.inWork
                }
              </div>
            </TitleValue>
            <TitleValue>
              <div className="title">Заказов всего</div>
              <div className="value">
                {data?.orders?.length || 0}
                {/* {data?.allOrdersCount} */}
              </div>
            </TitleValue>
            {data?.orders && (
              <TitleValue>
                <div className="title">Заказчик</div>
                <div className="value">{data?.customer}</div>
              </TitleValue>
            )}
          </BudgetDeadline>
          {data?.orders && (
            <>
              <Desc>
                <div className="title">Примечание</div>
                <div className="text">{data?.description}</div>
              </Desc>
              <Address>
                <div className="title">Адрес</div>
                <div className="text">{data?.address}</div>
              </Address>
            </>
          )}
        </DataWrapper>
        {data?.orders.length === 0 ? (
          <Empty className="emptyTag" />
        ) : (
          <OrdersListWrapper>
            {data?.orders?.map(
              ({
                id,
                title,
                categoryId,
                createdAt,
                address,
                status,
                startDate,
                endDate,
                category,
                offerCount,
              }: any) => (
                <OrderItem
                  key={id}
                  onClick={() => navigate(`/o/${id}`)}
                  // onClick={() => navigate(`/${id}*`)}
                >
                  <TitlePart>
                    <Excavator />
                    <div className="text">{title}</div>
                    <div className="date">{moment(createdAt).format("ll")}</div>
                  </TitlePart>
                  <BodyPart>
                    <div>{address}</div>
                    <div>
                      Выполнить &#160;
                      {moment(startDate).format("ll")}
                    </div>
                  </BodyPart>
                  <FooterPart>
                    <div className="offer">
                      <img src={avatarWorker} alt="" />
                      Откликов: {offerCount}
                    </div>
                    <div className="status process">
                      <ChevronRight />
                      {status === "InProgress"
                        ? "Идут работы"
                        : status === "Finished"
                        ? "Завершено"
                        : status === "Published"
                        ? "Опубликовано"
                        : status === "Done"
                        ? "Завершен"
                        : status === "WorkerSetOut"
                        ? "Идут работы"
                        : status === "WorkerSelected"
                        ? "Исполнитель выбран"
                        : status}
                    </div>
                  </FooterPart>
                </OrderItem>
              ),
            )}
          </OrdersListWrapper>
        )}
      </OrderWrapper>
    </Wrapper>
  );
};

export default memo(DashboardRightManagerData);
