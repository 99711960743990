import React, { memo, useCallback, useEffect } from "react";
import {
  Header,
  StatusCard,
  StatusCardsList,
  Wrapper,
  WrapperBackground,
} from "./styles";
import StatusTrack from "./statusTrack";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-24-intellicon-basic-circle-x.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/icons-24-status-icon-check-green.svg";
import { ReactComponent as WaitIcon } from "../../assets/icons/icons-24-status-icon-waiting.svg";
import { CustomerRole } from "../../types";
import {
  WorkerOrderStatuses,
  CustomerOrderStatuses,
} from "../../constants/orderStatusCards";

interface StatusHistorySidebarProps {
  history: any[];
  refId: string;
  orderId: string | number;
  role: CustomerRole;
  open: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  status: string | undefined;
}

const StatusHistorySidebar: React.FC<StatusHistorySidebarProps> = ({
  open,
  toggleOpen,
  history,
  refId,
  orderId,
  role,
  status,
}) => {
  useEffect(() => {
    open
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [open]);

  const renderStatusCards = useCallback(() => {
    const statusArray =
      role === "worker" ? WorkerOrderStatuses : CustomerOrderStatuses;
    const lastStatusIndex = statusArray.findIndex(
      (orderStatus) => orderStatus.type === status,
    );
    const lastStatus = statusArray.find(
      (orderStatus) => orderStatus.type === status,
    );
    return [
      <StatusCard key={`status_${-1}`}>
        <div className="top">
          <WaitIcon />
          <div className="titleGroup">{lastStatus?.title}</div>
        </div>
        <div className="bottom">{lastStatus?.text}</div>
      </StatusCard>,
      statusArray
        .map((status, index) => {
          if (index < lastStatusIndex) {
            return (
              <StatusCard key={`${status.title}`}>
                <div className="top">
                  <CheckIcon />
                  <div className="titleGroup">{status?.title}</div>
                </div>
                <div className="bottom">{status?.text}</div>
              </StatusCard>
            );
          }
        })
        .reverse(),
    ];
  }, [status]);

  return (
    <>
      <WrapperBackground
        open={open}
        onClick={() => toggleOpen(false)}
      ></WrapperBackground>
      <Wrapper open={open}>
        <Header>
          Выполнение заказа <CloseIcon onClick={() => toggleOpen(false)} />
        </Header>
        {/* <StatusTrack
          history={history}
          role={role}
          refId={refId}
          orderId={orderId}
        /> */}
        <StatusCardsList>{renderStatusCards()}</StatusCardsList>
      </Wrapper>
    </>
  );
};

export default memo(StatusHistorySidebar);
