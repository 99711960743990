import styled from "styled-components";

export const Wrapper = styled.div<{ inputToggle: boolean }>`
  width: 100%;
  max-width: fit-content;
  display: flex;
  gap: 9px;
  align-self: center;
  align-items: center;
  overflow-x: scroll;
  margin-bottom: 40px;
  box-sizing: content-box;
  padding: 8px 0 32px;
  min-height: 32px;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  .inputToggle {
    display: ${({ inputToggle }) => (inputToggle ? "none" : "block")};
  }

  input {
    padding: 8px 4px;
    font-size: 14px;
    line-height: 1;
    height: 32px;
    text-align: center;
    transition: all 0.3s linear;
    display: ${({ inputToggle }) => (!inputToggle ? "none" : "block")};
    border: 2px solid #ffffff;
    border-radius: 10px;
    outline: none;
    max-width: 40px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      appearance: textfield;
    }

    &:focus {
      color: #222222;
      border: solid 2px #757575;
    }
  }
`;

export const Button = styled.div<{ square?: boolean }>`
  height: 32px;
  ${({ square }) => square && "aspect-ratio: 1 / 1"};
  padding: ${({ square }) => (square ? "8px 4px" : "8px 21px")};
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: #757575;
  border-radius: 10px;
  border: solid 2px #e8e8e8;
  cursor: pointer;
  transition: all 0.3s linear;

  &:hover {
    color: #222222;
    border: solid 2px #757575;
  }
  &.active {
    color: #ff6633b0 !important;
    border: solid 2px rgba(255, 102, 51, 0.24) !important;
  }
  .active {
  }

  @media screen and (max-width: 768px) {
    ${({ square }) => !square && "padding: 8px 12px"};
  }
`;
