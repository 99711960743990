import styled, { keyframes } from "styled-components";
import { Button } from "../../components/form/styles";
import { styles, Text16 } from "../../components/styles/styles";

export const ProfileWrapper = styled.div`
  width: 100%;
  @media only screen and (min-width: 1440px) {
    width: 920px;
  }
`;

export const FirstSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  margin: 16px 0 24px;
`;

export const InviteLinkWrapper = styled.div`
  height: 100%;
  background-color: #f7f4f1;
  padding: 24px;
  border-radius: 8px;
  .text {
    font-size: 14px;
    line-height: 1.14;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

export const AvatarBox = styled.div`
  /* width: 50%; */
  width: 100%;
  display: flex;
`;

const animation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const AvatarImg = styled.div`
  width: 80px;
  height: 80px;
  overflow: hidden;
  margin-right: 15px;
  position: relative;
  display: flex;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
  }

  .loading {
    width: 50%;
    height: 50%;
    border-radius: 50%;
    border: 1px solid black;
    border-bottom-color: transparent;
    margin: auto;
    animation-name: ${animation};
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .remove-img {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    display: flex;
    border-radius: 50%;
    opacity: 0;
    cursor: pointer;
  }

  &:hover {
    .remove-img {
      opacity: 1;
    }
  }
`;

export const AvatarData = styled.div`
  /* width: calc(100% - (80px + 15px)); */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .verified-user {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;

    span {
      width: fit-content;
      display: block;
      margin-right: 7px;
    }

    .box {
      display: flex;
      position: relative;

      .sign {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        text-align: center;
        font-size: 10px;
        line-height: 18px;
        vertical-align: middle;
        color: ${styles.mainColor};
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  .registered-time {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-top: 3px;
  }
`;

export const AddImgBtn = styled.label`
  width: 32px;
  height: 32px;
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.84);
  display: flex;
  border-radius: 100%;
  opacity: 0;
  transition: all 0.5s ease-out;
  animation: anim 0.2s ease;

  :hover {
    opacity: 1;
  }

  svg {
    width: 18px;
    height: 18px;
    margin: auto;
  }

  input {
    display: none;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const InputWrapper = styled.div`
  width: ${({ threeByOne }: { threeByOne?: boolean }) =>
    threeByOne ? "30%" : "48%"};
  margin-bottom: 4px;
  position: relative;

  button {
    outline: 0;
  }

  .name {
    width: 100%;
    padding: 6px 0;
    background-color: white;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    height: 36px;

    .placeholder {
      color: #9e9e9e;
    }
  }

  .ant-picker,
  .ant-picker-focused {
    border: 0;
    outline: 0;
    box-shadow: 0 0 0;
    padding: 0;
    width: 100%;
  }

  .ant-select-selector,
  .ant-select,
  .ant-select-auto-complete,
  .ant-select-focused,
  .ant-select-open,
  .ant-select-single,
  .ant-select-show-search {
    border: 0 !important;
    outline: none !important;
  }

  .ant-select-selector {
  }

  .ant-picker {
    width: 0;
    opacity: 0;
  }

  .ant-picker-input input {
    width: 0;
    opacity: 0;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }

  @media only screen and (max-width: 576px) {
    margin-bottom: 0;
  }
`;

export const DocTitle = styled(Text16)`
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  max-width: 48%;
  align-items: start;
  font-weight: 500;

  .pending {
    color: #fec826;
  }

  .fulfilled {
    color: #71c562;
  }

  .rejected {
    color: #ff3358;
  }
  @media only screen and (max-width: 1050px) {
    max-width: 100%;
  }
`;

export const DocsWrapper = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: 20px;

  .question_mark {
    margin-left: 0.5rem;
    cursor: pointer;
  }
`;

export const DocWrapper = styled.div`
  width: 48%;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;
  border: solid 2px var(--color-black);
  position: relative;
  overflow: hidden;
  //box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);

  ${({ done }: { done?: boolean }) => done && "border: 1px solid black;"}
  .background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: #00000011;
  }

  .icon-box {
    margin-right: 16px;
    display: flex;
    position: relative;
    z-index: 2;
  }

  .text-part {
    position: relative;
    z-index: 2;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.87);
    }

    .desc {
      width: fit-content;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.543846);
      /* margin-top: 10px; */
      display: inline-block;
    }
  }

  .status-icon {
    margin-left: auto;
    display: flex;
    position: relative;
    z-index: 2;
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
  }
`;

export const SaveBtn = styled(Button)`
  margin-bottom: 15px;
  width: fit-content;
  padding: 8px 26px;
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-bottom: 35px;
  }
`;

export const CityList = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 55px;
  width: 100%;
  height: fit-content;
  max-height: 200px;
  min-height: 0;
  background-color: white;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  z-index: 4;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);
  box-shadow: 0 3px 6px -1px rgba(34, 60, 80, 0.2);
  scrollbar-width: none;
  scrollbar-color: #b8b8b8 #ffffff;
  z-index: 6;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b8b8b8;
    border-radius: 10px;
    border: 0 solid #ffffff;
  }

  .item {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    b {
      margin-right: 5px;
    }

    &:hover {
      background-color: #ebebeb;
    }
  }

  .not-found {
    width: 100%;
    height: 100px;
    background-color: lightblue;
  }
`;

export const InputPart = styled.div`
  width: calc(100% - 48px - 10px);
`;
