import { screen } from "@testing-library/react";
import { FilterText } from "./../../pages/dashboardOrders/styles";
import styled from "styled-components";
import { styles, TextLight14 } from "../styles/styles";

export const ErrorMsg = styled.div`
  color: ${styles.red};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #ff3358;
  mix-blend-mode: normal;
  opacity: 0.5;
  margin-bottom: 7px;
`;

export const PinInput = styled.div`
  display: flex !important;
  text-align: center;
  margin: 32px 0 0;
`;

export const PinInputField = styled.input`
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 8px;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  border-radius: 10px;
  transition: all 0.3s linear;
  border: 1px solid
    ${({ error }: { error: boolean }) =>
      error ? "#FF3358" : "rgba(147, 147, 147, 1)"};
  outline: 0;

  ${({ error }: { error: boolean }) => (error ? `color: #FF3358;` : "")}
  &:focus {
    border-color: ${({ error }: { error: boolean }) =>
      error ? styles.red : "rgba(254, 200, 38, 1)"};
  }

  &:last-child {
    margin-right: 0;
  }

  @media only screen and (max-width: 350px) {
    width: 40px;
    height: 40px;
    margin-right: 4px;
    justify-content: left;
  }
`;

export const CodeSendTo = styled(TextLight14)`
  margin-top: 15px;
  text-align: center;
`;

export const DidNotReceiveCode = styled(TextLight14)`
  text-align: center;
  margin-top: 18px;
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
  opacity: 1;
`;

export const TimeRemained = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 20px;

  span {
    color: #222222;

    mix-blend-mode: normal;
    opacity: 0.5;
  }
`;

export const FormDesc = styled.div`
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
`;

export const Back = styled.div`
  cursor: pointer;
  display: inline-flex;
  gap: 8px;
  padding: 8px 20px;
  border-radius: 18px;
  background-color: #eee7e1;
  margin-bottom: 24px;

  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  text-align: center;
  color: #1a110f;
  display: flex;
  width: fit-content;
  align-items: center;
  position: absolute;
  top: -56px;
  @media only screen and (max-width: 768px) {
    top: -35px;
  }
  @media only screen and (max-width: 576px) {
    position: relative;
    top: 0;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const ButtonsWrapper = styled.div``;
