import React, { memo } from "react";
import { HeaderWrapper } from "./styles";

interface IHeaderProps {
  text: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  Icon?: any;
  onClick?: () => void;
  padding?: string;
}

const Header: React.FC<IHeaderProps> = ({
  text,
  color,
  fontSize,
  fontWeight,
  lineHeight,
  Icon,
  onClick,
  padding,
}) => {
  return (
    <HeaderWrapper
      color={color}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      padding={padding}
    >
      {text}
      {Icon && <Icon onClick={() => onClick && onClick()} />}
    </HeaderWrapper>
  );
};

export default memo(Header);
