import React, { memo, useEffect, useState } from "react";
import {
  CdCard,
  ContentCard,
  Document,
  DocumentsCard,
  InputContainerInline,
  InputsWrapperRow,
  OrgDataCard,
  OrgDataPageWrapper,
  ProfileDataCard,
  ProfileInfo,
} from "./styles";
import { ReactComponent as PlusIcon } from "../../assets/icons/plus-orange.svg";
import { ReactComponent as DigitalDispatcherIcon } from "../../assets/icons/icons-digital-dispatch-logo.svg";
import CheckIcon from "../../assets/icons/ic-verified-user.png";
import UpIcon from "../../assets/icons/icons-up-right.png";
import ProfileIcon from "../../assets/icons/icons-person.png";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ActualAddress } from "../addOrgPage/styles";
import { InputBox } from "../userRegistration/InputBox";
import PassportPopUp from "../../components/popUp/passportPopUp";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import moment from "moment";

import { getMe } from "../../redux/store/reducers/authSlice";
import useHttpRequest from "../../hooks/useHttpRequest";
import defaultAvatar from "../../assets/images/avatar.png";
import { ENDPOINT } from "../../constants/general";
import { toast } from "react-toastify";
import { setCompanyRegistration } from "../../redux/store/reducers/registrationSlice";
import { SwitchRoleWrapperXL } from "../../components/sidebar/styles";
import { updateProfileInfo } from "../../redux/store/reducers/profileSlice";
import { JobTitles } from "../dashboardEmployeesPage";
import { Skeleton } from "antd";
import { getCompany } from "../../redux/store/reducers/companySlice";
import { getRequisitesByInn } from "../../redux/store/reducers/companySlice";

const inputsList = [
  {
    label: "ИНН организации",
    name: "inn",
    width: "246px",
    type: "inn",
    value: "9710101610",
    height: "48px",
    borderColor: "#e5e5e5",
  },
];

const inputListStep1 = [
  {
    label: "Полное название",
    name: "full_name",
    value: "Общество с ограниченной ответственностью РУККИ",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "Краткое название",
    name: "short_name",
    value: "ООО РУККИ",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "Юридический адрес",
    name: "legal_address",
    value: "123056, город Москва, 2-Я Брестская ул, д. 5, помещ. 39",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "КПП",
    name: "kpp",
    value: "771001001",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "ОГРН",
    name: "ogrn",
    value: "1227700503964",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "Должность представителя",
    name: "representative_position",
    value: "Генеральный директор",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
  {
    label: "ФИО представителя",
    name: "full_name_representative",
    value: "Зенин Игорь Игоревич",
    width: "100%",
    height: "48px",
    borderColor: "#e5e5e5",
  },
];

const inputListStep2 = [
  {
    label: "Фамилия*",
    name: "secondName",
    width: "300px",
    value: "Иванов",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "text",
  },
  {
    label: "Имя*",
    name: "firstName",
    width: "300px",
    value: "Сергей",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "text",
  },
  {
    label: "Отчество",
    name: "patronymic",
    width: "300px",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "text",
  },
  {
    label: "Телефон",
    name: "phoneNumber",
    width: "220px",
    value: "Иванов",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "tel",
  },
  {
    label: "Электронная почта*",
    name: "email",
    width: "220px",
    value: "adres@pochta.ru",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "email",
  },
  {
    label: "Страна*",
    name: "country",
    width: "220px",
    value: "Российская Федерация",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "country",
  },
  {
    label: "Город*",
    name: "city",
    width: "220px",
    value: "Москва",
    height: "48px",
    borderColor: "#e5e5e5",
    type: "city",
  },
];

interface UserInput {
  label: string;
  name: string;
  width: string;
  value: string;
  height: string;
  borderColor: string;
  type: string;
}

interface UserDataState {
  [key: string]: string | null;
}

const defaultUserData: UserDataState = {
  firstName: "",
  secondName: "",
  patronymic: "",
  email: "",
  phoneNumber: "",
  country: "",
  city: "",
};

const OrgDataPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [userData, setUserData] = useState<any>(defaultUserData);
  const [companyData, setCompanyData] = useState<any>({});

  const user = useAppSelector((state: RootState) => state.auth.user);
  const customer = useAppSelector(
    (state: RootState) => state.auth?.user?.customer,
  );
  const company = useAppSelector(
    (state: RootState) => state.company.companyData,
  );

  useEffect(() => {
    if (customer) {
      setUserData({
        firstName: user?.customer?.firstName,
        secondName: user?.customer?.secondName,
        patronymic: user?.customer?.patronymic,
        email: user?.customer?.email,
        phoneNumber: user?.customer?.phoneNumber,
        city: { text: user?.customer?.city, open: false },
        country: {
          text: "Российская Федерация",
          open: false,
          data: {
            id: 643,
            alpha2: "ru",
            alpha3: "rus",
            name: "Российская Федерация",
            country_id: 3159,
            flag: "🇷🇺",
            dial_code: "+7",
          },
        },
      });
    }
  }, [customer]);

  useEffect(() => {
    if (customer?.refId) {
      dispatch(getCompany(customer?.refId));
    }
  }, [customer?.refId]);

  const inputChangeHandler = (name: string, value: string) => {
    const letterRegex = new RegExp("^[а-яА-Я]+$");
    if (name !== "phoneNumber") {
      if (
        (name === "secondName" ||
          name === "firstName" ||
          name === "patronymic") &&
        value !== ""
      ) {
        if (letterRegex.test(value)) {
          setUserData({ ...userData, [name]: value });
        }
      } else {
        setUserData({ ...userData, [name]: value });
      }
    }
  };

  const [searchRequisiteProcess, searchRequisiteRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      setCompanyData((prev: any) => ({
        ...prev,
        full_name: res?.data?.full_name,
        short_name: res?.data?.short_name,
        inn: res?.data?.inn,
        kpp: res?.data?.kpp,
        full_name_representative: res?.data?.chief,
        representative_position: res?.data?.chief_position,
        ogrn: res?.data?.ogrn,
        legal_address: res?.data?.address,
      }));
    },
    onError: () => {
      setCompanyData(null);
    },
  });

  const searchRequisite = (inn: any) => {
    searchRequisiteRequest({
      url: `${ENDPOINT}/api/customers/find-by-inn?q=${inn}`,
      method: "POST",
    });
  };

  useEffect(() => {
    if (company?.requisites?.inn) {
      const inn = company?.requisites?.inn;
      searchRequisite(inn);
    }
  }, [company?.requisites?.inn]);

  const updateSuccessToast = () => {
    toast.success("Данные успешно сохранены", {
      autoClose: 3000,
    });
  };

  const updateProfileData = async (field: string) => {
    let updateProfile: any = {};
    if (field) {
      if (field === "city" || field === "country") {
        updateProfile = {
          [field]: userData[field]?.text,
        };
      } else if (field === "email") {
        const emailRegEx =
          /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (emailRegEx.test(userData[field]))
          updateProfile = {
            [field]: userData[field],
          };
      } else {
        updateProfile = {
          [field]: userData[field],
        };
      }
    } else {
      updateProfile = {
        firstName: userData.firstName,
        secondName: userData.secondName,
        patronymic: userData.patronymic,
        city: userData.city.text,
        country: userData.country.text,
        email: userData.email,
      };
    }

    if (
      user?.customer?.refId &&
      Object.keys(updateProfile).length &&
      updateProfile[field] !== "" &&
      updateProfile[field] !== (customer as any)[field]
    ) {
      dispatch(
        updateProfileInfo({
          refId: user?.customer?.refId,
          data: { ...updateProfile },
        }),
      ).then(() => {
        toast.success("Данные успешно сохранены", {
          autoClose: 3000,
        });
      });
    }
  };

  const [updateAvatarProcess, updateAvatarRequest] = useHttpRequest({
    onSuccess: () => {
      if (user?.customer?.refId) dispatch(getMe(user.customer?.refId));
      updateSuccessToast();
    },
  });

  const updateAvatar = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const data = new FormData();
      data.append("image", file);

      updateAvatarRequest({
        method: "PUT",
        path: `customers/${user?.customer?.refId}/upload-avatar`,
        data,
      });
    }
  };

  const deleteAvatar = () => {
    updateAvatarRequest({
      method: "POST",
      path: `customers/${user?.customer?.refId}/avatar/remove`,
    });
  };

  const changeRole = (role: string) => {
    user?.customer?.refId &&
      user?.customer?.role !== role &&
      dispatch(
        updateProfileInfo({
          refId: user?.customer?.refId,
          data: { role: role },
        }),
      );
  };

  const getCompanyRole = (companyRole: string): string => {
    return JobTitles.find((job) => job.value === companyRole)?.label!;
  };

  const getProfileInfo = () => {
    if (
      (user?.customer?.companyId && company && companyData?.short_name) ||
      (user?.customer && !user?.customer.companyId)
    ) {
      return (
        <>
          <div className="title">
            {user?.customer?.companyId
              ? `Вы работаете как ${companyData?.short_name}${
                  !!user?.customer?.companyRole
                    ? `, должность: ${getCompanyRole(
                        user?.customer?.companyRole,
                      )}`
                    : ""
                }`
              : user?.customer.firstName && user?.customer.secondName
              ? `Вы работаете как ${user?.customer.firstName} ${user?.customer.secondName}, физлицо`
              : `Вы работаете как физлицо`}
          </div>
          <div className="text">
            {customer?.companyId
              ? `Зарегистрирован ${moment(company?.createdAt).format(
                  "DD.MM.YYYY",
                )}`
              : `Зарегистрирован ${moment(customer?.createdAt).format(
                  "DD.MM.YYYY",
                )}`}
          </div>
        </>
      );
    } else {
      return (
        <Skeleton
          paragraph={{ rows: 1, style: { margin: 0 } }}
          style={{ height: "24px" }}
          title={{
            width: "100%",
            style: { margin: "0 0 8px 0", height: "40px" },
          }}
          active
        />
      );
    }
  };

  return (
    <OrgDataPageWrapper>
      <ContentCard half>
        <ProfileInfo>
          <div className="profilePhoto">
            <div className="imgContainer">
              <img
                src={
                  user?.customer?.avatarUrl
                    ? `data:image/jpeg;base64,${user?.customer?.avatarUrl}`
                    : defaultAvatar
                }
                alt="avatar"
              />
            </div>
            <div className="profilePhotoGroup">
              <div className="title">Фото профиля</div>
              <div className="buttons">
                <label style={{ cursor: "pointer" }}>
                  Загрузить
                  <input
                    onChange={updateAvatar}
                    type="file"
                    style={{ display: "none" }}
                  />
                </label>
                <div style={{ cursor: "pointer" }} onClick={deleteAvatar}>
                  Удалить
                </div>
              </div>
            </div>
          </div>
          <SwitchRoleWrapperXL
            id={"rolePicker"}
            width="233px"
            height="32px"
            active={user?.customer?.role === "customer"}
          >
            <div
              style={{ cursor: "pointer" }}
              className={`btn ${
                user?.customer?.role === "customer" && "active"
              }`}
              onClick={() => changeRole("customer")}
            >
              Я заказчик
            </div>
            <div
              style={{ cursor: "pointer" }}
              className={`btn ${user?.customer?.role === "worker" && "active"}`}
              onClick={() => changeRole("worker")}
            >
              Я исполнитель
            </div>
          </SwitchRoleWrapperXL>
          <div className="profileInfo">{getProfileInfo()}</div>
        </ProfileInfo>
      </ContentCard>
      <ContentCard
        half
        //  width={"484px"}
      >
        {customer?.companyId ? (
          customer.companyRole === "ceo" && (
            <CdCard>
              <DigitalDispatcherIcon />
              <div className="content">
                <div className="title">Хотите больше возможностей?</div>
                <div className="text">
                  Подключите услугу «Цифровой диспетчер» и получите доступ к
                  документообороту, управлением командами и другим возможностям
                </div>
                <div
                  className="button"
                  onClick={() => {
                    if (customer?.companyId) {
                      navigate("/digital-dispatcher");
                    } else {
                      dispatch(setCompanyRegistration(true));
                      navigate({
                        pathname: "/registration",
                        search: `${createSearchParams({
                          org: "true",
                        })}`,
                      });
                    }
                  }}
                >
                  Подключить услугу
                </div>
              </div>
            </CdCard>
          )
        ) : (
          <CdCard>
            <DigitalDispatcherIcon />
            <div className="content">
              <div className="title">Работаете, как юридическое лицо?</div>
              <div className="text">
                Создайте профиль организации и получите доступ к
                документообороту, управлением командами и другим возможностям
              </div>
              <div
                className="button"
                onClick={() => {
                  if (customer?.companyId) {
                    navigate("/digital-dispatcher");
                  } else {
                    dispatch(setCompanyRegistration(true));
                    navigate({
                      pathname: "/registration",
                      search: `${createSearchParams({
                        org: "true",
                      })}`,
                    });
                  }
                }}
              >
                Создать профиль организации
              </div>
            </div>
          </CdCard>
        )}
      </ContentCard>
      {customer?.companyId ? (
        <ContentCard>
          <OrgDataCard>
            <div className="title">Реквизиты организации</div>
            {companyData ? (
              <>
                <InputsWrapperRow>
                  {inputsList.map(
                    (
                      { label, name, value, width, type, height, borderColor },
                      index,
                    ) =>
                      name === "search-btn" ? (
                        <></>
                      ) : (
                        <InputBox
                          key={`companyData1_${index}`}
                          label={label}
                          width={width}
                          name={name}
                          //onChange={changeFormData}
                          value={companyData["inn"]}
                          type={type}
                          height={height}
                          small={true}
                          disabled={true}
                          borderColor={borderColor}
                        />
                      ),
                  )}
                </InputsWrapperRow>
                {inputListStep1.map(
                  ({ label, name, width, value, height, borderColor }, index) =>
                    name === "search-btn" ? null : name === "actual_address" ? (
                      <ActualAddress key={`companyData2_${index}`}>
                        <InputBox
                          label={label}
                          width={width}
                          name={name}
                          //onChange={changeFormData}
                          value={companyData[name]}
                          height={height}
                          small
                          disabled={true}
                          borderColor={borderColor}
                        />
                      </ActualAddress>
                    ) : name === "inn" ||
                      name === "kpp" ||
                      name === "ogrn" ||
                      name === "okpo" ? (
                      <InputContainerInline key={`companyData2_${index}`}>
                        <InputBox
                          label={label}
                          width={width}
                          name={name}
                          value={companyData[name]}
                          height={height}
                          small
                          disabled={true}
                          borderColor={borderColor}
                        />
                      </InputContainerInline>
                    ) : label === "ИНН организации" ? (
                      <InputBox
                        key={`companyData2_${index}`}
                        label={label}
                        width={width}
                        name={name}
                        //onChange={changeFormData}
                        value={companyData[name]}
                        height={height}
                        small
                        disabled={true}
                        borderColor={borderColor}
                      />
                    ) : (
                      <InputBox
                        key={`companyData2_${index}`}
                        label={label}
                        width={width}
                        name={name}
                        //onChange={changeFormData}
                        value={companyData[name]}
                        height={height}
                        small
                        disabled={true}
                        borderColor={borderColor}
                      />
                    ),
                )}
              </>
            ) : (
              <>
                {Array.from(Array(9).keys()).map((item, index) => {
                  return (
                    <Skeleton
                      key={`requisites_skeleton_${index}`}
                      active
                      paragraph={{
                        rows: 0,
                        style: { margin: 0 },
                      }}
                      title={{
                        width: `${index === 0 ? "246px" : "100%"}`,
                        style: {
                          height: "48px",
                          margin: "0",
                          borderRadius: "10px",
                        },
                      }}
                    />
                  );
                })}
              </>
            )}
          </OrgDataCard>
        </ContentCard>
      ) : null}

      <ContentCard>
        <ProfileDataCard>
          <div className="title">Официальный представитель</div>
          {inputListStep2.map(
            ({ label, name, width, height, borderColor, type }, index) => {
              return (
                <InputBox
                  key={`userData_${index}`}
                  label={label}
                  width={width}
                  name={name}
                  onChange={(e: any) => {
                    inputChangeHandler(name, e.target.value);
                  }}
                  value={userData[name] || ""}
                  height={height}
                  small
                  orgPage
                  borderColor={borderColor}
                  type={type}
                  onBlur={updateProfileData}
                  formData={userData}
                  disabled={type === "tel"}
                />
              );
            },
          )}
        </ProfileDataCard>
      </ContentCard>
    </OrgDataPageWrapper>
  );
};

export default memo(OrgDataPage);

