import TextareaAutosize from "react-textarea-autosize";
import styled from "styled-components";
import { styles } from "../styles/styles";

export const HeaderPart = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background-color: white;
  padding: 16px 24px;
  box-shadow: 0px 2px 4px 3px rgb(0 0 0 / 10%);
  z-index: 2;

  @media only screen and (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 576px) {
    position: unset;
  }
`;

export const NamePart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;

  .go-back {
    width: 24px;
    height: 24px;
  }

  .full-name {
    font-weight: 500;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    line-height: 1;
    cursor: pointer;

    .membersCount {
      font-size: 12px;
      font-weight: 450;
      color: #00000080;
    }
  }

  .call-icon {
    display: flex;
    cursor: pointer;
  }

  .setting-icon {
    display: flex;
    position: relative;
    margin-left: auto;
    appearance: none;
    border: none;
    outline: none;
    background: none;

    svg {
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1050px) {
    width: 100%;
    .setting-icon {
      margin-left: auto;
    }
  }
  @media only screen and (max-width: 576px) {
    .go-back {
      display: block;
    }
  }
`;

export const Typing = styled.div`
  font-size: 0.7rem;
  font-style: italic;
  margin-left: 15px;
  -webkit-animation: blink1 2s linear infinite;
  animation: blink1 2s linear infinite;
  @-webkit-keyframes blink1 {
    0% {
      color: rgba(34, 34, 34, 1);
    }
    50% {
      color: rgba(34, 34, 34, 0);
    }
    50% {
      color: rgba(34, 34, 34, 0);
    }
    100% {
      color: rgba(34, 34, 34, 1);
    }
  }
  @keyframes blink1 {
    0% {
      color: rgba(34, 34, 34, 1);
    }
    50% {
      color: rgba(34, 34, 34, 0);
    }
    100% {
      color: rgba(34, 34, 34, 1);
    }
  }
`;

export const SettingBox = styled.div<{ open: boolean }>`
  position: absolute;
  top: 30px;
  right: 0px;
  background-color: white;
  filter: drop-shadow(0px 2px 42px rgba(0, 0, 0, 0.08));
  border-radius: 10px;
  z-index: 10;
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  overflow: hidden;
  height: ${({ open }) => (open ? "136px" : "0px")};
  animation: ${({ open }) =>
    open ? "settingBoxOpen 0.2s ease-in" : "settingBoxClose 0.2s ease-in"};

  .settingBoxItem {
    display: flex;
    width: 100%;
    padding: 6px 12px;
    transition: all 0.3s linear;
    font-size: 14px;
    background: #ffffff;
    color: #222222;
    cursor: pointer;
    user-select: none;
    gap: 4px;
    white-space: nowrap;

    &:hover {
      color: #ffffff;
      background: #ff6633;
    }
  }

  @keyframes settingBoxOpen {
    0% {
      display: none;
      height: 0px;
    }
    100% {
      display: flex;
      height: 136px;
    }
  }

  @keyframes settingBoxClose {
    0% {
      display: flex;
      height: 136px;
    }
    100% {
      display: none;
      heihgt: 0px;
    }
  }
`;

export const SettingList = styled.div`
  .item {
    display: block;
    padding: 15px 0;
    border-bottom: 1px solid #ebebeb;

    a {
      color: black;
    }

    &.red {
      div {
        cursor: pointer;
      }
    }

    &:last-child {
      border: 0;
    }
  }

  .red {
  }
`;

export const TaskPart = styled.div`
  background: #ffedb5;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .icon {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .text {
    margin: 0 10px 0 5px;
    width: 220px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .right-arrow {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 1250px) {
    .text {
      width: fit-content;
    }
  }
  @media only screen and (max-width: 1050px) {
    .text,
    & {
      width: 100%;
    }
  }
`;

export const MessagesPart = styled.div`
  display: flex;
  flex-grow: 1;
  position: relative;
  overflow: hidden;

  .scrollToBottom {
    width: 36px;
    height: 36px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    scale: 0;
    right: 8px;
    background: #ff6633;
    cursor: pointer;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.35);

    &.open {
      scale: 1;
      bottom: 12px;
      animation: scrollToBottomOpen 0.2s linear;
    }

    &.close {
      scale: 0;
      bottom: -36px;
      animation: scrollToBottomClose 0.2s linear;
    }

    @keyframes scrollToBottomOpen {
      0% {
        bottom: -36px;
        scale: 0;
      }
      100% {
        bottom: 12px;
        scale: 1;
      }
    }

    @keyframes scrollToBottomClose {
      0% {
        bottom: 12px;
        scale: 1;
      }
      100% {
        bottom: -36px;
        scale: 0;
      }
    }
  }

  .wrapper {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    height: 100%;
    padding: 12px 24px 12px;
    scrollbar-width: auto;
    scrollbar-color: #c4c4c4 #ffffff;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #c4c4c4;
      border: none;
    }

    div:first-child {
      margin-top: auto !important;
    }
  }

  @media screen and (max-width: 768px) {
    .wrapper {
      padding: 8px 12px;
    }
  }

  @media screen and (max-width: 576px) {
    .wrapper {
      padding: 8px;
    }
  }

  @media screen and (max-width: 480px) {
    .wrapper {
      padding: 8px 12px;
    }
  }
`;

export const FilesWrapper = styled.div`
  display: flex;
  gap: 0px 20px;
  flex-wrap: wrap;

  p {
    margin: 0;
    display: flex;
    align-items: center;

    svg {
      display: flex;
      cursor: pointer;
    }
  }
`;

export const InputMsgPart = styled.div`
  padding: 0 24px 24px;
  display: flex;
  flex-direction: column;
  height: max-content;
  background-color: #f3f7f8;
  border-radius: 10px;
  margin: 8px 24px 24px;
  padding: 8px 8px 8px 16px;
  transition: all 0.2s linear;

  .counterOfferButton {
    height: 36px;
    width: 220px;
    margin-bottom: 8px;
  }

  .topPart {
    height: 0px;
    animation: topPartClose 0.2s linear;
    display: flex;
    overflow-y: hidden;
    gap: 4px;
    transition: all 0.2s linear;

    &.active {
      height: 74px;
      animation: topPartOpen 0.2s linear;
    }

    @keyframes topPartOpen {
      0% {
        height: 0px;
      }
      100% {
        height: 74px;
      }
    }

    @keyframes topPartClose {
      0% {
        height: 74px;
      }
      100% {
        height: 0px;
      }
    }
  }

  .bottomPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f3f7f8;
    transition: all 0.2s linear;
  }

  .buttonsContainer {
    display: flex;
    gap: 8px;
    align-items: center;
    align-self: flex-end;
  }

  .attachIcon {
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover {
      g {
        rect {
          transition: all 0.3s linear;
          fill: #000000;
        }
      }
    }
  }

  .sentIcon {
    border-radius: 50%;
    cursor: pointer;
    opacity: 0.5;
    font-size: 150%;
    transition: all 0.3s linear;

    &:hover {
      opacity: 1;
    }
  }

  .attachment {
    width: 29px;
    height: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-top: auto;
    cursor: pointer;
    opacity: 0.8;
    transition: all 0.3s linear;

    &:hover {
      opacity: 1;
    }

    input {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    margin: 8px 12px 12px;
  }

  @media screen and (max-width: 576px) {
    margin: 8px 8px 8px;
  }

  @media screen and (max-width: 480px) {
    margin: 8px 12px 12px;
  }
`;

export const Textarea = styled(TextareaAutosize)`
  width: calc(100% - 40px);
  outline: 0;
  border: 0;
  background-color: transparent;
  min-height: 20px;
  resize: none;
  font-size: 14px;
  line-height: 1.14;
  color: #222222;
  padding: 2px 0;
  transition: all 0.2s linear;
  align-self: flex-end;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 234px;
  width: fit-content;
  border-radius: 10px;
  padding: 12px 12px 22px 12px;
  background-color: #f3f7f8;
  font-size: 15px;
  line-height: 1.07;
  letter-spacing: -0.25px;
  color: #000;
  align-self: flex-start;
  position: relative;
  white-space: pre-line;
  min-width: 70px;

  &.audio {
    display: flex;
    width: fit-content;
    max-width: unset;
  }

  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    cursor: pointer;
    user-select: none;
  }

  audio {
    width: 100%;
    min-width: 300px;
    user-select: none;
  }

  video {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
    cursor: pointer;
    user-select: none;
  }

  .file {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    box-sizing: content-box;
    font-family: "Rubik";
    font-weight: 450;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    user-select: none;
    background-color: rgb(243, 247, 248);
    color: #222222;
    padding: 8px 8px 4px;
    cursor: pointer;
    line-height: 1;
  }

  &.userMessage {
    background-color: #ff6633;
    color: #ffffff;
    align-self: flex-end;

    .timeBlock {
      svg {
        fill: #ffffff;
        display: block !important;
      }
    }

    .fileLoadingIndicator {
      width: 100%;
      display: flex;
      background-color: #00000080;
      border-radius: 8px;
      span {
        width: 24px;
        height: 24px;
        border: 3px solid #fff;
        border-bottom-color: transparent;
        border-radius: 50%;
        margin: auto auto;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
      }

      &.image {
        min-width: 150px;
        aspect-ratio: 1 / 1;
      }

      &.audio {
        min-width: 300px;
        height: 54px;
      }

      @keyframes rotation {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .timeBlock {
    position: absolute;
    display: flex;
    align-items: flex-end;
    font-size: 12px;
    color: inherit;
    gap: 4px;
    bottom: 4px;
    right: 4px;

    svg {
      width: 15px;
      height: 15px;
      display: none;
    }
  }
`;

export const MsgTextBox = styled.div`
  width: fit-content;
  display: flex;
  margin-top: 10px;
  margin-left: ${({ my }: { my?: boolean }) => (my ? "auto" : "0px")};
  /* display: "flex"; */
  align-items: center;
  justify-content: center;
  gap: 5px;

  .user {
    width: 30px;
    height: 30px;
    margin: auto 5px 0 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
    }
  }

  .text {
    width: fit-content;
    min-width: 20px;
    max-width: 420px;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background-color: ${({ my }: { my?: boolean }) =>
      my ? styles.mainColor : "#f3f7f8"};
    color: ${({ my }: { my?: boolean }) => (my ? "white" : "black")};
    /* line-break: anywhere;
    word-break: break-all; */
    overflow-wrap: break-word;
  }

  label {
    font-size: 10px;
    opacity: 0.6;
  }

  .rate-box {
    padding: 10px 0;

    .header {
      display: flex;
      align-items: center;

      .data {
        margin-right: 20px;
        width: calc(100% - 50px);

        b {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }

        .desc {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #222222;
          mix-blend-mode: normal;
          opacity: 0.5;
        }
      }

      .img {
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;
        }
      }
    }

    .rate-wrapper {
      margin-top: 30px;

      .rate {
        margin-bottom: 10px;

        .ant-rate {
          .ant-rate-star {
            height: fit-content;
            margin-right: 18px !important;
          }
        }

        .rate-result {
          margin-top: 15px;

          b {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            margin-right: 10px;
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #222222 !important;
          }
        }
      }

      .number {
        display: flex;
        align-items: center;

        b {
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          margin-right: 10px;
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #222222;
        }
      }
    }
  }

  .rate {
    .ant-rate-star-zero,
    .ant-rate-star-focused {
      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #c7c3c1;
      }
    }

    .ant-rate-star-full,
    .ant-rate-star-full.ant-rate-star-focused {
      color: #fec826 !important;

      .ant-rate-star-first,
      .ant-rate-star-second {
        color: #fec826;
      }
    }
  }

  .star-icon {
    font-size: 45px;
  }

  @media only screen and (max-width: 640px) {
    .text {
      max-width: 250px;
    }

    .star-icon {
      font-size: 30px;
    }

    .rate-box {
      .rate-wrapper {
        margin-top: 20px;

        .rate {
          margin-bottom: 10px;

          .ant-rate {
            .ant-rate-star {
              margin-right: 10px !important;
            }
          }
        }
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  margin: 12px 0 0;
  gap: 16px;

  .counterOffer {
    width: fit-content;
    display: flex;
    // we are using webkit for safari br
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: rgb(255, 102, 51);
    color: white;
    border-radius: 10px;
    font-size: 16px;
    border: 0;
    margin-left: auto;

    :hover {
      background-color: rgb(255, 102, 51);
      color: white;
      opacity: 0.8;
    }
  }

  /* button {
    width: fit-content;
    margin: 0 8px 0 0;
    padding: 10px 16px;
    line-height: 18px;

    &:last-child {
      margin: 0 0 0 8px;
    }
  } */

  button {
    padding: 8px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    //background: rgba(255, 255, 255, 0.5);
    background: rgba(0, 0, 0, 0.01);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    svg {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  @media only screen and (max-width: 1050px) {
    /* display: none; */
    button {
      padding: 7px 15px;
    }
  }
  @media only screen and (max-width: 350px) {
    flex-direction: column;
    button {
      margin: 5px 0 0;
      justify-content: center;
    }
  }
`;

export const CounterOfferMsgBox = styled(MsgTextBox)`
  .text-box {
    width: fit-content;

    .text {
      margin-bottom: 10px;
    }

    .btns-wrapper {
      display: flex;
      gap: 10px;
      justify-content: flex-start;

      button {
        padding: 6px 21px;
        margin: 0;
        font-size: 14px;

        &.decline {
          background-color: white;
          border: 1px solid ${styles.mainColor};
          color: ${styles.mainColor};
        }
      }
    }
  }
`;

export const PopupWrapper = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? "flex" : "none")};
  background: ${({ open }) =>
    open ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0)"};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 4;
  animation: ${({ open }) =>
    open ? "WrapperOpen 0.1s linear" : "WrapperClose 0.1s linear"};

  @media screen and (max-width: 576px) {
    height: 100dvh;
    width: 100vw;
  }

  @keyframes WrapperOpen {
    0% {
      display: none;
      background: rgba(0, 0, 0, 0);
    }
    100% {
      display: flex;
      background: rgba(0, 0, 0, 0.3);
    }
  }

  @keyframes WrapperClose {
    0% {
      display: flex;
      background: rgba(0, 0, 0, 0.3);
    }
    100% {
      display: none;
      background: rgba(0, 0, 0, 0);
    }
  }
`;

export const PopupContent = styled.div<{
  open: boolean;
  isInvite: boolean;
  width?: string;
  lineHeight?: string;
}>`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  opacity: ${({ open }) => (open ? "100%" : "0%")};
  scale: ${({ open }) => (open ? "1" : "0.6")};
  animation: ${({ open }) =>
    open ? "PopupOpen 0.2s linear" : "PopupClose 0.2s linear"};
  background: #ffffff;
  border-radius: 10px;
  padding: 16px;
  width: ${({ width }) => (width ? width : "300px")};
  height: 500px;
  gap: 24px;
  z-index: 4;
  box-shadow: 0px 0px 5px 2px #00000040;
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};

  .popupHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    font-weight: 550;

    svg {
      cursor: pointer;
    }
  }

  .popupBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    white-space: pre-line;

    .buttonsWrapper {
      display: flex;
      gap: 8px;
      height: 36px;
      margin-top: auto;
    }

    .membersList {
      display: flex;
      flex-direction: column;
      gap: 16px;
      overflow: auto;
    }

    .inviteMemberList {
      display: ${({ isInvite }) => (isInvite ? "flex" : "none")};
      scale: ${({ isInvite }) => (isInvite ? "1" : "0.6")};
      opacity: ${({ isInvite }) => (isInvite ? "100%" : "0%")};
      animation: ${({ isInvite }) =>
        isInvite
          ? "inviteListOpen 0.2s linear"
          : "inviteListClose 0.2s linear"};
      flex-direction: column;
      gap: 16px;
      background: #ffffff;
      box-shadow: 0px 0px 5px 2px #00000040;
      border-radius: 8px;
      bottom: 80px;
      left: 8px;
      right: 8px;
      overflow: hidden;
      position: absolute;
      padding: 8px;
      height: 350px;
      overflow: auto;

      @keyframes inviteListOpen {
        0% {
          display: none;
          scale: 0.6;
          opacity: 0%;
        }
        100% {
          display: flex;
          scale: 1;
          opacity: 100%;
        }
      }

      @keyframes inviteListClose {
        0% {
          display: flex;
          scale: 1;
          opacity: 100%;
        }
        100% {
          display: none;
          scale: 0.6;
          opacity: 0%;
        }
      }
    }

    .plusButton {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      cursor: pointer;
      transition: all 0.3s linear;
      color: #222222;
      font-size: 14px;
      position: relative;
      margin-top: auto;

      svg {
        transition: all 0.3s linear;
        g {
          transition: all 0.3s linear;
        }
      }

      &:hover:not(.inviteMemberList):not(.bodyItem) {
        color: #ff6633;

        svg {
          g {
            g {
              fill: #ff6633;
            }
          }
        }
      }
    }

    .bodyItem {
      display: flex;
      gap: 8px;
      align-items: center;
      cursor: pointer;
      color: #222222;

      img {
        width: 36px;
        height: 36px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }

      .flex-group {
        display: flex;
        flex-direction: column;
        font-size: 15px;
        gap: 2px;
        line-height: 1;

        .subText {
          font-size: 12px;
          line-height: 1;
          color: #757575;
        }
      }

      svg {
        margin-left: auto;
        cursor: pointer;
        transition: all 0.3s linear;

        &:hover {
          fill: #ff6633;
        }
      }
    }
  }

  @keyframes PopupOpen {
    0% {
      display: none;
      opacity: 0%;
    }
    100% {
      display: flex;
      opacity: 100%;
    }
  }

  @keyframes PopupClose {
    0% {
      display: flex;
      opacity: 100%;
      scale: 1;
    }
    100% {
      display: none;
      opacity: 0%;
      scale: 0.6;
    }
  }
`;

