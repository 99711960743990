export const thousandSeparator = (number: any) => {
  return number
    ? String(number)
        .split(".")[0]
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ") +
        (String(number).split(".")[1] !== "00" &&
        String(number).split(".")[1] !== undefined
          ? "." + String(number).split(".")[1]
          : "")
    : 0;
};

export const displayPhoneNumber = (ph: string) => {
  return ph
    ? `${ph.slice(0, 2)} (${ph.slice(2, 5)}) ${ph.slice(5, 8)}-${ph.slice(
        8,
        10,
      )}-${ph.slice(10, 12)}`
    : "";
};

export const getRandomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomNumberFloat = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};
