import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 1392px;
  margin: 0 auto;

  @media screen and (max-width: 1440px) {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    gap: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  @media screen and (max-width: 998px) {
    background: #ffffff;
    border-radius: 16px;
    padding: 24px;
  }

  @media screen and (max-width: 768px) {
    background: #ffffff;
    border-radius: 16px;
    max-width: 736px;
    padding: 16px;
  }

  @media screen and (max-width: 480px) {
    background: none;
    padding: 0;
  }
`;

export const PageText = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #000;
  display: flex;
  max-width: 518px;

  @media screen and (max-width: 768px) {
    display: flex;
    max-width: 465px;
  }

  @media screen and (max-width: 480px) {
    padding: 0 16px;
  }
`;

export const OrderBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 546px;
  background: #ffffff;
  padding: 16px;
  border-radius: 8px;

  .text {
  }

  .formBottom {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #999999;
  }

  @media screen and (max-width: 998px) {
    width: 100%;
    background-color: #ffffff;
    max-width: unset;
    border-radius: 16px;
  }

  @media screen and (max-width: 768px) {
    .formBottom {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    border-radius: 0;
    padding: 8px;
  }
`;
