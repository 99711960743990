import React, { useEffect, useRef, useState } from "react";
import {
  BarDesc,
  BarHeader,
  ButtonsWrapper,
  PopUpAcceptBody,
  PopUpRateBody,
  ProcessBar,
  ProcessBarList,
  ProcessFinished,
  ProcessItem,
  ProcessSteps,
  Title,
  Wrapper,
} from "./styles";

import { StarFilled } from "@ant-design/icons";
import { Rate } from "antd";
import { ReactComponent as CheckIcon } from "../../assets/icons/Check.svg";
import { ReactComponent as CheckStroke } from "../../assets/icons/CheckStroke.svg";
import { InputBox } from "../form";
import { Button } from "../form/styles";
import PopUpContainer from "../popUp/popUpContainer";
import { RootState } from "../../redux/store/store";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import moment from "moment";
import { getHistoryName, getWorkerData } from "../../utils/uiHelpers";
import { useNavigate } from "react-router-dom";
import Loading from "../loading";
import useHttpRequest from "../../hooks/useHttpRequest";
import { ENDPOINT, GEOCODE_MAPS } from "../../constants/general";
import {
  getOrderDocs,
  getSingleOrders,
} from "../../redux/store/reducers/orderSlice";
import { toast } from "react-toastify";
import RatingBox from "../ratingBox";

interface History {
  id: number;
  orderId: number;
  type: string;
  workerId: number;
  createdAt: string;
  moreInfo: string;
}

const OrderProcess: React.FC = () => {
  const [accept, setAccept] = useState(false);
  const [rateOpen, setRateOpen] = useState(false);
  const [startWorkPopUp, setStartWorkPopUp] = useState(false);
  const [arrivedAtWorkPopUp, setArrivedAtWorkPopUp] = useState(false);
  const [goingToWorkPopUp, setGoingToWorkPopUp] = useState(false);
  const [cancelPopUp, setCancelPopUp] = useState(false);
  const [finishPopUp, setFinishPopUp] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [rateAmount, setRateAmount] = useState(5);
  const [rateDesc, setRateDesc] = useState("");
  const [selectedWorker, setSelectedWorker] = useState<any | null>(null);
  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [firstLocation, setFirstLocation] = useState<any>([]);
  const [address, setAddress] = useState<any>({});
  const [workFinished, setWorkFinished] = useState<boolean>(false);
  const [locationStatus, setLocationStatus] = useState({
    error: false,
    success: false,
  });
  const [historyBar, setHistoryBar] = useState<any>({
    created: {},
    worker_selected: {},
    started: {},
    result: {},
    done: {},
    rate: {},
  });

  const [orderPrice, setOrderPrice] = useState<number>(0);
  const [statusLoading, setStatusLoading] = useState<boolean>(false);

  const errorToast = useRef(null);
  const errorToastId = "error-toast";

  const [addressProcess, makeAddressRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const { name, description } =
        res?.data?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject;
      setAddress({ name, description, coordinates: location });
    },
  });

  const getAddress = ([latitude, longitude]: any) => {
    makeAddressRequest({
      method: "GET",
      url: GEOCODE_MAPS,
      params: {
        geocode: [longitude, latitude].join(","),
        lang: "ru_RU",
        apikey: "7446be07-f1bc-4ce3-a5fe-0a423e51a168",
        format: "json",
      },
    });
  };

  useEffect(() => {
    if (
      accept ||
      rateOpen ||
      startWorkPopUp ||
      arrivedAtWorkPopUp ||
      goingToWorkPopUp ||
      cancelPopUp ||
      finishPopUp
    ) {
      setButtonClicked(false);
    }
  }, [
    accept,
    rateOpen,
    startWorkPopUp,
    arrivedAtWorkPopUp,
    goingToWorkPopUp,
    cancelPopUp,
    finishPopUp,
  ]);

  const getUserLocation = (mount?: boolean) => {
    window?.navigator?.geolocation?.getCurrentPosition(
      (position) => {
        setLocationStatus({
          error: false,
          success: true,
        });

        const { longitude, latitude } = position?.coords;
        setCenter([latitude, longitude]);
        setLocation([latitude, longitude]);
        if (mount) {
          setFirstLocation([latitude, longitude]);
        } else {
          getAddress([latitude, longitude]);
        }
      },
      (err) => {
        //@ts-ignore
        if (!toast.isActive(errorToast?.current)) {
          errorToast.current! = toast.error(
            "Чтобы видеть вашу геопозицию, откройте доступ в настройках браузера.",
            {
              autoClose: 7000,
              toastId: errorToastId,
            },
          ) as never;
        }

        setLocationStatus({
          error: true,
          success: false,
        });
      },
    );
  };

  const DisplayLocation = ({ customer, latitude, longitude }: any) => {
    return customer && latitude && longitude ? (
      <div className="location">
        <a
          style={{ alignSelf: "end", textAlign: "end" }}
          target={"_blank"}
          href={`https://yandex.ru/maps/?whatshere[point]=${longitude},${latitude}&whatshere[zoom]=18`}
          rel="noreferrer"
        >
          GPS: {latitude} {longitude}
        </a>
      </div>
    ) : (
      <></>
    );
  };

  const { singleOrder, images, offers, orderDocs, orderActions } =
    useAppSelector((state: RootState) => state.order);

  const [process, makeRequest] = useHttpRequest({
    onSuccess: async () => {
      if (user?.customer?.refId) {
        await dispatch(
          getSingleOrders({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
        dispatch(
          getOrderDocs({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
        setGoingToWorkPopUp(false);
        setArrivedAtWorkPopUp(false);
        setStartWorkPopUp(false);
        setCancelPopUp(false);
        setFinishPopUp(false);
        setAccept(false);
      }
    },
    onError: () => {
      setGoingToWorkPopUp(false);
    },
  });

  useEffect(() => {
    if (process.loading) {
      setStatusLoading(true);
    }
    if (process.success) {
      setStatusLoading(false);
    }
  }, [process]);

  const user = useAppSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    if (offers?.length > 0) setSelectedWorker(getWorkerData(offers));
    else setSelectedWorker(null);
  }, [offers]);

  // const getPorgressBarNum = (array: History[]): number => {
  //   const pieceOfBar = 100 / array.length;
  //
  //   return 0;
  // };

  useEffect(() => {
    if (user?.customer?.role === "worker") {
      getUserLocation(true);
    }
  }, [user]);

  useEffect(() => {
    document.body.style.overflow = "unset";
  }, []);

  useEffect(() => {
    setHistoryBar({
      created: {},
      worker_selected: {},
      started: {},
      result: {},
      done: {},
    });

    singleOrder?.history?.forEach((v: any) => {
      const { id, orderId, type, workerId, createdAt, moreInfo } = v;
      setHistoryBar((prev: any) => {
        switch (type) {
          case "created":
            return {
              ...prev,
              created: v,
            };
          case "worker_selected":
            return {
              ...prev,
              worker_selected: v,
            };
          case "waiting_for_start":
            return {
              ...prev,
              started: v,
            };
          case "started":
            return {
              ...prev,
              started: v,
            };
          case "waiting_for_finish":
            return {
              ...prev,
              result: v,
            };
          case "done":
            return {
              ...prev,
              done: v,
            };
          default:
            return prev;
        }
      });
    });
    const rate = singleOrder?.order?.feedbacks?.[0];
    if (rate) {
      setHistoryBar((prev: any) => ({
        ...prev,
        rate: { createdAt: rate?.createdAt },
      }));
    }
  }, [singleOrder?.history]);

  const checkEsmSigned = () => {
    let EsmSigned = false;
    let WayBillSigned = false;
    let ActSigned = false;
    let CommittentReportWorker = false;

    orderDocs?.forEach((doc: any, index: number) => {
      if (
        doc.documentType === "completed_work" &&
        doc.isSignedByWorker &&
        doc.isSignedByCustomer
      ) {
        EsmSigned = true;
      }

      if (
        doc.documentType === "waybill" &&
        doc.isSignedByWorker &&
        doc.isSignedByCustomer
      ) {
        WayBillSigned = true;
      }

      if (
        doc.documentType === "act" &&
        doc.isSignedByWorker &&
        doc.isSignedByCustomer
      ) {
        ActSigned = true;
      }
      if (
        doc.documentType === "committent_report_worker" &&
        doc.isSignedByWorker
      ) {
        CommittentReportWorker = true;
      }
    });

    return EsmSigned && WayBillSigned && ActSigned && CommittentReportWorker;
  };

  const checkDocsSigned = () => {
    let isSigned = true;

    orderDocs?.forEach((doc) => {
      if (
        doc.documentType === "tech_task" &&
        (!doc.isSignedByWorker || !doc.isSignedByCustomer)
      ) {
        isSigned = false;
      }
      if (
        doc.documentType === "framework" &&
        (!doc.isSignedByWorker || !doc.isSignedByCustomer)
      ) {
        isSigned = false;
      }
      if (doc.documentType === "bill" && !doc.isSignedByWorker) {
        isSigned = false;
      }
    });

    return isSigned;
  };

  // const getBackgroundArray = () => {
  //   const resultDone = history?.find((item: any) => item.type === "done");
  //   if (resultDone) return historyBackDone;
  //   const result = history?.find((item: any) => item.type === "started");
  //   if (result) return historyBackStarted;
  //   return historyBack;
  // };

  const getOrderPrice = () => {
    offers?.map((offer: any) => {
      if (offer.status === "Accepted") {
        setOrderPrice(offer.budget);
      }
    });
  };

  useEffect(() => {
    if (offers) getOrderPrice();
  }, [offers]);

  const goingToWork = () => {
    setButtonClicked(true);
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/going`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
  };

  const arrivedAtWork = () => {
    setButtonClicked(true);
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/set-out`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
  };

  const startWork = () => {
    setButtonClicked(true);
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/start`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
  };

  const cancelOrder = () => {
    setButtonClicked(true);
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/cancel`,
    });
  };

  const finishOrder = async () => {
    setButtonClicked(true);
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/waiting-for-done`,
      data: {
        location: {
          latitude: location[0],
          longitude: location[1],
        },
      },
    });
    user?.customer?.refId &&
      dispatch(
        getOrderDocs({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
  };

  const doneOrder = async () => {
    makeRequest({
      method: "POST",
      url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/done`,
    });
    user?.customer?.refId &&
      dispatch(
        getOrderDocs({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
  };

  const isObjEmpty = (obj: object) => {
    return Object.keys(obj).length === 0;
  };

  const [rateProcess, rateRequest] = useHttpRequest({
    onSuccess: () => {
      setRateOpen(false);
      user?.customer?.refId &&
        dispatch(
          getSingleOrders({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
    },
  });

  const rate = () => {
    const data = {
      description: rateDesc,
      rating: rateAmount,
      orderId: singleOrder?.order?.id,
      rewards: [],
    };

    let workerId = offers.find(
      ({ status }: any) => status === "Accepted",
    )?.workerId;
    let creatorId = singleOrder.order.creatorId;
    let userId;
    if (Number(user?.customer?.id) === Number(creatorId)) {
      userId = workerId;
    }
    if (Number(user?.customer?.id) === Number(workerId)) {
      userId = creatorId;
    }

    rateRequest({
      method: "POST",
      url: `${ENDPOINT}/feedback/${user?.customer?.refId}/user/${userId}`,
      data,
    });
  };

  if (singleOrder === null)
    return (
      <Loading
        title="Загрузка заказа"
        description="Не закрывайте окно браузера"
        noHeight={false}
        half
      />
    );

  return (
    <Wrapper id="order_process">
      <Title>Выполнение заказа</Title>
      <ProcessSteps
        step={
          Object.values(historyBar).filter((v: any) => !isObjEmpty(v)).length
        }
        lengtOfArray={Object.keys(historyBar).length - 1}
      >
        <div className="process-track">
          <div className="process-bar" />
          <div className="process-steps">
            <>
              <ProcessItem active={!isObjEmpty(historyBar?.created || {})}>
                <div className="process-name">{getHistoryName("created")}</div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.created || {})
                    ? moment(historyBar?.created?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                  {/* {historyBar?.created?.createdAt &&
                    moment(
                      historyBar?.created?.createdAt
                      , "YYYY-MM-DDThh:mm:ss.SSSZ"
                    )?.calendar()}
                  {
                    moment(
                      historyBar?.created?.createdAt,
                      "YYYY-MM-DDThh:mm:ss.SSSZ"
                    ).format("lll")} */}
                </div>
              </ProcessItem>
              <ProcessItem
                active={!isObjEmpty(historyBar?.worker_selected || {})}
              >
                <div className="process-name">
                  {getHistoryName("worker_selected")}
                </div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.worker_selected || {})
                    ? moment(historyBar?.worker_selected?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                </div>
              </ProcessItem>
              {/*<ProcessItem*/}
              {/*    active={!isObjEmpty(historyBar?.worker_selected || {})}*/}
              {/*>*/}
              {/*  <div className="process-name">*/}
              {/*    {getHistoryName("worker_set_out")}*/}
              {/*  </div>*/}
              {/*  <div className="circle"/>*/}
              {/*  <div className="process-date">*/}
              {/*    {!isObjEmpty(historyBar?.worker_selected || {})*/}
              {/*        ? moment(historyBar?.worker_selected?.createdAt).calendar()*/}
              {/*        : ""}*/}
              {/*  </div>*/}
              {/*</ProcessItem>*/}
              <ProcessItem active={!isObjEmpty(historyBar?.started || {})}>
                <div className="process-name">
                  {getHistoryName(
                    isObjEmpty(historyBar?.result)
                      ? "waiting_for_start"
                      : "started",
                  )}
                </div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.started || {})
                    ? moment(historyBar?.started?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                </div>
              </ProcessItem>
              <ProcessItem active={!isObjEmpty(historyBar?.result || {})}>
                <div className="process-name">
                  {getHistoryName("waiting_for_finish")}
                </div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.result || {})
                    ? moment(historyBar?.result?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                </div>
              </ProcessItem>
              <ProcessItem active={!isObjEmpty(historyBar?.done || {})}>
                <div className="process-name">{getHistoryName("done")}</div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.done || {})
                    ? moment(historyBar?.done?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                </div>
              </ProcessItem>
              <ProcessItem active={!isObjEmpty(historyBar?.rate || {})}>
                <div className="process-name">{getHistoryName("rate")}</div>
                <div className="circle" />
                <div className="process-date">
                  {!isObjEmpty(historyBar?.rate || {})
                    ? moment(historyBar?.rate?.createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })
                    : ""}
                </div>
              </ProcessItem>
            </>
          </div>
        </div>
      </ProcessSteps>

      {singleOrder?.history?.find((item: any) => item.type === "done") &&
        user?.customer?.role === "customer" && (
          <ProcessFinished>
            <div className="text">
              <CheckStroke /> Заказ завершен
            </div>
            <Button fit onClick={() => navigate("/create-order")}>
              Создать новый заказ
            </Button>
          </ProcessFinished>
        )}
      <ProcessBarList>
        {/* RATING ACTION */}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "done")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
              }: any,
              index: number,
            ) => {
              const feedbacks = singleOrder?.order?.feedbacks;
              const rated = singleOrder?.order?.feedbacks.find(
                ({ createdBy }: any) => createdBy?.id === user?.customer?.id,
              );

              return (
                <>
                  {feedbacks.map(
                    ({
                      createdAt,
                      createdBy: { id: creatorId },
                      description,
                      id,
                      rating,
                      type,
                      updatedAt,
                    }: any) => (
                      <ProcessBar key={`wait2${index}`}>
                        <BarHeader>
                          <div className="status">
                            {feedbacks.length ? (
                              <div className="active">
                                <CheckIcon />
                              </div>
                            ) : (
                              <div className="progress" />
                            )}
                          </div>
                          <div className="data">
                            <div className="name">
                              {(Number(user?.customer?.id) ===
                                Number(singleOrder?.order?.creatorId) &&
                                Number(user?.customer?.id) ===
                                  Number(creatorId)) ||
                              (Number(user?.customer?.id) ===
                                Number(workerId) &&
                                Number(user?.customer?.id) ===
                                  Number(creatorId))
                                ? "Ваш отзыв"
                                : Number(singleOrder?.order?.creatorId) ===
                                  Number(creatorId)
                                ? "Заказчик оставил отзыв"
                                : Number(workerId) === Number(creatorId)
                                ? "Исполнитель оставил отзыв"
                                : ""}
                            </div>
                            <div className="date">
                              {moment(createdAt).format("DD.MM.YYYY")}
                            </div>
                          </div>
                          <div className="rating-part">
                            <RatingBox rating={rating} />{" "}
                            <span>{rating}.0</span>
                          </div>
                        </BarHeader>
                        {description}
                        {/* {!rated && (
                          <ButtonsWrapper>
                            <Button fit onClick={() => setRateOpen(true)}>
                              Оставить отзыв
                            </Button>
                          </ButtonsWrapper>
                        )} */}
                      </ProcessBar>
                    ),
                  )}
                  {(user?.customer?.id === singleOrder.order.creatorId ||
                    user?.customer?.id === workerId) &&
                    !rated && (
                      <ProcessBar key={`wait2${index}`}>
                        <BarHeader>
                          <div className="status">
                            <div className="progress" />
                          </div>
                          <div className="data">
                            <div className="name">
                              {user?.customer?.id ===
                              singleOrder.order.creatorId
                                ? "Оставьте отзыв исполнителю"
                                : user?.customer?.id === workerId
                                ? "Оставьте отзыв клиенту"
                                : ""}
                            </div>
                            <div className="date">
                              {moment(createdAt).format("DD.MM.YYYY")}
                            </div>
                          </div>
                        </BarHeader>
                        <ButtonsWrapper>
                          <Button
                            fit
                            onClick={() => setRateOpen(true)}
                            disabled={statusLoading}
                          >
                            Оставить отзыв
                          </Button>
                        </ButtonsWrapper>
                      </ProcessBar>
                    )}
                </>
              );
            },
          )}

        {/* ACTION TO SHOW, ORDER IS FINISHED */}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "done")
          .map(
            ({
              id,
              orderId,
              type,
              workerId,
              createdAt,
              moreInfo,
              startDate,
            }: any) => {
              return (
                <ProcessBar key={id}>
                  <BarHeader>
                    <div className="status">
                      <div className="active">
                        <CheckIcon />
                      </div>
                    </div>
                    <div className="data">
                      <div className="name">Работа выполнена</div>
                      <div className="date">
                        {moment(createdAt).format("DD.MM.YYYY")}
                      </div>
                    </div>
                  </BarHeader>
                </ProcessBar>
              );
            },
          )}
        {/* ACTION FOR ACCEPT WORK */}
        {singleOrder?.history
          ?.filter(
            (item: any) =>
              item.type ===
              (singleOrder?.history?.find(
                ({ type }: any) => type === "wait_for_accept",
              )
                ? "wait_for_accept"
                : "finished"),
          )
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
              }: any,
              index: number,
            ) => {
              const isAccepted = singleOrder?.history?.find(
                ({ type }: any) => type === "done",
              );
              return (
                <ProcessBar key={`wait2${index}`}>
                  <BarHeader>
                    <div className="status">
                      {/* <div className="not-active">
                            <CrossIcon />
                          </div> */}
                      {isAccepted ? (
                        <div className="active">
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className="progress" />
                      )}
                    </div>
                    <div className="data">
                      <div className="name">Заказчик принимает работу</div>
                    </div>
                  </BarHeader>
                  {!isAccepted &&
                  (user?.customer?.id === singleOrder.order.creatorId ||
                    (singleOrder?.order?.company?.id ===
                      user?.customer.companyId &&
                      user?.customer.accessSettings.acceptanceWork)) ? (
                    <>
                      {!checkEsmSigned() ? (
                        "Подпишите документы, чтобы принять работу."
                      ) : (
                        <div>
                          Сначала удостоверьтесь в том, что работа выполнена в
                          полном объеме,
                          <br />
                          затем нажимайте кнопку «Принять работу»
                        </div>
                      )}

                      <ButtonsWrapper>
                        <Button
                          fit
                          onClick={() => setAccept(true)}
                          disabled={
                            !checkEsmSigned() ||
                            statusLoading ||
                            user?.customer?.companyId !==
                              singleOrder?.order?.company?.id ||
                            !user?.customer.accessSettings.acceptanceWork
                          }
                        >
                          Принять работу
                        </Button>
                      </ButtonsWrapper>
                    </>
                  ) : !checkEsmSigned() ? (
                    <>
                      {
                        "Подпишите документы, чтобы заказчик мог принять работу."
                      }
                    </>
                  ) : (
                    <>{"Ожидаем прием работ заказчика."}</>
                  )}
                </ProcessBar>
              );
            },
          )}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "started")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
              }: any,
              index: number,
            ) => {
              // type === "created" ? (
              const isFinished = singleOrder?.history?.find(
                ({ type }: any) =>
                  type ===
                  (singleOrder?.history?.find(
                    ({ type }: any) => type === "wait_for_accept",
                  )
                    ? "wait_for_accept"
                    : "finished"),
              );
              return (
                <ProcessBar key={`wait2${index}`}>
                  <BarHeader>
                    <div className="status">
                      {/* <div className="not-active">
                            <CrossIcon />
                          </div> */}
                      {isFinished ? (
                        <div className="active">
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className="progress" />
                      )}
                    </div>
                    <div className="data">
                      <div className="name">
                        {user?.customer?.role === "customer"
                          ? isFinished
                            ? "Исполнитель закончил работу."
                            : "Исполнитель еще не закончил работу."
                          : isFinished
                          ? "Вы закончили работу."
                          : "Вы закончили работу?"}
                      </div>
                    </div>
                  </BarHeader>
                  <h4></h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ maxWidth: "500px" }}>
                      {"Исполнитель завершил работу на объекте: " +
                        singleOrder?.order?.address}
                    </p>
                    <DisplayLocation
                      customer={user?.customer?.role === "customer"}
                      latitude={isFinished?.latitude}
                      longitude={isFinished?.longitude}
                    />
                  </div>
                  {selectedWorker?.worker?.refId === user?.customer?.refId &&
                    !isFinished && (
                      <>
                        Сначала удостоверьтесь в том, что работа выполнена в
                        полном объеме,
                        <br />
                        затем нажимайте кнопку «Закончить работу»
                        <ButtonsWrapper>
                          <Button
                            fit
                            onClick={() => setFinishPopUp(true)}
                            disabled={statusLoading}
                          >
                            Закончить работу
                          </Button>
                          {/* <div className="cancel">
                        <button>Отправить на доработку</button>
                      </div> */}
                        </ButtonsWrapper>
                      </>
                    )}
                </ProcessBar>
              );
            },
          )}

        {singleOrder?.history
          ?.filter((item: any) => item.type === "worker_set_out")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
                latitude,
                longitude,
              }: any,
              index: number,
            ) => {
              const isStarted = singleOrder?.history?.find(
                ({ type }: any) => type === "started",
              );
              return (
                <ProcessBar key={`wait${index}`}>
                  <BarHeader>
                    <div className="status">
                      {isStarted ? (
                        <div className="active">
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className="progress" />
                      )}
                    </div>
                    <div className="data">
                      <div className="name">
                        {user?.customer?.role === "customer"
                          ? isStarted
                            ? "Исполнитель начал работу."
                            : "Исполнитель еще не начал работу."
                          : isStarted
                          ? "Вы начали работу, удачи!"
                          : "Перед началом работы, пожалуйста, нажмите кнопку «Начать работу»."}
                      </div>
                      <div className="date">
                        {moment(createdAt).calendar({
                          sameDay: "Сегодня в HH:mm",
                          lastDay: "Вчера в HH:mm",
                          sameElse: "DD.MM.YYYY",
                        })}
                      </div>
                    </div>
                  </BarHeader>
                  <h4></h4>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text" style={{ width: "500px" }}>
                      Исполнитель прибыл на объект и начал работу:{" "}
                      {singleOrder?.order?.address}
                    </div>
                    <DisplayLocation
                      customer={user?.customer?.role === "customer"}
                      latitude={isStarted?.latitude}
                      longitude={isStarted?.longitude}
                    />
                  </div>
                  <br />
                  {selectedWorker?.worker?.refId === user?.customer?.refId &&
                    !isStarted && (
                      <ButtonsWrapper>
                        <div className="btn_wrapper">
                          <Button
                            fit
                            onClick={() => setStartWorkPopUp(true)}
                            disabled={!location[0] || statusLoading}
                          >
                            Начать работу
                          </Button>
                          {/* <div className="cancel">
                        <button>Отправить на доработку</button>*/}
                        </div>
                      </ButtonsWrapper>
                    )}
                </ProcessBar>
              );
            },
          )}

        {singleOrder?.history
          ?.filter(
            (item: any) =>
              item.type === "going_to_destination" ||
              item.type === "going_go_destination",
          )
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
                latitude,
                longitude,
              }: any,
              index: number,
            ) => {
              const waitingForStart = singleOrder?.history?.find(
                ({ type }: any) => type === "worker_set_out",
              );
              return (
                <ProcessBar key={`wait${index}`}>
                  <BarHeader>
                    <div className="status">
                      {waitingForStart ? (
                        <div className="active">
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className="progress" />
                      )}
                    </div>
                    <div className="data">
                      <div className="name">
                        {waitingForStart
                          ? user?.customer?.role === "customer"
                            ? "Исполнитель прибыл на объект"
                            : "Вы в пункте назначения."
                          : user?.customer?.role === "customer"
                          ? "Исполнитель еще не прибыл в пункт назначения"
                          : "Работа ждет вас в пункте назначения"}
                      </div>
                      <div className="date">
                        {moment(createdAt).calendar({
                          sameDay: "Сегодня в HH:mm",
                          lastDay: "Вчера в HH:mm",
                          sameElse: "DD.MM.YYYY",
                        })}
                      </div>
                    </div>
                  </BarHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text">
                      {waitingForStart
                        ? user?.customer?.role === "customer"
                          ? `Исполнитель отправился на объект: ${singleOrder?.order?.address}`
                          : `Вы в пункте назначения: ${singleOrder?.order?.address}`
                        : ""}
                    </div>
                    <DisplayLocation
                      customer={user?.customer?.role === "customer"}
                      latitude={waitingForStart?.latitude}
                      longitude={waitingForStart?.longitude}
                    />
                  </div>
                  {selectedWorker?.worker?.refId === user?.customer?.refId &&
                    !waitingForStart && (
                      <ButtonsWrapper>
                        <div className="btn_wrapper">
                          <Button
                            fit
                            onClick={() => setArrivedAtWorkPopUp(true)}
                            disabled={!location[0]}
                          >
                            Я прибыл на объект
                          </Button>
                          {!location[0] && (
                            <Button
                              fit
                              className="btn_geo"
                              onClick={() => getUserLocation()}
                            >
                              Поделиться геопозицией
                            </Button>
                          )}
                        </div>
                      </ButtonsWrapper>
                    )}
                </ProcessBar>
              );
            },
          )}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "worker_selected")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
                latitude,
                longitude,
              }: any,
              index: number,
            ) => {
              const goingToDestination = singleOrder?.history?.find(
                ({ type }: any) =>
                  type === "going_to_destination" ||
                  type === "going_go_destination" ||
                  type === "worker_set_out",
              );
              return (
                <ProcessBar key={`wait${index}`}>
                  <BarHeader>
                    <div className="status">
                      {goingToDestination ? (
                        <div className="active">
                          <CheckIcon />
                        </div>
                      ) : (
                        <div className="progress" />
                      )}
                    </div>
                    <div className="data">
                      <div className="name">
                        {goingToDestination
                          ? user?.customer?.role === "customer"
                            ? "Исполнитель выехал к месту назначения"
                            : "Вы выехали к месту назначения"
                          : user?.customer?.role === "customer"
                          ? "Исполнитель еще не выехал к месту назначения."
                          : "Вы еще не направились к месту назначения."}
                      </div>
                      <div className="date">
                        {moment(createdAt).calendar({
                          sameDay: "Сегодня в HH:mm",
                          lastDay: "Вчера в HH:mm",
                          sameElse: "DD.MM.YYYY",
                        })}
                      </div>
                    </div>
                  </BarHeader>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="text"></div>
                    <DisplayLocation
                      customer={user?.customer?.role === "customer"}
                      latitude={goingToDestination?.latitude}
                      longitude={goingToDestination?.longitude}
                    />
                  </div>
                  {selectedWorker?.worker?.refId === user?.customer?.refId &&
                    !goingToDestination && (
                      <>
                        {!checkDocsSigned() && (
                          <div>
                            Чтобы приступить к выполнению заказа Вам необходимо
                            ознакомиться и подписать Техническое задание, Счет
                            на оплату и Рамочный договор.
                          </div>
                        )}

                        <ButtonsWrapper>
                          <div className="btn_wrapper">
                            <Button
                              fit
                              onClick={() => setGoingToWorkPopUp(true)}
                              disabled={
                                !location[0] ||
                                !checkDocsSigned() ||
                                statusLoading
                              }
                            >
                              Выехать на объект
                            </Button>

                            {!location[0] && (
                              <Button
                                fit
                                className="btn_geo"
                                onClick={() => getUserLocation()}
                              >
                                Поделиться геопозицией
                              </Button>
                            )}
                          </div>
                        </ButtonsWrapper>
                      </>
                    )}
                </ProcessBar>
              );
            },
          )}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "paid")
          .map(({ createdAt }: any) => {
            return (
              <ProcessBar>
                <BarHeader>
                  <div className="status">
                    <div className="active">
                      <CheckIcon />
                    </div>
                  </div>
                  <div className="data">
                    <div className="name">Оплата заказа</div>
                    <div className="date">
                      {moment(createdAt).calendar({
                        sameDay: "Сегодня в HH:mm",
                        lastDay: "Вчера в HH:mm",
                        sameElse: "DD.MM.YYYY",
                      })}
                    </div>
                  </div>
                </BarHeader>
                {singleOrder?.order?.budget && (
                  <BarDesc>
                    <div className="infoBlock">
                      <div className="column">
                        <div className="info">
                          <div className="subtitle">Способ оплаты</div>
                          <div className="priceBigSize">
                            {singleOrder?.order?.paymentType === "bn"
                              ? "Безналичный"
                              : singleOrder?.order?.paymentType === "bnnds"
                              ? "Безналичный с НДС"
                              : singleOrder?.order?.paymentType === "cash"
                              ? "Наличные"
                              : "По договоренности"}
                          </div>
                        </div>

                        <div className="info">
                          <div className="subtitle">Итого</div>
                          <div className="priceBigSize">{`${orderPrice} ₽`}</div>
                        </div>
                      </div>
                    </div>
                  </BarDesc>
                )}
              </ProcessBar>
            );
          })}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "worker_selected")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
                latitude,
                longitude,
              }: any,
              index: number,
            ) => {
              return (
                <ProcessBar key={`history2${index}`}>
                  <BarHeader>
                    <div className="status">
                      <div className="active">
                        <CheckIcon />
                      </div>
                    </div>
                    <div className="data">
                      <div className="name">
                        {user?.customer?.role === "customer"
                          ? "Выбран исполнитель"
                          : "Выбран исполнитель"}
                      </div>
                      <div className="date">
                        {moment(createdAt).calendar({
                          sameDay: "Сегодня в HH:mm",
                          lastDay: "Вчера в HH:mm",
                          sameElse: "DD.MM.YYYY",
                        })}
                      </div>
                    </div>
                  </BarHeader>
                  <BarDesc>
                    {user?.customer?.id === workerId
                      ? "Заказчик оплатил заказ, и теперь вы можете приступить к работе"
                      : ""}
                    {/* {"Исполнитель приступит к работе " + moment(createdAt, "YYYY-MM-DDThh:mm:ss.SSSZ").calendar().toLowerCase()} */}
                  </BarDesc>
                </ProcessBar>
              );
            },
          )}
        {singleOrder?.history
          ?.filter((item: any) => item.type === "created")
          .map(
            (
              {
                id,
                orderId,
                type,
                workerId,
                createdAt,
                moreInfo,
                startDate,
              }: any,
              index: number,
            ) => {
              return (
                <ProcessBar key={`history${index}`}>
                  <BarHeader>
                    <div className="status">
                      <div className="active">
                        <CheckIcon />
                      </div>
                    </div>
                    <div className="data">
                      <div className="name">
                        {user?.customer?.role === "customer"
                          ? "Заказ создан и ожидает откликов исполнителей"
                          : "Заказ создан"}
                      </div>
                      <div className="date">
                        {moment(createdAt, "YYYY-MM-DDThh:mm:ss.SSSZ").calendar(
                          {
                            sameDay: "Сегодня в HH:mm",
                            lastDay: "Вчера в HH:mm",
                            sameElse: "DD.MM.YYYY",
                          },
                        )}
                      </div>
                    </div>
                  </BarHeader>
                  <BarDesc>
                    {!!singleOrder?.order?.description?.length && (
                      <div className="description">
                        {singleOrder?.order?.description}
                        <br />
                        {moreInfo}
                      </div>
                    )}

                    {singleOrder?.order?.startDate
                      ? "Выполнить " +
                        moment(singleOrder?.order?.startDate).format(
                          "DD.MM.YYYY",
                        )
                      : "Время будет обсуждаться"}
                  </BarDesc>
                </ProcessBar>
              );
            },
          )}
      </ProcessBarList>

      {accept && (
        <PopUpContainer title="Прием работы" close={() => setAccept(false)}>
          <PopUpAcceptBody>
            <div className="title">Вы принимаете работу</div>
            <div className="desc">
              Нажимая кнопку ниже, вы соглашаетесь с тем, что задание выполнено
              и вы принимаете выполненный результат, не имея претензий.
              <br /> <br />
              После подтверждения приема работы исполнитель получит свою оплату
            </div>
            <Button disabled={statusLoading} onClick={doneOrder}>
              Да, принять работу
            </Button>
            <Button className="need-help">Нужна помощь?</Button>
          </PopUpAcceptBody>
        </PopUpContainer>
      )}

      {rateOpen && (
        <PopUpContainer
          middle
          title={
            user?.customer && user?.customer?.role === "customer"
              ? "Оценка исполнителя"
              : "Оценка заказчика"
          }
          close={() => setRateOpen(false)}
          button="Отправить отзыв"
          onSubmit={rate}
          disabled={rateProcess.loading || statusLoading}
        >
          <PopUpRateBody>
            <div className="rate-box">
              <Rate
                character={
                  <div
                    style={{
                      //   width: "40px",
                      //   height: "40px",
                      fontSize: "45px",
                      // marginRight:"28px"
                    }}
                  >
                    <StarFilled />
                  </div>
                }
                value={rateAmount}
                onChange={(e) => setRateAmount(e)}
              />
              {/* <div className="rate-result">
                <b>{rateAmount}.0</b>{" "}
                <span>
                  {rateAmount === 5
                    ? "Отлично"
                    : rateAmount === 4
                    ? "Хорошо"
                    : rateAmount === 3
                    ? "Удовлетворительно"
                    : rateAmount === 2
                    ? "Плохо"
                    : "Ужасно"}
                </span>
              </div> */}
            </div>
            <InputBox
              label="Подробный отзыв"
              type="textarea"
              autoFocus
              value={rateDesc}
              placeholder={
                rateAmount > 2
                  ? "Подробный отзыв"
                  : "Подскажите что вас не устроило в заказе?"
              }
              onChange={(e: any) => setRateDesc(e.target.value)}
            />
            <div className="desc">
              {`${
                user?.customer?.role === "customer" ? "Исполнитель" : "Заказчик"
              } уже оставил отзыв. Чтобы увидеть его, отправьте свою оценку.`}
            </div>
          </PopUpRateBody>
        </PopUpContainer>
      )}
      {goingToWorkPopUp && (
        <PopUpContainer
          title="Еду к месту назначения"
          close={() => setGoingToWorkPopUp(false)}
          onSubmit={goingToWork}
          button="Еду к месту назначения"
          disabled={statusLoading || buttonClicked}
        >
          Вы едете к месту назначения
        </PopUpContainer>
      )}
      {arrivedAtWorkPopUp && (
        <PopUpContainer
          title="Прибыл на объект."
          close={() => setArrivedAtWorkPopUp(false)}
          onSubmit={arrivedAtWork}
          button="Прибыл на объект"
          disabled={rateProcess.loading || statusLoading || buttonClicked}
        >
          Вы уверены, что прибыли в место назначения?
        </PopUpContainer>
      )}
      {startWorkPopUp && (
        <PopUpContainer
          title="Начало работ"
          close={() => setStartWorkPopUp(false)}
          onSubmit={startWork}
          button="Начать работу"
          disabled={rateProcess.loading || statusLoading || buttonClicked}
        >
          Вы уверены, что хотите начать работу?
        </PopUpContainer>
      )}
      {cancelPopUp && (
        <PopUpContainer
          title="Отменить работу"
          close={() => setCancelPopUp(false)}
          onSubmit={cancelOrder}
          button="Отменить работу"
          disabled={rateProcess.loading || statusLoading || buttonClicked}
        >
          Вы действительно хотите отменить эту работу?
        </PopUpContainer>
      )}
      {finishPopUp && (
        <PopUpContainer
          title="Закончить работу"
          close={() => setFinishPopUp(false)}
          onSubmit={() => {
            finishOrder();
            setWorkFinished(true);
          }}
          button="Закончить работу"
          disabled={workFinished || statusLoading || buttonClicked}
        >
          Вы действительно закончили эту работу?
        </PopUpContainer>
      )}
    </Wrapper>
  );
};

export default OrderProcess;
