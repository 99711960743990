export const getAddressesString = (addressObject: any) => {
  return [
    addressObject?.road
      ?.replace("улица", "ул.")
      ?.replace("переулок", "пер.")
      ?.replace("бульвар", "бул.") ?? "",
    addressObject?.house_number ?? "",
  ]
    .filter((item) => item !== "")
    .join(", ");
  // const street = () => {
  //   if (road.includes("улица")) return road.replace("улица", "ул.");
  //   if (road.includes("переулок")) return road.replace("переулок", "пер.");
  //   if (road.includes("бульвар")) return road.replace("бульвар", "бул.");
  //   return road;
  // };
  // return `${street()}${house_number ? ", д. " + house_number : ""}`;
};
