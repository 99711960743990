import React, { memo, useState } from "react";
import { InputWrapper, CheckboxWrapper } from "./styles";
import { ReactComponent as CheckBoxIcon } from "../../assets/icons/input-checkbox-status.svg";

interface ICheckboxProps {
  onClick?: () => void;
  text?: string;
  active?: boolean;
  fontSize?: string;
  disabled?: boolean;
}

const Checkbox: React.FC<ICheckboxProps> = ({
  onClick,
  text,
  active,
  fontSize,
  disabled = false,
}) => {
  return (
    <CheckboxWrapper fontSize={fontSize}>
      <InputWrapper onClick={() => !disabled && onClick!()} active={active}>
        <input type="checkbox" />
        <CheckBoxIcon />
      </InputWrapper>
      <div className={"checkboxText"} onClick={() => !disabled && onClick!()}>
        {text}
      </div>
    </CheckboxWrapper>
  );
};

export default memo(Checkbox);
