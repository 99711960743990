import React, { memo } from "react";

import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import {
  ClosePreview,
  ImageBackground,
  ImageBox,
  ImageWrapper,
} from "../imagePreview/styles";
import { SetMessageFileState } from "../../pages/messagesPage";

interface Props {
  videoFile: SetMessageFileState;
  close: () => void;
}

const VideoPreview: React.FC<Props> = ({ videoFile, close }) => {
  return (
    <ImageWrapper>
      <ImageBackground />
      <ImageBox>
        <video controls className="video">
          <source src={`data:${videoFile.type};base64, ${videoFile.fileUrl}`} />
        </video>
      </ImageBox>
      <ClosePreview onClick={close}>
        <CloseIcon />
      </ClosePreview>
    </ImageWrapper>
  );
};

export default memo(VideoPreview);

