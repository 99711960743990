import React, { memo, useEffect } from "react";
import {
  CloseBtn,
  DeadLine,
  Desc,
  Footer,
  ImagesWrapper,
  ImgItem,
  Price,
  SubTitle,
  Title,
  WorkDetailWrapper,
} from "./styles";
import { ReactComponent as Excavator } from "../../assets/icons/excavator.svg";
import img from "../../assets/images/P1.png";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { getSingleOrders } from "../../redux/store/reducers/orderSlice";
import { BASE_URL, ENDPOINT_FOR_IMG } from "../../constants/general";
import pdfImg from "../../assets/icons/pdf-icon.png";
import { thousandSeparator } from "../../utils/numbers";
import Button from "../../UI/Button";

interface Work {
  id: string;
  title: string;
  description: string;
  budget: string;
  startDate: string;
  endDate: string;
  address: string;
  Icon?: any;
  vehicle?: any;
}

interface Props {
  close: any;
  workDetail: Work;
  test?: boolean;
}

const WorkDetailOnMap: React.FC<Props> = ({ close, workDetail, test }) => {
  const { id, title, description, budget, startDate, endDate, address } =
    workDetail;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.auth.user);
  const images = useAppSelector((state: RootState) => state.order.images);

  useEffect(() => {
    if (user?.customer?.refId) {
      const reqData = { refId: user?.customer?.refId, orderId: id };
      dispatch(getSingleOrders(reqData));
    }
  }, [id]);

  const navigate = useNavigate();

  return (
    <WorkDetailWrapper>
      {/* <TransparentBack /> */}
      <Title>
        <div className="icon">
          {workDetail?.Icon ? (
            <workDetail.Icon />
          ) : workDetail?.vehicle?.icon ? (
            <img
              className="icon"
              src={`${ENDPOINT_FOR_IMG}/${workDetail?.vehicle?.icon}`}
              alt=""
            />
          ) : (
            <Excavator />
          )}
        </div>
        <div className="text">{title}</div>
      </Title>
      <SubTitle>{address}</SubTitle>
      <Price>{thousandSeparator(budget)} ₽</Price>
      <DeadLine>
        <div className="title">Сроки</div>
        <div className="date">
          {startDate
            ? moment(startDate).format("lll")
            : "Время будет обсуждаться"}
          {/* {startDate ? moment(startDate).format("lll") : ""} */}
          {/* -{" "} */}
          {endDate ? ` - ${moment(endDate).format("lll")}` : ""}
        </div>
      </DeadLine>
      <Desc>
        <div className="name">Описание</div>
        <div className="text">
          {description}
          {/* <Highlighted>Читать далее</Highlighted> */}
        </div>
      </Desc>
      <ImagesWrapper>
        {images.map(({ path }: { path: string }) => (
          <ImgItem>
            <div className="item">
              <img
                src={
                  path.toLowerCase().endsWith(".pdf")
                    ? pdfImg
                    : `${BASE_URL}/customers/image/${path}`
                }
                alt=""
              />
            </div>
          </ImgItem>
        ))}
      </ImagesWrapper>
      <Footer>
        <Button
          onClick={() =>
            test ? navigate(`/dashboard/order/${id}`) : navigate(`/o/${id}`)
          }
          width={"219px"}
          height={"56px"}
        >
          Смотреть подробно
        </Button>
        <CloseBtn onClick={close}>Закрыть</CloseBtn>
      </Footer>
    </WorkDetailWrapper>
  );
};

export default memo(WorkDetailOnMap);
