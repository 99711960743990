import React, { memo, useCallback, useEffect, useState } from "react";
import {
  Half,
  HideButton,
  MsgBox,
  RightHalf,
  UserData,
  Wrapper,
} from "./styles";
import avatar from "../../assets/images/avatar.png";
import Button from "../../UI/Button";
import ProposalMenu from "./proposalMenu";
import { useNavigate, useParams } from "react-router-dom";
import { thousandSeparator } from "../../utils/numbers";
import moment from "moment";
import {
  useActionCreators,
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import PopUpContainer from "../popUp/popUpContainer";
import {
  acceptCounterOffer,
  createCounterOffer,
  declineCounterOffer,
  getCounterOffers,
  getOrderDocs,
  getOrderOffers,
  getTechTask,
  requestCall,
  selectWorker,
  startWorkAsExecutor,
} from "../../redux/store/reducers/orderSlice";
import { ENDPOINT } from "../../constants/general";
import { getToken, VEHICLE_PARAMETERS_NAMES } from "../../utils/uiHelpers";
import { ReactComponent as StarSmall } from "../../assets/icons/StarSmall.svg";
import { ReactComponent as CallIcon } from "../../assets/icons/icon-24-intellicon-call-call-calling.svg";
import { ReactComponent as ChatIcon } from "../../assets/icons/icon-24-intellicon-chatting-comment.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/icon-24-intellicon-basic-check-mark.svg";
import { ReactComponent as HideIcon } from "../../assets/icons/icon-24-intellicon-basic-eye-no.svg";
import { ReactComponent as MoreIcon } from "../../assets/icons/icon-24-intellicon-basic-more-horizontal.svg";
import { ReactComponent as CounterOfferIcon } from "../../assets/icons/forward_to_inbox_black_24dp.svg";
import { ReactComponent as ChatMessage } from "../../assets/icons/Chat-msg.svg";

import { checkUserRegistered } from "../../utils/checkUserRegistered";
import {
  setPageToRedirect,
  setWorkerToAppoint,
} from "../../redux/store/reducers/registrationSlice";
import ReportWorkerPopUp from "../popUp/reportWorkerPopUp";
import HideOfferPopUp from "../popUp/hideOfferPopUp";
import { authActions } from "../../redux/store/reducers/authSlice";
import ProposalPopUp from "../popUp/ProposalPopUp";
import { CounterOffer } from "../../types/orderSlice.types";
import { getCounterOfferDifference } from "../notificationSidebar";

interface Worker {
  id: number;
  firstName: string;
  secondName: string;
  role: string;
  phoneNumber: string;
  birthday: string;
  createdAt: null | string;
  commission: string;
  city: string;
  patronymic: null;
  email: string;
  refId: string;
}

interface Props {
  id?: number;
  orderId?: number;
  worker?: any;
  createdAt?: any;
  budget: number;
  description?: string;
  startDate?: string;
  endDate?: string;
  status?: string;
  orderStatus?: string;
  vehicle?: any;
  isAbleToSelect?: boolean;
  selectedOffer?: number;
  cmpTitle: string | null;
  paymentType?: string;
  getVehicleCategory: (categoryId: number) => string;
  counterOffer: CounterOffer | null | undefined;
  setCounterOffers: any;
}

export const getAvatarUrl = (url: string) => {
  const isStartsWithSlash = url.startsWith("/");
  if (isStartsWithSlash) {
    return `${ENDPOINT}${url}`;
  } else {
    const isStartsWithUploads = url.startsWith("uploads");
    if (isStartsWithUploads) {
      return `${ENDPOINT}/${url}`;
    }
    return `data:image/jpeg;base64,${url}`;
  }
};

const ProposalItem: React.FC<Props> = ({
  id,
  orderId,
  worker,
  createdAt,
  budget,
  description,
  startDate,
  endDate,
  status,
  orderStatus,
  isAbleToSelect,
  counterOffer,
  vehicle,
  cmpTitle,
  paymentType,
  getVehicleCategory,
  setCounterOffers,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const actions = useActionCreators(authActions);
  const orderDocs = useAppSelector((state: RootState) => state.order.orderDocs);
  const selectWorkerLoading = useAppSelector(
    (state: RootState) => state.order.selectWorkerLoading,
  );
  const user = useAppSelector((state: RootState) => state.auth.user);
  const singleOrder = useAppSelector(
    (state: RootState) => state.order.singleOrder,
  );
  const { id: orderIdInPath } = useParams();
  const [popUp, setPopUp] = useState<boolean>(false);
  const [proposalMenuOpen, setProposalMenuOpen] = useState<boolean>(false);
  const [callButtonDisalbed, setCallButtonDisalbed] = useState<boolean>(false);
  const [reportWorkerOpen, setReportWorkerOpen] = useState<boolean>(false);
  const [hideOfferOpen, setHideOfferOpen] = useState<boolean>(false);
  const [counterOfferPopup, setCounterOfferPopup] = useState<boolean>(false);
  const [counterOfferOpen, setCOunterOfferOpen] = useState<boolean>(false);
  const [workerSelectClicked, setWorkerSelectClicked] =
    useState<boolean>(false);

  const handleStartWork = async () => {
    user?.customer?.refId &&
      dispatch(
        startWorkAsExecutor({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
  };

  //
  //<------- CHAT HANDLER ---------->
  const redirectToChatHandler = async () => {
    if (user?.customer?.refId) {
      dispatch(
        actions.setInitChatData({
          secondMemberId: worker.id,
          title: `Заказ №${singleOrder?.order?.id}`,
          order_id: Number(singleOrder?.order?.id),
        }),
      );

      navigate(`/messages`);
    }
  };

  const currentOfferDoc = orderDocs?.find(
    (doc) => doc.documentType === "tech_task",
  );

  const shouldCounterOfferButtonRender = () => {
    if (status === "Sent") {
      if (counterOffer && counterOffer.creator.id !== user?.customer.id) {
        if (
          user?.customer?.id === singleOrder?.order?.creatorId ||
          user?.customer.companyId === singleOrder?.order?.company?.id
        ) {
          return true;
        }
        if (user?.customer.id === worker.id) {
          return true;
        }
      }
    }

    return false;
  };

  const handleCounterOffer = async (newCounterOffer: {
    budget: string;
    description: string;
    vehicleId: number | null;
    paymentType: string;
    startDate: string;
    endDate: string | null;
  }) => {
    if (counterOffer) {
      await handleDeclineCounterOffer(counterOffer.id);
    }
    await dispatch(
      createCounterOffer({
        refId: user?.customer.refId!,
        orderId: singleOrder.order.id,
        counterOffer: {
          ...newCounterOffer,
          vehicleId: vehicle.id,
          offer_id: id!,
        },
      }),
    );

    await dispatch(
      getCounterOffers({
        refId: user?.customer.refId!,
        orderId: singleOrder?.order?.id,
      }),
    ).then((response) => {
      if (response.meta.requestStatus === "fulfilled") {
        setCounterOffers(response.payload);
      }
    });

    await dispatch(
      getOrderOffers({
        refId: user?.customer?.refId!,
        orderId: singleOrder?.order?.id,
      }),
    );
  };

  const handleAcceptCounterOffer = async (counterofferId: number) => {
    if (user?.customer.refId) {
      await dispatch(
        acceptCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );
      await dispatch(
        getCounterOffers({
          refId: user.customer.refId,
          orderId: singleOrder?.order?.id,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setCounterOffers(response.payload);
        }
      });
      await dispatch(
        getOrderOffers({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
      await dispatch(
        getOrderDocs({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
    }
  };

  const handleDeclineCounterOffer = async (counterofferId: number) => {
    if (user?.customer.refId) {
      await dispatch(
        declineCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );

      await dispatch(
        getCounterOffers({
          refId: user.customer.refId,
          orderId: singleOrder?.order?.id,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setCounterOffers(response.payload);
        }
      });

      await dispatch(
        getOrderOffers({
          refId: user?.customer?.refId,
          orderId: singleOrder?.order?.id,
        }),
      );
    }
  };

  const selectWorkerHandler = useCallback(async () => {
    if (checkUserRegistered(user)) {
      if (!currentOfferDoc && id !== undefined && user?.customer?.refId) {
        await dispatch(
          selectWorker({
            refId: user?.customer?.refId,
            offerId: id,
            orderId: singleOrder?.order?.id,
          }),
        );

        await dispatch(
          getTechTask({
            refId: user?.customer?.refId,
            offerId: id,
            coordinates: [
              singleOrder?.order?.latitude,
              singleOrder?.order?.longitude,
            ],
          }),
        );
      }
      if (user?.customer?.refId) {
        await dispatch(
          getOrderOffers({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );

        await dispatch(
          getOrderDocs({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
      }
    } else {
      dispatch(setPageToRedirect(`/o/${singleOrder?.order?.id}`));
      id &&
        user?.customer?.refId &&
        dispatch(
          setWorkerToAppoint({
            refId: user?.customer?.refId,
            offerId: id,
            orderId: singleOrder?.order?.id,
            coordinates: [
              singleOrder?.order?.latitude,
              singleOrder?.order?.longitude,
            ],
          }),
        );
      navigate("/registration");
    }
  }, [dispatch, user, singleOrder, currentOfferDoc]);

  const callHandler = () => {
    setCallButtonDisalbed(true);
    setTimeout(() => {
      setCallButtonDisalbed(false);
    }, 60000);
    if (user?.customer) {
      dispatch(
        requestCall({
          refId: user?.customer.refId,
          orderId: singleOrder?.order?.id,
          offerId: Number(id),
        }),
      );
    }
  };

  return (
    <>
      <Wrapper>
        <div className="createdAt">
          {moment(createdAt).format("DD.MM.YYYY")}
        </div>
        <div className="workerData">
          <UserData>
            <div
              className="avatar"
              onClick={() => {
                navigate(`/user/${worker?.id}`);
              }}
            >
              <img
                src={
                  worker?.avatarUrl?.length
                    ? getAvatarUrl(worker.avatarUrl)
                    : avatar
                }
                alt=""
              />
            </div>
            <div
              className="data"
              onClick={() => navigate(`/user/${worker?.id}`)}
            >
              <b>
                {worker?.firstName} {worker?.secondName}
              </b>{" "}
              <div className={"companyName"}>
                {cmpTitle?.length && cmpTitle}
              </div>
              <div className="ratingMsg">
                <div className="rating">
                  <StarSmall />{" "}
                  <span className="starRating">{worker?.rating}</span>
                </div>
                <div className="message">
                  <ChatMessage />{" "}
                  <span className="starRating">
                    {worker?.feedbacks?.length || 0}
                  </span>
                </div>
              </div>
            </div>
          </UserData>
          {description && <div className="proposalText">{description}</div>}
          {((user?.customer.id === singleOrder.order.creatorId &&
            !user?.customer.companyId) ||
            (user?.customer?.companyId === singleOrder?.order?.company?.id &&
              user?.customer.accessSettings.communicationWithContractor)) && (
            <div className="buttonsWrapper">
              <Button
                width="fit-content"
                height="48px"
                fontWeight="400"
                background="white"
                variant="secondary"
                border="1px solid #e8e8e8"
                onClick={redirectToChatHandler}
              >
                <ChatIcon />
                Написать
              </Button>
              <Button
                width="fit-content"
                height="48px"
                fontWeight="400"
                background="white"
                variant="secondary"
                border="1px solid #e8e8e8"
                disabled={callButtonDisalbed}
                onClick={callHandler}
              >
                <CallIcon />
                Позвонить
              </Button>
            </div>
          )}
        </div>
        <div
          className="offer"
          style={
            !counterOffer || status !== "Sent"
              ? { gridTemplateColumns: "1fr" }
              : undefined
          }
        >
          <div className="offerDetails">
            <div className="offerItem">
              <div className="offerItemTitle">Цена и оплата</div>
              <div className="offerItemText">{`${thousandSeparator(budget)} ₽ ${
                paymentType === "bn" ? "Безналичный" : "Безналичный с НДС"
              } `}</div>
            </div>
            <div className="offerItem">
              <div className="offerItemTitle">Дата начала</div>
              <div className="offerItemText">{`${moment(startDate)
                .local(true)
                .format("DD MMMM, HH:mm")}`}</div>
            </div>
            <div className="offerItem">
              <div className="offerItemTitle">Дата окончания</div>
              <div className="offerItemText">{`${
                endDate
                  ? moment(endDate).local(true).format("DD MMMM, HH:mm")
                  : "Не указано"
              }`}</div>
            </div>
            <div className="offerItem">
              <div className="offerItemTitle">Вид техники</div>
              <div className="offerItemText">
                {getVehicleCategory(vehicle?.categoryId)}
              </div>
            </div>
          </div>
          {status === "Sent" && counterOffer && (
            <div className="offerDetails">
              <div className="offerItem">
                <div className="offerItemTitle">
                  {" "}
                  {`Предложение от ${moment(counterOffer.createdAt).format(
                    "DD.MM, HH:mm",
                  )}`}
                </div>
                <div className="offerItemText">{` → ${
                  counterOffer.budget !== budget
                    ? thousandSeparator(counterOffer.budget)
                    : thousandSeparator(budget)
                } ₽ ${
                  counterOffer.paymentType === "bn"
                    ? "Безналичный"
                    : "Безналичный с НДС"
                }`}</div>
              </div>
              <div className="offerItem">
                <div className="offerItemTitle">&nbsp;</div>
                <div className="offerItemText">{` → ${
                  counterOffer.startDate !== startDate
                    ? moment(counterOffer.startDate)
                        .local(true)
                        .format("DD MMMM, HH:mm")
                    : "Без изменения"
                }`}</div>
              </div>
              <div className="offerItem">
                <div className="offerItemTitle">&nbsp;</div>
                <div className="offerItemText">{` → ${
                  counterOffer.endDate !== endDate
                    ? counterOffer.endDate
                      ? moment(counterOffer.endDate)
                          .local(true)
                          .format("DD MMMM, HH:mm")
                      : "Не указано"
                    : "Без изменения"
                }`}</div>
              </div>
              <div className="offerItem">
                <div className="offerItemTitle">&nbsp;</div>
                <div className="offerItemText">
                  {/* {counterOffer.vehicle_id !== vehicle.categoryId ?  :  /* {getVehicleCategory(vehicle?.categoryId)} */}
                </div>
              </div>
            </div>
          )}
          {((user?.customer?.id === singleOrder?.order?.creatorId ||
            user?.customer.companyId === singleOrder?.order?.company?.id) &&
            user?.customer.role === "customer") ||
          user?.customer.id === worker.id ? (
            <>
              {status === "WaitingForPayment" &&
              (!user?.customer.companyId ||
                user.customer.accessSettings.payingOrder) &&
              user?.customer?.id === singleOrder?.order?.creatorId ? (
                <Button
                  width="fit-content"
                  height="48px"
                  fontWeight="400"
                  onClick={() =>
                    navigate(`/o/${orderIdInPath}/appoint/${worker?.id}`)
                  }
                  disabled={
                    !!orderDocs?.find((doc) => doc.documentType === "bill")
                  }
                >
                  Перейти к оплате
                </Button>
              ) : status === "Sent" &&
                (!user?.customer.companyId ||
                  user.customer.accessSettings.assigningContractor) &&
                isAbleToSelect ? (
                counterOffer &&
                counterOffer.creator.id !== user?.customer.id ? (
                  <Button
                    width="fit-content"
                    height={"48px"}
                    variant="outlined"
                    onClick={() => setCounterOfferPopup(true)}
                  >
                    Встречное предложение
                  </Button>
                ) : (
                  !counterOffer &&
                  user?.customer.id === singleOrder?.order?.creatorId && (
                    <div className="buttonsWrapper">
                      <Button
                        width="fit-content"
                        height="48px"
                        fontWeight="400"
                        onClick={() => {
                          if (
                            selectWorkerLoading !== "pending" &&
                            !workerSelectClicked
                          ) {
                            selectWorkerHandler();
                            setWorkerSelectClicked(true);
                          }
                        }}
                        disabled={
                          selectWorkerLoading === "pending" ||
                          !isAbleToSelect ||
                          workerSelectClicked
                        }
                      >
                        <CheckIcon />
                        Выбрать исполнителя
                      </Button>
                      {status === "Sent" &&
                        isAbleToSelect &&
                        user?.customer.id !== worker.id && (
                          <Button
                            width="fit-content"
                            onClick={() => setCounterOfferPopup(true)}
                          >
                            <CounterOfferIcon />
                          </Button>
                        )}
                    </div>
                  )
                )
              ) : null}
            </>
          ) : null}
          {shouldCounterOfferButtonRender() &&
            (!user?.customer.companyId ||
              user?.customer.accessSettings.assigningContractor) &&
            isAbleToSelect && (
              <Button
                width="fit-content"
                height="48px"
                fontWeight="400"
                onClick={() => handleAcceptCounterOffer(counterOffer!.id)}
                disabled={!isAbleToSelect}
              >
                <CheckIcon />
                {user?.customer.role === "customer"
                  ? "Принять и назначить"
                  : "Принять предложение"}
              </Button>
            )}
        </div>

        {/* {counterOffer && (
          <div className="counterOffer">
            <div
              className="counterOfferTitle"
              onClick={() => setCOunterOfferOpen(!counterOfferOpen)}
            >
              {`${
                counterOfferOpen
                  ? "Скрыть встречное предложение"
                  : "Показать встречное предложение"
              }`}
            </div>
            <div
              className={`counterOfferBody ${counterOfferOpen ? "active" : ""}`}
            >
              {getCounterOfferDifference(counterOffer)}
              <div className="counterOfferButtons">
                {counterOffer.status === "Accepted" ? (
                  <Button disabled height={"36px"} maxWidth="250px">
                    Предложение принято
                  </Button>
                ) : counterOffer.status === "Declined" ? (
                  <>
                    <Button disabled height={"36px"} maxWidth="250px">
                      Предложение отклонено
                    </Button>
                    <Button
                      height={"36px"}
                      variant="outlined"
                      onClick={() => setCounterOfferPopup(true)}
                      disabled={counterOffer.creator.id === user?.customer.id}
                      maxWidth="250px"
                    >
                      Сделать встречное предложение
                    </Button>
                  </>
                ) : (
                  counterOffer.creator.id !== user?.customer.id && (
                    <>
                      <Button
                        height={"36px"}
                        onClick={() =>
                          handleAcceptCounterOffer(counterOffer.id)
                        }
                      >
                        Принять
                      </Button>
                      <Button
                        height={"36px"}
                        variant="outlined"
                        onClick={() => setCounterOfferPopup(true)}
                      >
                        Сделать встречное предложение
                      </Button>
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        )} */}

        {proposalMenuOpen && (
          <ProposalMenu
            setReportWorkerOpen={setReportWorkerOpen}
            setHideOfferOpen={setHideOfferOpen}
          />
        )}
        {hideOfferOpen && (
          <HideOfferPopUp close={() => setHideOfferOpen(false)} />
        )}
        {reportWorkerOpen && (
          <ReportWorkerPopUp close={() => setReportWorkerOpen(false)} />
        )}
        {counterOfferPopup && (
          <ProposalPopUp
            close={() => setCounterOfferPopup(false)}
            counterOffer
            handleCounterOffer={handleCounterOffer}
          />
        )}
      </Wrapper>
    </>
  );
};

export default memo(ProposalItem);

