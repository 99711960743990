import React, { memo, useEffect, useState } from "react";
import {
  FormWrapper,
  List,
  Section,
  Wrapper,
  FormText,
  MobileIllustration,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  authActions,
  createTelegramAuthSession,
  getMe,
  getTelegramAuthToken,
} from "../../redux/store/reducers/authSlice";
import { ReactComponent as TelegramLogo } from "../../assets/images/telegram-logo-auth.svg";
import { ReactComponent as RukkiLogo } from "../../assets/images/rukki-logo-auth.svg";
import { ReactComponent as Arrow } from "../../assets/icons/icons-arrow-back.svg";
import { useNavigate, useParams } from "react-router-dom";
import { client } from "../../services";
import QRCode from "react-qr-code";
import PageHeader from "../../UI/PageHeader";
import Button from "../../UI/Button";

type PageParams = {
  sessionId: string | undefined;
};

const TelegramAuthPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams<PageParams>();
  const user = useAppSelector((state) => state.auth.user);
  const { sessionId } = useParams<PageParams>();
  const [botLink, setBotLink] = useState<string>("");

  useEffect(() => {
    dispatch(createTelegramAuthSession()).then((data) => {
      setBotLink(data.payload.botLink);
      client.emit(
        "create-auth-connection",
        JSON.stringify({ roomId: data.payload.roomId }),
      );
    });
  }, []);

  useEffect(() => {
    if (sessionId) {
      dispatch(getTelegramAuthToken({ sessionId: sessionId }));
    }
  }, [sessionId]);

  useEffect(() => {
    if (user?.customer?.role) {
      user.customer.role === "worker"
        ? navigate("/search-order")
        : navigate("/create-order");
    }
  }, [user]);

  useEffect(() => {
    client.on("success-telegram-auth", (response) => {
      dispatch(authActions.setAccessToken(JSON.parse(response).token));
      localStorage.setItem("accessToken", JSON.parse(response).token);
      const data = JSON.parse(response);
      dispatch(getMe(data.refId)).then((response) => {
        if (response?.payload?.customer?.role === "worker") {
          navigate("/search-order");
        } else {
          navigate("/create-order");
        }
      });
    });
  }, []);

  useEffect(() => {
    if (params.sessionId) {
      dispatch(getTelegramAuthToken({ sessionId: params.sessionId })).then(
        (response) => {
          dispatch(authActions.setAccessToken(response.payload.token));
          localStorage.setItem("accessToken", response.payload.token);
        },
      );
    }
  }, [params]);

  return (
    <Wrapper>
      <Section>
        <PageHeader backButtonText={false} width="340px">
          Войти через Telegram
        </PageHeader>
        <FormWrapper>
          <FormText>
            Отсканируйте QR-код с помощью мобильного телефона или нажмите <br />
            на кнопку "Войти через Telegram"
          </FormText>

          {!!botLink.length && (
            <div className="qrCodeWrapper">
              <QRCode
                size={128}
                style={{
                  height: "auto",
                  maxWidth: "175px",
                  width: "175px",
                }}
                value={botLink}
                viewBox={`0 0 175 175`}
                bgColor="#ffffff"
                fgColor=""
              />
            </div>
          )}
          {/* <div
            onClick={() => botLink.length && window.open(botLink, "_blank")}
            className={`button ${!!botLink.length ? "" : "disabled"}`}
          >
            Войти через Telegram
          </div> */}
          <Button
            height="48px"
            fontWeight="500"
            onClick={() => botLink.length && window.open(botLink, "_blank")}
            disabled={!botLink.length}
          >
            Войти через Telegram
          </Button>
          <List>
            <div>1. Нажмите на кнопку выше</div>
            <div>2. Перейдите в приложение Telegram</div>
            <div>3. Нажмите кнопку “Запустить”</div>
            <div>
              4. Если вы новый пользователь, нажмите кнопку “Отправить контакт”
            </div>
            <div>5. Поздравляем! Вы успешно авторизовались!</div>
          </List>
          <List className="mobile">
            <div>1. Нажмите на кнопку ниже</div>
            <div>2. Перейдите в приложение Telegram</div>
            <div>
              3. Если вы новый пользователь, нажмите кнопку “Отправить контакт”
            </div>
            <div>4. Поздравляем! Вы успешно авторизовались!</div>
          </List>
          <MobileIllustration>
            <TelegramLogo />
            <Arrow />
            <RukkiLogo />
          </MobileIllustration>
        </FormWrapper>
      </Section>
      <Section className="image">
        <div className="background"></div>
      </Section>
    </Wrapper>
  );
};

export default memo(TelegramAuthPage);
