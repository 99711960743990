import styled from "styled-components";

export const Wrapper = styled.div<{ topmargin: number }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: fit-content;
  font-size: 14px;
  transition: all 0.3s linear;

  &:focus-within {
    .marker {
      display: block;
    }
  }

  .marker {
    display: none;
    width: 84px;
    margin: 0 auto;
    border-radius: 8px;
    padding: 4px;
    height: 28px;
    background: rgba(255, 102, 51, 0.24);
    position: absolute;
    top: 113px;
    left: 0;
    right: 0;
    z-index: 3;
    animation: markerAppear 0.4s linear;
  }

  .select {
    background: #ffffff;
    width: 100px;
    border-radius: 8px;
    height: 151px;
    overflow: hidden;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50px;
    transition: all 0.3s linear;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);
    display: none;
    animation: growDown 0.3s ease-in-out;
    transform-origin: top center;
    z-index: 3;

    .marginContainer {
      margin-top: 0px;
      width: 100%;
      padding: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 0.15s ease-in;
      ${({ topmargin }) =>
        topmargin && `margin-top: calc(-28px * ${topmargin})`};
      position: relative;

      .selectItem {
        display: flex;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
        transition: color 0.15s linear background-color 0.15s linear;
        height: 28px;
      }

      .active {
        //background: rgba(255, 102, 51, 0.24);
        color: #ff6633;
        border-radius: 8px;
        padding: 4px;
        height: 28px;
        transition: color 0.15s linear background-color 0.15s linear;
      }

      .dimmed {
        color: #999999;
      }
    }
  }

  input:focus + .select {
    display: block !important;
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes markerAppear {
    0% {
      opacity: 0%;
    }
    100% {
      opacity: 100%;
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 0px;
  background: #ffffff;
  display: flex;
  align-items: center;
  color: #222222;
  justify-content: center;
  transition: all 0.3s linear;
  outline: none;
  text-align: center;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  z-index: 2;

  &:focus {
    border: 1px solid #999999;
  }
`;

export const Select = styled.div<{ topmargin: number }>`
  background: #ffffff;
  width: 100px;
  border-radius: 8px;
  height: 151px;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 44px;
  transition: all 0.3s linear;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.12);

  .marginContainer {
    margin-top: 0px;
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.15s ease-in;
    ${({ topmargin }) => topmargin && `margin-top: calc(-28px * ${topmargin})`};
    position: relative;

    .selectItem {
      display: flex;
      width: 100%;
      text-align: center;
      align-items: center;
      justify-content: center;
      transition: color 0.15s linear background-color 0.15s linear;
      height: 28px;
    }

    .active {
      //background: rgba(255, 102, 51, 0.24);
      color: #ff6633;
      border-radius: 8px;
      padding: 4px;
      height: 28px;
      transition: color 0.15s linear background-color 0.15s linear;
      /* scroll-snap-align: center;
    scroll-snap-stop: always; */
    }

    .dimmed {
      color: #999999;
    }
  }

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;
