import React, { ReactNode, memo } from "react";
import { Wrapper } from "./styles";

interface IPageWrapperProps {
  children: ReactNode;
  flexDirection?: "row" | "column" | "row-reverse" | "column-reverse";
  alignItems?: string;
  noHeight?: boolean;
  noWidth?: boolean;
  minHeight?: string;
  noFooter?: boolean;
  gap?: string;
  paddingTop?: string;
}

const PageWrapper: React.FC<IPageWrapperProps> = ({
  children,
  flexDirection,
  noHeight,
  noWidth,
  minHeight,
  noFooter,
  gap,
  alignItems,
  paddingTop,
}) => {
  return (
    <Wrapper
      flexDirection={flexDirection}
      noHeight={noHeight}
      noWidth={noWidth}
      minHeight={minHeight}
      noFooter={noFooter}
      gap={gap}
      alignItems={alignItems}
      paddingTop={paddingTop}
    >
      {children}
    </Wrapper>
  );
};

export default memo(PageWrapper);
