import React, { useState } from "react";
import Loading from "../../loading";
import PopUpContainer from "../popUpContainer";
import { DescriptionBox } from "./styles";

interface Props {
  close?: any;
  onSubmit?: any;
  defaultValue?: string | null;
  loading?: boolean;
  // tab?: any;
}

const DeleteOrderPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  defaultValue,
  loading,
  // tab,
}) => {
  const [text, setText] = useState<string>(
    // defaultValue ||
    "",
  );

  return (
    <PopUpContainer
      title="Удалить заказ"
      close={close}
      onSubmit={() => {
        onSubmit(text);
        // close();
      }}
      button={!loading ? "Удалить" : null}
      disabled={false}
      // tab={tab}
    >
      <DescriptionBox>
        {loading ? (
          <Loading />
        ) : (
          <div className="text">
            Вы действительно хотите удалить этот заказ?
          </div>
        )}
      </DescriptionBox>
    </PopUpContainer>
  );
};

export default DeleteOrderPopUp;
