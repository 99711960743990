import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 30vh;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DataPart = styled.div`
  width: fit-content;
  height: fit-content;
  text-align: center;
`;
