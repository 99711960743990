import React, { memo, useState } from "react";
import {
  Box,
  BoxInner,
  RightFooter,
  SocialMediaLogo,
  SocialMediaWrapper,
  Typography,
} from "./styled";
import logo from "../../assets/images/bw-logo.png";
import { ReactComponent as TelegramLogo } from "../../assets/icons/24-symbols-telegram.svg";
import { ReactComponent as Vk } from "../../assets/icons/24-symbols-vk.svg";
import { ReactComponent as YouTubeLogo } from "../../assets/icons/24-symbols-youtube.svg";
import ConfidentialityPopUp from "../popUp/confidentialityPopUp";
import UserAgreementPopUp from "../popUp/userAgreementPopUp";
const NewFooter: React.FC = () => {
  const socialMedias = [
    {
      icon: <YouTubeLogo />,
      link: "https://www.youtube.com/channel/UCGuavrdpoW8BvdhfgfGmdTg",
    },
    {
      icon: <TelegramLogo />,
      link: "https://t.me/Rukki_Pro",
    },
    {
      icon: <Vk />,
      link: "https://vk.com/rukkipro",
    },
  ];
  const [modal, setModal] = useState({
    userAgreement: false,
    confidentiality: false,
  });
  return (
    <Box id="footer">
      <BoxInner>
        <div className="logo">
          <img src={logo} alt="" />
        </div>
        <RightFooter>
          <Typography
            className="agreement-text"
            onClick={() => setModal({ ...modal, userAgreement: true })}
          >
            Правила Сервиса
          </Typography>
          <Typography
            className="agreement-text"
            onClick={() => setModal({ ...modal, confidentiality: true })}
          >
            Конфиденциальность
          </Typography>
          <SocialMediaWrapper>
            {socialMedias.map(({ icon, link }, index) => (
              <SocialMediaLogo
                target={"_blank"}
                rel="noreferrer"
                key={index}
                href={link}
              >
                {icon}
              </SocialMediaLogo>
            ))}
          </SocialMediaWrapper>
        </RightFooter>
      </BoxInner>
      {modal.userAgreement && (
        <UserAgreementPopUp
          close={() => setModal({ ...modal, userAgreement: false })}
        />
      )}
      {modal.confidentiality && (
        <ConfidentialityPopUp
          close={() => setModal({ ...modal, confidentiality: false })}
        />
      )}
    </Box>
  );
};

export default memo(NewFooter);
