import React, { memo, useState } from "react";
import {
  FieldsWrapper,
  SideMenuBackground,
  SideMenuHeader,
  SideMenuWrapper,
} from "../dashboardEmployeesPage/styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-24-intellicon-basic-circle-x.svg";
import Input from "../../UI/Input";
import Button from "../../UI/Button";
import { useAppDispatch } from "../../redux/hooks";
import {
  addCompanyUser,
  addVipWorker,
  getVipWorkers,
} from "../../redux/store/reducers/companySlice";

interface AddEmployeeProps {
  menuOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  customerRefId?: string | undefined;
}

const Fields = [
  {
    name: "secondName",
    placeholder: "Фамилия",
    required: true,
  },
  {
    name: "firstName",
    placeholder: "Имя",
    required: true,
  },
  {
    name: "patronymic",
    placeholder: "Отчество",
    required: false,
  },
  {
    name: "phone",
    placeholder: "Телефон",
    required: true,
  },
];

const AddWorkerSideMenu: React.FC<AddEmployeeProps> = ({
  menuOpen,
  toggleOpen,
  customerRefId,
}) => {
  const dispatch = useAppDispatch();

  const [createUserError, setCreateUserError] = useState<string | null>(null);
  const [role, setRole] = useState<{
    name: string;
    value: string;
  } | null>(null);
  const [fieldsData, setFieldsData] = useState<{ [key: string]: string }>({
    firstName: "",
    secondName: "",
    patronymic: "",
    phone: "",
  });

  const handeCreateEmployee = () => {
    customerRefId &&
      dispatch(
        addVipWorker({
          refId: customerRefId,
          firstName: fieldsData.firstName,
          lastName: fieldsData.secondName,
          patronymic: fieldsData.patronymic,
          phone: fieldsData.phone,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          toggleOpen(false);
          dispatch(getVipWorkers({ refId: customerRefId }));
          setFieldsData({
            firstName: "",
            secondName: "",
            patronymic: "",
            phone: "",
          });
          setRole(null);
          setCreateUserError(null);
        } else {
          //@ts-ignore
          if (response.error.message.includes("400")) {
            setCreateUserError("Исполнитель уже принадлежит другой компании");
          }
          //setErrorMsg(["При добавлении пользователя произошла ошибка"]);
        }
      });
  };

  const renderFileds = () => {
    return Fields.map((field) => {
      return (
        <Input
          placeholder={field.placeholder}
          name={field.name}
          value={fieldsData[field.name]}
          required={field.required}
          maxLength={field.name === "phoneNumber" ? 12 : undefined}
          onChange={(e: any) => {
            if (e.target.name === "phoneNumber") {
              const numberRegEx = new RegExp("[0-9]");
              if (fieldsData.phoneNumber === "") {
                if (e.target.value === "+") {
                  setFieldsData({
                    ...fieldsData,
                    phoneNumber: "+7",
                  });
                }
                if (e.target.value === "7" || e.target.value === "8") {
                  setFieldsData({
                    ...fieldsData,
                    phoneNumber: "+7",
                  });
                }
                if (e.target.value === "9") {
                  setFieldsData({
                    ...fieldsData,
                    phoneNumber: "+79",
                  });
                }
                if (numberRegEx.test(e.target.value.substring(1))) {
                  setFieldsData({
                    ...fieldsData,
                    phoneNumber: "+7" + e.target.value,
                  });
                }
              } else {
                if (numberRegEx.test(e.target.value.substring(1))) {
                  setFieldsData({
                    ...fieldsData,
                    phoneNumber: e.target.value,
                  });
                }
              }
            } else {
              setFieldsData({
                ...fieldsData,
                [e.target.name]: e.target.value,
              });
            }
          }}
          height="48px"
          width="100%"
        />
      );
    });
  };

  return (
    <>
      <SideMenuBackground
        open={menuOpen}
        onClick={() => toggleOpen(false)}
      ></SideMenuBackground>
      <SideMenuWrapper open={menuOpen}>
        <SideMenuHeader>
          Добавление исполнителя <CloseIcon onClick={() => toggleOpen(false)} />
        </SideMenuHeader>
        <FieldsWrapper>
          {renderFileds()}
          <Button
            onClick={handeCreateEmployee}
            disabled={
              !fieldsData.firstName ||
              !fieldsData.secondName ||
              !fieldsData.phone
            }
            width="100%"
          >
            Добавить
          </Button>

          <div
            className="errorMessage"
            style={{
              opacity: createUserError ? "100%" : "0%",
              transition: "all 0.3s linear",
            }}
          >
            Исполнитель уже принадлежит другой компании
          </div>
        </FieldsWrapper>
      </SideMenuWrapper>
    </>
  );
};

export default memo(AddWorkerSideMenu);
