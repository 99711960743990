export const getWord = (count: number, word: string): string => {
  if (
    Number(count.toString().slice(-1)) > 1 &&
    Number(count.toString().slice(-1)) < 5
  ) {
    return word.slice(-1) === "з" ? word + "а" : "я";
  } else if (
    Number(count.toString().slice(-1)) >= 5 ||
    Number(count.toString().slice(-1)) === 0
  ) {
    return word.slice(-1) === "з" ? word + "ов" : "ий";
  } else {
    return word;
  }
};
