import React, { memo, useState } from "react";
import { DropdownMenu, Wrapper } from "./styles";
import NavigationButton from "../NavigationButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../../assets/icons/icons-arrow-back.svg";
import { ReactComponent as MobileMenuIcon } from "../../assets/icons/icons-more-vert.svg";
import Button from "../Button";
import BetaMarker from "../BetaMarker";

interface IMenuItem {
  label: string;
  onClick: () => void;
}

interface IPageHeaderProps {
  menuList?: IMenuItem[];
  bold?: boolean;
  width?: string;
  children: React.ReactNode | string;
  button?: React.ReactNode;
  backButton?: boolean;
  backButtonText?: boolean;
  paddingTop?: string;
  className?: string;
  betaMarker?: boolean;
  margin?: string;
}

const PageHeader: React.FC<IPageHeaderProps> = ({
  children,
  menuList,
  bold,
  width,
  button,
  backButton = true,
  backButtonText = true,
  paddingTop,
  className,
  betaMarker = false,
  margin,
}) => {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const menuItems = () => {
    return menuList?.map(({ label, onClick }, index) => {
      return (
        <div
          className="menuItem"
          key={`menuItem_${index}`}
          onClick={() => {
            onClick();
            setMenuOpen(false);
          }}
        >{`- ${label}`}</div>
      );
    });
  };

  return (
    <Wrapper
      className={className}
      menuOpen={menuOpen}
      bold={bold}
      width={width}
      paddingTop={paddingTop}
      margin={margin}
    >
      {backButton && (
        <NavigationButton text={backButtonText ? "Назад" : undefined} />
      )}
      <ArrowBack onClick={() => navigate(-1)} className="arrowIcon" />
      <div className="text">{children}</div>
      {betaMarker && <BetaMarker marginLeft="-8px" />}
      <div className="buttonContainer">{button && button}</div>
      {!!menuList?.length && (
        <MobileMenuIcon
          className="menuIcon"
          onClick={() => setMenuOpen(!menuOpen)}
        />
      )}

      <div
        className="menuBackground"
        onClick={() => setMenuOpen(!menuOpen)}
      ></div>
      {!!menuList?.length && (
        <DropdownMenu className="dropdownMenu" menuOpen={menuOpen}>
          {menuItems()}
        </DropdownMenu>
      )}
    </Wrapper>
  );
};

export default memo(PageHeader);
