import React, { memo, useState } from "react";
import { Title, Wrapper } from "./styles";

import { Timeline } from "antd";
import moment from "moment";
import { JobTitles } from "../../pages/dashboardEmployeesPage";
import defaultImage from "../../assets/images/avatar.png";
import Accordion from "../../UI/Accordion";
import { OrderActionsItem } from "../../types";

interface HistoryOfOrderProps {
  orderHistoryInfo: OrderActionsItem[];
  workerId: number | undefined;
}

const getEmployeeRole = (role: string) => {
  return JobTitles.find((job) => job.value === role)?.label;
};

const HistoryOfOrder: React.FC<HistoryOfOrderProps> = ({
  orderHistoryInfo,
  workerId,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const renderHistoryInfo = () => {
    return (
      <Timeline className="timeLine">
        {orderHistoryInfo?.map((action, index) => {
          return (
            <Timeline.Item
              color={action.user_id === workerId ? "#ff6633" : "#21c17a"}
              key={`orderAction_${index}`}
            >
              <div className="actionContainer">
                <img
                  className="avatar"
                  src={
                    action.avatarUrl
                      ? `data:image/jpeg;base64,${action.avatarUrl}`
                      : defaultImage
                  }
                  alt=""
                />
                <div>
                  {action.firstName} {action.secondName}
                  <span className="actionTime">
                    {action.companyRole &&
                      ` (${getEmployeeRole(action.companyRole)})`}
                    {action.user_id === workerId && " (Исполнитель)"}
                    <br />
                    {action.action_name}
                  </span>
                  <br />
                  <span className="actionTime">{` ${moment(action.time).format(
                    "HH:mm DD.MM.YY ",
                  )}`}</span>
                </div>
              </div>
            </Timeline.Item>
          );
        })}
      </Timeline>
    );
  };

  return (
    <Wrapper id="order_history">
      <Accordion
        title="История заказа"
        list={renderHistoryInfo()}
        open={open}
        setOpen={() => setOpen(!open)}
        buttonVisible={false}
        bodyMaxHeight="1500px"
        button={false}
      />
    </Wrapper>
  );
};

export default memo(HistoryOfOrder);
