import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .makeOrderBtn {
    margin-top: 55px;
  }

  p {
    width: 80%;
    font-size: 20px;
    margin: 0 auto 10px;
  }

  button {
    margin: 0 auto;
  }
`;
