import styled from "styled-components";

export const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const NotificationItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  padding: 16px;
  background: #f7f7f7;
  transition: all 0.3s linear;
  line-height: 1;
  gap: 16px;
  animation: fadeIn 0.4s linear;

  .notificationTitleBlock {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .notificationTitle {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #000;
    font-size: 15px;
    letter-spacing: -0.25px;
    margin-bottom: 2px;
  }

  .notificationTime {
    color: #757575;
    font-size: 12px;
  }

  .notificationText {
    color: #222;
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 16px;
    white-space: pre-line;
  }

  .notificationButtons {
    display: flex;
    width: 100%;
    gap: 8px;
    height: 36px;
  }

  :hover {
    box-shadow: 2px 2px 7px 4px #efefef;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
