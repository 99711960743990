import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpRequest } from "../../../utils/httpRequest";
import { CompanyAccessSettings, LoadingType, WorkerType } from "../../../types";
import { ENDPOINT } from "../../../constants/general";
import axios from "axios";
import getManagers from "../../modules/getManagers";
import { getMe, getUser } from "./authSlice";

interface UserData {
  companyRole: string;
  firstName: string;
  secondName: string;
  patronymic?: string;
  phoneNumber: string;
  email: string;
  city?: string;
}

export const getVipWorkers = createAsyncThunk(
  "company/getVipWorkers",
  async (
    {
      refId,
    }: {
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/vip-workers/${refId}/all`,
    });

    return response.data;
  },
);

export const addVipWorker = createAsyncThunk(
  "company/addVipWorker",
  async (
    {
      refId,
      phone,
      firstName,
      lastName,
      patronymic,
    }: {
      refId: string;
      phone: string;
      firstName: string;
      lastName: string;
      patronymic?: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/vip-workers/${refId}`,
      data: {
        phone,
        firstName,
        lastName,
        patronymic,
      },
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const deleteVipWorker = createAsyncThunk(
  "company/deleteVipWorker",
  async (
    {
      workerId,
      refId,
    }: {
      workerId: number;
      refId: string;
    },
    thunkApi,
  ) => {
    const response = await httpRequest({
      method: "DELETE",
      url: `${ENDPOINT}/vip-workers/${refId}/${workerId}`,
    });

    await thunkApi.dispatch(getVipWorkers({ refId }));
    await thunkApi.dispatch(getUser({ refId, id: workerId }));

    return response.data;
  },
);

export const removeManager = createAsyncThunk(
  "company/removeManager",
  async (
    {
      employeeId,
      refId,
    }: {
      employeeId: number;
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/companies/${refId}/employee/remove/${employeeId}`,
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const editManagerRole = createAsyncThunk(
  "company/editManagerRole",
  async (
    {
      role,
      employeeId,
      refId,
    }: {
      role: string;
      employeeId: number;
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/companies/${refId}/employee/update/role/${employeeId}`,
      params: {
        role,
      },
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const leaveCompany = createAsyncThunk(
  "company/leaveCompany",
  async (
    {
      refId,
    }: {
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/companies/${refId}/leave`,
    });

    thunkApi.dispatch(getMe(refId));

    return response.data;
  },
);

export const editManagerData = createAsyncThunk(
  "company/editManagerData",
  async (
    {
      data,
      employeeId,
      refId,
    }: {
      data: {
        firstName: string;
        secondName: string;
        patronymic: string;
        phoneNumber: string;
        email: string;
        companyRole: string;
        city: string;
      };
      employeeId: number;
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "PATCH",
      url: `${ENDPOINT}/companies/${refId}/employee/update/personal/${employeeId}`,
      data: {
        ...data,
      },
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const editAccessSettings = createAsyncThunk(
  "company/editAccessSettings",
  async (
    {
      accessSettings,
      employeeId,
      refId,
    }: {
      accessSettings: CompanyAccessSettings;
      employeeId: number;
      refId: string;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/companies/${refId}/employee/update/access/${employeeId}`,
      data: {
        ...accessSettings,
      },
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const validateAccessSettings = createAsyncThunk(
  "company/validateAccessSettings",
  async (
    {
      accessSettings,
    }: {
      accessSettings: CompanyAccessSettings;
    },
    thunkApi: any,
  ) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/companies/access-settings/validate`,
      data: {
        ...accessSettings,
      },
    });

    return response.data;
  },
);

export const addCompanyUser = createAsyncThunk(
  "company/addCompanyUser",
  async ({ refId, data }: { refId: string; data: UserData }, thunkApi: any) => {
    const response = await httpRequest({
      method: "POST",
      url: `${ENDPOINT}/companies/${refId}/add-managers`,
      data: {
        user: { ...data },
      },
    });

    thunkApi.dispatch(getManagers(refId));

    return response.data;
  },
);

export const editManager = createAsyncThunk(
  "company/getCompany",
  async ({
    refId,
    userId,
    data,
  }: {
    refId: string;
    userId: number;
    data: any;
  }) => {
    const response = await httpRequest({
      method: "PUT",
      url: `${ENDPOINT}/companies/${refId}/update-managers/291/${userId}`,
      data,
    });

    return response.data;
  },
);

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (refId: string) => {
    const response = await httpRequest({
      method: "GET",
      url: `${ENDPOINT}/companies/${refId}`,
    });

    return response.data;
  },
);

export const getRequisitesByInn = createAsyncThunk(
  "auth/getRequisitesByInn",
  async (inn: string | number) => {
    // const response = await httpRequest({
    //   method: "POST",
    //   url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party`,
    //   data: {
    //     query: inn,
    //   },
    // });

    const response = await axios({
      method: "POST",
      url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token 67fb8da92c4db410181f56c0edf89ba19e1b9530",
      },
      data: {
        query: inn.toString().split(" ").join(""),
      },
    });

    return response.data;
  },
);

export const updateAvatar = createAsyncThunk(
  "company/getCompany",
  async ({ refId, data }: { refId: string; data: FormData }) => {
    const response = await httpRequest({
      method: "PUT",
      path: `customers/${refId}/upload-avatar`,
      data,
    });

    return response.data;
  },
);

interface CompanySlice {
  createUser: LoadingType;
  getCompany: LoadingType;
  getWorkers: LoadingType;
  createWorker: LoadingType;
  deleteWorker: LoadingType;
  companyData: any;
  workers: WorkerType[];
}

const initialState: CompanySlice = {
  createUser: LoadingType.Idle,
  getCompany: LoadingType.Idle,
  getWorkers: LoadingType.Idle,
  createWorker: LoadingType.Idle,
  deleteWorker: LoadingType.Idle,
  companyData: null,
  workers: [],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addCompanyUser.fulfilled, (state, action) => {
        state.createUser = LoadingType.Succeeded;
      })
      .addCase(addCompanyUser.pending, (state) => {
        state.createUser = LoadingType.Pending;
        //state.error = null;
      })
      .addCase(addCompanyUser.rejected, (state, action) => {
        state.createUser = LoadingType.Failed;
        //state.error = 'Внимание какая то ошибка';
      });

    builder
      .addCase(getCompany.fulfilled, (state, action) => {
        state.getCompany = LoadingType.Succeeded;
        state.companyData = action.payload;
      })
      .addCase(getCompany.pending, (state) => {
        state.getCompany = LoadingType.Pending;
        //state.error = null;
      })
      .addCase(getCompany.rejected, (state) => {
        state.getCompany = LoadingType.Failed;
        //state.error = 'Внимание какая то ошибка';
      });

    builder
      .addCase(getVipWorkers.fulfilled, (state, action) => {
        state.workers = action.payload;
        state.getWorkers = LoadingType.Succeeded;
      })
      .addCase(getVipWorkers.pending, (state) => {
        state.getWorkers = LoadingType.Pending;
        //state.error = null;
      })
      .addCase(getVipWorkers.rejected, (state) => {
        state.getWorkers = LoadingType.Failed;
        //state.error = 'Внимание какая то ошибка';
      });

    builder
      .addCase(addVipWorker.fulfilled, (state) => {
        state.createWorker = LoadingType.Succeeded;
      })
      .addCase(addVipWorker.pending, (state) => {
        state.createWorker = LoadingType.Pending;
        //state.error = null;
      })
      .addCase(addVipWorker.rejected, (state) => {
        state.createWorker = LoadingType.Failed;
        //state.error = 'Внимание какая то ошибка';
      });

    builder
      .addCase(deleteVipWorker.fulfilled, (state) => {
        state.deleteWorker = LoadingType.Succeeded;
      })
      .addCase(deleteVipWorker.pending, (state) => {
        state.deleteWorker = LoadingType.Pending;
        //state.error = null;
      })
      .addCase(deleteVipWorker.rejected, (state) => {
        state.deleteWorker = LoadingType.Failed;
        //state.error = 'Внимание какая то ошибка';
      });
  },
});

export default companySlice.reducer;
