import React, { memo, useCallback, useEffect, useState } from "react";
import { Wrapper, Filter, ListItem, ListWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import getManagers from "../../redux/modules/getManagers";
import { ReactComponent as ProfileIcon } from "../../assets/icons/icon-bottom-profile-active.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/icon-24-basic-chevron-right.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add-white.svg";
import SideMenu from "./sideMenu";
import PageWrapper from "../../UI/PageWrapper";
import BetaMarker from "../../UI/BetaMarker";
import PageHeader from "../../UI/PageHeader";
import Button from "../../UI/Button";
import AddManagementItemEmpty from "../../components/addManagementItemEmpty";
import { CompanyAccessSettings, ManagerType } from "../../types";
import AddEmployeeSideMenu from "./addEmployeeSideMenu";
import pattern from "../../assets/images/pattern.png";

interface Filter {
  label: string;
  roles: string[];
}

export const JobTitles = [
  { label: "Руководитель компании", value: "ceo" },
  { label: "Руководитель проекта", value: "pm" },
  { label: "Бухгалтер", value: "accountant" },
  { label: "Юрист", value: "lawyer" },
  { label: "Снабженец", value: "supplier" },
  { label: "Инженер", value: "engineer" },
  { label: "Прораб", value: "foreman" },
  { label: "Механик", value: "mechanic" },
];

export const getEmployeeRole = (role: string) => {
  return JobTitles.find((job) => job.value === role)?.label;
};

const Filters: Filter[] = [
  { label: "Все", roles: [] },
  { label: "Руководители компании", roles: ["ceo"] },
  { label: "Руководители проекта", roles: ["pm"] },
  { label: "Бухгалтеры и юристы", roles: ["accountant", "lawyer"] },
  { label: "Снабженцы и инженеры", roles: ["supplier", "engineer"] },
  { label: "Прорабы и механики", roles: ["foreman", "mechanic"] },
];

const DashboardEmployeesPage: React.FC = () => {
  const navigate = useNavigate();
  const [currentFilter, setCurrentFilter] = useState<Filter>({
    label: "Все",
    roles: [],
  });
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [addEmployeeOpen, setAddEmployeeOpen] = useState<boolean>(false);
  const [employees, setEmployees] = useState<ManagerType[]>([]);
  const [selectedEmployee, setSelectedEmployee] = useState<ManagerType | null>(
    null,
  );

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  const managers: ManagerType[] = useAppSelector(
    (state) => state.managers.data,
  );

  const renderFilterItems = () => {
    return Filters.map((filter, index) => {
      return (
        <div
          key={`employeeFilter_${index}`}
          onClick={() => setCurrentFilter(filter)}
          className={`${
            filter.label === currentFilter.label ? "active " : ""
          }filterItem`}
        >
          {filter.label}
        </div>
      );
    });
  };

  const filterList = () => {
    if (currentFilter.roles.length) {
      setEmployees(
        managers.filter((user) =>
          currentFilter.roles.includes(user?.companyRole),
        ),
      );
    } else {
      managers && setEmployees([...managers]);
    }
  };

  const renderEmployees = useCallback(
    (employees: any[]) => {
      return employees.map((employee: any, index: number) => {
        return (
          <ListItem
            key={`employee_${index}`}
            onClick={() => {
              setMenuOpen(true);
              setSelectedEmployee(employee);
            }}
          >
            {employee?.avatarUrl ? (
              <img
                className="employeeAvarat"
                src={`data:image/jpeg;base64, ${employee?.avatarUrl}`}
                alt="avatar"
              />
            ) : (
              <ProfileIcon />
            )}
            <div className="userInfo">
              <div className="subTitle">
                {employee.companyRole && getEmployeeRole(employee.companyRole)}
              </div>
              <div>{`${employee.firstName} ${employee.secondName}`}</div>
            </div>
            <div className="rightGroup">
              {employee.refId === user?.customer?.refId && (
                <div className="currentUserBadge">Мой аккаунт</div>
              )}
              <ArrowIcon />
            </div>
          </ListItem>
        );
      });
    },
    [employees],
  );

  useEffect(() => {
    filterList();
  }, [currentFilter]);

  useEffect(() => {
    managers?.length && setEmployees([...managers]);
  }, [managers]);

  useEffect(() => {
    if (user?.customer?.refId && !managers?.length) {
      dispatch(getManagers(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  return (
    <PageWrapper flexDirection="column">
      <PageHeader
        button={
          user?.customer?.accessSettings?.addingEmployees &&
          employees.length ? (
            <Button
              height="32px"
              fontWeight="400"
              onClick={() => setAddEmployeeOpen(true)}
            >
              <PlusIcon />
              Добавить сотрудника
            </Button>
          ) : undefined
        }
        menuList={[
          {
            label: "Добавить сотрудника",
            onClick: () => setAddEmployeeOpen(true),
          },
        ]}
      >
        Сотрудники
      </PageHeader>
      <Wrapper>
        <Filter>{renderFilterItems()}</Filter>
        <ListWrapper>
          {!!employees.length ? (
            renderEmployees(employees)
          ) : (
            <AddManagementItemEmpty
              employees
              onClick={() => setAddEmployeeOpen(true)}
            />
          )}
        </ListWrapper>
        <SideMenu
          menuOpen={menuOpen}
          toggleOpen={setMenuOpen}
          user={selectedEmployee}
          customerRefId={user?.customer?.refId}
        />
        <AddEmployeeSideMenu
          menuOpen={addEmployeeOpen}
          toggleOpen={setAddEmployeeOpen}
          customerRefId={user?.customer?.refId}
        />
      </Wrapper>
    </PageWrapper>
  );
};

export default DashboardEmployeesPage;
