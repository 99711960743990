import React, { useCallback, useEffect, useState } from "react";
import { NotificationItem, NotificationList } from "./styles";
import { ReactComponent as CheckIcon } from "../../assets/icons/icons-24-status-icon-check-orange.svg";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import moment from "moment";
import { Notification } from "../../types";
import SideMenu from "../../UI/SideMenu";
import {
  getNotifications,
  getNotificationsCount,
} from "../../redux/store/reducers/notificationsSlice";
import Button from "../../UI/Button";
import {
  acceptCounterOffer,
  declineCounterOffer,
  getCounterOfferById,
} from "../../redux/store/reducers/orderSlice";
import { CounterOffer } from "../../types/orderSlice.types";

export const getCounterOfferDifference = (counterOffer: CounterOffer) => {
  let budget, startDate, endDate, paymentType;

  const getPaymentType = (type: string) => {
    return type === "bn" ? "Безналичный" : "Безналичный с НДС";
  };

  if (counterOffer.budget !== counterOffer.offer_id.budget) {
    budget = {
      prev: counterOffer.offer_id.budget,
      new: counterOffer.budget,
    };
  }
  if (counterOffer.startDate !== counterOffer.offer_id.startDate) {
    startDate = {
      prev: counterOffer.offer_id.startDate,
      new: counterOffer.startDate,
    };
  }
  if (counterOffer.endDate !== counterOffer.offer_id.endDate) {
    endDate = {
      prev: counterOffer.offer_id.endDate,
      new: counterOffer.endDate,
    };
  }
  if (counterOffer.paymentType !== counterOffer.offer_id.paymentType) {
    paymentType = {
      prev: counterOffer.offer_id.paymentType,
      new: counterOffer.paymentType,
    };
  }
  return `${
    budget
      ? "Бюджет: " +
        (budget.prev ? budget.prev + "₽" : "Не указан") +
        " → " +
        budget.new +
        "₽"
      : "Бюджет: Без изменений"
  }
  ${
    paymentType
      ? "\nСпособ оплаты: " +
        getPaymentType(paymentType.prev) +
        " → " +
        getPaymentType(paymentType.new)
      : "\nСпособ оплаты: Без изменений"
  }
  ${
    startDate
      ? "\nДата начала: " +
        (startDate.prev
          ? moment(startDate.prev).format("D MMM YYYY HH:mm")
          : "Не указана") +
        " → " +
        moment(startDate.new).format("D MMM YYYY HH:mm")
      : "\nДата начала: Без изменений"
  }
  ${
    endDate
      ? "\nДата окончания: " +
        (endDate.prev
          ? moment(endDate.prev).format("D MMM YYYY HH:mm")
          : "Не указана") +
        " → " +
        moment(endDate.new).format("D MMM YYYY HH:mm")
      : "\nДата окончания: Без изменений"
  }`;
};

const NotificationSidebar: React.FC<any> = ({ isOpen, close }) => {
  const dispatch = useAppDispatch();
  const [notificationsArray, setNotificationsArray] = useState<JSX.Element[]>(
    [],
  );
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { notifications, notificationsCount, getNotificationsProcess } =
    useAppSelector((state) => state.notifications);

  const [pagination, setPagination] = useState<{
    take: number;
    skip: number;
  }>({
    take: 15,
    skip: 0,
  });

  const fetchNotifications = async () => {
    if (user?.customer.refId) {
      await dispatch(getNotificationsCount({ refId: user.customer.refId }));
      dispatch(
        getNotifications({
          refId: user.customer.refId,
          take: pagination.take,
          skip: pagination.skip,
        }),
      ).then((response) => {
        if (
          response.meta.requestStatus === "fulfilled" &&
          notifications.length < notificationsCount.all
        ) {
          setPagination({
            ...pagination,
            take: pagination.take + 15,
          });
        }
      });
    }
  };

  const handleAcceptCounterOffer = (counterofferId: number) => {
    if (user?.customer.refId) {
      dispatch(
        acceptCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );
    }
  };

  const handleDeclineCounterOffer = (counterofferId: number) => {
    if (user?.customer.refId) {
      dispatch(
        declineCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      fetchNotifications();
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  const getNotificationsArray = async () => {
    const newNotifications = await Promise.all(
      notifications.map(
        async ({ title, content, date_created, is_read, userId }, index) => {
          let counterOffer: {
            type: "counterOffer";
            data: { id: string | number };
          } | null = null;
          let counterOfferData: CounterOffer | null = null;
          if (content.includes("counteroffer")) {
            counterOffer = JSON.parse(content);
            counterOfferData = await dispatch(
              getCounterOfferById({
                refId: user?.customer.refId!,
                counterofferId: Number(counterOffer?.data.id),
              }),
            ).then((response) => (counterOfferData = response.payload));
          }
          return (
            <NotificationItem key={`notification_${index}`}>
              <div className="notificationTitleBlock">
                <CheckIcon />
                <div className="notificationTitle">
                  {title}
                  <div className="notificationTime">
                    {moment(date_created).format("D MMMM HH:mm")}
                  </div>
                </div>
              </div>

              <div className="notificationText">
                {counterOfferData !== null
                  ? getCounterOfferDifference(counterOfferData)
                  : content}
              </div>
              {counterOfferData !== null && (
                <div className="notificationButtons">
                  {counterOfferData.status === "Accepted" ? (
                    <Button height="100%" disabled>
                      Предложение принято
                    </Button>
                  ) : (
                    <>
                      <Button
                        height="100%"
                        onClick={() =>
                          counterOfferData?.id &&
                          handleAcceptCounterOffer(counterOfferData?.id)
                        }
                      >
                        Принять
                      </Button>
                      <Button
                        height="100%"
                        variant="outlined"
                        onClick={() =>
                          counterOfferData?.id &&
                          handleDeclineCounterOffer(counterOfferData?.id)
                        }
                      >
                        Отклонить
                      </Button>
                    </>
                  )}
                </div>
              )}
            </NotificationItem>
          );
        },
      ),
    );
    setNotificationsArray(newNotifications);
  };

  useEffect(() => {
    getNotificationsArray();
  }, [notifications]);

  return (
    <SideMenu menuTitle="Уведомления" toggleMenuOpen={close} menuOpen={isOpen}>
      <NotificationList>
        {notificationsArray}
        {!!notifications.length &&
          notificationsCount.all > notifications.length && (
            <Button
              height="36px"
              onClick={fetchNotifications}
              disabled={getNotificationsProcess === "pending"}
            >
              Загрузить еще
            </Button>
          )}
      </NotificationList>
    </SideMenu>
  );
};

export default NotificationSidebar;

