import React, { useEffect, useState } from "react";
import {
  AddCardsWrapper,
  AddCardWrapper,
  AddDesc,
  AddIconPart,
  AddTitle,
  ButtonsWrapper,
  Content,
  ContentTitle,
  ContentTitleWrapper,
  ContentWrapper,
  List,
  Paragraph,
  RegisterForm,
  RegistrationStep,
  RoleBtn,
  SkipBtn,
  SkipWrapper,
  SubmitButton,
  VehicleBox,
  VehicleList,
  VehicleMainTitle,
  Wrapper,
} from "./styles";
import { ReactComponent as ThreePeopleIcon } from "../../assets/icons/icons-people-3.svg";
import { ReactComponent as Hoist } from "../../assets/icons/icons-vehicles-hoist-white.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import RegisterImg from "../../assets/images/register-img.png";
import { ReactComponent as SelectedIcon } from "../../assets/icons/icons-24-status-icon-check-orange.svg";
import { ReactComponent as SelectedIconSmall } from "../../assets/icons/icons-24-status-icon-check-orange-small.svg";
import { ReactComponent as CirclePlusIcon } from "../../assets/icons/24-basic-circle-plus.svg";
import RegisterOrg from "./registerOrg";
import RegisterUser from "./registerUser";
import AddManagerPopUp from "../../components/popUp/addManagerPopUp";
import AddVehiclePopUp from "../../components/popUp/addVehiclePopUp";
import { getVehiclesList } from "../../redux/store/reducers/vehicleSlice";
import getManagers from "../../redux/modules/getManagers";
import { getMe } from "../../redux/store/reducers/authSlice";

import { ManagerRow as VehicleRow } from "../../components/vehicleManagementPagesDataList";
import { ManagerRow as EmployeeRow } from "../../components/managementPagesDataList";
import { CustomerRole } from "../../types/authSlice.types";

interface Props {
  setDisable?: any;
}

const listItemsWorker: string[] = [
  "Ежедневные заказы на ваш тип спецтехники",
  "Заказы рядом с вами",
  "Удобные взаиморасчёты",
  "Электронный документооборот",
];
const listItemsClient: string[] = [
  "Быстрый заказ спецтехники",
  "База от 12000 проверенных исполнителей",
  "Удобные взаиморасчёты",
  "Электронный документооборот",
];

const UserRegistration: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [registerAs, setRegisterAs] = useState<"person" | "organization">(
    "person",
  );
  const [step, setStep] = useState<number>(2);
  const [addVehicle, setAddVehicle] = useState(false);
  const [addManager, setAddManager] = useState(false);
  const [role, setRole] = useState<CustomerRole>(CustomerRole.Customer);
  const { user, fireBaseData } = useAppSelector((state) => state?.auth);
  const refId = fireBaseData?.user?.refId;
  const registration = useAppSelector((state) => state.registration);
  const vehicleList = useAppSelector((state) => state.vehicle.vehicleList);
  const { data: managers } = useAppSelector((state) => state.managers);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getMe(refId));
  }, []);

  useEffect(() => {
    if (step === 2) {
      dispatch(getVehiclesList(refId));
    } else if (step === 3) {
      dispatch(getManagers(refId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (registration.isCompanyRegistration || searchParams.get("org")) {
      setRegisterAs("organization");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Content>
        <ContentWrapper>
          <RegistrationStep>
            <div className="stepGroup">
              <div
                className="backButton"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Назад
              </div>
            </div>
          </RegistrationStep>
          <div className="content-box">
            {step === 1 && (
              <>
                <ContentTitle>Кто вы?</ContentTitle>
                <ButtonsWrapper>
                  <RoleBtn
                    onClick={() => setRole(CustomerRole.Customer)}
                    isActive={role === "customer"}
                    width={"208px"}
                    firstStep
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Я – заказчик</div>
                  </RoleBtn>
                  <RoleBtn
                    onClick={() => setRole(CustomerRole.Worker)}
                    isActive={role === "worker"}
                    width={"208px"}
                    firstStep
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Я – исполнитель</div>
                  </RoleBtn>
                </ButtonsWrapper>
                <List>
                  {(role === "worker"
                    ? listItemsWorker
                    : role === "customer"
                    ? listItemsClient
                    : []
                  ).map((item, index) => {
                    return (
                      <div className="listItem" key={index}>
                        <div className="icon">
                          <SelectedIconSmall />
                        </div>
                        <div className="text">{item}</div>
                      </div>
                    );
                  })}
                </List>
                <ButtonsWrapper>
                  <SkipBtn onClick={() => setStep((prev) => prev + 1)}>
                    Продолжить
                  </SkipBtn>
                </ButtonsWrapper>
              </>
            )}
            {step === 2 && (
              <>
                <ContentTitle>Вы новый пользователь!</ContentTitle>
                <div className="buttons">
                  <RoleBtn
                    onClick={() => setRegisterAs("person")}
                    isActive={registerAs === "person"}
                    width={"176px"}
                    disabled={
                      registration.isCompanyRegistration ||
                      !!searchParams.get("org")
                    }
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Физлицо</div>
                  </RoleBtn>
                  <RoleBtn
                    onClick={() => setRegisterAs("organization")}
                    isActive={registerAs === "organization"}
                    width={"176px"}
                  >
                    <div className="selectedIcon">
                      <SelectedIcon />
                    </div>
                    <div className="buttonTitle">Организация</div>
                  </RoleBtn>
                </div>
                {registerAs === "organization" ? (
                  <div>
                    <RegisterOrg
                      setRole={setRole}
                      role={role}
                      setStep={setStep}
                      onChangeStep={(e) => {
                        if (e) {
                          setStep(e);
                        }
                        dispatch(getMe(refId));
                      }}
                    />
                  </div>
                ) : registerAs === "person" ? (
                  <RegisterUser
                    setRole={setRole}
                    setStep={setStep}
                    role={role}
                    onSuccess={(e: any) => {
                      if (e) {
                        setStep(e);
                      }
                      dispatch(getMe(refId));
                    }}
                  />
                ) : null}
              </>
            )}
            {step > 2 && (
              <>
                <ContentTitleWrapper>
                  <ContentTitle>Продолжим!</ContentTitle>
                  <Paragraph>
                    Чтобы скорее приступить к работе в сервисе, добавьте
                    информацию о своем парке техники и сотрудниках
                  </Paragraph>
                </ContentTitleWrapper>
                <RegisterForm>
                  {step === 4 && (
                    <VehicleBox>
                      <VehicleMainTitle>
                        Список сотрудников
                        {managers?.users?.length > 0 && (
                          <button onClick={() => setAddManager(true)}>
                            <CirclePlusIcon />
                            Добавить сотрудника
                          </button>
                        )}
                      </VehicleMainTitle>
                      <VehicleList>
                        {managers?.users?.length > 0 &&
                          managers?.users?.forEach(
                            (user: any, index: number) => {
                              if (user.companyRole !== "admin") {
                                return (
                                  <EmployeeRow value={user} index={index} />
                                );
                              }
                            },
                          )}
                      </VehicleList>
                    </VehicleBox>
                  )}

                  {step === 3 && (
                    <VehicleBox>
                      <VehicleMainTitle>
                        Список техники
                        {vehicleList?.length > 0 && (
                          <button onClick={() => setAddManager(true)}>
                            <CirclePlusIcon />
                            Добавить технику
                          </button>
                        )}
                      </VehicleMainTitle>
                      <VehicleList>
                        {vehicleList?.length > 0 &&
                          vehicleList?.map((vehicle: any, index: number) => (
                            <VehicleRow value={vehicle} index={index} />
                          ))}
                      </VehicleList>
                    </VehicleBox>
                  )}
                  <AddCardsWrapper>
                    {step === 4 && !managers?.users?.length && (
                      <AddCardWrapper>
                        <AddIconPart>
                          <ThreePeopleIcon />
                        </AddIconPart>
                        <AddTitle>Добавьте сотрудников</AddTitle>
                        <AddDesc>
                          Так вы сможете назначать заказы своим сотрудникам и
                          управлять занятостью
                        </AddDesc>
                        <SubmitButton onClick={() => setAddManager(true)}>
                          Добавить сотрудника
                        </SubmitButton>
                      </AddCardWrapper>
                    )}
                    {step === 3 && !vehicleList?.length && (
                      <AddCardWrapper>
                        <AddIconPart>
                          <Hoist />
                        </AddIconPart>
                        <AddTitle>Добавьте свою технику</AddTitle>
                        <AddDesc>
                          Так мы сможем предлагать вам наиболее подходящие
                          заказы, а вы – управлять занятостью
                        </AddDesc>
                        <SubmitButton onClick={() => setAddVehicle(true)}>
                          Добавить технику
                        </SubmitButton>
                      </AddCardWrapper>
                    )}
                  </AddCardsWrapper>
                  <SkipWrapper className="skip-wrapper">
                    <SkipBtn
                      onClick={() => {
                        if (step === 2 && role === "customer") {
                          navigate("/create-order");
                        } else if (
                          step === 3 &&
                          role === "worker" &&
                          registerAs === "person"
                        ) {
                          navigate("/search-order");
                        } else if (
                          step === 4 &&
                          registerAs === "organization" &&
                          role === "worker"
                        ) {
                          navigate("/search-order");
                        } else {
                          setStep((prev) => prev + 1);
                        }
                      }}
                    >
                      {step === 3
                        ? vehicleList?.length > 0 && "Продолжить"
                        : vehicleList?.length === 0 && "Заполнить позже"}
                      {step === 4
                        ? managers?.users?.length > 0 && "Продолжить"
                        : managers?.users?.length === 0 && "Заполнить позже"}
                    </SkipBtn>
                  </SkipWrapper>
                </RegisterForm>
                {addVehicle && (
                  <AddVehiclePopUp
                    onSubmit={() => {
                      setAddVehicle(false);
                      if (user?.customer?.refId)
                        dispatch(getVehiclesList(user?.customer?.refId));
                    }}
                    close={() => setAddVehicle(false)}
                  />
                )}
                {addManager && (
                  <AddManagerPopUp
                    onSuccess={() => {
                      dispatch(getManagers(user?.customer?.refId));
                      setAddManager(false);
                    }}
                    close={() => setAddManager(false)}
                  />
                )}
              </>
            )}
          </div>
        </ContentWrapper>
      </Content>
      <div className="image">
        <img src={RegisterImg} alt="" />
      </div>
    </Wrapper>
  );
};

export default UserRegistration;
