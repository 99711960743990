import React, { memo } from "react";
import { Wrapper } from "./styles";
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow-gray.svg";
import { useNavigate } from "react-router-dom";

interface INavigationButtonProps {
  text?: string;
  direction?: "forward" | "backward";
}

const NavigationButton: React.FC<INavigationButtonProps> = ({
  text,
  direction,
}) => {
  const navigate = useNavigate();

  const clickHandler = () => {
    if (!direction || direction !== "forward") {
      navigate(-1);
    }
  };

  return (
    <Wrapper
      direction={direction}
      onClick={clickHandler}
      //text={!!text}
    >
      <LeftArrow />
      {text ? text : null}
    </Wrapper>
  );
};

export default memo(NavigationButton);
