import React, { memo, useCallback, useEffect, useState } from "react";
import {
  AgreePrivacy,
  BankData,
  BoxesWrapper,
  CardDetailData,
  ContactDesc,
  ContactDetail,
  Desc,
  ErrorBody,
  FirstColumn,
  Footer,
  InputsWrapper,
  InputWrapper,
  PaymentDesc,
  PaymentDetail,
  PaymentDetailWrapper,
  PaymentHeader,
  PaymentMethodBox,
  PaymentMethodList,
  PaymentRow,
  PaymentTitle,
  PopUpDesc,
  Title,
  Wrapper,
  PaymentDescInfo,
  SecondColumn,
  TimerWrapper,
} from "./styles";

import { ReactComponent as CirclePlus } from "../../assets/icons/circle-plus.svg";
import { ReactComponent as CircleCheck } from "../../assets/icons/icons-24-status-icon-check-2.svg";
import { ReactComponent as CloseRed } from "../../assets/icons/close-red-circle.svg";
import { ReactComponent as TimerIcon } from "../../assets/icons/24-time-timer.svg";

import Button from "../../UI/Button";
import PopUpContainer from "../popUp/popUpContainer";
import { useNavigate, useParams } from "react-router-dom";
import {
  IOrderDoc,
  getSingleOrders,
} from "../../redux/store/reducers/orderSlice";
import bankData from "../../assets/images/bank-data.svg";
import cardLogo from "../../assets/images/card-logo.png";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import {
  fetchGetUserForOrder,
  getOrderDocs,
  getOrderOffers,
  getTechTask,
} from "../../redux/store/reducers/orderSlice";
import NumberFormat from "react-number-format";
import { DatePicker } from "antd";
import moment from "moment";
import getCards from "../../redux/modules/getCards";
import AddCardInAppointExecutorPopUp from "../popUp/addCardInAppointExecutorPopUp";
import { ENDPOINT } from "../../constants/general";
import axios from "axios";
import UserAgreementPopUp from "../popUp/userAgreementPopUp";
import { httpRequest } from "../../utils/httpRequest";
import useHttpRequest from "../../hooks/useHttpRequest";
import { getCountdown } from "../../utils/countdown";
import Docs from "../infoAboutOrderContainer/Docs";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";
import Loading from "../loading";
import { getUserLocation } from "../../utils/getUserLocation";

const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
  disabled,
}: any) => {
  return (
    <InputWrapper
      active={type !== "role" && value?.length}
      style={{ width: width ? width : "100%" }}
      className={type !== "role" && value?.length ? "active" : ""}
    >
      <>
        <span className="label">
          <span className="text">{label}</span>{" "}
          <span className="required">{required ? "*" : ""}</span>
        </span>
        {type === "inn" ? (
          <NumberFormat
            format="### ### ######"
            name={name}
            value={value || ""}
            type={type || "text"}
            placeholder={placeholder}
            onChange={onChange}
            spellCheck={false}
          />
        ) : type === "tel" ? (
          <NumberFormat
            name={name}
            value={value || ""}
            prefix={"+"}
            placeholder={placeholder}
            onChange={onChange}
            spellCheck={false}
          />
        ) : type === "date" ? (
          <div className="date-input">
            <DatePicker
              format={"ll"}
              value={value ? moment(value) : null}
              onChange={(e, date) => {
                onChange({
                  target: {
                    value: e?.format("YYYY-MM-DD"),
                    name,
                  },
                });
              }}
            />
          </div>
        ) : (
          <input
            name={name}
            value={value || ""}
            type={type || "text"}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            spellCheck={false}
          />
        )}
      </>
    </InputWrapper>
  );
};

const AppointExecutor: React.FC = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id, userId } = params;

  const [financePopUp, setFinancePopUp] = useState<boolean>(false);
  const [addCardUrl, setAddCardUrl] = useState<{
    redirectUrl: string;
    type: "card" | "order";
  }>({
    redirectUrl: "",
    type: "card",
  });
  const [openIframe, setOpenIframe] = useState(false);
  const [currentOffer, setCurrentOffer] = useState<any>(null);
  const [chosenPaymentType, setChosenPaymentType] = useState<any>({});
  const [agreement, setAgreement] = useState(false);
  const [accountPopUp, setAccountPopUp] = useState<boolean>(false);
  const [promoCode, setPromoCode] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [billLink, setBillLink] = useState<string>("");
  const [location, setLocation] = useState<[number, number] | null>(null);
  const [countdownDateStart, setCountdownDateStart] = useState<
    string | undefined
  >();
  const [countdown, setCountdown] = useState<string>("");
  const user = useAppSelector((state: RootState) => state.auth.user);
  const { offers, setWorkerLoader, singleOrder, orderDocs, techTaskLoading } =
    useAppSelector((state: RootState) => state.order);

  const { data: cards } = useAppSelector((state) => state.cards);

  const onCloseIframe = useCallback(
    (process: any) => {
      if (addCardUrl.type === "order") {
        navigate(`/o/${singleOrder.order.id}`);
      } else {
        setAddCardUrl({
          redirectUrl: "",
          type: "card",
        });
      }

      setOpenIframe(false);
    },
    [addCardUrl?.type, navigate, singleOrder?.order?.id],
  );

  const [notificationProcess, notificationRequest] = useHttpRequest();
  const [signProcess, signRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      dispatch(
        getOrderDocs({
          refId: user?.customer?.refId!,
          orderId: singleOrder.order.id,
        }),
      );
    },
    onError: (err: any) => {},
  });

  const [getDocumentProcess, getDocumentProcessRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      dispatch(
        getOrderDocs({
          refId: user?.customer?.refId!,
          orderId: singleOrder.order.id,
        }),
      );
      setBillLink(res?.data?.fullpath);
    },
    onError: (err: any) => {},
  });

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getCards(user.customer?.refId));
    }
  }, [openIframe]);

  useEffect(() => {
    if (
      orderDocs?.find((document: any) => document.documentType === "tech_task")
        ?.signDate
    ) {
      const countDownDate: string = orderDocs?.find(
        (document: any) => document.documentType === "tech_task",
      )!.signDate!;
      setCountdownDateStart(countDownDate);
    }
  }, [orderDocs]);

  useEffect(() => {
    if (countdownDateStart?.length) {
      setCountdown(getCountdown(countdownDateStart));
    }
  }, [countdownDateStart]);

  useEffect(() => {
    if (countdown?.length && countdownDateStart?.length) {
      setTimeout(() => {
        setCountdown(getCountdown(countdownDateStart));
      }, 1000);
    }
  }, [countdown]);

  useEffect(() => {
    if (!accountPopUp && user?.customer?.refId) {
      dispatch(
        getSingleOrders({
          refId: user.customer?.refId,
          orderId: singleOrder.order.id,
        }),
      );
    }
  }, [accountPopUp]);

  useEffect(() => {
    if (techTaskLoading === "fulfilled") {
      const document: any = orderDocs?.find(
        (document: any) => document.documentType === "tech_task",
      );

      signRequest({
        method: "PUT",
        url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/sign-an-agreement`,
        data: {
          fileId: document?.id,
          docType: "tech_task",
          coordinates: [
            singleOrder?.order?.latitude,
            singleOrder?.order?.longitude,
          ],
        },
      });
    }
  }, [dispatch, techTaskLoading]);

  useEffect(() => {
    if (orderDocs && orderDocs?.length > 0) {
      orderDocs.map((document: IOrderDoc) => {
        if (document.isSignedByCustomer === 0) {
          signRequest({
            method: "PUT",
            url: `${ENDPOINT}/orders/${user?.customer?.refId}/${singleOrder?.order?.id}/sign-an-agreement`,
            data: {
              fileId: document?.id,
              docType: document?.documentType,
              coordinates: [
                singleOrder?.order?.latitude,
                singleOrder?.order?.longitude,
              ],
            },
          });
        }
      });
    }
  }, [orderDocs]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (addCardUrl.redirectUrl) {
      timer = setInterval(() => {
        notificationRequest({
          method: "GET",
          url: `${ENDPOINT}/actions/get-updates`,
          params: {
            userId: user?.customer?.id,
            list: true,
          },
        });
      }, 15000);
    }

    return () => clearInterval(timer);
  }, [addCardUrl?.redirectUrl, user?.customer?.id]);

  useEffect(() => {
    if (!notificationProcess.loading) {
      const currentOrderData = notificationProcess?.data?.find(
        (process: any) =>
          process?.orderId === singleOrder?.order?.id &&
          ["payment_success", "payment_error"].includes(process?.type),
      );

      if (currentOrderData) {
        setTimeout(() => {
          onCloseIframe(currentOrderData);
        }, 3000);
      }
    }
  }, [notificationProcess, onCloseIframe, singleOrder?.order?.id]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getOrderOffers({ refId: user.customer.refId, orderId: id }));
      setPhoneNumber(user?.customer?.phoneNumber || "");
      setEmail(user?.customer?.email || "");
      setPromoCode(user?.customer?.refId || "");
      dispatch(getCards(user.customer.refId));
    }
  }, [user?.customer?.refId]);

  useEffect(() => {
    if (offers && userId && id) {
      const result = offers.find(
        (item: any) =>
          item?.workerId === parseInt(userId) && item.orderId === parseInt(id),
      );
      setCurrentOffer(result);
    }
  }, [offers]);

  const createSaveCardSession = async () => {
    try {
      const { data } = await axios.post<{
        success: boolean;
        redirectUrl?: string;
      }>(
        `${ENDPOINT}/pay/${user?.customer?.refId}/init-session`,
        {
          type: "card",
          orderId: singleOrder.order.id,
        },
        {
          headers: {
            Authorizaton: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      if (data.redirectUrl) {
        setAddCardUrl({ redirectUrl: data.redirectUrl, type: "card" });
      }
    } catch (error) {}
  };

  const getLocation = () => {
    const success = (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      setLocation([latitude, longitude]);
    };
    getUserLocation(success);
  };

  const getPaymentDocument = async (orderDocs: IOrderDoc[] | null) => {
    if (location) {
      getDocumentProcessRequest({
        method: "POST",
        url: `${ENDPOINT}/pay/${user?.customer?.refId}/payment-bill/order/${id}`,
        data: {
          offerId: currentOffer?.id,
          location: {
            latitude: location[0],
            longitude: location[1],
          },
        },
      });

      if (
        user?.customer?.refId &&
        !orderDocs?.find((document) => document.documentType === "tech_task")
      ) {
        await dispatch(
          getTechTask({
            refId: user?.customer?.refId,
            offerId: currentOffer?.id,
          }),
        );

        await dispatch(
          getOrderDocs({
            refId: user?.customer?.refId,
            orderId: singleOrder?.order?.id,
          }),
        );
      }
    } else {
      getLocation();
    }
  };

  useEffect(() => {
    if (id && userId && user?.customer?.refId)
      dispatch(
        fetchGetUserForOrder({
          refId: user.customer.refId,
          orderId: id,
          userId: userId,
        }),
      );
  }, [id, userId, user?.customer?.refId]);

  const handleSetWorker = useCallback(() => {
    if (id && userId && user?.customer?.refId) {
      const refId = user?.customer?.refId;
      const path = `${ENDPOINT}/pay/${refId}/init-session`;

      httpRequest({
        method: "POST",
        url: path,
        data: {
          type: "order",
          orderId: singleOrder.order.id,
          offerId: currentOffer.id,
          card_uid: chosenPaymentType?.cardUId,
        },
      }).then((res) => {
        setAddCardUrl({
          redirectUrl: res?.data?.redirectUrl,
          type: "order",
        });
        //window.open(res?.data?.redirectUrl);
      });

      // dispatch(
      //   fetchSetWorker({
      //     refId: user.customer.refId,
      //     orderId: id,
      //     offerId: currentOffer.id,
      //   })
      // );
    }
  }, [
    currentOffer?.id,
    id,
    singleOrder?.order?.id,
    user?.customer?.refId,
    userId,
    chosenPaymentType,
  ]);

  useEffect(() => {
    if (setWorkerLoader === "succeeded" && user?.customer?.refId) {
      setPopUp({ ...popUp, setExecutor: false });
      navigate(`/o/${id}`);
      dispatch(
        getOrderOffers({
          refId: user?.customer?.refId,
          orderId: id,
        }),
      );
    }
  }, [setWorkerLoader]);

  const [popUp, setPopUp] = useState({
    cardData: false,
    cardError: false,
    setExecutor: false,
  });

  useEffect(() => {
    if (user?.customer?.companyId) {
      setChosenPaymentType("account");
    }
  }, [user]);

  useEffect(() => {
    if (addCardUrl.redirectUrl) {
      setOpenIframe(true);
    }
  }, [addCardUrl?.redirectUrl]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getOrderDocs({ refId: user.customer.refId, orderId: id }));
      dispatch(getSingleOrders({ refId: user.customer.refId, orderId: id }));
    }
  }, [user]);

  useEffect(() => {
    getLocation();
  }, []);

  if (!currentOffer)
    return (
      <Loading
        title="Загрузка страницы оплаты"
        description="Не закрывайте окно браузера"
      />
    );

  return (
    <PageWrapper flexDirection="column" minHeight="calc(100vh - 137px)">
      <PageHeader>Оплата заказа</PageHeader>
      <BoxesWrapper>
        <FirstColumn>
          <Wrapper>
            <Title>
              Вы назначаете {currentOffer?.worker?.secondName}{" "}
              {currentOffer?.worker?.firstName} исполнителем своего заказа
            </Title>
            <Desc>
              Для того, чтобы ваши отношения с исполнителем были правильно
              оформлены, необходимо подписать документы. <br /> Это даст всем
              сторонам уверенность в сделке, а Rukki выступит гарантом
              выполнения обязательств
            </Desc>
            {user?.customer && (
              <Docs paddingless userInfo={{ ...user?.customer! }} />
            )}
          </Wrapper>
          <Wrapper>
            <Title>Выберите способ оплаты</Title>
            <Desc>
              На этом этапе вы подтверждаете исполнителю свою
              платежеспособность. <br /> Исполнитель получит деньги за работу
              только после того, как вы примете результат
            </Desc>
            <PaymentMethodList>
              <PaymentTitle>Способ оплаты</PaymentTitle>
              {user?.customer?.companyId === null &&
                cards?.map(
                  ({
                    PanMask: panMask,
                    CardUId: cardUId,
                    EMonth: eMonth,
                    EYear: eYear,
                    Status: status,
                    CardHolder: cardHolder,
                  }: any) => {
                    const active = chosenPaymentType?.cardUId === cardUId;
                    return (
                      <PaymentMethodBox
                        active={active}
                        // onClick={() => setFinancePopUp(true)}
                        onClick={() => {
                          setChosenPaymentType({
                            panMask,
                            cardUId,
                            eMonth,
                            eYear,
                            status,
                            cardHolder,
                          });
                        }}
                      >
                        <PaymentHeader active={active}>
                          <b className="text">
                            {eMonth}/{eYear}
                          </b>
                          {active ? (
                            <CircleCheck className="circle" />
                          ) : (
                            <div className="circle" />
                          )}
                        </PaymentHeader>
                        <PaymentDesc active={active}>
                          <small>{panMask.slice(0)}</small>
                          {/* <br />  */}
                        </PaymentDesc>
                        <Footer active={active}>Оплата картой</Footer>
                      </PaymentMethodBox>
                    );
                  },
                )}
              {/* <PaymentMethodBox onClick={() => setFinancePopUp(true)}>
                  <PaymentHeader>
                    <b className="text">Оплата по счету</b>
                    <div className="circle" />
                  </PaymentHeader>
                  <Footer>Безналичный расчет</Footer>
                </PaymentMethodBox> */}
              {user?.customer?.companyId !== null && (
                <PaymentMethodBox
                  active={chosenPaymentType === "account"}
                  // onClick={() => setFinancePopUp(true)}
                  onClick={() => {
                    setChosenPaymentType("account");
                  }}
                >
                  <PaymentHeader active={chosenPaymentType === "account"}>
                    <b className="text">{`Оплата\nпо счету`}</b>
                    {chosenPaymentType === "account" ? (
                      <CircleCheck className="circle" />
                    ) : (
                      <div className="circle" />
                    )}
                  </PaymentHeader>
                  <PaymentDesc active={chosenPaymentType === "account"}>
                    {/* <br />  */}
                  </PaymentDesc>
                  <Footer active={chosenPaymentType === "account"}>
                    Расчётный счет
                  </Footer>
                </PaymentMethodBox>
              )}
              {user?.customer?.companyId === null && (
                <PaymentMethodBox onClick={createSaveCardSession}>
                  <PaymentHeader>
                    <b className="text">Добавить карту</b>
                    <CirclePlus className="circle" />
                  </PaymentHeader>
                  <Footer>Мир, UnionPay</Footer>
                </PaymentMethodBox>
              )}
              <PaymentDescInfo className="paymentDescInfo text">
                После принятия платежных данных сумма заказа будет заморожена на
                специальном счете сервиса Рукки, а по завершению работ будет
                разморожена на счету исполнителя.
              </PaymentDescInfo>
            </PaymentMethodList>
          </Wrapper>
          {user?.customer?.companyId === null && (
            <ContactDetail>
              <Title style={{ marginBottom: "8px" }}>Контактные данные</Title>
              <InputsWrapper>
                <InputBox
                  label="Электронная почта"
                  value={email}
                  onChange={(e: any) => setEmail(e?.target?.value)}
                />
                <InputBox
                  label="Телефон"
                  type="tel"
                  value={phoneNumber}
                  onChange={(e: any) => setPhoneNumber(e?.target?.value)}
                />
              </InputsWrapper>
              <ContactDesc>
                Если вам нужен чек, то уточните ваши контактные данные.
                <br />
                <br />
                Эти данные мы будем использовать для отправки чека и для связи
                по вопросам оплаты.
              </ContactDesc>
            </ContactDetail>
          )}
        </FirstColumn>
        <SecondColumn>
          <TimerWrapper>
            <div className="timer">
              <TimerIcon />
              <div className="text">{countdown}</div>
            </div>
            <div className="text">на оплату заказа</div>
          </TimerWrapper>
          <PaymentDetailWrapper>
            <Title>Детали платежа</Title>
            {user?.customer?.companyId === null && (
              <InputBox
                label="Промокод"
                // value={promoCode}
                value={"Скоро будет доступно!"}
                disabled
                onChange={(e: any) => setPromoCode(e?.target?.value)}
              />
            )}
            {/*<PromoCodeText>Скидка 5% на стоимость работ</PromoCodeText>*/}
            <PaymentDetail>
              <PaymentRow>
                <span className="name">Стоимость работ</span>
                <span className="price">{currentOffer?.budget} ₽</span>
              </PaymentRow>
              {user?.customer?.companyId === null && (
                <PaymentRow>
                  <span className="name">Скидка (промо-код)</span>
                  <span className="price red"></span>
                </PaymentRow>
              )}
              <PaymentRow className="total">
                <span className="name total">Итого</span>
                <b className="price total">
                  {parseInt(currentOffer?.budget) +
                    parseInt(currentOffer?.budget) *
                      (parseInt(currentOffer?.worker?.comission || 0) /
                        100)}{" "}
                  ₽
                </b>
              </PaymentRow>
            </PaymentDetail>
            <Button
              onClick={() => {
                if (chosenPaymentType === "account") {
                  !getDocumentProcess.loading && getPaymentDocument(orderDocs);
                } else {
                  handleSetWorker();
                }
              }}
              onDisabledAction={
                chosenPaymentType === "account" ? getLocation : undefined
              }
              disabled={
                (chosenPaymentType === "account"
                  ? getDocumentProcess.loading || !location
                  : !chosenPaymentType.cardUId || getDocumentProcess.loading) ||
                !!orderDocs?.find(
                  (document) => document.documentType === "bill",
                )
              }
            >
              {chosenPaymentType === "account"
                ? "Сформировать счет"
                : "Оплатить"}
            </Button>
            <AgreePrivacy>
              При оплате, вы принимаете{"\n"}
              <span onClick={() => setAgreement(true)}>"Правила сервиса"</span>.
              {/* Нажимая кнопку ниже, вы соглашаетесь с{" "}
              <span onClick={() => setAgreement(true)}>
                пользовательским соглашением
              </span> */}
              {/* Нажимая кнопку ниже, вы соглашаетесь с{" "}
              <span>пользовательским соглашением</span> */}
            </AgreePrivacy>
          </PaymentDetailWrapper>
        </SecondColumn>
      </BoxesWrapper>
      {/* {accountPopUp && (
        <PaymentBillPopUp
          close={() => setAccountPopUp(false)}
          refId={user?.customer?.refId}
          offerId={currentOffer?.id}
          orderId={singleOrder?.order?.id}
          orderDocs={orderDocs}
        />
      )} */}
      {openIframe && (
        <PopUpContainer middle close={onCloseIframe}>
          <iframe
            src={addCardUrl.redirectUrl}
            width="100%"
            height="557px"
            frameBorder={0}
          />
        </PopUpContainer>
      )}
      {popUp.cardData && (
        <PopUpContainer
          title="Привязать карту"
          button="Добавить карту"
          close={() => setPopUp({ ...popUp, cardData: false })}
        >
          <CardDetailData>
            <BankData>
              <img src={bankData} alt="" />
              <div className="text">
                Все платежи обрабатываются на стороне АО «Тинькофф Банк» и
                надежно защищены.
              </div>
            </BankData>
            <InputBox
              label="Номер карты"
              type="card-number"
              prefix={<img src={cardLogo} alt="logo" />}
              autoFocus
            />
            <div className="data">
              <div className="half">
                <InputBox type="expire-date" label="ММ/ГГ" />
              </div>
              <div className="half">
                <InputBox type="cvc" label="Код CVC" />
              </div>
            </div>
          </CardDetailData>
          <PopUpDesc>
            Нажимая кнопку «Добавить карту» вы привязываете указанную банковскую
            карту для расчетов в сервисе Rukki и принимаете
            <span> Правила использования </span>
            сервиса
          </PopUpDesc>
        </PopUpContainer>
      )}
      {popUp.cardError && (
        <PopUpContainer
          title="Добавление карты"
          button="Попробовать еще раз"
          close={() => setPopUp({ ...popUp, cardError: false })}
        >
          <ErrorBody>
            <div className="title">
              <CloseRed />
              Карта не добавлена
            </div>
            <div className="desc">
              Проверьте информацию о карте и попробуйте ввести ее еще раз. Если
              карта не добавляется, попробуйте использовать другую или выберите
              наличный способ расчета.
            </div>
          </ErrorBody>
        </PopUpContainer>
      )}
      {/* {popUp.setExecutor && (
              <PopUpContainer
                  title="Назначение исполнителя"
                  button="Назначить"
                  close={() => setPopUp({...popUp, setExecutor: false})}
                  onSubmit={handleSetWorker}
                  loading={setWorkerLoader}
                  disabled={setWorkerLoader === "pending"}
              >
                <ErrorBody>
                  <div className="title">Вы назначаете исполнителя</div>
                  <PopUpDesc>
                    Вы уверены, что хотите назначить исполнителем?
                  </PopUpDesc>
                </ErrorBody>
              </PopUpContainer>
          )} */}
      {financePopUp && (
        // chosenPayment !== "pay-by-account" &&
        // user.customer.verificationStatus === "confirmed" && (
        // <SetOrderAccountPopUp
        //   close={() => setFinancePopUp(false)}
        //   cardUId="fdsfsdf"
        //   // cardUId={cardUid}
        //   refId={user.customer?.refId}
        //   onSubmit={addCard}
        // />
        <AddCardInAppointExecutorPopUp close={() => setFinancePopUp(false)} />
      )}
      {agreement && <UserAgreementPopUp close={() => setAgreement(false)} />}
    </PageWrapper>
  );
};

export default AppointExecutor;

