import styled from "styled-components";
import { styles } from "../../styles/styles";

export const PopUpContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 1000;

  @media screen and (max-width: 480px) {
    align-items: flex-end;
  }
`;

export const PopUpBackground = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PopUpWrapper = styled.div`
  width: 600px;
  min-height: 498px;
  height: fit-content;
  max-height: 90vh;
  overflow: hidden;
  background-color: white;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 24px 0 0 0;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  @media screen and (max-width: 480px) {
    border-radius: 20px 20px 0 0;
    margin: 0;
    max-height: 100vh;
  }
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  //padding: 0 24px 24px 24px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;

  .datePicker {
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s linear;
    width: 100% !important;

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-color: rgba(0, 0, 0, 0.1) !important;
    }

    &:focus-within {
      border: 1px solid #999999 !important;
      box-shadow: none !important;
    }

    &.ant-picker-focused {
      box-shadow: none !important;
    }
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 24px;

  .label {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000;
  }

  .labelSmall {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000;
  }

  input {
    width: 100%;
  }
`;

export const TimeBox = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .label {
    font-size: 25px;
  }
  .input-box {
    width: 47%;
    .label {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.539261);
    }
    .ant-picker {
      width: 100%;
      padding: 0;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      outline: 0;
      box-shadow: unset !important;
      border-radius: 0;
      .ant-picker-suffix {
        display: none;
      }
    }
    input {
      width: 100% !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: rgba(0, 0, 0, 0.87);
      border: 0;
      outline: 0;
    }
  }
  .date {
  }
  .time {
    margin-left: auto;
  }
  .pill {
    padding: 2.5px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: rgba(153, 153, 153, 0.8);
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    margin: 12px 8px 0 0;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
  .active {
    border-color: ${styles.mainColor};
    color: ${styles.mainColor};
  }

  @media screen and (max-width: 360px) {
    flex-direction: column;
    gap: 15px;
    .input-box {
      width: 100%;
    }
  }
`;

export const AddVehicleBlock = styled.div<{ isOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "220px" : "20px")};
  background: ${({ isOpen }) => (isOpen ? "#f7f4f1" : "#ffffff")};
  padding: ${({ isOpen }) => (isOpen ? "16px 24px" : "0 40px")};
  transition: all 0.3s linear;

  .title {
    transition: all 0.3s linear;
    font-size: ${({ isOpen }) => (isOpen ? "16px" : "14px")};
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${({ isOpen }) => (isOpen ? "#222222" : "#ff6633")};
    ${({ isOpen }) => !isOpen && "cursor: pointer"};
    width: fit-content;

    &:hover {
      ${({ isOpen }) => !isOpen && "color: #222222"};
    }
  }

  .inputsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 11px;
    width: 100%;
  }

  .buttonsWrapper {
    padding-top: 8px;
    display: ${({ isOpen }) => (isOpen ? "flex" : "none")};
    gap: 32px;
    align-items: center;
    justify-content: flex-end;

    .cancelButton {
      cursor: pointer;
      transition: all 0.3s linear;
      font-size: 14px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      color: #f63;

      &:hover {
        color: #222222;
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: unset;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  .input-box {
    width: 100%;
    .label {
      font-weight: 400;
      font-size: 15px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.539261);
    }
    .ant-picker {
      width: 100%;
      padding: 0;
      border: 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      outline: 0;
      box-shadow: unset !important;
      border-radius: 0;
      .ant-picker-suffix {
        display: none;
      }
    }
    input {
      width: 100% !important;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      color: rgba(0, 0, 0, 0.87);
      border: 0;
      outline: 0;
    }
  }
  .date {
  }
  .time {
    margin-left: auto;
  }
  .pill {
    padding: 2.5px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;

    color: rgba(153, 153, 153, 0.8);
    border: 1px solid #e3e3e3;
    border-radius: 10px;
    margin: 12px 8px 0 0;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
  }
  .active {
    border-color: ${styles.mainColor};
    color: ${styles.mainColor};
  }
`;
export const WrapperBottom = styled.div`
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CommissionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .commission-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.14;
  }
  .commission-sum {
    font-size: 16px;
    font-weight: 500;
  }
  .income-sum {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const EarnPrice = styled.div`
  width: 100%;
`;

export const Description = styled.div`
  width: 100%;
  margin: 24px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;
