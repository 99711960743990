import { createSlice } from "@reduxjs/toolkit";

interface UiInterface {
  isRegistration: boolean;
}

const initialState: UiInterface = {
  isRegistration: false,
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsRegistration(state) {
      state.isRegistration = true;
    },
    setNotRegistration(state) {
      state.isRegistration = false;
    },
  },
});

export const { setIsRegistration, setNotRegistration } = uiSlice.actions;

export default uiSlice.reducer;
