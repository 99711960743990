import React, { memo, useEffect, useState } from "react";
import {
  Map,
  Placemark,
  Polyline,
  YMaps,
  ZoomControl,
} from "@pbe/react-yandex-maps";
import { useAppSelector } from "../../redux/hooks";
import moment from "moment";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import { ENDPOINT_FOR_IMG } from "../../constants/general";
import { Wrapper } from "./styles";
import { RootState } from "../../redux/store/store";

const MapContainer: React.FC<{
  fallbackCoords?: [number, number] | null;
  coords?: [number, number] | [null, null];
  address: string | null;
}> = ({ fallbackCoords, coords, address }) => {
  const singleOrder = useAppSelector(
    (state: RootState) => state.order.singleOrder,
  );
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const [ymaps, setYmaps] = useState<any>();
  const [center, setCenter] = useState<number[]>([]);
  const [mouseOverTime, setMouseOverTime] = useState(-1);

  const iconPath = vehicleCategories.find(
    ({ title, icon }: { title: any; icon: any }) =>
      title.trim() === singleOrder?.order?.title?.trim() && icon,
  )?.icon;

  useEffect(() => {
    // @ts-ignore
    coords?.length ? setCenter(coords!) : setCenter(fallbackCoords);
  }, []);

  return (
    <Wrapper id="order_map">
      <div className="cardHeader">На карте</div>
      <div className="subTitle">Местонахождение техники</div>
      <div className="cardText">{address}</div>
      <div className="mapContainer">
        <YMaps query={{ lang: "ru_RU" }}>
          <Map
            modules={["templateLayoutFactory"]}
            width={"100%"}
            height={"100%"}
            onLoad={(e: any) => setYmaps(e)}
            defaultState={{
              center: center,
              zoom: 16,
            }}
          >
            {ymaps && singleOrder?.coordinates?.length ? (
              <Polyline
                // @ts-ignore
                geometry={singleOrder?.coordinates
                  ?.filter(({ latitude, longitude }: any, index: number) => {
                    if (index > 0) {
                      const { latitude: lat, longitude: long } =
                        singleOrder?.coordinates[index - 1];
                      const multiplyInto = 1000;
                      return (
                        Math.trunc(lat * multiplyInto) !==
                          Math.trunc(latitude * multiplyInto) &&
                        Math.trunc(long * multiplyInto) !==
                          Math.trunc(longitude * multiplyInto)
                      );
                    }
                    return true;
                  })
                  ?.map(({ latitude, longitude }: any) => [
                    latitude,
                    longitude,
                  ])}
                options={{
                  strokeColor: "#ff3358",
                  strokeWidth: 4,
                  strokeStyle: "shortdash",
                  openHintOnHover: true,
                  // @ts-ignore
                  fillColor: "#00FF00",
                }}
              />
            ) : (
              <></>
            )}
            {ymaps &&
              singleOrder?.coordinates
                ?.slice(0, singleOrder?.coordinates?.length - 1)
                ?.filter(({ latitude, longitude }: any, index: number) => {
                  if (index > 0) {
                    const { latitude: lat, longitude: long } =
                      singleOrder?.coordinates[index - 1];
                    const multiplyInto = 1000;
                    return (
                      Math.trunc(lat * multiplyInto) !==
                        Math.trunc(latitude * multiplyInto) &&
                      Math.trunc(long * multiplyInto) !==
                        Math.trunc(longitude * multiplyInto)
                    );
                  }
                  return true;
                })
                ?.map((coord: any, index: any) => (
                  <Placemark
                    modules={["geoObject.addon.balloon"]}
                    onMouseOver={() => {
                      setMouseOverTime(index);
                    }}
                    onHover={() => {
                      setMouseOverTime(index);
                    }}
                    onMouseOut={() => setMouseOverTime(-1)}
                    onMouseLeave={() => setMouseOverTime(-1)}
                    options={{
                      // @ts-ignore
                      preset: "islands#circleIcon",
                      iconLayout: ymaps?.templateLayoutFactory?.createClass(
                        `<div class="polyline-point">
                                  <div class="time ${
                                    mouseOverTime === index ? "active" : ""
                                  }">
                                    ${moment(coord?.createdAt)
                                      .calendar()
                                      .split(" ")
                                      .join(" ")}
                                  </div>
                                </div>`,
                      ),
                      iconContentSize: [8, 8],
                      iconOffset: [0, 0],
                      iconShape: {
                        type: "Rectangle",
                        //@ts-ignore
                        coordinates: [
                          [0, 0],
                          [24, 24],
                        ],
                      },
                    }}
                    geometry={{
                      type: "Point",
                      coordinates: [coord?.latitude, coord?.longitude],
                    }}
                    properties={{
                      iconContent: `
                              <div class="polyline-point">
                                <div class="time ${
                                  mouseOverTime === index ? "active" : ""
                                }">
                                ${moment(coord?.createdAt).format("HH:mm")}
                                </div>
                              </div>`,
                    }}
                  />
                ))}
            <Placemark
              width="0px"
              height="0px"
              z
              options={{
                zIndex: 999,
                preset: "islands#circleIcon",
                iconColor: "#423189",
                draggable: false,
                hideIconOnBalloonOpen: false,
                hasBalloon: true,
                balloonShadow: false,
                balloonPanelMaxMapArea: 0,
              }}
              geometry={{
                type: "Point",
                coordinates: center,
              }}
              properties={{
                iconContent: `
                        <div class="order-placemark">   
                          <img class="placemark" src=${placemark} alt="" />
                          <img class="vehicle" src=${
                            iconPath
                              ? `${ENDPOINT_FOR_IMG}/${iconPath}`
                              : `${ENDPOINT_FOR_IMG}/excavator.png`
                          } alt="" />
                        </div>`,
              }}
            />
            <ZoomControl options={{ position: { right: 10, top: 20 } }} />
          </Map>
        </YMaps>
      </div>
    </Wrapper>
  );
};

export default memo(MapContainer);
