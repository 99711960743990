import React, { createRef, memo, useCallback, useEffect, useRef } from "react";
import { StatusTrackWrapper } from "./styles";
import { ReactComponent as CheckIcon } from "../../assets/icons/icons-24-status-icon-check-green.svg";
import { ReactComponent as WaitIcon } from "../../assets/icons/icons-24-status-icon-waiting.svg";
import { CustomerRole } from "../../types";
import {
  WorkerOrderStatuses,
  CustomerOrderStatuses,
} from "../../constants/orderStatusCards";
import moment from "moment";

interface StatusTrackProps {
  history: any[];
  refId: string;
  orderId: string | number;
  role: CustomerRole;
  status: string | undefined;
}

const StatusTrack: React.FC<StatusTrackProps> = ({
  history,
  refId,
  orderId,
  role,
  status,
}) => {
  const scrollRef = createRef<HTMLDivElement>();
  const statuses = useRef<HTMLDivElement[]>([]);
  function addStatusToStatusesArr(element: HTMLDivElement) {
    if (!statuses.current.includes(element) && element != null) {
      statuses.current.push(element);
    }
  }
  const scrollId = `statusScrollContainer_${Math.floor(Math.random() * 99)}`;
  const scrollHandler = (
    e: React.WheelEvent<HTMLDivElement> | { deltaY: number },
  ) => {
    const scrollContainer: HTMLElement | null =
      document.getElementById(scrollId);
    if (scrollContainer) {
      scrollContainer.scrollBy(e.deltaY, 0);
    }
  };

  const renderStatuses = useCallback(() => {
    const lastStatus = history[history.length - 1];

    const statusArray =
      role === "worker" ? WorkerOrderStatuses : CustomerOrderStatuses;
    const indexOfStatus = statusArray.findIndex(
      (orderStatus) => orderStatus.type === status,
    );
    return statusArray.map((orderStatus, index) => {
      return (
        <div
          className="status"
          key={`status_${index}`}
          ref={addStatusToStatusesArr}
          id={`status_${index}`}
        >
          <div className="statusTitle">{orderStatus.trackTitle}</div>
          <div
            className={`statusIcon${
              index - indexOfStatus > 1 ? " grayed" : ""
            }`}
          >
            {indexOfStatus >= index ? <CheckIcon /> : <WaitIcon />}
          </div>
          <div className="statusDate">
            {/* {statusDate && moment(statusDate).format("DD MMMM в HH:mm")} */}
          </div>
          <div className="progressTrackContainer">
            <div
              className="progressTrack"
              style={{
                width:
                  indexOfStatus > index
                    ? "100%"
                    : indexOfStatus === index
                    ? "50%"
                    : "0%",
                borderRadius: indexOfStatus >= index ? "0" : "3px",
              }}
            ></div>
          </div>
        </div>
      );
    });
  }, [status, history]);

  useEffect(() => {
    const statusesArray =
      role === "worker" ? WorkerOrderStatuses : CustomerOrderStatuses;
    const indexOfStatus = statusesArray.findIndex(
      (orderStatus) => orderStatus.type === status,
    );
    if (indexOfStatus > 0) {
      const leftDistance = statuses.current[indexOfStatus - 1]?.offsetLeft;
      if (leftDistance) {
        scrollRef.current!.scrollTo({
          left: leftDistance,
          behavior: "smooth",
        });
      }
    }

    //indexOfStatus > 0 && scrollHandler({ deltaY: 100 * (indexOfStatus + 1) });
  }, [status]);

  return (
    <StatusTrackWrapper>
      <div
        className="scrollContainer"
        id={scrollId}
        ref={scrollRef}
        onWheel={(e) => {
          e.stopPropagation();
          scrollHandler(e);
        }}
      >
        {renderStatuses()}
      </div>
      <div className="fadeLeft"></div>
      <div className="fadeRight"></div>
    </StatusTrackWrapper>
  );
};

export default memo(StatusTrack);

