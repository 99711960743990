import React, { memo, useEffect, useState } from "react";
import {
  AddButton,
  DropdownBtn,
  DropdownItem,
  DropdownList,
  DropdownWrapper,
  TRowTitle,
} from "./styles";
import {
  Section,
  StatCard,
  StatCardList,
  CalendarWrapper,
  FilterText,
  FilterWrapper,
  TableHeader,
  TableWrapper,
  TBody,
  TData,
  TH,
  TimeItem,
  TimeList,
  TRow,
  FilterBtn,
} from "../dashboardObjects/styles";
import { ReactComponent as DropDown } from "../../assets/icons/icons-arrow-drop-down.svg";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import getDashboardOrders from "../../redux/modules/getDashboardOrders";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add.svg";
import moment from "moment";
import { thousandSeparator } from "../../utils/numbers";
import { DatePicker } from "antd";
import { removeDuplicates } from "../../utils/uiHelpers";
import getManagers from "../../redux/modules/getManagers";
import { MinusOutlined } from "@ant-design/icons";
import getDashboardObjects from "../../redux/modules/getDashboardObjects";
import EmptyDashboardTable from "../../components/emptyPage/emptyDashboardTable";
import EmptyFilter from "../../components/emptyPage/emptyFilter";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { setReturnToDashboard } from "../../redux/store/reducers/orderSlice";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";

const { RangePicker } = DatePicker;

export const Dropdown = ({
  value,
  onChange,
  list,
  placeholder,
  empty,
}: {
  value?: string[] | null;
  onChange?: any;
  list?: { label: string; value: string }[] | null;
  placeholder?: string;
  empty?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownWrapper
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
    >
      <DropdownBtn
        title={value?.join(", ") || placeholder}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          color: value && value?.length ? "black" : "rgba(149,149,149,0.8)",
        }}
        _someStyles
      >
        {value && value?.length > 0
          ? value
              .map((v) => list?.find(({ value }: any) => v === value)?.label)
              ?.join(", ")
              .slice(0, 20)
          : placeholder}
        <DropDown />
      </DropdownBtn>
      {isOpen && (
        <>
          <DropdownList>
            {list?.length === 0 && <EmptyFilter text={empty} />}
            {list?.map(({ label, value: v }, index) => (
              <DropdownItem key={index}>
                <input
                  type="checkbox"
                  checked={value?.includes(v)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      onChange([...(value || []), v]);
                    } else {
                      onChange(value?.filter((vv) => vv !== v));
                    }
                  }}
                />
                <>{label}</>
              </DropdownItem>
            ))}
          </DropdownList>
        </>
      )}
    </DropdownWrapper>
  );
};

const DashboardOrders: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [sort, setSort] = useState<string[]>(["-date"]);
  const { data: objects } = useAppSelector((state) => state.object);
  const { pathname } = useLocation();

  const [filterParams, setFilterParams] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
    customers: string[] | null;
    vehicle: string[] | null;
    staff: string[] | null;
  }>({ from: null, to: null, customers: null, vehicle: null, staff: null });

  const [dateFilter, setDateFilter] = useState<{
    from: moment.Moment | null;
    to: moment.Moment | null;
  }>({ from: null, to: null });

  const [customerFilter, setCustomerFilter] = useState<string[]>([]);
  const [vehicleFilter, setVehicleFilter] = useState<string[]>([]);
  const [staffFilter, setStaffFilter] = useState<string[]>([]);

  const user = useAppSelector((state) => state.auth.user);
  const { data, success: orderSuccess } = useAppSelector(
    (state) => state.dashboardOrders,
  );
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );
  const { data: managers } = useAppSelector((state) => state.managers);

  const sortHandle = (name: string) => {
    if (sort[0] === name) {
      setSort([`-${name}`]);
    } else {
      setSort([name]);
    }
  };

  const sortData = (): any => {
    let orderList = data?.orders.map(
      ({
        amount,
        customer,
        endDate,
        id,
        startDate,
        status,
        vehicle,
        createdAt,
      }: any) => ({
        id,
        title: vehicle?.title,
        vehicle: vehicle?.title,
        vehicleData: vehicle,
        Icon: null,
        amount,
        customer,
        startDate,
        endDate,
        status,
        createdAt,
        staffId: "",
      }),
    );

    return orderList;
  };

  useEffect(() => {
    dispatch(getVehicleCategories());
  }, []);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(getManagers(user?.customer?.refId));
      dispatch(getDashboardObjects(user?.customer?.refId));
    }
  }, [user?.customer?.refId]);

  useEffect(() => {
    if (user?.customer?.refId) {
      dispatch(
        getDashboardOrders(user?.customer?.refId, {
          filterParams,
          sort,
        }),
      );
    }
  }, [user?.customer?.refId, sort, filterParams]);

  const handleFilterDate = ({
    type,
    from,
    to,
  }: {
    type?: string | null;
    from?: moment.Moment | null;
    to?: moment.Moment | null;
  }) => {
    switch (type) {
      case "today":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().endOf("day"),
        });
        break;
      case "tomorrow":
        setDateFilter({
          from: moment().add(1, "day").startOf("day"),
          to: moment().add(1, "day").endOf("day"),
        });
        break;
      case "week":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(7, "day").endOf("day"),
        });
        break;
      case "month":
        setDateFilter({
          from: moment().startOf("day"),
          to: moment().add(30, "day").endOf("day"),
        });
        break;
      default:
        setDateFilter({
          from: from || null,
          to: to || null,
        });
        break;
    }
  };

  const handleFilter = () => {
    setFilterParams({
      ...filterParams,
      from: dateFilter?.from,
      to: dateFilter?.to,
      customers: customerFilter.length > 0 ? customerFilter : null,
      vehicle: vehicleFilter.length > 0 ? vehicleFilter : null,
      staff: staffFilter.length > 0 ? staffFilter : null,
    });
  };

  const getVehicleIcon = (categoryId: number) => {
    const icon = vehicleCategories?.find(
      (vehicle: any) => vehicle.id === categoryId,
    )?.icon;

    return icon ? icon : "assets/images/excavator.png";
  };

  const isActiveDate = (type: string): boolean => {
    switch (type) {
      case "today":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().endOf("day").diff(dateFilter.to) === 0
        );
      case "tomorrow":
        return (
          moment().add(1, "day").startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(1, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "week":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(7, "day").endOf("day").diff(dateFilter.to) === 0
        );
      case "month":
        return (
          moment().startOf("day").diff(dateFilter.from) === 0 &&
          moment().add(30, "day").endOf("day").diff(dateFilter.to) === 0
        );
      default:
        return false;
    }
  };

  return (
    <PageWrapper gap="0" minHeight="calc(100vh - 65px)" noWidth>
      <Section>
        <PageHeader
          button={
            <AddButton
              onClick={() => {
                dispatch(setReturnToDashboard());
                navigate("/create-order");
              }}
            >
              <PlusIcon />
              Создать заказ
            </AddButton>
          }
        >
          Заказы
        </PageHeader>
        <StatCardList>
          <StatCard>
            <div className="number">{data?.headers?.inSearch || 0}</div>
            <div className="text">
              Заказов <br /> в поиске
            </div>
          </StatCard>
          <StatCard>
            <div className="number">{data?.headers?.inWork || 0}</div>
            <div className="text">
              Заказов <br />в работе
            </div>
          </StatCard>
          <StatCard>
            <div className="number">{data?.headers?.startToday || 0}</div>
            <div className="text">
              Заказов начинаются <br /> сегодня
            </div>
          </StatCard>
          <StatCard className="amountInWork">
            <div className="number">{data?.headers?.finishToday || 0}</div>
            <div className="text">
              Заказов завершаются <br /> сегодня
            </div>
          </StatCard>
        </StatCardList>
        <FilterWrapper>
          <FilterText>Фильтр</FilterText>
          <TimeList>
            <TimeItem
              active={isActiveDate("today")}
              onClick={() => handleFilterDate({ type: "today" })}
            >
              Сегодня
            </TimeItem>
            <TimeItem
              active={isActiveDate("tomorrow")}
              onClick={() => handleFilterDate({ type: "tomorrow" })}
            >
              Завтра
            </TimeItem>
            <TimeItem
              active={isActiveDate("week")}
              onClick={() => handleFilterDate({ type: "week" })}
            >
              Неделя
            </TimeItem>
            <TimeItem
              active={isActiveDate("month")}
              onClick={() => handleFilterDate({ type: "month" })}
            >
              Месяц
            </TimeItem>
          </TimeList>
          <CalendarWrapper>
            <RangePicker
              separator={<MinusOutlined />}
              format={"DD/MM/YYYY"}
              onChange={(e: any) => {
                const [from, to] = e;
                setDateFilter({
                  from: from.startOf("day"),
                  to: to.endOf("day"),
                });
              }}
              value={[dateFilter?.from, dateFilter?.to]}
            />
          </CalendarWrapper>
          <FilterBtn onClick={() => handleFilter()} _bgColor>
            Показать
          </FilterBtn>
        </FilterWrapper>
        <FilterWrapper>
          <TimeList>
            <Dropdown
              placeholder="Все объекты"
              list={removeDuplicates(
                objects?.objects?.map(({ id, title }: any) => ({
                  label: title,
                  value: id,
                })),
              )}
              value={customerFilter || null}
              onChange={setCustomerFilter}
            />
            <Dropdown
              placeholder="Все сотрудники"
              list={removeDuplicates(
                managers?.data?.map(
                  ({ id, name }: { id: number | string; name: string }) => ({
                    label: name,
                    value: id,
                  }),
                ),
              )}
              value={staffFilter || null}
              onChange={setStaffFilter}
            />
            <Dropdown
              placeholder="Вся спецтехника"
              list={removeDuplicates(
                // orderList
                data?.orders?.map(({ vehicle }: any) => ({
                  value: vehicle?.id,
                  label: vehicle?.title,
                })),
              )}
              value={vehicleFilter || null}
              onChange={setVehicleFilter}
            />
          </TimeList>
        </FilterWrapper>
        <TableWrapper>
          <TableHeader>
            <TH>Номер</TH>
            <TH
              onClick={() => {
                sortHandle("vehicle");
              }}
              asc={sort.includes("vehicle")}
              desc={sort.includes("-vehicle")}
            >
              <div className="text">Техника</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("cost");
              }}
              asc={sort.includes("cost")}
              desc={sort.includes("-cost")}
            >
              <div className="text">Стоимость</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("customer");
              }}
              asc={sort.includes("customer")}
              desc={sort.includes("-customer")}
            >
              <div className="text">Заказчик</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("date");
              }}
              asc={sort.includes("date")}
              desc={sort.includes("-date")}
            >
              <div className="text">Сроки работ</div>
              <DropDown />
            </TH>
            <TH
              onClick={() => {
                sortHandle("status");
              }}
              asc={sort.includes("status")}
              desc={sort.includes("-status")}
            >
              <div className="text">Статус</div>
              <DropDown />
            </TH>
          </TableHeader>
          <TBody>
            {orderSuccess && sortData()?.length === 0 && (
              <EmptyDashboardTable text="Мы не смогли найти заказы" />
            )}
            {sortData()?.map(
              (
                {
                  id,
                  title,
                  vehicle,
                  vehicleData,
                  Icon,
                  amount,
                  customer,
                  startDate,
                  endDate,
                  status,
                  createdAt,
                }: any,
                index: number,
              ) => (
                <TRow
                  key={id}
                  onClick={() => navigate(`/dashboard/order/${id}`)}
                >
                  <TRowTitle>
                    <img
                      src={`https://dev.rukki.pro/${getVehicleIcon(
                        vehicleData?.id,
                      )}`}
                      alt="icon"
                    />
                    {title}
                  </TRowTitle>
                  <TData>
                    #{id}
                    {/* <br />{" "}
                    {moment(createdAt).format("LL")?.replace("г.", "")} */}
                  </TData>
                  <TData>{vehicle}</TData>
                  <TData>
                    {amount
                      ? `${thousandSeparator(amount)} ₽`
                      : "Цена обсуждается"}
                  </TData>
                  <TData>{customer}</TData>
                  <TData>
                    от {moment(startDate).format("DD.MM.YYYY")}
                    {endDate ? (
                      <>
                        <br />
                        до {moment(endDate).format("DD.MM.YYYY")}
                      </>
                    ) : (
                      ""
                    )}
                  </TData>
                  <TData>
                    {status === "InProgress"
                      ? "Идут работы"
                      : status === "Finished"
                      ? "Завершено"
                      : status === "WorkerSetOut"
                      ? "В работе"
                      : status === "WorkerSelected"
                      ? "Исполнитель выбран"
                      : status === "Published"
                      ? "Опубликовано"
                      : status === "Done"
                      ? "Завершен"
                      : status}
                  </TData>
                </TRow>
              ),
            )}
          </TBody>
        </TableWrapper>
      </Section>
      <Section noPadding={pathname === "/dashboard/order"}>
        <Outlet />
      </Section>
    </PageWrapper>
  );
};

export default memo(DashboardOrders);
