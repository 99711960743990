import styled from "styled-components";

export const PopUpWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  z-index: 1050;
  /* position: relative;

  .background {
    background: #f7f4f1;
    width: 100%;
    height: 169px;
    position: absolute;
    bottom: 0;
    left: 0;
  } */
`;

export const PopUpBackground = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.55);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const PopUpHiddenPart = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 100px;
  margin: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 90%;

  @media screen and (max-width: 620px) {
    padding: 90px 0;
  }

  @media only screen and (max-width: 400px) {
    width: 90%;
  }
`;

export const PopUpBox = styled.div<{
  middle?: boolean;
  big?: boolean;
  paddingless?: boolean;
  bigger?: boolean;
  proposal?: boolean;
}>`
  width: ${({ middle, big, bigger, proposal }) =>
    bigger
      ? "962px"
      : big
      ? "600px"
      : middle
      ? "480px"
      : proposal
      ? "560px"
      : "360px"};
  height: fit-content;
  max-height: 90vh;
  overflow: auto;
  background-color: white;
  white-space: pre-line;
  padding: ${({ paddingless }) => (!paddingless ? "24px" : "")};
  border-radius: 20px;
  position: relative;
  .button {
    margin-top: 20px;
    padding: 19px 21px 21px;
  }
  .label {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    color: #000;
    margin-bottom: 8px;
  }

  .budget {
    margin-top: 25px;
  }

  @media only screen and (max-width: 1000px) {
    ${({ bigger }) => (bigger ? "width:640px;" : "")}
  }

  @media only screen and (max-width: 650px) {
    width: ${({ middle, big }: { middle?: boolean; big?: boolean }) =>
      big ? "100%" : middle ? "480px" : "360px"};
  }

  @media only screen and (max-width: 530px) {
    width: ${({ middle, big }: { middle?: boolean; big?: boolean }) =>
      big ? "100%" : middle ? "100%" : "360px"};
    padding: 16px;
  }

  @media only screen and (max-width: 400px) {
    width: 100%;
    /* height: 90%; */
  }

  @media screen and (max-width: 320px) {
    padding: 8px;
  }
`;

export const Header = styled.div<{ proposal?: boolean; mb?: any }>`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb }) => (mb ? mb : "24px")};
  justify-content: space-between;
  .title {
    width: calc(100% - 65px);
    font-weight: 400;
    font-size: ${({ proposal }) => (proposal ? "24px" : "16px")};
    line-height: ${({ proposal }) => (proposal ? "24px" : "16px")};
    white-space: pre-wrap;
    color: #000000;
    letter-spacing: -0.1px;
  }
  .close {
    // width: 60px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    cursor: pointer;
    span {
      color: rgba(0, 0, 0, 0.4);
    }
  }
`;
