import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import pattern from "../../assets/images/pattern.png";
import {
  ButtonsWrapper,
  CounterOfferMsgBox,
  FilesWrapper,
  HeaderPart,
  InputMsgPart,
  MessageBox,
  MessagesPart,
  MsgTextBox,
  NamePart,
  SettingBox,
  SettingList,
  TaskPart,
  Textarea,
  Typing,
} from "./styles";
import { ReactComponent as PhoneOut } from "../../assets/icons/phone-out.svg";
import { ReactComponent as ThreeDots } from "../../assets/icons/three-dots.svg";
import { ReactComponent as Excavator } from "../../assets/icons/excavator.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow_downward_black_24dp.svg";
import { ReactComponent as Attach } from "../../assets/icons/attach.svg";
import { ReactComponent as SentIcon } from "../../assets/icons/done_black_18dp.svg";
import { ReactComponent as ReadIcon } from "../../assets/icons/done_all_black_18dp.svg";
import { ReactComponent as X } from "../../assets/icons/x.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import getChat from "../../redux/modules/getChat";
import moment from "moment";
import { thousandSeparator } from "../../utils/numbers";
import CounterOfferPopUp from "../popUp/counterOfferPopUp";
import Button from "../../UI/Button";
import {
  Chat,
  Message,
  SetMessageFileState,
  SetMessageFilesDispatch,
} from "../../pages/messagesPage";
import { Socket } from "socket.io-client";
import FilePreview from "../../UI/FilePreview";
import UploadPopUp from "../popUp/uploadPopUp";
import * as MagicBytes from "magic-bytes.js";
import { IUserView } from "../../types";
import ChatMembersPopup from "./chatMembersPopup";
import ProposalPopUp from "../popUp/ProposalPopUp";
import {
  acceptCounterOffer,
  createCounterOffer,
  declineCounterOffer,
  getCounterOffers,
} from "../../redux/store/reducers/orderSlice";
import CounterOfferPopup from "./counterOfferPopup";
import { CounterOffer } from "../../types/orderSlice.types";
import { getOrderOffers } from "../../redux/store/reducers/orderSlice";
import BetaMarker from "../../UI/BetaMarker";
import MessageFile from "./messageFile";

interface Props {
  socket: Socket;
  messagesState: Map<number, Message[]>;
  chatId: number;
  chat: Chat | null;
  setChatId: React.Dispatch<React.SetStateAction<number | null>>;
  messageFiles: SetMessageFileState[];
  setMessageFiles: SetMessageFilesDispatch;
  getFileContent: (messageId: number) => void;
  chatMemberToDisplay: IUserView | null;
  chatMembers: IUserView[];
}

const MessagesList: React.FC<Props> = ({
  socket,
  messagesState,
  chatId,
  setChatId,
  messageFiles,
  setMessageFiles,
  chat,
  getFileContent,
  chatMemberToDisplay,
  chatMembers,
}) => {
  const navigate = useNavigate();
  const messages =
    messagesState !== null ? messagesState.get(chatId ?? 0) ?? [] : [];
  const [settingOpen, setSettingOpen] = useState(false);
  const [chatData, setChatData] = useState<any>();
  const [msgText, setValue] = useState<string>("");
  const [isTyping, setIsTyping] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [filesBinaries, setFilesBinaries] = useState<Uint8Array[]>([]);
  const [showSCrollBottom, setShowScrollBottom] = useState<boolean>(false);
  const [uploadFilesPopup, setUploadFilesPopup] = useState<boolean>(false);
  const [membersPopup, setMembersPopup] = useState<boolean>(false);
  const [counterOfferPopup, setCounterOfferPopup] = useState<boolean>(false);
  const [counterOffer, setCounterOffer] = useState<CounterOffer | null>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const dispatch = useAppDispatch();

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const sendMessage = async () => {
    const text = msgText?.trim();

    if (text || filesBinaries) {
      console.log(filesBinaries);
      const message = {
        text,
        chat: chatId,
        frontend_id:
          messages !== null && messages.length > 0
            ? messages[messages.length - 1].id + 1
            : 1,
        file: filesBinaries,
      };
      socket.emit("sendText", message);
      setFilesBinaries([]);
      setFiles([]);
      //setChatId(chatId);
      setValue("");
    }
  };

  const renderFile = (
    messageFile: SetMessageFileState | undefined,
    fileName: string,
  ) => {
    const fileExtension =
      fileName && fileName.includes(".") ? fileName.split(".")[1] : null;
    return (
      <MessageFile
        messageFile={messageFile}
        fileExtension={fileExtension}
        fileName={fileName}
      />
    );
  };

  const getMessageClassname = (fileType: string | null) => {
    if (fileType) {
      if (
        fileType.includes("audio") ||
        fileType.includes("mp3") ||
        fileType.includes("wav")
      ) {
        return "audio";
      }
    }
    return "";
  };

  const renderMessages = useCallback(
    (messages: Message[]) => {
      return messages.map((message, index) => {
        const messageFile = messageFiles.find(
          (messageFile) => messageFile.id === message.id,
        );

        if (message.is_file) {
          if (messageFile === undefined) {
            getFileContent(message.id);
          }
        }
        return (
          <MessageBox
            key={`messageBox_${index}`}
            className={`${
              user?.customer.id === message.sender_id ? "userMessage " : ""
            }${
              message.is_file
                ? getMessageClassname(
                    messageFile && messageFile.type
                      ? messageFile.type
                      : message.file_object_name,
                  )
                : ""
            }`}
          >
            {message.text}
            {!!message.is_file &&
              renderFile(messageFile, message.file_object_name as string)}
            <div
              className="timeBlock"
              title={`${moment(message.created_at).format(
                "DD MMMM YYYY, HH:mm",
              )}`}
            >
              {moment(message.created_at).format("H:mm")}
              {message.is_read ? <ReadIcon /> : <SentIcon />}
            </div>
          </MessageBox>
        );
      });
    },
    [messages, messageFiles],
  );

  useEffect(() => {
    scrollToBottom();
  }, [chatId]);

  useEffect(() => {
    if (messagesContainerRef?.current) {
      if (
        messagesContainerRef.current.scrollHeight -
          messagesContainerRef.current.offsetHeight -
          messagesContainerRef?.current?.scrollTop <
        205
      ) {
        scrollToBottom();
      }
    }
  }, [messages]);

  const renderAttachedFiles = () => {
    return files?.map((file, index: number) => {
      const fileUrl = URL.createObjectURL(file);
      const fileExtension = file.name.substring(file.name.lastIndexOf(".") + 1);
      return (
        <FilePreview
          key={`filePreview_${index}`}
          src={fileUrl}
          onClick={() => {
            setFiles((prev) => prev.filter((file, i) => index !== i));
          }}
          fileType={file.type}
          fileExtension={fileExtension}
        />
      );
    });
  };

  const handleAddChatMember = (memberId: number) => {
    if (chatId) {
      socket?.emit(
        "addChatMember",
        {
          secondMemberId: memberId,
          chatId: chatId,
        },
        () => {
          socket.emit("collectChatMembers", { chatId: chatId });
        },
      );
    }
  };

  const handleRemoveChatMember = (memberId: number) => {
    if (chatId) {
      socket?.emit(
        "removeChatMember",
        {
          secondMemberId: memberId,
          chatId: chatId,
        },
        () => {
          socket.emit("collectChatMembers", { chatId: chatId });
        },
      );
    }
  };

  const getMembersString = () => {
    let length: number = chatMembers.length;
    if (length >= 20) {
      length = Number(String(length).charAt(String(length).length - 1));
    }
    if (length === 1) {
      return "1 участник";
    }
    if (length > 1 && length < 5) {
      return `${length} участника`;
    }
    if (length > 1 && length < 5) {
      return `${length} участника`;
    }
    if (length >= 5 && length < 20) {
      return `${length} участников`;
    }
    return "0 участников";
  };

  const handleAcceptCounterOffer = async (counterofferId: number) => {
    if (user?.customer.refId) {
      dispatch(
        acceptCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );
    }
  };

  const handleDeclineCounterOffer = async (counterofferId: number) => {
    if (user?.customer.refId) {
      dispatch(
        declineCounterOffer({
          refId: user.customer.refId,
          counterofferId,
        }),
      );
    }
  };

  const handleCounterOffer = async (counterOffer: {
    budget: string;
    description: string;
    vehicleId: number | null;
    paymentType: string;
    startDate: string;
    endDate: string | null;
  }) => {
    if (chat && chatMemberToDisplay) {
      const orderOffers = await dispatch(
        getOrderOffers({
          refId: user?.customer.refId!,
          orderId: chat.order_id,
        }),
      );
      const offer = orderOffers.payload.find(
        (offer: any) => offer.workerId === chatMemberToDisplay.id,
      );

      dispatch(
        createCounterOffer({
          refId: user?.customer.refId!,
          orderId: chat?.order_id!,
          counterOffer: {
            ...counterOffer,
            //vehicleId: vehicle.id,
            offer_id: offer.id,
          },
        }),
      );
    }
  };

  useEffect(() => {
    if (chat?.order_id && user?.customer.refId && chatMemberToDisplay) {
      dispatch(
        getCounterOffers({
          refId: user?.customer.refId!,
          orderId: chat.order_id,
        }),
      ).then((response) => {
        const offers: CounterOffer[] = response.payload;
        const counterOffer = offers.reverse().find((offer) => {
          if (offer.offer_id) {
            return offer.offer_id.workerId === chatMemberToDisplay?.id;
          }
        });
        counterOffer && setCounterOffer(counterOffer);
      });
    }
  }, [chat, chatMemberToDisplay, user]);

  return chatId ? (
    <>
      <HeaderPart>
        <NamePart>
          <div className="go-back" onClick={() => setChatId(null)}>
            <ArrowLeft />
          </div>
          <div className="full-name">
            {chatMemberToDisplay
              ? `${chatMemberToDisplay.firstName} ${chatMemberToDisplay.secondName}`
              : chat?.title}

            <div
              className="membersCount"
              onClick={() => setMembersPopup(true)}
            >{`Заказ №${chat?.order_id} | ${getMembersString()}`}</div>
          </div>
          <button
            className="setting-icon"
            onFocus={() => setSettingOpen(true)}
            onBlur={() => setSettingOpen(false)}
          >
            <ThreeDots />
            <SettingBox open={settingOpen}>
              {chat?.order_id && (
                <div
                  className="settingBoxItem"
                  onClick={() => {
                    navigate(`/o/${chat.order_id}`);
                  }}
                >
                  Перейти к заказу
                </div>
              )}
              <div
                className="settingBoxItem"
                onClick={() => {
                  setCounterOfferPopup(true);
                  setSettingOpen(false);
                }}
              >
                Встречное предложение
                <BetaMarker />
              </div>
              <div className="settingBoxItem">
                Позвонить собеседнику <BetaMarker />
              </div>
              <div className="settingBoxItem">
                Позвонить в службу поддержки
                <BetaMarker />
              </div>
              <div className="settingBoxItem">
                Скрыть чат
                <BetaMarker />
              </div>
            </SettingBox>
          </button>
        </NamePart>
      </HeaderPart>
      <MessagesPart className="msg-box-wrapper">
        <div
          className="wrapper"
          onScroll={() => {
            if (messagesContainerRef?.current) {
              if (
                messagesContainerRef.current.scrollHeight -
                  messagesContainerRef.current.offsetHeight -
                  messagesContainerRef?.current?.scrollTop >
                150
              ) {
                setShowScrollBottom(true);
              } else {
                setShowScrollBottom(false);
              }
            }
          }}
          ref={messagesContainerRef}
        >
          {renderMessages(messages)}
        </div>
        <div
          className={`scrollToBottom ${showSCrollBottom ? "open" : "close"}`}
          onClick={scrollToBottom}
        >
          <ArrowDown />
        </div>
      </MessagesPart>
      {isTyping && (
        <Typing className="typing">
          {chatData?.customer?.firstName} набирает сообщение . . .
        </Typing>
      )}
      <InputMsgPart>
        <div className={`topPart ${!!files.length ? "active" : ""}`}>
          {files?.length > 0 ? renderAttachedFiles() : null}
        </div>
        <div className="bottomPart">
          <Textarea
            minRows={1}
            maxRows={6}
            placeholder="Сообщение"
            value={msgText}
            onChange={(e) => {
              const msg = e.target.value;
              setValue(msg);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                sendMessage();
              }
            }}
          />
          <div className="buttonsContainer">
            <Attach
              className="attachIcon"
              onClick={() => setUploadFilesPopup(true)}
            />
            <SendOutlined onClick={sendMessage} className="sentIcon" />
          </div>
        </div>
      </InputMsgPart>
      {uploadFilesPopup && (
        <UploadPopUp
          onSubmit={(files, binaries) => {
            setFiles(files);
            setFilesBinaries(binaries);
            console.log("submit", binaries);
          }}
          close={() => setUploadFilesPopup(false)}
          files={files}
        />
      )}
      <ChatMembersPopup
        isOpen={membersPopup}
        members={chatMembers}
        close={() => setMembersPopup(false)}
        handleAddChatMember={handleAddChatMember}
        handleRemoveChatMember={handleRemoveChatMember}
      />

      <CounterOfferPopup
        handleAccept={handleAcceptCounterOffer}
        close={() => setCounterOfferPopup(false)}
        isOpen={counterOfferPopup}
        handleDecline={handleDeclineCounterOffer}
        counterOffer={counterOffer}
        handleCreateOffer={handleCounterOffer}
      />
    </>
  ) : null;
};

export default MessagesList;

