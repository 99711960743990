import { CompanyAccessSettings } from "./companyAccessSettings.types";
import { IVehicle } from "./vehicle.types";

export enum CustomerRole {
  Worker = "worker",
  Customer = "customer",
}

export enum CustomerCompanyRole {}

export enum VerificationStatus {
  Confirmed = "confirmed",
  NotConfirmed = "not-confirmed",
  Pending = "pending",
}

export interface IUserViewFeedback {
  id: number;
  description: string;
  rating: number;
  createdAt: Date;
  createdBy: {
    id: number;
    firstName: string | null;
    secondName: string | null;
    patronymic: string | null;
  };
}

export interface IUserRequisites {
  id: 1510;
  companyName: string;
  userId: number | null;
  inn: string;
  address: string | null;
  actualAddress: string | null;
  bik: string | null;
  ks: string | null;
  rs: string | null;
  gendir: string | null;
  jobTitle: string | null;
  okpo: number | null;
  phoneNumber: string | null;
  fio: string | null;
  email: string | null;
  kpp: string | null;
  pan: string | null;
  card_encrypted: string | null;
  ogrn: string | null;
  bankName: string | null;
}

interface IUserNotificationsSettings {
  telegram: boolean;
  sms: boolean;
}

export interface ICustomer {
  refId: string;
  id: number;
  firstName: string | null;
  secondName: string | null;
  patronymic: string | null;
  checked?: null | number;
  role: CustomerRole;
  companyRole?: string;
  verificationStatus?: VerificationStatus | null;
  accessSettings: CompanyAccessSettings;
  rejectPassportReason?: string | null;
  phoneNumber: string;
  email: string | null;
  city: string | null;
  birthday: string | null;
  createdAt: Date;
  updatedAt: Date;
  rating: string;
  avatarUrl: string | null;
  companyId?: number;
  country?: string | null;
  settings?: string;
  telegramId?: number;
  notifications_settings?: IUserNotificationsSettings;
}

export interface IUserView {
  id: number;
  firstName: string | null;
  secondName: string | null;
  patronymic: string | null;
  avatarUrl: string | null;
  role: CustomerRole;
  rating: string;
  verificationStatus?: VerificationStatus | null;
  companyId?: number;
  feedbacks: IUserViewFeedback[];
  company?: {
    id: number;
    requisitesId: number;
    title: string;
  };
  requisites: IUserRequisites[];
  vehicles: IVehicle[];
}

