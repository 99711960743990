import styled from "styled-components";
import { Button } from "../form/styles";
import { styles } from "../styles/styles";

export const PageWrapper = styled.div<{
  footer?: number | null;
  navbar?: number | null;
  keepContainer?: boolean;
  noHeight?: boolean;
}>`
  width: 100%;
  box-sizing: border-box;
  ${({ noHeight }) => !noHeight && "height: calc(100dvh - 137px)"};

  @media screen and (max-width: 768px) {
    ${({ noHeight }) => !noHeight && "height: calc(100dvh - 64px)"};
  }
`;

export const TitlePart = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding-top: 32px;
  justify-content: space-between;
  ${({
    _mb20_p0,
    _w500,
    center,
  }: {
    _mb20_p0?: boolean;
    _w500?: boolean;
    center?: boolean;
  }) => _mb20_p0 && "margin: 0 0 20px; padding: 0;"}
  ${({ _w500 }) => _w500 && "width: 500px;"}
  ${({ center }) => center && "width: 100%; max-width: 1392px;"}

  .titleGroup {
    display: flex;
  }

  .switch-mode {
    margin: 0 0 0 20px;
    padding: 5px 20px;
  }

  .containerAddManager {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    svg {
      display: flex;
      height: fit-content;
    }
  }

  .containerAddManager > div {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-left: 24px;
    cursor: pointer;
  }

  .containerAddManager > div p {
    margin-left: 7px;
  }

  .uploadIcon {
    width: 24px;
  }

  @media only screen and (min-width: 1440px) {
    padding-top: 24px;
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 1050px) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 720px) {
    display: none;
  }
`;

export const AddButton = styled.div`
  width: 100%;
  height: 32px;
  padding: 4px 10px 4px 8px;
  border-radius: 10px;
  background-color: #f63;
  display: flex;
  align-items: center;
  gap: 4px;
  max-width: fit-content;
  cursor: pointer;

  div {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: -0.25px;
    color: #fdfdfd;
    word-wrap: no-wrap;
  }
`;

export const GoBack = styled.div`
  background: #eee7e1;
  border-radius: 18px;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  padding: 9px 21px;
  margin-right: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  svg {
    margin-right: 10px;
  }
`;

export const Btn = styled(Button)`
  padding: 6px 21px;
  margin: 0;
  margin-left: auto;
`;

export const Body = styled.div<{ center?: boolean; noHeight?: boolean }>`
  display: flex;
  ${({ noHeight }) => !noHeight && "height: 100%"};
  width: 100%;
`;

export const SwitchToAgentBtn = styled.div`
  padding: 8px 27px;
  margin-left: auto;
  background-color: ${styles.mainColor};
  color: white;
  border-radius: 10px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
`;
