import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: 984px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;

  @media screen and (max-width: 480px) {
    gap: 0;
  }
`;

export const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 23px 0 34px;
  .number {
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -0.56px;
    color: #000;
    margin-right: 8px;
  }
  .stars-number-wrapper {
    .stars-wrapper {
      margin-bottom: 2px;
    }
    .rating-number {
      font-size: 12px;
      line-height: 1.33;
      color: rgba(0, 0, 0, 0.54);
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }
`;

export const FeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const FeedbackTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000;
`;

export const SingleFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

export const SFHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .data-part {
    .full-name {
      font-size: 16px;
      line-height: 1.25;
      margin-bottom: 4px;
    }
    .rating {
      display: flex;
      align-items: center;
      .stars {
        margin-right: 8px;
        display: flex;
        gap: 2px;
        svg {
          width: 12px;
          height: 12px;
          & g path:last-child {
            fill: #878787;
            opacity: 1;
          }
          &.in-active g path:last-child {
            fill: #cfcfcf;
            opacity: 1;
          }
        }
      }
      .r-text {
        opacity: 0.5;
        font-size: 12px;
        line-height: 1;
        color: #222222;
      }
    }
  }
  .three-dots {
    width: fit-content;
    height: 16px;
    padding: 0 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;
    cursor: pointer;
    .dot {
      width: 4px;
      height: 4px;
      background-color: #757575;
      border-radius: 50%;
    }
  }
`;

export const SFText = styled.div``;
