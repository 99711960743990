import React, { useEffect, useState } from "react";
import {
  ArrowRightPart,
  BarBoxWrapper,
  CheckboxItem,
  CheckboxWrapper,
  CounterBox,
  CreateOrderBox,
  CreateOrderFormBox,
  CreateOrderIntro,
  CreateOrderWrapper,
  Dropdown,
  DropdownBody,
  DropdownBodyWrapper,
  DropdownItem,
  DropdownTitle,
  ExplanationMark,
  FilterBtn,
  IconPart,
  InputOverlay,
  InputPart,
  InputWrapper,
  IntroContainer,
  ScrollWrapper,
  SelectWrapper,
  SettingsButton,
  YandexMapWrapper,
} from "./styles";
import Button from "../../UI/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as MapPin } from "../../assets/icons/map-pin-location.svg";
import { ReactComponent as MapPinStart } from "../../assets/icons/24-maps-pin-start.svg";
import { ReactComponent as Clock } from "../../assets/icons/clock.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as LeftArrow } from "../../assets/icons/icons-chevron-left.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/icons-chevron-right.svg";
import { ReactComponent as PhotoIcon } from "../../assets/icons/icons-photo.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/icons-information.svg";
import { ReactComponent as CalendarIcon } from "../../assets/icons/icons-calendar.svg";
import { ReactComponent as WalletIcon } from "../../assets/icons/icons-wallet.svg";
import { ReactComponent as FilterIcon } from "../../assets/icons/filter.svg";
import { ReactComponent as OptionIcon } from "../../assets/icons/options.svg";
// import { ReactComponent as Wallet } from "../../assets/icons/options.svg";
import {
  clearReturnToDashboard,
  getVehicleParams,
} from "../../redux/store/reducers/orderSlice";
import InputDataset from "../../components/locationInputDataset";
import { InputDatasetBackground } from "../../components/locationInputDataset/styles";
import { emptyTagResult } from "../../constants/emptyTagResult";
import { useSelector } from "react-redux";
import { getAddressesAsync } from "../../redux/modules/getAddresses";
import TimePopUp from "../../components/popUp/timePopUp";
import moment from "moment";
import DescriptionPopUp from "../../components/popUp/descriptionPopUp";
import PhotoVideoPopUp from "../../components/popUp/photoVideoPopUp";
import { Map, Placemark, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { styles } from "../../components/styles/styles";
import useHttpRequest from "../../hooks/useHttpRequest";
import {
  ENDPOINT,
  ENDPOINT_FOR_IMG,
  GEOCODE_MAPS,
} from "../../constants/general";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import { httpRequest } from "../../utils/httpRequest";
import searchByTag from "../../redux/modules/searchByTag";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import CharacteristicsPopUp from "../../components/popUp/characteristicsPopUp";
import PaymentMethodPopUp from "../../components/popUp/paymentMethodPopUp";
import PaymentAmountPopUp from "../../components/popUp/paymentAmountPopUp";
import ConnectionPopUp from "../../components/popUp/connectionPopUp";
import PaymentPopUp from "../../components/popUp/paymentPopUp";
import placemark from "../../assets/icons/icon-position-icon-bold.svg";
import NumberFormat from "react-number-format";
import { DatePicker } from "antd";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { ReactComponent as QuestionIcon } from "../../assets/icons/icons-vehicles-unknown.svg";
import SearchVehiclePopUp from "../../components/popUp/searchVehiclePopUp";
import { clearAddressString } from "../../utils/clearAddressString";
import PageWrapper from "../../UI/PageWrapper";
import { getPosition } from "../../redux/modules/getPosition";
import { getAddressesString } from "../../utils/getAddressString";
import { authActions } from "../../redux/store/reducers/authSlice";
import { GeolocationButton } from "../SearchOrderPage/styles";

export const InputBox = ({
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  icon,
  onClick,
  address,
  onRightClick,
  disabled = true,
  error,
}: any) => {
  return (
    <InputWrapper
      active={type === "date" || (type !== "role" && value?.length)}
      style={{ width: width ? width : "100%" }}
      className={
        type === "date" || (type !== "role" && value?.length) ? "active" : ""
      }
      onClick={onClick}
      error={error}
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
        </SelectWrapper>
      ) : (
        <>
          <IconPart
            onClick={() => {
              if (address) {
                onClick && onClick();
              }
            }}
          >
            {icon === QuestionIcon ? (
              <QuestionIcon />
            ) : icon.length ? (
              <img src={icon} alt="" width="24px" height="24px" />
            ) : (
              icon
            )}
          </IconPart>
          <InputPart
            onClick={() => {
              onClick && onClick();
            }}
          >
            <InputOverlay onClick={onClick} />
            <div onClick={onClick}>
              {type === "inn" ? (
                <NumberFormat
                  format="### ### ######"
                  name={name}
                  value={value || ""}
                  type={type || "text"}
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                  spellCheck={false}
                  onFocus={onClick}
                />
              ) : type === "tel" ? (
                <NumberFormat
                  name={name}
                  value={value || ""}
                  prefix={"+"}
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                  spellCheck={false}
                  onFocus={onClick}
                />
              ) : type === "date" ? (
                <div className="date-input" onClick={onClick}>
                  <DatePicker
                    format={"ll"}
                    value={value ? moment(value) : null}
                    onChange={(e) => {
                      onChange({
                        target: {
                          value: e?.format("YYYY-MM-DD"),
                          name,
                        },
                      });
                    }}
                    disabled={disabled}
                    onFocus={onClick}
                  />
                </div>
              ) : (
                <input
                  name={name}
                  value={value || ""}
                  type={type || "text"}
                  placeholder={placeholder}
                  onChange={onChange}
                  disabled={disabled}
                  spellCheck={false}
                  onFocus={onClick}
                  onClick={() => {
                    onClick && onClick();
                  }}
                />
              )}
            </div>
          </InputPart>
          <ArrowRightPart
            address={address}
            onClick={(e) => {
              address && e.stopPropagation();
              address && onRightClick();
            }}
          >
            {address ? <MapPin /> : <ArrowRight />}
          </ArrowRightPart>
        </>
      )}
    </InputWrapper>
  );
};

const CreateOrderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const state: any = useSelector((state) => state);
  const addresses = state.addresses;
  const { width } = useWindowDimensions();
  const [searchParams, setSearchParams] = useSearchParams();
  const [address, setAddress] = useState<any>({});
  const [getLocationFor, setGetLocationFor] = useState("");
  const [task, setTask] = useState<any>({});
  const [dateTime, setDateTime] = useState<any>({});
  const [payment, setPayment] = useState<any>({});
  const [description, setDescription] = useState<string>("");
  const [characteristics, setCharacteristics] = useState<any>([]);
  const [chosenCharacteristics, setChosenCharacteristics] = useState<any>(null);
  const [photoVideo, setPhotoVideo] = useState<any>([]);
  const [connection, setConnection] = useState<any>({});
  const [addressOpen, setAddressOpen] = useState<boolean>(false);
  const [addressOpen2, setAddressOpen2] = useState<boolean>(false);
  const [taskOpen, setTaskOpen] = useState<boolean>(false);
  const [dateTimeOpen, setDateTimeOpen] = useState<boolean>(false);
  const [paymentOpen, setPaymentOpen] = useState<boolean>(false);
  const [paymentMethodOpen, setPaymentMethodOpen] = useState<boolean>(false);
  const [paymentAmountOpen, setPaymentAmountOpen] = useState<boolean>(false);
  const [descriptionOpen, setDescriptionOpen] = useState<boolean>(false);
  const [connectionOpen, setConnectionOpen] = useState<boolean>(false);
  const [additionalOpen, setAdditionalOpen] = useState<boolean>(false);
  const [characteristicsOpen, setCharacteristicsOpen] =
    useState<boolean>(false);
  const [photoVideoOpen, setPhotoVideoOpen] = useState<boolean>(false);
  const [isFilterVisible, setIsFilterVisible] = useState<boolean>(true);
  const [vehicleInputIcon, setVehicleInputIcon] = useState<any>(QuestionIcon);

  const [center, setCenter] = useState([55.75, 37.57]);
  const [location, setLocation] = useState<any>([]);
  const [zoom, setZoom] = useState(9);

  const [hint, setHint] = useState(true);
  const [shift, setShift] = useState<number>(1);

  const [accommodation, setAccommodation] = useState(false);
  const [protection, setProtection] = useState(false);
  const [telegram, setTelegram] = useState(false);
  const [ymap, setYmap] = useState<any>(null);
  const [searchResultToRender, setSearchResultToRender] = useState<any>([]);
  const [noSearchResult, setNoSearchResult] = useState<boolean>(true);

  const [orderProcess, setOrderProcess] = useState({
    loading: false,
    success: false,
    error: false,
  });

  const searchByTagResult = useAppSelector(
    (state: RootState) => state.searchByTag,
  );

  const vehicleParams = useAppSelector(
    (state: RootState) => state.order.vehicleParams,
  );
  const vehicleCategories = useAppSelector(
    (state: RootState) => state.vehicle.vehicleCategories,
  );

  const auth = useAppSelector((state: RootState) => state.auth);
  const user = useAppSelector((state: RootState) => state?.auth?.user);
  const refId = auth?.fireBaseData?.refId;
  const returnToDashboard = useAppSelector(
    (state) => state.order.returnToDashboard,
  );

  const getAddressList = (searchInput: any) => {
    dispatch(getAddressesAsync({ text: searchInput, center: center }));
  };

  const [addressProcess, makeAddressRequest] = useHttpRequest({
    onSuccess: (res: any) => {
      const { name, description } =
        res?.data?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject;

      // dispatch(getAddressesAsync({ text: description + " " + name })).then(
      //   (response) => {
      //     const foundAddress = {
      //       coordinates: [
      //         response.payload.suggestions[0].data.geo_lat,
      //         response.payload.suggestions[0].data.geo_lon,
      //       ],
      //       // name: response.payload.suggestions[0].value,
      //       name: createAddressString({
      //         street_with_type:
      //           response.payload.suggestions[0].data.street_with_type,
      //         house: response.payload.suggestions[0].data.house,
      //       }),
      //       city: response.payload.suggestions[0].data.city,
      //     };
      //     setAddress({ ...foundAddress });
      //   }
      // );
    },
  });

  const getAddress = ([latitude, longitude]: any) => {
    if (latitude && longitude) {
      makeAddressRequest({
        method: "GET",
        url: GEOCODE_MAPS,
        params: {
          geocode: [longitude, latitude].join(","),
          lang: "ru_RU",
          apikey: "7446be07-f1bc-4ce3-a5fe-0a423e51a168",
          format: "json",
          // YANDEX_PLACE_APIKEY,
          // results: 4,
        },
      });
      ymap?.setCenter([latitude, longitude], 17, {
        duration: 1000,
        timingFunction: "linear",
      });
    }
  };

  const navigate = useNavigate();

  const getUserLocation = (force?: boolean) => {
    window?.navigator?.geolocation.getCurrentPosition(
      (position) => {
        const { longitude, latitude } = position.coords;
        setCenter([latitude, longitude]);
        setLocation([latitude, longitude]);
        const empty = {};
        if (address === empty || force) {
          mapClickHandler([latitude, longitude]);
        }
      },
      (err) => {},
    );
  };

  useEffect(() => {
    if (task && Number.isInteger(task.categoryId)) {
      const categoryId: number = Number(task.categoryId);
      dispatch(getVehicleParams({ categoryId }));
      setChosenCharacteristics(null);
    }
  }, [task?.categoryId]);

  const updateVehicleParams = async (
    cId: number,
    value: number | string | Array<string>,
  ) => {
    if (task && !isNaN(Number(task.categoryId))) {
      const categoryId: number = Number(task.categoryId);
      await dispatch(
        getVehicleParams({
          categoryId: categoryId,
          additional: [{ cId: cId, value: value }],
        }),
      );
    }
  };

  useEffect(() => {
    if (ymap) {
      const hints = localStorage.getItem("hints");
      setHint(hints !== "remove");
      dispatch(getVehicleCategories());

      const title = searchParams.get("title");
      setTask(title ? { title } : {});
      const lat = searchParams.get("lat");
      const lon = searchParams.get("lng");
      const address = searchParams.get("address");
      // if (address?.length) {
      //   getAddressList(address);
      // }
      //getAddress([lat, lon]);
      if (lat && lon) {
        mapClickHandler([lat, lon]);
      }

      const paymentType = searchParams.get("paymentType");
      const budget = searchParams.get("budget");

      if (paymentType && budget) {
        setPayment({
          paymentMethod: Array.isArray(paymentType)
            ? paymentType
            : [paymentType],
          amount: Number(budget) || null,
        });
      }
      const desc = searchParams.get("desc");
      setDescription(desc || "");
      setDateTime({
        date: moment(),
        time: moment(),
      });

      //setSearchParams({});
    }
  }, [ymap]);

  useEffect(() => {
    if (vehicleCategories.length) {
      const categoryId = searchParams.get("categoryid");

      if (categoryId && vehicleCategories.length) {
        const queryVehicle = vehicleCategories.filter(
          (vehicle) => vehicle.id === parseInt(categoryId),
        );

        if (queryVehicle) {
          setTask({
            title: queryVehicle[0]?.title,
            categoryId: queryVehicle[0]?.id,
          });
          dispatch(getVehicleParams(queryVehicle[0]?.id));
        }
      }
    }
  }, [vehicleCategories]);

  useEffect(() => {
    if (ymap && !searchParams.get("address")) {
      getUserLocation(true);
    }
  }, [ymap]);

  useEffect(() => {
    const refId = user?.customer?.refId;
    if (refId) {
      if (
        user.customer.companyId &&
        !user.customer.accessSettings.creatingOrder
      ) {
        navigate("/profile");
      }
      searchByTag(refId, "");
    }
  }, [user?.customer?.role]);

  useEffect(() => {
    if (!searchParams.get("address")) {
      getUserLocation(true);
    }
  }, []);

  const createOrder = async () => {
    setOrderProcess({
      loading: true,
      success: false,
      error: false,
    });

    const chars: any = {};
    Object.entries(characteristics).forEach(([name, value]: any) => {
      chars[name] = String(value?.value);
    });

    let timeOffset = new Date().getTimezoneOffset() / 60;
    timeOffset < 0 && (timeOffset *= -1);

    const order = {
      latitude: Number(location[0]),
      longitude: Number(location[1]),
      title: task?.title,
      categoryId: task?.categoryId || 1,
      startDate:
        dateTime?.date && dateTime?.time
          ? `${dateTime?.date?.format("YYYY-MM-DD")}T${dateTime?.time?.format(
              "HH:mm",
            )}+${
              timeOffset.toString().length > 1
                ? timeOffset.toString()
                : "0" + timeOffset.toString()
            }`
          : null, //2022-10-15T12:10:23.362Z
      description,
      paymentType: payment?.paymentMethod?.[0]?.value,
      budget: payment?.amount * shift,
      // address: address?.description,
      address: clearAddressString(address?.name),
      ofisid: 1,
      operatorid: 19,
      vehicleParams: characteristics,
      shifts: shift,
      telegram: Number(telegram),
      accommodation: Number(accommodation),
      protection: Number(protection),
      city: address?.city,
      companyId: user?.customer?.companyId,
    };

    if (user?.customer?.refId) {
      httpRequest({
        method: "POST",
        url: `${ENDPOINT}/orders/${user?.customer?.refId}`,
        data: order,
      })
        .then(async (res: any) => {
          const orderId = res?.data?.id;
          const formData = new FormData();
          photoVideo.forEach(({ file }: any) => {
            formData.append("", file);
          });

          dispatch(authActions.incrementOrdersTotal());

          setOrderProcess({
            loading: true,
            success: false,
            error: false,
          });

          if (photoVideo?.length > 0) {
            await httpRequest({
              method: "PUT",
              url: `${ENDPOINT}/orders/${user?.customer?.refId}/${orderId}/images`,
              data: formData,
            })
              .then((res) => {
                setOrderProcess({
                  loading: false,
                  success: true,
                  error: false,
                });
              })
              .catch((err) => {
                setOrderProcess({
                  loading: false,
                  success: false,
                  error: true,
                });
              });
          } else {
            setOrderProcess({
              loading: false,
              success: true,
              error: false,
            });
          }

          if (returnToDashboard) {
            navigate("/dashboard/order");
            dispatch(clearReturnToDashboard());
          } else navigate(`/o/${res?.data?.id}`);
        })
        .catch((err) => {
          setOrderProcess({
            loading: false,
            success: false,
            error: true,
          });
        });
    } else {
      localStorage.setItem("makeOrderData", JSON.stringify(order));
      localStorage.setItem("makeOrderPhotoVideo", JSON.stringify(photoVideo));
      localStorage.setItem("hideRolePicker", "true");
      setOrderProcess({
        loading: false,
        success: true,
        error: false,
      });
      setTimeout(() => {
        navigate("/login");
      }, 1500);
    }
  };

  const closeHint = (forever: boolean) => {
    setHint(false);
    if (forever) {
      localStorage.setItem("hints", "remove");
    }
  };

  const makeAddressInputEmpty = () => {
    setAddressOpen(false);
    setAddress("");
  };
  const makeAddressInputEmpty2 = () => {
    setAddressOpen2(false);
  };

  const makeTaskInputEmpty = () => {
    setTaskOpen(false);
    setTask("");
    setVehicleInputIcon(QuestionIcon);
    setSearchResultToRender(emptyTagResult);
  };

  const vehicleList = [
    "assets/images/tank_truck.png",
    "assets/images/excavator.png",
    "assets/images/bulldozer_2.png",
    "assets/images/bur.png",
  ];

  useEffect(() => {
    if (searchByTagResult?.data?.length > 0) {
      setSearchResultToRender(searchByTagResult);
      setNoSearchResult(false);
    } else {
      setSearchResultToRender(emptyTagResult);
      setNoSearchResult(true);
    }
  }, [searchByTagResult]);

  // useEffect(() => {
  //   if (ymap && zoom) {
  //     ymap?.setZoom(zoom, { duration: 500 });
  //   }
  // }, [zoom]);

  // useEffect(() => {
  //   setZoom(ymap?._zoom);
  // }, [ymap?._zoom]);

  const [titleEmpty, setTitleEmpty] = useState<boolean>(false);
  const [locationEmpty, setLocationEmpty] = useState<boolean>(false);
  const [dateEmpty, setDateEmpty] = useState<boolean>(false);
  const [paymentEmpty, setPaymentEmpty] = useState<boolean>(false);

  const checkEmptyFields = () => {
    if (!task?.title) setTitleEmpty(true);
    if (!location?.length) setLocationEmpty(true);
    if (!dateTime?.date) setDateEmpty(true);
    if (!payment?.paymentMethod?.[0]?.value) setPaymentEmpty(true);
  };

  useEffect(() => {
    if (task?.title) setTitleEmpty(false);
    if (dateTime?.date) setDateEmpty(false);
    if (payment?.paymentMethod?.[0]?.value) setPaymentEmpty(false);
    if (location?.length) setLocationEmpty(false);
  }, [dateTime, payment, task, location]);

  const mapClickHandler = (coords: [number, number] | [string, string]) => {
    dispatch(getPosition({ lat: coords[0], lon: coords[1] })).then((result) => {
      setAddress({
        name: getAddressesString({
          road: result.payload.address.road,
          house_number: result.payload.address.house_number,
        }),
        city: result.payload.address.city || result.payload.address.town,
      });
    });
    ymap?.setCenter([...coords], 17, {
      duration: 1000,
      timingFunction: "linear",
    });
  };

  return (
    <PageWrapper noWidth noFooter>
      <style>
        {`html {
            overflow: hidden !important;
            overscroll-behavior: none !important;
            touch-action: none !important;
          }`}
      </style>
      {!orderProcess.loading && !orderProcess.success && (
        // false
        <>
          <CreateOrderWrapper>
            <YandexMapWrapper>
              <YMaps>
                <Map
                  onLoad={(e) => {
                    setYmap(e);
                  }}
                  instanceRef={(e) => {
                    setYmap(e);
                  }}
                  width={"100%"}
                  height={"100%"}
                  defaultState={{ center, zoom }}
                  // state={{ center, zoom: zoom }}
                  onClick={(e: any) => {
                    const coords = e.get("coords");
                    mapClickHandler(coords);
                    setGetLocationFor("1");
                    setLocation(coords);
                    //getAddress(coords);
                  }}
                  options={{
                    maxAnimationZoomDifference: 23,
                    yandexMapDisablePoiInteractivity: false,
                  }}
                >
                  {location?.length > 0 && (
                    <Placemark
                      // preset={"islands#circleDotIcon"}
                      options={{
                        preset: task?.img
                          ? "islands#circleIcon"
                          : "islands#circleDotIcon",
                        iconColor: styles.mainColor,
                        draggable: true,
                        hideIconOnBalloonOpen: false,
                        hasBalloon: true,
                        balloonShadow: false,
                        balloonPanelMaxMapArea: 0,
                      }}
                      geometry={{
                        type: "Point",
                        coordinates: location,
                      }}
                      onDragEnd={(e: any) => {
                        const coordinates =
                          e.get("target").geometry._coordinates;
                        setLocation(coordinates);
                        getAddress(coordinates);
                      }}
                      properties={{
                        iconContent: `
                                <div class="order-placemark">   
                                  <img class="placemark" src=${placemark} alt="" />
                                  <img class="vehicle" src=${task?.img} alt="" />
                                </div>`,
                      }}
                    />
                  )}
                  <ZoomControl
                    options={{
                      position: {
                        right: 10,
                        top: width > 576 ? 20 : 80,
                      },
                    }}
                  />
                </Map>
              </YMaps>
            </YandexMapWrapper>
            {!isFilterVisible && (
              <FilterBtn onClick={() => setIsFilterVisible(true)}>
                <FilterIcon /> Фильтр
              </FilterBtn>
            )}
            <GeolocationButton onClick={() => getUserLocation(true)}>
              <MapPin />
            </GeolocationButton>
            {searchParams.get("nav") === "intro" ? (
              <CreateOrderIntro>
                <IntroContainer>
                  <div className="title">Привет! 👋</div>
                  <div className="desc">
                    Мы работаем над полноценной веб-версией нашего сервиса и
                    скоро ее запустим.
                    <br /> <br />
                    Есть и хорошие новости! Уже сейчас можно пользоваться
                    мобильным приложением для Android и экономить на аренде
                    тяжелой техники!
                  </div>
                  <Button onClick={() => setSearchParams({})}>
                    Получить доступ к бета-версии
                  </Button>
                </IntroContainer>
              </CreateOrderIntro>
            ) : (
              <CreateOrderBox>
                <CreateOrderFormBox
                  isVisible={isFilterVisible}
                  className="create-order-form-box"
                >
                  <ScrollWrapper>
                    <BarBoxWrapper>
                      <InputBox
                        onClick={() => setTaskOpen(true)}
                        setVehicleInputIcon={setVehicleInputIcon}
                        icon={vehicleInputIcon}
                        label={"Какая техника или что сделать?"}
                        placeholder={"Какая техника или что сделать?"}
                        value={task?.title}
                        width={
                          vehicleParams?.length && task?.title?.length
                            ? "calc(100% - 56px)"
                            : 0
                        }
                        error={titleEmpty}
                      />
                      {vehicleParams?.length && task?.title?.length ? (
                        <SettingsButton
                          onClick={() =>
                            task?.title && setCharacteristicsOpen(true)
                          }
                        >
                          <OptionIcon />
                          <ExplanationMark>
                            {Object.values(characteristics).filter(
                              ({ value }: any) => value,
                            ).length || "!"}
                          </ExplanationMark>
                        </SettingsButton>
                      ) : (
                        ""
                      )}
                      {taskOpen && (
                        <>
                          <SearchVehiclePopUp
                            wrapperClass="create-order-form-box"
                            setValue={(a: any) => {
                              setTask({
                                ...a.data,
                                img: a?.img,
                              });
                              setTaskOpen(false);
                              setCharacteristics([]);
                            }}
                            category
                            defaultValue={task?.title}
                            close={() => makeTaskInputEmpty()}
                            onChange={(text: any) => {
                              if (text === "") {
                                setSearchResultToRender(emptyTagResult);
                                setNoSearchResult(true);
                              } else {
                                dispatch(searchByTag(refId, text));
                                setNoSearchResult(true);
                              }
                            }}
                            noSearchResult={noSearchResult}
                            setVehicleInputIcon={setVehicleInputIcon}
                            type={"vehicle"}
                            list={searchResultToRender?.data?.map(
                              (
                                { categoryId, id, tags, title, category }: any,
                                index: number,
                              ) => ({
                                name: category?.title,
                                // description: tags,
                                img: `${ENDPOINT_FOR_IMG}/${
                                  category?.icon
                                    ? category?.icon
                                    : "assets/images/excavator.png"
                                }`,
                                data: {
                                  categoryId,
                                  id,
                                  tags,
                                  title: category?.title,
                                },
                              }),
                            )}
                            tab={() => {
                              setTaskOpen(false);
                              setDateTimeOpen(true);
                            }}
                          />
                          <InputDatasetBackground
                            onClick={() => setTaskOpen(false)}
                          />
                        </>
                      )}
                    </BarBoxWrapper>
                    <BarBoxWrapper>
                      <InputBox
                        address
                        icon={<MapPinStart />}
                        label={"Адрес выполнения работы"}
                        placeholder={"Адрес выполнения работы"}
                        onClick={() => {
                          setAddressOpen(true);
                          setGetLocationFor("1");
                        }}
                        onRightClick={() => {
                          getUserLocation(true);
                          setGetLocationFor("1");
                        }}
                        value={
                          address?.name
                            ? address?.name
                            : address?.city
                            ? address.city
                            : ""
                        }
                        error={locationEmpty}
                      />
                      {addressOpen && (
                        <>
                          <InputDataset
                            wrapperClass="create-order-form-box"
                            isAddress
                            setValue={(a: any) => {
                              const [latitude, longitude] = a?.coordinates;
                              setAddress(a);
                              setAddressOpen(false);
                              setLocation([latitude, longitude]);
                              // getAddress([latitude, longitude]);
                              setCenter([latitude, longitude]);
                              setZoom(15);
                              ymap?.setCenter([latitude, longitude], 17, {
                                duration: 1000,
                                timingFunction: "linear",
                              });
                            }}
                            defaultValue={
                              address?.name?.length
                                ? address?.name
                                : address?.city?.length
                                ? address?.city
                                : ""
                            }
                            close={() => makeAddressInputEmpty()}
                            list={addresses.data?.map(
                              ({
                                address: {
                                  city,
                                  county,
                                  road,
                                  house_number,
                                  town,
                                  village,
                                  hamlet,
                                  locality,
                                  state,
                                  natural,
                                },
                                lat,
                                lon,
                                addresstype,
                              }: any) => ({
                                city,
                                county,
                                road,
                                house_number,
                                lat,
                                lon,
                                town,
                                addresstype,
                                village,
                                hamlet,
                                locality,
                                state,
                                natural,
                              }),
                            )}
                            tab={() => {
                              setAddressOpen(false);
                              setTaskOpen(true);
                            }}
                            onChange={getAddressList}
                          />
                          <InputDatasetBackground
                            onClick={() => setAddressOpen(false)}
                          />
                        </>
                      )}
                    </BarBoxWrapper>
                    {/*{task?.title?.toLowerCase() === "Манипулятор".toLowerCase() ||
                  task?.title?.toLowerCase() === "Тонар".toLowerCase() ||
                  task?.title?.toLowerCase() === "Длиномер".toLowerCase() ? (
                    !addNewAddress ? (
                      <NewAddressBox onClick={() => setAddNewAddress(true)}>
                        <NewAddressIcon />
                        Добавить адрес
                      </NewAddressBox>
                    ) : (
                      <BarBoxWrapper>
                        <InputBox
                          address
                          icon={<MapRoute />}
                          label={"Адрес выполнения работы"}
                          onClick={() => {
                            setAddressOpen2(true);
                            setGetLocationFor("2");
                          }}
                          onRightClick={() => {
                            getUserLocation(true);
                            setGetLocationFor("2");
                          }}
                          value={address2?.name}
                        />
                        {addressOpen2 && (
                          <>
                            <InputDataset
                              wrapperClass="create-order-form-box"
                              isAddress
                              setValue={(a: any) => {
                                const [longitude, latitude] = a?.coordinates;
                                setAddress2(a);

                                setAddressOpen2(false);
                                setLocation([latitude, longitude]);
                                // getAddress([latitude, longitude]);
                                setCenter([latitude, longitude]);
                                setZoom(15);
                                ymap?.setCenter([latitude, longitude], 17, {
                                  duration: 1000,
                                  timingFunction: "linear",
                                });
                              }}
                              defaultValue={address2?.name}
                              close={() => makeAddressInputEmpty2()}
                              list={addresses?.data?.features?.map(
                                ({
                                  properties: { name, description },
                                  geometry: { coordinates },
                                }: any) => ({ name, description, coordinates })
                              )}
                              // search={getAddressList}
                              tab={() => {
                                setAddressOpen2(false);
                                setTaskOpen(true);
                              }}
                              onChange={(e: any) => getAddressList(e)}
                            />
                            <InputDatasetBackground
                              onClick={() => setAddressOpen2(false)}
                            />
                          </>
                        )}
                      </BarBoxWrapper>
                    )
                  ) : (
                    <></>
                  )}*/}

                    <BarBoxWrapper>
                      <InputBox
                        label={"Указать дату и время"}
                        placeholder={"Указать дату и время"}
                        icon={<Clock />}
                        value={
                          dateTime?.date && dateTime?.time
                            ? `${dateTime?.date?.format(
                                "D MMMM",
                              )} ${dateTime?.time?.format("HH:mm")}`
                            : "По договоренности"
                        }
                        onClick={() => setDateTimeOpen(true)}
                        error={dateEmpty}
                      />
                    </BarBoxWrapper>
                    <BarBoxWrapper>
                      <InputBox
                        // active={shift > 0}
                        active={Boolean(Object.keys(payment)?.length)}
                        onClick={() => setPaymentMethodOpen(true)}
                        icon={<WalletIcon />}
                        label={"Способ оплаты"}
                        placeholder={"Способ оплаты"}
                        value={
                          payment?.paymentMethod?.length
                            ? `${payment.paymentMethod
                                .map(({ name }: any) => name)
                                .join(", ")
                                .slice(0, 25)}${
                                payment.paymentMethod
                                  .map(({ name }: any) => name)
                                  .join(", ")?.length > 25
                                  ? `...`
                                  : ""
                              }`
                            : ""
                        }
                        error={paymentEmpty}
                      >
                        <div className="icon"></div>
                        <div className="name">
                          {payment?.paymentMethod?.length
                            ? `
                        ${payment.paymentMethod
                          .map(({ name }: any) => name)
                          .join(", ")
                          .slice(0, 25)}${
                                payment.paymentMethod
                                  .map(({ name }: any) => name)
                                  .join(", ")?.length > 25
                                  ? `...`
                                  : ""
                              }`
                            : "Оплата"}
                        </div>
                        <div className="right-icon">
                          <ArrowRight />
                        </div>
                      </InputBox>
                    </BarBoxWrapper>
                    <Dropdown>
                      <DropdownTitle
                        open={additionalOpen}
                        onClick={() => setAdditionalOpen(!additionalOpen)}
                      >
                        <div className="text">Дополнительная информация</div>
                        <div className="arrow">
                          <ArrowRight />
                        </div>
                      </DropdownTitle>
                      <DropdownBodyWrapper
                        shift={shift}
                        // style={{
                        //   height: additionalOpen
                        //     ? `${
                        //         (document.querySelector(".dropdown-body-box")
                        //           ?.clientHeight || 0) + 20
                        //       }px`
                        //     : "0px",
                        // }}
                        open={additionalOpen}
                        // payment={
                        //     payment?.paymentMethod?.length &&
                        //     payment.paymentMethod?.[0]?.name !== "Любой бюджет" &&
                        //     payment.paymentMethod?.[0]?.name !== "По договоренности"
                        // }
                      >
                        <DropdownBody className="dropdown-body-box">
                          <BarBoxWrapper>
                            <InputBox
                              // active={shift > 0}
                              icon={<div>₽</div>}
                              active={true}
                              label={"Бюджет"}
                              placeholder={"Бюджет"}
                              value={
                                payment.paymentMethod?.[0]?.name ===
                                "Любой бюджет"
                                  ? "Любой бюджет"
                                  : payment.amount
                                  ? shift > 0
                                    ? `${String(payment.amount * shift).replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        " ",
                                      )} ₽`
                                    : payment.amount
                                  : ""
                              }
                              onClick={() => {
                                setPaymentAmountOpen(true);
                              }}
                            />
                          </BarBoxWrapper>

                          <DropdownItem active={shift > 0}>
                            <div className="icon">
                              <CalendarIcon />
                            </div>
                            <div
                              className={`${shift > 0 ? "active" : ""} text`}
                            >
                              {/* {dateTime?.date && dateTime?.time
                          ? `${dateTime?.date
                              ?.hour(dateTime?.time?.hour())
                              ?.minute(dateTime?.time?.minute())
                              ?.calendar()}
                          `
                          : "Введите дату"} */}
                              <div className="title">
                                Количество смен (8ч.){" "}
                              </div>
                            </div>

                            <CounterBox>
                              <button
                                disabled={shift <= 0}
                                onClick={() =>
                                  setShift((prev) => (prev > 0 ? prev - 1 : 0))
                                }
                              >
                                <LeftArrow />
                              </button>
                              <div className="indicator">{shift}</div>
                              <button
                                onClick={() => setShift((prev) => prev + 1)}
                              >
                                <RightArrow />
                              </button>
                            </CounterBox>
                          </DropdownItem>

                          <BarBoxWrapper>
                            <InputBox
                              label={"Дополнительная информация"}
                              placeholder={"Дополнительная информация"}
                              icon={<InfoIcon />}
                              onClick={() => setDescriptionOpen(true)}
                              value={
                                description
                                  ? description?.length > 15
                                    ? description.slice(0, 15) + "..."
                                    : description.slice(0, 15)
                                  : ""
                              }
                            />
                          </BarBoxWrapper>

                          <BarBoxWrapper>
                            <InputBox
                              label={"Добавить фото, видео"}
                              placeholder={"Добавить фото, видео"}
                              icon={<PhotoIcon />}
                              onClick={() => setPhotoVideoOpen(true)}
                              value={
                                photoVideo.length
                                  ? `Файлов: ${photoVideo.length} `
                                  : ""
                              }
                            />
                          </BarBoxWrapper>

                          {/* {payment?.paymentMethod?.length &&
                        payment.paymentMethod?.[0]?.name !== "Любой бюджет" &&
                        payment.paymentMethod?.[0]?.name !==
                          "По договоренности" ? ( */}

                          {/* ) : (
                          <></>
                        )} */}

                          {/* <BarBoxWrapper>
                          <InputBox
                            label={
                              "Оплата"
                            }
                            icon={<WalletIcon />}
                            onClick={() => setPaymentOpen(true)}
                            value={
                              payment.amount
                                ? `${thousandSeparator(
                                    shift !== 0
                                      ? payment.amount * shift
                                      : payment.amount
                                  )} ₽`
                                : payment?.paymentMethod?.find(
                                    ({ value }: any) => value === "agreement"
                                  )
                                ? "Цена обсуждается"
                                : ""
                            }
                          />
                        </BarBoxWrapper> */}
                          {shift >= 2 && (
                            <CheckboxWrapper>
                              <CheckboxItem>
                                <input
                                  type="checkbox"
                                  checked={accommodation}
                                  onChange={(e) => {
                                    setAccommodation(e.target.checked);
                                  }}
                                />
                                Предоставляется жилье
                              </CheckboxItem>

                              <CheckboxItem>
                                <input
                                  type="checkbox"
                                  checked={protection}
                                  onChange={(e) => {
                                    setProtection(e.target.checked);
                                  }}
                                />
                                Охрана
                              </CheckboxItem>
                            </CheckboxWrapper>
                          )}
                        </DropdownBody>
                      </DropdownBodyWrapper>
                    </Dropdown>

                    {/* <BarBoxWrapper>
                    <BarBox
                      // active={shift > 0}
                      active={Boolean(Object.keys(payment)?.length)}
                      onClick={() => setPaymentMethodOpen(true)}
                    >
                      <div className="icon">
                        <Wallet />
                      </div>
                      <div className="name">
                        {payment.paymentMethod?.[0]?.name === "Любой бюджет"
                          ? "Любой бюджет"
                          : payment.paymentMethod?.[0]?.name ===
                            "По договоренности"
                          ? "По договоренности"
                          : payment?.paymentMethod?.length
                          ? `
                        ${payment.paymentMethod
                          .map(({ name }: any) => name)
                          .join(", ")
                          .slice(0, 25)}${
                              payment.paymentMethod
                                .map(({ name }: any) => name)
                                .join(", ")?.length > 25
                                ? `...`
                                : ""
                            }`
                          : "Оплата"}
                      </div>
                      <div className="right-icon">
                        <ArrowRight />
                      </div>
                    </BarBox>
                  </BarBoxWrapper> */}
                    {/* <BarBoxWrapper>
                    <BarBox
                      // active={shift > 0}
                      active={payment.amount}
                      onClick={() => {
                        if (
                          !(
                            payment.paymentMethod?.[0]?.name ===
                              "Любой бюджет" ||
                            payment.paymentMethod?.[0]?.name ===
                              "По договоренности"
                          )
                        ) {
                          setPaymentAmountOpen(true);
                        }
                      }}
                    >
                      <div className="icon">
                         ₽
                      </div>
                      <div className="name">
                        {payment.paymentMethod?.[0]?.name === "Любой бюджет"
                          ? "Любой бюджет"
                          : payment.paymentMethod?.[0]?.name ===
                            "По договоренности"
                          ? // "Сумма"
                            // "По договоренности"
                            "Бюджет"
                          : payment.amount
                          ? `
                          ${String(payment.amount).replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            " "
                          )} ₽`
                          : "Бюджет"}
                      </div>
                      <div className="right-icon">
                        <ArrowRight />
                      </div>
                    </BarBox>
                  </BarBoxWrapper> */}
                    {/* <CheckboxWrapper>
                    {shift >= 2 && (
                      <CheckboxItem>
                        <input
                          type="checkbox"
                          checked={accommodation}
                          onChange={(e) => {
                            setAccommodation(e.target.checked);
                          }}
                        />
                        Предоставляется жилье?
                      </CheckboxItem>
                    )}
                    {shift >= 2 && (
                      <CheckboxItem>
                        <input
                          type="checkbox"
                          checked={protection}
                          onChange={(e) => {
                            setProtection(e.target.checked);
                          }}
                        />
                        Охрана
                      </CheckboxItem>
                    )}
                    <CheckboxItem>
                      <input
                          type="checkbox"
                          checked={telegram}
                          onChange={(e) => {
                            setTelegram(e.target.checked);
                          }}
                      />
                      Связаться с вами в Телеграм?
                    </CheckboxItem>
                        </CheckboxWrapper> */}
                    {/* <SmallBarWrapper></SmallBarWrapper> */}
                  </ScrollWrapper>
                  <div className="formBottom">
                    <Button
                      onClick={() => {
                        createOrder();
                      }}
                      onDisabledAction={checkEmptyFields}
                      height={"48px"}
                      width={"100%"}
                      disabled={
                        !(
                          Boolean(Object.keys(address).length) &&
                          Boolean(Object.keys(task).length) &&
                          Boolean(Object.keys(dateTime).length) &&
                          Boolean(payment?.paymentMethod?.length)
                        )
                      }
                    >
                      Сделать заказ
                    </Button>
                    <div className="text">
                      После нажатия кнопки «Сделать заказ» ваш заказ будет
                      передан в Rukki для поиска подходящих предложений. Это ни
                      к чему не обязывает.
                    </div>
                  </div>
                </CreateOrderFormBox>
                <CharacteristicsPopUp
                  close={() => setCharacteristicsOpen(false)}
                  isVisible={characteristicsOpen && vehicleParams?.length > 0}
                  onSubmit={(p: any) => {
                    setCharacteristics(p);
                    setChosenCharacteristics(p);
                  }}
                  defaultValue={
                    chosenCharacteristics?.length ? chosenCharacteristics : []
                  }
                  tab={() => {
                    setCharacteristicsOpen(false);
                    // setPhotoVideoOpen(true);
                  }}
                  vehicle={task?.title}
                  characteristics={vehicleParams}
                  updateVehicleParams={updateVehicleParams}
                  categoryId={task?.categoryId}
                />
              </CreateOrderBox>
            )}
          </CreateOrderWrapper>
          {dateTimeOpen && (
            <TimePopUp
              close={() => setDateTimeOpen(false)}
              onSubmit={(dt: any) => setDateTime(dt)}
              tab={() => {
                setDateTimeOpen(false);
                // setPaymentOpen(true);
              }}
              defaultValue={dateTime}
              setShift={setShift}
              shift={shift}
            />
          )}
          {paymentMethodOpen && (
            <PaymentMethodPopUp
              close={() => setPaymentMethodOpen(false)}
              onSubmit={(p: any) => {
                setPayment({
                  ...payment,
                  paymentMethod: p.paymentMethod,
                  amount:
                    p.paymentMethod === "По договоренности" ||
                    p.paymentMethod === "Любой бюджет"
                      ? 0
                      : payment.amount,
                });
                // setPayment(p);
              }}
              tab={() => {
                setPaymentMethodOpen(false);
                setDescriptionOpen(true);
              }}
              defaultValue={
                payment.paymentMethod?.[0]?.name !== "Любой бюджет" &&
                payment.paymentMethod?.[0]?.name !== "По договоренности"
                  ? connection
                  : null
              }
            />
          )}
          {paymentOpen && (
            <PaymentPopUp
              close={() => setPaymentOpen(false)}
              onSubmit={(p: any) => {
                setPayment({
                  ...payment,
                  paymentMethod: p.paymentMethod,
                  amount:
                    p.paymentMethod === "По договоренности" ||
                    p.paymentMethod === "Любой бюджет"
                      ? 0
                      : p.amount,
                });
                // setPayment(p);
              }}
              tab={() => {
                setPaymentOpen(false);
                setDescriptionOpen(true);
              }}
              defaultValue={payment}
            />
          )}
          {connectionOpen && (
            <ConnectionPopUp
              close={() => setConnectionOpen(false)}
              onSubmit={(p: any) => {
                setConnection({
                  ...payment,
                  connectionMethod: p.connectionMethod,
                  amount:
                    p.connectionMethod === "Подойдет любой способ связи"
                      ? 0
                      : connection.amount,
                });
              }}
              defaultValue={
                payment.paymentMethod?.[0]?.name !==
                "Подойдет любой способ связи"
                  ? connection
                  : null
              }
            />
          )}
          {paymentAmountOpen && (
            <PaymentAmountPopUp
              close={() => setPaymentAmountOpen(false)}
              onSubmit={(p: any) => {
                setPayment({ ...payment, amount: p.amount });
              }}
              tab={() => {
                setPaymentAmountOpen(false);
                setDescriptionOpen(true);
              }}
              defaultValue={{
                amount: payment.amount ? payment.amount : 0,
              }}
            />
          )}
          {descriptionOpen && (
            <DescriptionPopUp
              close={() => setDescriptionOpen(false)}
              onSubmit={(p: any) => setDescription(p)}
              defaultValue={description}
              tab={() => {
                setDescriptionOpen(false);
                setPhotoVideoOpen(true);
              }}
            />
          )}

          {photoVideoOpen && (
            <PhotoVideoPopUp
              close={() => setPhotoVideoOpen(false)}
              onSubmit={(p: any) => setPhotoVideo(p)}
              defaultPhotoVideo={photoVideo}
            />
          )}
        </>
      )}
      {/*{!orderProcess.loading && (*/}
      {/*  // <Container>*/}
      {/*  //   <Loading*/}
      {/*  //       title="Регистрация заказа"*/}
      {/*  //       description="Не закрывайте окно браузера"*/}
      {/*  //   />*/}
      {/*  // </Container>*/}
      {/*  <Container>*/}
      {/*    <Success*/}
      {/*      title="Вы создали заказ"*/}
      {/*      description="Отлично, ваше предложение уже отправлено исполнителю. Ожидайте уведомление или скачайте мобильное приложение, чтобы быстрее работать с заказами."*/}
      {/*      // button="Посмотреть мои заказы"*/}
      {/*      // onClick={() => navigate("/o")}*/}
      {/*    />*/}
      {/*  </Container>*/}
      {/*)}*/}
      {/*{!orderProcess.success && (*/}
      {/*  // true*/}
      {/*  <Container>*/}
      {/*    <Success*/}
      {/*      title="Вы создали заказ"*/}
      {/*      description="Отлично, ваше предложение уже отправлено исполнителю. Ожидайте уведомление или скачайте мобильное приложение, чтобы быстрее работать с заказами."*/}
      {/*      // button="Посмотреть мои заказы"*/}
      {/*      // onClick={() => navigate("/o")}*/}
      {/*    />*/}
      {/*  </Container>*/}
      {/*)}*/}
    </PageWrapper>
  );
};

export default CreateOrderPage;

