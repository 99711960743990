import React, { memo, useCallback, useEffect, useState } from "react";
import { IUserView, ManagerType } from "../../types";
import { PopupWrapper, PopupContent } from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/Close.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/icons-add.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete_black_24dp.svg";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/avatar.png";
import { getAvatarUrl } from "../proposalItem";
import Input from "../../UI/Input";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getEmployeeRole } from "../../pages/dashboardEmployeesPage";

interface ChatMembersPopupProps {
  members: IUserView[];
  close: () => void;
  isOpen: boolean;
  handleAddChatMember: (memberId: number) => void;
  handleRemoveChatMember: (memberId: number) => void;
}

const ChatMembersPopup: React.FC<ChatMembersPopupProps> = ({
  members,
  close,
  isOpen,
  handleAddChatMember,
  handleRemoveChatMember,
}) => {
  const [inviteState, setInviteState] = useState<boolean>(false);
  const [filterMembers, setFilterMembers] = useState<string>("");

  const managers: ManagerType[] = useAppSelector(
    (state) => state.managers.data,
  );
  const user = useAppSelector((state) => state.auth.user);

  const navigate = useNavigate();

  const renderMembers = (members: IUserView[]) => {
    return members.map((member, index) => {
      return (
        <div
          key={`chatMember_${index}`}
          onClick={() => navigate(`/user/${member.id}`)}
          className="bodyItem"
        >
          <img
            src={
              member?.avatarUrl?.length
                ? getAvatarUrl(member.avatarUrl)
                : avatar
            }
            alt=""
          />
          <div className="flex-group">
            {`${member.firstName} ${member.secondName && member.secondName}`}
            {member.company && member.role !== "worker" && (
              <div className="subText">{member.company.title}</div>
            )}
            {member.role === "worker" && (
              <div className="subText">Исполнитель</div>
            )}
          </div>
          {member.role !== "worker" && user?.customer.id !== member.id ? (
            <DeleteIcon
              onClick={() => {
                handleRemoveChatMember(member.id);
                close();
              }}
            />
          ) : null}
        </div>
      );
    });
  };

  const renderEmployees = useCallback(
    (employees: ManagerType[]) => {
      return employees.map((employee, index) => {
        const EmployeeElement = (
          <div
            key={`chatMember_${index}`}
            onClick={() => {
              handleAddChatMember(employee.id);
              close();
            }}
            className="bodyItem"
          >
            <img src={avatar} alt="" />
            <div className="flex-group">
              {`${employee.firstName} ${
                employee.secondName && employee.secondName
              }`}
              <div className="subText">
                {getEmployeeRole(employee.companyRole)}
              </div>
            </div>
          </div>
        );
        if (filterMembers.length) {
          if (
            employee.firstName
              .toLowerCase()
              .startsWith(filterMembers.toLowerCase()) ||
            employee.secondName
              .toLowerCase()
              .startsWith(filterMembers.toLowerCase())
          ) {
            return EmployeeElement;
          }
        } else {
          return EmployeeElement;
        }
      });
    },
    [managers, filterMembers],
  );

  useEffect(() => {
    if (!isOpen) {
      setInviteState(false);
    }
  }, [isOpen]);

  return (
    <PopupWrapper open={isOpen} onClick={close}>
      <PopupContent
        onClick={(e) => {
          e.stopPropagation();
          setInviteState(false);
        }}
        onMouseEnter={(e) => {
          e.stopPropagation();
        }}
        open={isOpen}
        isInvite={inviteState}
      >
        <div className="popupHeader">
          Участники
          <CloseIcon onClick={close} />
        </div>
        <div className="popupBody">
          <div className="membersList">{renderMembers(members)}</div>

          <div
            className="plusButton"
            onClick={(e) => {
              e.stopPropagation();
              setInviteState(!inviteState);
            }}
          >
            <PlusIcon /> Добавить участника
            <div
              className="inviteMemberList"
              onClick={(e) => e.stopPropagation()}
            >
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFilterMembers(e.target.value)
                }
                name={"filter"}
                value={filterMembers}
                placeholder="Поиск сотрудника"
                height="32px"
              />
              {managers ? renderEmployees(managers) : null}
            </div>
          </div>
        </div>
      </PopupContent>
    </PopupWrapper>
  );
};

export default memo(ChatMembersPopup);
