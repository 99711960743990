import React, { memo, useEffect, useState } from "react";
import { Wrapper } from "./styles";

import { ReactComponent as ActiveStar } from "../../assets/icons/icons-rating-large-active.svg";
import { ReactComponent as InActiveStar } from "../../assets/icons/icons-rating-large-gray.svg";

const RatingBox: React.FC<{ rating?: string }> = ({ rating }) => {
  const [rate, setRate] = useState(0);
  useEffect(() => {
    setRate(Math.round(Number(rating)));
  }, [rating]);
  return (
    <Wrapper>
      {Array(5)
        .fill("")
        .map((v, i) =>
          rate >= i + 1 ? <ActiveStar key={i} /> : <InActiveStar key={i} />,
        )}
    </Wrapper>
  );
};

export default memo(RatingBox);
