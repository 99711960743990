import { Button } from "antd";
import styled from "styled-components";
import { styles } from "../styles/styles";

export const Wrapper = styled.div<{ open?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const UserData = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const UserDataPart = styled.div`
  width: fit-content;

  .full-name {
    margin-bottom: 6px;
    font-size: 28px;
    font-weight: 500;
    font-family: "Rubik";
    line-height: 1.11;
    letter-spacing: -1px;
    cursor: pointer;
    user-select: none;
    transition: all 0.3s linear;
    &:hover {
      color: #ff6633;
    }
  }

  .role {
    font-size: 16px;
    line-height: 1.25;
    color: var(--color-black);
    margin-bottom: 8px;
  }

  .rating-part {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    span {
      font-size: 24px;
      font-weight: 500;
      line-height: 0.83;
      letter-spacing: -0.1px;
      margin-left: 10px;
    }
  }
`;

export const AvatarBox = styled.div`
  width: 80px;
  height: 80px;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const NavsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const NavItem = styled.div<{ active?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  justify-content: space-between;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s linear;

  .name {
    font-size: 16px;
    line-height: 1.25;
    transition: all 0.3s linear;
  }

  .icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s linear;

    svg {
      width: 100%;
      height: 100%;
      transition: all 0.3s linear;
    }
  }

  ${({ active }) =>
    active &&
    `
  background-color: #fce1d899;
    .name {
      color: ${styles.mainColor};
      /* font-weight: bold; */
    }
    .icon {
      filter: invert(49%) sepia(97%) saturate(2127%) hue-rotate(339deg) brightness(102%) contrast(101%);
    }
  `};
  &:hover {
    background-color: #fce1d8;

    .name {
      color: ${styles.mainColor};
      /* font-weight: bold; */
    }

    &:hover {
      .icon {
        ${({ active }) =>
          !active &&
          "filter: invert(49%) sepia(97%) saturate(2127%) hue-rotate(339deg) brightness(102%) contrast(101%)"};
      }
    }
  }
`;

export const BetweenSpace = styled.div`
  width: 100%;
  height: 70px;

  @media screen and (max-width: 768px) {
    height: 35px;
  }
`;

