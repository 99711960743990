import styled from "styled-components";
import { styles } from "../../components/styles/styles";

export const AddObj = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    display: flex;
    margin-right: 5px;
  }
`;

export const Section = styled.div<{ noPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  padding: ${({ noPadding }) => (noPadding ? "0" : "0 20px 12px 20px")};
  /* height: 100%; */
  width: 50%;
  max-height: calc(100vh - 65px);
  overflow: auto;

  .mapWrapper {
    width: 100%;
    height: calc(100vh - 65px);
  }

  &:last-child {
    background: none;
  }

  @media screen and (max-width: 1440px) {
    width: 880px;

    &:last-child {
      width: calc(100% - 880px);
    }
  }
`;

export const StatCardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  .amountInWork {
    background-color: rgba(117, 117, 117, 0.08);
    color: #222222;
    cursor: unset;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StatCard = styled.div`
  background-color: #ff6633;
  padding: 16px;
  color: white;
  border-radius: 10px;
  cursor: pointer;
  height: 112px;
  width: 198px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .number {
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.5px;
  }

  .text {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: var(--color-white);
    white-space: nowrap;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:nth-child(even) {
    align-self: flex-end;
  }

  @media screen and (max-width: 700px) {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 220px;
  }
`;

export const FilterText = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
`;

export const TimeList = styled.div`
  display: flex;
  gap: 8px;
`;

export const TimeItem = styled.div<{ active?: boolean }>`
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 7px 10.5px 8px;
  border-radius: 10px;
  color: ${({ active }) =>
    active ? styles.mainColor : "rgba(153, 153, 153, 0.8)"};
  border: solid 1px ${({ active }) => (active ? styles.mainColor : "#e3e3e3")};
  cursor: pointer;
`;

export const CalendarBox = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0 33px;
  padding: 4.3px 16px 5.7px 8px;
  border-radius: 10px;
  border: solid 1px var(--color-light-gray);
  cursor: pointer;

  svg {
    margin-right: 8px;
  }

  span {
    opacity: 0.5;
    font-size: 14px;
    line-height: 1.14;
    color: var(--color-black);
  }
`;

export const CalendarWrapper = styled.div`
  .ant-picker.ant-picker-range {
    border-radius: 10px;
    height: 33px;
  }

  .ant-picker:hover {
    border-color: ${styles.mainColor};
  }

  .ant-picker-focused,
  .ant-picker:hover {
    box-shadow: 0 0 0 0;
    border-color: ${styles.mainColor};

    .ant-picker-active-bar {
      background-color: ${styles.mainColor};
    }
  }
`;

export const FilterBtn = styled.div`
  height: 33px;
  border-radius: 10px;
  width: 27%;
  font-size: 14px;
  line-height: 1.14;
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  ${({ _bgColor }: { _bgColor?: boolean }) =>
    _bgColor &&
    `background: #FF6633; color: white;`} @media screen and(max-width: 700px) {
    width: 200px;
    margin: 0 auto;
  }
`;

export const TableWrapper = styled.div``;

export const TableHeader = styled.div`
  width: 100%;
  /* padding: 8px; */
  border: 1px solid var(--color-gray);
  border-right: 0;
  border-left: 0;
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  flex-wrap: wrap;
  gap: 10px;
  padding: 8px 30px 8px 24px;
  @media screen and (max-width: 700px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

export const TH = styled.div<{ asc?: boolean; desc?: boolean }>`
  width: calc((100% - 50px) / 6);
  display: flex;
  align-items: center;

  .text {
    display: flex;
    width: fit-content;
    line-height: normal;
    max-width: calc(100% - 24px);
  }

  svg {
    transform: rotate(${({ desc }) => (desc ? "-180deg" : "0deg")});
    opacity: ${({ asc, desc }) => (!(asc || desc) ? 0.5 : 1)};
    transition-duration: 0.5s;
    cursor: pointer;
    @media screen and (max-width: 700px) {
      svg {
        margin-top: 60px;
      }
    }
  }
`;

export const TBody = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

export const TRow = styled.div`
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 24px 30px 21px 24px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #fcfcfc;
  background-color: var(--color-white);
`;

export const TRowTitle = styled.div`
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
`;

export const TData = styled.div`
  width: calc((100% - 50px) / 6);
  font-size: 14px;
  line-height: 1.14;
`;
