import styled from "styled-components";

export const Wrapper = styled.div`
  width: 99vw;
  height: 100vh;
  display: flex;
`;

export const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 10px;
  overflow: hidden;

  .emptyTag {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: auto;

    .header {
      font-size: 32px;
      font-family: "Rubik";
      font-weight: 500;
      margin-bottom: 16px;
    }

    .text {
      font-size: 20px;
    }

    @media screen and (max-width: 480px) {
      margin: auto 0;
      padding: 24px 8px 0;
      align-items: center;

      .header {
        font-size: 24px;
      }
      .text {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .legend {
    display: flex;
    gap: 32px;
    align-self: flex-end;
    margin-right: 24px;

    .legendItem {
      display: flex;
      gap: 8px;
      font-size: 16px;
      color: #999999;

      .color {
        width: 24px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
      }

      &:nth-child(1) {
        .color {
          background-color: #21c17a;
        }
      }

      &:nth-child(2) {
        .color {
          background-color: #ff3358;
        }
      }

      &:nth-child(3) {
        .color {
          background-color: #ff6633;
        }
      }
    }
  }
`;
