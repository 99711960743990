import React, { memo, useCallback, useEffect, useState } from "react";
import {
  SideMenuBackground,
  SideMenuWrapper,
  SideMenuHeader,
  OrderCard,
  OrdersList,
} from "./styles";
import { ReactComponent as CloseIcon } from "../../assets/icons/icon-24-intellicon-basic-circle-x.svg";
import { ReactComponent as Excavator } from "../../assets/icons/icons-vehicles-excavator.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/icons-chevron-right.svg";
import avatarWorker from "../../assets/images/avatar-worker.png";
import FilterSelect from "../../UI/FilterSelect";
import Checkbox from "../../UI/Checkbox";
import Input from "../../UI/Input";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getVehicleCategories } from "../../redux/store/reducers/vehicleSlice";
import { useNavigate } from "react-router-dom";
import { JobTitles } from ".";
import Accordion from "../../UI/Accordion";
import { CompanyAccessSettings, ManagerType } from "../../types";
import {
  editAccessSettings,
  editManagerData,
  editManagerRole,
  leaveCompany,
  removeManager,
  validateAccessSettings,
} from "../../redux/store/reducers/companySlice";
import { getAccessSettingName } from "../../utils/getAccessSettingName";
import { makeValidAccessSettings } from "../../utils/makeValidAccessSettings";
import ConfirmLeaveCompanyPopUp from "../../components/popUp/ConfirmLeaveCompanyPopUp";
import { getCompany } from "../../redux/store/reducers/companySlice";

interface SideMenuProps {
  menuOpen: boolean;
  toggleOpen: React.Dispatch<React.SetStateAction<boolean>>;
  user: ManagerType | null;
  displayRoles?: boolean;
  customerRefId?: string | undefined;
  title?: string;
}

const SideMenu: React.FC<SideMenuProps> = ({
  menuOpen,
  toggleOpen,
  user,
  displayRoles = true,
  customerRefId,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const companyData = useAppSelector((state) => state.company);
  const fireBaseData = useAppSelector((state) => state?.auth.fireBaseData);
  const userAccessSettings = useAppSelector(
    (state) => state.auth.user?.customer.accessSettings,
  );
  const customerRole = useAppSelector(
    (state) => state.auth.user?.customer.companyRole,
  );
  const refId = fireBaseData?.user?.refId;
  const customerId = useAppSelector((state) => state.auth.user?.customer.id);
  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );
  const [leaveCompanyPopUp, setLeaveCompanyPopUp] = useState("");
  const [jobTitle, setJobTitle] = useState<{
    name: string;
    value: string;
  } | null>();
  const [userEditPending, setUserEditPending] = useState<boolean>(false);
  const [accordions, setAccordions] = useState<{
    access: boolean;
    orders: boolean;
    userData: boolean;
  }>({ access: false, orders: false, userData: false });
  const [userData, setUserData] = useState<{
    firstName: string;
    secondName: string;
    patronymic: string;
    email: string;
    phoneNumber: string;
  } | null>(null);
  const [userDataEdited, setUserDataEdited] = useState<string[]>([]);
  const [accessSettings, setAccessSettings] =
    useState<CompanyAccessSettings | null>(null);

  useEffect(() => {
    if (user) {
      const userRole = JobTitles.find((job) => job.value === user?.companyRole);
      if (userRole) {
        setJobTitle({ name: userRole.label, value: userRole.value });
      }

      user?.companyAccessSettings
        ? setAccessSettings(
            JSON.parse(user.companyAccessSettings) as CompanyAccessSettings,
          )
        : dispatch(
            validateAccessSettings({
              accessSettings: {
                ...(JSON.parse(
                  user.companyAccessSettings,
                ) as CompanyAccessSettings),
              },
            }),
          ).then((response) => {
            setAccessSettings({ ...response.payload.sample });
          });
      setUserData({
        firstName: user.firstName,
        secondName: user.secondName,
        patronymic: user.patronymic,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });
    }

    return () => {
      setAccessSettings(null);
      setJobTitle(null);
    };
  }, [user, menuOpen]);
  const handleRemoveManager = () => {
    if (customerRefId && user) {
      dispatch(
        removeManager({ refId: customerRefId, employeeId: user?.id }),
      ).then((response) => {
        response.payload.removed && toggleOpen(false);
      });
    }
  };

  const handleLeave = () => {
    if (customerRefId) {
      dispatch(leaveCompany({ refId: customerRefId })).then((response) => {
        response.meta.requestStatus === "fulfilled" && navigate("/profile");
      });
    }
  };

  const compareUserData = () => {
    if (userData && user) {
      const keys = Object.keys(userData!);
      let result = false;
      for (let key of keys) {
        if (
          userData[key as keyof typeof userData] !==
          user[key as keyof ManagerType]
        ) {
          result = true;
        }
      }
      if (result) setUserDataEdited([...userDataEdited, "userData"]);
      else setUserDataEdited([]);
    }
  };

  useEffect(() => {
    compareUserData();
  }, [userData]);

  useEffect(() => {
    if (!menuOpen) {
      setAccordions({ access: false, userData: false, orders: false });
      setUserData(null);
    }
  }, [menuOpen]);

  useEffect(() => {
    !vehicleCategories?.length && dispatch(getVehicleCategories());
  }, []);

  const renderUserData = () => {
    const handleUserDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (userData) {
        setUserData({ ...userData, [e.target.name]: e.target.value });
      }
    };

    const getPlaceholder = (fieldName: string): string => {
      switch (fieldName) {
        case "firstName":
          return "Имя";
        case "secondName":
          return "Фамилия";
        case "patronymic":
          return "Отчество";
        case "email":
          return "Email";
        case "phoneNumber":
          return "Телефон";
        default:
          return "";
      }
    };

    if (userData) {
      return [
        <FilterSelect
          name="role"
          list={JobTitles.filter((job) => job.value !== "ceo")}
          listMarker={false}
          value={
            jobTitle
              ? JobTitles.find((job) => job.value === jobTitle.value)?.label
              : ""
          }
          onChange={({ name, value }) => {
            setJobTitle({ name, value: value as string });
            setUserDataEdited([...userDataEdited, "role"]);
          }}
          placeholder="Должность сотрудника"
          disabled={
            !userAccessSettings?.changingEmployeesPersonalData ||
            user?.id === customerId ||
            user?.companyRole === customerRole ||
            !user?.roleAvailableToAccess
          }
        />,
        [
          ...Object.keys(userData!).map((key: string, index: number) => {
            return (
              <Input
                name={key}
                key={`userDataField_${index}`}
                value={userData![key as keyof typeof userData]}
                placeholder={getPlaceholder(key)}
                disabled={
                  key === "phoneNumber" ||
                  !userAccessSettings?.changingEmployeesPersonalData ||
                  user?.id === customerId ||
                  user?.companyRole === customerRole ||
                  !user?.roleAvailableToAccess
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleUserDataChange(e)
                }
              />
            );
          }),
        ],
      ];
    }
  };

  const handleRoleChange = () => {
    if (jobTitle && customerRefId && user)
      dispatch(
        editManagerRole({
          role: jobTitle?.value,
          employeeId: user?.id,
          refId: customerRefId,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setUserDataEdited([
            ...userDataEdited.filter((item) => item !== "role"),
          ]);
        }
        setUserEditPending(false);
      });
  };
  const handleUserEdit = () => {
    if (customerRefId && userData && user) {
      dispatch(
        editManagerData({
          data: {
            firstName: userData?.firstName,
            secondName: userData?.secondName,
            patronymic: userData?.patronymic,
            email: userData.email,
            phoneNumber: user.phoneNumber,
            companyRole: user?.companyRole,
            city: "",
          },
          employeeId: user.id,
          refId: customerRefId,
        }),
      ).then((response) => {
        if (response.meta.requestStatus === "fulfilled") {
          setUserDataEdited([
            ...userDataEdited.filter((item) => item !== "userData"),
          ]);
        }
        setUserEditPending(false);
      });
    }
  };

  const handleAccessChange = (setting: keyof CompanyAccessSettings) => {
    if (typeof accessSettings === "object" && accessSettings) {
      setAccessSettings({
        ...accessSettings,
        [setting]: !accessSettings[setting],
      } as CompanyAccessSettings);
      dispatch(validateAccessSettings({ accessSettings })).then((response) => {
        if (response.payload.isValid) {
          dispatch(
            editAccessSettings({
              accessSettings: {
                ...accessSettings,
                [setting]: !accessSettings[setting],
              } as CompanyAccessSettings,
              refId: customerRefId!,
              employeeId: user?.id!,
            }),
          );
        } else {
          dispatch(
            editAccessSettings({
              accessSettings: {
                ...makeValidAccessSettings(
                  accessSettings,
                  response.payload.sample,
                ),
              },
              refId: customerRefId!,
              employeeId: user?.id!,
            }),
          );
        }
      });
    }
  };

  const renderOrders = () => {
    return (
      <OrdersList>
        {user?.orders
          ?.filter((order) => order.status !== "Canceled")
          ?.map((order, index) => {
            return (
              <OrderCard
                onClick={() => navigate(`/o/${order?.id}`)}
                key={`employeeOrder_${index}`}
              >
                <div className="orderTitle">
                  {vehicleCategories.find(
                    (vehicle: any) => vehicle.id === order?.categoryId,
                  )?.icon ? (
                    <img
                      src={`https://dev.rukki.pro/${
                        vehicleCategories.find(
                          (vehicle: any) => vehicle.id === order?.categoryId,
                        )?.icon
                      }`}
                      width={"24px"}
                      height={"24px"}
                      alt=""
                    />
                  ) : (
                    <Excavator />
                  )}
                  <div>{order?.title}</div>

                  <div className="date">
                    {/* {moment(order?.createdAt).format("D MMMM YYYY")} */}
                    {`#${order.id}`}
                  </div>
                </div>
                <div className="orderBody">
                  {order?.address && <div>{order?.address}</div>}

                  <div>{`Выполнить ${moment(order?.startDate).format(
                    "D MMMM YYYY",
                  )}`}</div>
                </div>
                <div className="orderFooter">
                  <img src={avatarWorker} alt="" />
                  <div>{`Откликов: ${order?.offersCount}`}</div>

                  <div className="orderStatus">
                    <ChevronRight />
                    {order?.status === "InProgress"
                      ? "Идут работы"
                      : order?.status === "Finished"
                      ? "Завершено"
                      : order?.status === "Published"
                      ? "Опубликовано"
                      : order?.status === "Done"
                      ? "Завершен"
                      : order?.status === "WorkerSetOut"
                      ? "Идут работы"
                      : order?.status === "WorkerSelected"
                      ? "Исполнитель выбран"
                      : order?.status === "GoingToDestination"
                      ? "Исполнитель выехал"
                      : order?.status}
                  </div>
                </div>
              </OrderCard>
            );
          })}
      </OrdersList>
    );
  };
  useEffect(() => {
    menuOpen
      ? (document.body.style.overflow = "hidden")
      : (document.body.style.overflow = "unset");
  }, [menuOpen]);

  useEffect(() => {
    if (refId) {
      dispatch(getCompany(refId));
    }
  }, [refId]);

  return (
    <>
      <SideMenuBackground
        open={menuOpen}
        onClick={() => toggleOpen(false)}
      ></SideMenuBackground>
      <SideMenuWrapper open={menuOpen}>
        <SideMenuHeader>
          {user?.id === customerId ? "Мой аккаунт" : "Управление сотрудником"}{" "}
          <CloseIcon onClick={() => toggleOpen(false)} />
        </SideMenuHeader>
        {userData ? (
          <Accordion
            title="Персональные данные"
            list={renderUserData()}
            bodyMaxHeight="544px"
            open={accordions.userData}
            setOpen={() => {
              setAccordions({
                ...accordions,
                userData: !accordions.userData,
              });
            }}
            buttonVisible={!!userDataEdited.length}
            buttonOnClick={() => {
              setUserEditPending(true);
              userDataEdited?.includes("role") && handleRoleChange();
              userDataEdited?.includes("userData") && handleUserEdit();
            }}
            buttonDisabled={userEditPending}
          />
        ) : null}
        {accessSettings ? (
          <Accordion
            title="Права"
            list={Object.keys(accessSettings as CompanyAccessSettings).map(
              (key: string, index: number) => {
                return (
                  key !== "changingEmployees" && (
                    <Checkbox
                      key={`accessCheck_${index}`}
                      text={getAccessSettingName(key)}
                      fontSize="15px"
                      active={
                        accessSettings[key as keyof CompanyAccessSettings]
                      }
                      onClick={() =>
                        handleAccessChange(key as keyof CompanyAccessSettings)
                      }
                      disabled={
                        !userAccessSettings?.changingEmployeesAccessSettings ||
                        user?.id === customerId ||
                        user?.companyRole === customerRole ||
                        !user?.roleAvailableToAccess
                      }
                    />
                  )
                );
              },
            )}
            bodyMaxHeight="unset"
            open={accordions.access}
            setOpen={() => {
              setAccordions({
                ...accordions,
                access: !accordions.access,
              });
            }}
          />
        ) : null}

        {!!user?.orders.length && (
          <Accordion
            title="Заказы"
            userInfo={user}
            open={accordions.orders}
            setOpen={() => {
              setAccordions({
                ...accordions,
                orders: !accordions.orders,
              });
            }}
            list={renderOrders()}
          />
        )}
        {userAccessSettings?.removingEmployees &&
        user?.id !== customerId &&
        user?.companyRole !== customerRole &&
        user?.companyRole !== "ceo" &&
        user?.roleAvailableToAccess ? (
          <>
            <div
              style={{
                marginTop: "auto",
                color: "red",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "450",
                cursor: "pointer",
              }}
              onClick={() => setLeaveCompanyPopUp("remove")}
            >
              Удалить сотрудника
            </div>
            {leaveCompanyPopUp === "remove" && (
              <ConfirmLeaveCompanyPopUp
                onSubmit={handleRemoveManager}
                close={() => setLeaveCompanyPopUp("")}
                removeEmployee={"remove"}
              />
            )}
          </>
        ) : null}
        {user?.id === customerId && customerRole !== "ceo" ? (
          <div
            style={{
              marginTop: "auto",
              color: "red",
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "450",
              cursor: "pointer",
            }}
            onClick={() => setLeaveCompanyPopUp("leave")}
          >
            Покинуть организацию
          </div>
        ) : null}
        {leaveCompanyPopUp === "leave" && (
          <ConfirmLeaveCompanyPopUp
            onSubmit={handleLeave}
            close={() => setLeaveCompanyPopUp("")}
            companyName={companyData?.companyData?.title}
            removeEmployee={"leave"}
          />
        )}
      </SideMenuWrapper>
    </>
  );
};

export default SideMenu;
