import React, { memo } from "react";
import RightBlock from "../../assets/images/register-img.png";
import { ReactComponent as HoistIcon } from "../../assets/icons/icons-vehicles-hoist.svg";
import { ReactComponent as PeopleIcon } from "../../assets/icons/icons-people-3.svg";
import { ReactComponent as GeoIcon } from "../../assets/icons/24-maps-map-pin-location.svg";
import { ReactComponent as FileIcon } from "../../assets/icons/24-file-file-text.svg";

import {
  Section,
  InfoCard,
  CardsWrapper,
  Paragraph,
  Row,
  Button,
  PhoneWrapper,
} from "./styles";
import PageWrapper from "../../UI/PageWrapper";
import PageHeader from "../../UI/PageHeader";

const CardsData = [
  {
    header: "Управляйте техникой",
    text: "Так мы сможем предлагать вам наиболее подходящие заказы, а вы – управлять занятостью",
    icon: HoistIcon,
  },
  {
    header: "Управляйте сотрудниками",
    text: "Так вы сможете назначать заказы своим сотрудникам и управлять занятостью",
    icon: PeopleIcon,
  },
  {
    header: "Информация по всем объектам",
    text: "GPS-трекинг техники на объекте, контроль всего процесса в прямом эфире",
    icon: GeoIcon,
  },
  {
    header: "Расширенная аналитика",
    text: "Подписание и передача цифровых путевок, договоров, актов откуда угодно",
    icon: FileIcon,
  },
];

const DigitalDispatcherDemo: React.FC = () => {
  return (
    <PageWrapper noWidth gap="0">
      <Section>
        <PageHeader backButton={false} bold>
          Цифровой диспетчер
        </PageHeader>
        <Paragraph>
          Используйте функционал сервиса РУККИ в полной мере, экономьте время и
          деньги в управлении ресурсами и процессами
        </Paragraph>
        <Row>
          <a href="tel:84993488499">
            <Button>Запросить демонстрацию</Button>
          </a>
          <PhoneWrapper>
            <div className="text">Телефон для консультаций</div>
            <div className="phone">
              <a href="tel:84993488499">8 499 348 8499</a>
            </div>
          </PhoneWrapper>
        </Row>
        <CardsWrapper>
          {CardsData.map((card, index) => {
            const Icon = card.icon;
            return (
              <InfoCard>
                <Icon />
                <div className="cardText">
                  <div className="cardHeader">{card.header}</div>
                  <div className="cardParagraph">{card.text}</div>
                </div>
              </InfoCard>
            );
          })}
        </CardsWrapper>
      </Section>
      <Section>
        <img src={RightBlock} alt="" />
      </Section>
    </PageWrapper>
  );
};

export default memo(DigitalDispatcherDemo);
