import { screen } from "@testing-library/react";
import { clearPassportImageData } from "./../../redux/modules/vehicleImages";
import styled from "styled-components";
import RegisterImg from "../../assets/images/register-img.png";

export const PageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div<{
  footer?: number | null;
  navbar?: number | null;
}>`
  width: 100%;
  display: flex;
  height: calc(100vh - 136px);
  flex-wrap: wrap;

  .row2 {
    width: 50%;
    background-color: white;
    /* position: absolute; */
    /* left: 0; */
    height: 100%;
    display: flex;
    align-items: center;
    /* position: relative; */
  }

  .row3 {
    width: 50%;
    height: 100%;
    display: flex;
    background-image: url(${RegisterImg});
    background-position: center;
    background-size: cover;
    .left-item {
      width: 50px;
      height: 100%;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      /* box-shadow: 0 0 100px white; */
      /* position: relative; */
      /* .gradient {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;

        width: 50px;
        height: 100%;
        z-index: 0;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 1) 0%,
          rgba(255, 255, 255, 0) 100%
        );
      } */
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      /* object-position: left;
      object-position: right; */
    }
  }

  @media only screen and (max-width: 998px) {
    img:not(.countryIcon) {
      display: none;
    }

    .row2 {
      width: 100%;
    }
    .row3 {
      display: none;
    }
  }

  @media only screen and (max-width: 480px) {
    height: calc(100vh - 60px);

    .row2 {
      height: 100%;
    }
  }
`;

export const LoginWrapper = styled.div<{ verification?: boolean }>`
  /* min-height: calc(100vh - 400px); */
  background-color: white;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 576px) {
    /* min-height: calc(100vh - 0px); */
    padding: 40px 0;
  }
  @media only screen and (max-width: 420px) {
    /* min-height: fit-content;
    height: fit-content; */
  }
`;

export const FormWrapper = styled.div`
  width: 328px;
  margin: 0px auto;
  position: relative;
  height: 100%;
  min-height: 390px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .btn {
    width: 50% !important;
  }

  @media only screen and (max-width: 350px) {
    width: 100%;
    padding: 0 20px;
  }
`;
export const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  font-family: "Rubik";
  line-height: 1.11;
  letter-spacing: -0.5px;
  color: #000;

  + p {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: #000;
    display: block;
    max-width: 280px;
  }
`;
