import React, { memo, useEffect, useState } from "react";
import {
  AddCardsWrapper,
  AddCardWrapper,
  AddDesc,
  AddIconPart,
  AddTitle,
  AddUserPart,
  ErrorMsg,
  ErrorsWrapper,
  FormDesc,
  FormTitle,
  InputsTitle,
  InputsWrapper,
  InputWrapper,
  RegisterForm,
  RemoveUser,
  SelectWrapper,
  SkipBtn,
  SkipText,
  SkipWrapper,
  VehicleBox,
  VehicleItem,
  VehicleList,
  VehicleMainTitle,
} from "./styles";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import { ReactComponent as PersonIcon } from "../../assets/icons/icons-person.svg";
import { ReactComponent as ThreePeopleIcon } from "../../assets/icons/icons-people-3.svg";
import { ReactComponent as Hoist } from "../../assets/icons/icons-vehicles-hoist-white.svg";
import { ReactComponent as HoistIcon } from "../../assets/icons/icons-vehicles-hoist.svg";
import { ReactComponent as CirclePlusIcon } from "../../assets/icons/24-basic-circle-plus.svg";
import { Button } from "../../components/form/styles";
import NumberFormat from "react-number-format";
import {
  ENDPOINT,
  ENDPOINT_FOR_IMG,
  GEOCODE_MAPS,
} from "../../constants/general";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useNavigate } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";

import { DatePicker } from "antd";
import moment from "moment";
import { isPossiblePhoneNumber } from "react-phone-number-input";
import AddVehiclePopUp from "../../components/popUp/addVehiclePopUp";
import AddManagerPopUp from "../../components/popUp/addManagerPopUp";
import {
  getVehicleCategories,
  getVehiclesList,
} from "../../redux/store/reducers/vehicleSlice";
import getManagers from "../../redux/modules/getManagers";
import { getCompany } from "../../redux/store/reducers/companySlice";

const InputBox = ({
  label,
  width,
  type,
  placeholder,
  onChange,
  value,
  name,
  required,
}: any) => {
  return (
    <InputWrapper
      active={type !== "role" && value?.length}
      style={{ width: width ? width : "100%" }}
      className={type !== "role" && value?.length ? "active" : ""}
    >
      {type === "role" ? (
        <SelectWrapper>
          <PersonIcon />
          <p>Сотрудник</p>
        </SelectWrapper>
      ) : (
        <>
          <span className="label">
            <span className="text">{label}</span>{" "}
            <span className="required">{required ? "*" : ""}</span>
          </span>
          {type === "inn" ? (
            <NumberFormat
              format="### ### ######"
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          ) : type === "tel" ? (
            value ? (
              <NumberFormat
                name={name}
                value={value || ""}
                prefix={"+"}
                placeholder={placeholder}
                onChange={onChange}
                autoFocus
                spellCheck={false}
              />
            ) : (
              <input
                name={name}
                value={"+"}
                type={type || "text"}
                placeholder={placeholder}
                onChange={onChange}
                spellCheck={false}
              />
            )
          ) : type === "date" ? (
            <div className="date-input">
              <DatePicker
                format={"ll"}
                value={value ? moment(value) : null}
                onChange={(e) => {
                  onChange({
                    target: {
                      value: e?.format("YYYY-MM-DD"),
                      name,
                    },
                  });
                }}
              />
            </div>
          ) : (
            <input
              name={name}
              value={value || ""}
              type={type || "text"}
              placeholder={placeholder}
              onChange={onChange}
              spellCheck={false}
            />
          )}
        </>
      )}
    </InputWrapper>
  );
};

const AddManagerAndVehicle: React.FC<{ skip?: any; setSkip?: any }> = ({
  skip,
}) => {
  const emailRegEx =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [formData, setFormData] = useState<any>({});
  const [step, setStep] = useState<number>(1);
  const [addVehicle, setAddVehicle] = useState(false);
  const [addManager, setAddManager] = useState(false);
  const [errorMsg, setErrorMsg] = useState<any>([]);
  const [errorMsgStep2, setErrorMsgStep2] = useState<any>({
    fullNameEmpty: false,
    phoneNumberEmpty: false,
    emailPattern: false,
    emailEmpty: false,
  });

  const fireBaseData = useAppSelector((state) => state?.auth.fireBaseData);
  const refId = fireBaseData?.user?.refId;

  const company = useAppSelector((state) => state.company);
  const { data: managers } = useAppSelector((state) => state.managers);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const vehicleCategories = useAppSelector(
    (state) => state.vehicle.vehicleCategories,
  );

  const vehiclesList = useAppSelector((state) => state.vehicle.vehicleList);

  const changeFormDataStep2 = (e: any, index: number) => {
    const { name, value } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      users: prev.users.map((v: any, i: number) =>
        i === index
          ? {
              ...v,
              [name]: value,
            }
          : v,
      ),
    }));
  };

  const inputListStep2 = [
    {
      name: "role",
      width: "300px",
      type: "role",
    },
    {
      label: "ФИО сотрудника",
      name: "full_name",
      required: true,
    },
    {
      label: "Телефон",
      name: "phone_number",
      width: "calc((100% - 12px) / 2)",
      required: true,
      type: "tel",
    },
    {
      label: "Электронная почта",
      name: "email",
      width: "calc((100% - 12px) / 2)",
      required: true,
    },
    {
      label: "Примечание (видно только вам)",
      name: "note",
    },
  ];

  const [addUserProcess, addUserRequest] = useHttpRequest({
    onSuccess: () => {
      setStep(3);
    },
    onError: (err: any) => {
      if (err?.response?.status === 409) {
        setErrorMsg([
          "Один из пользователей уже является менеджером в другой компании, либо пользователь уже зарегистрирован",
        ]);
      }
    },
  });

  const addUserToCompany = () => {
    setErrorMsgStep2({});
    const emailRegEx =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const filterData = formData.users.filter(
      ({ email, full_name, phone_number }: any, index: number) => {
        const objData = {
          fullNameEmpty: false,
          phoneNumberEmpty: false,
          emailPattern: false,
          emailEmpty: false,
        };
        if (!full_name) {
          objData.fullNameEmpty = true;
        }
        if (!phone_number) {
          objData.phoneNumberEmpty = true;
        }
        if (!email) {
          objData.emailEmpty = true;
        } else if (!email?.toLowerCase()?.match(emailRegEx)) {
          objData.emailPattern = true;
        }
        setErrorMsgStep2((prev: any) => ({
          ...prev,
          [index]: objData,
        }));
        return (
          full_name &&
          email?.toLowerCase()?.match(emailRegEx) &&
          phone_number &&
          isPossiblePhoneNumber(phone_number)
        );
      },
    );

    if (filterData?.length === formData?.users?.length) {
      setErrorMsgStep2({});
      addUserRequest({
        method: "POST",
        url: `${ENDPOINT}/companies/${refId}/add-managers`,
        data: {
          users: formData?.users?.map(
            ({ email, full_name, note, phone_number }: any) => ({
              firstName: full_name?.split(" ")[0],
              secondName: full_name?.split(" ")[1],
              phoneNumber: phone_number,
              email,
              companyDescription: note,
            }),
          ),
        },
      });
    } else {
    }
  };

  useEffect(() => {
    if (company.companyData && company.getCompany === "succeeded") {
      if (!formData?.users?.length) {
        setFormData((prev: any) => ({
          ...prev,
          users: [{}],
        }));
      }
    }
  }, [company.companyData, company.getCompany]);

  useEffect(() => {
    if (refId) {
      dispatch(getCompany(refId));
      dispatch(getVehiclesList(refId));
      dispatch(getVehicleCategories());
      dispatch(getManagers(refId));
    }
  }, [refId]);

  return (
    <>
      {step === 1 ? (
        <RegisterForm>
          <FormTitle>Продолжим!</FormTitle>
          <FormDesc>
            Чтобы скорее приступить к работе в сервисе, добавьте информацию о
            своем парке техники и сотрудниках
          </FormDesc>
          {vehiclesList?.length ? (
            <VehicleBox>
              <VehicleMainTitle>
                Список техники
                <button onClick={() => setAddVehicle(true)}>
                  <CirclePlusIcon />
                  Добавить технику
                </button>
              </VehicleMainTitle>
              <VehicleList>
                {vehiclesList?.map((vehicle: any, index: number) => {
                  const {
                    id,
                    model,
                    categoryId,
                    brand,
                    comment,
                    stateNumber,
                    productionYear,
                  } = vehicle;
                  const vCategory = vehicleCategories.find(
                    ({ id }) => id === categoryId,
                  );
                  return (
                    <VehicleItem
                      key={`vehicle${index}`}
                      to={`/my-vehicle/${id}`}
                    >
                      <div className="icon">
                        {vCategory?.icon ? (
                          <img
                            src={`${ENDPOINT_FOR_IMG}/${vCategory?.icon}`}
                            alt=""
                          />
                        ) : (
                          <HoistIcon />
                        )}
                      </div>
                      <div className="data">
                        <div className="year">{productionYear} год выпуска</div>
                        <div className="data-line">
                          {vCategory?.title} {model} {brand}, госномер{" "}
                          {stateNumber} {comment}
                        </div>
                      </div>
                      <div className="arrow-right">
                        <ArrowRight />
                      </div>
                    </VehicleItem>
                  );
                })}
              </VehicleList>
            </VehicleBox>
          ) : (
            <></>
          )}

          {managers?.users?.length > 0 ? (
            <VehicleBox>
              <VehicleMainTitle>
                Список сотрудников
                <button onClick={() => setAddManager(true)}>
                  <CirclePlusIcon />
                  Добавить сотрудников
                </button>
              </VehicleMainTitle>
              <VehicleList>
                {managers?.users?.map((vehicle: any, index: number) => {
                  const { id, name, phoneNumber } = vehicle;
                  return (
                    <VehicleItem key={`vehicle${index}`} to={`${id}`}>
                      <div className="data">
                        <div className="data-line">{name}</div>
                        <div className="year">{phoneNumber}</div>
                      </div>

                      <div className="arrow-right">
                        <ArrowRight />
                      </div>
                    </VehicleItem>
                  );
                })}
              </VehicleList>
            </VehicleBox>
          ) : (
            <></>
          )}

          <AddCardsWrapper>
            {!vehiclesList?.length ? (
              <AddCardWrapper>
                <AddIconPart>
                  <Hoist />
                </AddIconPart>
                <AddTitle>Добавьте свою технику</AddTitle>
                <AddDesc>
                  Так мы сможем предлагать вам наиболее подходящие заказы, а вы
                  – управлять занятостью
                </AddDesc>
                <Button onClick={() => setAddVehicle(true)}>
                  Добавить технику
                </Button>
              </AddCardWrapper>
            ) : (
              <></>
            )}
            {!managers?.users?.length ? (
              <AddCardWrapper>
                <AddIconPart>
                  <ThreePeopleIcon />
                </AddIconPart>
                <AddTitle>Добавьте сотрудников</AddTitle>
                <AddDesc>
                  Так вы сможете назначать заказы своим сотрудникам и управлять
                  занятостью
                </AddDesc>
                <Button onClick={() => setAddManager(true)}>
                  Добавить сотрудников
                </Button>
              </AddCardWrapper>
            ) : (
              <></>
            )}
          </AddCardsWrapper>
        </RegisterForm>
      ) : step === 2 ? (
        <RegisterForm>
          <FormTitle>Сведения о сотрудниках</FormTitle>
          <FormDesc>
            Добавьте сотрудников, чтобы отслеживать их работу в системе
          </FormDesc>
          {formData?.users?.map((v: any, i: number) => (
            <>
              {i > 0 ? <InputsTitle>Сотрудник {i + 1}</InputsTitle> : ""}
              <InputsWrapper>
                {inputListStep2.map(
                  ({ label, name, width, type, required }, index) => (
                    <>
                      <InputBox
                        label={label}
                        width={width}
                        name={name}
                        onChange={(e: any) => changeFormDataStep2(e, i)}
                        value={formData?.users?.[i]?.[name]}
                        type={type}
                        required={required}
                      />
                      {i > 0 && index === 0 ? (
                        <RemoveUser
                          onClick={() => {
                            setFormData((prev: any) => ({
                              ...prev,
                              users: prev.users.filter(
                                (v: any, ind: number) => i !== ind,
                              ),
                            }));
                          }}
                        >
                          Удалить сотрудника
                        </RemoveUser>
                      ) : (
                        ""
                      )}
                    </>
                  ),
                )}
                <ErrorsWrapper>
                  {errorMsgStep2?.[i]?.fullNameEmpty && (
                    <ErrorMsg>Укажите полное имя.</ErrorMsg>
                  )}
                  {errorMsgStep2?.[i]?.phoneNumberEmpty && (
                    <ErrorMsg>Укажите номер телефона.</ErrorMsg>
                  )}
                  {errorMsgStep2?.[i]?.emailEmpty && (
                    <ErrorMsg>Укажите адрес электронной почты.</ErrorMsg>
                  )}
                  {errorMsgStep2?.[i]?.emailPattern && (
                    <ErrorMsg>Электронная почта недействительна.</ErrorMsg>
                  )}
                </ErrorsWrapper>
              </InputsWrapper>
            </>
          ))}

          {errorMsg.length > 0 ? (
            <ErrorsWrapper>
              {errorMsg.map((msg: string) => (
                <ErrorMsg>{msg}</ErrorMsg>
              ))}
            </ErrorsWrapper>
          ) : (
            ""
          )}

          <AddUserPart>
            У вас есть еще сотрудники?{" "}
            <u
              onClick={() => {
                setFormData((prev: any) => ({
                  ...prev,
                  users: [...prev.users, {}],
                }));
              }}
            >
              Добавьте еще одного
            </u>
          </AddUserPart>
          <Button
            onClick={() => {
              addUserToCompany();
            }}
            fit
            _m0auto
            disabled={
              formData?.users?.filter(
                ({ email, full_name, phone_number }: any) => {
                  return (
                    full_name &&
                    email?.toLowerCase()?.match(emailRegEx) &&
                    phone_number &&
                    isPossiblePhoneNumber(phone_number)
                  );
                },
              ).length !== formData?.users?.length
            }
          >
            Продолжить
          </Button>
        </RegisterForm>
      ) : (
        <></>
      )}
      {skip.clicked && (!managers?.users?.length || !vehiclesList?.length) && (
        <SkipWrapper className="skip-wrapper">
          <SkipText>
            Вы можете перейти к следующему этапу, чтобы завершить заполнение
            профиля. <br />
            Вы всегда сможете обновить эту информацию позже в профиле
          </SkipText>
          <SkipBtn onClick={() => navigate("/profile")}>Продолжить</SkipBtn>
        </SkipWrapper>
      )}
      {addVehicle && (
        <AddVehiclePopUp
          onSubmit={() => {
            setAddVehicle(false);
            dispatch(getVehiclesList(refId));
          }}
          close={() => setAddVehicle(false)}
        />
      )}
      {addManager && (
        <AddManagerPopUp
          onSuccess={() => {
            dispatch(getManagers(refId));
            setAddManager(false);
          }}
          close={() => setAddManager(false)}
        />
      )}
    </>
  );
};

export default memo(AddManagerAndVehicle);
