import React, { memo, useEffect, useState } from "react";
import {
  FullName,
  NavItem,
  NavsListWrapper,
  RatingPart,
  Role,
  SidebarWrapper,
  SwitchRoleWrapperXL,
  UserAvatar,
  UserData,
  UserDataBox,
} from "./styles";
import { ReactComponent as GearIcon } from "../../assets/icons/gear.svg";
import { ReactComponent as RatingIcon } from "../../assets/icons/icon-rating.svg";
import { ReactComponent as ProfileIcon } from "../../assets/icons/icon-bottom-profile-active.svg";
import { ReactComponent as VehiclesManagementIcon } from "../../assets/icons/icons-vehicle.svg";
import { ReactComponent as EmployeeManagementIcon } from "../../assets/icons/icons-people-3-small.svg";
import { ReactComponent as AnalyticsIcon } from "../../assets/icons/icon-24-intellicon-basic-bar-chart.svg";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { roles } from "../../constants/translations";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { RootState } from "../../redux/store/store";
import LoaderSideBar from "./loaderSideBar";
import defaultAvatar from "../../assets/images/avatar.png";
import RatingBox from "../ratingBox";
import { updateProfileInfo } from "../../redux/store/reducers/profileSlice";
import BetaMarker from "../../UI/BetaMarker";

const Sidebar: React.FC<any> = ({ setSwitchToAgent }) => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const companyData = useAppSelector((state) => state.company.companyData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const [navsList, setNavsList] = useState([
    {
      name: "Данные профиля",
      path: "/profile",
      icon: <ProfileIcon />,
    },
    {
      name: "Отзывы и рейтинг",
      path: "rating",
      icon: <RatingIcon />,
    },
  ]);

  useEffect(() => {
    if (user && user.customer.role === "worker" && !user?.customer?.companyId) {
      setNavsList([
        {
          name: "Данные профиля",
          path: "/profile",
          icon: <ProfileIcon />,
        },
        {
          name: "Моя техника",
          path: "vehicle-management",
          icon: <VehiclesManagementIcon />,
        },
        {
          name: "Отзывы и рейтинг",
          path: "rating",
          icon: <RatingIcon />,
        },
        {
          name: "Настройки",
          path: "settings",
          icon: <GearIcon />,
        },
      ]);
    }
    if (
      user &&
      user.customer.role === "customer" &&
      !user?.customer?.companyId
    ) {
      setNavsList([
        {
          name: "Данные профиля",
          path: "/profile",
          icon: <ProfileIcon />,
        },
        {
          name: "Отзывы и рейтинг",
          path: "rating",
          icon: <RatingIcon />,
        },
        {
          name: "Настройки",
          path: "settings",
          icon: <GearIcon />,
        },
      ]);
    }
    if (
      user &&
      user.customer.role === "customer" &&
      user?.customer?.companyId
    ) {
      setNavsList([
        {
          name: "Данные организации",
          path: "/profile",
          icon: <ProfileIcon />,
        },
        {
          name: "Цифровой Диспетчер",
          path: "/dashboard/objects",
          icon: <EmployeeManagementIcon />,
        },
        {
          name: "Аналитика",
          path: "/analytics",
          icon: <AnalyticsIcon />,
        },
        {
          name: "Отзывы и рейтинг",
          path: "rating",
          icon: <RatingIcon />,
        },
        {
          name: "Настройки",
          path: "settings",
          icon: <GearIcon />,
        },
      ]);
    }
    if (user && user.customer.role === "worker" && user?.customer?.companyId) {
      setNavsList([
        {
          name: "Данные организации",
          path: "/profile",
          icon: <ProfileIcon />,
        },

        {
          name: "Управление техникой",
          path: "vehicle-management",
          icon: <VehiclesManagementIcon />,
        },
        // {
        //   name: "Управление сотрудниками",
        //   path: "/employee-management",
        //   icon: <EmployeeManagementIcon />,
        // },
        {
          name: "Отзывы и рейтинг",
          path: "rating",
          icon: <RatingIcon />,
        },
        {
          name: "Настройки",
          path: "settings",
          icon: <GearIcon />,
        },
        // {
        //   name: "Сотрудники",
        //   path: "/employees",
        //   icon: <ProfileIcon />,
        // },
      ]);
    }
  }, [user]);

  const update = (role: string) => {
    user?.customer?.refId &&
      user?.customer?.role !== role &&
      dispatch(
        updateProfileInfo({
          refId: user?.customer?.refId,
          data: { role: role },
        }),
      ).then(() => {
        if (
          role === "customer" &&
          (pathname === "vehicle-management" || pathname === "create-vehicle")
        ) {
          navigate("/profile");
        }
      });
  };

  return (
    <SidebarWrapper>
      {!user ? (
        <LoaderSideBar />
      ) : (
        <UserDataBox>
          <UserData>
            <FullName>
              {user?.customer?.firstName} {user?.customer?.secondName}
            </FullName>
            <Role>{roles[user?.customer?.role]}</Role>

            <RatingPart>
              <RatingBox rating={user?.customer?.rating} />
              <span>{user?.customer?.rating}</span>
            </RatingPart>
          </UserData>
          {((user?.customer?.id === companyData?.ownerId &&
            user?.customer?.companyId) ||
            !user?.customer?.companyId) && (
            <SwitchRoleWrapperXL
              width="233px"
              height="32px"
              active={user?.customer?.role === "customer"}
            >
              <div
                style={{ cursor: "pointer" }}
                className={`btn ${
                  user?.customer?.role === "customer" && "active"
                }`}
                onClick={() => update("customer")}
              >
                Я заказчик
              </div>
              <div
                style={{ cursor: "pointer" }}
                className={`btn ${
                  user?.customer?.role === "worker" && "active"
                }`}
                onClick={() => update("worker")}
              >
                Я исполнитель
              </div>
            </SwitchRoleWrapperXL>
          )}
        </UserDataBox>
      )}
      <NavsListWrapper>
        {navsList.map(({ name, path, icon }) => (
          <NavItem
            key={path}
            slash={path === "/profile" && pathname === "/"}
            active={
              pathname === "/profile"
                ? path === pathname
                : pathname.includes(path) && path !== "/profile"
            }
          >
            <Link
              to={path}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <div>{icon}</div>
              <p>{name}</p>
              {(name === "Сотрудники" ||
                name === "Воронка заказов" ||
                name === "Наработка техники") && (
                <BetaMarker marginLeft="auto" />
              )}
            </Link>
          </NavItem>
        ))}
      </NavsListWrapper>
    </SidebarWrapper>
  );
};

export default memo(Sidebar);

