const report = (state: string) => {
  console.log(`Permission ${state}`);
};

const revokePermission = () => {
  navigator.permissions;
};

export const getLocationPermission = () => {
  navigator.permissions.query({ name: "geolocation" }).then((result) => {
    if (result.state === "granted") {
      report(result.state);

      navigator.geolocation.getCurrentPosition(
        (location) => console.log(location),
        (error) => {
          console.error(error.message);
        },
        {
          timeout: 5000,
          enableHighAccuracy: false,
        },
      );
    } else if (result.state === "prompt") {
      report(result.state);
    } else if (result.state === "denied") {
      report(result.state);
    }
    result.addEventListener("change", () => {
      report(result.state);
    });
  });
};

