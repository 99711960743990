import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { BarList, PaymentBox, PaymentItem, PaymentMethodList } from "./styles";
import { ReactComponent as MirCard } from "../../../assets/icons/mir-card.svg";
import { ReactComponent as SberPay } from "../../../assets/icons/visa.svg";
import { InputNumber } from "antd";

interface Props {
  close?: any;
  onSubmit?: any;
  role?: "customer" | "worker";
  tab?: any;
  defaultValue?: any;
  shifts?: number;
}

const PaymentPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  role,
  tab,
  defaultValue,
  shifts,
}) => {
  const paymentMethods = [
    {
      name: "Безналичный",
      img: <MirCard />,
      value: "bn",
    },
    {
      name: "Безналичный с НДС",
      img: <SberPay />,
      value: "bnnds",
    },
    // {
    //   name: "Факторинг",
    //   img: <SberPay />,
    //   value: "factoring",
    // },
    // {
    //   name: "По договоренности",
    //   img: <SberPay />,
    //   value: "agreement",
    // },
    // {
    //   name: role === "worker" ? "Любой бюджет" : "По договоренности",
    //   img: <SberPay />,
    //   value: role === "worker" ? "Любой бюджет" : "По договоренности",
    // },
    // {
    //   name: "По договоренности",
    //   img: <SberPay />,
    //   value: "По договоренности",
    // },
    // {
    //   name: "Яндекс пэй",
    //   img: <YandexPay />,
    //   value: "yandex-pay",
    // },
    // {
    //   name: "Пэй пэй",
    //   img: <PayPay />,
    //   value: "pay-pay",
    // },
  ];

  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [amount, setAmount] = useState<number>(0);

  const togglePaymentMethod = (method: any) => {
    setPaymentMethod([method]);
    // const exist = paymentMethod.find(
    //   ({ name, img, value }: any) =>
    //     name === method.name && value === method.value
    // );

    // if (!exist) {
    //   setPaymentMethod([...paymentMethod, method]);
    // } else {
    //   setPaymentMethod(
    //     paymentMethod.filter(
    //       ({ name, img, value }: any) =>
    //         name !== method.name && value !== method.value
    //     )
    //   );
    // }
  };

  // useEffect(() => {
  //   if (
  //     (paymentMethod && paymentMethod.name === "По договоренности") ||
  //     (paymentMethod && paymentMethod.name === "Любой бюджет")
  //   ) {
  //     setAmount(0);
  //     onSubmit({ paymentMethod, amount });
  //     close();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [paymentMethod]);

  useEffect(() => {
    if (defaultValue?.paymentMethod?.length > 0 && defaultValue?.amount) {
      setPaymentMethod(defaultValue?.paymentMethod);
      setAmount(
        shifts && shifts > 0
          ? defaultValue?.amount / shifts
          : defaultValue?.amount,
      );
    } else if (defaultValue?.amount) {
      setAmount(
        shifts && shifts > 0
          ? defaultValue?.amount / shifts
          : defaultValue?.amount,
      );
    }
  }, []);

  return (
    <PopUpContainer
      title={
        paymentMethod ? "Планируемый бюджет \n(за смену)" : "Способы оплаты"
      }
      close={close}
      button={
        paymentMethod &&
        paymentMethod.name !== "По договоренности" &&
        "Продолжить"
      }
      onSubmit={
        paymentMethod &&
        (() => {
          onSubmit({
            paymentMethod,
            amount: shifts && shifts > 0 ? amount * shifts : amount,
          });
          close();
        })
      }
      disabled={!Boolean(paymentMethod.length) || !amount}
      tab={tab}
      boxTab={tab}
    >
      <PaymentMethodList>
        {paymentMethods.map(({ name, img, value }) => (
          <PaymentItem
            onClick={() => togglePaymentMethod({ name, img, value })}
            key={value}
          >
            <input
              type="checkbox"
              checked={paymentMethod.some(
                (method: any) => name === method.name && value === method.value,
              )}
              // checked={true}
            />
            <div className="name">{name}</div>
            {/* <div className="icon">{img}</div> */}
          </PaymentItem>
        ))}
      </PaymentMethodList>
      {/* {paymentMethod && paymentMethod.name !== "По договоренности" ? ( */}
      <PaymentBox>
        <InputNumber
          placeholder="Бюджет"
          onChange={(value: any) => {
            setAmount(value);
          }}
          // type={"number"}
          formatter={(value) =>
            `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
          // parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
          value={amount}
          min={0}
          autoFocus
          //disabled={!paymentMethod.length}
          // ref={ref}
        />
        <BarList>
          <div
            className={`barItem ${amount === 10000 ? "active" : ""}`}
            onClick={() => setAmount(10000)}
          >
            10 000
          </div>
          <div
            className={`barItem ${amount === 20000 ? "active" : ""}`}
            onClick={() => setAmount(20000)}
          >
            20 000
          </div>
          <div
            className={`barItem ${amount === 50000 ? "active" : ""}`}
            onClick={() => setAmount(50000)}
          >
            50 000
          </div>
          <div
            className={`barItem ${amount === 100000 ? "active" : ""}`}
            onClick={() => setAmount(100000)}
          >
            100 000
          </div>
        </BarList>
        <div className="description">
          Окончательная цена будет сформирована при выборе исполнителяаа
        </div>
      </PaymentBox>
    </PopUpContainer>
  );
};

export default memo(PaymentPopUp);
