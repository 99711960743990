import styled from "styled-components";

export const InputDatasetWrapper = styled.div`
  width: 100%;
  /* position: absolute;
  top: 0;
  right: 0;
  left: 0; */
  border-radius: 2px;
  z-index: 2;
  height: 48px;
  position: relative;
`;

export const FiltersModal = styled.div`
  display: flex;
  width: calc(100% - 48px);
  background: #ffffff;
  border-radius: 20px;
`;

export const InputDatasetBackground = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
`;

export const InputBox = styled.div`
  background-color: white;
  display: flex;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px 8px;

  .go-back {
    /* padding: 14px 12px 14px 16px; */
    display: flex;
    cursor: pointer;
    transition: all 0.3s linear;
    height: 24px;
    width: 24px;
    align-items: center;
    justify-content: center;

    svg {
      path {
        transition: all 0.3s linear;
      }
    }

    &:hover {
      svg {
        path {
          fill: #ff6633;
        }
      }
    }
  }

  input {
    margin: 0 8px;
    width: calc(100% - 64px);
    /* padding: 12px 0; */
    border: 0;
    outline: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  .close {
    /* padding: 14px 16px 14px 12px; */
    display: flex;
  }

  .save {
    display: flex;
    margin-right: 16px;
    cursor: pointer;

    img {
      width: 20px;
      margin: auto;
    }
  }
`;

export const DatasetList = styled.div<{}>`
  padding: 0 10px;
  background-color: white;
  overflow: auto;
  height: fit-content;
  max-height: 205px;

  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow-x: hidden;

  position: absolute;
  top: 50px;
  right: 0;
  left: 0;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

  .toplist-header {
    background-color: #dbdbdb;
    width: calc(100% + 20px);
    margin-left: -10px;
    color: #777777;
    padding: 5px 0 5px 12px;
  }
`;

export const DatasetBox = styled.div`
  padding: 13px 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  &:last-child {
    border-bottom: 0;
  }

  &::after {
    content: "";
    position: absolute;
    top: -1px;
    right: -10px;
    bottom: -1px;
    left: -10px;
  }

  &:hover::after {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .img {
    margin-right: 14px;
    width: 24px;
    height: 24px;

    img {
      width: 24px;
      height: 24px;
    }
  }

  .data {
    width: calc(100% - 38px);
  }

  .title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.38);
  }
`;
