import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { ContentPart, InfoContainer, Wrapper } from "./styles";
import { ReactComponent as PeopleIcon } from "../../assets/icons/icons-people-3.svg";
import VehicleIcon from "../../assets/icons/icons-vehicles-hoist.png";
import { ImgItem } from "../workDetailOnMap/styles";
import Button from "../../UI/Button";
import { useAppSelector } from "../../redux/hooks";

interface IProps {
  vehicles?: boolean;
  employees?: boolean;
  workers?: boolean;
  person?: boolean;
  onClick?: any;
}

const AddManagementItemEmpty: React.FC<IProps> = ({
  vehicles,
  employees,
  person,
  onClick,
  workers,
}) => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);

  return (
    <ContentPart>
      <InfoContainer>
        {employees ? (
          <PeopleIcon />
        ) : (
          <img src={VehicleIcon} width="64px" height="64px" />
        )}
        <div className="text">{`Добавьте ${
          employees ? "сотрудников" : workers ? "исполнителей" : "свою технику"
        }`}</div>
        <p>
          {employees
            ? "Так вы сможете назначать заказы своим сотрудникам и управлять занятостью"
            : "Избранные исполнители видят ваши заказы в первую очередь, и для них может быть согласована особая ставка комиссии системы."}
        </p>
        {employees &&
        user &&
        user?.customer?.accessSettings?.addingEmployees ? (
          <Button onClick={onClick} height="48px" fontWeight={"500"} shadowed>
            {`Добавить ${
              employees ? "сотрудников" : workers ? "исполнителей" : "технику"
            }`}
          </Button>
        ) : (
          <Button
            onClick={() =>
              onClick
                ? onClick()
                : person
                ? navigate("/my-vehicle/add")
                : navigate("/create-vehicle")
            }
            height="48px"
            fontWeight={"500"}
            shadowed
          >
            {`Добавить ${
              employees ? "сотрудников" : workers ? "исполнителей" : "технику"
            }`}
          </Button>
        )}
      </InfoContainer>
    </ContentPart>
  );
};

export default memo(AddManagementItemEmpty);
