import styled from "styled-components";
import RegisterImg from "../../assets/images/register-img.png";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  background-color: #ffffff;
  height: calc(100% - 137px);

  @media screen and (max-width: 998px) {
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    height: calc(100% - 64px);
  }
`;

export const Section = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;

  &:not(.image) {
    width: 50%;
    min-width: 420px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &.image {
    width: 50%;
  }

  .background {
    width: 100%;
    height: 100%;
    background-image: url(${RegisterImg});
    background-position: center;
    background-size: cover;
  }

  @media screen and (max-width: 998px) {
    &:not(.image) {
      width: 390px;
      align-items: center;
    }

    &.image {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    &:not(.image) {
      width: 100%;
      min-width: unset;
      align-items: unset;
      justify-content: unset;
    }
  }
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 340px;
  margin-top: 16px;

  .button {
    width: 328px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    background: #ff6633;
    border-radius: 10px;
    box-shadow: 0 2px 14px 0 #a63c184c;
    cursor: pointer;
    transition: all 0.3s linear;

    &.disabled {
      background: #e5e5e5;
      color: #999999;
      box-shadow: none;
    }
  }

  .qrCodeWrapper {
    width: fit-content;
    height: fit-content;
    margin: 16px auto;

    @media screen and (max-width: 480px) {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    width: 320px;
    height: 100%;
    align-self: center;
    justify-content: flex-start;
    flex-direction: column-reverse;
    padding-bottom: 40px;
    gap: 40px;
    margin-top: 0;

    .button {
      width: 100%;
    }
  }

  @media screen and (max-width: 320px) {
    padding: 16px 16px 0;
  }
`;

export const MobileIllustration = styled.div`
  display: none;
  justify-content: space-around;
  align-items: center;
  margin-top: 80px;
  margin-bottom: auto;

  @media screen and (max-width: 480px) {
    display: flex;
  }

  svg:nth-child(even) {
    transform: rotate(180deg);

    g {
      use {
        fill: #757575;
      }
      g {
        fill: #757575;
      }
    }
  }
`;

export const FormText = styled.div`
  font-size: 19px;
  font-family: "Rubik";
  line-height: 1.2;
  color: #000;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 480px) {
    &.mobile {
      display: block;
    }

    &:not(.mobile) {
      display: none;
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #222222;
  padding-bottom: 12px;

  &.mobile {
    display: none;
  }

  @media screen and (max-width: 480px) {
    gap: 16px;
    display: none;

    &.mobile {
      display: flex;
    }
  }
`;
