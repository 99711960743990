import React, { memo, useEffect, useState } from "react";
import PopUpContainer from "../popUpContainer";
import { PaymentItem, PaymentMethodList } from "./styles";
import { ReactComponent as MirCard } from "../../../assets/icons/mir-card.svg";
import { ReactComponent as SberPay } from "../../../assets/icons/visa.svg";

interface Props {
  close?: any;
  onSubmit?: any;
  role?: "customer" | "worker";
  tab?: any;
  defaultValue?: any;
}

const PaymentMethodPopUp: React.FC<Props> = ({
  close,
  onSubmit,
  role,
  tab,
  defaultValue,
}) => {
  const paymentMethods = [
    {
      name: "Безналичный",
      img: <MirCard />,
      value: "bn",
    },
    {
      name: "Безналичный с НДС",
      img: <SberPay />,
      value: "bnnds",
    },
    // {
    //   name: "Наличные",
    //   img: <MirCard />,
    //   value: "cash",
    // },
    // {
    //   name: "Факторинг",
    //   img: <SberPay/>,
    //   value: "factoring",
    // },
    // {
    //   name: "По договоренности",
    //   img: <SberPay />,
    //   value: "agreement",
    // },
    // {
    //   name: role === "worker" ? "Любой бюджет" : "По договоренности",
    //   img: <SberPay />,
    //   value: role === "worker" ? "Любой бюджет" : "По договоренности",
    // },
    // {
    //   name: "По договоренности",
    //   img: <SberPay />,
    //   value: "По договоренности",
    // },
    // {
    //   name: "Яндекс пэй",
    //   img: <YandexPay />,
    //   value: "yandex-pay",
    // },
    // {
    //   name: "Пэй пэй",
    //   img: <PayPay />,
    //   value: "pay-pay",
    // },
  ];

  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [amount, setAmount] = useState<number>(0);

  const togglePaymentMethod = (method: any) => {
    setPaymentMethod([method]);
    onSubmit({ paymentMethod: [method], amount });
    close();
    // const exist = paymentMethod.find(
    //     ({name, img, value}: any) =>
    //         name === method.name && value === method.value
    // );

    // if (!exist) {
    //   setPaymentMethod([...paymentMethod, method]);
    // } else {
    //   setPaymentMethod(
    //       paymentMethod.filter(
    //           ({name, img, value}: any) =>
    //               name !== method.name && value !== method.value
    //       )
    //   );
    // }
  };

  useEffect(() => {
    if (
      (paymentMethod && paymentMethod.name === "По договоренности") ||
      (paymentMethod && paymentMethod.name === "Любой бюджет")
    ) {
      setAmount(0);
      onSubmit({ paymentMethod, amount });
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  useEffect(() => {
    if (defaultValue?.paymentMethod?.length > 0 && defaultValue?.amount) {
      setPaymentMethod(defaultValue?.paymentMethod);
      setAmount(defaultValue?.amount);
    } else if (defaultValue?.amount) {
      setAmount(defaultValue?.amount);
    }
  }, []);

  return (
    <PopUpContainer
      title={"Способы оплаты"}
      close={close}
      // button={
      //     paymentMethod &&
      //     paymentMethod.name !== "По договоренности" &&
      //     "Продолжить"
      // }
      // onSubmit={
      //     paymentMethod &&
      //     (() => {
      //       onSubmit({paymentMethod, amount});
      //       close();
      //     })
      // }
      // disabled={
      //   !Boolean(paymentMethod.length)
      //   //  || !amount
      // }
      tab={tab}
      boxTab={tab}
    >
      <PaymentMethodList>
        {paymentMethods.map(({ name, img, value }) => (
          <PaymentItem
            onClick={() => togglePaymentMethod({ name, img, value })}
            key={value}
          >
            {/* <input
              type="checkbox"
              checked={paymentMethod.some(
                (method: any) => name === method.name && value === method.value
              )}
              // checked={true}
            /> */}
            <div className="name">{name}</div>
            {/* <div className="icon">{img}</div> */}
          </PaymentItem>
        ))}
      </PaymentMethodList>
    </PopUpContainer>
  );
};

export default memo(PaymentMethodPopUp);
